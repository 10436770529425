export enum ClipPathTypes {
  TRIANGLE = "TRIANGLE",
  TRAPEZOID = "TRAPEZOID",
  PARALLELOGRAM = "PARALLELOGRAM",
  RHOMBUS = "RHOMBUS",
  PENTAGON = "PENTAGON",
  HEXAGON = "HEXAGON",
  HELPTAGON = "HELPTAGON",
  OCTAGON = "OCTAGON",
  NONAGON = "NONAGON",
  DECAGON = "DECAGON",
  BEVEL = "BEVEL",
  RABBET = "RABBET",
  LEFTARROW = "LEFTARROW",
  RIGHTARROW = "RIGHTARROW",
  LEFTPOINT = "LEFTPOINT",
  RIGHTPOINT = "RIGHTPOINT",
  LEFTCHEVRON = "LEFTCHEVRON",
  RIGHTCHEVRON = "RIGHTCHEVRON",
  STAR = "STAR",
  CROSS = "CROSS",
  MESSAGE = "MESSAGE",
  CLOSE = "CLOSE",
  FRAME = "FRAME",
  INSET = "INSET",
  CIRCLE = "CIRCLE",
  ELLIPSE = "ELLIPSE"
}
