import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 100,
    mobile: {
      desktop: { padding: 100 },
      smartphone: { padding: 0 },
    },
  },
  HEADLINE: {
    fontSize: "16pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    textAlign: "center",
    // padding: 40,
    marginTop: 30,
  },
  TEXT: {
    padding: 10,
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    textAlign: "center",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 20,
      border: "none",
      background: theme.foregroundColor,
      width: 200,
      // maxWidth: "100%",
      borderRadius: 5,
      cursor: "pointer",
      // margin: "30px auto",
      marginTop: 40,
      textAlign: "center",
    },
    caption1: {
      // color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "14pt",
    },
  },
};
