import React from "react";
import { get } from 'lodash'

import {
  // Button,
  Card,
  CardCaption,
  Cell,
  Grid,
  Tab,
  Tabs,
  Thumbnail,
  TextInput,
} from "@launchos/components/ui";
import { triggerHook } from "@launchos/plugins/client";
import { useSpring, animated } from "react-spring";
import { AppConfig } from "@launchos/modules/app";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {
  ListItemsHooks,
  HookTriggers,
} from "@launchos/plugins/types";

import gql from "graphql-tag";
import { client } from "@launchos/api/gql/client";

import PropTypes from "prop-types";
import style from "./style";

import { checkForSmartCopyIntegration, parseWithSmartCopy } from '@launchos/plugins/misc/v2/smartcopy/client/scripts'

const typeMatches = {
  lead: ["lead", "webinar"],
  sales: ["sales", "home", "vsl"],
  event: ["event", "launch", "content"],
  oto: ["oto"],
  order: ["order", "checkout"],
  "thank you": ["thank you"],
  all: ["all"],
  blank: ["all"],
};

const changeTemplate = async (props) => {
  const {
    updatePageContent,
    match,
    history,
    settings,
    tab,
    useSmartCopyEngine,
    setIsLoading
  } = props;

  const { page } = settings;
  const content = page ? page.content : false

  if (content) {

    let proceed = false;

    if (confirm("Are you sure you want to use this page as your template?"))
      proceed = true;
    else proceed = false;

    // console.log({ tab, categories: settings.categories })

    if (proceed) {

      setIsLoading(true); // show loading

      let parsedContent = content;

      // fill with copy if specified
      // const useSmartCopyEngine = true; // get from localhost

      if (useSmartCopyEngine) {
        parsedContent = await parseWithSmartCopy(content, settings.categories)
        // console.log({ parsedContentReview: parsedContent.filter(({ type }) => type === "Paragraph") })
      }

      const variables = {
        pageId: match.params.nodeId,
        content: parsedContent,
        type: tab,
      };

      await updatePageContent({ variables });

      setIsLoading(false);

      history.push(
        `/campaign/${match.params.campaignId}/${match.params.nodeId}/builder`
      );
    }
  }
}

/**
 * This represents a single template
 * @param {object} props The props
 * @returns {function} The component
 */
const TemplateItem = (props) => {
  const [templateItemHover, setTemplateItemHover] = React.useState(false)

  const fadeAnimStyle = useSpring({
    zoom: templateItemHover ? 1.5 : 1.45,
    marginTop: templateItemHover ? -66 : -45,
    from: {
      zoom: templateItemHover ? 1.45 : 1.5,
      marginTop: templateItemHover ? -45 : -66,
    },
  });

  const { settings, setItemsAreActive } = props;
  const { id, description, image, name, page } = settings;

  const thumbnail = (<Thumbnail
    {...props}
    alt={description}
    style={{ borderRadius: 3, width: "101%" }}
    src={`https://sandcastleassets.s3.amazonaws.com/templates/pageid/${page.id}.png`}
  />)

  return (
    <Cell key={id}
      onMouseEnter={() => { setTemplateItemHover(true); setItemsAreActive(true); }}
      onMouseLeave={() => { setTemplateItemHover(false); setItemsAreActive(false); }}
      onClick={() => changeTemplate(props)}
      style={{ margin: 20 }}
    >
      <Card>
        <div
          style={{
            margin: "30px 30px 10px",
            ...style.templateItem,
            ...(props.itemsAreActive && !templateItemHover) ? { filter: "grayscale(100%)", opacity: 0.3 } : {},
          }}
        >
          {templateItemHover ? (
            <animated.div style={fadeAnimStyle}>
              <div style={{
                ...style.templateItem,
                ...style.templateItemHoverZoom,
              }}>
                {thumbnail}
              </div>
            </animated.div>
          ) : thumbnail}
        </div>
        <CardCaption>
          <div style={{ padding: 10, textAlign: "center", width: "100%" }}>
            {name}
          </div>
        </CardCaption>
      </Card>
    </Cell>
  );
}

/**
 * This is a sidebar that opens to reveal all the various
 * templates that can be applied to the associated page
 * @param {object} props The props
 * @returns {function} The component
 */
const TemplateSelection = (props) => {
  const [filter, setFilter] = React.useState("");
  const [itemsAreActive, setItemsAreActive] = React.useState(false);
  const [useSmartCopyEngine, setUseSmartCopyEngine] = React.useState(false);
  const [usesSmartCopy, setUsesSmartCopy] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)

  // const checkForSmartCopyIntegration = async () => {
  //   // check for smart copy integration
  //   const { data } = await client.query({
  //     query: gql`
  //     query GetSmartCopyProfile($userId: ID!) {
  //       integrations(where: { user: { id: $userId }, type: "smart_copy" }) {
  //         id
  //       }
  //     }
  //   `,
  //     variables: {
  //       userId: localStorage.userId,
  //     },
  //   });

  //   if (get(data, "integrations", []).length) {
  //     setUsesSmartCopy(true);
  //   }
  // }

  const handleOnLoad = async () => {
    const usesIt = await checkForSmartCopyIntegration();
    setUsesSmartCopy(usesIt)
  }

  React.useEffect(() => {
    handleOnLoad()
  }, [])

  return (
    <AppConfig.Consumer>
      {(settings) => {
        if (props.allCatalogDesignItems.loading || isLoading)
          return (
            <div style={{ textAlign: "center" }}>
              <img src={settings.app.loadingImage} alt="Loading..." />
              {useSmartCopyEngine && <p style={{ padding: 20 }}>Generating Smart Copy<sup>ALPHA</sup><br /><br />Please stand-by.  This may take up to 60 seconds...</p>}
            </div>
          );
        if (props.allCatalogDesignItems.error)
          return (
            <div style={{ textAlign: "center" }}>
              <img src={settings.app.error} alt="Loading..." />
            </div>
          );

        // console.log("TAB", props.tab);

        if (!props.hidden) {
          // what is the type of template we're filtering for? (tab)
          const tab = props.tab ? props.tab : "all";

          // what are the matching template types to include in your search
          let tabFilters = ["all"];
          if (!typeMatches.hasOwnProperty(tab)) {
            props.history.push("./all");
          } else {
            tabFilters = typeMatches[tab];
          }

          // filter catalog items for any of those types
          const pluginItems = triggerHook(HookTriggers.onListItems, {
            id: ListItemsHooks.PAGE_TEMPLATE,
            type: "designs",
          });
          const catalogItems = props.allCatalogDesignItems.catalogItems;

          const items = [...pluginItems, ...catalogItems]
            .filter(
              ({ name }) =>
                name.toLowerCase().indexOf(filter.toLowerCase()) > -1
            )
            .filter(
              (itm) => tabFilters.indexOf(itm.categories) > -1 || tab === "all"
            );
          // console.log({ catalogItems, pluginItems, items });

          // build a TemplateItem object from that
          return (
            <div>
              {/* {usesSmartCopy &&
                <div style={{ float: 'right', padding: 10 }}>
                  <FormControlLabel control={<Switch checked={useSmartCopyEngine} onChange={e => setUseSmartCopyEngine(e.target.checked)} />} label="Use Smart Copy" />
                  <sup style={{ marginLeft: -13, color: '#BB0000', fontSize: '7pt' }}>ALPHA</sup>
                </div>
              } */}

              <div
                style={{
                  backgroundColor: "rgb(245,245,245)",
                  borderBottom: "1px solid rgb(238, 238, 238)",
                }}
              >
                <h2
                  style={{ textAlign: "center", padding: 20, paddingTop: 40 }}
                >
                  Please choose a template for this page
                </h2>
                {/* {props.title ? <h1>Choose a template for this page</h1> : null} */}
                <div style={{ width: 650, margin: "0 auto" }}>
                  <TextInput
                    placeholder="Search for a template..."
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    style={{ backgroundColor: "white", textAlign: "center" }}
                  />
                </div>
                <Tabs transparent style={{ padding: "35px 0 0" }}>
                  <Tab name="all" href="all" currentTab={tab}>
                    All Pages
                  </Tab>
                  <Tab name="lead" href="lead" currentTab={tab}>
                    Lead Capture
                  </Tab>
                  <Tab name="sales" href="sales" currentTab={tab}>
                    Sales Pages
                  </Tab>
                  <Tab name="event" href="event" currentTab={tab}>
                    Event Pages
                  </Tab>
                  <Tab name="oto" href="oto" currentTab={tab}>
                    Special Offer
                  </Tab>
                  <Tab name="order" href="order" currentTab={tab}>
                    Checkout Pages
                  </Tab>
                  <Tab name="thank you" href="thank you" currentTab={tab}>
                    Thank You Pages
                  </Tab>
                </Tabs>
              </div>
              <Grid justify="center" wrap style={{ padding: 25 }}>
                {/* {items.map((template, key) => (<p>{template.name}</p>))} */}
                {[...(tab === "all" ? [items[0]] : []), ...items.reverse()].map(
                  (template, key) => (
                    <TemplateItem
                      key={key}
                      {...props}
                      useSmartCopyEngine={useSmartCopyEngine}
                      setIsLoading={setIsLoading}
                      settings={template}
                      itemsAreActive={itemsAreActive}
                      setItemsAreActive={setItemsAreActive}
                    />
                  )
                )}
              </Grid>
            </div>
          );
        }
      }}
    </AppConfig.Consumer>
  );
};

TemplateSelection.propTypes = {
  /** Boolean whether or not to display the section */
  hidden: PropTypes.bool,
  tab: PropTypes.string,
};

TemplateSelection.defaultProps = {
  hidden: false,
  tab: "all",
};

export default TemplateSelection;
