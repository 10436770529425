import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    paddingTop: 50,
    paddingBottom: 0,
    paddingLeft: 50,
    paddingRight: 50,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "center",
    padding: 20,
    fontFamily: theme.fontFamily,
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
    // padding: "100px 20px",
    // backgroundColor: theme.backgroundColor,
    // backgroundImage: `url(https://images.unsplash.com/photo-1512766851852-dbea8be6418e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60)`,
    backgroundImage: `url(https://images.unsplash.com/photo-1577416887379-2d7212a2c27c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80)`,
    backgroundPosition: "center -100px",
    backgroundSize: "100%",
    position: "relative",
  },
  BUTTON_CONTAINER: {
    maxWidth: 275,
    margin: "0 auto",
    marginTop: 20,
    marginBottom: 50,
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 10,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
