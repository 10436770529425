import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { checkIfSlugExists } from "@launchos/api/gql/pages";
import { client } from "@launchos/api/gql/client";

import {
  Button,
  Cell,
  FontIcon,
  Grid
} from "@launchos/components/ui";

import { AppConfig } from "@launchos/modules/app";
import { triggerHook } from "@launchos/plugins/client";

import {
  ComponentRenderHooks,
  HookTriggers,
} from "@launchos/plugins/types";

import { UpDownControls, moveCampaignObject } from './UpDownControls';

import { objects, RenamableTitle, style } from "../shared";
import { style as simpleBuilderStyle } from "./style";

export const MenuItemLabel = ({ children, icon }) => (
  <div style={style.menuItem}>
    {icon && <FontIcon>{icon}</FontIcon>}
    <span style={style.menuItemLabel}>{children}</span>
  </div>
);

class CampaignLegendObject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentage: 0,
      slug: _.get(props, "settings.page.slug", false),
      isActive: false,
      loading: false,
      settings: props.settings,
      numberActive: false,
      anchorEl: null,
    };

    this.renameObject = this.renameObject.bind(this);
    this.deleteObject = this.deleteObject.bind(this);
    this.makeThisMyHomePage = this.makeThisMyHomePage.bind(this);
    this.makeThisMy404Page = this.makeThisMy404Page.bind(this);
    this.makeThisATemplate = this.makeThisATemplate.bind(this);
    this.showSlug = this.showSlug.bind(this);
    this.hideSlug = this.hideSlug.bind(this);
    this.changeSlug = this.changeSlug.bind(this);
    this.cloneObject = this.cloneObject.bind(this);
    this.handleDropDownClick = this.handleDropDownClick.bind(this);
    this.handleDropDownClose = this.handleDropDownClose.bind(this);
  }

  static contextType = AppConfig;

  makeThisMyHomePage() {
    // what is the id of the domain for this campaign?
    const domainId = this.props.getCampaignQuery.campaign.domain.id;
    const pageId = this.props.settings.page.id;

    if (domainId) {
      this.props
        .makeThisMyHomePage({
          variables: {
            pageId,
            domainId,
          },
        })
        .then((json) => {
          console.log(json);
          // this.props.getCampaignQuery.refetch();
          alert(
            `Your Home Page settings have been updated.  Please re-publish the "${json.data.updateDomain.homePage.name}" page for it to take effect for your website visitors.`
          );

          window.location.reload();
        });
    } else {
      alert("No Domain Has Been Set For This Campaign");
    }
  }

  makeThisMy404Page() {
    // what is the id of the domain for this campaign?
    const domainId = this.props.getCampaignQuery.campaign.domain.id;
    const pageId = this.props.settings.page.id;

    if (domainId) {
      this.props
        .makeThisMyErrorPage({
          variables: {
            pageId,
            domainId,
          },
        })
        .then((json) => {
          console.log(json);
          alert(
            `Your 404 (Error) Page settings have been updated.  Please re-publish the "${json.data.updateDomain.errorPage.name}" page for it to take effect for new website visitors.`
          );

          window.location.reload();
        });
    } else {
      alert("No Domain Has Been Set For This Campaign");
    }
  }

  makeThisATemplate() { }

  showSlug() {
    this.setState({
      isActive: true,
    });
  }

  hideSlug() {
    this.setState({
      isActive: false,
    });
  }

  async changeSlug(slug = false) {
    const filteredSlug = slugify(slug.toString().toLowerCase(), { remove: /[*+~.()'"!:@]/g });
    const { settings, showSnackBar, updatePageName } = this.props;
    const pageId = settings.page.id;

    if (filteredSlug === "") {
      alert(
        "Sorry!  Your Slug Cannot be Empty.  Please Try Again.\n\nIf you want to make this page your Home Page, click Page Actions > This is my Home Page."
      );
    } else {
      // Check if slug exists
      const variables = {
        slug: filteredSlug,
        userId: localStorage.userId,
        pageId,
      };
      const { data } = await client.query({
        query: checkIfSlugExists,
        variables,
      });

      console.log("Exists?", data.pages.length, data, variables);
      if (data.pages.length) {
        showSnackBar(
          "Sorry, that slug name already exists.  Please try again!",
          "warning"
        );
      } else {
        this.setState({ slug: filteredSlug });

        await updatePageName({
          variables: {
            pageId,
            slug: filteredSlug,
          },
        });

        showSnackBar(
          "Your slug has been updated.  Publish the campaign to make it live!"
        );
      }
    }
  }

  renameObject(name = false) {
    const newObjectName = name
      ? name
      : window.prompt("Please Rename This Object", this.state.settings.name);

    if (newObjectName) {
      const variables = {
        name: newObjectName,
        deleted: false,
        screenshot: this.state.settings.screenshot,
        id: this.state.settings.id,
      };

      console.log({ variables });

      // update campaign with newobject name first before saving
      this.setState(
        (prevState) => ({
          settings: {
            ...prevState.settings,
            name: newObjectName,
          },
        }),
        () => {
          this.props
            .updateCampaignObjectMutation({
              variables,
            })
            .then((json) => {
              const pageId = this.props.settings.page.id;
              console.log({ pageId });

              this.props.updatePageName({
                variables: {
                  pageId,
                  name: newObjectName,
                },
              });
            });
        }
      );
    }
  }

  async deleteObject() {
    const { showSnackBar, removeCanvasObject, settings } = this.props;

    if (window.confirm("Are you sure you want to delete this page?")) {
      await removeCanvasObject(settings.id);
      showSnackBar(
        "Your page was deleted.  Publish the campaign to make it live!"
      );
    }
  }

  async cloneObject() {
    const { showCloneObjectPopup, settings } = this.props;
    showCloneObjectPopup(settings);
  }

  handleDropDownClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleDropDownClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { app } = this.context;
    const { getCampaignState, setCampaignState, settings } = this.props;
    const pageId = settings.page ? settings.page.id : false;
    // console.log("Page", settings.page.content.length)

    const isHome = _.has(this.props, "settings.page.homePageForDomain.id");
    const isError = _.has(this.props, "settings.page.errorPageForDomain.id");

    if (this.props.getUserQuery.loading || this.state.loading)
      return (
        <div style={{ textAlign: "center" }}>
          <img src={app.loadingImage} alt="Loading..." />
        </div>
      );

    // console.log({ user: this.props.getUserQuery });

    const defaultDomain = `${this.props.getUserQuery.user.subdomain}.${app.domain}`;

    const getComponentFromPlugin = triggerHook(
      HookTriggers.onComponentRender,
      { id: ComponentRenderHooks.CANVAS_OBJECT },
      settings,
      { getCampaignState, setCampaignState }
    );

    // thumbnail
    const type = this.props.settings.page.type;
    const thumbnailMatches = objects.pages.filter(
      (itm) => itm.pageType === type
    );
    const thumbnail = thumbnailMatches.length
      ? thumbnailMatches[0].screenshot
      : app.logoIcon;

    return (
      <div style={{ padding: "60px 50px" }}>
        <div
          style={{
            marginLeft: 25,
            borderLeft:
              this.props.index < this.props.length ? "1px dashed #CCC" : "none",
            position: "absolute",
            height: 335,
          }}
        />
        <Grid
          onMouseEnter={() => this.setState({ numberActive: true })}
          onMouseLeave={() => this.setState({ numberActive: false })}
        >
          <Cell>
            {this.state.numberActive && (
              <UpDownControls
                showUp={this.props.index > 1}
                showDown={this.props.index < this.props.length}
                id={this.props.settings.id}
                useObjects={this.props.useObjects}
                updateCampaignObjectMutation={
                  this.props.updateCampaignObjectMutation
                }
                setLoading={loading => this.setState({ loading })}
              />
            )}
            <div
              style={{
                borderRadius: 100,
                padding: !isHome && !isError ? 18 : 12,
                marginRight: 35,
                textAlign: "center",
                width: 50,
                backgroundColor: "white",
                boxShadow: this.state.isActive
                  ? "5px 5px 25px 0 rgba(46,61,73,.35)"
                  : "5px 5px 25px 0 rgba(46,61,73,.2)",
                position: "relative",
              }}
            >
              {isHome ? <FontIcon>home</FontIcon> : null}
              {isError ? <FontIcon>error</FontIcon> : null}
              {!isHome && !isError ? <>{this.props.index}</> : null}
            </div>
          </Cell>
          <Cell
            style={{
              borderRadius: 3,
              backgroundColor: "white",
              boxShadow: this.state.isActive
                ? "5px 5px 25px 0 rgba(46,61,73,.35)"
                : "5px 5px 25px 0 rgba(46,61,73,.2)",
            }}
          >
            <Grid onMouseEnter={this.showSlug} onMouseLeave={this.hideSlug}>
              <Cell style={{ padding: 35, paddingBottom: 0, width: 550 }}>
                <div>
                  <RenamableTitle
                    name={this.state.settings.name}
                    onChange={(name) => this.renameObject(name)}
                    showIcon
                    style={simpleBuilderStyle.renamableTitle}
                  />
                  {this.state.slug ? (
                    <div
                      style={{
                        padding: "5px 0",
                        visibility: this.state.isActive ? "visible" : "hidden",
                      }}
                    >
                      <span style={{ fontSize: 12, color: "#999" }}>/</span>
                      <RenamableTitle
                        name={this.state.slug}
                        showIcon
                        onChange={(slug) => this.changeSlug(slug)}
                        style={{
                          fontSize: 12,
                          color: "#999",
                          padding: 0,
                          textAlign: "left",
                        }}
                        iconStyle={{
                          zoom: 0.5,
                          marginLeft: 32,
                          marginTop: -8,
                        }}
                      />
                      <a
                        href={`http://${this.props.domainName || defaultDomain
                          }/${this.state.slug}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontIcon
                          style={{
                            zoom: 0.5,
                            marginLeft: 10,
                            position: "relative",
                            top: 3,
                            color: "#666",
                          }}
                        >
                          launch
                        </FontIcon>
                      </a>
                    </div>
                  ) : null}
                </div>
                <Link to={settings.page.content.length === 1 ? `${pageId}/templates/${settings.page.type}` : `${pageId}/builder`}>
                  <Button secondary style={{ margin: "25px 0" }}>
                    Edit Page &gt;
                  </Button>
                </Link>

                <div style={style.bigToolbar.innerInfoBar}>
                  <Grid justify="space-between">
                    <Cell />
                    <Cell>
                      <div
                        style={{
                          display: 'flex',
                          cursor: "pointer",
                          textAlign: "right",
                          padding: "12px 0 8px"
                        }}
                        aria-controls="editor-object-menu"
                        aria-haspopup="true"
                        onClick={this.handleDropDownClick}
                      >
                        <div style={{ paddingRight: 10 }}>
                          Page Actions
                        </div>

                        <span
                        >
                          <FontIcon
                            className="arrdropdown"
                            style={{ color: "#666" }}
                          >
                            keyboard_arrow_down
                          </FontIcon>
                        </span>
                      </div>

                      <Menu
                        id="editor-object-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleDropDownClose}
                      >
                        <Link to={settings.page.content.length === 1 ? `${pageId}/templates/${settings.page.type}` : `${pageId}/builder`}>
                          <MenuItem>
                            <MenuItemLabel icon="edit">Edit Page</MenuItemLabel>
                          </MenuItem>
                        </Link>
                        {/* <Link
                          target="_blank"
                          to={`${pageId}/builder/preview`}
                        >
                          <MenuItem>
                            <MenuItemLabel icon="preview">Preview Page</MenuItemLabel>
                          </MenuItem>
                        </Link> */}
                        <MenuItem onClick={() => window.open(`http://${this.props.domainName}/${this.state.slug}`)}>
                          <MenuItemLabel icon="launch">View Published Page</MenuItemLabel>
                        </MenuItem>
                        <MenuItem onClick={() => this.renameObject()}>
                          <MenuItemLabel icon="input">
                            Rename Page
                          </MenuItemLabel>
                        </MenuItem>
                        <div style={style.divider} />
                        {this.props.index > 1 &&
                          <MenuItem onClick={() => { this.setState({ loading: true }); moveCampaignObject("UP", { id: this.props.settings.id, ...this.props }); }}>
                            <MenuItemLabel icon="keyboard_arrow_up">
                              Move Page Up
                            </MenuItemLabel>
                          </MenuItem>
                        }
                        {(this.props.index < this.props.length) && (
                          <MenuItem onClick={() => { this.setState({ loading: true }); moveCampaignObject("DOWN", { id: this.props.settings.id, ...this.props }); }}>
                            <MenuItemLabel icon="keyboard_arrow_down">
                              Move Page Down
                            </MenuItemLabel>
                          </MenuItem>
                        )}
                        <div style={style.divider} />
                        <MenuItem onClick={this.cloneObject}>
                          <MenuItemLabel icon="content_copy">
                            Make a Copy
                          </MenuItemLabel>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const slug = prompt(
                              `What url would you like this page to have?\n\nhttp://${this.props.domainName}/`,
                              this.state.slug
                            );
                            if (slug) this.changeSlug(slug);
                          }}
                        >
                          <MenuItemLabel icon="http">
                            Change the Page URL/Slug
                          </MenuItemLabel>
                        </MenuItem>
                        <div style={style.divider} />
                        <MenuItem onClick={this.makeThisMyHomePage}>
                          <MenuItemLabel icon="home">
                            Make This My Home Page
                          </MenuItemLabel>
                        </MenuItem>
                        <MenuItem onClick={this.makeThisMy404Page}>
                          <MenuItemLabel icon="error">
                            Make This My 404 (Error) Page
                          </MenuItemLabel>
                        </MenuItem>
                        <div style={style.divider} />
                        <MenuItem onClick={this.deleteObject}>
                          <MenuItemLabel icon="delete_forever">
                            Delete Page
                          </MenuItemLabel>
                        </MenuItem>
                      </Menu>

                    </Cell>
                  </Grid>
                </div>
              </Cell>
              <Cell
                style={{
                  width: 250,
                  backgroundColor: "#EEE",
                  textAlign: "center",
                  backgroundImage: `url(${thumbnail})`,
                  backgroundPosition: "center",
                  backgroundSize: thumbnailMatches.length ? "cover" : "auto",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {getComponentFromPlugin.length
                  ? React.createElement(
                    _.head(getComponentFromPlugin),
                    this.props
                  )
                  : null}
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </div>
    );
  }
}

export default CampaignLegendObject;
