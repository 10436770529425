import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 50,
    mobile: {
      desktop: { padding: 50 },
      smartphone: { padding: 0 },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,

    mobile: {
      desktop: { fontSize: "32pt" },
      smartphone: { fontSize: "22pt" },
    },
  },
  SUBHEADLINE: {
    textAlign: "center",
    fontSize: "16pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "10px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "16pt",
    },
  },
};
