import _ from "lodash";
import React, { Component } from "react";
import {
  Dialog,
  DialogBody,
  Grid,
  Cell,
  Button,
  TextInput
} from "@launchos/components/ui";

export default class RedirectPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: _.get(props, "options.settings.url", "")
    };

    this.saveSettings = this.saveSettings.bind(this);
  }

  async saveSettings() {
    const { options, actions } = this.props;
    const { url } = this.state;
    const { updatePageSettings, updateObjectSettings } = actions;

    const pageId = _.get(options, "page.id");

    const pageScript = `
      <script type="text/javascript">
        window.location.href = "${url}";
      </script>
    `;

    await updateObjectSettings({
      id: options.id,
      settings: { url }
    });

    await updatePageSettings({
      pageId,
      pageSettings: {
        tracking: {
          head: pageScript
        }
      }
    });

    window.location.reload();
    // onClose();
  }

  render() {
    return (
      <Dialog {...this.props} style={{ width: 700 }} centered>
        <DialogBody>
          <img
            style={{ margin: 10, width: 100 }}
            alt=""
            src="https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_pageredirect.png"
          />

          <h2>
            What Website Address (URL) should visitors <br />
            automatically be re-routed to?
          </h2>

          <TextInput
            style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}
            placeholder="(e.g. http://www.your.destination.url.here.com/)"
            onChange={e => this.setState({ url: e.target.value })}
            value={this.state.url}
          />

          <Grid justify="space-evenly" style={{ width: 250, margin: "0 auto" }}>
            <Cell align="center">
              <Button onClick={this.saveSettings} large tertiary>
                OK
              </Button>
            </Cell>
            <Cell align="center">
              <Button
                onClick={this.props.onClose}
                large
                transparent
                style={{ color: "#333" }}
              >
                Cancel
              </Button>
            </Cell>
          </Grid>
        </DialogBody>
      </Dialog>
    );
  }
}
