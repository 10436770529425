import * as React from 'react'
import ReactPlayer from "react-player";
import { get, head, orderBy } from 'lodash'
import Tooltip from "@material-ui/core/Tooltip";
import Switch from '@material-ui/core/Switch';

import { Icon } from "@launchos/plugins/webcomponents/v2";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon/types";

import { VideoActions, VideoTutorialProps } from './types'
import { getStyles, SectionCircle, BigButton } from './style'

export const VideoTutorial: React.FC<VideoTutorialProps> = ({
  data = [],
  src,
  style = {},
  transparent = false,
  secondary = false,
  primary = true,
  tertiary = false,
  disabled = false,
  negative = false,
  autoPlay = false,
  videoConfig = {},
  sectionToPlay = false,
  onPlay = () => null,
  onPause = () => null,
  onClose = () => null,
  onProgress = () => null,
  onSectionChange = () => null,
  onComplete = () => null,
}) => {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(autoPlay);
  const [isShowingVideo, setIsShowingVideo] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>(sectionToPlay ? get(data, `${data.findIndex(itm => itm.id === sectionToPlay)}.title`, get(head(data), 'title', '')) : get(head(data), 'title', ''))
  // const [currentSection, setCurrentSection] = React.useState<string>(sectionToPlay || get(head(data), 'id'))
  const [currentSection, setCurrentSection] = React.useState<string>(get(data, `${data.findIndex(itm => itm.id === sectionToPlay)}.id`, get(head(data), 'id', '')))
  const [skipSectionPause, setSkipSectionPause] = React.useState<string>(get(head(data), 'id'))
  const [currentAutoPlayValue, setAutoPlay] = React.useState(autoPlay);
  const [replayPrevious, setReplayPrevious] = React.useState(false);
  const ref = React.useRef(null)

  const styles = getStyles({
    transparent,
    secondary,
    primary,
    tertiary,
    disabled,
    negative,
    ...style,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoPlay(event.target.checked);
  };

  const handleProgress = (progress) => {
    const { playedSeconds } = progress;
    data.forEach(({ id, seconds = [0, 0], title }, key) => {
      const start = seconds[0]; const end = seconds[1]
      if (playedSeconds > start && playedSeconds < end) {
        setTitle(title);
        setCurrentSection(id);
      }
    })
    onProgress(playedSeconds)
  }

  const playFromSection = (id) => {
    const key = data.findIndex(itm => itm.id === id)
    const { seconds = [0, 0] } = data[key];
    const start = seconds[0];
    ref.current.seekTo(start);
    setCurrentSection(id)
    setTimeout(() => {
      setIsShowingVideo(true)
      setIsPlaying(true)
      setReplayPrevious(false)
    }, 100);
  }

  React.useEffect(() => {
    if (currentSection !== skipSectionPause) {
      console.log("Section change", currentSection);
      const key = data.findIndex(itm => itm.id === currentSection)

      if (!currentAutoPlayValue) {
        if (data[key]['action'] === VideoActions.MINIMIZE) {
          setIsPlaying(false);
          setIsShowingVideo(false);
          setReplayPrevious(true);
          // setCurrentSection(data[key - 1]['id']); // in case they want to replay the step
        }
      }

      onSectionChange(data[key])
    }
  }, [currentSection])

  React.useEffect(() => {
    const key = data.findIndex(itm => itm.id === currentSection)
    if (isPlaying) onPlay(data[key])
    if (!isPlaying) onPause(data[key])
  }, [isPlaying])

  return (
    <div data-testid="VideoTutorial">
      <div
        data-testid="VideoTutorial-VIDEOPLAYER"
        style={{
          ...styles.videoContainer,
          ...!isShowingVideo ? { display: 'none' } : {}
        }}
      >
        <ReactPlayer
          url={src}
          width="100%"
          height="100%"
          playing={isPlaying}
          config={videoConfig}
          onProgress={handleProgress}
          onStart={() => {
            if (sectionToPlay) playFromSection(sectionToPlay)
          }}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
          onEnded={() => onComplete(data[data.findIndex(itm => itm.id === currentSection)])}
          onSeek={(seconds) => {
            const key = data.findIndex(itm => itm.seconds[0] < seconds && itm.seconds[1] > seconds);
            setSkipSectionPause(data[key]['id'])
          }}
          ref={ref}
        />
        {/* <div style={{ textAlign: 'center', color: 'white' }}>{currentSection} | {skipSectionPause}</div> */}
      </div>

      <div style={styles.container}>

        <div data-testid="VideoTutorial-Title" style={styles.title}>{title}</div>

        <BigButton data-testid="VideoTutorial-BigButton" style={styles.playButton}>
          {!isPlaying && <Icon style={styles.playButtonIcon} type={IconTypes.PlayArrow} onClick={() => {
            setIsPlaying(true);
            setIsShowingVideo(true);
            setReplayPrevious(false);
          }} />}
          {isPlaying && <Icon style={styles.playButtonIcon} type={IconTypes.Pause} onClick={() => { setIsPlaying(false); setIsShowingVideo(false); }} />}
        </BigButton>

        <div style={styles.sectionWrapper}>

          {data.map((section, key) => (
            <Tooltip
              key={key}
              title={section['title']}
              placement="top"
            >
              <SectionCircle
                isActive={key <= data.findIndex(({ id }) => currentSection === id)}
                data-testid="VideoTutorial-Section"
                onClick={() => playFromSection(section['id'])}
              />
            </Tooltip>
          ))}

          <button style={styles.replayButton} data-testid="VideoTutorial-ReplayButton" onClick={() => {
            const key = data.findIndex(itm => itm.id === currentSection)
            if (replayPrevious) playFromSection(data[key - 1]['id']);
            else playFromSection(currentSection)

            setReplayPrevious(false);
          }}>{replayPrevious ? `Replay Previous` : `Replay Step`}</button>

          <Tooltip title="Auto Play: Don't Pause Video">
            <Switch
              checked={currentAutoPlayValue}
              onChange={handleChange}
              color="default"
            />
          </Tooltip>
          <div data-testid="VideoTutorial-CloseButton" onClick={() => onClose(data[data.findIndex(itm => itm.id === currentSection)])}>
            <Icon style={styles.closeButton} type={IconTypes.Close} />
          </div>
        </div>
      </div>

      {isShowingVideo && <div
        style={styles.background}
        onClick={() => {
          setIsPlaying(false);
          setIsShowingVideo(false);
        }}
      />
      }

    </div>
  )
}

export default VideoTutorial;