import moment from "moment";
import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import {
  LinkDisplayTypes,
  LinkLayoutStyles,
} from "@launchos/plugins/webcomponents/v2/Navigation/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/live";
import {
  faFacebookF,
  faGoogle,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FooterLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FooterLayouts.SIX,
  layout: FooterLayouts.SIX,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/footers/Footer06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzoubo42irl0780q5gtpfk3",
    itemId: "ppncsoapqel",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                src: theme.logoIcon,
                style: { width: 60 },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: `© ${moment().format("YYYY")} All Rights Reserved`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      display: "block",
                    },
                    smartphone: {
                      display: "none",
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Pricing",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Pricing",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Pricing",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
