import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: ContentLayouts.FOURTEEN,
  layout: ContentLayouts.FOURTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/content/Content14.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyb60t929qm07804oando29",
    itemId: "0ifihvpaf09g",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CONTENT,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: false,
        properties: {
          padding: 50,
          mobile: {
            desktop: { padding: 50 },
            smartphone: { padding: 5 },
          },
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: false,
                    properties: {
                      padding: 40,
                      mobile: {
                        desktop: { padding: 40 },
                        smartphone: { padding: 5 },
                      },
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Secondary Point",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          ...get(content, "sub_headline.style", {}),
                        },
                        html: get(content, "sub_headline.html", "..."),
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    src:
                      "https://images.unsplash.com/photo-1547250098-7fa6606002ac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
                    style: {
                      height: "100%",
                      // backgroundColor: theme.backgroundColor,
                      width: "100%",
                      // marginTop: 100,
                    },
                  }),
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: false,
                    properties: {
                      padding: 40,
                      mobile: {
                        desktop: { padding: 40 },
                        smartphone: { padding: 5 },
                      },
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Topical Headline",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          ...get(content, "sub_headline.style", {}),
                        },
                        html: get(content, "sub_headline.html", "..."),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
