import { CSSProperties } from "react";

export const style: {
  selectField: CSSProperties;
  menuItem: CSSProperties;
  menuItemLabel: CSSProperties;
  slugField: CSSProperties;
} = {
  selectField: { margin: "0 10px", border: "none", height: 50, zoom: 0.95 },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  menuItemLabel: {
    fontSize: 14,
  },
  slugField: {
    marginTop: -2,
    marginLeft: 10,
    fontSize: 10,
    background: "#EEE",
    color: "#666",
    padding: 3,
    borderRadius: 4,
    float: "right",
  },
};
