import * as React from "react";

interface EmptyStateComponentProps {
  /**
   * The message to display
   */
  message?: React.ReactNode;

  /**
   * The empty state image to display next to the message
   */
  image?: React.ReactNode;

  /**
   * The content to display below the empty state (e.g. an action button)
   */
  children?: React.ReactNode;
}

/**
 * Shows an empty state message, image, and optional action item
 */

const EmptyStateComponent: React.FC<EmptyStateComponentProps> = ({
  message,
  image,
  children,
}: EmptyStateComponentProps) => {
  return (
    <div data-testid="EmptyState-Default">
      <div data-testid="EmptyState-Message">{message}</div>
      <div data-testid="EmptyState-Image">{image}</div>
      <div data-testid="EmptyState-Children">{children}</div>
    </div>
  );
};

EmptyStateComponent.defaultProps = {
  image: (
    <img
      style={{ width: "80%", margin: "0 auto" }}
      src="https://uploads.toptal.io/blog/image/128038/toptal-blog-image-1548744925019-395cde9862cfed376f3dfb7c907604d6.jpg"
    />
  ),
};

export default EmptyStateComponent;
