import PropTypes from "prop-types";
import React from "react";
import { ObjectPreview, Section } from "..";

const style = {
  preset: {
    border: "1px solid #CCC",
    padding: 10,
    display: "inline-block",
    cursor: "pointer",
    width: "50%",
    boxSizing: "border-box"
  }
};

/**
 * This is an individual preset
 */
export const Preset = props => {
  const { onSelect } = props;

  return (
    <div style={style.preset} onClick={onSelect}>
      <ObjectPreview hideLabel isExpanded>
        {props.children}
      </ObjectPreview>
    </div>
  );
};

Preset.propTypes = {
  /** This is the ?liveComponent that will be rendered live when published */
  children: PropTypes.element.isRequired
};

Preset.defaultProps = {};

/**
 * Show's a collection of pre-built components with various setting combinations applied.  If more presets exist than can fill the viewport, show in a carousel
 */
export const Presets = props => {
  const {
    presets,
    settings,
    component,
    isExpanded,
    updateComponentStyle
  } = props;

  const Obj = component;

  const doChange = (id, updatedSettings) => {
    updateComponentStyle(settings.id, updatedSettings.properties);
    props.onChange(id);
  };

  return (
    <Section label="Presets" icon="refresh" isExpanded={isExpanded}>
      <div style={{ overflowY: "scroll", maxHeight: 400 }}>
        {presets.map((itm, key) => {
          const updatedSettings = {
            ...settings,
            properties: {
              ...settings.properties,
              ...itm.properties
            }
          };
          return (
            <Preset
              key={key}
              onSelect={() => doChange(itm.id, updatedSettings)}
              {...props}
            >
              <Obj
                {...props}
                settings={{
                  ...updatedSettings,
                  width: "100%",
                  cursor: "pointer",
                  zoom: 0.6
                }}
              />
            </Preset>
          );
        })}
      </div>
    </Section>
  );
};

Presets.propTypes = {
  presets: PropTypes.arrayOf(PropTypes.object),
  // component: PropTypes.elem,
  settings: PropTypes.objectOf(PropTypes.any)
};
Presets.defaultProps = {
  component: props => <div {...props}>testing</div>,
  settings: {
    id: 123,
    html: "Testing 123",
    properties: {
      color: "red"
    }
  },
  presets: [
    {
      id: "novascotia1",
      properties: {
        backgroundColor: "green"
      }
    },
    {
      id: "novascotia2",
      properties: {
        backgroundColor: "blue"
      }
    },
    {
      id: "novascotia3",
      properties: {
        backgroundColor: "purple"
      }
    }
  ]
};

export default Presets;
