import * as React from "react";
import gravatar from "gravatar";
import { Link } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { Avatar } from "@launchos/components/ui";

const style: {
  divider: React.CSSProperties;
  container: React.CSSProperties;
} = {
  divider: {
    borderTop: "1px solid white",
    height: 1,
    borderBottom: "1px solid #CCC",
  },
  container: { padding: "0 5px", cursor: "pointer" },
};

export const AvatarButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={style.container}>
      <div
        aria-controls="avatar_menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar
          src={gravatar.url(localStorage.email)}
          role="presentation"
          alt="Your Avatar"
        />
      </div>

      <Menu
        id="avatar_menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to="/settings/profile">
          <MenuItem>Update My Profile</MenuItem>
        </Link>
        <Link to="/settings/domains">
          <MenuItem>Manage My Domains</MenuItem>
        </Link>
        <Link to="/settings/integrations">
          <MenuItem>3rd Party Integrations</MenuItem>
        </Link>
        <div style={style.divider} />
        <Link to="/login">
          <MenuItem>Sign Out</MenuItem>
        </Link>
      </Menu>
    </div>
  );
};

export default AvatarButton;
