import * as React from "react";

import { TextInput } from "@launchos/components/ui";

import { StringValueProps } from "./types";

export const StringValue: React.FC<StringValueProps> = ({
  value = "",
  onChange = () => null,
  onBlur = () => null,
}) => {

  return (
    <div data-testid="StringValue-LIVE">
      {/* <input type="text" value={value} onChange={e => onChange(e.target.value)} onBlur={e => onBlur(e.target.value)} /> */}

      <TextInput
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        onBlur={e => onBlur(e.target.value)}
      />
    </div>
  );
};

export default StringValue;
