import { MobileState } from "./MobileResponsiveToggle/types";

export const getCanvasWrapperStyle = (mobileState) => {
  const { type } = mobileState;

  const overflow =
    type === MobileState.TABLET || MobileState.SMARTPHONE ? "auto" : "inherit";

  return {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "white",
    height: "100%",
    overflow,
  };
};

export default {
  pageWrap: {
    height: "100%",
  },
  addNewButton: {
    position: "fixed",
    zIndex: 300,
    top: 70,
    left: 100,
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)",
  },
};
