import { has } from 'lodash';

/**
 * Return the properties for a component based on the provided mobile type 
 * @param settings The settings for the component
 * @param currentMobileType The current mobile type
 */
export const getResponsiveSettings = (settings, { type }) => {
    const { properties = false } = settings

    if (has(properties, "mobile")) {
        
        const { fullscreen = {}, desktop = {}, tablet = {}, smartphone = {} } = properties.mobile;

        // Determine the priority (smallest device to largest)
        switch(type) {
            case "smartphone": {
                const properties = {
                    ...settings.properties, 
                    ...fullscreen, ...desktop, ...tablet, ...smartphone,
                }
                return { ...settings, properties }
            }
            case "tablet": {
                const properties = {
                    ...settings.properties, 
                    ...smartphone, ...fullscreen, ...desktop, ...tablet,
                }
                return { ...settings, properties }
            }
            case "desktop": {
                const properties = {
                    ...settings.properties, 
                    ...smartphone, ...tablet, ...fullscreen, ...desktop,
                }
                return { ...settings, properties }
            }
            case "fullscreen": {
                const properties = {
                    ...settings.properties, 
                    ...smartphone, ...tablet, ...desktop, ...fullscreen,
                }
                return { ...settings, properties }
            }
        }
    }

    return settings;
};
