import theme from '@launchos/components/ui/theme'

const style = {
  addButton: {
    position: "absolute",
    marginTop: -30,
    marginLeft: 20
  },
  sectionPanel: {
    width: "100%",
    borderRadius: 3,
    backgroundColor: "#EEE",
    marginBottom: 5
  },
  sectionContent: {
    border: "1px solid #DDD",
    backgroundColor: "white",
    boxShadow: "0px 28px 45px 0px rgba(0,0,0,0.17)"
  },
  sectionCircle: {
    borderRadius: 10,
    background: "#CCC",
    padding: 6,
    margin: 3,
    display: "inline-block"
  },
  slugText: {
    color: "#555",
    padding: 6,
    ":hover": {
      background: "#EEE"
    }
  },
  slugInput: { width: 225, margin: 10 },
  slugSpan: { fontSize: 13, color: "#666" },
  sidebarContainer: {
    backgroundColor: theme.darkColor,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 200,
  },
  // pageWrap: {
  //   minHeight: "100vh",
  //   backgroundColor: "#F0F0F0",
  //   // backgroundColor: `rgb(28,28,28)`,
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  // }
};

export const getPageWrapStyle = ({ darkMode = false }) => {

  // send message to iframe to get dark mode value
  // const darkModeCheckResponse = window.parent.postMessage({ type: "getDarkMode" }, "*");
  // console.log("getPageWrapStyle message was called", { darkModeCheckResponse });

  return {
    minHeight: "100vh",
    backgroundColor: darkMode ? 'theme.darkColor' : "#F0F0F0",
    // backgroundColor: darkMode ? `rgb(28,28,28)` : "#F0F0F0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }
}

export const getMainContainerStyle = (mobileState, show, { darkMode = false, containerStyle = {} }) => {
  // console.log("getMainContainerStyle was called", { darkMode });

  return ({
    borderRadius: 3,
    height: "100%",
    backgroundColor: "white",
    boxShadow: "5px 5px 25px 0 rgba(46,61,73,.2)",
    width: show === "templates" ? "90%" : 1000,
    margin: "120px auto",
    ...mobileState.dimensions,
    maxWidth:
      show === "templates"
        ? "90%"
        : mobileState.dimensions.maxWidth || "unset",
    ...containerStyle,
  })
}

export default style;
