import { CSSProperties } from "react";

const style: { span: CSSProperties } = {
  span: {
    cursor: "text",
    display: "inline-block",
  },
};

export default style;
