import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: `rgb(0,0,0,0.55)`,
    backgroundImage:
      "url(https://images.unsplash.com/photo-1502890269230-32cef9d08b2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1491&q=80)",
    backgroundSize: "cover",
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 50,
    mobile: {
      desktop: { padding: 50 },
      smartphone: { padding: 30 },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "26pt",
    fontFamily: "Roboto",
    fontWeight: "bold" as "bold",
    marginTop: 0,
    mobile: {
      desktop: { fontSize: "26pt", marginTop: 20 },
      smartphone: { fontSize: "18pt", marginTop: 0 },
    },
  },
  SUBHEADLINE: {
    textAlign: "center",
    fontSize: "20pt",
    fontFamily: theme.fontFamily,
    color: theme.foregroundColor,
    // fontWeight: "bold" as "bold",
    marginTop: 30,
    mobile: {
      desktop: { fontSize: "20pt", marginTop: 30, lineHeight: "26pt" },
      smartphone: { fontSize: "12pt", marginTop: 10, lineHeight: "16pt" },
    },
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    // lineHeight: "18px",
    padding: "20px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      marginTop: 20,
      // padding: "10px 75px",
      padding: 10,
      border: "none",
      background: theme.foregroundColor,
      // width: "100%",
      borderRadius: 3,
      cursor: "pointer",
      textAlign: "center",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "14pt",
    },
  },
};
