import convert from "color-convert";

/**
 * Converts a color value (of source type hex or rgb) to another (right now only rgb)
 */
export const doColorConversion = (colorValue, type = "rgb") => {
    if (!colorValue || colorValue === "") return "";

    const extractRgb = (str) => {
      return str
        .replace("rgb(", "")
        .replace(")", "")
        .replace(/ /g, "")
        .split(",");
    };

    // Detect what type of color is coming in a hex color or an rgb
    let sourceType = "keyword";
    if (colorValue.indexOf("#") > -1) sourceType = "hex";
    if (colorValue.indexOf("rgb") > -1) sourceType = "rgb";

    if (type === "rgb") {
      switch (sourceType) {
        case "rgb":
          return {
            r: parseFloat(extractRgb(colorValue)[0]),
            g: parseFloat(extractRgb(colorValue)[1]),
            b: parseFloat(extractRgb(colorValue)[2]),
            a: parseFloat(extractRgb(colorValue)[3]) || 1,
          };
        default: {
          const conversion = convert[sourceType].rgb(colorValue);
          if (conversion) {
            return {
              r: parseFloat(conversion[0]),
              g: parseFloat(conversion[1]),
              b: parseFloat(conversion[2]),
              a: parseFloat(conversion[3]) || 1,
            };
          } else return false;
        }
      }
    } else if (type === "hex") {
      switch(sourceType) {
        case "rgb": {
          const cv = extractRgb(colorValue)
          return `#${convert[sourceType].hex(cv)}`
        }
        default: {
          return colorValue;
        }
      }
    } else if (type === "hsl") {
      switch(sourceType) {
        case "rgb": {
          const cv = extractRgb(colorValue);
          const hsl = convert[sourceType].hsl(cv)
          return {
            h: hsl[0],
            s: hsl[1],
            l: hsl[2],
            a: parseFloat(cv[3]) || 1,
          }
        }
        default: {
          return colorValue;
        }
      }
    }
  }