export const renameObject = ({ updateComponentSettings, settings }) => {
  const label = prompt(
    "What would you like to name this element?",
    _.get(settings, "label", "")
  );
  if (label) {
    console.log({ label }, { settings });
    updateComponentSettings(
      settings.id,
      {
        ...settings,
        label,
      },
      true,
      false
    );
  }
};
