import { Button, TextInput } from "@launchos/components/ui";
import _ from "lodash";
import React, { Component } from "react";
import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

export default class SEO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      keywords: "",
      description: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
  }

  async componentDidMount() {
    const { getPageQuery, match } = this.props;

    const pageId = match.params.nodeId;
    const { data } = await getPageQuery.refetch({ pageId });

    console.log({ data });
    const { pageSettings } = data.page;

    this.setState({
      ..._.get(pageSettings, "seo", {})
    });
    // console.log({ data });
  }

  handleChange(key, value) {
    this.setState({
      [key]: value
    });
  }

  async saveSettings() {
    const { updatePageSettings, getPageQuery, history, match } = this.props;
    const { title, keywords, description } = this.state;

    const { pageSettings, id } = getPageQuery.page;

    const variables = {
      pageId: id,
      pageSettings: {
        ...pageSettings,
        seo: {
          title,
          keywords,
          description
        }
      }
    };

    await updatePageSettings({ variables });

    history.push(
      `/campaign/${match.params.campaignId}/${match.params.nodeId}/builder`
    );
  }

  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: 50 }}>
          SEO Settings
          {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'SEO' })}
        </h1>
        <h2 style={{ textAlign: "center" }}>
          What should the search results show when someone
          <br />
          uses a search engine to find your page?
        </h2>
        <TextInput
          label="Page Title"
          value={this.state.title}
          onChange={event => {
            this.handleChange("title", event.target.value);
          }}
        />
        <TextInput
          label="Page Keywords"
          value={this.state.keywords}
          onChange={event => {
            this.handleChange("keywords", event.target.value);
          }}
        />
        <TextInput
          label="Page Description"
          value={this.state.description}
          multiline
          style={{ height: 100 }}
          onChange={event => {
            this.handleChange("description", event.target.value);
          }}
        />
        <div style={{ padding: 20, textAlign: "center" }}>
          <Button primary large onClick={this.saveSettings}>
            Save Settings
          </Button>
        </div>
      </div>
    );
  }
}
