import {
  Button,
  Cell,
  FontIcon,
  Grid,
  ListItem,
  Menu,
  Toolbar,
} from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import {
  ComponentRenderHooks,
  ListItemsHooks,
  HookTriggers,
} from "@launchos/plugins/types";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppDrawer from "../../appdrawer";
import { toggleAppDrawer } from "./functions";
import { leftActionsGroup, rightActionsGroup } from "./HeaderComponents";
import Title from "./RenamableTitle";
import style from "./style";
import { triggerHook } from "@launchos/plugins/client";

class CampaignInfo extends Component {
  render() {
    const {
      campaign,
      firstBuildableObject,
      hidden,
      renameCampaign,
    } = this.props;
    if (!hidden) {
      return (
        <Grid justify="space-between" style={style.bigToolbar.inner}>
          <Cell style={style.bigToolbar.cell}>
            <h4>Welcome to:</h4>
            <Title
              name={campaign.name}
              showIcon
              onChange={renameCampaign}
              style={{
                fontSize: 24,
                lineHeight: "32px",
                color: "rgba(0, 0, 0, 0.87)",
                marginLeft: -7,
                fontFamily: `'Open Sans', sans-serif`,
                fontWeight: 300,
                textAlign: "left",
              }}
            />
            <h4>{campaign.description}</h4>
          </Cell>
          <Cell align="center">
            {firstBuildableObject ? (
              <div style={style.bigToolbar.cell}>
                <Link
                  to={`/campaign/${campaign.id}/${firstBuildableObject.page.id}/builder`}
                >
                  <Button primary="true" large style={style.bigToolbar.button}>
                    Start Building
                  </Button>
                </Link>
              </div>
            ) : null}
          </Cell>
        </Grid>
      );
    }
    return null;
  }
}

const CampaignSettingsMenu = (props) => {
  const { campaign } = props.getCampaignQuery;
  const { getCampaignState, setCampaignState } = props;

  const menuItems = triggerHook(
    HookTriggers.onListItems,
    { id: ListItemsHooks.CAMPAIGN_SETTINGS_MENU },
    {},
    { getCampaignState, setCampaignState }
  );

  const user = JSON.parse(sessionStorage.getItem("user"));
  const isAdmin = _.get(user, "permissions", []).indexOf("admin") > -1

  return (
    <Menu label="Campaign Settings" style={{ cursor: "pointer", width: 250, marginRight: props.show === "legend" ? 0 : 40 }}>
      {campaign ? (
        <React.Fragment>
          {props.show === "legend" ? (
            <Link to={`/campaign/${campaign.id}/builder`}>
              <ListItem>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FontIcon>account_tree</FontIcon>
                  <div style={{ padding: "0px 10px" }}>
                    Open Drag-and-Drop Builder
                  </div>
                </div>
              </ListItem>
            </Link>
          ) : (
            <Link to={`/campaign/${campaign.id}/edit`}>
              <ListItem>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FontIcon>dns</FontIcon>
                  <div style={{ padding: "0px 10px" }}>Open Simple Builder</div>
                </div>
              </ListItem>
            </Link>
          )}
          <Link to={`/campaign/${campaign.id}/tracking`}>
            <ListItem>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FontIcon>code</FontIcon>
                <div style={{ padding: "0px 10px" }}>
                  Campaign Tracking Codes
                </div>
              </div>
            </ListItem>
          </Link>
          {_.map(menuItems, (itm, key) => (
            <ListItem key={key} onClick={_.get(itm, "onSelect", () => null)}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {_.has(itm, "icon") && <FontIcon>{itm.icon}</FontIcon>}
                <div style={{ padding: "0px 10px" }}>{itm.caption}</div>
              </div>
            </ListItem>
          ))}
          <Link to={`/campaign/${campaign.id}/domain`}>
            <ListItem>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FontIcon>dns</FontIcon>
                <div style={{ padding: "0px 10px" }}>Connect a Domain Name</div>
              </div>
            </ListItem>
          </Link>
          {/* {isAdmin && (
            <ListItem onClick={async () => {

              const url = prompt("URL for Niche Funnel Data");
              // write to campaign meta data
              console.log({ url })
            }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FontIcon>construction</FontIcon>
                <div style={{ padding: "0px 10px" }}>Update Niche Funnel Data</div>
              </div>
            </ListItem>
          )} */}
        </React.Fragment>
      ) : (
        <ListItem />
      )}
    </Menu>
  );
};

class CampaignHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appDrawerVisible: false,
      settingsTabDropdownVisible: false,
      settings: false,
      percentage: 0,
      count: 0,
      domainName: props.domainName,
      urlHover: false,
    };

    this.toggleAppDrawer = toggleAppDrawer.bind(this);
    this.renameCampaign = this.renameCampaign.bind(this);
  }

  static contextType = AppConfig;

  componentDidUpdate(prevProps) {
    if (prevProps.domainName !== this.props.domainName) {
      const { domainName } = this.props;
      this.setState({ domainName });
    }
  }

  renameCampaign(name) {
    this.props.updateCampaignMutation({
      variables: {
        id: this.props.match.params.campaignId,
        name,
      },
    });
  }

  render() {
    const { app } = this.context;
    const { campaign } = this.props.getCampaignQuery;
    const {
      show,
      activeObject,
      getCampaignState,
      setCampaignState,
      getUserQuery
    } = this.props;

    const { subdomain } = getUserQuery.user;
    const objects = _.get(campaign, "objects", []);
    const useObjects = objects.filter(
      (itm) => itm.type === "PageComponent" && itm.page
    );
    const firstBuildableObject = useObjects.length ? useObjects[0] : false;
    let firstUrl = this.state.domainName ? `http://${this.state.domainName}` : false;
    if (firstUrl && firstUrl.indexOf(subdomain) > -1) firstUrl = firstUrl.replace('http://', "https://");

    const aStyle = {
      textTransform: "lowercase",
      color: "#666",
      textDecoration: this.state.urlHover ? "underline" : "normal",
    };

    const getComponents = triggerHook(
      HookTriggers.onComponentRender,
      { id: ComponentRenderHooks.CAMPAIGN_SETTINGS_TOOLBAR },
      { ...campaign, show },
      { getCampaignState, setCampaignState }
    );

    const logo = app.showToolbarLogo
      ? [
        () => (
          <Link to="/dashboard/campaigns">
            <img
              alt={`${app.name} Logo`}
              src={app.logoBlack}
              style={{ height: 35 }}
            />
          </Link>
        ),
      ]
      : [];

    return (
      <div style={style.bigToolbar.main}>
        <AppDrawer
          hidden={!this.state.appDrawerVisible}
          toggleAppDrawer={this.toggleAppDrawer}
        />
        <Toolbar
          leftActionsGroup={[...leftActionsGroup, ...logo]}
          title={
            show !== "legend" ? (
              <Title
                name={_.get(campaign, "name")}
                showIcon
                onChange={this.renameCampaign}
              />
            ) : null
          }
          rightActionsGroup={rightActionsGroup}
          toggleAppDrawer={this.toggleAppDrawer}
          onPublish={this.props.onPublish}
          noPreview
          theme="light"
        />

        {show === "legend" ? (
          <CampaignInfo
            {...this.props}
            renameCampaign={this.renameCampaign}
            firstBuildableObject={firstBuildableObject}
            campaign={campaign}
          />
        ) : null}

        <div style={{ ...style.bigToolbar.innerInfoBar }}>
          <Grid
            justify="space-between"
            style={{
              ...style.bigToolbar.inner,
              ...{ padding: show === "legend" ? "0 50px" : "0px 0 0 90px" },
              maxWidth: show === "legend" ? 1000 : "100%",
            }}
          >
            <Cell>
              <h5 style={{ paddingBottom: 0 }}>
                {firstUrl ? (
                  <div>
                    {activeObject ? (
                      <a
                        href={`${firstUrl}/${_.get(
                          activeObject,
                          "page.slug",
                          ""
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={aStyle}
                      >
                        {firstUrl}/{_.get(activeObject, "page.slug")}
                        <FontIcon
                          style={{
                            zoom: 0.6,
                            marginLeft: 10,
                            position: "relative",
                            top: 4,
                            color: "#666",
                          }}
                        >
                          launch
                        </FontIcon>
                      </a>
                    ) : (
                      <div
                        style={{ display: "inline" }}
                        onMouseEnter={() => this.setState({ urlHover: true })}
                        onMouseLeave={() => this.setState({ urlHover: false })}
                      >
                        <Link
                          to={`/campaign/${_.get(campaign, "id")}/domain`}
                          style={aStyle}
                        >
                          {firstUrl}
                        </Link>

                        {this.state.urlHover && _.has(campaign, "id") ? (
                          <Link to={`/campaign/${campaign.id}/domain`}>
                            <FontIcon
                              style={{
                                zoom: 0.5,
                                marginLeft: 10,
                                marginTop: 5,
                              }}
                            >
                              edit
                            </FontIcon>
                          </Link>
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : null}
              </h5>
            </Cell>
            <Cell style={{ padding: 5 }}>
              <Grid>
                {getComponents.length ? (
                  <React.Fragment>
                    {getComponents.map((elem, key) => (
                      <Cell key={key}>{React.createElement(elem, {})}</Cell>
                    ))}
                  </React.Fragment>
                ) : null}
                <Cell>
                  <CampaignSettingsMenu {...this.props} />
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}

export default CampaignHeader;
