import React from "react";
import ReactJson from "react-json-view";
import { get } from 'lodash';

export default (props) => (
  // <div />
  <ReactJson
    onEdit={(options) => {
      console.log({ options });

      props.updateComponentSettings(
        props.settings.id,
        options.updated_src,
        true,
        false
      );
    }}
    onAdd={(options) => console.log({ options })}
    onDelete={(options) => console.log({ options })}
    theme="monokai"
    src={props.settings}
    collapsed
    indentWidth={2}
    style={{
      fontSize: 10,
      textAlign: 'left'
    }}
    name={get(props, 'settings.type')}
    displayDataTypes={false}
    displayObjectSize={false}
    sortKeys
    quotesOnKeys={false}
  />
);
