import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 50,
    mobile: {
      desktop: { padding: 50 },
      smartphone: { padding: 10 },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,
    mobile: {
      desktop: { fontSize: "32pt" },
      smartphone: { fontSize: "22pt" },
    },
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      // padding: "15px 75px",
      padding: 15,
      width: 500,
      mobile: {
        desktop: { width: 500 },
        smartphone: { width: "100%" },
      },
      border: "none",
      backgroundColor: theme.backgroundColor,
      // minWidth: 125,
      // width: "100%",
      borderRadius: 3,
      cursor: "pointer",
      margin: "50px auto 0",
      textAlign: "center",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "16pt",
    },
    caption2: {
      color: theme.foregroundColor,
      opacity: 0.5,
      // fontWeight: "bold" as "bold",
      fontSize: "12pt",
    },
  },
};
