export default {
  mainContent: {
    maxWidth: 1360,
    minWidth: 1020,
    display: "flex"
  },
  campaignItem: {
    border: "1px solid #CCC",
    borderRadius: 3,
    height: 200,
    overflowY: "hidden",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.30)"
    }
    // ":hover": {
    //   boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.30)"
    // }
  },
  campaignItemHovering: {
    boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.30)"
  },
  campaignItemCell: { width: 300, marginRight: 40, marginBottom: 20 },
  campaignItemCaption: { padding: "0 10px", marginTop: -5 },
  campaignItemCaptionButton: { marginRight: -15 },
  divider: {
    borderTop: "1px solid white",
    height: 1,
    borderBottom: "1px solid #CCC"
  },
  selectField: {
    margin: 50,
    padding: 10,
    color: "#666",
    border: "1px solid #DDD",
    borderRadius: 3,
    cursor: "pointer"
  }
};
