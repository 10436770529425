import { Button, Cell, Grid, Snackbar } from "@launchos/components/ui";
import { FixedPositions } from "@launchos/components/ui/types";

import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import slugify from "slugify";
import { style } from "../shared";
import CampaignLegendObject from "./CampaignLegendObject";
import CloneObjectPopup from "./CloneObjectPopup";

class CampaignLegend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemDrawerVisible: false,
      propertiesDrawerVisible: false,
      showCloneObjectPopup: false,
      activeObject: {},
      snackBarIsShowing: false,
      snackBarMessage: "Success!",
      snackBarType: "success",
    };

    this.handleCreatePage = this.handleCreatePage.bind(this);
  }

  static contextType = AppConfig;

  componentDidMount() {
    localStorage.setItem("builderType", "legend");
    console.log("I AM LEGEND!!!");
  }

  handleCreatePage = async (e) => {
    // e.stopPropagation();
    const settings = this.context;

    const {
      campaignContent,
      createPageMutation,
      createCampaignObjectMutation,
      updateCampaignObjectMutation,
      getCampaignQuery,
      match,
      history,
    } = this.props;

    const theObjects = campaignContent.filter(
      (itm) => itm.type === "PageComponent" && itm.page
    );
    const useObjects = _.orderBy(theObjects, "x", "asc");

    const lastObject = useObjects.length
      ? useObjects[useObjects.length - 1]
      : false;

    // 1. create a blank page and give me a page id;
    const name = window.prompt(
      "What would you like to name this page?",
      "Untitled Page"
    );
    if (!name) return false;

    const slug = slugify(name.toString().toLowerCase(), { remove: /[*+~.()'"!:@]/g });

    const pageVariables = {
      name,
      slug: `${slug}-${Math.random()
        .toString(36)
        .substr(2, 2)}`,
      screenshot: "",
      theme: "all",
      type: "all",
      content: settings.pages.blankPage,
      userId: localStorage.userId,
    };

    console.log({ pageVariables });

    const { data } = await createPageMutation({ variables: pageVariables });
    console.log({ data });

    // 2. create a new object in the campaign and connect the page to it
    const objectVariables = {
      campaignId: getCampaignQuery.campaign.id,
      name,
      screenshot: "",
      // connectToIds: [],
      x: lastObject ? lastObject.x + 280 : 200,
      y: lastObject ? lastObject.y : 200,
      deleted: false,
      type: "PageComponent",
      pageId: data.createPage.id,
      settings: {
        parent: false,
        style: false,
        pageId: data.createPage.id,
        properties: false,
      },
    };

    console.log({ objectVariables });

    const newObjectCreation = await createCampaignObjectMutation({
      variables: objectVariables,
    });

    console.log({ newObjectCreation });

    // 3. connect that new object to the last object in the campaign
    if (lastObject) {
      const connectToIds = lastObject.connectTo.length
        ? [
          ...lastObject.connectTo,
          { id: newObjectCreation.data.createObject.id },
        ]
        : [{ id: newObjectCreation.data.createObject.id }];

      console.log({ connectToIds });

      const updateObjectCreation = await updateCampaignObjectMutation({
        variables: {
          name: lastObject.name,
          deleted: false,
          screenshot: lastObject.screenshot,
          id: lastObject.id,
          connectToIds: _.map(connectToIds, (itm) => ({ id: itm.id })),
          settings: lastObject.settings,
        },
      });

      // refetch pages (update cache)

      console.log({ updateObjectCreation });
    }

    await getCampaignQuery.refetch({ id: match.params.campaignId });

    history.push(
      `/campaign/${match.params.campaignId}/${data.createPage.id}/templates/all`
    );
  };

  render() {
    let i = 1;
    const { campaignContent } = this.props;
    const theObjects = campaignContent.filter(
      (itm) => itm.type === "PageComponent" && itm.page && !itm.deleted
    );
    const useObjects = _.orderBy(theObjects, "x", "asc");

    console.log({ campaignContent, theObjects, useObjects });

    return (
      <div>
        {useObjects.length ? (
          <Button
            style={{
              position: "fixed",
              zIndex: 300,
              boxShadow:
                "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)",
            }}
            icon
            secondary
            fixedPosition={FixedPositions.BOTTOM_RIGHT}
            onClick={this.handleCreatePage}
          >
            add
          </Button>
        ) : null}

        {this.state.showCloneObjectPopup && (
          <CloneObjectPopup
            {...this.props}
            settings={this.state.activeObject}
            onClose={() => this.setState({ showCloneObjectPopup: false })}
          />
        )}

        <Snackbar
          open={this.state.snackBarIsShowing}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackBarIsShowing: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          severity={this.state.snackBarType}
        >
          {this.state.snackBarMessage}
        </Snackbar>

        <div
          style={{
            ...style.bigToolbar.inner,
            marginTop: 250,
            zIndex: 0,
            position: "relative",
          }}
        >
          {useObjects.length ? (
            useObjects.map((obj) => (
              <CampaignLegendObject
                {...this.props}
                key={obj.id}
                index={i++}
                length={useObjects.length}
                settings={obj}
                useObjects={useObjects}
                showCloneObjectPopup={(settings) =>
                  this.setState({
                    showCloneObjectPopup: true,
                    activeObject: settings,
                  })
                }
                showSnackBar={(message, type = "success") => {
                  this.setState({
                    snackBarMessage: message,
                    snackBarType: type,
                    snackBarIsShowing: true,
                  });
                }}
              />
            ))
          ) : (
            <div style={{ marginTop: 220 }}>
              <Grid justify="center" align="center" style={{ marginTop: 80 }}>
                <Cell style={{ textAlign: "center" }} align="center">
                  <img
                    alt=""
                    style={{ display: "block", width: 500, marginBottom: 50 }}
                    src="https://s3.amazonaws.com/sandcastleassets/images/emptystate5.svg"
                  />
                  <Button onClick={this.handleCreatePage} secondary large>
                    Create Your First Page
                  </Button>
                </Cell>
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CampaignLegend;
