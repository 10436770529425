import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 50,
    mobile: {
      desktop: { padding: 50 },
      smartphone: { padding: 5 },
    },
  },
  HEADLINE: {
    fontSize: "24pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
  },
  TEXT: {
    // padding: 20,
    marginTop: 30,
    fontFamily: theme.fontFamily,
    fontSize: "11pt",
    lineHeight: "18pt",
  },
};
