import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "16pt",
    lineHeight: "18pt",
    // fontWeight: "bold" as "bold",
  },
  COUNTDOWN: {
    style: {
      margin: "0 auto",
      display: "inline-block",
    },
    dimensionStyle: {
      minWidth: 75,
    },
    numberStyle: {
      textAlign: "center",
      fontSize: "16pt",
      fontFamily: theme.fontFamily,
      fontWeight: "bold" as "bold",
      margin: "0 5px",
      display: "inline-block",
    },
    labelStyle: {
      fontWeight: "bold" as "bold",
      display: "inline-block",
      fontSize: "16pt",
      fontFamily: theme.fontFamily,
      textTransform: "lower-case",
    },
    dividerStyle: {
      fontWeight: "bold" as "bold",
      fontFamily: theme.fontFamily,
    },
  },
};
