import gql from "graphql-tag";

// allCatalogItems;
export const allCatalogItems = gql`
  query allCatalogItems {
    catalogItems(orderBy:rating_DESC) {
      id
      user {
        id
      }
      type
      image
      name
      video
      color
      skill
      isNew
      description
      link
      rating
      campaign {
        id
        name
        screenshot
        objects {
          id
          name
          screenshot
          type
          x
          y
          settings
        }
      }
      page {
        id
        name
      }
      earnings
      isRecommended
      isSpotlight
      tags
      categories
      createdAt
      updatedAt
    }
  }
`;

// createCatalogItem;
export const createCatalogItem = gql`
  mutation createCatalogItem(
    $userId: ID
    $name: String!
    $type: String
    $image: String
    $video: String
    $color: String
    $skill: String
    $partner: String
    $isNew: Boolean
    $description: String
    $link: String
    $rating: Float
    $campaignId: ID
    $earnings: Float
    $isRecommended: Boolean
    $isSpotlight: Boolean
    $tags: String
    $categories: String
  ) {
    createCatalogItem(
      data: {
        user: { connect: { id: $userId } }
        name: $name
        type: $type
        image: $image
        video: $video
        color: $color
        skill: $skill
        partner: $partner
        isNew: $isNew
        description: $description
        link: $link
        rating: $rating
        campaign: { connect: { id: $campaignId } }
        earnings: $earnings
        isRecommended: $isRecommended
        isSpotlight: $isSpotlight
        tags: $tags
        categories: $categories
      }
    ) {
      id
      name
    }
  }
`;

// deleteCatalogItem;
export const deleteCatalogItem = gql`
  mutation deleteCatalogItem($id: ID!) {
    deleteCatalogItem(where: { id: $id }) {
      id
    }
  }
`;

// deployCatalogItem;
export const deployCatalogItem = gql`
  mutation deployCatalogItem($id: ID!, $destination: String!, $userId: ID!) {
    deployCatalogItem(id: $id, destination: $destination, userId: $userId) {
      response
      statusCode
      message
    }
  }
`;

// updateCatalogItem;
export const updateCatalogItem = gql`
  mutation updateCatalogItem(
    $id: ID!
    $name: String!
    $type: String
    $image: String
    $video: String
    $color: String
    $skill: String
    $partner: String
    $isNew: Boolean
    $description: String
    $link: String
    $rating: Float
    $campaignId: ID
    # $pageId: ID
    $earnings: Float
    $isRecommended: Boolean
    $isSpotlight: Boolean
    $tags: String
    $categories: String
  ) {
    updateCatalogItem(
      where: { id: $id }
      data: {
        name: $name
        type: $type
        image: $image
        video: $video
        color: $color
        skill: $skill
        partner: $partner
        isNew: $isNew
        description: $description
        link: $link
        rating: $rating
        campaign: { connect: { id: $campaignId } }
        # page: { connect: { id: $pageId } }
        earnings: $earnings
        isRecommended: $isRecommended
        isSpotlight: $isSpotlight
        tags: $tags
        categories: $categories
      }
    ) {
      id
      name
    }
  }
`;

// allCatalogDesignItems;
export const allCatalogDesignItems = gql`
  query allCatalogDesignItems {
    catalogItems(where: { type: "designs" } orderBy: isSpotlight_DESC) {
      id
      user {
        id
      }
      type
      image
      name
      video
      color
      skill
      isNew
      description
      link
      rating
      page {
        id
        content
        type
      }
      earnings
      isRecommended
      isSpotlight
      tags
      categories
      createdAt
      updatedAt
    }
  }
`;
