import * as React from "react";
import Select from "react-select";
import { keys, head } from "lodash";

import { SelectFieldTypeProps } from "./types";
import { FormFieldTypes } from "../../types";
import styles from "../../styles";

import fieldNames from "../fieldNames";
import ComponentSelectorStor_ies from "../../../../Editor/components/ComponentSelector/ComponentSelector.stor_ies";

const allFieldNames = [
  ...fieldNames,
  {
    attributes: { name: false },
    label: "-----------------------",
  },
  {
    attributes: { name: "custom" },
    label: "Use a Custom Field Type",
  },
]

/**
 * Presents a way to select the type of form field.
 *
 * - Provides a number of common (prebuilt) choices (name/email/shipping)
 * - Lets the user create a customer one as well
 */
const SelectFieldType: React.FC<SelectFieldTypeProps> = ({
  type,
  preset,
  caption,
  onChange,
}) => {
  const [customFieldsAreShowing, setCustomFieldsAreShowing] = React.useState<
    boolean
  >(false);

  return (
    <div
      data-testid="FormBuilder-EditFormField-SelectFieldType"
      style={{ zIndex: 100 }}
    >
      {caption && <span>{caption}</span>}
      <Select
        defaultValue={head(
          allFieldNames.filter((itm) => itm.attributes.name === preset)
        )}
        options={allFieldNames.map((type) => ({
          ...type,
          value: type.attributes.name,
        }))}
        onChange={(e) => {
          if (e.value) {
            if (e.value !== "custom") {
              onChange(e.fieldType, e.value, e.attributes);
            } else {
              setCustomFieldsAreShowing(true);
            }
          }
        }}
      />

      {(customFieldsAreShowing || preset === "custom") && (
        <div style={styles.select.container}>
          <select
            style={styles.select.element}
            onChange={(e) =>
              onChange(e.target.value as FormFieldTypes, "custom")
            }
            value={type}
          >
            <option value={false}>Select Form Field ...</option>
            {keys(FormFieldTypes).map((itm, key) => (
              <option
                key={key}
                // selected={type === FormFieldTypes[itm]}
                value={FormFieldTypes[itm]}
              >
                {FormFieldTypes[itm]}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default SelectFieldType;
