import * as React from "react";
// import ReactJson from "react-json-view";

import { EditorMode } from "@launchos/modules/v2/Editor/types";
import { fieldset } from "@launchos/components/ui/theme";

import { EditFormFieldProps } from "./types";
import SelectFieldType from "./SelectFieldType";
import { FormFieldTypes } from "../types";

import {
  Checkboxes,
  ContentField,
  DateField,
  DropdownField,
  FileUpload,
  LinearScaleField,
  MultipleChoice,
  TextAreaField,
  TextField,
} from "../FormFields";
import LiveFormField from "../LiveFormField";

const Wrapper: React.FC<{
  style?: React.CSSProperties;
  children: React.ReactNode;
}> = ({ style = {}, children }) => {
  return <div style={style}>{children}</div>;
};

/**
 * This is the container to use as the sortable item in the SortableList Component
 *
 * - Used for managing the editing of individual form fields
 * - It's the element that gets dragged up and down
 * - Shows a dropdown for selecting the type of form field you want to edit
 * - Then the appropriate form field component is displayed in "edit" mode
 */
const EditFormField: React.FC<EditFormFieldProps> = (props) => {
  const {
    type,
    preset,
    fieldData,
    showPreview,
    showFieldset = false,
    isExpanded = false,
    caption,
    globalFieldStyle = {
      containerStyle: {},
    },
    onChange = () => { },
    onBlur = () => { },
    onTypeChange = () => { },
    // onClick,
    items,
  } = props;

  /**
   * Performs any cleanups/combining of data (if any) before triggering the onChange event
   * @params
   */
  const handleChange = (data): void => {
    // console.log({ data })
    onChange(data);
  };

  const { containerStyle } = globalFieldStyle;

  const SFT = (
    <SelectFieldType
      type={type}
      preset={preset}
      onChange={onTypeChange}
      caption={caption}
    />
  );

  const LFF = <LiveFormField {...props} />;

  const FS = (
    <>
      {isExpanded &&
        (type === FormFieldTypes.TEXT ||
          type === FormFieldTypes.PASSWORD ||
          type === FormFieldTypes.NUMBER ||
          type === FormFieldTypes.CURRENCY) && (
          <Wrapper style={containerStyle}>
            <TextField
              {...fieldData}
              mode={EditorMode.EDITOR}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Wrapper>
        )}
      {isExpanded && type === FormFieldTypes.DATE && (
        <Wrapper style={containerStyle}>
          <DateField
            {...fieldData}
            mode={EditorMode.EDITOR}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </Wrapper>
      )}
      {isExpanded &&
        (type === FormFieldTypes.SELECT ||
          type === FormFieldTypes.DROPDOWN) && (
          <Wrapper style={containerStyle}>
            <DropdownField
              {...fieldData}
              data={items}
              mode={EditorMode.EDITOR}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Wrapper>
        )}
      {isExpanded && type === FormFieldTypes.TEXTAREA && (
        <Wrapper style={containerStyle}>
          <TextAreaField
            {...fieldData}
            mode={EditorMode.EDITOR}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </Wrapper>
      )}
      {isExpanded && type === FormFieldTypes.CHECKBOXES && (
        <Wrapper style={containerStyle}>
          <Checkboxes
            {...fieldData}
            mode={EditorMode.EDITOR}
            onChange={handleChange}
            onBlur={onBlur}
            data={items}
          />
        </Wrapper>
      )}
      {isExpanded && type === FormFieldTypes.MULTIPLECHOICE && (
        <Wrapper style={containerStyle}>
          <MultipleChoice
            {...fieldData}
            mode={EditorMode.EDITOR}
            onChange={handleChange}
          />
        </Wrapper>
      )}
      {isExpanded &&
        (type === FormFieldTypes.CONTENT ||
          type === FormFieldTypes.TYPOGRAPHY) && (
          <Wrapper style={containerStyle}>
            <ContentField {...fieldData} mode={EditorMode.EDITOR} />
          </Wrapper>
        )}
      {isExpanded && type === FormFieldTypes.FILEUPLOAD && (
        <Wrapper style={containerStyle}>
          <FileUpload
            {...fieldData}
            mode={EditorMode.EDITOR}
            onChange={handleChange}
          />
        </Wrapper>
      )}
      {isExpanded && type === FormFieldTypes.LINEARSCALE && (
        <Wrapper style={containerStyle}>
          <LinearScaleField
            {...fieldData}
            mode={EditorMode.EDITOR}
            onChange={handleChange}
          />
        </Wrapper>
      )}
    </>
  );

  return (
    <div data-testid="FormBuilder-EditFormField" style={{ width: "100%" /* width: 800, position: "absolute", top: 20, left: 30, backgroundColor: 'white', padding: 80, zIndex: 100 */ }}>

      {showFieldset ? (
        <>
          <fieldset style={fieldset}>
            <legend>{caption || "Field Type"}</legend>
            {SFT}
          </fieldset>

          {isExpanded && showPreview && (
            <fieldset style={fieldset}>
              <legend>Field Preview</legend>
              {LFF}
            </fieldset>
          )}

          <fieldset style={fieldset}>
            <legend>Field Settings</legend>
            {FS}
          </fieldset>
        </>
      ) : (
        <>
          {SFT}
          {isExpanded && showPreview && LFF}
          {FS}
        </>
      )}
    </div>
  );
};

export default EditFormField;
