import {
  FancySelector,
  Icon,
  Section,
} from "@launchos/modules/editor/Builder/Properties";
import PropTypes from "prop-types";
import React, { Component } from "react";

export const alignVertical = [
  {
    id: "vertical_align_top",
    component: (props) => <Icon {...props} icon="vertical_align_top" />,
  },
  {
    id: "vertical_align_center",
    component: (props) => <Icon {...props} icon="vertical_align_center" />,
  },
  {
    id: "vertical_align_bottom",
    component: (props) => <Icon {...props} icon="vertical_align_bottom" />,
  },
];

export const alignTextHorizontal = [
  {
    id: "text_align_left",
    component: (props) => <Icon {...props} icon="align_horizontal_left" />,
  },
  {
    id: "text_align_center",
    component: (props) => <Icon {...props} icon="align_horizontal_center" />,
  },
  {
    id: "text_align_right",
    component: (props) => <Icon {...props} icon="align_horizontal_right" />,
  },
];

export const alignMarginHorizontal = [
  {
    id: "margin_align_left",
    component: (props) => <Icon {...props} icon="align_horizontal_left" />,
  },
  {
    id: "margin_align_center",
    component: (props) => <Icon {...props} icon="align_horizontal_center" />,
  },
  {
    id: "margin_align_right",
    component: (props) => <Icon {...props} icon="align_horizontal_right" />,
  },
];

class Alignment extends Component {
  handleChange(id) {
    const { updateComponentStyle, settings } = this.props;
    console.log("Handle Change", id);
    // alert(id);
    switch (id) {
      case "vertical_align_top":
        updateComponentStyle(
          settings.id,
          { justifyContent: "flex-start" },
          true,
          false
        );
        break;
      case "vertical_align_center":
        updateComponentStyle(
          settings.id,
          { justifyContent: "center" },
          true,
          false
        );
        break;
      case "vertical_align_bottom":
        updateComponentStyle(
          settings.id,
          { justifyContent: "flex-end" },
          true,
          false
        );
        break;
      case "text_align_left":
        console.log("left");
        updateComponentStyle(settings.id, { textAlign: "left" }, true, false);
        break;
      case "text_align_center":
        console.log("center");
        updateComponentStyle(settings.id, { textAlign: "center" }, true, false);
        break;
      case "text_align_right":
        console.log("right");
        updateComponentStyle(settings.id, { textAlign: "right" }, true, false);
        break;
      case "margin_align_left":
        console.log("left");
        updateComponentStyle(
          settings.id,
          {
            display: "flex",
            justifyContent: "flex-start",
          },
          true,
          500
        );
        break;
      case "margin_align_center":
        console.log("center");
        updateComponentStyle(
          settings.id,
          {
            display: "flex",
            justifyContent: "center",
          },
          true,
          500
        );
        break;
      case "margin_align_right":
        console.log("right!");
        updateComponentStyle(
          settings.id,
          {
            display: "flex",
            justifyContent: "flex-end",
          },
          true,
          500
        );

        break;
      default:
        break;
    }
  }

  render() {

    return (
      <Section label="Alignment" icon="vertical_align_center" {...this.props}>
        {this.props.verticalAlign ? (
          <FancySelector
            items={alignVertical}
            onChange={(id) => this.handleChange(id)}
          />
        ) : (
          false
        )}
        {this.props.textAlign ? (
          <FancySelector
            items={alignTextHorizontal}
            onChange={(id) => this.handleChange(id)}
          />
        ) : (
          false
        )}
        {this.props.marginAlign ? (
          <FancySelector
            items={alignMarginHorizontal}
            onChange={(id) => this.handleChange(id)}
          />
        ) : (
          false
        )}
      </Section>
    );
  }
}

Alignment.propTypes = {
  verticalAlign: PropTypes.bool,
  textAlign: PropTypes.bool,
  marginAlign: PropTypes.bool,
};

Alignment.defaultProps = {
  verticalAlign: true,
  textAlign: false,
  marginAlign: false,
};

export default Alignment;
