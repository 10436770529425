import * as React from "react";
import moment from "moment";

import { TextInput } from "@launchos/components/ui";

import { DateRangeValueProps } from "./types";

export const DateRangeValue: React.FC<DateRangeValueProps> = ({
  startValue = new Date(),
  endValue = new Date(),
  onChange = () => null,
}) => {

  const [startDate, setStartDate] = React.useState<Date>(startValue)
  const [endDate, setEndDate] = React.useState<Date>(endValue)

  const handleOnChange = (e, startorend) => {

    let sendStartDate = startDate;
    let sendEndDate = endDate;
    if (startorend === "start") { sendStartDate = e.target.value; setStartDate(sendStartDate) }
    if (startorend === "end") { sendEndDate = e.target.value; setEndDate(sendEndDate) }

    onChange({ start: moment(sendStartDate).format("YYYY-MM-DD"), end: moment(sendEndDate).format("YYYY-MM-DD") });

  }

  return (
    <div
      data-testid="DateRangeValue-LIVE"
    >
      {/* <input type="date" value={moment(startDate).format("YYYY-MM-DD")} onChange={(e) => handleOnChange(e, "start")} /> */}
      {/* <input type="date" value={moment(endDate).format("YYYY-MM-DD")} onChange={(e) => handleOnChange(e, "end")} /> */}
      <div style={{ display: 'flex', width: 280 }}>
        <div style={{ flex: 1, marginRight: 5, width: "40%" }}>
          <TextInput
            type="date"
            value={moment(startDate).format("YYYY-MM-DD")}
            onChange={(e) => handleOnChange(e, "start")}
          />
        </div>
        <div style={{ flex: 1, marginLeft: 5, width: "40%" }}>
          <TextInput
            type="date"
            value={moment(endDate).format("YYYY-MM-DD")}
            onChange={(e) => handleOnChange(e, "end")}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangeValue;
