import * as React from "react";
import { useState, useEffect, FC } from "react";
import { useMediaQuery } from "@material-ui/core";

import SectionsLegend from "@launchos/plugins/misc/v2/blocks/SectionsLegend";
import { SectionItemProps } from "@launchos/plugins/misc/v2/blocks/SectionsLegend/SectionItem/types";

import { ComponentTypes } from "@launchos/plugins/webcomponents/types";

interface SectionsLegendForBuilderProps {
  pageContent: any[];
  openAddNewTab: () => void;
  getMobileState: () => void;
  changeState: any;
  moveThisByThat: (id1: string, id2: string, dbUpdate?: boolean) => void;
}

export const SectionsLegendForBuilder: FC<SectionsLegendForBuilderProps> = ({
  pageContent,
  openAddNewTab,
  getMobileState,
  changeState,
  moveThisByThat,
}) => {
  const sections = pageContent.filter(
    (itm) => itm.type === ComponentTypes.SECTION
  );

  let num = 1600;
  if (getMobileState()["type"] === "fullscreen") num = 5000;
  if (getMobileState()["type"] === "desktop") num = 1850;
  if (getMobileState()["type"] === "tablet") num = 1800;
  if (getMobileState()["type"] === "smartphone") num = 1450;

  const matches = useMediaQuery(`(min-width:${num}px)`);
  
  const isFromIframe = window.location !== window.parent.location;
  
  if (sections.length)
    return (
      <div>
        {matches && (
          <SectionsLegend
            title="Page Sections"
            sections={sections.map((section) => ({
              id: section.id,
              label: section.label || section.type,
              type: section.label || section.type,
              settings: section,
            }))}
            style={{
              position: "fixed",
              top: 150,
              left: 140,
              width: 225,
              bottom: 150,
              overflowY: "scroll",
              ...isFromIframe && { top: 50, left: 50 },
              
            }}
            onChange={(id1, id2) => moveThisByThat(id1, id2)}
            onCreate={openAddNewTab}
            onChangeState={(id, state) =>
              changeState(id, state.toString().toLowerCase())
            }
          />
        )}
      </div>
    );

  return null;
};

export default SectionsLegendForBuilder;
