import _ from "lodash";
import React, { Component } from "react";

import { renameObject } from "@launchos/modules/editor/Builder/actions";

import { style } from "./style";

export class LegendObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "normal",
    };
  }
  render() {
    const {
      children,
      changeState,
      settings,
      updateComponentSettings,
    } = this.props;
    let color = { color: "black", fontWeight: "normal" };
    if (settings.state === "active") {
      color = { fontWeight: "bold" };
    }
    return (
      <div
        onClick={(e) => {
          changeState(settings.id, "active");
          e.stopPropagation();
        }}
        onDoubleClick={() =>
          renameObject({ updateComponentSettings, settings })
        }
        onMouseEnter={() => changeState(settings.id, "hover")}
        onMouseLeave={() => changeState(settings.id, "normal")}
        style={{ ...style.legendObject, ...color, ...this.props.style }}
        title="Click to select this element.  Double click to rename it"
      >
        {children}
      </div>
    );
  }
}
