import _ from "lodash";
import React, { Component } from "react";
import { Switch, Grid, Cell } from "@launchos/components/ui";
import { Section, Interactions } from "@launchos/modules/editor/Builder/Properties";

import { ActionTypes } from '@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/types'

import style from "./style";

export class MoreTimeOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDays: _.get(props, "settings.showDays", true),
      showHours: _.get(props, "settings.showHours", true),
      showMinutes: _.get(props, "settings.showMinutes", true),
      showSeconds: _.get(props, "settings.showSeconds", true),
      showDivider: _.get(props, "settings.showDivider", true),
      showLabels: _.get(props, "settings.showLabels", true),
    };
    this.toggleDivision = this.toggleDivision.bind(this);
  }
  toggleDivision(division) {
    const { settings, updateComponentSettings } = this.props;
    this.setState(
      (prevState) => ({
        [division]: !prevState[division],
      }),
      () => {
        updateComponentSettings(
          settings.id,
          {
            ...settings,
            [division]: this.state[division],
          },
          true,
          false
        );
      }
    );
  }
  render() {
    const {
      showDays,
      showHours,
      showMinutes,
      showSeconds,
      showDivider,
      showLabels,
    } = this.state;
    return (
      <Section label="More Time Options" icon="av_timer" {...this.props}>
        <fieldset style={style.fieldset}>
          <legend>Time Divisions</legend>
          <Grid>
            <Cell style={{ width: "50%" }}>
              <Switch
                checked={showDays}
                color="primary"
                onChange={(e) => this.toggleDivision("showDays")}
              />
              <span style={{ position: "relative" }}>Days</span>
            </Cell>
            <Cell>
              <Switch
                checked={showHours}
                color="primary"
                onChange={(e) => this.toggleDivision("showHours")}
              />
              <span style={{ position: "relative" }}>Hours</span>
            </Cell>
          </Grid>
          <Grid>
            <Cell style={{ width: "50%" }}>
              <Switch
                checked={showMinutes}
                color="primary"
                onChange={(e) => this.toggleDivision("showMinutes")}
              />
              <span style={{ position: "relative" }}>Minutes</span>
            </Cell>
            <Cell>
              <Switch
                checked={showSeconds}
                color="primary"
                onChange={(e) => this.toggleDivision("showSeconds")}
              />
              <span style={{ position: "relative" }}>Seconds</span>
            </Cell>
          </Grid>
          <Grid>
            <Cell style={{ width: "50%" }}>
              <Switch
                checked={showDivider}
                color="primary"
                onChange={(e) => this.toggleDivision("showDivider")}
              />
              <span style={{ position: "relative" }}>Divider</span>
            </Cell>
            <Cell style={{ width: "50%" }}>
              <Switch
                checked={showLabels}
                color="primary"
                onChange={(e) => this.toggleDivision("showLabels")}
              />
              <span style={{ position: "relative" }}>Labels</span>
            </Cell>
          </Grid>
        </fieldset>

        <Interactions
          hideLabel
          label="When countdown is complete..."
          {...this.props}
          onlyTheseActions={[ActionTypes.DO_NOTHING, ActionTypes.TRIGGER_NEXT_NODE, ActionTypes.GO_TO_URL, ActionTypes.OPEN_POPUP, ActionTypes.SUBMIT_FORM, ActionTypes.SHOW_AND_HIDE, ActionTypes.CREATE_TAG]}
          children={null}
        />
      </Section>
    );
  }
}
