import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const TheCategories = styled.div`
  ul {
    padding-bottom: 25px;
    width: 100%;
    display: block;
  }
  li {
    padding: 10px;
    display: block;
  }
  li:not(.title):hover {
    background-color: #ddd;
  }
  .title {
    font-style: bold;
    font-size: 14pt;
  }
  .active {
    border-left: 3px solid red;
    padding-left: 8px;
  }
  a {
    color: #000;
  }
`;

const Categories = props => {
  const { match } = props;

  const tabId = match.params.tabId;
  const searchStr = match.params.searchStr;

  return (
    <TheCategories>
      <ul>
        <Link to={`/catalog/${tabId}/all`}>
          <li className={`title ${searchStr === "all" ? "active" : ""}`}>
            All
          </li>
        </Link>
        {/* <Link to={`/catalog/${tabId}/saved`}>
          <li className={`title ${searchStr === "saved" ? "active" : ""}`}>
            Saved
          </li>
        </Link> */}
      </ul>
      <ul>
        <li className="title">Skill</li>
        <Link to={`/catalog/${tabId}/beginner`}>
          <li className={searchStr === "beginner" ? "active" : ""}>Beginner</li>
        </Link>
        <Link to={`/catalog/${tabId}/intermediate`}>
          <li className={searchStr === "intermediate" ? "active" : ""}>
            Intermediate
          </li>
        </Link>
        <Link to={`/catalog/${tabId}/advanced`}>
          <li className={searchStr === "advanced" ? "active" : ""}>Advanced</li>
        </Link>
      </ul>
      <ul>
        <li className="title">Goal</li>
        <Link to={`/catalog/${tabId}/leads`}>
          <li className={searchStr === "leads" ? "active" : ""}>More Leads</li>
        </Link>
        <Link to={`/catalog/${tabId}/sales`}>
          <li className={searchStr === "sales" ? "active" : ""}>More Sales</li>
        </Link>
        <Link to={`/catalog/${tabId}/recover`}>
          <li className={searchStr === "recover" ? "active" : ""}>
            Recover a Sale
          </li>
        </Link>
        <Link to={`/catalog/${tabId}/fulfilment`}>
          <li className={searchStr === "fulfilment" ? "active" : ""}>
            Fulfilment
          </li>
        </Link>
      </ul>
    </TheCategories>
  );
};

export default Categories;
