import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import Dashboard from "./Dashboard";
import config from '@launchos/10mf/src/config'

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "bottom" && config.app.name === "10 Minute Funnels") {
    return [
      {
        // route: "/university",
        // link: "http://support.tenminutefunnels.com",
        // link: "https://kjgraham.wistia.com/projects/rlvshm295t",
        link: "https://university.tenminutefunnels.com",
        icon: "school",
        label: "10MF University"
      }
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    return [Dashboard];
  }
};
