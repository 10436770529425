import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 100,
    mobile: {
      desktop: {
        padding: 100,
      },
      smartphone: {
        padding: 15,
      },
    },
  },
  ITEM_HEADLINE: {
    textAlign: "left",
    fontSize: "16pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,
    paddingTop: 40,
  },
  TEXT: {
    padding: "20px 0",
    fontSize: "13pt",
    fontFamily: theme.fontFamily,
    lineHeight: "18pt",
  },
};
