import * as React from "react";
import _ from "lodash";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Divider } from "./live";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

import dividerSettings from "./settings";
import { PluginProps, DividerTypes } from "./types";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

const Properties = (props) => {
  const { updateComponentStyle, updateComponentSettings, settings } = props;
  const { properties } = dividerSettings;
  const { sections } = properties.main;

  // const withType = updateSection(sections, "custom_type", {
  //   component: (
  //     <RadioGroup
  //       aria-label="dividerType"
  //       name="dividerType"
  //       value={settings.dividerType}
  //       onChange={(e) =>
  //         updateComponentSettings(
  //           settings.id,
  //           {
  //             ...settings,
  //             dividerType: e.target.value,
  //           },
  //           true,
  //           false
  //         )
  //       }
  //     >
  //       <div style={{ display: "flex" }}>
  //         <FormControlLabel
  //           value={DividerTypes.HORIZONTAL}
  //           control={<Radio />}
  //           label="Horizontal"
  //         />
  //         <FormControlLabel
  //           value={DividerTypes.VERTICAL}
  //           control={<Radio />}
  //           label="Vertical"
  //         />
  //       </div>
  //     </RadioGroup>
  //   ),
  // });

  const sectionsWithColorPickerChange = updateSection(
    sections,
    SectionTypes.COLORPICKER,
    {
      onChange: (e) => {
        updateComponentStyle(settings.id, { backgroundColor: e.hex });
      },
    }
  );

  // const sectionsWithHeightChange = updateSection(
  //   sectionsWithColorPickerChange,
  //   SectionTypes.HEIGHT,
  //   // {
  //   //   onChange: (resp) => {
  //   //     switch (resp) {
  //   //       case "GROW": {
  //   //         const height = _.get(settings, "properties.height", 0) + 10;
  //   //         updateComponentStyle(settings.id, { height }, true, false);
  //   //         break;
  //   //       }
  //   //       case "SHRINK": {
  //   //         const height = _.get(settings, "properties.height", 6) - 10;
  //   //         updateComponentStyle(settings.id, { height }, true, false);
  //   //         break;
  //   //       }
  //   //       default: {
  //   //         const height = resp;
  //   //         updateComponentStyle(settings.id, { height }, true, false);
  //   //       }
  //   //     }
  //   //   },
  //   // }
  // );

  return (
    <PropertiesBuilder
      {...props}
      data={{
        ...properties,
        main: { ...properties.main, sections: sectionsWithColorPickerChange },
      }}
      title="Divider Settings"
    />
  );
};

export const EditorDivider: React.FC<PluginProps> = (props) => {
  const { settings, mode, id } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Divider"
        id={id}
        {...props}
        PropertiesView={Properties}
      >
        <Divider
          {...settings}
          properties={properties}
          mode={mode}
          type={settings.dividerType}
          testId="WC-DIVIDER-EDIT"
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorDivider;
