import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "../../../../../../webcomponents/v2/Icon";
import {
  getClipPath,
  ClipPathTypes,
} from "@launchos/plugins/utilities/clipPaths";
import { SocialProofLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.SEVEN,
  layout: SocialProofLayouts.SEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof07.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkypng982by30780ld7yaetu",
    itemId: "rn9n2jb1d2",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: "0 30px",
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      display: "inline-block",
                      textAlign: "center",
                    },
                    children: [
                      getImagePlaceholder({
                        type: ImagePlaceholderTypes.NONE,
                        src:
                          "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                        style: {
                          display: "block",
                          width: 50,
                          clipPath: getClipPath({ type: ClipPathTypes.CIRCLE }),
                        },
                      }),
                    ],
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: `"Hands down the best process I've seen in the last 11 years. Thank you!! I really value your approach"`,
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      fontSize: "11pt",
                    },
                    html: "Jacob McDonald, Invictus",
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: "0 30px",
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      display: "inline-block",
                      textAlign: "center",
                    },
                    children: [
                      getImagePlaceholder({
                        type: ImagePlaceholderTypes.NONE,
                        src:
                          "https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                        style: {
                          display: "block",
                          width: 50,
                          clipPath: getClipPath({ type: ClipPathTypes.CIRCLE }),
                        },
                      }),
                    ],
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: `"Hands down the best process I've seen in the last 11 years. Thank you!! I really value your approach"`,
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      fontSize: "11pt",
                    },
                    html: "Jacob McDonald, Invictus",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
