import * as React from "react";
import { useSpring, animated } from "react-spring";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

export enum AnimationType {
  FADE_IN,
  GROW,
}

interface AnimationProps {
  mode?: EditorMode;
  children: React.ReactNode | string;
  autoPlay?: boolean;
  type: AnimationType;
}

export const Animation: React.FC<AnimationProps> = ({
  children,
  autoPlay,
  type,
}) => {
  let animStyle;

  const fadeAnimStyle = useSpring({
    opacity: autoPlay ? 1 : 0,
    from: { opacity: autoPlay ? 0 : 1 },
  });

  const growAnimStyle = useSpring({
    zoom: autoPlay ? 1.05 : 1,
    from: {
      zoom: autoPlay ? 1.05 : 1,
    },
  });

  switch (type) {
    case AnimationType.FADE_IN:
      animStyle = fadeAnimStyle;
      break;
    case AnimationType.GROW:
      animStyle = growAnimStyle;
      break;
  }

  return (
    <animated.div data-testid="WC-ANIMATION-LIVE" style={animStyle}>
      {children}
    </animated.div>
  );
};

export default Animation;
