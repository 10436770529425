import * as React from "react";
import { get, head } from "lodash";
import { ValueTypesEnum } from "../ValueSelector/types";
import { LogicBuilderStyles } from "../types";

export enum SelectorTypes {
  INDEX = "INDEX",
  // OPERATOR = "OPERATOR",
  DIMENSION = "DIMENSION",
  VALUE = "VALUE",
}

export interface LoadSelectorProps {
  // e.g. index (show show as dropdown item) -- should show the appropriate selector component
  // Index | Operator | Dimension | Value
  type?: SelectorTypes;

  // IndexType | OperatorType | DimensionType | ValuePickerType
  // e.g. id, type, caption, next []
  data?: { id?: string; type?: any; caption?: string; next?: string }[];

  // value to pass along
  // value?: any;
  values?: any[];
  setValues?: () => void;

  styles?: LogicBuilderStyles;

  // (all changes, not just final) -- don't pass up
  onChange?: any;

  // passes up
  // (used for every onChange event, but to differentiate between non-commital onBlur vs onChange changes in ValuePickers -- e.g. text box typing, etc.)
  onFinalChange?: any;

  allFields?: any;

  component?: ValueTypesEnum;
}

export const getType = (tp, data = [], component = null) => {
  switch (tp) {
    case SelectorTypes.INDEX:
      return ValueTypesEnum.SELECT;
    case SelectorTypes.DIMENSION:
      if (get(data[0], "component") === ValueTypesEnum.MULTISELECT)
        return ValueTypesEnum.MULTISELECT;
      else return ValueTypesEnum.SELECT;
    case SelectorTypes.VALUE:
      return component;
    // default:
    // return ValueTypesEnum.STRING;
  }
};
