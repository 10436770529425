import * as React from "react";
import { get } from "lodash";

import {
  SortableList,
  DefaultItemComponent,
} from "@launchos/modules/v2/CRUD/SortableList";

import { AttributeContainer } from "@launchos/modules/v2/CRUD/FormBuilder";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";
import { LiveFormFieldProps } from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField/types";
import { ItemComponentTypes } from "@launchos/modules/v2/CRUD/SortableList/types";
import { CarouselItemType } from "../types";
import { IconTypes } from "../../Icon";

interface CarouselItemAttributes extends LiveFormFieldProps {
  active: boolean;
}

export const CarouselBuilder: React.FC<{
  items: CarouselItemType[];
  onChange: () => void;
  onAdd: () => void;
}> = ({ items = [], onChange = () => null, onAdd = () => null }) => {
  const handleChange = (mutatedPayload: CarouselItemType[]): void =>
    onChange(mutatedPayload);

  const handleEdit = (data: CarouselItemType): void => {
    // makeActive(data);
    console.log("Editing", data);
    const newName = prompt("Please enter a name for this slide", data.label);
    handleChange(
      items.map((itm) => ({
        ...itm,
        active: itm.id === data.id,
        label: itm.id === data.id ? newName : itm.label,
      }))
    );
  };

  const makeActive = (data: CarouselItemType): void => {
    console.log("Making Active", data);
    handleChange(items.map((itm) => ({ ...itm, active: itm.id === data.id })));
  };

  const ItemComponent = (itm) => {
    const [attributes, setAttributes] = React.useState<CarouselItemAttributes>(
      itm
    );
    const { id, label, description, active } = attributes;

    return (
      <DefaultItemComponent
        {...itm}
        placeholder="Add a new Slide"
        caption={get(itm, "label", "Untitled Slide")}
        type={ItemComponentTypes.TEXT}
        isExpanded={active}
        icon={
          active ? IconTypes.RadioButtonChecked : IconTypes.RadioButtonUnchecked
        }
        // toggleExpansion={() => handleEdit(itm)}
        toggleExpansion={() => makeActive(itm)}
      />
    );
  };

  return (
    <div
      style={{
        width: 350,
        marginLeft: -20,
      }}
    >
      <SortableList
        data={items}
        itemStyle={{
          border: "1px solid #DDD",
          borderRadius: 3,
          margin: "10px 0",
          height: 45,
        }}
        itemHoverStyle={{
          border: "1px solid #AAA",
          boxShadow: "0 2px 1px 0 rgb(0,0,0,0.1)",
          backgroundColor: "#F3F3F3",
        }}
        ItemComponent={ItemComponent}
        onAdd={onAdd}
        onEdit={handleEdit}
        onChange={handleChange}
        onDelete={(payload, updatedData) => {
          if (updatedData.length) handleChange(updatedData);
        }}
        canDragAndDrop
        showItemInline={false}
        name="Slide"
      />
    </div>
  );
};
