import React from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogTitle,
  ListItem,
  Menu,
  TextInput,
} from "@launchos/components/ui";
// import style from "../page/style";

const style = {
  selectField: {
    padding: "10px 15px",
    border: "1px solid #DBDBDB",
    borderRadius: 3,
    display: "block",
    width: "100%",
    marginBottom: 10,
    fontSize: "11pt",
    outline: "none",
    textAlign: "left",
    color: "#666",
  },
};

const SelectField = (props) => {
  const lbl = {
    textTransform: "uppercase",
    fontSize: 13,
    color: "rgb(102, 102, 102)",
    display: "inline-block",
    marginTop: 20,
    marginBottom: 5,
  };

  return (
    <div style={{ textAlign: "left", cursor: "pointer" }}>
      <span style={lbl}>{props.label}</span>
      <div style={style.selectField}>
        <Menu label={props.value} style={{ width: "100%", textAlign: "left" }}>
          <div style={{ maxHeight: 350, overflowY: "scroll" }}>
            {props.children}
          </div>
        </Menu>
      </div>
    </div>
  );
};

const CatalogItemEditPopup = (props) => {
  const cITxtItmStyle = {
    width: 650,
  };

  const {
    allCampaignsQuery,
    allCatalogItemsQuery,
    activeCampaignDetails,
    updateCatalogItem,
    createCatalogItem,
    onClose,
  } = props;

  const { campaigns } = allCampaignsQuery;
  const allCampaigns = campaigns;

  const saveChanges = async () => {
    const {
      type,
      name,
      description,
      image,
      campaignId,
      video,
      categories,
      tags,
      isNew,
      isExpanded,
      isRecommended,
      isSpotlight,
      link,
      skill,
      rating,
      color,
      earnings,
      id,
      pageId,
    } = activeCampaignDetails;

    const conditionalContent = type === "designs" ? { pageId } : { campaignId };

    const variables = {
      type: type || "funnels",
      name,
      description,
      image,
      video,
      categories: categories || "",
      tags,
      isNew: isNew || false,
      // isExpanded: isExpanded || false,
      isRecommended: isRecommended || false,
      isSpotlight: isSpotlight || false,
      link,
      skill: skill || "beginner",
      rating,
      color,
      earnings,
      id,
      ...conditionalContent,
    };

    // console.log({ variables });

    if (id) await updateCatalogItem({ variables });
    else
      await createCatalogItem({
        variables: { ...variables, userId: localStorage.userId },
      });

    await allCatalogItemsQuery.refetch();
    allCampaignsQuery.refetch();

    onClose();
  };

  const handleChange = (e, type) => {
    let details = activeCampaignDetails;
    details[type] = e.target.value;
    props.setActiveCampaignDetails(details);
  };

  const setTarget = (value) => ({ target: { value } });

  let campaignName = "Choose a Campaign...";
  if (allCampaigns && activeCampaignDetails.campaignId) {
    const campaignObj = allCampaigns.filter(
      (itm) => itm.id === activeCampaignDetails.campaignId
    );
    if (campaignObj.length) campaignName = campaignObj[0].name;
  }

  let allPages = [];
  allCampaigns.forEach((itm) =>
    itm.objects.forEach((pg) => {
      if (pg.type === "PageComponent") allPages.push(pg.page);
    })
  );

  let pageName = "Choose a Page...";
  if (allPages && activeCampaignDetails.pageId) {
    pageName = allPages.filter(
      (itm) => itm.id === activeCampaignDetails.pageId
    )[0].name;
  }

  return (
    <Dialog style={{ position: "fixed" }} {...props}>
      <DialogTitle>
        {Object.keys(activeCampaignDetails).length
          ? `Edit Catalog Item`
          : `Create Catalog Item`}
      </DialogTitle>
      <DialogBody>
        <div style={{ height: 500, padding: "0 35px", overflowY: "auto" }}>
          <SelectField
            label="Type of Campaign"
            value={activeCampaignDetails.type || "funnels"}
          >
            <ListItem
              onClick={() => handleChange(setTarget("funnels"), "type")}
            >
              Funnels
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget("designs"), "type")}
            >
              Designs
            </ListItem>
          </SelectField>
          {activeCampaignDetails.type !== "designs" ? (
            <SelectField label="Which Campaign" value={campaignName}>
              {allCampaigns.map((itm) => (
                <ListItem
                  key={itm.id}
                  onClick={() => handleChange(setTarget(itm.id), "campaignId")}
                >
                  {itm.name}
                </ListItem>
              ))}
            </SelectField>
          ) : null}
          {activeCampaignDetails.type === "designs" ? (
            <SelectField label="Which Page" value={pageName}>
              {allPages.map((page) => (
                <ListItem
                  key={page.id}
                  onClick={() => handleChange(setTarget(page.id), "pageId")}
                >
                  {page.name}
                </ListItem>
              ))}
            </SelectField>
          ) : null}
          <TextInput
            label="name"
            value={activeCampaignDetails.name}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "name")}
          />
          <TextInput
            label="description"
            value={activeCampaignDetails.description}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "description")}
          />
          <TextInput
            label="image"
            value={activeCampaignDetails.image}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "image")}
          />
          <TextInput
            label="video"
            value={activeCampaignDetails.video}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "video")}
          />
          <SelectField
            label="Goal"
            value={activeCampaignDetails.categories || "4"}
          >
            <ListItem
              onClick={() => handleChange(setTarget("leads"), "categories")}
            >
              Leads
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget("sales"), "categories")}
            >
              Sales
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget("recover"), "categories")}
            >
              Recover Sales
            </ListItem>
            <ListItem
              onClick={() =>
                handleChange(setTarget("fulfilment"), "categories")
              }
            >
              Fulfilment
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget(false), "categories")}
            >
              N/A
            </ListItem>
          </SelectField>
          <TextInput
            label="tags"
            value={activeCampaignDetails.tags}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "tags")}
          />
          <SelectField
            label="Show as new"
            value={activeCampaignDetails.isNew ? "Yes" : "No"}
          >
            <ListItem onClick={() => handleChange(setTarget(true), "isNew")}>
              Yes
            </ListItem>
            <ListItem onClick={() => handleChange(setTarget(false), "isNew")}>
              No
            </ListItem>
          </SelectField>
          <SelectField
            label="Show expanded details"
            value={activeCampaignDetails.isExpanded ? "Yes" : "No"}
          >
            <ListItem
              onClick={() => handleChange(setTarget(true), "isExpanded")}
            >
              Yes
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget(false), "isExpanded")}
            >
              No
            </ListItem>
          </SelectField>
          <SelectField
            label="Show as a Recommended Campaign"
            value={activeCampaignDetails.isRecommended ? "Yes" : "No"}
          >
            <ListItem
              onClick={() => handleChange(setTarget(true), "isRecommended")}
            >
              Yes
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget(false), "isRecommended")}
            >
              No
            </ListItem>
          </SelectField>
          <SelectField
            label='Show as a "Spotlight" Campaign'
            value={activeCampaignDetails.isSpotlight ? "Yes" : "No"}
          >
            <ListItem
              onClick={() => handleChange(setTarget(true), "isSpotlight")}
            >
              Yes
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget(false), "isSpotlight")}
            >
              No
            </ListItem>
          </SelectField>
          <TextInput
            label="link"
            value={activeCampaignDetails.link}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "link")}
          />
          <SelectField
            label="Skill Level"
            value={activeCampaignDetails.skill || "beginner"}
          >
            <ListItem
              onClick={() => handleChange(setTarget("beginner"), "skill")}
            >
              Beginner
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget("intermediate"), "skill")}
            >
              Intermediate
            </ListItem>
            <ListItem
              onClick={() => handleChange(setTarget("advanced"), "skill")}
            >
              Advanced
            </ListItem>
          </SelectField>
          <SelectField
            label="Rating"
            value={activeCampaignDetails.rating || "4"}
          >
            <ListItem onClick={() => handleChange(setTarget(1), "rating")}>
              1
            </ListItem>
            <ListItem onClick={() => handleChange(setTarget(2), "rating")}>
              2
            </ListItem>
            <ListItem onClick={() => handleChange(setTarget(3), "rating")}>
              3
            </ListItem>
            <ListItem onClick={() => handleChange(setTarget(4), "rating")}>
              4
            </ListItem>
            <ListItem onClick={() => handleChange(setTarget(5), "rating")}>
              5
            </ListItem>
          </SelectField>
          <TextInput
            label="color"
            value={activeCampaignDetails.color}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "color")}
          />
          <TextInput
            label="earnings"
            value={activeCampaignDetails.earnings}
            style={cITxtItmStyle}
            onChange={(e) => handleChange(e, "earnings")}
          />
        </div>
        <Button primary large style={{ marginTop: 10 }} onClick={saveChanges}>
          Save Changes
        </Button>
      </DialogBody>
    </Dialog>
  );
};

export default CatalogItemEditPopup;
