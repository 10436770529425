import { Button, Cell, Grid } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { app } from "../../../config";
import providers from "../../settings/IntegrationManagement/integrationProviders";

export default class Success extends Component {
  constructor(props) {
    super(props);

    this.providerInfo = [];
  }

  static contextType = AppConfig;

  componentDidMount() {
    const { app } = this.context;

    this.providerInfo = _.filter(
      providers,
      o =>
        o.id ===
        _.get(this.props, "providerSettings.payload.type", app.defaultProvider)
    )[0];

    this.props.updatePageSettings();
  }

  render() {
    const { hidden, message, footer } = this.props;
    if (!hidden) {
      return (
        <div
          style={{
            width: 550,
            margin: "0 auto"
          }}
        >
          <img
            style={{ margin: 10, height: 100 }}
            alt="Success Indicator"
            src="https://s3.amazonaws.com/sandcastleassets/images/success_check.png"
          />
          <h2>{message}</h2>
          <p
            style={{
              marginTop: 30,
              fontSize: 11,
              color: "#666"
            }}
          >
            {footer}
          </p>
          <Grid
            justify="space-evenly"
            style={{
              marginTop: 30
            }}
          >
            <Cell align="center">
              <Link to={this.props.prevHref}>
                <Button
                  large
                  transparent
                  style={{
                    color: "#333"
                  }}
                >
                  Back
                </Button>
              </Link>
              <Link to={_.get(this.props, "nextHref", "../builder")}>
                <Button large tertiary>
                  OK
                </Button>
              </Link>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
