import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { CTALayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/CTA/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.TEN,
  layout: CTALayouts.TEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA10.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx8ufdx24fg0780ybqe4j4m",
    itemId: "juy516yevl",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          width: 750,
          margin: "0 auto",
          textAlign: "center",
          display: "inline-block",
          mobile: {
            desktop: { width: 750, maxWidth: "100%" },
            smartphone: { width: "100%" },
          },
        },
        children: [
          getImagePlaceholder({
            type: ImagePlaceholderTypes.NONE,
            style: {
              margin: "40px auto",
              display: "inline-block",
              textAlign: "center",
              mobile: {
                desktop: { width: 400, maxWidth: "100%" },
                smartphone: { width: "100%" },
              },
            },
          }),
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              textAlign: "center",
            },
            children: [
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: style.BUTTON.style,
                caption1: "Start",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
