import * as React from "react";
import styled from "styled-components";

import { AlphabetFilterProps } from "../types";
import style from "./styles";

const CharacterContainer = styled.span`
  &:hover {
    background: white;
  }
`;

/**
 * A vertical list of letters (the alphabet) that filter the DisplayItems when clicked
 */
export const AlphabetFilter: React.FC<AlphabetFilterProps> = ({
  containerStyle,
  characterStyle,
  onClick
}: AlphabetFilterProps) => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");

  return (
    <div
      data-testid="AlphabetFilter-Default"
      style={containerStyle || style.containerStyle}
    >
      {alphabet.map((character, key) => (
        <CharacterContainer
          key={key}
          style={characterStyle || style.characterStyle}
          data-testid="AlphabetFilter-Character"
          onClick={() => onClick(character)}
        >
          {character}
        </CharacterContainer>
      ))}
    </div>
  );
};

export default AlphabetFilter;
