import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { HeaderLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/types";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { doIShow, combineData, lorem } from "../../utils";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.SIXTEEN,
  layout: HeaderLayouts.SIXTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header16.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "1j6nxcwu831",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.SUBHEADLINE,
        },
        html: `Do You Know ${startCase(lorem.generateWords(3))}?`,
      },
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: `Introducing: ${startCase(lorem.generateWords(7))}`,
      },
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.SUBHEADLINE,
        },
        html: startCase(lorem.generateWords(25)),
      },
      getVideoPlaceholder({
        type: VideoPlaceholderTypes.NONE,
        style: { height: 400, color: theme.foregroundColor, marginTop: 40 },
      }),
      {
        type: ComponentTypes.BUTTON,
        canHaveChildren: false,
        properties: style.BUTTON.style,
        caption1: "Yes - Add to Cart!",
        caption1style: style.BUTTON.caption1,
      },
    ],
  },
};

export default settings;
