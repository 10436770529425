import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 30,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    mobile: {
      desktop: {
        // padding: 30,
        fontSize: "32pt",
      },
      smartphone: {
        marginTop: 30,
        padding: 5,
        fontSize: "24pt",
      },
    },
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 11,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
      // margin: "30px auto"
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "100%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.backgroundColor}`,
      borderRadius: 5,
      color: theme.backgroundColor,
    },
  },
};
