import _ from "lodash";
import {
  Button,
  Dialog,
  DialogBody,
  Menu,
  ListItem,
  Grid,
  Cell
} from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React from "react";

import style from "../shared/style";
import { client } from "@launchos/api/gql";
import { cloneObject } from "@launchos/api/gql/campaigns";

/**
 * This is the popup window that displays when a user attempts to add an SSL to their domain name
 * @param {object} props The props
 * @returns {function} The component
 */
class CloneObjectPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      success: false,
      id: props.settings.id,
      campaignId: props.match.params.campaignId
    };

    this.cloneObject = this.cloneObject.bind(this);
  }

  static contextType = AppConfig;

  async cloneObject() {
    const { id, campaignId } = this.state;

    await client.mutate({
      mutation: cloneObject,
      variables: { id, campaignId }
    });

    window.location.href = `/campaign/${campaignId}/edit`;
  }

  render() {
    window.scroll(0, 0);

    const settings = this.context;

    const { campaigns } = this.props.allCampaignsQuery;

    const label = _.get(
      _.head(_.filter(campaigns, itm => itm.id === this.state.campaignId)),
      "name"
    );

    return (
      <Dialog {...this.props} style={{ width: 700 }} centered>
        <DialogBody>
          <img
            style={{ margin: 10, width: 100 }}
            alt=""
            src="https://sandcastleassets.s3.amazonaws.com/images/duplicate-icon.png"
          />

          <div>
            <h2>Where would you like to copy this page?</h2>

            <div
              style={{ ...style.selectField, width: 350, margin: "30px auto" }}
            >
              <Menu style={{ width: "100%" }} label={label}>
                <div style={{ maxHeight: 300, overflowY: "auto" }}>
                  {campaigns.map(d => (
                    <ListItem
                      onClick={() => this.setState({ campaignId: d.id })}
                      key={d.id}
                    >
                      {d.name}
                    </ListItem>
                  ))}
                </div>
              </Menu>
            </div>

            <br />

            <Grid justify="space-evenly" style={{ width: 250, margin: '0 auto' }}>
              <Cell align="center">
                <Button onClick={this.cloneObject} large tertiary>
                  OK
                </Button>
              </Cell>
              <Cell align="center">
                <Button
                  onClick={this.props.onClose}
                  large
                  transparent
                  style={{ color: "#333" }}
                >
                  Cancel
                </Button>
              </Cell>
            </Grid>
          </div>

          {this.state.loading && (
            <div style={{ textAlign: "center" }}>
              <img src={settings.app.loadingImage} alt="Loading..." />
            </div>
          )}
        </DialogBody>
      </Dialog>
    );
  }
}

export default CloneObjectPopup;
