import {
  Button,
  Cell,
  Dialog,
  DialogBody,
  DialogFooter,
  Grid
} from "@launchos/components/ui";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";

/**
 * This is the popup window that displays when a user attempts to publish their page
 * @param {object} props The props
 * @returns {function} The component
 */
const DeleteConfirmation = props => {
  window.scroll(0, 0);

  if (!props.hidden) {
    const domainInfoIndex = props.allDomainsQuery.domains.findIndex(
      domain => domain.id === props.match.params.domainId
    );
    const domainInfo = props.allDomainsQuery.domains[domainInfoIndex];

    if (!_.has(domainInfo, "name")) props.history.push("/settings/domains");

    return (
      <Dialog
        {...props}
        style={{ width: 700 }}
        centered
        closeHref={props.closeHref}
      >
        <DialogBody>
          <img
            style={{ margin: 10, width: 100 }}
            alt=""
            src="https://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/sign-delete-icon.png"
          />
          <h2>Disconnect ({domainInfo.name}) Domain?</h2>
          <p style={{ fontWeight: "bold" }}>Warning: this cannot be undone.</p>
        </DialogBody>
        <DialogFooter>
          <Grid justify="space-evenly">
            <Cell align="center">
              <Button
                large
                secondary
                onClick={() => {
                  props.onDeleteDomain(props, props.match.params.domainId);
                }}
              >
                Yes, Disconnect this Domain
              </Button>
            </Cell>
            <Cell align="center">
              <Link to={props.closeHref}>
                <Button
                  large
                  transparent
                  style={{ color: "black" }}
                >
                  Cancel, Keep this Domain
                </Button>
              </Link>
            </Cell>
          </Grid>
        </DialogFooter>
      </Dialog>
    );
  }
  return null;
};
DeleteConfirmation.defaultProps = {
  hidden: true
};

export default DeleteConfirmation;
