import { ComponentRenderHooks } from "@launchos/plugins/types";
import EditorContainer from "./Container";
import settings from "./settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Container") {
    return [EditorContainer];
  }
};
