import * as React from "react";
import { get } from 'lodash'

import BooleanValue from "./components/BooleanValue";
import DateRangeValue from "./components/DateRangeValue";
import DateValue from "./components/DateValue";
import NumberValue from "./components/NumberValue";
import StringValue from "./components/StringValue";
import { ValueSelectorProps, ValueTypesEnum } from "./types";
import SelectValue from "./components/SelectValue";

export const ValueSelector: React.FC<ValueSelectorProps> = ({
  name,
  type = ValueTypesEnum.STRING,
  value,
  items = [],
  onChange = () => null,
  onBlur = () => null,
}) => {
  const handleChange = (data) => {
    onChange(data, name)
  }
  return (
    <div data-testid="ValueSelector-LIVE">
      {type === ValueTypesEnum.BOOLEAN && <BooleanValue value={value} onChange={handleChange} />}
      {type === ValueTypesEnum.DATE && <DateValue value={value} onChange={handleChange} />}
      {type === ValueTypesEnum.DATERANGE && <DateRangeValue startValue={get(value, 'start')} endValue={get(value, 'end')} onChange={handleChange} />}
      {type === ValueTypesEnum.NUMBER && <NumberValue value={value} onChange={handleChange} />}
      {type === ValueTypesEnum.STRING && <StringValue value={value} onChange={handleChange} onBlur={onBlur} />}
      {type === ValueTypesEnum.SELECT && <SelectValue value={value} items={items} onChange={handleChange} />}
      {type === ValueTypesEnum.MULTISELECT && <SelectValue value={value} items={items} onChange={handleChange} multiple />}
    </div>
  );
};

export default ValueSelector;
