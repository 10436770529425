export enum RichEditorPosition {
  TOP = "top",
  BOTTOM = "bottom",
  INLINE = "auto",
}

type ButtonItem =
  | "aligncenter"
  | "alignjustify"
  | "alignleft"
  | "alignnone"
  | "alignright"
  | "blockquote"
  | "backcolor"
  | "bold"
  | "copy"
  | "cut"
  | "fontselect"
  | "fontsizeselect"
  | "forecolor"
  | "formatselect"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "indent"
  | "italic"
  | "lineheight"
  | "newdocument"
  | "outdent"
  | "paste"
  | "redo"
  | "remove"
  | "removeformat"
  | "selectall"
  | "strikethrough"
  | "styleselect"
  | "subscript"
  | "superscript"
  | "underline"
  | "undo"
  | "visualaid"
  | "bullist"
  | "numlist"
  | "quicklink";

type menuItem =
  | "align" //	Changes alignment to the current block or selection.
  | "backcolor" //	Applies background color to selection.
  | "blockformats" //	Applies block formats to current selection.
  | "bold" //	Applies bold format to current selection.
  | "codeformat" //	Applies inline code format to current selection.
  | "copy" //	Copies the current selection into clipboard.
  | "cut" //	Cuts the current selection into clipboard.
  | "forecolor" //	Applies foreground/text color to selection.
  | "formats" //	Menu of all available formats.
  | "fontformats" //	Dropdown list with font families to apply to selection.
  | "fontsizes" //	Dropdown list with font sizes to apply to selection.
  | "italic" //	Applies italic format to current selection.
  | "lineheight" //	Dropdown list with line heights to apply to selection. > Note: This feature is only available for TinyMCE 5.5 and later.
  | "newdocument" //	Creates a new document.
  | "paste" //	Pastes the current clipboard contents into editor.
  | "redo" //	To redo the last undo-ed operation.
  | "removeformat" //	Removes all formats form the current selection.
  | "selectall" //	Selects all the editor contents.
  | "strikethrough" //	Applies strikethrough format to current selection.
  | "subscript" //	Applies subscript format to current selection.
  | "superscript" //	Applies superscript format to current selection.
  | "underline" //	Applies underline format to current selection.
  | "undo" //	To undo the last operation.
  | "visualaid" //	Toggles visual aids on/off.
  | "googlefonts" // Custom Sub Menu for selecting a Google Font
  | "link";

export interface RichEditorProps {
  /**
   * The unique id for the Rich Editor - prepend with `ed_*` so font lists are generated accurately
   */
  id: string;
  /**
   * The array of buttons you want to display
   */
  buttonList?: ButtonItem[][];

  /**
   * The array of menu items you want to display
   */
  menuList?: menuItem[][];

  /**
   * An optional list of line heights to display in the line height dropdown
   * (e.g. [1,2,3] or ["1em", "2em", "3em"])
   */
  lineHeightList?: string[] | number[];

  /**
   * An optional list ofi font sizes to display in the font sizes dropdown
   * (e.g. [1,2,3] or ["1em", "2em", "3em"])
   */
  fontSizeList?: string[] | number[];

  /**
   * An optional list of fonts to display in the font family dropdown.
   * Be sure to include/import the css on the page so that the font is available
   * e.g. ([{name: "Arial", font: "arial,helvetica,sans-serif"}])
   */
  fontFormatList?: { name: string; font: string }[];

  /**
   * An optional list of fonts to display in the google font family dropdown.
   * e.g. ([{name: "Arial", font: "arial,helvetica,sans-serif"}])
   */
  googleFontList?: { name: string; font: string }[];

  /**
   * The custom CSS style as a string that you want to use in the editor
   */
  style?: string;

  /**
   * Triggers (instantly) when a change is made in the rich editor
   */
  onInstantChange?: (html: string, fontList?: string[]) => void;

  /**
   * Triggers (debounced) when a change is made in the rich editor
   */
  onChange?: (html: string, fontList?: string[]) => void;

  /**
   * Triggers when the editor is entered by the mouse
   */
  onMouseEnter?: () => void;

  /**
   * Triggers when the editor is clicked
   */
  onClick?: (event: any) => void;

  /**
   * Triggers when the editor is focused on
   */
  onFocus?: (event: any) => void;

  /**
   * Triggers when the editor loses focus
   */
  onBlur?: (event: any) => void;

  /**
   * Triggers when a key is pressed while in the editor
   */
  onKeyCommand?: (event: any) => void;

  /**
   * The html content of the rich editor
   */
  content?: string;

  /**
   * Whether or not to display an inline control toolbar
   */
  disableInlineControls?: boolean;

  /**
   * Triggers when text is selected in the editor
   */
  onSelectText?: (event: any) => void;

  /**
   * Whether or not the rich editor is disabled
   */
  disabled?: boolean;

  /**
   * The desired position of the inline style controls
   */
  position?: RichEditorPosition;

  /**
   * The default text to show if no content is provided
   */
  placeholder?: string;

  /**
   * An optional function to supply for loading a Google Fonts Popup window
   */
  openGoogleFontSelector?: (editor: any) => void;

  ref?: React.LegacyRef<HTMLDivElement>;
}
