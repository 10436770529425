import React from "react";
export const HiddenFields: React.FC<{
  nextPage: any;
  emailPayload: any;
  merchantPayload: any;
  pageId: string;
  campaignId: string;
}> = ({ nextPage, emailPayload, merchantPayload, pageId, campaignId }) => {
  return (
    <>
      {emailPayload && (
        <input type="hidden" name="emP" value={unescape(emailPayload)} />
      )}
      {merchantPayload && (
        <input type="hidden" name="pmP" value={unescape(merchantPayload)} />
      )}
      {nextPage && <input type="hidden" name="nxP" value={nextPage} />}
      {pageId && <input type="hidden" name="pageId" value={pageId} />}
      {campaignId && <input type="hidden" name="campaignId" value={campaignId} />}
    </>
  );
};

export default HiddenFields