import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { IconTypes } from "../../../../../../webcomponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.TWELVE,
  layout: FormLayouts.TWELVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form12.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyvra3g2f8207806ga0wtnl",
    itemId: "ktm2i0441n",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: "Create Your Free Account",
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "sub_headline.style", {}),
        },
        html: lorem.generateSentences(2),
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 10,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Activate Your Free Account",
              },
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.FIRSTNAME,
                    placeholder: "First Name",
                    preset: FieldNamePresets.FIRSTNAME,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.LASTNAME,
                    placeholder: "Last Name",
                    preset: FieldNamePresets.LASTNAME,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.EMAIL,
                    placeholder: "Email",
                    preset: FieldNamePresets.EMAIL,
                  },
                ],
                properties: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                },
              },
              {
                type: ComponentTypes.FORMFIELD,
                canHaveChildren: false,
                attributes: {
                  placeholder: "Terms",
                  name: "Terms",
                  items: [
                    {
                      id: 0,
                      label: "I accept the terms and conditions",
                      value: false,
                    },
                  ],
                },
                fieldType: FormFieldTypes.CHECKBOXES,
                styles: {
                  containerStyle: {
                    ...style.FORM_FIELD.style,
                    marginTop: 20,
                  },
                  labelStyle: style.FORM_FIELD.labelStyle,
                },
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: style.BUTTON.style,
                caption1: "Next",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  borderRadius: 5,
                  backgroundColor: theme.backgroundColor,
                  padding: 30,
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.SUBHEADLINE,
                      color: theme.foregroundColor,
                    },
                    html: "What You Will Receive",
                  },
                  {
                    type: ComponentTypes.LIST,
                    canHaveChildren: false,
                    data: [
                      {
                        caption:
                          "<strong>Bonus #1:</strong> " +
                          startCase(lorem.generateWords(3)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                      {
                        caption:
                          "<strong>Bonus #2:</strong> " +
                          startCase(lorem.generateWords(3)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                      {
                        caption:
                          "<strong>Bonus #3:</strong> " +
                          startCase(lorem.generateWords(3)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                    ],
                  },
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    src: theme.logoLight,
                    style: {
                      marginTop: 40,
                      width: 200,
                      textAlign: "center",
                      display: "inline-block",
                    },
                  }),
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
