import * as React from "react";
import ReactPlayer from "react-player";

// import playIcon from "@launchos/lsvt/src/images/playIcon.png";
// import playIcon from "./playIcon.png";
import { themeSettings } from "../theme";
import { Animation } from "..";
import { AnimationType } from "../Animation/component";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

// const playIcon = require("./playIcon.png");

interface VideoProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  src?: string;
  style?: any;
  muted?: boolean;
  controls?: boolean;
  autoPlay?: boolean;
  onProgress?: () => any;
  videoThumbnail?: string;
  persistOnScroll?: boolean;
}

export const Video: React.FC<VideoProps> = ({
  style,
  src,
  muted,
  controls,
  onProgress,
  autoPlay,
  videoThumbnail,
  persistOnScroll,
}) => {
  const [isPlaying, setIsPlaying] = React.useState(autoPlay);
  const [isInViewport, setIsInViewport] = React.useState(true);

  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;


  // try {
  //   const thumbnail = videoThumbnail
  //     ? require(`@launchos/lsvt/src/images/${videoThumbnail}`)
  //     : false;
  // } catch (e) {
  //   const thumbnail = videoThumbnail;
  // }
  const thumbnail = videoThumbnail;

  const standardStyle = {
    ...style,
    backgroundImage: !isPlaying ? `url(${thumbnail})` : "none",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  React.useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 840) setIsInViewport(false);
      else setIsInViewport(true);
    });
  }, []);

  const PlayButton = () => {
    const [isHovering, setIsHovering] = React.useState(false);

    return (
      <Animation type={AnimationType.GROW} autoPlay={isHovering}>
        <p>Play Icon</p>
        {/* <img
          src={playIcon}
          style={{
            position: "absolute",
            marginTop: style.height / 2 - 85,
            marginLeft: -80,
            cursor: "pointer",
          }}
          onClick={() => setIsPlaying(true)}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        /> */}
      </Animation>
    );
  };

  const forcedViewStyle =
    persistOnScroll && !isInViewport && isPlaying
      ? {
        position: "fixed",
        width: 400,
        height: 220,
        left: 20,
        top: 20,
        border: `5px solid ${themeSettings.default.primaryColor}`,
        boxShadow: "0 0 20px rgb(0,0,0,0.75)",
      }
      : {};

  return (
    <>
      {!isPlaying && <PlayButton />}
      <div
        data-testid="WC-VIDEO-LIVE"
        style={{ ...standardStyle, ...forcedViewStyle }}
      >
        {isPlaying && (
          <ReactPlayer
            url={src}
            controls={controls}
            // controls
            width="100%"
            height="100%"
            muted={muted}
            playing={isPlaying}
            // playIcon={playIcon}
            onProgress={onProgress}
          />
        )}
      </div>
    </>
  );
};

Video.defaultProps = {
  autoPlay: false,
};

export default Video;
