import React from 'react'
import styled from 'styled-components'

import { Icon } from "@launchos/plugins/webcomponents/v2";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon/types";

import { ToolTip } from '@launchos/components/ui/theme'

type HelpButtonProps = {
  href: string;
  tooltip: string;
  style: React.CSSProperties;
}

const Link = styled.a`
    color: #999;
    transition: 250ms;
    &:hover {
        color: #333;
        transform: scale(1.3);
    }
`

export const HelpButton: React.FC<HelpButtonProps> = ({ href = "", tooltip = "", style = {} }) => {

  return (
    <ToolTip arrow title={
      <div style={{ padding: 10 }}>
        {tooltip}
      </div>
    }>
      <Link target="_blank" href={href} style={style}>
        <Icon style={{ fontSize: '14pt' }} type={IconTypes.ErrorOutline} />
      </Link>
    </ToolTip>
  )
}

export default HelpButton;