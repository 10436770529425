import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // padding: 20,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "16pt",
    fontFamily: theme.fontFamily,
    color: theme.foregroundColor,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    color: theme.foregroundColor,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  COUNTDOWN: {
    style: {
      margin: "0 auto",
      display: "inline-block",
      backgroundColor: `rgb(0,0,0,0.35)`,
      padding: 25,
    },
    dimensionStyle: {
      minWidth: 75,
    },
    numberStyle: {
      textAlign: "center",
      fontSize: "26pt",
      fontFamily: theme.fontFamily,
      fontWeight: "bold" as "bold",
      color: theme.foregroundColor,
    },
    labelStyle: {
      fontWeight: "bold" as "bold",
      fontFamily: theme.fontFamily,
      color: theme.foregroundColor,
      fontSize: "9pt",
      padding: 5,
    },
    dividerStyle: {
      padding: 10,
      fontWeight: "bold" as "bold",
      fontFamily: theme.fontFamily,
      color: theme.foregroundColor,
    },
  },
};
