import _ from "lodash";
import {
  Button,
  Dialog,
  DialogBody,
  Grid,
  Cell,
  Avatar,
} from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React from "react";
import { Link } from "react-router-dom";
import gravatar from "gravatar";

import { client } from "@launchos/api/gql";
import { getCampaign } from "@launchos/api/gql/campaigns";

export class ShareLinkPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      success: false,
    };
  }

  static contextType = AppConfig;

  render() {
    window.scroll(0, 0);

    return (
      <Dialog {...this.props} style={{ width: 700 }} centered>
        <DialogBody>
          <img
            style={{ margin: 10, width: 100 }}
            alt=""
            src="https://sandcastleassets.s3.amazonaws.com/images/duplicate-icon.png"
          />

          <h2>Share this campaign</h2>

          <div
            style={{
              margin: "30px 0",
              width: 600,
            }}
          >
            <input
              style={{
                textAlign: "center",
                width: "100%",
                padding: 20,
                fontSize: "12pt",
                borderRadius: 5,
                border: "1px solid #CCC",
              }}
              readOnly
              onClick={(e) => e.target.select()}
              value={`${window.location.origin}/campaign/${this.props.campaignData.id}/share`}
            />
          </div>

          <Grid justify="space-evenly" style={{ width: 250, margin: "0 auto" }}>
            <Cell align="center">
              <Button onClick={this.props.onClose} large tertiary>
                OK
              </Button>
            </Cell>
            <Cell align="center">
              <Button
                onClick={this.props.onClose}
                large
                transparent
                style={{ color: "#333" }}
              >
                Cancel
              </Button>
            </Cell>
          </Grid>
        </DialogBody>
      </Dialog>
    );
  }
}

export class ShareCampaignConfirmationPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      campaign: {},
    };

    this.cloneCampaign = this.cloneCampaign.bind(this);
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { campaignId } = this.props.match.params;

    // get campaign details
    const { data } = await client.mutate({
      mutation: getCampaign,
      variables: {
        id: campaignId,
      },
    });

    this.setState({
      campaign: data.campaign,
    });
  }

  async cloneCampaign() {
    const { campaign } = this.state;
    const { id } = campaign;
    const { userId } = localStorage;

    await this.props.cloneCampaign(id, userId);

    window.location.href = "/dashboard/campaigns";
  }

  render() {
    window.scroll(0, 0);

    const settings = this.context;

    const { campaign } = this.state;

    return (
      <Dialog {...this.props} style={{ width: 700 }} centered>
        <DialogBody>
          <Avatar
            src={gravatar.url(_.get(campaign, "user.email"))}
            role="presentation"
            alt="Your Avatar"
            style={{ width: 80, height: 80 }}
          />

          <div>
            <h2 style={{ padding: 40 }}>
              {_.get(campaign, "user.firstName")}{" "}
              {_.get(campaign, "user.lastName")} would like to share their "
              {campaign.name}" campaign with you.
            </h2>

            <Grid
              justify="space-evenly"
              style={{ width: 400, margin: "0 auto" }}
            >
              <Cell align="center">
                <Button onClick={this.cloneCampaign} large tertiary>
                  Accept It
                </Button>
              </Cell>
              <Cell align="center">
                <Link to={this.props.closeHref}>
                  <Button large transparent style={{ color: "#333" }}>
                    Maybe Later
                  </Button>
                </Link>
              </Cell>
            </Grid>
          </div>

          {this.state.loading && (
            <div style={{ textAlign: "center" }}>
              <img src={settings.app.loadingImage} alt="Loading..." />
            </div>
          )}
        </DialogBody>
      </Dialog>
    );
  }
}
