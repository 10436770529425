import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { CTALayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/CTA/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.SEVEN,
  layout: CTALayouts.SEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA07.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx8ufdx24fg0780ybqe4j4m",
    itemId: "x0f06i4hweb",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                src:
                  "https://images.unsplash.com/photo-1589465885857-44edb59bbff2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
                style: {
                  // height: "100%",
                  backgroundColor: theme.backgroundColor,
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 75,
                  mobile: {
                    desktop: { padding: 75 },
                    smartphone: { padding: 20 },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: get(content, "main_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: {
                      ...style.BUTTON.style,
                      maxWidth: 150,
                      marginTop: 40,
                      backgroundColor: "transparent",
                      border: `1px solid ${theme.backgroundColor}`,
                    },
                    caption1: "Get Started",
                    caption1style: {
                      ...style.BUTTON.caption1,
                      color: theme.backgroundColor,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
