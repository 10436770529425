import _ from "lodash";
import React from "react";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import * as Tools from "@launchos/modules/editor/Builder/Properties";
import { Switch } from "@launchos/components/ui/Inputs";
import { PopupInner, PopupOuter } from "./component";
import style from "./style";

class PopupBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupBackgroundShowing: _.get(
        props.settings,
        "isPopupBackgroundShowing",
        true
      ),
      isCloseButtonShowing: _.get(props.settings, "isCloseButtonShowing", true),
    };
    this.onBackgroundChange = this.onBackgroundChange.bind(this);
    this.onCloseButtonChange = this.onCloseButtonChange.bind(this);
  }

  onBackgroundChange() {
    const { settings, updateComponentSettings } = this.props;

    this.setState(
      (prevState) => ({
        isPopupBackgroundShowing: !prevState.isPopupBackgroundShowing,
      }),
      () => {
        updateComponentSettings(
          settings.id,
          {
            ...settings,
            isPopupBackgroundShowing: !_.get(
              settings,
              "isPopupBackgroundShowing",
              true
            ),
          },
          true,
          false
        );
      }
    );
  }

  onCloseButtonChange() {
    const { settings, updateComponentSettings } = this.props;

    this.setState(
      (prevState) => ({
        isCloseButtonShowing: !prevState.isCloseButtonShowing,
      }),
      () => {
        updateComponentSettings(
          settings.id,
          {
            ...settings,
            isCloseButtonShowing: !_.get(
              settings,
              "isCloseButtonShowing",
              true
            ),
          },
          true,
          false
        );
      }
    );
  }

  render() {
    // const { settings } = this.props;
    return (
      <Tools.Section label="Basic Settings" icon="settings" isExpanded>
        <div style={{ textAlign: "left", padding: 0 }}>
          <Switch
            // checked={_.get(settings, "isPopupBackgroundShowing", true)}
            checked={this.state.isPopupBackgroundShowing}
            onChange={this.onBackgroundChange}
          />
          <span style={{ position: "relative", padding: 0, top: 0 }}>
            Show Faded Background
          </span>
        </div>
        <div style={{ textAlign: "left", padding: 0 }}>
          <Switch
            // checked={_.get(settings, "isPopupBackgroundShowing", true)}
            checked={this.state.isCloseButtonShowing}
            onChange={this.onCloseButtonChange}
          />
          <span style={{ position: "relative", padding: 0, top: 0 }}>
            Show the [Close] Button
          </span>
        </div>
      </Tools.Section>
    );
  }
}

const BasicPropertiesTab = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <PopupBackground {...props} />
      <Tools.PositionPadding {...props} minValue={0} maxValue={500} />
      <Tools.WidthHeight label="Popup Size" {...props} />
      <Tools.BordersShadow {...props} />
    </div>
  );
};

const Properties = (props) => (
  <Tools.PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Popup", component: BasicPropertiesTab }]}
  />
);

export default (props) => {
  const { settings } = props;
  return (
    <PopupOuter {...props}>
      <EditorObject {...props} PropertiesView={Properties}>
        {_.get(settings, "isCloseButtonShowing", true) && (
          <div
            style={style.popupClose}
            onClick={() =>
              props.updateComponentSettings(
                settings.id,
                {
                  ...settings,
                  properties: {
                    ...settings.properties,
                    display: "none",
                  },
                },
                true,
                false
              )
            }
          />
        )}
        <PopupInner {...props} />
      </EditorObject>
    </PopupOuter>
  );
};
