// @ts-nocheck
import * as React from "react";
import { ReactSVG } from "react-svg";
import _ from "lodash";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

export enum ShapeTypes {
  CIRCLE,
  RECTANGLE,
  ELLIPSE,
  LINE,
  POLYLINE,
  POLYGON,
  PATH,
  POLYPATH,
  FILE,
}

interface ShapeProps {
  mode?: EditorMode;
  type: ShapeTypes;
  style?: any;
  data?: any[];
  before?: Boolean;
  after?: Boolean;
  url?: string | undefined;
}

export const Shape: React.FC<ShapeProps> = ({ type, style, data, url }) => {
  if (type === ShapeTypes.CIRCLE) {
    return <circle {...style} style={style} />;
  }

  if (type === ShapeTypes.RECTANGLE) {
    return <rect style={style} />;
  }

  if (type === ShapeTypes.ELLIPSE) {
    return <ellipse style={style} />;
  }

  if (type === ShapeTypes.LINE) {
    return <line {...style} style={style} />;
  }

  if (type === ShapeTypes.POLYLINE) {
    return <polyline {...style} style={style} />;
  }

  if (type === ShapeTypes.POLYGON) {
    return <polygon {...style} style={style} />;
  }

  if (type === ShapeTypes.PATH) {
    return <path {...style} style={style} />;
  }

  if (type === ShapeTypes.POLYPATH) {
    return data.map((p: any) => <path {...p} style={p} />);
  }

  if (type === ShapeTypes.FILE) {
    return <ReactSVG src={url} style={style} />;
  }
};

interface ShapesProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  style?: any;
  data?: ShapeProps[];
  clipPathId?: string;
}

export const Shapes: React.FC<ShapesProps> = ({
  children,
  style,
  data,
  clipPathId,
}) => {
  interface WrapProps {
    mode?: EditorMode;
    children: React.ReactNode;
  }

  const SVGWrap: React.FC<WrapProps> = ({ children }) => (
    <svg
      data-testid="WC-SHAPES-LIVE"
      width="100%"
      height="100%"
      style={{ position: "absolute" }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {children}
    </svg>
  );

  const Wrap: React.FC<WrapProps> = ({ children }) => (
    <div style={{ position: "absolute" }}>{children}</div>
  );

  interface RSProps {
    mode?: EditorMode;
    data: ShapeProps[];
    filter: string;
    type?: ShapeTypes;
  }

  const RenderShapes: React.FC<RSProps> = ({ data, filter, type }) =>
    data
      .filter((itm: any) => itm[filter] && (type ? itm.type === type : true))
      .map((shape: any) => <Shape {...shape} />);

  if (clipPathId)
    return (
      <SVGWrap>
        <defs>
          <clipPath id={clipPathId}>
            <RenderShapes data={data} filter="before" />
          </clipPath>
        </defs>
      </SVGWrap>
    );

  if (!data) return <SVGWrap>{children}</SVGWrap>;

  return (
    <div style={style}>
      <SVGWrap>
        <RenderShapes data={data} filter="before" />
      </SVGWrap>
      <Wrap>
        <RenderShapes data={data} filter="before" type={ShapeTypes.FILE} />
      </Wrap>
      {children && <div style={{ position: "absolute" }}>{children}</div>}
      <Wrap>
        <RenderShapes data={data} filter="after" type={ShapeTypes.FILE} />
      </Wrap>
      <SVGWrap>
        <RenderShapes data={data} filter="after" />
      </SVGWrap>
    </div>
  );
};

export default Shapes;
