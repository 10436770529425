export default [
  {
    type: "shopping_cart",
    id: "shopping_cart_1shoppingcart",
    cart_name: "1 ShoppingCart",
    description: "Creates a link that automatically sends visitors to a 1 ShoppingCart order form",
    name: "ShoppingCart Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_1shoppingcart.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_amazon",
    cart_name: "Amazon",
    description: "Creates a link that automatically sends visitors to an Amazon order form",
    name: "Amazon Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_amazon.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_clickbank",
    cart_name: "ClickBank",
    description: "Creates a link that automatically sends visitors to a ClickBank order form",
    name: "ClickBank Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_clickbank.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_infusionsoft",
    cart_name: "Infusionsoft",
    description: "Creates a link that automatically sends visitors to an Infusionsoft order form",
    name: "Infusionsoft Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_infusionsoft.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_jvzoo",
    cart_name: "JVZoo",
    description: "Creates a link that automatically sends visitors to a JVZoo order form",
    name: "JVZoo Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_jvzoo.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_nanacast",
    cart_name: "Nanacast",
    description: "Creates a link that automatically sends visitors to a Nanacast order form",
    name: "Nanacast Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_nanacast.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_paypal",
    cart_name: "PayPal",
    description: "Creates a link that automatically sends visitors to a PayPal order form",
    name: "PayPal Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_paypal.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_samcart",
    cart_name: "SamCart",
    description: "Creates a link that automatically sends visitors to a SamCart order form",
    name: "SamCart Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_samcart.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_shopify",
    cart_name: "Shopify",
    description: "Creates a link that automatically sends visitors to a Shopify order form",
    name: "Shopify Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_shopify.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_ultracart",
    cart_name: "Ultracart",
    description: "Creates a link that automatically sends visitors to a Ultracart order form",
    name: "Ultracart Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_ultracart.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_zaxaa",
    cart_name: "Zaxaa",
    description: "Creates a link that automatically sends visitors to a Zaxaa order form",
    name: "Zaxaa Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_zaxaa.png`
  },
  {
    type: "shopping_cart",
    id: "shopping_cart_another",
    name: '"Another" Shoping Cart',
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_another.png`
  }
];
