import PropTypes from "prop-types";
import React from "react";
import { Section } from "..";

/**
 * Show's a small preview of the component (should zoom to fit)
 */
export const ObjectPreview = props => {
  const desiredWidth = 200;
  let zoom = 1;

  if (props.objCoordinates) {
    const calculatedZoom = desiredWidth / props.objCoordinates.width;
    zoom = calculatedZoom > 1 ? 1 : calculatedZoom;
  }

  const viewPort = {
    textAlign: "center",
    zoom: props.zoom === "auto" ? zoom : props.zoom
  };

  return (
    <Section label="Preview" icon="visibility" {...props}>
      <div style={{ ...viewPort, width: "100%", maxHeight: 300 }}>
        {props.children}
      </div>
    </Section>
  );
};

ObjectPreview.propTypes = {
  /** This is the ?liveComponent that will be rendered live when published */
  children: PropTypes.element.isRequired,
  /** This is a way to set the background color of the preview view port */
  color: PropTypes.string,
  objCoordinates: PropTypes.objectOf(PropTypes.number)
};

ObjectPreview.defaultProps = {
  color: "#DDD",
  objCoordinates: false
};

export default ObjectPreview;
