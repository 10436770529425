import {
  allCampaigns,
  createCampaign,
  createCampaignObject,
  updateCampaignObject
} from "@launchos/api/gql/campaigns";

import {
  allCatalogItems,
  createCatalogItem,
  deleteCatalogItem,
  deployCatalogItem,
  updateCatalogItem
} from "@launchos/api/gql/catalog";

import {
  createPage
} from "@launchos/api/gql/pages";

import { getUser } from "@launchos/api/gql/users";

import { compose, graphql } from "react-apollo";

import Catalog from "./Catalog";

export default compose(
  graphql(allCampaigns, {
    name: "allCampaignsQuery",
    options: props => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),
  graphql(createCampaign, {
    name: "createCampaign"
  }),
  graphql(createPage, {
    name: "createPage"
  }),
  graphql(allCatalogItems, {
    name: "allCatalogItemsQuery",
    options: props => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),
  graphql(updateCampaignObject, {
    name: "updateCampaignObject"
  }),
  graphql(createCampaignObject, {
    name: "createCampaignObject"
  }),
  graphql(getUser, {
    name: "getUserQuery",
    options: () => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),
  graphql(updateCatalogItem, { name: "updateCatalogItem" }),
  graphql(createCatalogItem, { name: "createCatalogItem" }),
  graphql(deleteCatalogItem, { name: "deleteCatalogItem" }),
  graphql(deployCatalogItem, { name: "deployCatalogItem" })
)(Catalog);
