import * as React from "react";
import { Grid } from "@material-ui/core";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface StepperProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  style?: any;
}

export const Stepper: React.FC<StepperProps> = ({ children, style }) => {
  return (
    <Grid data-testid="WC-STEPPER-LIVE" container style={style}>
      {children}
    </Grid>
  );
};

export default Stepper;
