import { client } from "@launchos/api/gql/client";
// // import { app } from "../../../config";
// import myStyle from "../../page/style";
import _ from "lodash";
import { createDomainOnServer } from "@launchos/api/gql/domains";
import { Dialog, DialogBody, DialogTitle } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { DomainAddOne } from "./DomainAddOne";
import { DomainChooseOne } from "./DomainChooseOne";
import { DomainHasContent } from "./DomainHasContent";
import { DomainSubdomain } from "./DomainSubdomain";
import { DomainSuccess } from "./DomainSuccess";
import { DomainTutorial } from "./DomainTutorial";

/**
 * This is the popup window that displays when a user attempts to publish their page
 * @param {object} props The props
 * @returns {function} The component
 */

class DomainSetup extends Component {
  constructor(props) {
    super();

    this.state = {
      step: props.step,
      domainNames: [],
      value: "",
      domainId: "",
    };

    this.addDomain = this.addDomain.bind(this);
    this.useDomain = this.useDomain.bind(this);
    // this.getDomainInfo = this.getDomainInfo.bind(this);
    this.updateDomainInfo = this.updateDomainInfo.bind(this);
    this.updateDomainData = this.updateDomainData.bind(this);
    this.changeStep = this.changeStep.bind(this);
    // this.getCurrentCampaignIds = this.getCurrentCampaignIds.bind(this);

    window.scroll(0, 0);
  }

  static contextType = AppConfig;

  updateDomainData(nextState) {
    const { match, allDomainsQuery } = this.props;
    const domainNames = allDomainsQuery.domains;

    if (!nextState.domainNames.length) {
      const campaignDomainObject = _.filter(domainNames, (itm) => {
        if (itm.campaign.length) {
          const matchingCampaign = _.filter(
            itm.campaign,
            (i) => i.id === match.params.campaignId
          );
          return matchingCampaign.length ? itm.name : false;
        }

        return false;
      });

      const domain = _.head(campaignDomainObject);

      this.setState({
        domainNames,
        value: _.get(domain, "name", false),
        domainId: _.get(domain, "id", false),
      });
    }
  }

  componentDidMount() {
    this.updateDomainData(this.state);
  }

  // getCurrentCampaignIds() {
  //   const { allDomainsQuery } = this.props;
  //   const { domainId } = this.state;

  //   const domainObject = allDomainsQuery.domains.filter(
  //     (domain) => domain.id === domainId
  //   );
  //   const campaignIds = domainObject.length
  //     ? domainObject[0].campaign.map((itm) => itm.id)
  //     : [];
  //   console.log({ domainObject }, { campaignIds });

  //   return campaignIds;
  // }

  async addDomain() {
    const {
      match,
      createCampainOfDomain,
      allDomainsQuery,
      createDomain,
      campaignId,
      getUserQuery,
    } = this.props;
    const { params } = match;
    const { app } = this.context;

    // add new ssl domain here
    const domain = this.state.value;

    const subdomain = getUserQuery.user.subdomain;
    const defaultDomain = _.get(getUserQuery, 'user.apps[0].defaultDomain', app.domain);
    const proxyPass = `http://${subdomain}.${defaultDomain}`;

    // this a bad way to get the main domain name (won't work for everybody)
    // const proxyPass = `http://${_.head(allDomainsQuery.domains.filter(({ name }) => name.indexOf(subdomain) > -1))["name"]}`;
    console.log({ subdomain, proxyPass }, getUserQuery.user);

    const variables = {
      domain,
      ssl: false,
      email: localStorage.email,
      proxyPass,
    };

    console.log("DOMAIN SHOULD BE ADDING NOW", variables);

    try {
      const cdsResponse = await client.mutate({
        mutation: createDomainOnServer,
        variables,
      });

      console.log("Response from Sites Server", {
        campaignId,
        domain,
        cdsResponse,
      });
    } catch (err) {
      console.log('createDomainOnServer Response', { error: err })
    }

    if (campaignId) {
      // const campaignIds = this.getCurrentCampaignIds();
      const variables = {
        userId: localStorage.userId,
        name: domain,
        campaignId: params.campaignId,
        // campaignIds: params.hasOwnProperty("campaignId")
        //   ? [...campaignIds, params.campaignId]
        //   : false,
        cloudfrontDistribution: app.cloudfrontDistribution,
      };

      console.log({ variables, name: this.state.value });
      createCampainOfDomain({
        variables,
      }).then((json) => {
        allDomainsQuery.refetch({ userId: localStorage.userId });
      });
    } else {
      try {
        await createDomain({
          variables: {
            userId: localStorage.userId,
            name: domain,
            cloudfrontDistribution: app.cloudfrontDistribution,
          },
        }).then((json) => {
          allDomainsQuery
            .refetch({ userId: localStorage.userId })
            .then((json) => {
              this.changeStep("success");
            });
        });
      } catch (err) {
        console.log('createDomain Response', { error: err });
      }
    }
  }

  useDomain() {
    const { domainId, value } = this.state;
    const {
      history,
      allDomainsQuery,
      updateCampaignOfDomain,
      getCampaignQuery,
      campaignId,
    } = this.props;

    if (value !== "") {
      // const campaignIds = this.getCurrentCampaignIds();
      updateCampaignOfDomain({
        variables: {
          id: domainId,
          userId: localStorage.userId,
          name: value,
          campaignIds: [
            // ...campaignIds.map(obj => ({ id: obj.id })),
            { id: campaignId }, // this is an issue, it makes it so that there's only one campaign per domain
          ], // get entire list of domains and append, not replace
        },
      }).then((json) => {
        allDomainsQuery
          .refetch({ userId: localStorage.userId })
          .then((json) => {
            if (campaignId) {
              getCampaignQuery.refetch({ campaignId }).then((json) => {
                history.push(`/campaign/${campaignId}/edit`);
                window.setTimeout(
                  () =>
                    alert(
                      "Your settings have been updated.  Please re-publish this funnel for your changes to go live!"
                    ),
                  150
                );
              });
            } else {
              history.push("/settings/domains");
            }
          });
      });
    }
  }

  updateDomainInfo(obj) {
    this.setState(obj);
  }

  // getDomainInfo() {
  //   return this.state;
  // }

  changeStep(step) {
    this.setState({ step });
  }

  render() {
    const { app } = this.context;
    const { step } = this.state;
    const { allDomainsQuery, campaignId } = this.props;

    const domainFunctions = {
      changeStep: this.changeStep,
      updateDomainInfo: this.updateDomainInfo,
      useDomain: this.useDomain,
      addDomain: this.addDomain,
      // getDomainInfo: this.getDomainInfo,
      domainInfo: this.state,
    };

    const closeHref =
      localStorage.builderType === "editor" ? "../builder" : "../edit";

    if (this.props.hidden) return null;

    return (
      <Dialog
        {...this.props}
        // style={{ width: 800 }}
        // style={style.popup}
        // overlayStyle={style.popupBg}
        centered
        closeHref={campaignId ? closeHref : "../domains"}
      >
        <DialogTitle>CUSTOM DOMAIN NAME</DialogTitle>
        <DialogBody>
          {allDomainsQuery.loading || allDomainsQuery.error ? (
            <div style={{ textAlign: "center" }}>
              <img src={app.loadingImage} alt="Loading..." />
            </div>
          ) : (
            <div>
              {step.toUpperCase() === "CHOOSE" ? (
                <DomainChooseOne {...this.props} {...domainFunctions} />
              ) : null}
              {step.toUpperCase() === "ADD" ? (
                <DomainAddOne {...this.props} {...domainFunctions} />
              ) : null}
              {step.toUpperCase() === "CONTENT" ? (
                <DomainHasContent {...this.props} {...domainFunctions} />
              ) : null}
              {step.toUpperCase() === "SUBDOMAIN" ? (
                <DomainSubdomain {...this.props} {...domainFunctions} />
              ) : null}
              {step.toUpperCase() === "TUTORIAL" ? (
                <DomainTutorial {...this.props} {...domainFunctions} />
              ) : null}
              {step.toUpperCase() === "SUCCESS" ? (
                <DomainSuccess {...this.props} {...domainFunctions} />
              ) : null}
            </div>
          )}
        </DialogBody>
      </Dialog>
    );
  }
}

DomainSetup.propTypes = {
  /** Boolean whether or not to display the section */
  hidden: PropTypes.bool,

  domains: PropTypes.arrayOf(PropTypes.object),
};
DomainSetup.defaultProps = {
  hidden: false,
  step: "add",
  campaignId: false,
};

export default DomainSetup;
