import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "center",
    padding: 20,
    fontFamily: theme.fontFamily,
  },
  VIDEO: {
    maxWidth: 500,
    margin: "0 auto",
    padding: "100px 20px",
    backgroundColor: theme.backgroundColor,
    position: "relative",
  },
  ICON: {
    display: "inline-block",
    color: theme.foregroundColor,
    fontSize: "10pt",
    border: `1px solid ${theme.foregroundColor}`,
    borderRadius: "100%",
    padding: 10,
  },
  LIST: {
    paddingLeft: 0,
    marginTop: 20,
  },
  LI: {
    style: {
      fontFamily: theme.fontFamily,
      padding: "10px 0",
    },
    iconStyle: {
      color: theme.backgroundColor,
      fontSize: "22pt",
      paddingRight: 20,
      marginTop: -6,
    },
  },
};
