import * as React from "react";
import { Grid } from "@material-ui/core";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface GridItemProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  xs?: any;
  sm?: any;
  md?: any;
  style?: any;
}

export const GridItem: React.FC<GridItemProps> = (props) => {
  const { children } = props;

  return (
    <Grid data-testid="WC-GRIDITEM-LIVE" item {...props}>
      {children}
    </Grid>
  );
};

export default GridItem;
