import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: "rgb(0,0,0,0.73)",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1588617312276-deeae375da60?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 80,
      },
      smartphone: {
        padding: 40,
      },
    },
  },
  SUB_HEADLINE: {
    textAlign: "center",
    fontSize: "14pt",
    lineHeight: "14pt",
    fontFamily: theme.fontFamily,
    padding: 20,
    color: theme.foregroundColor,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: "Poppins",
    // fontWeight: 200,
    fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
    mobile: {
      desktop: {
        fontSize: 48,
        lineHeight: "55px",
      },
      smartphone: {
        fontSize: 32,
        lineHeight: "34px",
      },
    },
  },
  TEXT: {
    textAlign: "center",
    padding: 20,
    fontFamily: theme.fontFamily,
    color: theme.foregroundColor,
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
    // padding: "100px 20px",
    // background: theme.foregroundColor,
    position: "relative" as "relative",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      textAlign: "center",
      padding: 15,
      border: "none",
      background: theme.foregroundColor,
      width: 150,
      margin: "0 auto",
      borderRadius: 3,
      cursor: "pointer",
      marginTop: 50,
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  ICON: {
    display: "inline-block",
    color: theme.foregroundColor,
    fontSize: "10pt",
    border: `1px solid ${theme.foregroundColor}`,
    borderRadius: "100%",
    padding: 10,
  },
};
