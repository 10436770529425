import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 100,
      },
      smartphone: {
        padding: 20,
      },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "14pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    // marginTop: 20,
    lineHeight: "18pt",
  },
  ICON: {
    borderRadius: "100%",
    backgroundColor: "transparent",
    padding: 10,
    display: "inline-block",
    color: theme.backgroundColor,
    border: `1px solid ${theme.backgroundColor}`,
    // marginBottom: 20,
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 10,
      marginTop: 40,
      border: "none",
      backgroundColor: theme.backgroundColor,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  LI: {
    color: theme.backgroundColor,
    fontFamily: theme.fontFamily,
    padding: "10px 0",
  },
};
