import React from "react";
import { get } from "lodash";

import {
  convertToRem,
  convertFromRem,
} from "@launchos/modules/editor/Builder/MobileResponsiveToggle/style";
import { MobileState } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";

import ButtonSliderGroup from "../BuildingBlocks/ButtonSliderGroup";

import { HeightProps } from "./types";

/**
 * Creates a Button Slider Group to Change the Components Height
 */
const Height: React.FC<HeightProps> = ({
  updateComponentStyle = () => null,
  onChange = () => null,
  settings = {},
  objCoordinates = {},
  minValue = 10,
  maxValue,
  parentCoordinates = {},
  heightLabel = "Height",
  getMobileState = () => ({
    type: MobileState.FULLSCREEN,
  }),
  useRem = true,
  showAutoCheck = true
}) => {
  const currentHeight = get(settings, "properties.height", 0);

  // send raw pixel value to the button slider even if input value is in rem
  const convertedValue = convertFromRem(
    currentHeight,
    getMobileState()["type"]
  );

  const handleChange = (value, shouldDbUpdate = true, wait = 500) => {
    const newHeight =
      convertedValue === "auto" || !convertedValue
        ? objCoordinates.height
        : convertedValue;

    const heightOffset =
      value === "SHRINK" ? Number(newHeight) - 10 : Number(newHeight) + 10;

    const useThisHeight = Number.isInteger(value) ? value : heightOffset;

    // convert the raw pixel value to rem before saving it the db
    const finalValue = useRem
      ? convertToRem(useThisHeight, getMobileState()["type"])
      : useThisHeight;

    const newStyle = value
      ? {
        height: finalValue,
        overflow: "visible",
      }
      : {
        height: "auto",
        overflow: "visible",
      };

    updateComponentStyle(settings.id, newStyle, shouldDbUpdate, wait);

    onChange();
  };

  const autoCheckValue = !(
    Number.isInteger(convertedValue) ||
    convertedValue === "100%" ||
    String(convertedValue).indexOf("rem") > -1
  ) || !currentHeight

  return (
    <div data-testid="Properties-Height">
      <ButtonSliderGroup
        showAutoCheck={showAutoCheck}
        onChange={handleChange}
        text={heightLabel}
        button1={{ icon: "remove", value: "SHRINK" }}
        button2={{ icon: "add", value: "GROW" }}
        minValue={minValue}
        maxValue={maxValue || parentCoordinates.height || 1000}
        value={convertedValue || objCoordinates.height || 0}
        originalValue={objCoordinates.height}
        autoCheckValue={Number(autoCheckValue)}
      />
    </div>
  );
};

export default Height;
