import * as React from "react";

export interface FilterTypes {}

export enum CommonProfiles {
  DATE_OPERATORS = "DATE_OPERATORS",
  QUICK_DATES = "QUICK_DATES",
  QUICK_DATES_RANGE = "QUICK_DATES_RANGE",
  NUMBER_OPERATORS = "NUMBER_OPERATORS",
  STRING_OPERATORS = "STRING_OPERATORS",
  BOOLEAN_OPERATORS = "BOOLEAN_OPERATORS",
  URL = "URL",
  FORM_FIELDS = "FORM_FIELDS",
  TAG_LIST = "TAG_LIST",
  COLLECTION_FIELDS = "COLLECTION_FIELDS",
}

export enum DateItems {
  IS = "IS",
  IS_BEFORE = "IS_BEFORE",
  IS_ON_OR_BEFORE = "IS_ON_OR_BEFORE",
  IS_AFTER = "IS_AFTER",
  IS_ON_OR_AFTER = "IS_ON_OR_AFTER",
  IS_BETWEEN = "IS_BETWEEN",
}

export enum BooleanItems {
  IS = "IS",
  IS_NOT = "IS_NOT",
}

export enum StringItems {
  IS = "IS",
  IS_NOT = "IS_NOT",
  CONTAINS = "CONTAINS",
  DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  IS_EMPTY = "IS_EMPTY",
  IS_NOT_EMPTY = "IS_NOT_EMPTY",
}

export enum NumberItems {
  EQUALS = "===",
  DOES_NOT_EQUAL = "!==",
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUALS = ">=",
  LESS_THAN_OR_EQUALS = "<=",
}

export enum QuickDates {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  PAST_MONTH = "PAST_MONTH",
  PAST_WEEK = "PAST_WEEK",
  CUSTOM_DATE = "CUSTOM_DATE",
  CUSTOM_DATE_RANGE = "CUSTOM_DATE_RANGE",
}

export type LogicBuilderStyles = {
  valueSelector?: React.CSSProperties;
};

export interface LogicBuilderProps {
  // returns the condition object (as well as an sql (later) string)
  // onChange?: (data: FilterTypes[]) => any;
  onChange?: (
    name: string,
    value: string | number | { start: string; end: string }
  ) => void;

  // the current value object representing the rule/condition
  value?: any;

  // the field profile
  data: FilterTypes[];

  // For handling any unknown types (e.g. operator, dimension, value, etc.) or value entry components
  // typeOverride?: any;

  styles?: LogicBuilderStyles;
}
