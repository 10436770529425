import * as React from "react";

import { TextProps } from "./types";
import { get } from "lodash";

/**
 * Text Component
 */
export const Text: React.FC<TextProps> = (props) => {
  const { children, mode, id, style, properties } = props;

  const marginTop = get(properties, "marginTop", 0);
  const textAlign = get(properties, "textAlign", "inherit");

  return (
    <div data-testid="WC-TEXT-LIVE" style={{ ...style, ...properties }}>
      <div style={{ marginTop, textAlign }}>{children}</div>
    </div>
  );
};

Text.defaultProps = {
  style: {},
};

export default Text;
