import React from "react";
import { get, uniq, keys } from "lodash";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import {
  RichEditor,
  PositionPadding,
  Alignment,
  Interactions,
  PropertiesWindow,
} from "@launchos/modules/editor/Builder/Properties";

import {
  generateInlineStyle,
  generateGoogleFontList,
} from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";

import { useDimensions } from "@launchos/plugins/utilities";

import style from "./style";
import { checkForSmartCopyIntegration } from '@launchos/plugins/misc/v2/smartcopy/client/scripts'

const TextContentEdit = (props) => {
  const [ref, hoverBoundingRect] = useDimensions();
  const [dragHandleIsShowing, setDragHandleIsShowing] = React.useState(true);
  const [usesSmartCopy, setUsesSmartCopy] = React.useState(false);

  const {
    settings,
    updateComponentSettings,
    pageContent,
    setIsEditing,
    setCanDrag,
    getCanDrag,
    changeState,
    setCanUndo,
  } = props;

  const handleOnLoad = async () => {
    const usesIt = await checkForSmartCopyIntegration();
    // console.log({ usesIt })
    setUsesSmartCopy(usesIt)
  }

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["bullist", "numlist"],
    ["outdent", "indent"],
    ["lineheight"],
    ["link"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    usesSmartCopy ? ["smartcopy"] : [],
    ["fontformats", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link", "selectall"],
  ];

  // get list of fonts used
  const googleFontList = generateGoogleFontList(settings, pageContent);

  const handleChange = (html, fontsList) => {
    const currentFontList = get(
      settings,
      "fontsUsed",
      []
    ).map((arr) => get(arr, "fontFamily"));
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({
      fontFamily: font,
    }));
    updateComponentSettings(settings.id, {
      ...settings,
      html,
      fontsUsed,
    });
  }

  React.useEffect(() => {
    handleOnLoad()
  }, [])

  // React.useEffect(() => {
  //   console.log("Text Editor Props Changed", settings.html);
  // }, [settings.html])

  return (
    <EditorObject
      {...props}
      PropertiesView={Properties}
      dragHandle={dragHandleIsShowing}
      label="Text"
      noOffset={get(settings, 'html', '').length < 20}
    >
      <div
        style={{ ...settings.properties, marginTop: 0 }}
      // ref={ref}
      >
        <span style={style.span}>
          <RichEditor
            id={`ed_${settings.id}`}
            content={settings.html}
            style={generateInlineStyle(settings)}
            buttonList={buttonList}
            menuList={menuList}
            googleFontList={googleFontList}
            onChange={handleChange}
            onInstantChange={() => setDragHandleIsShowing(false)}
            onClick={() => {
              setDragHandleIsShowing(true);
            }}
            onMouseEnter={() => {
              setDragHandleIsShowing(true);
            }}
            onFocus={(e) => {
              console.log("Text Editor Focus", getCanDrag());
              setIsEditing(true);
              setCanDrag(false);
              changeState(settings.id, "active");
              setCanUndo(false);
            }}
            onBlur={(e) => {
              console.log("Text Editor Blur", getCanDrag());
              setDragHandleIsShowing(true);
              setCanUndo(true);
              setTimeout(() => {
                setIsEditing(false);
                setCanDrag(true);
              }, 200);
            }}
          // disabled={props.show === "preview" || isDragging}
          // disabled={props.show === "preview"}
          // disabled={isDragging}
          // disabled
          // placeholder="Click here to begin entering content..."
          />
        </span>
      </div>
    </EditorObject>
  );
};

const BasicPropertiesTab = (props) => (
  <div style={{ width: "100%" }}>
    {/* <Typography {...props} showLineHeight isExpanded /> */}
    <PositionPadding {...props} isExpanded />
    <Alignment {...props} label="Text Alignment" verticalAlign={false} textAlign />
    <Interactions
      {...props}
      label="Text Actions"
      onUpdate={(action) => console.log(action)}
    >
      <div />
    </Interactions>
  </div>
);

const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Text", component: BasicPropertiesTab }]}
  />
);

export default (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <TextContentEdit {...props} />
    </div>
  );
};
