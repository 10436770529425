import { get } from "lodash";
import React, { Component } from "react";
import { ObjectContent } from "./ObjectContent";
import style from "./style";

export class MainObject extends Component {
  render() {
    const {
      connectDragSource,
      connectDragPreview,
      settings,
      draggable = true,
      fitContent = true,
    } = this.props;

    let defaultStyle = {};

    if (
      get(settings, "canHaveChildren") ||
      get(settings, "type") === "Columns"
    ) {
      defaultStyle = { height: "100%" };
    }

    if (!fitContent) defaultStyle = {};

    if (!draggable) return <ObjectContent {...this.props} />;

    return connectDragSource(
      <div style={defaultStyle}>
        {connectDragPreview(<div style={style.preview} />)}
        <ObjectContent {...this.props} />
      </div>
    );
  }
}
