import {
  Button,
  Cell,
  DropDownSelector,
  FontIcon,
  Grid
} from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import $ from "jquery";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { app } from "../../../config";
import providers from "../../settings/IntegrationManagement/integrationProviders";
import style from "./style";

export default class ChooseMapping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapping: [],
      fields: [],
      mappingSelection: _.get(props, "payload.mappingSelection", {})
    };

    this.saveMapping = this.saveMapping.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
  }

  static contextType = AppConfig;

  componentDidMount() {
    const { formCode } = this.props.payload;

    // grab input fields from form code
    const code = $(`<div>${formCode}</div>`);
    let mapping = [];
    code.find("input").each(function (i) {
      if (
        $(this).attr("type") !== "hidden" &&
        $(this).attr("type") !== "submit"
      ) {
        const name = $(this).attr("name");
        mapping.push(name);
      }
    });

    // grab input fields from current page (editor)
    const { content } = this.props.getPageQuery.page;
    const inputs = _.filter(content, itm => itm.type === "TextInput");
    const fields = _.map(inputs, itm => _.get(itm, "name"));

    this.setState({ mapping, fields }, () => {
      // update default choices if none set
      let mappingSelection = {};

      if (!_.keys(this.props.payload.mappingSelection).length) {
        mapping.forEach(name => {
          mappingSelection[name] = this.getChoice(name);
        });

        this.setState({ mappingSelection });
      }
    });
  }

  handleDropDownChange(name, choice) {
    this.setState(prevState => ({
      mappingSelection: {
        ...prevState.mappingSelection,
        [name]: choice
      }
    }));
  }

  getChoice(name) {
    // return the id of the mapped input element that closely matches the name
    const { fields } = this.state;
    const matched = _.head(
      _.filter(
        fields,
        field => name.toLowerCase().indexOf(field.toLowerCase()) > -1
      )
    );

    return matched;
  }

  async saveMapping() {
    const { updateSettings, payload, history, nextHref } = this.props;

    const { mappingSelection } = this.state;

    const settings = {
      ...payload,
      mappingSelection
    };

    console.log({ settings });

    await updateSettings(settings);

    // Show the Next Screen
    history.push(nextHref);
  }

  render() {
    const { app } = this.context;
    const providerType = _.get(
      this.props,
      "providerSettings.payload.type",
      app.defaultProvider
    );
    const providerInfo = _.head(
      _.filter(providers, o => o.id === providerType)
    );

    return (
      <div
        style={{
          margin: "0 auto"
        }}
      >
        <h2>
          Now let's map the fields we found in your{" "}
          {_.upperFirst(_.get(providerInfo, "id", ""))}
          <br />
          web form code to the fields on your page...
        </h2>

        <div style={{ margin: "40px auto" }}>
          {this.state.mapping.map((name, key) => {
            const theChoice = _.get(this.state.mappingSelection, name, 0);

            return (
              <Grid
                key={key}
                justify="space-evenly"
                style={{ width: 600, margin: "0 auto" }}
              >
                <Cell
                  style={{
                    width: 275,
                    fontSize: 14,
                    fontWeight: "bold",
                    padding: 40,
                    textAlign: "right"
                  }}
                >
                  {name}
                </Cell>
                <Cell
                  style={{ width: 25, padding: "35px 0", textAlign: "center" }}
                >
                  <FontIcon>arrow_right_alt</FontIcon>
                </Cell>
                <Cell
                  style={{
                    ...style.selectField,
                    margin: 20,
                    textAlign: "left",
                    width: 300
                  }}
                >
                  <DropDownSelector
                    text="Choose a field on your page..."
                    items={this.state.fields.map((name, id) => ({
                      id: name,
                      name
                    }))}
                    choice={theChoice}
                    onChange={choice => this.handleDropDownChange(name, choice)}
                  />
                </Cell>
              </Grid>
            );
          })}
        </div>

        <Grid
          justify="space-evenly"
          style={{
            marginTop: 30
          }}
        >
          <Cell align="center">
            {this.props.prevHref && !app.defaultProvider ? (
              <Link to={this.props.prevHref}>
                <Button large transparent style={{ color: "#333" }}>
                  Back
                </Button>
              </Link>
            ) : null}
            <Button large tertiary onClick={this.saveMapping}>
              Next
            </Button>
          </Cell>
        </Grid>
      </div>
    );
  }
}
