import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.SIX,
  layout: BenefitLayouts.SIX,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx36rg821t10780ogx3g72b",
    itemId: "u5j1r64m5rf",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                  textAlign: "left",
                },
                html: get(content, "main_headline.html", "..."),
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                  textAlign: "left",
                },
                html: get(content, "sub_headline.html", "..."),
              },
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  ...style.LIST,
                },
                children: [
                  {
                    type: ComponentTypes.LISTITEM,
                    canHaveChildren: false,
                    properties: {
                      ...style.LI.style,
                    },
                    icon: IconTypes.CheckCircleOutline,
                    iconStyle: style.LI.iconStyle,
                    html: "Try it free",
                  },
                  {
                    type: ComponentTypes.LISTITEM,
                    canHaveChildren: false,
                    properties: {
                      ...style.LI.style,
                    },
                    icon: IconTypes.CheckCircleOutline,
                    iconStyle: style.LI.iconStyle,
                    html: "Rapid Response",
                  },
                  {
                    type: ComponentTypes.LISTITEM,
                    canHaveChildren: false,
                    properties: {
                      ...style.LI.style,
                    },
                    icon: IconTypes.CheckCircleOutline,
                    iconStyle: style.LI.iconStyle,
                    html: "Sit apet consectetur",
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {},
              }),
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
