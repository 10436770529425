import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",

    mobile: {
      desktop: {
        padding: 80,
      },
      smartphone: {
        padding: 15,
      },
    },
  },
  HEADLINE: {
    fontSize: "26pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    // paddingBottom: 30,
    // color: theme.foregroundColor
  },
  // TEXT: {
  //   padding: 20,
  //   fontFamily: theme.fontFamily,
  //   color: theme.foregroundColor
  // },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
    padding: "100px 20px",
    background: theme.foregroundColor,
    position: "relative",
  },
  ICON: {
    display: "inline-block",
    color: theme.foregroundColor,
    fontSize: "10pt",
    border: `1px solid ${theme.foregroundColor}`,
    borderRadius: "100%",
    padding: 10,
  },
  LIST: {
    paddingLeft: 0,
    marginTop: 40,
  },
  LI: {
    style: {
      fontFamily: theme.fontFamily,
      padding: "10px 0",
    },
    iconStyle: {
      color: theme.backgroundColor,
      fontSize: "22pt",
      paddingRight: 20,
      marginTop: -6,
    },
  },
};
