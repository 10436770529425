import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { HeaderLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.EIGHT,
  layout: HeaderLayouts.EIGHT,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header08.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckktsa7qd1v1u078062fyqewd",
    itemId: "bdud11ztc08",
  },
  default: defaultData.default,
  // default: {
  //   type: ComponentTypes.SECTION,
  //   label: PageSectionCategory.HEADER,
  //   bodyOnly: true,
  //   canHaveChildren: false,
  //   properties: {
  //     ...style.SECTION,
  //     ...style.SECTION_INNER,
  //   },
  //   children: [
  //     {
  //       type: ComponentTypes.CONTAINER,
  //       canHaveChildren: false,
  //       properties: {
  //         maxWidth: 700,
  //         margin: "0 auto",
  //         textAlign: "center",
  //       },
  //       children: [
  //         {
  //           type: ComponentTypes.HEADLINE,
  //           canHaveChildren: false,
  //           properties: {
  //             ...style.HEADLINE,
  //             ...get(content, "main_headline.style", {}),
  //           },
  //           html: get(content, "main_headline.html", "..."),
  //         },
  //         {
  //           type: ComponentTypes.BUTTON,
  //           // canHaveChildren: false,
  //           properties: style.BUTTON.style,
  //           caption1: "Get Started",
  //           caption1style: style.BUTTON.caption1,
  //         },
  //       ],
  //     },
  //     getImagePlaceholder({
  //       type: ImagePlaceholderTypes.MOBILE02,
  //       style: {},
  //     }),
  //   ],
  // },
};

export default settings;
