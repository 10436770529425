import React from "react";
import { get } from "lodash";
import { TextInput, Radio, Grid, Cell } from "@launchos/components/ui";
import { DatetimePickerTrigger } from "rc-datetime-picker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import moment from "moment";
import { google, outlook, office365, yahoo, ics } from 'calendar-link'

enum CalendarTypes {
  APPLE = "Apple",
  GOOGLE = "Google",
  OUTLOOK = "Outlook",
  OUTLOOKCOM = "Outlook.com",
  YAHOO = "Yahoo",
}

export const AddToCalendar = (props) => {
  const { action, updateAction } = props;
  const { payload } = action;

  const [title, setTitle] = React.useState(get(payload, "title"));
  const [description, setDescription] = React.useState(get(payload, "description"));
  const [startTime, setStartTime] = React.useState(moment(get(payload, "startTime")));
  const [endTime, setEndTime] = React.useState(moment(get(payload, "endTime")));
  const [calendarType, setCalendarType] = React.useState(get(payload, "calendarType", CalendarTypes.APPLE));

  const getDownloadUrl = type => {

    const event = {
      title,
      description,
      start: moment(startTime),
      end: moment(endTime),
    };

    switch (type) {
      case CalendarTypes.APPLE:
        console.log("APPLE", ics(event))
        return ics(event)
      case CalendarTypes.GOOGLE:
        console.log("GOOGLE", google(event))
        return google(event)
      case CalendarTypes.OUTLOOK:
        return outlook(event)
      case CalendarTypes.OUTLOOKCOM:
        return office365(event)
      case CalendarTypes.YAHOO:
        return yahoo(event)
    }
  }

  return (
    <React.Fragment>

      <TextInput label="Title"
        placeholder="Sample Event"
        value={title}
        onChange={e => setTitle(e.target.value)}
        onBlur={e => updateAction(action, { ...payload, title, description, startTime, endTime, calendarType, href: getDownloadUrl(calendarType) })}
      />

      <TextInput label="Description"
        placeholder="This is a Sample Event"
        value={description}
        onChange={e => setDescription(e.target.value)}
        onBlur={e => updateAction(action, { ...payload, title, description, startTime, endTime, calendarType, href: getDownloadUrl(calendarType) })}
      />

      <DatetimePickerTrigger
        moment={moment(startTime)}
        onChange={(newDate) => {
          setStartTime(newDate);
          updateAction(action, { ...payload, title, description, startTime: newDate, endTime, calendarType, href: getDownloadUrl(calendarType) })
        }}
      >
        <TextInput
          iconRight="date_range"
          value={moment(startTime).format("MM/DD/YYYY HH:mm")}
          label="Start Time"
        />
      </DatetimePickerTrigger>

      <DatetimePickerTrigger
        moment={moment(endTime)}
        onChange={(newDate) => {
          setEndTime(newDate);
          updateAction(action, { ...payload, title, description, startTime, endTime: newDate, calendarType, href: getDownloadUrl(calendarType) })
        }}
      >
        <TextInput
          iconRight="date_range"
          value={moment(endTime).format("MM/DD/YYYY HH:mm")}
          label="End Time"
        />
      </DatetimePickerTrigger>

      <RadioGroup
        aria-label="calendar type settings"
        name="calType"
        value={calendarType}
        onChange={(e) => {
          setCalendarType(e.target.value)
          updateAction(action, { ...payload, title, description, startTime, endTime, calendarType: e.target.value, href: getDownloadUrl(calendarType) })
        }}
      >
        <Grid>
          <Cell>
            <FormControlLabel
              value={CalendarTypes.APPLE}
              control={<Radio />}
              label="Apple"
            />
            <FormControlLabel
              value={CalendarTypes.GOOGLE}
              control={<Radio />}
              label="Google"
            />
            <FormControlLabel
              value={CalendarTypes.OUTLOOK}
              control={<Radio />}
              label="Outlook"
            />
          </Cell>
          <Cell>
            <FormControlLabel
              value={CalendarTypes.OUTLOOKCOM}
              control={<Radio />}
              label="Outlook.com"
            />
            <FormControlLabel
              value={CalendarTypes.YAHOO}
              control={<Radio />}
              label="Yahoo"
            />
          </Cell>
        </Grid>
      </RadioGroup>
    </React.Fragment>
  );
};