import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 60,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "18pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    marginTop: 30,
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 20,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
  ICON: {
    fontSize: "18pt",
    borderRadius: "50%",
    backgroundColor: theme.backgroundColor,
    color: theme.foregroundColor,
    padding: 15,
    textAlign: "left",
    mobile: {
      desktop: {
        padding: 15,
      },
      smartphone: {
        display: "inline-block",
      },
    },
  },
  LI: {
    fontSize: "32pt",
    fontWeight: "bold" as "bold",
    fontFamily: theme.fontFamily,
    padding: "10px 0",
    color: "#DDD",
    mobile: {
      desktop: {
        fontSize: "32pt",
      },
      smartphone: {
        fontSize: "22pt",
      },
    },
  },
};
