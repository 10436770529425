import _ from "lodash";
import { Button, Cell, Grid, ListItem, Menu } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React, { Component } from "react";
import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

export class DomainChooseOne extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  static contextType = AppConfig;

  handleChange(id) {
    const { domainNames } = this.props.domainInfo;
    // const { domainNames } = this.props.getDomainInfo();

    const domainObject = domainNames.filter((itm) => itm.id === id);
    console.log({ domainObject });
    if (domainObject.length) {
      this.props.updateDomainInfo({
        value: domainObject[0].name,
        domainId: domainObject[0].id,
      });
    }
  }

  render() {
    if (!this.props.hidden) {
      const { changeStep, useDomain, domainInfo } = this.props;
      const value = _.get(domainInfo, "value", "");
      const domainNames = _.get(domainInfo, "domainNames", []);

      return (
        <div style={{ width: 550 }}>
          <img
            alt="Domain Name"
            src="https://s3.amazonaws.com/sandcastleassets/images/domain_icon.png"
          />
          <h2>
            Choose the Domain Name you want
            <br />
            to use for this Campaign...
            {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Domains' })}
          </h2>
          <div style={{ margin: "30px 0" }}>
            {!domainNames.length ? null : (
              <>
                <Menu style={{ width: 350, cursor: "pointer" }} label={value}>
                  <div style={{ maxHeight: 300, overflowY: "auto" }}>
                    {domainNames.map((d) => (
                      <ListItem
                        onClick={() => this.handleChange(d.id)}
                        key={d.id}
                      >
                        {d.name}
                      </ListItem>
                    ))}
                  </div>
                </Menu>
              </>
            )}
          </div>
          <Grid justify="space-evenly" style={{ marginTop: 30 }}>
            <Cell align="center">
              <Button onClick={() => changeStep("add")} large tertiary>
                Add another Domain Name
              </Button>
            </Cell>
            <Cell align="center">
              <Button onClick={useDomain} large tertiary>
                Save
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
