import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import ShoppingCartPopup from "./ShoppingCartPopup";
import objects from "./objects";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.DESTINATION_NODES_DRAWER_TABS) {
    return ["Shopping Carts"];
  }

  if (hook.id === ListItemsHooks.DESTINATION_OBJECTS && hook.activeTab === "Shopping Carts") {
    return objects;
  }
};

export const onNodeCreate = async (
  hook,
  { item },
  { createPage, updateObjectSettings }
) => {
  if (hook.type === "shopping_cart") {
    const { name, screenshot } = item;

    const url = "<%=nextPage%>";

    const pageScript = `
      <script type="text/javascript">
        window.location.href = "${url}";
      </script>
    `;

    const response = await createPage({
      name,
      slug: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 6),
      screenshot,
      theme: "none",
      type: "redirect",
      content: [
        {
          id: 2540,
          parent: false,
          properties: { height: "100%" },
          type: "Body",
          canHaveChildren: true
        }
      ],
      userId: localStorage.userId,
      pageSettings: {
        tracking: {
          head: pageScript
        }
      }
    });

    return response;
  }
};

export const onNodeOpen = async (
  hook,
  { settings },
  { showPopup, updateObject, updatePageSettings, updateObjectSettings }
) => {
  if (hook.type === "shopping_cart") {
    showPopup(ShoppingCartPopup, settings, {
      updateObject,
      updatePageSettings,
      updateObjectSettings
    });
  }
};
