import { ComponentMountHooks } from "@launchos/plugins/types";
import config from '@launchos/10mf/src/config'

const loadIntercom = () => {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;

    function l() {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/lw210xad";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
    if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
};

export const onComponentMount = (hook, payload, actions) => {
  const isFromIframe = window.location !== window.parent.location;

  if (
    hook.id === ComponentMountHooks.APP_DID_LOAD &&
    config.app.loadIntercom &&
    window.location.pathname.indexOf('login') === -1 &&
    !isFromIframe
  ) {
    loadIntercom();
    window.Intercom("boot", {
      email: localStorage.email,
      user_id: localStorage.userId,
      app_id: "lw210xad",
    });
  }
};

export default { onComponentMount };
