import React from "react";
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'

import { green, purple } from "@material-ui/core/colors";

import { triggerHook } from "@launchos/plugins/client";
import { ComponentMountHooks, HookTriggers } from "@launchos/plugins/types";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import settings from "./config";
import Routes from "./routes";
import "./style.css";

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green
  }
})

class App extends React.Component {
  componentDidMount() {
    triggerHook(HookTriggers.onComponentMount, {
      id: ComponentMountHooks.APP_DID_LOAD,
    });
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <Routes settings={settings} />
        </ThemeProvider>
      </DndProvider>
    );
  }
}

export default App;
