// Modules
import * as admin from "./admin";
import * as webinar from "./webinar";
import * as media from "./media";
import * as contacts from "./contacts";
import * as affiliates from "./affiliates";
import * as membership from "./membership";
import * as quiz from "./quiz";
import * as university from "./university";
import * as helpsupport from "./helpsupport";

export default [
  admin,
  // media,
  // contacts,
  // affiliates,
  // membership,
  // webinar,
  // quiz,
  university,
  helpsupport,
];
