import React from "react";
import { Button } from "@launchos/components/ui";

export const PublishButton: React.FC<{ onPublish: () => void }> = ({
  onPublish,
}) => (
  <Button iconLeft="publish" secondary onClick={onPublish}>
    Publish
  </Button>
);
