import React from "react";
import ItemDrawerTabContent from "@launchos/modules/campaign/AdvancedBuilder/ItemDrawerTabContent";
import { triggerHook } from "@launchos/plugins/client";
import {
  ComponentRenderHooks,
  ListItemsHooks,
  HookTriggers,
} from "@launchos/plugins/types";

export default (props) => {
  const objects = triggerHook(HookTriggers.onListItems, {
    id: ListItemsHooks.TRAFFIC_SOURCE_OBJECTS,
  });

  return (
    <ItemDrawerTabContent
      {...props}
      title="Traffic Sources"
      objects={objects}
      hint="Click on a traffic source<br/>and drag it to the canvas"
    />
  );
};
