import * as Headers from "./Headers/plugins";
import * as Benefits from "./Benefits/plugins";
import * as Content from "./Content/plugins";
import * as CTA from "./CTA/plugins";
import * as Footers from "./Footers/plugins";
import * as Forms from "./Forms/plugins";
import * as Guarantee from "./Guarantee/plugins";
import * as Navigation from "./Navigation/plugins";
import * as Pricing from "./Pricing/plugins";
import * as Quiz from "./Quiz/plugins";
import * as SocialProof from "./SocialProof/plugins";
import * as Urgency from "./Urgency/plugins";
import * as Personal from "./Personal/plugins";

export default [
  Headers,
  Benefits,
  Content,
  CTA,
  Footers,
  Forms,
  Guarantee,
  Navigation,
  Pricing,
  Quiz,
  SocialProof,
  Urgency,
  Personal,
];
