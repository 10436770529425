import theme from "@launchos/components/ui/theme";
export default {
  templateItem: {
    width: 370,
    height: 260,
    // margin: "30px 30px 10px",
    overflowY: "hidden",
    backgroundColor: "white",
    cursor: "pointer"
  },
  templateItemHoverZoom: {
    // zoom: 1.5, 
    position: 'absolute',
    marginLeft: -75,
    // marginTop: -66,
    boxShadow: "0px 0px 35px 0px rgba(0,0,0,0.25)",
    height: "unset", zIndex: 10,
    borderRadius: 3
  },
  templateItemHover: {
    container: {
      width: 370,
      height: 260,
      position: "absolute"
    },
    bg: {
      backgroundColor: theme.darkColor,
      opacity: 0.8,
      zIndex: 1
    },
    content: {
      zIndex: 2
    }
  }
};
