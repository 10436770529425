import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.FIVE,
  layout: FormLayouts.FIVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyu67qy2e6c07805fbyk6cj",
    itemId: "3983a2fjvch",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
      maxWidth: 500,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: "One More Step...",
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "sub_headline.style", {}),
        },
        html: "Getting results is much easier than you think.",
      },
      {
        type: ComponentTypes.FORMFIELD,
        canHaveChildren: false,
        attributes: {
          placeholder: "Email",
          name: "email",
        },
        fieldType: FormFieldTypes.TEXT,
        preset: FieldNamePresets.EMAIL,
        styles: {
          containerStyle: style.FORM_FIELD.style,
          inputStyle: style.FORM_FIELD.inputStyle,
        },
      },
      {
        type: ComponentTypes.BUTTON,
        canHaveChildren: false,
        properties: style.BUTTON.style,
        caption1: "Free Instant Access",
        caption1style: style.BUTTON.caption1,
      },
    ],
  },
};

export default settings;
