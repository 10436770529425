import * as React from "react";
import { useState } from "react";

import { BulkActionsMenuProps, BulkActionsMenuPropsType } from "../types";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Icon from "@material-ui/core/Icon";

/**
 * A list of actions to be applied to the selected items in the data display.
 */
const BulkActionsMenu: React.FC<BulkActionsMenuProps> = ({
  type,
  icon,
  label,
  data,
  onSelect
}: BulkActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div data-testid="BulkActionsMenu-Default">
      {type === BulkActionsMenuPropsType.ICON && (
        <Button
          onClick={handleClick}
          data-testid="BulkActionsMenu-Icon"
          aria-controls="bulk-actions-menu"
        >
          <Icon>{icon}</Icon>
        </Button>
      )}
      {type === BulkActionsMenuPropsType.BUTTON && (
        <Button
          color="primary"
          variant="contained"
          onClick={handleClick}
          data-testid="BulkActionsMenu-Button"
          aria-controls="bulk-actions-menu"
        >
          {label}
          {icon && <Icon>{icon}</Icon>}
        </Button>
      )}
      <Menu
        id="bulk-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="BulkActionsMenu-MenuList"
      >
        {data.map((a, key) => (
          <MenuItem
            key={key}
            data-testid="BulkActionsMenu-MenuItem"
            onClick={() => onSelect(a)}
          >
            {a.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

BulkActionsMenu.defaultProps = {
  type: BulkActionsMenuPropsType.ICON,
  icon: "more_horiz"
};

export default BulkActionsMenu;
