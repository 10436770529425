import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { CTALayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/CTA/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.FOUR,
  layout: CTALayouts.FOUR,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx8ufdx24fg0780ybqe4j4m",
    itemId: "pohlh35h8lg",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 100,
                  mobile: {
                    desktop: { padding: 100 },
                    smartphone: { padding: 20 },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: get(content, "main_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: get(content, "sub_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: style.BUTTON_CONTAINER,
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: 50,
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Learn More",
                            caption1style: style.BUTTON.caption1,
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: 50,
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: "transparent",
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Get Started",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  maxHeight: 525,
                  padding: 20,
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.MOBILE04,
                    style: {
                      height: "100%",
                      backgroundColor: theme.foregroundColor,
                      // maxWidth: 350,
                      width: 350,
                      mobile: {
                        desktop: { width: 350 },
                        smartphone: { width: "100%" },
                      },
                      textAlign: "center",
                      display: "inline-block",
                      margin: "0 auto",
                    },
                  }),
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
