import React from "react";

import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";

import StatsNode from "./StatsNode";
import ConversionRate from "./ConversionRate";
import TimelineSelector from "./TimelineSelector";

import { calcConversionRate } from './scripts'

export const onListItems = (hook, payload, actions) => {
  // Add a Stats Button on the toolbar
  if (hook.id === ListItemsHooks.CAMPAIGN_SETTINGS_MENU) {
    const {
      getCampaignState = () => null,
      setCampaignState = () => null,
    } = actions;

    const isShowingStats = getCampaignState("isShowingStats");

    return [
      {
        id: "stats",
        icon: "equalizer",
        caption: isShowingStats ? "Hide Stats" : "View Stats",
        onSelect: () => {
          setCampaignState((prevState) => ({
            isShowingStats: !prevState.isShowingStats,
          }));
        },
      },
    ];
  }
};

// Switch out the canvas objects with the stats object if stats are toggled
export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.CANVAS_OBJECT) {
    const { getCampaignState } = actions;
    if (getCampaignState("isShowingStats")) {
      const { page } = payload;
      const { id } = page;
      const stats = getCampaignState(id);
      return [() => <StatsNode {...actions} {...payload} {...stats} />];
    }
  }

  if (hook.id === ComponentRenderHooks.CONNECTION_LINE) {
    const { getCampaignState } = actions;
    if (getCampaignState("isShowingStats")) {
      const value = calcConversionRate(payload, getCampaignState);
      return [(props) => <ConversionRate {...props} value={value} />];
    }
  }

  if (hook.id === ComponentRenderHooks.CAMPAIGN_SETTINGS_TOOLBAR) {
    const { getCampaignState = () => null } = actions;

    if (getCampaignState("isShowingStats")) {
      return [() => <TimelineSelector {...actions} {...payload} />];
    }
  }

};
