import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // padding: 100
    paddingTop: 100,
    paddingBottom: 100,
    paddingRight: 100,
    mobile: {
      desktop: { paddingTop: 100, paddingBottom: 100, paddingRight: 100 },
      smartphone: { paddingTop: 0, paddingBottom: 0, paddingRight: 0 },
    },
  },
  HEADLINE: {
    fontFamily: theme.fontFamily,
    fontSize: "26pt",
    // fontWeight: "bold" as "bold",
    textAlign: "left",
    // padding: "20px 0",
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    textAlign: "left",
    lineHeight: "18pt",
    // padding: "40px 0"
  },
};
