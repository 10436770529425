import {
  allCampaigns,
  createCampaign,
  deleteCampaign,
  updateCampaignName
} from "@launchos/api/gql/campaigns";
import { createPage, deletePageMutation } from "@launchos/api/gql/pages";
import { compose, graphql } from "react-apollo";
import Dashboard from "./CampaignsDashboard";

export default compose(
  graphql(allCampaigns, {
    name: "allCampaignsQuery",
    options: () => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),

  graphql(updateCampaignName, {
    name: "updateCampaignName"
  }),

  graphql(createCampaign, {
    name: "createCampaign"
  }),

  graphql(createPage, {
    name: "createPage"
  }),

  graphql(deleteCampaign, {
    name: "deleteCampaign"
  }),

  graphql(deletePageMutation, {
    name: "deletePageMutation"
  })
)(Dashboard);
