import { client } from "@launchos/api/gql";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import WebFontLoader from "webfontloader";
import App from "./App";
import "./index.css";

WebFontLoader.load({
  google: {
    families: ["Roboto:300,400,500,700", "Material Icons"]
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);
