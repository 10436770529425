import * as React from "react";
import { DragSource } from "react-dnd";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PreviewIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";

import theme from "@launchos/components/ui/theme";

import { SectionPreviewProps } from "./types";
// import { Popup } from "@launchos/plugins/webcomponents/v2";

const Container = styled.li`
  position: relative;
  border-radius: 4px;
  transition: 0.25s;
  cursor: move;
  list-style: none;
  text-align: left;
  border: 1px solid rgb(0, 0, 0, 0.1);
`;

const Label = styled.span`
  position: absolute;
  display: inline-block;
  bottom: 0;
  padding: 10px;
  margin: 10px;
  background-color: rgb(100, 100, 100, 0.5);
  color: #f3f3f3;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: bold;
`;

const PreviewIconButton = styled.span`
  color: white;
  position: relative;
  top: 3px;
  &:hover {
    color: ${theme.secondaryColor};
  }
`;

const ActionBar = styled.div`
  position: absolute;
  top: 0;
  padding: 10px;
  width: 100%;
  height: 80px;
  text-align: right;
  border-radius: 4px;
  background-image: linear-gradient(
    rgb(0, 0, 0, 0.3),
    rgb(0, 0, 0, 0.3),
    rgb(0, 0, 0, 0)
  );
`;

const CURSOR_ID = 1111;

const componentSource = {
  beginDrag(props) {
    return { ...props };
  },

  endDrag(props, monitor) {
    props.onAddSection(props.layout, CURSOR_ID);
    // props.onClose();
  },
};

/**
 * A component for previewing blocks/sections -- Generates a picture of the provided component layout if a preview isn't provided
 */
const SectionPreview: React.FC<SectionPreviewProps> = ({
  label,
  layout,
  previewImage,
  onAddSection,
  connectDragSource,
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [previewPopupIsShowing, setPreviewPopupIsShowing] = React.useState<
    boolean
  >(false);

  /**
   * Triggers when the [preview] button is clicked.  Will show a popup window with a larger view of the section that will be added
   * @params
   */
  const handlePreviewSection = (): void => {
    setPreviewPopupIsShowing(true);
  };

  return connectDragSource(
    <div>
      <Container
        data-testid="Blocks-AddSectionsTab-SectionPreview"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onDoubleClick={() => onAddSection(layout)}
      >
        {previewPopupIsShowing && (
          <div data-testid="Blocks-AddSectionsTab-SectionPreview-Preview">
            --- Popup Content ---
            {/* <Popup open={previewPopupIsShowing} setOpen={setPreviewPopupIsShowing}>
              </Popup> */}
          </div>
        )}

        {isHovering && (
          <>
            <ActionBar>
              {/* <Tooltip title="Preview This Section">
                <IconButton style={{ padding: 5 }} onClick={handlePreviewSection}>
                  <PreviewIconButton data-testid="preview-icon">
                    <PreviewIcon />
                  </PreviewIconButton>
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Add This Section To Your Page">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onAddSection(layout)}
                  disableElevation
                  size="small"
                  startIcon={<AddIcon />}
                >
                  Add To Page
                </Button>
              </Tooltip>
            </ActionBar>
            <Label>{label}</Label>
          </>
        )}
        <Tooltip
          title="Double-click (or drag) to add this to your page"
          enterDelay={500}
          placement="right"
        >
          <img
            src={previewImage}
            style={{
              display: "block",
              minHeight: 45,
              borderRadius: 4,
              width: "100%",
            }}
          />
        </Tooltip>
      </Container>
    </div>
  );
};

export default DragSource("box", componentSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(SectionPreview);
