import { has, get, head } from 'lodash';
import theme from "@launchos/components/ui/theme";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import React from "react";

import { handleResize, handleResizeEnd } from "./handleDragDrop";

import { ColumnDropDownMenu } from "./ColumnDropDownMenu";
import { Properties } from "./Properties";
import { Content } from "./Content";

import style from "./style";
import { MobileState } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";

export const CURSOR_ID = 1111;

const Column = (props) => {
  const {
    settings,
    pageContent,
    changeState,
    show,
    showingProperties,
    showResize,
    getMobileState
  } = props;

  const { state, parent, id } = settings;
  const { type } = getMobileState()

  const getColumns = (pageContent) =>
    pageContent.filter((itm) => itm.parent === parent);

  const getResizeHandles = (pageContent) => {
    const theColumns = getColumns(pageContent);
    const key = theColumns.findIndex((itm) => itm.id === id);
    if (theColumns.length > 1)
      return key !== theColumns.length - 1 ? ["E"] : ["W"];
    else return false;
  };

  const canUserResize = () => {
    if (type === MobileState.SMARTPHONE) {
      const rowSettings = head(pageContent.filter(itm => itm.id === parent));
      const maxColumnPerRow = get(rowSettings, 'properties.mobile.smartphone.maxColumnsPerRow', false);
      if (!maxColumnPerRow || maxColumnPerRow === 1) return false;
    }

    return true;
  }

  const handleDragStart = (e) => e.preventDefault();

  const handleOnClick = showingProperties
    ? () => null
    : () => changeState(parent, "active");

  const triggerActionOnParent = () => null; // need to pass any function to trigger row properties (overrides onClick, which stops)

  const visible =
    (state === "hover" || state === "active" || showResize) &&
    show !== "preview";

  return (
    <EditorObject
      {...props}
      resizeHandles={getResizeHandles(pageContent)}
      isResizable={canUserResize()}
      onResize={handleResize}
      onResizeEnd={handleResizeEnd}
      PropertiesView={Properties}
      resizeHandleColor={theme.altColors[1]}
      color="transparent"
      dragHandle={false}
      draggable={false}
      onDragStart={handleDragStart}
      onClick={handleOnClick}
      onRightClick={triggerActionOnParent}
      onDoubleClick={triggerActionOnParent}
      style={style.editorObject}
    >
      <ColumnDropDownMenu {...props} visible={visible} />
      <Content {...props} />
    </EditorObject>
  );
};

export default Column;
