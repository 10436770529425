import React from "react";
import { get, has } from 'lodash';
import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import config from "@launchos/10mf/src/config";
import { HelpButton } from "@launchos/components/ui";
import { ComponentTypes } from "@launchos/modules/editor/Builder/WebComponent/types";
import { ActionTypes } from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/types";

export const onComponentRender = ({ id, type }, payload, actions) => {
  if (config.app.name === "10 Minute Funnels") {
    if (id === ComponentRenderHooks.DASHBOARD_PAGE_TITLE) {
      switch (type) {
        case 'Dashboard':
          return [<HelpButton style={{ margin: '5px 15px', display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2354207-the-dashboard" tooltip="Click here to learn more about the Dashboard" />];
        case 'Domains':
          return [<HelpButton style={{ margin: '5px 15px', display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2362432-publishing-with-a-custom-domain-name" tooltip="Click here to learn more about Domains" />];
        case 'SSL':
          return [<HelpButton style={{ margin: '5px 15px', display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/3066110-creating-an-ssl-certificate" tooltip="Click here to learn more about SSL Certificates" />];
        case 'Integrations': {
          if (get(payload, 'filter') === "merchant")
            return [<HelpButton style={{ margin: '5px 15px', display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2565255-connect-a-merchant-provider-to-collect-payments" tooltip="Click here to learn more about collecting payments from your prospects" />];
          else if (get(payload, 'filter') === "email")
            return [<HelpButton style={{ margin: '5px 15px', display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2562280-connect-an-autoresponder-email-service-to-page-to-capture-emails" tooltip="Click here to learn more about connecting to your 3rd party email provider" />];
          else
            return [<HelpButton style={{ margin: '5px 15px', display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/collections/1368509-integrations" tooltip="Click here to learn more about 3rd Party Integrations" />];
        }
        case 'Catalog':
          return [<HelpButton style={{ marginTop: 7, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2354220-choose-a-campaign-template" tooltip="Click here to learn more about choosing a campaign" />];
        case 'Campaign Tracking':
          return [<HelpButton style={{ margin: 10, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2410677-campaign-tracking-codes" tooltip="Click here to learn more about adding tracking scripts to your campaign" />];
        case 'Page Tracking':
          return [<HelpButton style={{ margin: 10, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/5000903-page-tracking" tooltip="Click here to learn more about adding tracking scripts to your page" />];
        case 'SEO':
          return [<HelpButton style={{ margin: 10, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2359932-seo-settings" tooltip="Click here to learn more about SEO and your pages" />];
        case 'CSS':
          return [<HelpButton style={{ margin: 10, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/4574552-adding-a-custom-css-code" tooltip="Click here to learn more about adding custom css code to your pages" />];
        case 'Exit Intent':
          return [<HelpButton style={{ margin: 10, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/4574593-exit-intent-pop-up" tooltip="Click here to learn more about exit intent popups" />];
      }
    }

    if (id === ComponentRenderHooks.CATALOG_ITEM_TITLE) {
      const catalogItemStyle = {
        display: 'inline-block',
        visibility: get(payload, 'isHover') ? "visible" : "hidden",
      }

      switch (payload.id) {
        case 'cjz0ij1tv0epy0792woxkpl6s':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/3125887-build-a-campaign-from-scratch" tooltip={`Click here to learn more about building a campaign from scratch`} />];
        case 'cjz0ij60j0ful07920stbw0r6':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/3097155-simple-lead-capture-funnel" tooltip={`Click here to learn more about the simple lead capture funnel`} />];
        case 'cjz0ij5yu0fsm0792426llv99':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/3081779-simple-straight-sale-funnel" tooltip={`Click here to learn more about the straight sale funnel`} />];
        case 'cjz0ij3xl0f530792vgqmadu1':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/5000759-event-funnel-with-replay" tooltip={`Click here to learn more about the event funnel`} />];
        case 'cjz0ij5330fjw0792joas38wp':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/5000759-event-funnel-with-replay" tooltip={`Click here to learn more about the event funnel`} />];
        case 'cjz0ij4vg0fh4079297kghpkf':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/4997153-launch-video-series-funnel" tooltip={`Click here to learn more about the launch / video series funnel`} />];
        case 'cjz0ij3yq0f650792lt96s4xr':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/4997041-fulfillment-funnel" tooltip={`Click here to learn more about the fulfillment funnel`} />];
        case 'cjz0ij4lk0fd807927kl3sr6g':
          return [<HelpButton style={catalogItemStyle} href="http://support.tenminutefunnels.com/en/articles/2354220-choose-a-campaign-template" tooltip={`Click here to learn more about the upsell funnel`} />];

      }
    }

    if (id === ComponentRenderHooks.CAMPAIGN_SETTINGS_TOOLBAR) {
      switch (payload.show) {
        case 'legend':
          return [() => <HelpButton style={{ marginTop: 7, position: 'absolute', right: 10, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2410257-the-simple-sequence-builder" tooltip="Click here to learn more about the simple funnel sequence builder" />];
        case 'editor':
          return [() => <HelpButton style={{ marginTop: 7, position: 'absolute', right: 10, display: 'inline-block' }} href="http://support.tenminutefunnels.com/en/articles/2406818-the-drag-drop-builder" tooltip="Click here to learn more about the drag-and-drop campaign builder" />];
      }
    }

    if (id === ComponentRenderHooks.DRAWER_TITLE) {

      const propertiesStyle = get(payload, 'tabs', []).length > 1 || type === 'OBJECTS' || type === 'SECTIONS' ? {
        position: 'absolute',
        right: 10,
        margin: 10,
        marginTop: -31,
        color: '#999',
        textShadow: '1px 1px white'
      } : {
        margin: 10,
        color: '#999',
        textShadow: '1px 1px white'
      }

      switch (type) {
        case 'OBJECTS':
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/collections/118685-page-elements" tooltip={`Click here to learn more about each page element`} />];
        case 'SECTIONS':
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4396210-page-section-widget" tooltip={`Click here to learn more page sections`} />];
      }

      switch (get(payload, 'activeTab')) {
        case "New Pages":
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2406818-the-drag-drop-builder" tooltip={`Click here to learn more about the drag and drop builder`} />];
        case "Existing Pages":
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/3371654-redirect-to-a-page-from-a-different-funnel" tooltip={`Click here to learn more about adding existing pages to your campaign`} />];
        case "Common":
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/collections/118680-10-minute-funnels-features" tooltip={`Click here to learn more about destination actions`} />];
        case "Shopping Carts":
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/collections/118680-10-minute-funnels-features" tooltip={`Click here to learn more about integrating shopping carts`} />];
        default: {
          if (get(payload, 'title') === "Traffic Sources")
            return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2410798-traffic-sources" tooltip={`Click here to learn more about tracking traffic sources`} />];
          return null;
        }
      }

    }

    if (id === ComponentRenderHooks.PROPERTIES_TITLE) {
      const propertiesStyle = get(payload, 'tabs', []).length > 1 ? {
        position: 'absolute',
        right: 10,
        margin: 10,
        marginTop: -31,
        color: '#999',
        textShadow: '1px 1px white'
      } : {
        margin: 10,
        color: '#999',
        textShadow: '1px 1px white'
      }

      switch (payload.type) {
        case 'Button':
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360267-buttons" tooltip={`Click here to learn more about working with the Button element`} />];
        case ComponentTypes.SECTION:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4396210-page-section-widget" tooltip={`Click here to learn more about working with the Section element`} />];
        case ComponentTypes.HEADLINE:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360283-headline-and-text" tooltip={`Click here to learn more about working with the Headline element`} />];
        case ComponentTypes.TEXT:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360283-headline-and-text" tooltip={`Click here to learn more about working with the Text element`} />];
        case ComponentTypes.VIDEO:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360245-videos" tooltip={`Click here to learn more about working with the Video element`} />];
        case ComponentTypes.IMAGE:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360200-pictures" tooltip={`Click here to learn more about working with the Image element`} />];
        case ComponentTypes.CONTAINER:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360104-containers" tooltip={`Click here to learn more about working with the Container element`} />];
        case ComponentTypes.SPACER:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4410550-spacer-widget" tooltip={`Click here to learn more about working with the Spacer element`} />];
        case ComponentTypes.DIVIDER:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4410562-divider-widget" tooltip={`Click here to learn more about working with the Divider element`} />];
        case ComponentTypes.FORMFIELD:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360295-form-widget" tooltip={`Click here to learn more about working with the Form Field element`} />];
        case ComponentTypes.NAVIGATION:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4392251-navigation-widget" tooltip={`Click here to learn more about working with the Navigation element`} />];
        case ComponentTypes.BUTTON:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4939495-button-widget" tooltip={`Click here to learn more about working with the Button element`} />];
        case ComponentTypes.LIST:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4390904-bullet-points-widget" tooltip={`Click here to learn more about working with the List element`} />];
        case ComponentTypes.PROGRESS:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4392340-progress-widget" tooltip={`Click here to learn more about working with the Progress element`} />];
        case ComponentTypes.ICON:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4390872-icon-widget" tooltip={`Click here to learn more about working with the Icon element`} />];
        case ComponentTypes.FACEBOOKLOGIN:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4372914-facebook-optin-widget" tooltip={`Click here to learn more about working with the Facebook Login element`} />];
        case ComponentTypes.COUNTDOWN:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/3346889-countdown-timer" tooltip={`Click here to learn more about working with the Countdown element`} />];
        case ComponentTypes.FACEBOOKCOMMENTS:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/3224668-social-facebook-comments" tooltip={`Click here to learn more about working with the Facebook Comments element`} />];
        case ComponentTypes.COLUMNS:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360165-columns" tooltip={`Click here to learn more about working with the Columns element`} />];
        case ComponentTypes.COLUMN:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360165-columns" tooltip={`Click here to learn more about working with the Column element`} />];
        case ComponentTypes.PICTURE:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/2360200-pictures" tooltip={`Click here to learn more about working with the Picture element`} />];
        case ComponentTypes.FORM:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4415916-form-field-widget" tooltip={`Click here to learn more about the Form Builder`} />];
        case ComponentTypes.LISTITEM:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4399183-list-item-widget" tooltip={`Click here to learn more about the List Item element`} />];
        case ComponentTypes.ORDERBUMP:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4939436-order-bump" tooltip={`Click here to learn more about working with Order Bump elements`} />];
        case ComponentTypes.POPUP:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/3030327-opt-in-pop-up-window" tooltip={`Click here to learn more about creating Popup Window's`} />];
        case ComponentTypes.GOOGLELOGIN:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4390830-google-optin-widget" tooltip={`Click here to learn more about the Google Optin element`} />];
        // case ComponentTypes.ORDERSUMMARY:
        //   return [<HelpButton style={propertiesStyle} href="https://app.intercom.com/a/apps/lw210xad/articles/articles/4399263" tooltip={`Click here to learn more about the Order Summary Element`} />];
        case ComponentTypes.BODY:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4956520-page-body" tooltip={`Click here to learn more about the BODY Element`} />];
        case ComponentTypes.HTML:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4965892-adding-an-html-code-to-a-page" tooltip={`Click here to learn more about the HTML Element`} />];
        case ComponentTypes.TABS:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/4939199-the-tab-widget" tooltip={`Click here to learn more about the Tabs element`} />];
      }
    }

    if (id === ComponentRenderHooks.SECTIONS_TITLE) {
      const propertiesStyle = {}
      switch (payload.type) {
        case ActionTypes.TRIGGER_WEBHOOK:
          return [<HelpButton style={propertiesStyle} href="http://support.tenminutefunnels.com/en/articles/5574768-how-to-trigger-a-webhook" tooltip={`Click here to learn more about how to trigger a web hook`} />];
      }
    }
  }
};

export const onListItems = ({ id, type }, payload, actions) => {
  if (config.app.name === "10 Minute Funnels") {
    if (id === ListItemsHooks.EDITOR_OBJECT_MENU) {
      const generateHelpPayload = (menu_id, text) => [
        ...payload,
        { id: "divider" },
        { id: menu_id, icon: "help", text },
      ]

      return null;

      switch (type) {
        case 'Button':
          return generateHelpPayload("button_help", "Help with Buttons")
        case ComponentTypes.SECTION:
          return generateHelpPayload("section_help", "Help with Sections")
        case ComponentTypes.HEADLINE:
          return generateHelpPayload("headline_help", "Help with Headlines")
        case ComponentTypes.TEXT:
          return generateHelpPayload("text_help", "Help with Text Elements")
        case ComponentTypes.VIDEO:
          return generateHelpPayload("video_help", "Help with Videos")
        case ComponentTypes.IMAGE:
          return generateHelpPayload("image_help", "Help with Images")
        case ComponentTypes.CONTAINER:
          return generateHelpPayload("container_help", "Help with Containers")
        case ComponentTypes.SPACER:
          return generateHelpPayload("spacer_help", "Help with Spacers")
        case ComponentTypes.DIVIDER:
          return generateHelpPayload("divider_help", "Help with Dividers")
        case ComponentTypes.FORM:
          return generateHelpPayload("form_help", "Help with Forms")
        case ComponentTypes.FORMFIELD:
          return generateHelpPayload("formfield_help", "Help with Form Fields")
        case ComponentTypes.NAVIGATION:
          return generateHelpPayload("navigation_help", "Help with Navigations")
        case ComponentTypes.BUTTON:
          return generateHelpPayload("button_help", "Help with Buttons")
        case ComponentTypes.LIST:
          return generateHelpPayload("list_help", "Help with Lists")
        case ComponentTypes.LISTITEM:
          return generateHelpPayload("listitem_help", "Help with List Items")
        case ComponentTypes.ORDERBUMP:
          return generateHelpPayload("orderbump_help", "Help with Order Bumps")
        case ComponentTypes.POPUP:
          return generateHelpPayload("popup_help", "Help with Popups")
        case ComponentTypes.PROGRESS:
          return generateHelpPayload("progress_help", "Help with Progress Elements")
        case ComponentTypes.ICON:
          return generateHelpPayload("icon_help", "Help with Icons")
        case ComponentTypes.GOOGLELOGIN:
          return generateHelpPayload("googlelogin_help", "Help with Google Login Buttons")
        case ComponentTypes.FACEBOOKLOGIN:
          return generateHelpPayload("facebooklogin_help", "Help with Facebook Login Buttons")
        case ComponentTypes.COUNTDOWN:
          return generateHelpPayload("countdown_help", "Help with Countdowns")
        // case ComponentTypes.ORDERSUMMARY:
        //   return generateHelpPayload("ordersummary_help", "Help with Order Summary Elements")
        case ComponentTypes.FACEBOOKCOMMENTS:
          return generateHelpPayload("facebookcomments_help", "Help with Facebook Comments")
        case ComponentTypes.BODY:
          return generateHelpPayload("body_help", "Help with Body Customization")
        case ComponentTypes.HTML:
          return generateHelpPayload("html_help", "Help with Html Elements")
        case ComponentTypes.COLUMNS:
          return generateHelpPayload("columns_help", "Help with Columns")
        case ComponentTypes.COLUMN:
          return generateHelpPayload("column_help", "Help with Columns")
        case ComponentTypes.PICTURE:
          return generateHelpPayload("picture_help", "Help with Pictures")
        case ComponentTypes.TABS:
          return generateHelpPayload("tabs_help", "Help with Tabs")
      }
    }
  }
}

export const onMenuSelect = (hook, payload, actions) => {
  const { id, type } = hook
  if (config.app.name === "10 Minute Funnels") {
    if (id === ListItemsHooks.EDITOR_OBJECT_MENU) {
      console.log("onMenuSelect (tooltip)", hook, payload)
      // if (type.toLowerCase() === "button" || payload.id === "button_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360267-buttons") }
      // if (type.toLowerCase() === "section" || payload.id === "section_help") { window.open("http://support.tenminutefunnels.com/en/articles/4396210-page-section-widget") }
      // if (type.toLowerCase() === "headline" || payload.id === "headline_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360283-headline-and-text") }
      // if (type.toLowerCase() === "text" || payload.id === "text_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360283-headline-and-text") }
      // if (type.toLowerCase() === "video" || payload.id === "video_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360245-videos") }
      // if (type.toLowerCase() === "image" || payload.id === "image_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360200-pictures") }
      // if (type.toLowerCase() === "container" || payload.id === "container_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360104-containers") }
      // if (type.toLowerCase() === "spacer" || payload.id === "spacer_help") { window.open("http://support.tenminutefunnels.com/en/articles/4410550-spacer-widget") }
      // if (type.toLowerCase() === "divider" || payload.id === "divider_help") { window.open("http://support.tenminutefunnels.com/en/articles/4410562-divider-widget") }
      // if (type.toLowerCase() === "form" || payload.id === "form_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360295-form-widget") }
      // if (type.toLowerCase() === "formfield" || payload.id === "formfield_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360295-form-field") }
      // if (type.toLowerCase() === "navigation" || payload.id === "navigation_help") { window.open("http://support.tenminutefunnels.com/en/articles/4392251-navigation-widget") }
      // if (type.toLowerCase() === "button" || payload.id === "button_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360267-buttons") }
      // if (type.toLowerCase() === "list" || payload.id === "list_help") { window.open("http://support.tenminutefunnels.com/en/articles/4390904-bullet-points-widget") }
      // if (type.toLowerCase() === "listitem" || payload.id === "listitem_help") { window.open("http://support.tenminutefunnels.com/en/articles/4399183-list-item-widget") }
      // if (type.toLowerCase() === "orderbump" || payload.id === "orderbump_help") { window.open("http://support.tenminutefunnels.com/en/articles/4939436-order-bump") }
      // if (type.toLowerCase() === "popup" || payload.id === "popup_help") { window.open("http://support.tenminutefunnels.com/en/articles/3030327-opt-in-pop-up-window") }
      // if (type.toLowerCase() === "progress" || payload.id === "progress_help") { window.open("http://support.tenminutefunnels.com/en/articles/4392340-progress-widget") }
      // if (type.toLowerCase() === "icon" || payload.id === "icon_help") { window.open("http://support.tenminutefunnels.com/en/articles/4390872-icon-widget") }
      // if (type.toLowerCase() === "googlelogin" || payload.id === "googlelogin_help") { window.open("http://support.tenminutefunnels.com/en/articles/4390830-google-optin-widget") }
      // if (type.toLowerCase() === "facebooklogin" || payload.id === "facebooklogin_help") { window.open("http://support.tenminutefunnels.com/en/articles/4372914-facebook-optin-widget") }
      // if (type.toLowerCase() === "countdown" || payload.id === "countdown_help") { window.open("http://support.tenminutefunnels.com/en/articles/3346889-countdown-timer") }
      // // itype.toLowerCase() === "ordersummary" || f (payload.id === "ordersummary_help") { window.open("https://app.intercom.com/a/apps/lw210xad/articles/articles/4399263") }
      // if (type.toLowerCase() === "facebookcomments" || payload.id === "facebookcomments_help") { window.open("http://support.tenminutefunnels.com/en/articles/3224668-social-facebook-comments") }
      // if (type.toLowerCase() === "body" || payload.id === "body_help") { window.open("http://support.tenminutefunnels.com/en/articles/4956520-page-body") }
      // if (type.toLowerCase() === "html" || payload.id === "html_help") { window.open("http://support.tenminutefunnels.com/en/articles/4965892-adding-an-html-code-to-a-page") }
      // if (type.toLowerCase() === "columns" || payload.id === "columns_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360165-columns") }
      // if (type.toLowerCase() === "column" || payload.id === "column_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360165-columns") }
      // if (type.toLowerCase() === "picture" || payload.id === "picture_help") { window.open("http://support.tenminutefunnels.com/en/articles/2360200-pictures") }
      // if (type.toLowerCase() === "tabs" || payload.id === "tabs_help") { window.open("http://support.tenminutefunnels.com/en/articles/4939199-the-tab-widget") }
      // if (type.toLowerCase() === "button" || payload.id === "button_help") { window.open("http://support.tenminutefunnels.com/en/articles/4939495-button-widget") }
    }
  }
}