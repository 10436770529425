import * as React from "react";
import TextField from "@material-ui/core/TextField";

import { LabelProps } from "../types";
import styles from "@launchos/modules/v2/CRUD/FormBuilder/styles";

/**
 * A component for collecting the label to use for the form field
 */
const Label: React.FC<LabelProps> = ({
  onChange = () => null,
  onBlur = () => null,
  value,
  label,
}) => {
  let inputRef = React.useRef(null);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 50);
  // }, []);
  return (
    <TextField
      data-testid="FormBuilder-Attributes-Label"
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      onClick={(e) => e.stopPropagation()}
      onKeyUp={e => e.which === 13 && onBlur(e.target.value)}
      defaultValue={value}
      value={value}
      label={label || "Label"}
      variant="filled"
      style={styles.input}
      inputRef={inputRef}
    />
  );
};

export default Label;
