import { Button, Cell, FontIcon, Grid, TextInput } from "@launchos/components/ui";
import theme from "@launchos/components/ui/theme";
import { AppConfig } from "@launchos/modules/app";
import React, { Component } from "react";
import styled from "styled-components";
import registrars from "./registrars";
import { style } from "./style";
export class DomainTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myRegistrar: false,
      searchFilter: false,
      step: 0
    };
    console.log("registrars", Object.keys(registrars));
  }
  static contextType = AppConfig;
  render() {
    const { app } = this.context;
    const RegistrarItem = styled.div`
      border: 2px solid transparent;
      cursor: pointer;
      &:hover {
        border: 2px solid ${theme.primaryColor};
      }
    `;
    if (!this.props.hidden) {
      const { changeStep } = this.props;
      const { myRegistrar, step } = this.state;
      if (!this.state.myRegistrar)
        return (
          <div style={{ width: 720 }}>
            <h2>
              Which company did you use to buy your domain name? <br />
              (or host your website)
            </h2>
            <div style={{ ...style.main }}>
              <TextInput
                iconLeft="search"
                style={{
                  border: "none",
                  ":hover": { border: "none" },
                  ":focus": { border: "none" }
                }}
                placeholder="Search"
                onKeyUp={e => this.setState({ searchFilter: e.target.value })}
              />
              <div style={{ borderTop: "1px solid #DDD", marginTop: -3 }}>
                <Grid
                  style={{
                    padding: 5,
                    minHeight: 100,
                    maxHeight: 330,
                    overflowY: "scroll"
                  }}
                  wrap
                >
                  {Object.keys(registrars).map((itm, key) => {
                    if (
                      !this.state.searchFilter ||
                      this.state.searchFilter === "" ||
                      itm
                        .toLowerCase()
                        .indexOf(this.state.searchFilter.toLowerCase()) > -1
                    )
                      return (
                        <RegistrarItem key={key} title={itm}>
                          <Cell
                            onClick={() => this.setState({ myRegistrar: itm })}
                            align="middle"
                            key={itm}
                            style={{
                              width: 220,
                              height: 75,
                              overflow: "hidden"
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: 5,
                                padding: 22,
                                cursor: "pointer"
                              }}
                              src={registrars[itm].logo}
                            />
                          </Cell>
                        </RegistrarItem>
                      );
                    return null;
                  })}
                </Grid>
              </div>
            </div>
          </div>
        );
      else
        return (
          <div>
            <h2>
              Perfect! You purchased this domain name from{" "}
              {myRegistrar.toUpperCase()}.<br />
              So, to finish connecting it, simply...
            </h2>
            <Grid style={{ width: 700, margin: "45px 0" }}>
              <Cell
                style={{
                  backgroundColor: "#DDD",
                  padding: "20px 30px 20px 0",
                  display: "inline-block",
                  width: 400
                }}
              >
                <a
                  href={registrars[myRegistrar].steps[step].image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "100%" }}
                    alt=""
                    src={registrars[myRegistrar].steps[step].image}
                  />
                </a>
              </Cell>
              <Cell
                style={{
                  textAlign: "left",
                  padding: 30,
                  width: 300,
                  display: "inline-block"
                }}
              >
                <div>
                  <img
                    style={{ width: 150 }}
                    alt=""
                    src={registrars[myRegistrar].logo}
                  />
                </div>
                <div style={{ margin: "10px 0" }}>
                  <div style={{ display: "inline-block", float: "right" }}>
                    <p
                      style={{ marginTop: 10, textAlign: "center", zoom: 1.2 }}
                    >
                      <FontIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState(prevState => ({
                            step: prevState.step
                              ? parseInt(prevState.step, 10) - 1
                              : 0
                          }))
                        }
                      >
                        arrow_left
                      </FontIcon>
                      <span
                        style={{
                          display: "inline-block",
                          padding: 10,
                          top: -7,
                          position: "relative"
                        }}
                      >
                        Step {step + 1} of{" "}
                        {registrars[myRegistrar].steps.length}
                      </span>
                      <FontIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState(prevState => ({
                            step:
                              prevState.step <
                                registrars[myRegistrar].steps.length - 1
                                ? parseInt(prevState.step, 10) + 1
                                : prevState.step
                          }))
                        }
                      >
                        arrow_right
                      </FontIcon>
                    </p>
                  </div>
                  <div>
                    <span
                      style={{
                        padding: 5,
                        backgroundColor: theme.primaryColor,
                        color: "white",
                        fontSize: 10,
                        fontWeight: 600
                      }}
                    >
                      STEP {step + 1}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        marginRight: 10,
                        marginTop: 5,
                        width: 0,
                        height: 0,
                        borderTop: "10px solid transparent",
                        borderBottom: "10px solid transparent",
                        borderLeft: `10px solid ${theme.primaryColor}`,
                        position: "relative",
                        top: 6
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontWeight: 600,
                      display: "block",
                      marginTop: 15,
                      clear: "both"
                    }}
                  >
                    {registrars[myRegistrar].steps[step].title.replace(
                      "##CFDOMAIN##",
                      app.cloudfrontDomain
                    )}
                  </p>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: registrars[myRegistrar].steps[
                      step
                    ].description.replace(
                      "##CFDOMAIN##",
                      `<span style="background:#FFC">${process.env.REACT_APP_CNAME}</span>`
                    )
                  }}
                />
              </Cell>
            </Grid>
            <Grid justify="space-evenly">
              <Cell align="center">
                <Button onClick={() => changeStep("success")} large tertiary>
                  All Done
                </Button>
              </Cell>
            </Grid>
            <p style={{ marginTop: 30, fontSize: 12, color: "#666" }}>
              Not using {myRegistrar}? &nbsp;
              <span
                onClick={() => this.setState({ myRegistrar: false })}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "blue"
                }}
              >
                Click here for connection instructions using a different
                registrar
              </span>
            </p>
          </div>
        );
    }
    return null;
  }
}
