import { client } from "@launchos/api/gql/client";
import { Button, Cell, Grid, Paper, TextInput } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import gql from "graphql-tag";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import style from "./style";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      error: false,
      message: "",
      success: false,
    };

    this.sendResetInstructions = this.sendResetInstructions.bind(this);
  }

  static contextType = AppConfig;

  componentDidMount() {
    const { app } = this.context;
    if (this.state.loading) this.setState({ loading: false });

    // if (app.loadIntercom) window.Intercom("shutdown");
  }

  async sendResetInstructions() {
    const { email } = this.state;

    const to = email;
    const from = "10 Minute Funnels <support@tenminutefunnels.com>";
    const subject = `Password Reset Instructions`;// for <%= name %>`;
    const text = ``;
    // <p>Hi <%= name %>,</p>
    const html = `
      <html lang="en">
      <head>
      </head>
      <body>
        <p>Thanks for using 10 Minute Funnels.</p>
        <p>Someone requested a password reset for your account.</p>
        <p>If this was not you, please disregard this email.</p>
        <p>If you'd like to continue, click the link below.</p>
        <p>This link will expire in 20 minutes</p>
        <p>${window.location.href.replace(/\/$/, "")}/<%= token %></p>
        <p>Thanks,</p>
        <p>10 Minute Funnels Team</p>
      </body>
      </html>
    `;

    const resetResponse = await client.mutate({
      mutation: gql`
        mutation sendResetPasswordEmail(
          $to: String!
          $from: String!
          $subject: String!
          $text: String!
          $html: String!
        ) {
          sendResetPasswordEmail(
            to: $to
            from: $from
            subject: $subject
            text: $text
            html: $html
          ) {
            statusCode
            message
          }
        }
      `,
      variables: {
        to,
        from,
        subject,
        text,
        html,
      },
    });

    const response = _.get(resetResponse, "data.sendResetPasswordEmail", false);

    console.log({ response });

    if (response.statusCode === 400) {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    } else {
      this.setState({
        error: false,
        success: true,
      });
    }
  }

  render() {
    const { app } = this.context;

    return (
      <Grid justify="center" align="center" style={{ height: "80vh" }}>
        <Cell align="center">
          <div style={style.paper}>
            <img
              role="presentation"
              style={style.logo}
              alt={app.name}
              src={app.logoBlack}
            />
            {this.state.error ? (
              <div style={style.error}>{this.state.message}</div>
            ) : null}

            {this.state.success ? (
              <div>
                <h2>Your password reset instructions have been sent</h2>
                <p>Please check your email to continue.</p>
                <div style={{ width: 450, marginLeft: 'auto', marginRight: 'auto', marginTop: 30, marginBottom: 50 }}>
                  <Link to="/login">
                    <Button style={style.button} large secondary>
                      Back to Login Form
                    </Button>
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <h2>Enter the email address you signed up with below.</h2>

                <p>
                  We will send you an email with a link to reset your password.
                </p>

                <div style={{ width: 450, marginLeft: 'auto', marginRight: 'auto', marginTop: 10, marginBottom: 30 }}>
                  <TextInput
                    name="email"
                    label="Email Address"
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) this.sendResetInstructions();
                    }}
                    focusOnMount
                  />
                </div>

                {this.state.loading ? (
                  <img alt="" src={app.smallLoader} style={{ margin: 20 }} />
                ) : (
                  <Button
                    style={style.button}
                    large
                    secondary
                    onClick={this.sendResetInstructions}
                  >
                    Send Reset Instructions
                  </Button>
                )}
              </>
            )}
          </div>
        </Cell>
      </Grid>
    );
  }
}

PasswordReset.defaultProps = {
  // logo: app.logoBlack,
  error: false,
};

export default PasswordReset;
