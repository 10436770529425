import React from "react";
import { get, head, has, filter } from "lodash";
import { ComponentTypes } from "../../../../WebComponent/types";
import { ComponentSelector } from "../Helpers/ComponentSelector";
import { NiceMenu } from "../Helpers/NiceMenu";
import { ListItem } from "@launchos/components/ui";

export const SwitchTabs = (props) => {
    const { action, updateAction, settings, pageContent } = props;
    const { payload } = action;

    if (settings.type === ComponentTypes.TABS) return null;

    const content = has(payload, "tabId") ? filter(pageContent, (itm) => itm.id === payload.tabId) : [];

    return (
        <React.Fragment>
            <ComponentSelector
                {...props}
                filterOnly={[["type", ComponentTypes.TABS]]}
                label={has(payload, "tabLabel") && content.length
                    ? `Tab Element: ${payload.tabLabel}`
                    : "Select a Tab Element..."}
                onChange={(itm) => {
                    updateAction(action, {
                        ...get(action, "payload", false),
                        tabId: itm.settings.id,
                        tabLabel: itm.settings.name || itm.settings.type,
                    })
                }}
                children={null}
            />
            {Boolean(has(payload, 'tabLabel') && content.length) && (
                <NiceMenu style={{ marginTop: 10 }} label={has(payload, 'tabItemId') ? `Switch to: ${get(head(head(content)['items'].filter(({ id }) => payload.tabItemId === id)), 'label', '(N/A)')}` : "Choose a Tab to Switch To..."}>
                    {head(content)['items'].map(({ id, label, icon }) => (
                        <ListItem onClick={() => updateAction(action, { ...get(action, 'payload'), tabItemId: id })} icon="tab">{label}</ListItem>
                    ))}
                </NiceMenu>
            )}
        </React.Fragment>
    );
};