import { get } from "lodash";
import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";

import Administration from "./Administration";
// import settings from "@launchos/10mf/src/config";

export const onListItems = (hook, payload, actions) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  // console.log({ user });
  if (
    hook.id === ListItemsHooks.APP_DRAWER &&
    hook.position === "top" &&
    get(user, "permissions", []).indexOf("admin") > -1
  ) {
    return [
      {
        route: "/admin",
        icon: "account_box",
        label: "Administration",
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    return [Administration];
  }
};
