import * as React from "react";
import { Grid as MuiGrid } from "@material-ui/core";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface GridProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  style?: any;
  spacing?: any;
  direction?: any;
  justify?: any;
  alignItems?: any;
}

export const Grid: React.FC<GridProps> = ({
  children,
  style,
  spacing,
  direction,
  justify,
  alignItems,
}) => {
  return (
    <MuiGrid
      data-testid="WC-GRID-LIVE"
      style={style}
      spacing={spacing}
      container
      direction={direction}
      justify={justify}
      alignItems={alignItems}
    >
      {children}
    </MuiGrid>
  );
};

Grid.defaultProps = {
  spacing: 0,
};

export default Grid;
