import * as React from "react";
import { Grid } from "@material-ui/core";
import { SortableList } from "@launchos/modules/v2/CRUD/SortableList";
import Icon, { IconTypes } from "../../Icon";

import { PromoCodeBuilderProps, PromoCodeItemType } from "../types";

const styles = {
  wrapper: {
    backgroundColor: "#EEE",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: "1px solid #999",
    padding: 10,
    marginTop: 10,
  },
  itemWrapper: {
    margin: "18px 0",
  },
  spacing: { padding: "0 3px" },
  input: {
    margin: "0 10px",
    padding: 8,
    fontSize: "10pt",
    borderRadius: 3,
    width: "100%",
    border: "1px solid #DDD",
  },
  label: { fontSize: "8pt", padding: 2, color: "#666" },
};

const DeleteButton: React.FC = ({ onDelete, payload }) => (
  <div style={{ position: "relative" }}>
    <div
      style={{
        cursor: "pointer",
        marginTop: -2,
        marginLeft: -20,
        color: "#666",
        position: "absolute",
      }}
      onClick={(itm) => {
        console.log({ payload });
        onDelete(payload);
      }}
    >
      <Icon type={IconTypes.Cancel} />
    </div>
  </div>
);

export const PromoCodeBuilder: React.FC<PromoCodeBuilderProps> = ({
  data,
  onAdd,
  onEdit,
  onChange,
  onDelete,
}) => {
  const ItemComponent: React.FC<PromoCodeItemType> = (itm) => {
    const [code, setCode] = React.useState(itm.code);
    const [discount, setDiscount] = React.useState(itm.discount);

    return (
      <Grid container spacing={2}>
        <Grid xs={8} style={styles.spacing}>
          <input
            key={itm.key}
            style={styles.input}
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onBlur={(e) => {
              const key = data.findIndex((d) => d.id === itm.id);
              const newData = [
                ...data.slice(0, key),
                { ...itm, code: e.target.value },
                ...data.slice(key + 1),
              ];
              onChange(newData);
            }}
          />
        </Grid>
        <Grid xs={4} style={styles.spacing}>
          <input
            key={itm.iKey}
            style={{ ...styles.input, width: 45 }}
            type="text"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            onBlur={(e) => {
              const key = data.findIndex((d) => d.id === itm.id);
              const newData = [
                ...data.slice(0, key),
                { ...itm, discount: e.target.value },
                ...data.slice(key + 1),
              ];
              onChange(newData);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div style={styles.wrapper}>
      <Grid container>
        <Grid xs={8} style={styles.spacing}>
          <span style={styles.label}>If Promo Code is...</span>
        </Grid>
        <Grid xs={4} style={styles.spacing}>
          <span style={{ ...styles.label, textAlign: "right" }}>
            Discount by...
          </span>
        </Grid>
      </Grid>
      <SortableList
        data={data}
        name="Promo Code"
        itemStyle={styles.itemWrapper}
        ItemComponent={ItemComponent}
        onAdd={onAdd}
        onEdit={onEdit}
        onChange={onChange}
        onDelete={onDelete}
        showDragHandle={false}
        canDragAndDrop={false}
        canEdit={false}
        DeleteButtonComponent={DeleteButton}
      />
    </div>
  );
};
