import { get, head } from "lodash";
import { ComponentRenderHooks } from "@launchos/plugins/types";
import { ComponentTypes } from "../../types";
import CarouselEditorVersion from "./CarouselComponent";
import CarouselItemEditorVersion from "./CarouselItemComponent";
import settings from "./settings";

// export const onListItems = (hook, payload, actions) => {
//   if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
//     return [settings];
//   }
// };

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.CAROUSEL
  ) {
    return [CarouselEditorVersion];
  }

  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.CAROUSELITEM
  ) {
    // console.log("Carousel Item", payload, hook, actions.getPageContent());
    const pageContent = actions.getPageContent();
    const parentCarousel = head(
      pageContent.filter(({ id }) => id === payload.parent)
    );
    if (
      get(parentCarousel, "items", []).findIndex(
        ({ id }) => id === get(payload, "itemId")
      ) > -1
    )
      return [CarouselItemEditorVersion];
  }

  return null;
};
