import * as React from "react";
import { get } from "lodash";
import * as WebComponents from "..";
import { Container } from "..";
import { ComponentTypes } from "../../types";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface SwitchProps {
  mode?: EditorMode;
  style?: any;
  globalStyle?: any;
  actionItems?: ISwitchItems[] | undefined | any;
  containerItems: ISwitchItems[] | undefined | any;
}

interface ISwitchItems {
  type?: ComponentTypes;
  style?: any;
  html?: string | React.ReactNode;
}

export const Switch: React.FC<SwitchProps> = ({
  actionItems = [],
  containerItems = [],
  globalStyle,
}) => {
  const [containerIndex, setContainerIndex] = React.useState(
    get(containerItems[0], "id", false)
  );

  const ActionItems = () =>
    actionItems((n) => setContainerIndex(n)).map((itm: ISwitchItems | any) => {
      const Component: any = WebComponents[itm.type];

      return (
        <Component {...itm} style={{ ...globalStyle, ...itm.style }}>
          {itm.html}
        </Component>
      );
    });

  const ContainerItems = () =>
    containerItems
      .filter((itm) => get(itm, "id") === containerIndex)
      .map((itm: ISwitchItems | any) => {
        const TheContainer = itm.children;
        return (
          <Container {...itm} style={{ ...globalStyle, ...itm.style }}>
            <TheContainer />
          </Container>
        );
      });

  return (
    <div data-testid="WC-SWITCH-LIVE">
      <ActionItems />
      <ContainerItems />
    </div>
  );
};

export default Switch;
