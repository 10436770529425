import * as React from "react";
import { get } from "lodash";
import { PropertiesProps } from "../types";

import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

import formBuilderSettings from "../settings";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import EditFormField from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/EditFormField";
import {
  defaultData,
  styleData,
} from "@launchos/modules/v2/CRUD/FormBuilder/FormBuilder.stories";
import { EditorMode } from "@launchos/modules/v2/Editor/types";
import { FormBuilder } from "@launchos/modules/v2/CRUD/FormBuilder";

/**
 * The property window that shows for the FormBuilderEditorObject
 */
const FormBuilderPropertyWindow: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);

  const { properties } = formBuilderSettings;
  const { sections } = properties.main;

  /**
   * Triggers when the <FormBuilder /> component showing in the property window changes
   * @params
   */
  const handleFormBuilderChange = (data, dbUpdate = false): void => {
    const { updateComponentSettings } = props;
    const newSettings = { ...props.settings, data };
    // console.log({ newSettings })
    setSettings(newSettings);
    updateComponentSettings(settings.id, newSettings, dbUpdate);
  };


  const withFormBuilder = updateSection(sections, "formBuilder_settings", {
    component: (
      <div style={{
        width: 350,
        marginLeft: -20,
      }}>
        <FormBuilder
          {...props}
          data={settings.data}
          mode={EditorMode.EDITOR}
          onBlur={(newData) => handleFormBuilderChange(newData, true)}
          onChange={(newData) => handleFormBuilderChange(newData, true)}
        />
      </div>
    ),
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withFormBuilder },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export default React.memo(FormBuilderPropertyWindow);
