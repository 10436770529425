import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 100,
    mobile: {
      desktop: {
        padding: 100,
      },
      smartphone: {
        padding: 0,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "12pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      textAlign: "center",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      minWidth: 150,
      marginTop: 45,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "14pt",
    },
  },
  LIST: {
    padding: 20,
    margin: "0 auto",
    marginTop: 20,
  },
  LI: {
    style: {
      // color: theme.foregroundColor,
      fontFamily: theme.fontFamily,
      padding: 5,
    },
    contentStyle: {
      padding: "3px 10px",
    },
  },
};
