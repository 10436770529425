import * as React from "react";
import numeral from "numeral";
import { get, uniqueId, keys, map } from "lodash";
import ReactJson from "react-json-view";

import { Grid } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

import { AttributeContainer } from "@launchos/modules/v2/CRUD/FormBuilder";
import {
  SortableList,
  DefaultItemComponent,
} from "@launchos/modules/v2/CRUD/SortableList";

import { fieldset } from "@launchos/components/ui/theme";
import styles from "@launchos/modules/v2/CRUD/FormBuilder/styles";

import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";
import { ItemComponentTypes } from "@launchos/modules/v2/CRUD/SortableList/types";

import { PromoCodeBuilder } from "./PromoCodeBuilder";

import orderBuilderSettings from "../settings";
import { IconTypes } from "../../Icon";

import {
  OrderSummaryItemList,
  OrderSummaryBuilderProps,
  OrderSummaryItemTypes,
} from "../types";

interface ItemComponentProps extends OrderSummaryItemList {
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isHovering?: boolean;
}

export const SummaryBuilder: React.FC<OrderSummaryBuilderProps> = ({
  data,
  onChange,
  // links,
}) => {
  const [isExpandedList, setIsExpandedList] = React.useState<string[]>([]);

  const handleChange = (mutatedPayload: OrderSummaryItemList[]): void => {
    console.log({ mutatedPayload });
    onChange(mutatedPayload);
  };

  const handleEdit = (data: OrderSummaryItemList): void => {
    // toggle expansion
    if (isExpandedList.indexOf(data.id) > -1) {
      // remove if it exists
      setIsExpandedList(isExpandedList.filter((id) => id !== data.id));
    } else {
      // add if it doesn't exist
      setIsExpandedList([...isExpandedList, data.id]);
    }
  };

  const handleAdd = () => {
    const newData = {
      ...orderBuilderSettings.default.data[0],
      id: Math.random()
        .toString(36)
        .slice(2),
      title: "Item",
    };

    handleChange([...data, newData]);
    handleEdit(newData);
  };

  const ItemComponent: React.FC<ItemComponentProps> = (incAttr) => {
    const {
      id,
      title,
      value,
      onMouseEnter,
      onMouseLeave,
      isHovering,
      // icon,
    } = incAttr;

    const [attributes, setAttributes] = React.useState<OrderSummaryItemList>({
      itemType: OrderSummaryItemTypes.PRODUCT,
      label: title,
      value: 0,
      ...incAttr,
    });

    const isExpanded = Boolean(isExpandedList.indexOf(id) > -1);

    const handleSingleFieldBlur = (newAttributes) => {
      const key = data.findIndex((itm) => itm.id === id);
      const newData = [
        ...data.slice(0, key),
        { ...data[key], title: newAttributes.label, ...newAttributes },
        ...data.slice(key + 1),
      ];
      console.log({ newData });
      onChange(newData);
    };

    const getLabelText = (type: OrderSummaryItemTypes) => {
      switch (type) {
        case OrderSummaryItemTypes.PRODUCT:
          return "Price";
        case OrderSummaryItemTypes.DISCOUNT:
          return "Discount by... (e.g $12 or 10%)";
        case OrderSummaryItemTypes.SALESTAX:
          return "Sales Tax Amount";
        case OrderSummaryItemTypes.SHIPPING:
          return "Shipping Amount";
      }
    };

    return (
      <DefaultItemComponent
        caption={`${title}${value && ` (${numeral(value).format("$0,0.00")})`}`}
        icon={IconTypes.ArrowRight}
        type={ItemComponentTypes.TEXT}
        onAdd={handleAdd}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isExpanded={isExpanded}
        isHovering={isHovering}
        toggleExpansion={() => handleEdit({ id })}
      >
        {/* <ReactJson src={attributes} collapsed /> */}
        <div style={{ position: "relative", top: 15, paddingBottom: 20 }}>
          {/* <fieldset style={fieldset}>
            <legend>Item Type</legend>
            <RadioGroup
              value={attributes.itemType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newAttributes = {
                  ...attributes,
                  itemType: e.target.value as OrderSummaryItemTypes,
                };
                setAttributes(newAttributes);
                handleSingleFieldBlur(newAttributes);
              }}
            >
              <Grid container>
                <Grid xs={6}>
                  <FormControlLabel
                    value={OrderSummaryItemTypes.PRODUCT}
                    control={<Radio />}
                    label="Product"
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    value={OrderSummaryItemTypes.SALESTAX}
                    control={<Radio />}
                    label="Sales Tax"
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    value={OrderSummaryItemTypes.DISCOUNT}
                    control={<Radio />}
                    label="Discount"
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    value={OrderSummaryItemTypes.SHIPPING}
                    control={<Radio />}
                    label="Shipping"
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    value={OrderSummaryItemTypes.PROMOCODE}
                    control={<Radio />}
                    label="Promo Code"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </fieldset> */}
          <fieldset style={{ ...fieldset, paddingBottom: 20 }}>
            <legend>
              <div style={{ display: "flex" }}>
                <div style={{ padding: "3px 8px" }}>
                  Item Settings ({attributes.itemType})
                </div>
              </div>
            </legend>
            <AttributeContainer
              attributes={attributes}
              data={[
                {
                  id: "label",
                  attribute: AttributeNames.LABEL,
                  settings: {
                    label: `Item Text`,
                  },
                },
              ]}
              onChange={(attr) => {
                // console.log({ attr });
                setAttributes(attr);
              }}
              onBlur={(data) => {
                console.log({ data });
                handleSingleFieldBlur({ ...data, title: data.label });
              }}
              hideFooter
            >
              {attributes.itemType !== OrderSummaryItemTypes.PROMOCODE ? (
                <TextField
                  onChange={(e) =>
                    setAttributes({ ...attributes, value: e.target.value })
                  }
                  onBlur={(e) => {
                    // setAttributes({ ...attributes, value: e.target.value });
                    handleSingleFieldBlur({
                      ...attributes,
                      value: e.target.value,
                    });
                  }}
                  onClick={(e) => e.stopPropagation()}
                  defaultValue={get(attributes, "value")}
                  value={get(attributes, "value")}
                  label={getLabelText(attributes.itemType)}
                  variant="filled"
                  style={styles.input}
                />
              ) : (
                <PromoCodeBuilder
                  data={attributes.codes}
                  onAdd={() => {
                    handleSingleFieldBlur({
                      ...attributes,
                      codes: [
                        ...attributes.codes,
                        {
                          id: uniqueId(),
                          code: uniqueId(`PROMO`),
                          discount: "5%",
                        },
                      ],
                    });
                  }}
                  onChange={(newCodes) =>
                    handleSingleFieldBlur({
                      ...attributes,
                      codes: newCodes,
                    })
                  }
                  onDelete={(payload, newCodes) =>
                    handleSingleFieldBlur({
                      ...attributes,
                      codes: newCodes,
                    })
                  }
                />
              )}
            </AttributeContainer>
          </fieldset>
        </div>
      </DefaultItemComponent>
    );
  };

  return (
    <div>
      <SortableList
        data={data}
        itemStyle={{
          border: "1px solid #DDD",
          borderRadius: 3,
          margin: "10px 0",
          height: 45,
        }}
        ItemComponent={ItemComponent}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onChange={handleChange}
        onDelete={(payload, updatedData) => handleChange(updatedData)}
        canDragAndDrop
        showItemInline={false}
        name="Item"
      />
    </div>
  );
};
