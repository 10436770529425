export enum DisplayItemsPropsType {
  CARDS,
  TABLE,
}

export enum DisplayItemsFieldDataType {
  STRING,
  TIME,
  DATE,
  CURRENCY,
  NUMBER,
  BOOLEAN,
  FILE,
  EMAIL,
  CUSTOM,
  SENTENCE,
  JSON,
  INDEX,
  COMPONENT,
  FIELD_OVERRIDE,
  LINK,
}

export enum BulkActionsMenuPropsType {
  BUTTON,
  ICON,
}

export enum BulkSelectedActionsPropsLocation {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
}

export interface AlphabetFilterProps {
  /**
   * The style to use for the component that wraps the character list
   */
  containerStyle?: any;

  /**
   * The style to use for each character rendered
   */
  characterStyle?: any;

  /**
   * Triggers when any of the characters are clicked
   */
  onClick: (character: string) => string;
}

export interface DisplayItemsFieldData {
  name: string;
  label: string;
  type: DisplayItemsFieldDataType;
  default?: boolean;
  onClick?: (row: unknown) => any;
  styles?: any;
}

export interface DisplayItemsProps {
  /**
   * The name to use for the dataDisplay component.  Useful for plugins that want to hook into your specific data display vs others
   */
  name?: string;

  /**
   * Establishes the type of data display to show (card view or table view)
   */
  type?: DisplayItemsPropsType;

  /**
   * The list of items to display
   */
  data?: any[];

  /**
   * The list of fields (and their settings) you plan to use for this data display
   */
  fields?: DisplayItemsFieldData[];

  /**
   * Whether or not to show the loading component instead of the data records
   */
  loading?: Boolean;

  /**
   * The component to use instead of the default loading component
   */
  loadingComponent?: React.ReactNode;

  /**
   * The title to show for the display
   */
  title?: string;

  /**
   * The list of bulk actions (and their settings) to provide the bulkActionControls component
   */
  bulkActions?: any[];

  /**
   * The component to use instead of the default BulkActions component
   */
  bulkActionsComponent?: (props?: any) => React.ReactNode;

  /**
   * Whether or not to show the bulkAction component
   */
  showbulkActions?: Boolean;

  /**
   * The list of available actions (and their settings) that can be applied to each data item that is displayed
   */
  dataItemActions?: any[];

  /**
   * The component to use for each item instead of the default DataDisplayCard component
   */
  dataItemComponent?: (props?: any) => React.ReactNode;

  /**
   * Whether or not to show selectable checkboxes next to each item
   */
  showCheckboxes?: Boolean;

  /**
   * The component to use instead of the default FilterGroup component
   */
  filterComponent?: (props?: any) => React.ReactNode;

  /**
   * Whether or not to show the filter controls
   */
  showFilterControls?: Boolean;

  /**
   * The current filter being displayed now
   */
  currentFilter?: any[];

  /**
   * Whether or not to show the alphabet controls
   */
  showAlphabetFilter?: Boolean;

  /**
   * The component to use instead of the default EmptyStateComponent
   */
  emptyStateComponent?: React.ReactNode;

  /**
   * The list of tags (one-click search filters) to show
   */
  tags?: string[];

  /**
   * Whether or not the list of tags are to be showing or not
   */
  showTags?: Boolean;

  /**
   * Whether or not to show the header
   */
  showHeaders?: Boolean;

  /**
   * Whether or not to show pagination controls
   */
  showPagination?: Boolean;

  /**
   * The settings to use for pagination
   */
  paginationSettings?: any;

  /**
   * Whether or not a sort icon is showing next to the field headers of a display table
   */
  enableSorting?: Boolean;

  /**
   * Triggers with appropriate payload when a new record is to be created
   */
  onCreate?: () => void;

  /**
   * Triggers with appropriate payload when an existing record is updated
   */
  onUpdate?: () => void;

  /**
   * Triggers with appropriate payload when the delete option on a record is selected
   */
  onDelete?: () => void;

  /**
   * Triggers when an individual record is selected (e.g. clicked)
   */
  onSelect?: () => void;

  /**
   * Triggers when all items are to be selected
   */
  onSelectAll?: () => void;

  /**
   * Triggers when one of the row action items are selected
   */
  onRowActionSelect?: () => void;

  /**
   * Triggers when the filter is to be changed
   */
  onFilterChange?: (filter?: any) => any;

  /**
   * Triggers when the current filter is to be saved
   */
  onFilterSave?: () => void;

  /**
   * Triggers when a sort is supposed to happen
   */
  onSort?: () => void;

  /**
   * Triggers when an export is supposed to happen
   */
  onExport?: () => void;

  /**
   * Triggers when an import is supposed to happen
   */
  onImport?: () => void;

  /**
   * Triggers when one of the bulk actions are selected
   */
  onBulkActionSelect?: () => void;

  /**
   * Triggers when the page is supposed to change
   */
  onPaginationChange?: () => void;
}

export interface BulkActionsMenuProps {
  /**
   * Whether to show a button or an icon as the menu
   */
  type?: BulkActionsMenuPropsType;

  /**
   * The icon to show
   */
  icon: string;

  /**
   * The text to display in the button
   */
  label?: string;

  /**
   * An array of items to show as the menu
   */
  data: any[];

  /**
   * Triggers when an item is selected
   */
  onSelect: (item: any) => any;
}

export interface BulkSelectedActionsProps {
  /**
   * custom components to display inside
   */
  children?: (props: any) => React.ReactNode;

  /**
   * The array of selected items
   */
  data?: any[];

  /**
   * The location to show the component
   */
  location?: BulkSelectedActionsPropsLocation | undefined;

  /**
   * An array of data object items to show that represents a button to perform the most common action for the selected records (e.g. 'Delete These Campaigns')
   */
  actionButtons?: any[];

  /**
   * Whether or not a single action button is showing (e.g. 'Archive')
   */
  showActionButtons?: Boolean;

  /**
   * Whether or not to show a bulk actions menu button
   */
  showBulkActionsMenuButton?: Boolean;

  /**
   * The props/settings to use for the Bulk Actions Menu Button
   */
  bulkActionsMenuSettings?: BulkActionsMenuProps;

  /**
   * For styling the object
   */
  style?: any;
}

export interface DataCardProps {
  /**
   * Display an optional icon in the card view
   */
  icon?: string;

  /**
   * Display an image in the main card view
   */
  image?: string;

  /**
   * Display component in the main card view
   */
  component?: React.ReactNode;

  /**
   * A list of tags, and associated actions to display on the card
   */
  tags?: any[];

  /**
   * The caption to show on the card
   */
  label?: string;

  /**
   * Whether or not the label's name can be edited.  Triggers onDataChange() event when clicked away, or [enter] is pressed
   */
  canEditLabel?: Boolean;

  /**
   * The list of items you want to show in the actions dropdown menu, and the associated function to perform when selected
   */
  actions?: any[];

  /**
   * Whether or not a clickable menu of 'actions' should be showing or not
   */
  showActionControls?: Boolean;

  /**
   * Triggered when the card is clicked
   */
  onClick?: () => any;

  /**
   * Triggered when the card is double-clicked
   */
  onDoubleClick?: () => any;

  /**
   * Triggered when an action item is selected
   */
  onActionSelect?: (itm: any) => any;

  /**
   * Triggered when the data needs to change in some way (e.g. the title of the card is renamed)
   */
  onDataChange?: () => any;
}

export interface FilterGroupProps {
  /**
   * Whether or not the date range controls are visible
   */
  showDateRange?: Boolean;

  /**
   * object representing the filter query
   */
  data?: any;

  /**
   * The list of fields (and their settings) you plan to use for filtering
   */
  fields: DisplayItemsFieldData[];

  /**
   * All the previous filters that were saved
   */
  savedFilters?: any[];

  /**
   * Whether or not the Save Filter feature is enabled and the existing list of Saved Filters is showing
   */
  savedFiltersIsEnabled?: Boolean;

  /**
   * Triggers whenever a filter is selected
   */
  onChange: (filter: any) => any;

  /**
   * Triggers when a filter group is deleted
   */
  // onDelete: () => any;

  /**
   * Trigger when a new filter is created or added to the group
   */
  // onCreate: () => any;

  /**
   * Trigger when all the filters are cleared
   */
  // onClear: () => any;

  /**
   * Trigger when the chosen group of filters are saved
   */
  // onSave: () => any;
}
