import { Button, Cell, Grid, TextInput } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { app } from "../../../config";
import providers from "../../settings/IntegrationManagement/integrationProviders";

export default class ChooseAmount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      amount: "0.00"
    };

    this.saveAmount = this.saveAmount.bind(this);
  }

  static contextType = AppConfig;

  componentDidMount() {
    const { app } = this.context;
    const providerType = _.get(
      this.props,
      "providerSettings.payload.type",
      app.defaultProvider
    );

    this.providerInfo = _.filter(providers, o => o.id === providerType)[0];

    const amount = _.get(this.props, "providerSettings.payload.amount", "0.00");

    this.setState({ amount });
  }

  async saveAmount() {
    const { updateSettings, providerSettings, history, nextHref } = this.props;

    const { amount } = this.state;

    // Save to State grab the entire campaign payload
    const payload = providerSettings.payload;

    await updateSettings({
      ...payload,
      amount
    });

    // Show the Next Screen
    history.push(nextHref);
  }

  render() {
    if (!this.props.hidden) {
      return (
        <div
          style={{
            width: 500,
            margin: "0 auto",
          }}
        >
          <div style={{ marginTop: -30 }}>
            {/* {JSON.stringify({ amount: this.state.amount })} */}
            <img
              alt=""
              src={_.get(
                this,
                "providerInfo.img",
                "https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png"
              )}
              style={{ height: 75, margin: 20 }}
            />
            <br />
          </div>
          <h2>{this.props.message}</h2>

          <div style={{ width: 150, margin: "0 auto" }}>
            <br />
            <TextInput
              iconLeft="attach_money"
              style={{ fontSize: 16 }}
              selectOnMount
              value={parseFloat(this.state.amount.replace(/[^0-9.]/g, ""))
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              format="currency"
              onBlur={(e) => {
                console.log({ e });
                this.setState({ amount: e.target.value.replace(/,/g, "") });
              }}
            />
          </div>
          <Grid
            justify="space-evenly"
            style={{
              marginTop: 30,
            }}
          >
            <Cell align="center">
              {this.props.showProvider ? (
                <Link to={this.props.prevHref}>
                  <Button large transparent style={{ color: "#333" }}>
                    Back to Providers
                  </Button>
                </Link>
              ) : null}
              <Button large tertiary onClick={this.saveAmount}>
                Next
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
