import * as React from "react";
import { get } from "lodash";
import { fieldset } from "@launchos/components/ui/theme";

import { Tabs, Tab } from "@material-ui/core";

import IconSelector from "@launchos/modules/v2/Properties/components/properties/IconSelector";
import { UploadGroup } from "@launchos/modules/editor/Builder/Properties";

const ArrowSelector: React.FC<{
  onChange: (
    type?: "icon" | "image",
    direction?: "left" | "right",
    value?: string
  ) => void;
  direction: "left" | "right";
}> = ({ onChange, direction = "left" }) => {
  return (
    <div>
      <div style={{ marginTop: 25 }}>
        <UploadGroup
          url=""
          onChange={(url) => onChange("image", direction, url)}
          highlighted
          hideLabel
        />
      </div>
      <fieldset style={fieldset}>
        <legend>Choose an Icon</legend>
        <IconSelector
          containerStyle={{ zoom: 0.85 }}
          label="Choose an arrow icon"
          onChange={(icn) => onChange("icon", direction, icn)}
          isExpanded={false}
          showNoneSelector
          initialSearchString="arrow"
        />
      </fieldset>
    </div>
  );
};

export const CarouselArrowOptions: React.FC = ({ arrowOptions, onChange }) => {
  const [value, setValue] = React.useState<number>(0);

  const switchTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = (type, direction = "left", value) => {
    console.log({ type, direction, value });
    onChange({ type, direction, value });
  };

  return (
    <div>
      <Tabs value={value} onChange={switchTab}>
        <Tab label="Left Arrow" value={0} />
        <Tab label="Right Arrow" value={1} />
      </Tabs>
      <div>
        {value === 0 && (
          <ArrowSelector direction="left" onChange={handleChange} />
        )}
        {value === 1 && (
          <ArrowSelector direction="right" onChange={handleChange} />
        )}
      </div>
    </div>
  );
};
