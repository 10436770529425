export { default } from "./Generator";

export const updateSection = (sections, typeOrId, obj) => {
  let k = sections.findIndex(
    (itm) => itm.type === typeOrId || itm.id === typeOrId
  );
  return [
    ...sections.slice(0, k),
    {
      ...sections[k],
      settings: {
        ...sections[k].settings,
        ...obj,
      },
    },
    ...sections.slice(k + 1),
  ];
};
