import React from "react";

import { Section, Margin, Padding } from "..";

const PositionPadding = (props) => (
  <Section
    label="Position & Padding"
    icon="import_export"
    isExpanded={props.isExpanded}
  >
    <Margin {...props} />
    <Padding {...props} />
  </Section>
);

export default PositionPadding;
