import gql from "graphql-tag";

// createMediaItem;
export const createMediaItem = gql`
  mutation createMediaItem($userId: ID!, $pageId: ID, $campaignId: ID) {
    createMediaItem(
      data: {
        user: { connect: { id: $userId } }
        page: { connect: { id: $pageId } }
        campaign: { connect: { id: $campaignId } }
      }
    ) {
      id
      name
      user {
        id
      }
      file {
        id
        name
        url
        contentType
      }
      url
      createdAt
    }
  }
`;

// uploadURLToS3;
export const uploadURLToS3 = gql`
  mutation uploadURLToS3($bucket: String!, $url: String!, $fileName: String!) {
    uploadURLToS3(bucket: $bucket, url: $url, fileName: $fileName) {
      statusCode
      message
      url
      response
    }
  }
`;
