import { Button, Cell, Grid, Paper, TextInput } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import style from "./style";
import Auth from "./verifyAccount";

const auth = new Auth();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      error: false,
      message: "",
    };
    this.login = this.login.bind(this);
  }

  static contextType = AppConfig;

  componentDidMount() {
    const { app } = this.context;
    if (this.state.loading) this.setState({ loading: false });

    localStorage.removeItem("accessToken");
    localStorage.removeItem("auth0UserId");
    localStorage.removeItem("email");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("userId");
    localStorage.removeItem("clipboard");

    // if (app.loadIntercom) window.Intercom("shutdown");
  }

  login() {
    const { email, password } = this.state;
    this.setState({ loading: true, error: false });

    auth.login(email, password, this.props, (response) => {
      console.log({ response });
      if (response.result === "error") {
        this.setState({
          loading: false,
          error: true,
          message: response.message.description,
        });
      } else {
        this.setState({ loading: false }, () => {
          window.location.href = "/dashboard";
        });
      }
    });
  }

  render() {
    const { app } = this.context;
    return (
      <Grid justify="center" align="center" style={{ height: "80vh" }}>
        <Cell align="center">
          <div style={style.paper}>
            <img
              role="presentation"
              style={style.logo}
              alt={app.name}
              src={app.logoBlack}
            />
            {this.state.error ? (
              <div style={style.error}>
                We're sorry. We were unable to log you in: &nbsp;
                {/* {this.state.message} */}
              </div>
            ) : null}
            <TextInput
              name="email"
              label="Email Address"
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) this.login();
              }}
            // focusOnMount
            />
            <TextInput
              name="password"
              type="password"
              label="Enter Password"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) this.login();
              }}
            />
            {this.state.loading ? (
              <img alt="" src={app.smallLoader} style={{ margin: 20 }} />
            ) : (
              <Grid style={{ marginTop: 40 }} justify="center" align="center">
                <Cell align="center">
                  <Button
                    style={style.button}
                    large
                    secondary
                    onClick={this.login}
                  >
                    Sign In
                  </Button>
                </Cell>
                {this.state.error && (
                  <Cell align="center">
                    <Link to="./reset">
                      <Button style={{ color: "black" }} transparent>
                        Forgot My Password
                      </Button>
                    </Link>
                  </Cell>
                )}
              </Grid>
            )}
          </div>
        </Cell>
      </Grid>
    );
  }
}

Login.defaultProps = {
  // logo: app.logoBlack,
  error: false,
};

export default Login;
