import * as React from "react";
import { get, head } from 'lodash';

import { NiceMenu, ListItem } from "@launchos/components/ui";

import { SelectValueProps } from "./types";

export const SelectValue: React.FC<SelectValueProps> = ({
  value = "",
  items = [],
  multiple = false,
  onChange = () => null,
  // onBlur = () => null,
}) => {

  const handleChange = (e) => {
    // let data = multiple ? Array.from(e.target.selectedOptions, option => option.value) : e.target.value;
    // onChange(data)

    onChange(e)
  }

  return (
    <div data-testid="SelectValue-LIVE">
      {/* <select value={value} onChange={handleChange} multiple={multiple}>
        {items.map(({ id, caption }, key) => (
          <option key={key} value={id}>{caption}</option>
        ))}
      </select> */}
      {!multiple && (
        <NiceMenu label={get(head(items.filter(itm => itm.id === value)), 'caption', 'Choose...')}>
          {items.map(({ id, caption }, key) => (
            <ListItem key={key} onClick={() => handleChange(id)}>{caption}</ListItem>
          ))}
        </NiceMenu>
      )}
    </div>
  );
};

export default SelectValue;
