import { CSSProperties } from "react";
import { themeSettings } from "@launchos/plugins/webcomponents/v2/theme";
export const style: {
  BUTTON: {
    properties: CSSProperties;
    caption1: CSSProperties;
    caption2: CSSProperties;
  };
} = {
  BUTTON: {
    properties: {
      display: "inline-block",
      // margin: 10,
      textAlign: "center",
      padding: "0.3125rem",
      // padding: 10,
      border: "none",
      borderRadius: 5,
      // width: "100%",
      // cursor: "pointer",
      backgroundColor: "#e43b2c",
      boxShadow: `0 1px 5px rgba(0,0,0,0.2)`,
    },
    caption1: {
      // fontFamily: "Poppins",
      // textShadow: "2px 2px rgb(0,0,0,0.25)",
      // fontSize: "22pt",
      // fontWeight: "bold",
      color: themeSettings.default.lightTextColor,
      // lineHeight: "35px",
      // display: "block",

      // color: "#FFFFFF"
      display: "block",
      fontFamily: "Oswald",
      fontSize: "1.72rem",
      fontWeight: "bold",
    },
    caption2: {
      color: "white",
      fontSize: "0.85rem",
    },
  },
};
