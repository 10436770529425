import React from 'react';
import { ListItemsHooks } from "@launchos/plugins/types";
import ZeroToHeroTutorial from "./ZeroToHeroTutorial";
import config from "@launchos/10mf/src/config";

import tours from "./tours"

export const onListItems = (hook, payload, actions) => {
  if (
    hook.id === ListItemsHooks.ROUTES &&
    localStorage.userId &&
    !Boolean(localStorage.getItem("zero-to-hero")) &&
    config.app.name === "10 Minute Funnels" &&
    payload.pathname.indexOf('login') === -1
  ) {

    const showThisTour = localStorage.getItem("showThisTour") || 'welcome';
    let data = tours[showThisTour];
    const isFromIframe = window.location !== window.parent.location;
    if (isFromIframe) return [];
    
    return [<ZeroToHeroTutorial data={data} />];
  }
};
