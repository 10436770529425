import { client } from "@launchos/api/gql/client";
import { Button, Cell, Grid, Paper, TextInput } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import gql from "graphql-tag";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import style from "./style";

class PasswordUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      expiredToken: false,
      loading: false,
      userId: false,
    };
    this.updatePassword = this.updatePassword.bind(this);
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { app } = this.context;
    if (this.state.loading) this.setState({ loading: false });

    // if (app.loadIntercom) window.Intercom("shutdown");

    // check token
    const { token } = this.props.match.params;

    const { data } = await client.query({
      query: gql`
        query verifyToken($token: String!) {
          verifyToken(token: $token) {
            message
            statusCode
            response
          }
        }
      `,
      variables: {
        token,
      },
    });

    const { statusCode, response } = data.verifyToken;

    this.setState({
      expiredToken: statusCode !== 200,
      userId: _.get(response, "id", false),
    });

    console.log({ statusCode });
  }

  async updatePassword() {
    const { password, confirmPassword } = this.state;
    this.setState({ loading: true, error: false });

    if (password !== confirmPassword) {
      alert("Your Passwords Don't Match.  Please Try again");
      this.setState({ loading: false });
    } else {
      // update the password now

      const { data } = await client.mutate({
        mutation: gql`
          mutation resetPassword($id: ID!, $password: String!) {
            resetPassword(id: $id, password: $password) {
              statusCode
              message
            }
          }
        `,
        variables: {
          id: this.state.userId,
          password,
        },
      });

      const { resetPassword } = data;

      if (resetPassword.statusCode === 200) {
        this.props.history.push("/dashboard");
      } else {
        alert(resetPassword.message);
      }
    }
  }

  render() {
    const { app } = this.context;
    return (
      <Grid justify="center" align="center" style={{ height: "80vh" }}>
        <Cell align="center">
          <div shadowDepth={0} style={style.paper}>
            <img
              role="presentation"
              style={style.logo}
              alt={app.name}
              src={app.logoBlack}
            />
            {this.state.expiredToken ? (
              <>
                <div style={style.error}>
                  Sorry. Your Reset Link Has Expired. Please reset your password
                  again.
                </div>
                <Link to="./">
                  <Button
                    style={style.button}
                    large
                    secondary
                    onClick={this.updatePassword}
                  >
                    Send Another Reset Link
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <h2>Please enter a new password for your {app.name} account</h2>
                <TextInput
                  name="password"
                  type="password"
                  label="Enter Password"
                  value={this.state.password}
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) this.updatePassword();
                  }}
                />
                <TextInput
                  name="password"
                  type="password"
                  label="Confirm Password"
                  value={this.state.confirmPassword}
                  onChange={(e) => {
                    this.setState({ confirmPassword: e.target.value });
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) this.updatePassword();
                  }}
                />
                <div style={{ margin: 40 }}>
                  {this.state.loading ? (
                    <img alt="" src={app.smallLoader} style={{ margin: 20 }} />
                  ) : (
                    <Button
                      style={style.button}
                      large
                      secondary
                      onClick={this.updatePassword}
                    >
                      Reset My Password
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </Cell>
      </Grid>
    );
  }
}

PasswordUpdate.defaultProps = {
  // logo: app.logoBlack,
  error: false,
};

export default PasswordUpdate;
