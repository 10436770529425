import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import EditorColumns from "./Columns";
import settings from "./settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Columns") {
    return [EditorColumns];
  }
};
