import * as React from "react";
import TextField from "@material-ui/core/TextField";

import RichEditor from "@launchos/modules/editor/Builder/Properties/RichEditor";

import { InitialValueProps } from "../types";
// import { FormFieldTypes } from "../../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import styles from "@launchos/modules/v2/CRUD/FormBuilder/styles";

/**
 * A component for collecting the initial value to use for the specific form field
 */
const InitialValue: React.FC<InitialValueProps> = ({
  onChange = () => null,
  onBlur = () => null,
  value,
  fieldType = FormFieldTypes.TEXT,
  label,
}) => {
  let inputRef = React.useRef(null);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 50);
  // }, []);

  switch (fieldType) {
    case FormFieldTypes.TEXT: {
      return (
        <TextField
          type="text"
          data-testid="FormBuilder-Attributes-InitialValue"
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => onBlur(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onKeyUp={e => e.which === 13 && onBlur(e.target.value)}
          defaultValue={value}
          value={value}
          style={styles.input}
          variant="filled"
          label={label || "Initial Value"}
          inputRef={inputRef}
        />
      );
    }

    case FormFieldTypes.CONTENT: {
      return (
        <div
          style={{ padding: 10 }}
          data-testid="FormBuilder-Attributes-InitialValue-CONTENT"
        >
          <RichEditor>
            <p>
              <strong>Apollo 11</strong> was the spaceflight that landed the
              first humans, Americans{" "}
              <a href="http://en.wikipedia.org/wiki/Neil_Armstrong">
                Neil Armstrong
              </a>
              and
              <a href="http://en.wikipedia.org/wiki/Buzz_Aldrin">Buzz Aldrin</a>
              , on the Moon on July 20, 1969, at 20:18 UTC. Armstrong became the
              first to step onto the lunar surface 6 hours later on July 21 at
              02:56 UTC.
            </p>
          </RichEditor>
        </div>
      );
    }

    case FormFieldTypes.DATE: {
      return (
        <TextField
          data-testid="FormBuilder-Attributes-InitialValue"
          id="datetime-local"
          type="datetime-local"
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => onBlur(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          style={styles.input}
          label={label || "Initial Value"}
          inputRef={inputRef}
        />
      );
    }
  }
};
export default InitialValue;
