import {
  validationSchema,
  FormFieldTypes,
} from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { LiveFormFieldProps } from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField/types";
// import { FormFieldTypes } from "@launchos/plugins/webcomponents/v2/FormField/types";

export interface PlaceholderProps {
  /**
   * The current value of the field
   */
  value?: string;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * Triggers when the field changes
   */
  onChange?: (value: string) => void;

  /**
   * Triggers when the field loses focus
   */
  onBlur?: (value: string) => void;
}

export interface DescriptionProps {
  /**
   * The current value of the field
   */
  value?: string;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * Triggers when the field changes
   */
  onChange?: (value: string) => void;

  /**
   * Triggers when the field loses focus
   */
  onBlur?: (value: string) => void;
}

export interface InitialValueProps {
  /**
   * The current value of the field
   */
  value?: string;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * Triggers when the field changes
   */
  onChange?: (value: string) => void;

  /**
   * Triggers when the field loses focus
   */
  onBlur?: (value: string) => void;

  /**
   * The type of field this attribute is for
   */
  fieldType?: FormFieldTypes;
}

export interface LabelProps {
  /**
   * The current value of the field
   */
  value?: string;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * Triggers when the field changes
   */
  onChange?: (value: string) => void;

  /**
   * Triggers when the field loses focus
   */
  onBlur?: (value: string) => void;
}

export interface FormItemNameProps {
  /**
   * the current "name" value
   */
  value?: string;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * Triggers when the field changes
   */
  onChange?: (value: string) => void;

  /**
   * Triggers when the field loses focus
   */
  onBlur?: (value: string) => void;
}

export interface ColumnsProps {
  /**
   * the current column count value
   */
  value?: string;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * Triggers when the column count content field changes
   */
  onChange?: (value: string) => void;

  /**
   * Triggers when the column count field loses focus
   */
  onBlur?: (value: string) => void;
}

export interface RequiredProps {
  /**
   * The current value of the required checkbox toggle switch
   */
  value?: boolean;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * The label (default = "Required") to display next to the toggle switch
   */
  label?: string;

  /**
   * Triggers when the toggle switch changes
   */
  onChange?: (checked: boolean) => void;
}

export interface ValidationCriteriaProps {
  /**
   * An array of the criteria to use for the specific field
   */
  criteria?: validationSchema;

  /**
   * The current type of form field this attribute is for.  This will determine which criteria to make available to the user
   */
  fieldType?: FormFieldTypes;

  /**
   * The label/placeholder to use for the text field
   */
  label?: string;

  /**
   * Triggers when the validation criteria changes
   */
  onChange?: (updatedCriteria: validationSchema) => void;
}

export interface AttributeList {
  id: string;
  attribute: AttributeNames;
  settings: AllAttributeProps;
}

export enum AttributeNames {
  PLACEHOLDER = "Placeholder",
  DESCRIPTION = "Description",
  INITIALVALUE = "Initial Value",
  LABEL = "Label",
  FORMITEMNAME = "Form Item Name",
  COLUMNS = "Columns",
  REQUIRED = "Required",
  VALIDATIONCRITERIA = "Validation Criteria",
  ICON = "Icon Selector",
  LINK = "Link Selector",
  PRESET = "Preset Selector",
}

export type AllAttributeProps =
  | PlaceholderProps
  | DescriptionProps
  | InitialValueProps
  | LabelProps
  | FormItemNameProps
  | ColumnsProps
  | RequiredProps
  | ValidationCriteriaProps;

export interface AttributeContainerProps {
  /**
   * An array of objects (containing attribute information) to display
   */
  data?: AttributeList[];

  /**
   * The current attributes for the form field
   */
  attributes?: LiveFormFieldProps;

  /**
   * Whether or not to stop showing the show/hide footer
   */
  hideFooter?: boolean;

  /**
   * Override the default container style
   */
  style?: React.CSSProperties;

  /**
   * Triggers when any attribute in the list changes
   */
  onChange?: (data: LiveFormFieldProps) => void;

  /**
   * Triggers when any attribute in the list blur's
   */
  onBlur?: (data: LiveFormFieldProps) => void;
}
