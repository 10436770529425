import * as React from "react";
import { head, merge } from "lodash";

import { FormBuilderProps, FormFieldTypes } from "./types";
import { EditorMode } from "../../Editor/types";
import { EditFormField } from "./EditFormField";

import { LiveFormFieldProps } from "./LiveFormField/types";

// import { cancelActions } from "@launchos/modules/editor/scripts";
import { cancelActions } from "@launchos/modules/editor/scripts"

import {
  SortableList,
  DefaultItemComponent,
} from "@launchos/modules/v2/CRUD/SortableList";

// import { Icon } from "@launchos/plugins/webcomponents/v2";
import { FieldNamePresets, FieldNameIcons } from "./EditFormField/types";
// import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
// import theme from "@launchos/components/ui/theme";
import { ItemComponentTypes } from "../SortableList/types";
import { LiveFormBuilder } from "./LiveFormBuilder";

interface ItemProps extends LiveFormFieldProps {
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isHovering?: boolean;
}

const newFormItem: LiveFormFieldProps = {
  id: Math.random().toString(36).slice(2),
  type: FormFieldTypes.TEXT,
  name: "email",
  placeholder: "Email Address",
  // label: "Text Label",
  // description: "Text Description",
  // defaultValue: "Testing Default Value",
  // styles: styleData,
  // icon: IconTypes.Email,
  columns: 12,
  preset: FieldNamePresets.EMAIL,
};

const FormBuilder: React.FC<FormBuilderProps> = ({
  mode = EditorMode.LIVE,
  data = [],
  globalFieldStyle,
  style = {},
  onChange = () => null,
  onBlur = () => null,
}) => {
  const [isExpandedList, setIsExpandedList] = React.useState<string[]>([]);

  const handleTypeChange = (id: string, type: FormFieldTypes, preset, attr = {}): void => {
    const key = data.findIndex((itm) => itm.id === id);
    // console.log({ id, type, preset, attr })
    onChange([
      ...data.slice(0, key),
      { ...data[key], type, preset, ...attr },
      ...data.slice(key + 1),
    ]);

    // make sure it is expanded
    if (isExpandedList.indexOf(id) === -1) setIsExpandedList([...isExpandedList, id]);
  };

  const handleChange = (mutatedPayload: LiveFormFieldProps[]): void => {
    console.log({ mutatedPayload });
    onChange(mutatedPayload);
  };

  const handleEdit = (data: LiveFormFieldProps): void => {
    console.log("Handle Edit")
    // toggle expansion
    if (data.id === head(isExpandedList)) setIsExpandedList([])
    else setIsExpandedList([data.id]);
    // if (isExpandedList.indexOf(data.id) > -1) {
    //   // remove if it exists
    //   setIsExpandedList(isExpandedList.filter((id) => id !== data.id));
    // } else {
    //   // add if it doesn't exist
    //   setIsExpandedList([...isExpandedList, data.id]);
    // }
  };

  const ItemComponent: React.FC<ItemProps> = (itm) => {
    const { id, type, label, name, placeholder, preset, styles } = itm;

    const [fieldData, setFieldData] = React.useState<ItemProps>(itm);
    const isExpanded = Boolean(isExpandedList.indexOf(id) > -1);

    const handleSingleFieldChange = (
      attrItem: LiveFormFieldProps,
      dbUpdate = true
    ): void => {
      const key = data.findIndex((itm) => itm.id === attrItem.id);
      const newData = [...data.slice(0, key), attrItem, ...data.slice(key + 1)];
      setFieldData(attrItem);
      if (dbUpdate) onChange(newData);
    };

    const handleSingleFieldBlur = (
      attrItem: LiveFormFieldProps,
      dbUpdate = true
    ): void => {
      const key = data.findIndex((itm) => itm.id === attrItem.id);
      const newData = [...data.slice(0, key), attrItem, ...data.slice(key + 1)];
      setFieldData(attrItem);
      if (dbUpdate) onBlur(newData);
    };

    const style = merge(globalFieldStyle, styles);

    return (
      <DefaultItemComponent
        isHovering={itm.isHovering}
        onMouseEnter={itm.onMouseEnter}
        onMouseLeave={itm.onMouseLeave}
        type={ItemComponentTypes.TEXT}
        icon={FieldNameIcons[preset === "custom" ? type : preset]}
        caption={label || placeholder || name || (preset === FieldNamePresets.CUSTOM ? type : preset)}
        isExpanded={isExpanded}
        toggleExpansion={() => {
          if (!isExpanded) setIsExpandedList([id]) // let there be only one expanded at a time
          else setIsExpandedList([])
        }}
      >
        <div style={{
          backgroundColor: "#F5F5F5",
          borderRadius: 5,
          padding: 30
        }}>
          <h2 style={{ textAlign: 'center', paddingBottom: 20, textShadow: '1px 1px white', fontSize: '14pt' }}>{type} Field Settings</h2>
          <EditFormField
            id={id}
            type={type}
            preset={preset}
            fieldData={fieldData}
            onBlur={(newData) => handleSingleFieldBlur(newData)}
            onChange={(newData) => handleSingleFieldChange(newData, false)}
            onTypeChange={(type: FormFieldTypes, preset, attr = {}) => handleTypeChange(id, type, preset, attr)}
            globalFieldStyle={style}
            isExpanded
          />
        </div>
      </DefaultItemComponent>
    );
  };


  return (
    <div data-testid="FormBuilder"
      onClick={cancelActions}
      onMouseDown={cancelActions}
      onContextMenu={cancelActions}
      onDrag={cancelActions}
      onDragStart={cancelActions}
    >
      {/* {JSON.stringify({ globalFieldStyle, style })} */}
      {mode === EditorMode.LIVE && (
        <LiveFormBuilder
          data={data}
          style={globalFieldStyle}
          _style={style}
        />
      )}
      {mode === EditorMode.EDITOR && (
        <SortableList
          id="form_builder"
          data={data}
          itemStyle={{
            border: "1px solid #DDD",
            borderRadius: 3,
            margin: "10px 0",
            height: 45,
          }}
          itemHoverStyle={{
            border: "1px solid #AAA",
            boxShadow: "0 2px 1px 0 rgb(0,0,0,0.1)",
            backgroundColor: "#F3F3F3"
          }}
          ItemComponent={ItemComponent}
          name="Form Field"
          onAdd={() => handleChange([
            ...data,
            data.length ? {
              ...data[data.length - 1],
              id: Math.random().toString(36).slice(2),
            } : newFormItem,
          ])}
          onEdit={handleEdit}
          onChange={handleChange}
          onDelete={(payload, updatedData) => handleChange(updatedData)}
          canDragAndDrop
          showItemInline={false}
        />
      )}
    </div>
  );
};

export default FormBuilder;
