import * as React from "react";
import { Grid } from "@material-ui/core";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface StepProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  icon?: string;
  caption1?: string;
  caption2?: string;
  active?: boolean;
  step?: number;
}

export const Step: React.FC<StepProps> = ({
  icon,
  caption1,
  caption2,
  active,
  step,
}) => {
  return (
    <>
      <Grid
        data-testid="WC-STEP-LIVE"
        item
        xs={step === 1 ? 6 : 5}
        style={{
          width: "100%",
          backgroundColor: active ? "#02568d" : "#EEE",
          display: "inline-block",
          padding: 15,
        }}
      >
        <Grid container>
          <Grid item xs={4}>
            <div
              style={{
                fontSize: "24pt",
                fontFamily: "Poppins",
                color: active ? "white" : "#666",
                border: `1px solid ${active ? "white" : "#666"}`,
                borderRadius: 50,
                // marginTop: 10,
                marginLeft: step === 2 ? -15 : 0,
                height: 45,
                width: 45,
              }}
            >
              {step}
            </div>
          </Grid>
          <Grid item xs={8}>
            <div style={{}}>
              <h2
                style={{
                  textAlign: "left",
                  color: active ? "white" : "#666",
                  fontFamily: "Poppins",
                  fontSize: "16pt",
                  padding: 0,
                  margin: 0,
                }}
              >
                {caption1}
              </h2>
              <p
                style={{
                  textAlign: "left",
                  color: active ? "white" : "#666",
                  fontFamily: "Roboto",
                  fontSize: "10pt",
                  fontWeight: "normal",
                }}
              >
                {caption2}
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {step === 1 && (
        <Grid
          item
          xs={1}
          style={{
            width: 0,
            height: 0,
            display: "inline-block",
            // backgroundColor: "#EEE",
            backgroundColor: active ? "#EEE" : "#02568d",
            borderStyle: "solid",
            borderWidth: "60px 0 60px 20px",
            borderColor: `transparent transparent transparent ${active ? "#02568d" : "#EEE"
              }`,
            // borderColor: `transparent transparent transparent ${"#EEE"}`,
          }}
        />
      )}
    </>
  );
};

export default Step;
