import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CTALayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.SEVENTEEN,
  layout: CTALayouts.SEVENTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA17.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "kr3iuf9202l",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: { ...style.TEXT, fontSize: "16pt" },
        html: `Yes! I would like to choose the one time payment option of the almost insane investment of $97`,
      },
      {
        type: ComponentTypes.BUTTON,
        canHaveChildren: false,
        properties: style.BUTTON.style,
        caption1: "Add to Cart",
        caption1style: style.BUTTON.caption1,
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "sub_headline.style", {}),
        },
        html: `<span style="padding: 10px, background-color: yellow, font-weight: bold"><a href="#">Add to Cart: One-Time Payment</a></span>`,
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: style.TEXT,
        html: `<a href="#">No thanks, I realize I will never get this opportunity again.</a>`,
      },
    ],
  },
};

export default settings;
