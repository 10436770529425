import * as React from "react";
import _ from "lodash";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

import { Button } from "./live";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import buttonSettings from "./settings";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { IconTypes } from "../Icon";
import { ColorPickerSection } from "./ColorPickerSection";
import { CaptionSection } from "./CaptionSection";

const Properties = (props) => {
  const { updateComponentSettings, settings } = props;
  const { properties } = buttonSettings;
  const { sections } = properties.main;

  const withIcon = updateSection(sections, SectionTypes.ICONSELECTOR, {
    onChange: (e) => {
      console.log(e);
      updateComponentSettings(
        settings.id,
        { ...settings, icon: IconTypes[e] },
        true,
        0
      );
    },
  });

  const withCaption = updateSection(withIcon, "custom_caption", {
    component: <CaptionSection {...props} />,
  });

  const withColorPicker = updateSection(withCaption, "custom_color_picker", {
    component: <ColorPickerSection {...props} />,
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withColorPicker },
  };

  return (
    <PropertiesBuilder
      title="Button Settings"
      data={updatedProperties}
      {...props}
      children={null}
    />
  );
};

/**
 * A button
 */
export const EditorButton: React.FC<PluginProps> = (props) => {
  const { id, mode, settings } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings} showOverlay={true}>
      <EditorObject
        // showOverlay
        label="Button"
        id={id}
        {...props}
        PropertiesView={Properties}
      >
        <Button {...settings} properties={properties} />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorButton;
