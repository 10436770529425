// import settings from "../../../config";

export default {
  pages: [
    {
      type: "PageComponent",
      id: "blank_page",
      name: "Blank Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_blank.jpg`,
      pageType: "blank"
    },
    {
      type: "PageComponent",
      id: "content_page",
      name: "Content Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_article.jpg`,
      pageType: "content"
    },
    {
      type: "PageComponent",
      id: "home_page",
      name: "Home Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_about.jpg`,
      pageType: "home"
    },
    {
      type: "PageComponent",
      id: "content_page",
      name: "Launch Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_product.jpg`,
      pageType: "launch"
    },
    // {
    //   type: "PageComponent",
    //   id: "content_page",
    //   name: "Product View Page",
    //   screenshot: `${
    //     settings.app.assetsLocation
    //   }/images/icons/pages/NT_text.png`,
    //   pageType: "products"
    // },
    {
      type: "PageComponent",
      id: "email_squeeze_page",
      name: "Lead Capture Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_squeeze_email.jpg`,
      pageType: "lead"
    },
    {
      type: "PageComponent",
      id: "legal_page",
      name: "Legal Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_terms.jpg`,
      pageType: "legal"
    },
    {
      type: "PageComponent",
      id: "live_event_page",
      name: "Live Event Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_video.jpg`,
      pageType: "event"
    },
    {
      type: "PageComponent",
      id: "precart_checkout",
      name: "Pre-cart Checkout Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_precart.jpg`,
      pageType: "checkout"
    },
    {
      type: "PageComponent",
      id: "order_page",
      name: "Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_longform.jpg`,
      pageType: "order"
    },
    {
      type: "PageComponent",
      id: "sales_page",
      name: "Sales Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales.jpg`,
      pageType: "sales"
    },
    {
      type: "PageComponent",
      id: "sales_page",
      name: "Special Offer (Upsell)",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_bonus.jpg`,
      pageType: "oto"
    },
    {
      type: "PageComponent",
      id: "thankyou_page",
      name: "Thank You Page",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_ty_simple.jpg`,
      pageType: "thank you"
    },
    {
      type: "PageComponent",
      id: "webinar_page",
      name: "Event (Webinar) Registration",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_squeeze_webinar.jpg`,
      pageType: "webinar"
    },
    {
      type: "addon",
      id: "ad_optin_popup",
      name: "Opt-in Popup",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_popup_optin.png`
    },
    {
      type: "cart",
      id: "shopping_cart_1shoppingcart",
      name: "ShoppingCart Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_1shoppingcart.png`
    },
    {
      type: "cart",
      id: "shopping_cart_amazon",
      name: "Amazon Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_amazon.png`
    },
    {
      type: "cart",
      id: "shopping_cart_clickbank",
      name: "ClickBank Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_clickbank.png`
    },
    {
      type: "cart",
      id: "shopping_cart_infusionsoft",
      name: "Infusionsoft Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_infusionsoft.png`
    },
    {
      type: "cart",
      id: "shopping_cart_jvzoo",
      name: "JVZoo Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_jvzoo.png`
    },
    {
      type: "cart",
      id: "shopping_cart_nanacast",
      name: "Nanacast Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_nanacast.png`
    },
    {
      type: "cart",
      id: "shopping_cart_paypal",
      name: "PayPal Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_paypal.png`
    },
    {
      type: "cart",
      id: "shopping_cart_samcart",
      name: "SamCart Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_samcart.png`
    },
    {
      type: "cart",
      id: "shopping_cart_shopify",
      name: "Shopify Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_shopify.png`
    },
    {
      type: "cart",
      id: "shopping_cart_ultracart",
      name: "Ultracart Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_ultracart.png`
    },
    {
      type: "cart",
      id: "shopping_cart_zaxaa",
      name: "Zaxaa Order Form",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_zaxaa.png`
    },
    {
      type: "cart",
      id: "shopping_cart_another",
      name: '"Another" Shoping Cart',
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_another.png`
    }
  ],
  traffic: [
    {
      type: "RedirectComponent",
      id: "traffic_source_facebook",
      name: "Facebook Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_facebook.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_adwords",
      name: "Google Adwords",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_adwords.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_email",
      name: "Email Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_email.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_blog",
      name: "Blog Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_blog.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_twitter",
      name: "Twitter Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_twitter.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_youtube",
      name: "YouTube Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_youtube.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_webinar",
      name: "Webinar Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_webinar.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_affiliate",
      name: "Affiliate Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_affiliate.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_other",
      name: '"Other" Traffic',
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_other.png`,
      width: 150,
      height: 100
    },
    {
      type: "RedirectComponent",
      id: "traffic_source_organic",
      name: "Organic Traffic",
      screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_organic.png`,
      width: 150,
      height: 100
    }
  ]
};
