import * as React from "react";
import _ from "lodash";

import { EditorMode } from "@launchos/modules/v2/Editor/types";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import { PluginProps } from "./types";
import { Progress } from "./live";
import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import progressSettings from "./settings";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

const Properties = (props) => {
  // const [barStyleColor, setBarStyleColor] = React.useState("#2096D3")

  const { updateComponentSettings, settings } = props;
  const { properties } = progressSettings;
  const { sections } = properties.main;

  const updatedSectionAmount = updateSection(
    sections,
    SectionTypes.BUTTONSLIDER,
    {
      onChange: (e) => {
        switch (e) {
          case "GROW": {
            const value = _.get(settings, "value", 0) + 5;
            updateComponentSettings(
              settings.id,
              { ...settings, value },
              true,
              0
            );
            break;
          }
          case "SHRINK": {
            const value = _.get(settings, "value", 6) - 5;
            updateComponentSettings(
              settings.id,
              { ...settings, value },
              true,
              0
            );
            break;
          }
          default: {
            updateComponentSettings(
              settings.id,
              { ...settings, value: e },
              true,
              0
            );
          }
        }
      },
    }
  );

  const updatedSections = updateSection(
    updatedSectionAmount,
    SectionTypes.COLORPICKER,
    {
      onChange: (e) => {
        updateComponentSettings(
          settings.id,
          {
            ...settings,
            barStyle: { backgroundColor: e.hex },
          },
          true,
          0
        );
      },
    }
  );

  return (
    <PropertiesBuilder
      {...props}
      data={{
        ...properties,
        main: { ...properties.main, sections: updatedSections },
      }}
      title="Progress Bar Settings"
    />
  );
};

export const EditorProgress: React.FC<PluginProps> = (props) => {
  const { settings, mode, id } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Progress"
        id={id}
        {...props}
        PropertiesView={Properties}
      >
        <Progress
          {...settings}
          properties={properties}
          testId="WC-PROGRESS-EDIT"
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorProgress;
