import _ from "lodash";
import React, { Component } from "react";
import { Menu, ListItem } from "@launchos/components/ui";
import { client } from "@launchos/api/gql";
import { getCampaign } from "@launchos/api/gql/campaigns";
import pageObjects from "@launchos/plugins/nodes/v1/page_nodes/tab/client/objects";
import { AppConfig } from "@launchos/modules/app";

// import scObjects from "../shopping_carts/objects";
import style from "./style";

export default class CampaignSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignId: false,
      label: "Choose a Campaign...",
    };

    this.updateCampaign = this.updateCampaign.bind(this);
  }

  static contextType = AppConfig;

  updateCampaign(d) {
    const { app } = this.context;
    const { setObjects } = this.props;

    this.setState({ campaignId: d.id, label: d.name }, async () => {
      // set node objects to show
      const { data } = await client.query({
        query: getCampaign,
        variables: {
          id: d.id,
        },
      });

      const { objects } = data.campaign;

      const filteredObjects = objects
        .filter((obj) => _.has(obj, "page.slug") && !obj.deleted)
        .map((d) => {
          const getScreenshot = (type) => {
            const thumbnailMatches = pageObjects.filter(
              (itm) => itm.pageType === type
            );
            return thumbnailMatches.length
              ? thumbnailMatches[0].screenshot
              : app.logoIcon;
          };

          return {
            type: "PageShortcutComponent",
            id: d.id,
            name: d.name,
            screenshot: d.screenshot || getScreenshot(d.page.type),
            ...(_.has(d, "page") ? { page: d.page } : {}),
          };
        });

      // console.log({ objects, filteredObjects, scObjects });

      setObjects(filteredObjects);
    });
  }

  render() {
    const { label } = this.state;
    const { allCampaignsQuery, match } = this.props;
    const { campaigns } = allCampaignsQuery;
    const { params } = match;

    return (
      <div style={{ ...style.selectField, width: "90%", margin: "30px auto" }}>
        <Menu style={{ width: "100%" }} label={label}>
          <div style={{ maxHeight: 300, overflowY: "auto" }}>
            {campaigns
              .filter((campaign) => campaign.id !== params.campaignId)
              .map((d) => (
                <ListItem onClick={() => this.updateCampaign(d)} key={d.id}>
                  {d.name}
                </ListItem>
              ))}
          </div>
        </Menu>
      </div>
    );
  }
}
