import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { CTALayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/CTA/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.TWELVE,
  layout: CTALayouts.TWELVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA12.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "3z716fg7jso",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 80,
                  mobile: {
                    desktop: { padding: 80 },
                    smartphone: { padding: 20 },
                  },
                  borderRadius: 5,
                  backgroundColor: "#EEE",
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    style: {
                      margin: "0 auto",
                      display: "inline-block",
                      textAlign: "center",
                    },
                  }),
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                    },
                    html: "Free, Instant Access",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html:
                      "It's true.  Getting results is much easier than you think.  The truth is most people simply don't know",
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: {
                      ...style.BUTTON.style,
                      border: `1px solid #CCC`,
                    },
                    caption1: "Get Started",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 80,
                  mobile: {
                    desktop: { padding: 80 },
                    smartphone: { padding: 20 },
                  },
                  borderRadius: 5,
                  backgroundColor: theme.backgroundColor,
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    style: {
                      margin: "0 auto",
                      display: "inline-block",
                      textAlign: "center",
                    },
                  }),
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      color: theme.foregroundColor,
                    },
                    html: "Free, Instant Access",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      color: theme.foregroundColor,
                    },
                    html:
                      "It's true.  Getting results is much easier than you think.  The truth is most people simply don't know",
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: {
                      ...style.BUTTON.style,
                    },
                    caption1: "Get Started",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
