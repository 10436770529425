import React from "react";
import Section from "@launchos/modules/editor/Builder/Properties/Section";

const Settings: React.FC = () => {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 1400,
        margin: "0 auto",
        marginTop: 50,
      }}
    >
      <Section label="Testing" hideBg style={{ backgroundColor: "#EEE" }}>
        <div style={{ backgroundColor: "white" }}>
          <p>Testing</p>
        </div>
      </Section>
    </div>
  );
};

export default Settings;
