import { forEach, has } from 'lodash'
import nodes_v1 from './nodes/v1/client'
import modules_v1 from './modules/v1/client'
import misc_v1 from './misc/v1/client'
import misc_v2 from './misc/v2/client'
import webcomponents_v1 from './webcomponents/v1/client'
import webcomponents_v2 from './webcomponents/v2/client'

export const plugins = [
  ...misc_v1,
  ...misc_v2,
  ...nodes_v1,
  ...modules_v1,
  ...webcomponents_v1,
  ...webcomponents_v2,
];

export const triggerHook = (name, hook, payload, actions) => {
  let toRet = [];
  forEach(plugins, async itm => {
    if (has(itm, name)) {
      const res = itm[name](hook, payload, actions); // trigger the function on every plugin that has it
      const response = res;
      forEach(response, itm => toRet.push(itm));
    }
  });

  return toRet;
};

export const triggerAsyncHook = async (name, hook, payload, actions) => {
  let ret = [];

  for (let i = 0; i <= plugins.length; i++) {
    const itm = plugins[i];
    if (has(itm, name)) {
      const resp = await itm[name](hook, payload, actions);
      if (resp) ret.push(resp);
    }
  }

  return ret;
};
