import * as React from 'react'
import { get, has, head } from 'lodash'

import ValueSelector from '../ValueSelector';
import { ValueSelectorProps, ValueTypesEnum } from '../ValueSelector/types';
import { LoadSelectorProps, SelectorTypes, getType } from './types';

import Debug from '@launchos/modules/editor/Builder/EditorObject/Debug'


export const LoadSelector: React.FC<LoadSelectorProps> = ({
  data = [],
  type = SelectorTypes.INDEX,
  values = [],
  onChange = () => null,
  allFields = [],
  component = ValueTypesEnum.STRING,
  styles = {}
}) => {


  // when onChange is triggered, simply append the array with the new set of props
  const handleChange = (value, indexProfile, key) => {
    // 1. trigger onChange
    const name = get(indexProfile, 'name');

    // 2. get the props for the next profile to load
    // 2a. get the profile item obj based on the selected option
    const itmProfile = head(get(indexProfile, 'items', []).filter(itm => itm.id === value))

    // 2b. pick out the [next] variable from that obj
    const next = get(itmProfile, 'next');

    // 2c. get the profile object from the allFields array that matches the [next] variable
    const newValueSelectorProps = head(allFields.filter(itm => itm.id === next))
    const valueSelectorWithType = newValueSelectorProps
      ? { ...newValueSelectorProps, type: getType(newValueSelectorProps['type'], data, component) }
      : { name, type: next }

    // console.log('LoadSelector.tsx', { itmProfile, value, next, newValueSelectorProps, valueSelectorWithType })

    const newValues = [
      ...values.slice(0, key),
      { ...values[key], value },
      valueSelectorWithType
    ]

    // 3. now append the value selector array with the new props object
    // TODO: in the future, you can append it with multiple items (if you want more than one to show)
    // onChange(key, name, value, newValues);
    onChange(name, value, newValues)

  }

  // when rendering, render a list of value selectors according to that array
  return (
    <div data-testid="LoadSelector-LIVE" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {
        values.map((itm, key) => get(itm, 'type') && (
          <div key={key} style={{
            ...get(styles, 'valueWrapper', {})
          }}>
            <ValueSelector
              type={itm.type}
              items={get(itm, 'items')}
              value={get(itm, 'value')}
              onChange={id => handleChange(id, itm, key)}
            />
          </div>
        ))
      }
    </div>
  )
}

export default LoadSelector;
