import _ from "lodash";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import * as Tools from "@launchos/modules/editor/Builder/Properties";
import React from "react";
import style from "./style";

const SocialContentEdit = (props) => {
  return (
    <EditorObject {...props} PropertiesView={Properties}>
      <div style={{ ...props.settings.properties, marginTop: 0 }}>
        <img
          src="https://image.ibb.co/bRreYU/fb.jpg"
          style={{ width: "100%" }}
          alt="facebook comments placeholder"
        />
      </div>
    </EditorObject>
  );
};

class BasicPropertiesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: _.get(props, "settings.appId"),
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      appId: e.target.value,
    });
  }

  render() {
    const { updateComponentSettings, settings } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Tools.Section
          label="Facebook App Id"
          isExpanded
          icon="share"
          {...this.props}
        >
          <fieldset style={style.fieldset} title="App Id">
            <legend>App Id</legend>
            <input
              placeholder="Type Facebook App Id"
              onBlur={(e) =>
                updateComponentSettings(settings.id, {
                  ...settings,
                  appId: e.target.value,
                })
              }
              onChange={this.handleChange}
              value={this.state.appId}
              style={{
                padding: 10,
                width: "100%",
                borderRadius: 5,
                border: "0px solid #CCC",
                margin: "5px 0",
                fontSize: 14,
              }}
            />
          </fieldset>
        </Tools.Section>
        <Tools.PositionPadding {...this.props} />
        <Tools.WidthHeight {...this.props} showHeight={false} />
        <Tools.Alignment {...this.props} verticalAlign={false} textAlign />
      </div>
    );
  }
}

const Properties = (props) => (
  <Tools.PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Social", component: BasicPropertiesTab }]}
  />
);

export default (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <SocialContentEdit {...props} />
    </div>
  );
};
