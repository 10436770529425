import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 150,
      },
      smartphone: {
        padding: 40,
      },
    },
  },
  PRE_HEADLINE: {
    textAlign: "center",
    fontSize: "14pt",
    fontFamily: theme.fontFamily,
    padding: 20,
    color: theme.foregroundColor,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
  },
  TEXT: {
    textAlign: "center",
    padding: 20,
    fontFamily: theme.fontFamily,
    color: theme.foregroundColor,
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      background: theme.foregroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "100%",
      padding: 19,
      backgroundColor: "transparent",
      border: `1px solid ${theme.foregroundColor}`,
      borderRadius: 5,
      color: theme.foregroundColor,
    },
  },
};
