import {
  Button,
  Dialog,
  DialogBody,
  DialogTitle,
  Tab,
  Tabs,
} from "@launchos/components/ui";
import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

export default class TrackingScript extends Component {
  constructor(props) {
    super(props);

    const campaign = props.getCampaignQuery.campaign;

    this.state = {
      head: campaign.scriptTags ? JSON.parse(campaign.scriptTags).head : "",
      body: campaign.scriptTags ? JSON.parse(campaign.scriptTags).body : "",
      bodyEnd: campaign.scriptTags
        ? JSON.parse(campaign.scriptTags).bodyEnd
        : "",
      activeTab: "head",
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitTracking = this.submitTracking.bind(this);

    this.switchTab = this.switchTab.bind(this);

    window.scroll(0, 0);
  }

  handleChange(editor, data) {
    // console.log({ editor })
    switch (data) {
      case "head":
        this.setState({
          head: editor,
        });
        break;
      case "body":
        this.setState({
          body: editor,
        });
        break;
      case "/body":
        this.setState({
          bodyEnd: editor,
        });
        break;
      default:
        break;
    }
  }

  async submitTracking() {
    const {
      campaignId,
      getCampaignQuery,
      updateCampaignMutation,
      history,
      closeHref,
    } = this.props;
    const { head, body, bodyEnd } = this.state;

    const scriptTags = JSON.stringify({
      head,
      body,
      bodyEnd,
    });

    const variables = {
      id: campaignId,
      name: getCampaignQuery.campaign.name,
      scriptTags,
    };

    console.log({ variables });

    await updateCampaignMutation({ variables });

    await getCampaignQuery.refetch({
      variables: { userId: localStorage.userId },
    });

    console.log({ variables }, { closeHref });
    history.push(
      localStorage.builderType === "editor" ? "./builder" : "./edit"
    );
  }

  switchTab(activeTab) {
    console.log({ activeTab });
    this.setState({ activeTab });
  }

  render() {
    const { activeTab } = this.state;

    return (
      <Dialog
        {...this.props}
        // style={style.popup}
        // overlayStyle={style.popupBg}
        style={{ marginTop: 0 }}
        centered
        closeHref={
          localStorage.builderType === "editor" ? "./builder" : "./edit"
        }
      >
        <DialogTitle>CAMPAIGN TRACKING CODE</DialogTitle>
        <DialogBody>
          <div style={{ overflowY: "scroll", textAlign: "left", width: 600 }}>
            <h2 style={{ textAlign: "center", marginBottom: 30 }}>
              Enter any custom scripts you want placed on
              <br />
              each page of this campaign
              {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Campaign Tracking' })}
            </h2>

            <Tabs transparent>
              <Tab
                key="head"
                active={activeTab === "head"}
                onClick={() => this.switchTab("head")}
              >
                {" "}
                &lt;Head&gt;
              </Tab>
              <Tab
                key="body"
                active={activeTab === "body"}
                onClick={() => this.switchTab("body")}
              >
                &lt;Body&gt;
              </Tab>
              <Tab
                key="bodyEnd"
                active={activeTab === "bodyEnd"}
                onClick={() => this.switchTab("bodyEnd")}
              >
                &lt;/Body&gt;
              </Tab>
            </Tabs>

            <div style={{ borderTop: `1px solid #CCC`, padding: 30 }}>
              {activeTab === "head" ? (
                <div>
                  <div style={{ textAlign: "center" }}>
                    Enter any scripts you want attached to each page&#39;s
                    &lt;HEAD&gt;
                  </div>
                  <CodeMirror
                    value={this.state.head}
                    className="cdmirror"
                    options={{ mode: "javascript", lineNumbers: true }}
                    onChange={(editor, data, value) => {
                      console.log(editor, value)
                      this.handleChange(editor, "head", value)
                    }}
                    autoFocus
                  />
                </div>
              ) : null}

              {activeTab === "body" ? (
                <div>
                  <div style={{ textAlign: "center" }}>
                    Enter any scripts you want attached to each page&#39;s
                    &lt;BODY&gt;
                  </div>
                  <CodeMirror
                    value={this.state.body}
                    className="cdmirror"
                    options={{ mode: "javascript", lineNumbers: true }}
                    onChange={(editor, data, value) =>
                      this.handleChange(editor, "body", value)
                    }
                  />
                </div>
              ) : null}

              {activeTab === "bodyEnd" ? (
                <div>
                  <div style={{ textAlign: "center" }}>
                    Enter any scripts you want attached to each page&#39;s
                    &lt;/BODY&gt;
                  </div>
                  <CodeMirror
                    value={this.state.bodyEnd}
                    className="cdmirror"
                    options={{ mode: "javascript", lineNumbers: true }}
                    onChange={(editor, data, value) =>
                      this.handleChange(editor, "/body", value)
                    }
                  />
                </div>
              ) : null}

              <div style={{ padding: 20, textAlign: "center" }}>
                <Button primary large onClick={this.submitTracking}>
                  Save Settings
                </Button>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    );
  }
}
