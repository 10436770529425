import theme from "@launchos/components/ui/theme";
export const style = {
  main: {
    borderRadius: 3,
    margin: "25px 0",
    paddingTop: 7,
    border: "1px solid #CCC",
    backgroundColor: "white",
    textAlign: "center"
  },
  item: {
    width: 146,
    padding: 20,
    borderRadius: 4,
    cursor: "pointer",
    textAlign: "center",
    ":hover": {
      border: `2px solid ${theme.primaryColor}`
    }
  }
};
