import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.FIVE,
  layout: BenefitLayouts.FIVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkwzmhha20mu0780yzhnmh2r",
    itemId: "qmf2va7vic",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 900,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((6 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: "Highlighted Benefit",
                  },
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      ...style.LIST,
                    },
                    children: [
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "Lorem ipsum dolor",
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "Sit apet consectetur",
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "40 Adipiscing elit",
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "25 Sed do eiusmod",
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((6 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: "Primary Advantage",
                  },
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      ...style.LIST,
                    },
                    children: [
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "Lorem ipsum dolor",
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "Sit apet consectetur",
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "40 Adipiscing elit",
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: {
                          ...style.LI.style,
                        },
                        icon: IconTypes.CheckCircleOutline,
                        iconStyle: style.LI.iconStyle,
                        html: "25 Sed do eiusmod",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
