import _ from 'lodash';
import React from "react";
import { Route } from "react-router-dom";
import AppDrawer from "@launchos/modules/appdrawer";
import { Toolbar } from "@launchos/components/ui";
import { leftActionsGroup, rightActionsGroup } from "@launchos/modules/dashboard/ToolbarComponents";

export default class Page extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      appDrawerVisible: false
    };

    this.toggleAppDrawer = this.toggleAppDrawer.bind(this);
  }

  toggleAppDrawer() {
    this.setState(prevState => ({
      appDrawerVisible: !prevState.appDrawerVisible
    }));
  }

  render() {
    return (
      <Route exact path={this.props.path} render={urlData => (
        <div>
          <AppDrawer
            hidden={!this.state.appDrawerVisible}
            toggleAppDrawer={this.toggleAppDrawer}
          />
          <Toolbar
            leftActionsGroup={_.get(this.props, 'toolbarOptions.leftActionsGroup', leftActionsGroup)}
            rightActionsGroup={_.get(this.props, 'toolbarOptions.rightActionsGroup', rightActionsGroup)}
            toggleAppDrawer={this.toggleAppDrawer}
            theme={_.get(this.props, 'toolbarOptions.theme', 'dark')}
            style={_.get(this.props, 'toolbarOptions.style', {})}
            ToolbarContent={_.get(this.props, 'ToolbarContent')}
          />
          {this.props.children}
        </div>
      )}
      />
    )
  }
}