import React, { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import Helmet from "react-helmet";
import favicon from '@launchos/10mf/src/favicon.png';

export const AppConfig = React.createContext();

export default props => {
  return (
    <AppConfig.Provider value={{ ...props }}>
      <Helmet>
        <title>{props.app.name}</title>
        {props.app.name === "10 Minute Funnels" && <link rel="icon" type="image/png" href="https://sandcastleassets.s3.amazonaws.com/images/favicon.png" />}
      </Helmet>
      <BrowserRouter>
        <Fragment>{props.children}</Fragment>
      </BrowserRouter>
    </AppConfig.Provider>
  );
};
