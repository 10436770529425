import _ from "lodash";
import React from "react";
import { Cell, FontIcon, Grid } from "@launchos/components/ui";
import styled, { css } from "styled-components";

const styles = {
  fancySelector: {
    fontFamily: "sans-serif",
    textAlign: "center",
    margin: "0 auto",
    display: "flex",
    width: 250,
  },
  iconStyle: {},
  fontIcon: {
    border: "1px solid #CCC",
    padding: 10,
    width: 50, height: 50,
    backgroundColor: "white",
  },
  caption: { fontSize: 11, marginTop: 10 },
};

const IconWrapper = styled.div`
  display: inline-block;
  border: 1px solid transparent;
  cursor: pointer;
  width: 80px;
  padding: 15px;
  background-color: ${({ highlighted }) => highlighted ? `#ddd` : 'inherit'}
  &:hover {
    ${(props) =>
    props.highlighted
      ? css`
            background-color: #ddd;
          `
      : css`
            background-color: #eee;
          `}
    border: 1px solid #ddd;
  }
  &:active {
    background-color: #ccc;
  }
`;

let Icon = ({ iconStyle = {}, onSelect, highlighted, caption, icon }) => (
  <IconWrapper onClick={onSelect} highlighted={highlighted}>
    <FontIcon style={{ ...styles.fontIcon, ...iconStyle }}>{icon}</FontIcon>
    {caption ? (
      <div style={styles.caption}>{caption.toUpperCase()}</div>
    ) : null}
  </IconWrapper>
);

Icon.defaultProps = {
  icon: "not_interested",
  highlighted: false,
  caption: false,
};

const FancySelector = (props) => (
  <Grid style={{ ...styles.fancySelector, ...props.style }} wrap>
    {props.items.map((itm) => {
      const Component = itm.component;
      return (
        <Cell key={itm.id}>
          <Component {...props} onSelect={() => props.onChange(itm.id)} />
          {_.has(itm, "label") ? (
            <div style={{ fontSize: 12 }}>{itm.label}</div>
          ) : null}
        </Cell>
      );
    })}
  </Grid>
);

FancySelector.defaultProps = {
  items: [],
};

export { Icon };
export default FancySelector;
