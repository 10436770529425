import * as React from "react";

import { ValidationCriteriaProps } from "../types";

/**
 * Provides a mechanism for setting up a list of validation conditions to enforce for the incoming form field • Returns a Yup ready schema object for use as in a global validation situation
 */
const ValidationCriteria: React.FC<ValidationCriteriaProps> = ({
  children,
}) => {
  /**
   * Converts the user specified validation data (a list sortable data object) to a Yup ready validation schema
   * @params
   */
  const handleYupConversion = (): void => {};

  return (
    <div data-testid="FormBuilder-Attributes-ValidationCriteria">
      {children}
    </div>
  );
};

export default ValidationCriteria;
