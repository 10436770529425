import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",

    mobile: {
      desktop: {
        padding: 80,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    fontSize: "26pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    marginTop: 10,
  },
  LIST: { marginTop: 40, paddingLeft: 0 },
  LI: {
    style: { marginTop: 40 },
    iconStyle: {
      backgroundColor: theme.foregroundColor,
      color: theme.backgroundColor,
      fontSize: "36pt",
      paddingRight: 15,
    },
  },
};
