import gql from 'graphql-tag';

export const getStatsGql:unknown = gql`
  query getStats($id: String!, $startDate: DateTime, $endDate: DateTime) {
    stats(where: { id: $id, startDate: $startDate, endDate: $endDate, type: PAGE }) {
      visitors
      people
      statusCode
    }
  }
`