import React from "react";
import {
  Background,
  Section,
  Padding,
  BordersShadow,
  PropertiesWindow,
} from "@launchos/modules/editor/Builder/Properties";

const BasicPropertiesTab = (props) => (
  <div style={{ width: "100%" }} onClick={(e) => e.stopPropagation()}>
    <Background
      {...props}
      accept="image/jpeg, image/png, video/mp4, video/ogg"
    />
    <Section label="Padding" icon="import_export" isExpanded>
      <Padding {...props} />
    </Section>
    <BordersShadow {...props} hideBorderRadius hideShadow />
  </div>
);

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Column", component: BasicPropertiesTab }]}
  />
);
