import * as React from 'react';
import { head } from 'lodash';

import { LogicBuilderProps } from './types';
import { SelectorTypes, getType } from "./LoadSelector/types";
import LoadSelector from "./LoadSelector";

export const LogicBuilder: React.FC<LogicBuilderProps> = ({ data = [], value, onChange = () => null, styles = { valueSelector: {} } }) => {

  return (
    <div data-testid="LogicBuilder">
      <LoadSelector
        type={SelectorTypes.INDEX}
        values={value}
        allFields={data}
        data={value}
        styles={styles}
        onChange={onChange}
      />
    </div>
  )
}

export default LogicBuilder;