import * as React from "react";
import { get } from "lodash";
import LiveFormField from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

import { generateFormComponentStyle } from "./script";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import Properties from "./Properties";

/**
 * The version of the <LiveFormField /> component to show in the editor canvas
 * - Shows the "live/preview" version of the form input, wrapped in an editor object
 */
export const FormField: React.FC<PluginProps> = (props) => {
  const { settings } = props;
  const properties = convertProperties(settings.properties);
  const propsWithoutHeight = {
    ...settings.properties,
    height: 'inherit'
  }

  return (
    <V1ObjectWrapper settings={{
      ...settings,
      properties: propsWithoutHeight
    }} showOverlay>
      <EditorObject
        label={`${settings.fieldType} Field`}
        {...props}
        settings={{
          ...settings,
          properties: propsWithoutHeight
        }}
        PropertiesView={Properties}
      // debug
      >
        <LiveFormField
          {...settings}
          {...get(settings, "attributes", {})}
          styles={{
            ...generateFormComponentStyle(properties),
            ...get(settings, "styles", {}),
          }}
          type={settings.fieldType}
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default FormField;
