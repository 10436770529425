export enum SmartConditionTypes {
  DATE,
  URL,
  FORM,
  TRAFFIC,
  GEOTARGET,
  PLATFORM,
  TAG,
}

export enum SmartConditionOperations {
  ISAFTER,
}
export enum SmartConditionActions {
  SWITCHCONTAINER,
}
