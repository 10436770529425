import { Button, Tab, Tabs, Menu, ListItem } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import style from "@launchos/modules/campaign/shared/style";

import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

import { createPopupWindow, setNewPopup } from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/Payloads/PopupOptions";

const NiceMenu = (props) => {
  const style = {
    selectField: {
      padding: 10,
      color: "#666",
      border: "1px solid #DDD",
      borderRadius: 3,
    },
  };
  return (
    <div style={{ ...style.selectField, cursor: "pointer", ...props.style }}>
      <Menu {...props} style={{ width: "100%", textAlign: "left" }}>
        <div style={{ maxHeight: 200, overflowY: "scroll" }}>
          {props.children}
        </div>
      </Menu>
    </div>
  );
};

export default class ExitIntent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allPopups: [],
      exitPopup: '{id:"false"}',
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitExitSettings = this.submitExitSettings.bind(this);
    this.switchTab = this.switchTab.bind(this);
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { getPageQuery, match, listComponents } = this.props;

    const pageId = match.params.nodeId;

    const { data } = await getPageQuery.refetch({ pageId });

    const { pageSettings } = data.page;

    try {
      this.setState({
        exitPopup: JSON.parse(_.get(pageSettings, "exitPopup", '{id:"false"}')),
        loading: false,
        // allPopups,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  UNSAFE_componentWillUpdate(newProps) {
    if (this.props.pageContent.length !== newProps.pageContent.length) {
      const newPopup = setNewPopup(this.props, newProps, (payload) => this.setState(payload));
      if (_.has(newPopup, 'settings.id')) {
        this.setState({
          exitPopup: { id: newPopup.settings.id }
        })
      }
      // window.location.href = window.location.href;
    }
  }

  handleChange(key, value) {
    this.setState({
      [key]: value,
    });
  }

  async submitExitSettings() {
    const { updatePageSettings, getPageQuery, history, match } = this.props;
    const { exitPopup } = this.state;

    const { pageSettings, id } = getPageQuery.page;

    const variables = {
      pageId: id,
      pageSettings: {
        ...pageSettings,
        exitPopup: JSON.stringify(exitPopup),
      },
    };

    console.log({ variables });
    // alert(JSON.stringify(variables));

    await updatePageSettings({ variables });

    history.push(
      `/campaign/${match.params.campaignId}/${match.params.nodeId}/builder`
    );
  }

  switchTab(activeTab) {
    console.log({ activeTab });
    this.setState({ activeTab });
  }

  render() {
    const { app } = this.context;
    const { loading } = this.state;
    const { listComponents, pageContent } = this.props;

    const allPopups = listComponents(pageContent, null, [["type", "Popup"]]);

    if (loading) return <img alt="" src={app.loadingImage} />;

    return (
      <div
        style={{
          overflowY: "scroll",
          textAlign: "left",
          maxWidth: 700,
          height: "100%",
          minHeight: 500,
          margin: "0 auto",
          padding: 20,
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: 50 }}>
          Exit Intent Settings
          {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Exit Intent' })}
        </h1>

        <h2 style={{ textAlign: "center", marginBottom: 50 }}>
          Exit intent popups allow you to show a popup window to visitors who
          are about to leave your web page.
        </h2>

        <div style={{ padding: 0 }}>
          <div>
            <div style={{ padding: 10 }}>
              <NiceMenu
                label={`Which Popup to Show: ${this.state.exitPopup
                  ? _.get(
                    allPopups[allPopups.findIndex((itm) => itm.id === _.get(this.state, "exitPopup.id", false))],
                    "html",
                    "(Don't Show Anything)"
                  )
                  : "(Don't Show Anything)"
                  }`}
              >
                <ListItem
                  key={0}
                  leftIcon="block"
                  onClick={() => this.setState({ exitPopup: { id: false } })}
                >
                  Don't Show Anything
                </ListItem>
                <div style={style.divider} />
                {_.map(allPopups, (itm) => (
                  <ListItem
                    key={itm.settings.id}
                    onClick={() =>
                      this.setState({ exitPopup: { id: itm.settings.id } })
                    }
                    leftIcon={_.get(itm, "icon", false)}
                  >
                    {_.get(itm, "html", "")}
                  </ListItem>
                ))}
                {allPopups.length ? <div style={style.divider} /> : null}
                <ListItem leftIcon="open_in_new" onClick={() => createPopupWindow(this.props, true)}>
                  Create a new Popup Window
                </ListItem>
              </NiceMenu>
            </div>
          </div>

          <div style={{ marginTop: 20, padding: 20, textAlign: "center" }}>
            <Button primary large onClick={this.submitExitSettings}>
              Save Settings
            </Button>
          </div>

        </div>
      </div>
    );
  }
}
