import { orderBy, head } from "lodash";
import React from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";

import { style } from "./style";

const PageSelector: React.FC<{
  campaignId: string;
  nodeId: string;
  pages: any[];
}> = ({ campaignId, nodeId, pages = [] }) => (
  <Select
    style={style.selectField}
    variant="outlined"
    value={nodeId}
    onChange={({ target: { value } }) => {
      const node = head(pages.filter(itm => itm.page.id === value));
      const url = (node.page.content.length === 1) ? `/campaign/${campaignId}/${value}/templates/${node.page.type}` : `/campaign/${campaignId}/${value}/builder`;
      window.location.href = url;
    }}
  >
    {orderBy(pages, "x")
      .filter((itm) => !itm.deleted)
      .map((itm, key) => (
        <MenuItem key={key} value={itm.page.id}>
          <div style={style.menuItem}>
            <span style={style.menuItemLabel}>Page: {itm.name.length > 20 ? `${itm.name.substring(0, 18)}...` : itm.name}</span>
            <div style={style.slugField}>/{itm.page.slug}</div>
          </div>
        </MenuItem>
      ))}
  </Select>
);

export const PageSelectorMenu: React.FC<{ match: any; pages: any[] }> = ({
  match = {},
  pages = [],
}) => {
  if (!pages.length) return null;

  const {
    params: { nodeId, campaignId },
  } = match;

  return <PageSelector nodeId={nodeId} campaignId={campaignId} pages={pages} />;
};
