import * as React from "react";
import { get } from "lodash";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { PluginProps } from "./types";
import { Navigation } from "./live";
import { Links } from "./Links";
import Properties from "./Properties";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

/**
 * Ability to create bulleted list
 */
export const EditorList: React.FC<PluginProps> = (props) => {
  const { id, mode, settings } = props;
  const properties = convertProperties(settings.properties);
  const { data, layoutStyle } = settings;

  return (
    <V1ObjectWrapper settings={settings} showOverlay={true}>
      <EditorObject
        label="Navigation"
        id={id}
        {...props}
        PropertiesView={Properties}
      // dropDownItems={items => {
      //   const key = items.findIndex(({ id }) => id === "PROPERTIES");
      //   return [
      //     ...items.slice(0, key),
      //     { id: "ADD", text: "Add Another Link", icon: "add_circle" },
      //     ...items.slice(key),
      //   ]
      // }}
      // onDropDownChange={id => {
      //   if (id === "ADD") {
      //     alert("Adding a New Link")
      //   }
      // }}
      >
        <Links
          items={data.map((itm) => ({ ...itm, type: itm.linkDisplayType }))}
          linkStyle={{
            ...get(settings, "linkStyle", {}),
            fontSize: get(properties, 'fontSize', 'inherit'),
            fontFamily: get(properties, 'fontFamily', 'inherit'),
            color: get(properties, 'color', 'inherit'),
          }}
          layoutStyle={layoutStyle}
          mode={EditorMode.EDITOR}
          style={properties}
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorList;
