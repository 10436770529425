import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CTALayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.FOURTEEN,
  layout: CTALayouts.FOURTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA14.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "68fk3xzw02v",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 750,
          width: "100%",
          display: "inline-block",
          textAlign: "center",
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "pre_headline.style", {}),
            },
            html: get(content, "pre_headline.html", "..."),
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              textAlign: "center",
              margin: "0 auto",
              mobile: {
                desktop: {
                  marginTop: 20,
                  width: 500,
                },
                smartphone: {
                  marginTop: 0,
                  width: "100%",
                },
              },
              display: "inline-block",
            },
            children: [
              {
                type: ComponentTypes.COLUMNS,
                canHaveChildren: false,
                properties: {
                  width: "100%",
                },
                children: [
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((8 * 100) / 12),
                    properties: { height: "100%", padding: 3, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.FORMFIELD,
                        canHaveChildren: false,
                        styles: {
                          containerStyle: style.FORM_FIELD.style,
                          inputStyle: style.FORM_FIELD.inputStyle,
                        },
                        placeholder: "Enter Your Email",
                        name: "email",
                        fieldType: "Text",
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((4 * 100) / 12),
                    properties: { height: "100%", padding: 3, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.BUTTON,
                        canHaveChildren: false,
                        properties: style.BUTTON.style,
                        caption1: "Join Now",
                        caption1style: style.BUTTON.caption1,
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                  fontSize: "9pt",
                  marginTop: 20,
                },
                html: get(content, "privacy_text.html", "..."),
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
