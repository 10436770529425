import { client } from "@launchos/api/gql/client";
import { verifyUser } from "@launchos/api/gql/users";
import { head, get } from "lodash";
import md5 from "md5";

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
  }

  login(username, password, props, callback) {
    // check db first
    // console.log(`http://${process.env.REACT_APP_SERVER_ENDPOINT}:4000`)
    // console.log({ username, password })
    client
      .query({
        query: verifyUser,
        variables: {
          email: username,
          password: md5(password)
        }
      })
      .then(result => {
        const { data } = result;
        const user = head(data.users);
        const { settings: { app } } = props;
        console.log({ user, app })
        // alert(app.id)
        if (user && user.deleted !== true && user.permissions.indexOf(app.id) > -1) {
          localStorage.setItem("userId", user.id);
          sessionStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("email", user.email);
          const appId = get(head(user.apps.filter(itm => itm.name === app.id)), 'id', false);
          // alert(JSON.stringify({ appId, v2: app.id }))
          localStorage.setItem("appId", appId)

          callback({ result: "sucess", message: user });
        } else {
          callback({
            result: "error",
            message: { description: "We could not find this user" }
          });
        }
      });
  }
}
