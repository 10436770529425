import * as React from "react";
import LivePage from "./Page";

import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "@launchos/site/src/reducers";
import { PageProps } from "./types";

const Preview: React.FC<PageProps> = ({
  nextPage = "",
  seoPayload = {},
  merchantPayload = {},
  trackingPayload = {},
  emailPayload = {},
  cssPayload = "",
  exitPayload = {},
  id,
  content = [],
}) => {
  return (
    <Provider store={createStore(reducers)}>
      <Router>
        <Switch>
          <LivePage
            content={JSON.stringify(content)}
            nextPage={nextPage}
            seoPayload={seoPayload}
            merchantPayload={merchantPayload}
            trackingPayload={trackingPayload}
            emailPayload={emailPayload}
            cssPayload={cssPayload}
            exitPayload={exitPayload}
            id={id}
            show="preview"
          />
        </Switch>
      </Router>
    </Provider>
  );
};

export default Preview;
