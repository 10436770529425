import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import TrafficDrawer from "./TrackingDrawer";
// import TrafficPopup from "./TrackingSettings";
import objects from "./objects";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.CANVAS_ITEM_DRAWER_TABS) {
    return [
      {
        id: "TRAFFIC",
        icon: "gps_fixed",
        caption: "Tracking",
        component: TrafficDrawer
      }
    ];
  }

  if (hook.id === ListItemsHooks.TRAFFIC_SOURCE_OBJECTS) {
    return objects;
  }
};

export const onNodeCreate = async (hook, { item }, { createPage }) => {
  if (hook.type === "TrackingSource") {
    const { name, screenshot } = item;

    const pageScript = `
      <script type="text/javascript">
        window.location.href = "/<%=nextPage%>";
      </script>
    `;

    const response = await createPage({
      name,
      slug: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 6),
      screenshot,
      theme: "none",
      type: "redirect",
      content: [
        {
          id: 2540,
          parent: false,
          properties: { height: "100%" },
          type: "Body",
          canHaveChildren: true
        }
      ],
      userId: localStorage.userId,
      pageSettings: {
        tracking: {
          head: pageScript
        }
      }
    });

    console.log({ response });

    return response;
  }
};

export const onNodeOpen = async (
  hook,
  { campaignId, page },
  { navigate, showPopup }
) => {
  if (hook.type === "TrackingSource") {
    console.log("testing node open!!");
    // showPopup(TrafficPopup);
  }
};

export const onNodeSelect = async (
  hook,
  { campaignId, page },
  { navigate }
) => {
  if (hook.type === "TrackingSource") {
    console.log("testing node select!!");
  }
};
