import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { GuaranteeLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: GuaranteeLayouts.ONE,
  layout: GuaranteeLayouts.ONE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/guarantees/Guarantee01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzt2al72l5707802d671a78",
    itemId: "u5j1r64m5rf",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.GUARANTEE,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
      width: 500,
      backgroundColor: theme.backgroundColor,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: { backgroundColor: theme.foregroundColor, padding: 50 },
        children: [
          {
            type: ComponentTypes.PICTURE,
            canHaveChildren: false,
            properties: {
              display: "inline-block",
              width: 220,
              margin: "0 auto",
              textAlign: "center",
            },
            src: "http://tenminutepages.com/images/guarantee_pic.png",
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: { ...style.HEADLINE, marginTop: 10 },
            html: "100% Money Back Guarantee",
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
            },
            html: startCase(lorem.generateWords(7)),
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
              textAlign: "left",
            },
            html: `${lorem.generateParagraphs(
              1
            )}<br/><br/>${lorem.generateParagraphs(1)}`,
          },
        ],
      },
    ],
  },
};

export default settings;
