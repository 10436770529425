import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { Grid } from "@material-ui/core";

import { useDimensions } from "@launchos/plugins/utilities";

import { TextFieldProps } from "../types";
import { EditorMode } from "../../../../Editor/types";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";
import { LiveFormFieldProps, SProps } from "../../LiveFormField/types";

import { AttributeContainer } from "@launchos/modules/v2/CRUD/AttributeBuilder";
import { FormFieldTypes } from "../../types";
import { LiveTextField } from "./LiveTextField";

export const Inpt = styled.input<SProps>`
  &::placeholder {
    color: ${(p) => get(p.inputStyle, "color", "inherit")};
  }
  box-sizing: border-box;
`;

const TextField: React.FC<TextFieldProps> = (props) => {
  // const [attributes, setAttributes] = React.useState<LiveFormFieldProps>(props);
  const [ref, iconDimensions] = useDimensions();
  const [value, setValue] = React.useState(props.defaultValue || "")

  const {
    mode = EditorMode.LIVE,
    placeholder,
    disabled,
    id,
    styles = {
      labelStyle: {},
      descriptionStyle: {},
      inputStyle: {},
      containerStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    type,
    name,
    label,
    description,
    defaultValue,
    icon,
    columns = 12,
    isExpanded = false,
    onChange = () => null,
    onBlur = () => null,
    attributeContainerDataSettings = {},
  } = props;
  /**
   * • Deals with any changes that comes from the various attributes that are loaded
   * • Converts to LiveFormFieldProps shape
   * • Triggers (and passes along the mutated LiveFormFieldProps payload to) the onChange event
   * @params
   */
  const handleChange = (data: LiveFormFieldProps): void => {
    onChange(data);
  };

  const {
    iconStyle = {},
    descriptionStyle = {},
    containerStyle = {},
    inputStyle = {},
    validationStyle = {},
    labelStyle = {},
  } = styles;

  let formFieldType = "text";
  if (type === FormFieldTypes.PASSWORD) formFieldType = "password";

  const inputPadding = get(inputStyle, 'padding', 'inherit')

  return (
    <div data-testid="FormBuilder-FormFields-TextField" style={containerStyle}>
      {/* {JSON.stringify(props)} */}
      {mode === EditorMode.LIVE && (
        <LiveTextField
          ref={ref}
          iconDimensions={iconDimensions}
          value={value}
          setValue={setValue}
          placeholder={placeholder}
          disabled={disabled}
          id={id} name={name}
          label={label}
          description={description}
          icon={icon}
          handleChange={handleChange}
          formFieldType={formFieldType}
          inputPadding={inputPadding}
          labelStyle={labelStyle}
          descriptionStyle={descriptionStyle}
          iconStyle={iconStyle}
          inputStyle={inputStyle}
          onBlur={onBlur}
        />
      )}
      {mode === EditorMode.EDITOR && (
        <div data-testid="FormBuilder-FormFields-TextField-EDITOR" style={{ ...inputStyle, zIndex: 0, position: 'relative' }}>
          <AttributeContainer
            onChange={handleChange}
            onBlur={onBlur}
            attributes={props}
            data={[
              {
                id: "label",
                attribute: AttributeNames.LABEL,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "placeholder",
                attribute: AttributeNames.PLACEHOLDER,
                settings: {
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "name",
                attribute: AttributeNames.FORMITEMNAME,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "description",
                attribute: AttributeNames.DESCRIPTION,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "columns",
                attribute: AttributeNames.COLUMNS,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "required",
                attribute: AttributeNames.REQUIRED,
                settings: {},
              },
              {
                id: "initialvalue",
                attribute: AttributeNames.INITIALVALUE,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "iconSelector",
                attribute: AttributeNames.ICON,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default TextField;
