import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.EIGHT,
  layout: FormLayouts.EIGHT,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form08.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyu67qy2e6c07805fbyk6cj",
    itemId: "194cbe7zudk",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: "Feedback Request Form",
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "main_headline.style", {}),
        },
        html:
          "What's on your mind?  Please fill out the form below to leave your feedback.",
      },
      {
        type: ComponentTypes.FORM,
        data: [
          {
            type: FormFieldTypes.DROPDOWN,
            name: "subject",
            label: "What type of feedback do you have?",
            data: [
              { id: "id", name: "name", value: "Test" },
              { id: "id", name: "name", value: "Test" },
              { id: "id", name: "name", value: "Test" },
              { id: "id", name: "name", value: "Test" },
            ],
          },
          {
            type: FormFieldTypes.TEXT,
            name: "company",
            placeholder: "Your Company's Name",
            preset: FieldNamePresets.COMPANY,
            columns: 6,
          },
          {
            type: FormFieldTypes.TEXT,
            name: "name",
            placeholder: "Your Name",
            preset: FieldNamePresets.NAME,
            columns: 6,
          },
          {
            type: FormFieldTypes.TEXT,
            name: "email",
            placeholder: "Your Email",
            preset: FieldNamePresets.EMAIL,
            columns: 6,
          },
          {
            type: FormFieldTypes.TEXT,
            name: "phone",
            placeholder: "Phone number",
            preset: FieldNamePresets.PHONE,
            columns: 6,
          },
        ],
        properties: {
          containerStyle: style.FORM_FIELD.style,
          inputStyle: style.FORM_FIELD.inputStyle,
          labelStyle: style.FORM_FIELD.labelStyle,
        },
      },
      {
        type: ComponentTypes.FORMFIELD,
        canHaveChildren: false,
        attributes: {
          placeholder: "Terms",
          name: "Terms",
          items: [
            {
              id: 0,
              label:
                "It's true.  Getting results is much easier than you think.  The truth is most people simply don't know the correct steps to take so they become frustrated and quit",
              value: false,
            },
          ],
        },
        fieldType: FormFieldTypes.CHECKBOXES,
        styles: {
          containerStyle: { ...style.FORM_FIELD.style, marginTop: 20 },
          // inputStyle: style.FORM_FIELD.inputStyle,
          labelStyle: style.FORM_FIELD.labelStyle,
        },
      },
    ],
  },
};

export default settings;
