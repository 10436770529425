import {
  get,
  head,
  flow,
  countBy,
  entries,
  maxBy,
  partialRight,
  last,
} from "lodash";
import gql from "graphql-tag";
import { client } from "@launchos/api/gql/client";

import { PageSectionCategory } from "../../blocks/weblayouts/types";

export const checkForSmartCopyIntegration = async () => {
  // check for smart copy integration
  const { data } = await client.query({
    query: gql`
      query GetSmartCopyProfile($userId: ID!) {
        integrations(where: { user: { id: $userId }, type: "smart_copy" }) {
          id
        }
      }
    `,
    variables: {
      userId: localStorage.userId,
    },
  });

  // console.log('Checking for Smart Copy Integration', { data });

  return Boolean(get(data, "integrations", []).length);
};

export const HTMLPartToTextPart = (HTMLPart) =>
  HTMLPart.replace(/\n/gi, "")
    .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, "")
    .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, "")
    .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, "")
    .replace(/<\/\s*(?:p|div)>/gi, "\n")
    .replace(/<br[^>]*\/?>/gi, "\n")
    .replace(/<[^>]*>/gi, "")
    .replace("&nbsp;", " ")
    .replace(/[^\S\r\n][^\S\r\n]+/gi, " ");

const listAncestors = (content, id) => {
  if (!id) return;
  let ancestors = [];
  const generateAncestors = (providedContent = [], id) => {
    const i = providedContent.findIndex((c) => c.id === id);
    if (i > -1) {
      const parent = providedContent[i].parent;
      ancestors = [...ancestors, providedContent[i]];
      if (parent) generateAncestors(providedContent, parent);
      return ancestors;
    }
  };

  return generateAncestors(content, id);
};

export const getNearestSection = (content, id) => {
  const res = listAncestors(content, id);
  // console.log(res);
  return head(res.filter((itm) => itm.type === "Section"));
};

export const getCopyBlocksFromPage = (content = [], page_type) => {
  const justCopyBlocks = content.filter(
    (itm) =>
      itm.type === "Headline" || itm.type === "Text" || itm.type === "Paragraph"
  );

  return justCopyBlocks.map(({ id, type, html }) => ({
    id,
    type,
    html,
    content: HTMLPartToTextPart(html),
    page_type,
    section_type: get(getNearestSection(content, id), "label"),
  }));
};

const doGPT = async (prompt) => {
  return await client.query({
    query: gql`
      query getGPTCompletion($prompt: String!, $type: String) {
        getGPTCompletion(prompt: $prompt, type: $type) {
          statusCode
          data
        }
      }
    `,
    variables: {
      prompt,
      // type: "Headline",
    },
  });
};

export const generateGPTRecipesFromPageContent = (pageContent, page_type) => {
  const resolveElementType = (type, content) => {
    switch (type.toLowerCase()) {
      case "text":
        return content.split(".").length > 2 ? "paragraph" : "statement";
      case "paragraph":
        return content.split(".").length > 2 ? "paragraph" : "statement";
      case "headline":
        return "headline";
      default:
        return type;
    }
  };

  const resolveSection = (type) => {
    switch (type) {
      case PageSectionCategory.HEADER:
        return "Header section";
      case PageSectionCategory.BENEFIT:
        return "Benefit based section";
      case PageSectionCategory.CTA:
        return "Call to action focused section";
      case PageSectionCategory.CONTENT:
        return "Information focused content section";
      case PageSectionCategory.PRICING:
        return "Pricing & payment related section";
      case PageSectionCategory.SOCIALPROOF:
        return "Testimonial or Case Study based section";
      case PageSectionCategory.FORM:
        return "Form or lead capture section";
      case PageSectionCategory.NAVIGATION:
        return "Navigation section";
      case PageSectionCategory.FOOTER:
        return "Footer section";
      case PageSectionCategory.GUARANTEE:
        return "Guarantee and risk removal based section";
      case PageSectionCategory.QUIZ:
        return "Quiz related section";
      case PageSectionCategory.URGENCY:
        return "Urgent, deadline focused section";
      default:
        return type + " section";
    }
  };

  const resolvePageType = (type) => {
    switch (type.toLowerCase()) {
      case "lead":
        return "web page focused on capturing leads";
      case "webinar":
        return "web page focused on capturing leads for an upcoming webinar";
      case "sales":
        return "web page focused on selling my product or service";
      case "home":
        return "home page focused on introducing my product or service";
      case "vsl":
        return "video based web page focused on selling my product or service";
      case "event":
        return "event based web page";
      case "launch":
        return "web page launching my product or service";
      case "content":
        return "content based web page";
      case "oto":
        return "web page that offers another product in the form an upsell";
      case "order":
        return "website checkout page";
      case "checkout":
        return "website checkout page";
      case "thank you":
        return "thank you page";
      default:
        return "web page";
    }
  };

  const copyBlocks = getCopyBlocksFromPage(pageContent, page_type);

  return copyBlocks
    .filter(({ content }) => content.split(" ").length > 3) // ignore blocks with just a couple words in it
    .map(({ id, content, html, section_type, page_type, type }) => ({
      id,
      content,
      html,
      prompt: `Write a ${content.split(" ").length} word ${resolveElementType(
        type,
        content
      )} for a ${resolveSection(section_type)} on a ${resolvePageType(
        page_type
      )}.`,
    }));
};

const getContext = async (old_text) => {
  const { data } = await client.query({
    query: gql`
      query GetSmartCopyProfile($userId: ID!) {
        integrations(where: { user: { id: $userId }, type: "smart_copy" }) {
          id
          type
          setting
        }
      }
    `,
    variables: {
      userId: localStorage.userId,
    },
  });

  console.log({ data });

  if (get(data, "integrations", []).length) {
    const { setting } = head(data.integrations);
    const toRet = `
  Company Name: ${setting.company}
  Product Name: ${setting.product_name}
  Product Description: ${setting.product_description}
  Old Text: ${old_text}
  
`;
    console.log({ context: toRet });

    return toRet;
  } else {
    return `
Old Text: ${old_text}

`;
  }
};

const applyHTMLStyling = (html, response, type = "Text") => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;

  let styles = {
    fontFamily: [],
    color: [],
    fontSize: [],
  };

  tmp.querySelectorAll("*").forEach((itm) => {
    styles.fontFamily.push(itm.style.fontFamily);
    styles.color.push(itm.style.color);
    styles.fontSize.push(itm.style.fontSize);
  });

  let fontWeight = tmp.querySelectorAll("strong").length ? "bold" : "normal";
  if (type === "Text" || type === "Paragraph") fontWeight = "normal";

  const fontFamily = flow(
    countBy,
    entries,
    partialRight(maxBy, last),
    head
  )(styles.fontFamily.filter((itm) => itm !== ""));

  const color = flow(
    countBy,
    entries,
    partialRight(maxBy, last),
    head
  )(styles.color.filter((itm) => itm !== ""));

  const fontSize = flow(
    countBy,
    entries,
    partialRight(maxBy, last),
    head
  )(styles.fontSize.filter((itm) => itm !== ""));

  const toReturn = `<p><span style="${
    fontFamily ? `font-family:${fontFamily.replaceAll('"', "")};` : ""
  }${color ? `color:${color};` : ""}${
    fontSize ? `font-size:${fontSize.replaceAll('"', "")};` : ""
  }${fontWeight ? `font-weight:${fontWeight};` : ""}">${response}</span></p>`;

  console.log({
    //   styles,
    html,
    //   response,
    fontFamily,
    color,
    fontSize,
    toReturn,
  });

  // return response;
  return toReturn;
};

export const parseWithSmartCopy = async (pageContent, page_type) => {
  const arr = generateGPTRecipesFromPageContent(pageContent, page_type);
  // console.log({ arr });

  let updatedContent = pageContent;

  for (let i = 0; i < arr.length; i++) {
    const { id, prompt, content, html } = arr[i];
    const context = getContext(content); // add previously generated content up to this point...
    // console.log({ prompt, html });
    const { data } = await doGPT(`${context}\n${prompt}`);
    const response = data.getGPTCompletion.data[0].text;

    // turn it into html
    //     const res2 = await doGPT(
    //       // `${html}\nUsing the above as an example, replace the content "${content}" to "${response}"`
    //       `Generate some HTML that replaces the inner content of the following html with the words: "${response}"

    // ${html}
    // `
    //     );
    //     const response2 = res2.data.getGPTCompletion.data[0].text;

    // console.log({ html, response2 });
    const key = updatedContent.findIndex((itm) => itm.id === id);

    const response2 = applyHTMLStyling(
      html,
      response,
      updatedContent[key]["type"]
    );

    updatedContent[key] = { ...updatedContent[key], html: response2 };
    // console.log(updatedContent[key]);
  }

  return updatedContent;
};
