import _ from "lodash";
import React, { Component } from "react";
import { Drawer, FontIcon, Tab, Tabs } from "@launchos/components/ui";
import theme from "@launchos/components/ui/theme";

import { LegendObject } from "./LegendObject";

export class BigLegend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "MAIN",
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility(settings) {
    const { updateComponentStyle, getMobileState } = this.props;
    const displaySetting = _.get(
      settings.properties,
      `mobile[${getMobileState().type}].display`,
      _.get(settings, "properties.display", true)
    );
    const objIsVisible = displaySetting !== "none";
    const display = objIsVisible
      ? "none"
      : _.get(settings, "properties.oldDisplay", "block");
    const oldDisplay = objIsVisible
      ? displaySetting
      : _.get(settings, "properties.oldDisplay", "block");
    const newStyle = {
      display,
      oldDisplay,
    };
    updateComponentStyle(settings.id, newStyle, true, false);
  }

  switchTab(activeTab) {
    this.setState({ activeTab });
  }

  render() {
    const {
      listComponents,
      pageContent,
      changeState,
      state,
      updateComponentSettings,
      getMobileState,
    } = this.props;

    const getContent = (obj, key) => {
      const objIsVisible =
        _.get(
          obj.settings.properties,
          `mobile[${getMobileState().type}].display`,
          _.get(obj.settings, "properties.display", true)
        ) !== "none";

      const html =
        _.get(obj, 'html', '').length > 20
          ? `${_.get(obj, 'html', '').substring(0, 20).trim()}...`
          : _.get(obj, 'html', '').substring(0, 20);

      const getColor = (state) => {
        if (state === "active") return theme.secondaryColor;
        if (state === "hover") return theme.primaryColor;
        else return "black";
      };

      return (
        <LegendObject
          style={{ display: "block", width: "100%" }}
          settings={obj.settings}
          changeState={changeState}
          state={state}
          key={key}
          updateComponentSettings={updateComponentSettings}
        >
          <div
            style={{ padding: "10px 15px", fontSize: "11pt" }}
            title="Double-Click to Rename"
          >
            <div
              style={{
                float: "left",
                paddingRight: 10,
                cursor: "pointer",
              }}
            >
              <FontIcon>{obj.icon}</FontIcon>
            </div>
            <div
              style={{
                float: "left",
                padding: 5,
                color: getColor(obj.settings.state),
              }}
            >
              {html}
            </div>
            <div
              onClick={() => this.toggleVisibility(obj.settings)}
              style={{ float: "right" }}
            >
              <FontIcon>
                {objIsVisible ? "visibility" : "visibility_off"}
              </FontIcon>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </LegendObject>
      );
    };

    const isFromIframe = window.location !== window.parent.location;

    return (
      <Drawer
        overlay={false}
        position="LEFT"
        style={{
          left: 85,
          top: 60,
          zIndex: 10,
          ...isFromIframe ? { left: 0, top: 0 } : {},
        }}
        onClose={this.props.toggleBigLegend}
      >
        <div
          style={{
            borderBottom: "1px solid #ccc",
            textAlign: "center",
          }}
        >
          <h2 style={{ padding: "30px 30px 15px", textAlign: "center" }}>
            Page Objects
          </h2>
          <Tabs transparent>
            <Tab
              active={this.state.activeTab === "MAIN"}
              onClick={() => this.switchTab("MAIN")}
            >
              Objects
            </Tab>
            <Tab
              active={this.state.activeTab === "SECTIONS"}
              onClick={() => this.switchTab("SECTIONS")}
            >
              Sections
            </Tab>
          </Tabs>
        </div>
        <div
          style={{
            bottom: 0,
            overflowY: "auto",
            position: "fixed",
            top: 210,
            width: 300,
          }}
        >
          {this.state.activeTab === "MAIN"
            ? _.map(
              listComponents(pageContent, [
                ["canHaveChildren", true],
                ["type", "Columns"],
                ["type", "Tabs"],
              ]),
              (obj, key) => getContent(obj, key)
            )
            : null}
          {this.state.activeTab === "SECTIONS"
            ? _.map(
              listComponents(
                pageContent,
                [
                  ["type", "Body"],
                  ["type", "Column"],
                  ["type", "Tab"],
                ],
                [
                  ["canHaveChildren", true],
                  ["type", "Columns"],
                  ["type", "Tabss"],
                ]
              ),
              (obj, key) => getContent(obj, key)
            )
            : null}
        </div>
      </Drawer>
    );
  }
}
