import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { DividerTypes } from "@launchos/plugins/webcomponents/v2/Divider/types";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.THREE,
  layout: BenefitLayouts.THREE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkwzmhha20mu0780yzhnmh2r",
    itemId: "qypmp8ljnv",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      padding: 50,
                    },
                    smartphone: {
                      padding: 30,
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((2 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.ICON,
                            canHaveChildren: false,
                            properties: style.ICON,
                            iconType: IconTypes.PlayArrow,
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((10 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.HEADLINE,
                            canHaveChildren: false,
                            properties: {
                              ...style.HEADLINE,
                              ...get(content, "main_headline.style", {}),
                            },
                            html: get(content, "main_headline.html", "..."),
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              ...get(content, "sub_headline.style", {}),
                            },
                            html: get(content, "sub_headline.html", "..."),
                          },
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: style.BUTTON.style,
                            caption1: "Get Started",
                            caption1style: style.BUTTON.caption1,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: {
              height: "100%",
              padding: 0,
              minHeight: 20,
              mobile: {
                desktop: {
                  display: "block",
                },
                smartphone: {
                  display: "none",
                },
              },
            },
            children: [
              {
                type: ComponentTypes.DIVIDER,
                canHaveChildren: false,
                properties: {
                  height: 400,
                },
                dividerType: DividerTypes.VERTICAL,
                color: theme.foregroundColor,
                size: 1,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      padding: 50,
                    },
                    smartphone: {
                      padding: 30,
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((2 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.ICON,
                            canHaveChildren: false,
                            properties: style.ICON,
                            iconType: IconTypes.PlayArrow,
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((10 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.HEADLINE,
                            canHaveChildren: false,
                            properties: {
                              ...style.HEADLINE,
                              ...get(content, "main_headline.style", {}),
                            },
                            html: get(content, "main_headline.html", "..."),
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              ...get(content, "sub_headline.style", {}),
                            },
                            html: get(content, "sub_headline.html", "..."),
                          },
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: style.BUTTON.style,
                            caption1: "Get Started",
                            caption1style: style.BUTTON.caption1,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
