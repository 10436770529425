import React from "react";

import { UndoRedo } from "./UndoRedo";
import { PageSelectorMenu } from "./PageSelector";
import { HamburgerMenu } from "./HamburgerMenu";
import { SavingStatus } from "./SavingStatus";
import { PreviewButton } from "./PreviewButton";
import { PublishButton } from "./PublishButton";
import { AvatarMenu } from "./AvatarMenu";

export const leftActionsGroup: React.FC[] = [
  HamburgerMenu,
  PageSelectorMenu,
  UndoRedo,
];

export const rightActionsGroup: React.FC[] = [
  SavingStatus,
  PreviewButton,
  PublishButton,
  AvatarMenu,
];
