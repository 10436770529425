import * as React from "react";

import { ListItem, NiceMenu } from "@launchos/components/ui";

export const CarouselNumberOfSlides: React.FC<{
  numberOfSlides?: number;
  onChange?: (num: number) => void;
}> = ({ onChange, numberOfSlides = 1 }) => {
  return (
    <div style={{ padding: 10 }}>
      <p>You can show more than one slide per page</p>
      <NiceMenu label={`Number of slides: ${numberOfSlides}`}>
        <ListItem onClick={() => onChange(1)}>1</ListItem>
        <ListItem onClick={() => onChange(2)}>2</ListItem>
        <ListItem onClick={() => onChange(3)}>3</ListItem>
        <ListItem onClick={() => onChange(4)}>4</ListItem>
        <ListItem onClick={() => onChange(5)}>5</ListItem>
        <ListItem onClick={() => onChange(6)}>6</ListItem>
        <ListItem onClick={() => onChange(7)}>7</ListItem>
        <ListItem onClick={() => onChange(8)}>8</ListItem>
      </NiceMenu>
    </div>
  );
};
