import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 50,
  },
  HEADLINE: {
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    fontSize: "12pt",
    lineHeight: "18pt",
  },
  NAVIGATION: {
    linkStyle: {
      fontFamily: theme.fontFamily,
      textAlign: "left",
      color: theme.backgroundColor,
      display: "inline-block",
      padding: "0 10px",
    },
  },
};
