import { TextInput } from "@launchos/components/ui";
import _ from "lodash";
import React, { Component } from "react";

export const ShowUserFields = ({ onChange, settings, values = {} }) => {

  if (!settings) return null;
  const fields = JSON.parse(settings);
  if (!fields.length) return null;

  return (
    <div>
      {fields.map(({ id, field_type, placeholder = "" }) => {
        if (field_type === "text") return <TextInput value={_.get(values, id, "")} placeholder={placeholder} onBlur={e => onChange({ [id]: e.target.value })} />
      })}
    </div>
  )
}

export default ShowUserFields;