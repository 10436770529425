import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { HeaderLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/types";
import { doIShow, combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "../../../../../../webcomponents/v2/Icon";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.TWENTYONE,
  layout: HeaderLayouts.TWENTYONE,
  previewImage: "",
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          backgroundColor: theme.highlightColor,
          padding: 15,
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
              color: theme.foregroundColor,
            },
            html: `Next Step... ${startCase(lorem.generateWords(4))}`,
          },
        ],
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: `Free Video Reveals The ${startCase(
                  lorem.generateWords(6)
                )}`,
              },
              {
                type: ComponentTypes.LIST,
                data: [
                  {
                    caption: lorem.generateSentences(2),
                    style: style.LISTITEM.style,
                    iconStyle: style.LISTITEM.iconStyle,
                    contentStyle: style.LISTITEM.contentStyle,
                    icon: IconTypes.CheckCircleOutline,
                  },
                  {
                    caption: lorem.generateSentences(2),
                    style: style.LISTITEM.style,
                    iconStyle: style.LISTITEM.iconStyle,
                    contentStyle: style.LISTITEM.contentStyle,
                    icon: IconTypes.CheckCircleOutline,
                  },
                  {
                    caption: lorem.generateSentences(2),
                    style: style.LISTITEM.style,
                    iconStyle: style.LISTITEM.iconStyle,
                    contentStyle: style.LISTITEM.contentStyle,
                    icon: IconTypes.CheckCircleOutline,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Where Should I Send Your Video",
              },
              {
                type: ComponentTypes.FORMFIELD,
                canHaveChildren: false,
                attributes: {
                  placeholder: "Enter Your Email",
                  name: "email",
                },
                fieldType: FormFieldTypes.TEXT,
                styles: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
