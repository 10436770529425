import { Button, Cell, Grid, Toolbar } from "@launchos/components/ui";
import { FixedPositions } from "@launchos/components/ui/types";
import { AppConfig } from "@launchos/modules/app";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";
import AppDrawer from "../appdrawer";
import { functions, style } from "../campaign/shared";
import CatalogContent from "./CatalogContent";
import CatalogItemEditPopup from "./CatalogItemEditPopup";
import Categories from "./Categories";
import { leftActionsGroup, rightActionsGroup } from "./HeaderComponents";
import CatalogHeaderContent from "./HeaderContent";

class Catalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appDrawerVisible: false,
      editPopup: false,
      createPopup: false,
      activeCampaignDetails: {},
    };

    this.toggleAppDrawer = functions.toggleAppDrawer.bind(this);
    this.showEditPopup = this.showEditPopup.bind(this);
    this.showCreatePopup = this.showCreatePopup.bind(this);
    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.setActiveCampaignDetails = this.setActiveCampaignDetails.bind(this);
  }

  static contextType = AppConfig;

  showEditPopup(catalogItem) {
    this.setState({
      editPopup: true,
      createPopup: false,
      activeCampaignDetails: catalogItem,
    });
  }

  showCreatePopup() {
    this.setState({
      editPopup: false,
      createPopup: true,
      activeCampaignDetails: {},
    });
  }

  hideEditPopup() {
    this.setState({ editPopup: false, createPopup: false });
  }

  setActiveCampaignDetails(details) {
    // console.log({ details })
    this.setState({ activeCampaignDetails: details });
  }

  render() {
    const { app } = this.context;

    const logo = app.showToolbarLogo
      ? [
        () => (
          <Link to="/dashboard/campaigns">
            <img
              alt={`${app.name} Logo`}
              src={app.logoBlack}
              style={{ height: 35 }}
            />
          </Link>
        ),
      ]
      : [];

    return (
      <div>
        <div
          style={{
            ...style.bigToolbar.main,
            position: "relative",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {this.state.editPopup || this.state.createPopup ? (
            <CatalogItemEditPopup
              {...this.props}
              activeCampaignDetails={this.state.activeCampaignDetails}
              setActiveCampaignDetails={this.setActiveCampaignDetails}
              onClose={this.hideEditPopup}
            />
          ) : null}
          <AppDrawer
            hidden={!this.state.appDrawerVisible}
            toggleAppDrawer={this.toggleAppDrawer}
          />
          <Toolbar
            leftActionsGroup={[...leftActionsGroup, ...logo]}
            rightActionsGroup={rightActionsGroup}
            toggleAppDrawer={this.toggleAppDrawer}
            theme="light"
          />
          <CatalogHeaderContent {...this.props} />
          {app.adminIds.indexOf(localStorage.userId) > -1 ? (
            <Button
              icon
              fixed
              floating
              secondary
              fixedPosition={FixedPositions.BOTTOM_RIGHT}
              onClick={this.showCreatePopup}
            >
              add
            </Button>
          ) : null}
        </div>
        <div style={{ padding: "50px 30px", width: 1200, margin: "0 auto" }}>
          <Grid justify="space-between">
            <Cell style={{ marginLeft: -25 }}>
              <Link to="/dashboard">
                <Button
                  iconLeft="keyboard_arrow_left"
                  transparent
                  style={{ color: "#999" }}
                >
                  <span style={{ fontSize: 14, position: "relative", top: -2 }}>
                    Back to Dashboard
                  </span>
                </Button>
              </Link>
            </Cell>
            <Cell>
              {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Catalog' })}
            </Cell>
          </Grid>
          <div style={{ padding: "50px 0" }}>
            <Grid justify="space-between">
              <Cell>
                <Categories {...this.props} />
              </Cell>
              <Cell style={{ width: 900 }}>
                <CatalogContent
                  {...this.props}
                  showEditPopup={this.showEditPopup}
                  activeCampaignDetails={this.state.activeCampaignDetails}
                />
              </Cell>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default Catalog;
