import { CSSProperties } from "react";
import styled from "styled-components";
import theme from "@launchos/components/ui/theme";

export const Cell = styled.div`
  border: 2px solid white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  border-color: white;
  background-color: white;
  &:hover {
    border-color: ${theme.primaryColor};
  }
`;

export const getFontStyle = (i) => {
  const backgroundImage =
    "https://s3.amazonaws.com/sandcastleassets/images/fonts.gif";

  return {
    margin: 5,
    backgroundImage: `url(${backgroundImage})`,
    backgroundPositionY: -1 * (i * 30),
    width: 150,
    height: 30,
  };
};

export const style: {
  main: CSSProperties;
  h2: CSSProperties;
  closeBtn: CSSProperties;
} = {
  main: {
    height: 500,
    width: 865,
    padding: 20,
    overflow: "scroll",
    borderRadius: 3,
    margin: "25px 0",
    paddingTop: 7,
    border: "1px solid #CCC",
    backgroundColor: "white",
    textAlign: "center",
  },
  h2: { textAlign: "center", padding: 15 },
  closeBtn: {
    cursor: "pointer",
    position: "absolute",
    right: 10,
    marginTop: -5,
  },
};
