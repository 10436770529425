import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 100,
      },
      smartphone: {
        padding: 20,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
    // padding: "10px 0"
    padding: 10,
    // color: theme.foregroundColor
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "12pt",
    lineHeight: "18pt",
    // padding: "20px 0"
    // color: theme.foregroundColor
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 7,
      border: "none",
      backgroundColor: theme.backgroundColor,
      minWidth: 150,
      marginTop: 25,
      borderRadius: 3,
      cursor: "pointer",
      textAlign: "center",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "14pt",
    },
  },
  LIST: {
    padding: 20,
    margin: "0 auto",
    marginTop: 20,
  },
  LI: {
    style: {
      // color: theme.foregroundColor,
      fontFamily: theme.fontFamily,
      padding: 5,
    },
    contentStyle: {
      padding: 10,
    },
    iconStyle: {
      marginTop: 11,
    },
  },
};
