import { Button, Cell, Grid } from "@launchos/components/ui";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import providers from "../../settings/IntegrationManagement/integrationProviders";
import IntegrationsSelector from "../../settings/IntegrationManagement/IntegrationsSelector";

import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

export default class ChooseProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      integration: false,
    };

    this.saveProviderSelection = this.saveProviderSelection.bind(this);
  }

  componentDidMount() {
    if (_.get(this.props, "providerSettings.payload.type", false)) {
      this.setState({
        integration: {
          ...this.state.integration,
          type: this.props.providerSettings.payload.type,
        },
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      _.get(nextProps, "providerSettings.payload.type", false) !==
      _.get(this.props, "providerSettings.payload.type", false)
    ) {
      // console.log("componentWillReceiveProps", nextProps.providerSettings.payload, this.props.providerSettings.payload)
      this.setState({
        integration: {
          ...this.state.integration,
          type: nextProps.providerSettings.payload.type,
        },
      });
    }
  }

  async saveProviderSelection() {
    const { updateSettings, providerSettings, history } = this.props;

    const type = _.get(this.state, "integration.type", false);
    const oldType = _.get(providerSettings, "payload.type", false);
    const payload = type === oldType ? providerSettings.payload : false;

    console.log({ type }, { oldType }, { payload });

    await updateSettings({
      ...payload, // <-- conditionally overwrite the payload
      ..._.get(this, "state.integration.setting", false),
      type,
    });

    const providerDetails = _.head(_.filter(providers, (o) => o.id === type));
    const firstTab = _.get(providerDetails, "tabs[0].id", false);

    // console.log({ providerDetails, firstTab });

    if (firstTab) history.push(`./${firstTab}`);
    else history.push(this.props.nextHref);

  }

  render() {
    const { allIntegrations, filter, label, message } = this.props;
    const existing = _.get(allIntegrations, "integrations", []).filter(
      (provider) =>
        _.filter(providers, (o) => o.type === filter).findIndex(
          (f) => f.id === provider.type
        ) > -1
    );

    return (
      <div>
        <h2 style={{ width: 600, margin: "0 auto 40px" }}>
          {message}
          {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Integrations' }, { filter })}
        </h2>

        <IntegrationsSelector
          style={{ minWidth: 600 }}
          onSelect={(integration) => {
            this.setState({ integration });
          }}
          selected={this.state.integration}
          filter={filter}
          showOnlyRegistered={existing.length}
          label={label}
          {...this.props}
          allIntegrationQuery={allIntegrations}
        />
        <Grid justify="space-evenly" style={{ margin: "0 auto", width: 350 }}>
          <Cell align="center">
            <Link to="../builder">
              <Button large transparent style={{ color: "#333" }}>
                Back to Editor
              </Button>
            </Link>
          </Cell>
          <Cell align="center">
            <Button
              onClick={this.saveProviderSelection}
              large
              disabled={!this.state.integration}
              tertiary
            >
              Next
            </Button>
          </Cell>
        </Grid>
        <p
          style={{
            marginTop: 30,
            fontSize: 13,
            color: "#666",
          }}
        >
          When a visitor fills out and submits a form on this page, we will send
          their information to the service provider you choose on this screen.
        </p>
      </div>
    );
  }
}
