import React from "react";

import { AppConfig } from "@launchos/modules/app";

import CatalogItemGroup from "./CatalogItemGroup";

const CatalogContent = (props) => {
  return (
    <AppConfig.Consumer>
      {(settings) => {
        if (props.allCatalogItemsQuery.loading)
          return (
            <div style={{ textAlign: "center" }}>
              <img src={settings.app.loadingImage} alt="Loading..." />
            </div>
          );
        if (props.allCatalogItemsQuery.error)
          return (
            <div style={{ textAlign: "center" }}>
              <img src={settings.app.errorImage} alt="Connection Error" />
            </div>
          );

        const { match, allCatalogItemsQuery } = props;
        const tabId = match.params.tabId;
        const searchStr = match.params.searchStr || "";

        const catalogItemQuery = allCatalogItemsQuery.catalogItems;

        const searchFilter =
          searchStr === "all"
            ? catalogItemQuery
            : catalogItemQuery.filter(
              (itm) =>
                (itm.name &&
                  itm.name.toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1) ||
                (itm.partner &&
                  itm.partner.toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1) ||
                (itm.description &&
                  itm.description
                    .toLowerCase()
                    .indexOf(searchStr.toLowerCase()) > -1) ||
                (itm.tags &&
                  itm.tags.toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1) ||
                (itm.categories &&
                  itm.categories
                    .toLowerCase()
                    .indexOf(searchStr.toLowerCase()) > -1) ||
                (itm.skill &&
                  itm.skill.toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1)
            );

        const recommendedItems = searchFilter.filter(
          (itm) => itm.isRecommended && itm.type === tabId
        );
        const spotlightItems = searchFilter.filter(
          (itm) => itm.isSpotlight && itm.type === tabId
        );
        const foundItems = searchFilter.filter(
          (itm) => itm.type === tabId && !itm.isRecommended && !itm.isSpotlight
        );

        return (
          <div>
            {spotlightItems.length ? (
              <CatalogItemGroup
                {...props}
                label="Funnel Spotlight..."
                items={spotlightItems}
              />
            ) : null}
            {recommendedItems.length ? (
              <CatalogItemGroup
                {...props}
                label="Recommended for you..."
                items={recommendedItems}
                isExpanded
              />
            ) : null}
            {foundItems.length ? (
              <CatalogItemGroup {...props} items={foundItems} />
            ) : null}
          </div>
        );
      }}
    </AppConfig.Consumer>
  );
};

export default CatalogContent;
