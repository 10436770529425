import * as React from "react";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface RepeaterProps {
  mode?: EditorMode;
  data: any[] | any;
  children: (itm: any) => any;
}

export const Repeater: React.FC<RepeaterProps> = ({ data = [], children }) => (
  <div data-testid="WC-REPEATER-LIVE">
    {data.map((itm: any) => children(itm))}
  </div>
);

export default Repeater;
