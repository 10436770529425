import * as React from "react";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

export interface BlockProps {
  mode?: EditorMode;
  children?: React.ReactNode | string;
}

/**
 * A block web component
 */
export const Block: React.FC<BlockProps> = ({ children }) => {
  return <div data-testid="WC-BLOCK-LIVE">{children}</div>;
};

// Block.defaultProps = {};

export default Block;
