import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // padding: 50
  },
  HEADLINE: {
    // textAlign: "center",
    fontSize: "22pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 10,
    color: theme.foregroundColor,
  },
  TEXT: {
    // textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
    color: theme.foregroundColor,
  },
  FORM_FIELD: {
    style: {
      padding: "10px 0",
      maxWidth: "100%",
      // paddingRight: 20
    },
    inputStyle: {
      width: "100%",
      padding: 15,
      border: "1px solid #DDD",
      borderRadius: 4,
      background: "transparent",
      color: theme.foregroundColor,
    },
    labelStyle: {
      fontFamily: theme.fontFamily,
      color: theme.foregroundColor,
      fontSize: "10pt",
      textAlign: "left",
      padding: 5,
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 20,
      marginTop: 10,
      border: "none",
      background: theme.foregroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
