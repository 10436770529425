import React from "react";
import { filter, has } from "lodash";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { FontIcon, MenuItemLabel } from "@launchos/components/ui";

import style from "./style";

export const ColumnDropDownMenu = (props) => {
  const [isShowingLabel, setIsShowingLabel] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    settings,
    pageContent,
    updateContent,
    showProperties,
    visible = false,
  } = props;

  const theColumns = pageContent.filter(
    (itm) => itm.parent === settings.parent
  );

  const handleDropdownChange = (id) => {
    if (id === "add") {
      // resize existing item (cut in half), then duplicate it
      const newLeftColumn = {
        ...settings,
        ...(has(settings, "md") ? { md: Math.round(settings.md / 2) } : {}),
        ...(has(settings, "xs") ? { xs: Math.round(settings.xs / 2) } : {}),
        ...(has(settings, "lg") ? { lg: Math.round(settings.lg / 2) } : {}),
        ...(has(settings, "sm") ? { sm: Math.round(settings.sm / 2) } : {}),
      };

      const newColumnId = Math.random().toString(36).slice(2);

      const newRightColumn = {
        ...settings,
        id: newColumnId,
        ...(has(settings, "md") ? { md: settings.md - newLeftColumn.md } : {}),
        ...(has(settings, "xs") ? { xs: settings.xs - newLeftColumn.xs } : {}),
        ...(has(settings, "lg") ? { lg: settings.lg - newLeftColumn.lg } : {}),
        ...(has(settings, "sm") ? { sm: settings.sm - newLeftColumn.sm } : {}),
      };

      const updatedSettings = [newLeftColumn, newRightColumn];

      const key = pageContent.findIndex((itm) => itm.id === settings.id);

      const content = [
        ...pageContent.slice(0, key),
        ...updatedSettings,
        ...pageContent.slice(key + 1),
      ];

      updateContent(content);
    }

    if (id === "delete") {
      // update parent without content & let the nearby column take up the remainining columns
      const siblings = pageContent.filter(
        (itm) => itm.parent === settings.parent
      );
      const leftKey = siblings.findIndex((itm) => itm.id === settings.id);

      const leftCol = siblings[leftKey];
      const rightCol =
        siblings.length - 1 > leftKey ? siblings[leftKey + 1] : false;

      // Update the Content
      const contentWithoutLeftColumn = pageContent.filter(
        (itm) => itm.id !== settings.id
      );

      // b. build second column
      let contentWithRightColumnUpdated = contentWithoutLeftColumn;
      if (rightCol) {
        const rightKey = contentWithRightColumnUpdated.findIndex(
          (itm) => itm.id === rightCol.id
        );
        contentWithRightColumnUpdated = [
          ...contentWithoutLeftColumn.slice(0, rightKey),
          {
            ...rightCol,
            ...(has(rightCol, "md") && has(leftCol, "md")
              ? rightCol.md + leftCol.md
              : {}),
            ...(has(rightCol, "xs") && has(leftCol, "xs")
              ? rightCol.xs + leftCol.xs
              : {}),
            ...(has(rightCol, "lg") && has(leftCol, "lg")
              ? rightCol.lg + leftCol.lg
              : {}),
            ...(has(rightCol, "sm") && has(leftCol, "sm")
              ? rightCol.sm + leftCol.sm
              : {}),
          },
          ...contentWithoutLeftColumn.slice(rightKey + 1),
        ];
      }

      // c. perform update
      updateContent(contentWithRightColumnUpdated);
    }

    if (id === "properties") {
      props.changeState(settings.id, "active", () => {
        showProperties();
      });
    }

    setAnchorEl(null);
  };

  const handleDropDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (visible)
    return (
      <div
        onMouseEnter={() => setIsShowingLabel(true)}
        onMouseLeave={() => setIsShowingLabel(false)}
        style={style.dropDownMenuWrapper}
      >
        <div
          aria-controls="column-menu"
          aria-haspopup="true"
          onClick={handleDropDownClick}
          style={style.dropDownMenu}
        >
          {isShowingLabel && <div style={style.dropDownMenuLabel}>COLUMN</div>}
          <FontIcon className="arrdropdown" style={style.dropDownMenuIcon}>
            arrow_drop_down
          </FontIcon>
        </div>
        <Menu
          onClick={(e) => {
            e.stopPropagation();
            props.changeState(settings.id, "active");
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          id="column-menu"
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleDropdownChange("properties");
            }}
          >
            <MenuItemLabel icon="settings">Column Properties</MenuItemLabel>
          </MenuItem>
          <div style={style.divider} />
          {filter(pageContent, (itm) => itm.id === settings.parent)[0].type ===
            "Columns" ? (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleDropdownChange("add");
              }}
            >
              <MenuItemLabel icon="add_road">Add a Column</MenuItemLabel>
            </MenuItem>
          ) : (
            <div />
          )}
          {theColumns.length > 2 ? (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleDropdownChange("delete");
              }}
            >
              <MenuItemLabel icon="delete_forever">Delete Column</MenuItemLabel>
            </MenuItem>
          ) : (
            <div />
          )}
        </Menu>
      </div>
    );
  else return null;
};
