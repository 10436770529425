import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import { ListItemTypes } from "@launchos/plugins/webcomponents/v2/ListItem/types";

import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.NINE,
  layout: BenefitLayouts.NINE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit09.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx36rg821t10780ogx3g72b",
    itemId: "25etfym1wl3",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      padding: 50,
                    },
                    smartphone: {
                      padding: 10,
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: get(content, "main_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: get(content, "sub_headline.html", "..."),
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      padding: 35,
                    },
                    smartphone: {
                      padding: 10,
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((6 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              // ...style.LIST,
                            },
                            children: [
                              {
                                type: ComponentTypes.LISTITEM,
                                canHaveChildren: false,
                                properties: {
                                  ...style.LI.style,
                                  marginTop: 20,
                                  color: theme.foregroundColor,
                                  lineHeight: "18pt",
                                },
                                icon: IconTypes.AccountCircle,
                                iconStyle: style.LI.iconStyle,
                                listItemType: ListItemTypes.BLOCK,
                                html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                              },
                              {
                                type: ComponentTypes.LISTITEM,
                                canHaveChildren: false,
                                properties: {
                                  ...style.LI.style,
                                  marginTop: 20,
                                  color: theme.foregroundColor,
                                  lineHeight: "18pt",
                                },
                                icon: IconTypes.AccountCircle,
                                iconStyle: style.LI.iconStyle,
                                listItemType: ListItemTypes.BLOCK,
                                html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((6 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              // ...style.LIST,
                            },
                            children: [
                              {
                                type: ComponentTypes.LISTITEM,
                                canHaveChildren: false,
                                properties: {
                                  ...style.LI.style,
                                  marginTop: 20,
                                  color: theme.foregroundColor,
                                  lineHeight: "18pt",
                                },
                                icon: IconTypes.AccountCircle,
                                iconStyle: style.LI.iconStyle,
                                listItemType: ListItemTypes.BLOCK,
                                html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                              },
                              {
                                type: ComponentTypes.LISTITEM,
                                canHaveChildren: false,
                                properties: {
                                  ...style.LI.style,
                                  marginTop: 20,
                                  color: theme.foregroundColor,
                                  lineHeight: "18pt",
                                },
                                icon: IconTypes.AccountCircle,
                                iconStyle: style.LI.iconStyle,
                                listItemType: ListItemTypes.BLOCK,
                                html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
