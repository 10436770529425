import * as React from 'react';

import { VideoTutorial } from "@launchos/components/ui";
import { Icon } from '@launchos/plugins/webcomponents/v2';
import { IconTypes } from '@launchos/plugins/webcomponents/v2/Icon';
// import theme from '@launchos/components/ui/theme';

export default ({ data = [] }) => {
	const [showTutorial, setShowTutorial] = React.useState(true);
	const [welcomeTextIsShowing, setWelcomeTextIsShowing] = React.useState(!Boolean(localStorage.getItem('zero-to-hero-progress')));
	if (!showTutorial) return null

	return (
		<>
			{welcomeTextIsShowing && <div style={{
				fontSize: '18pt',
				fontWeight: 'bold',
				textAlign: 'center',
				backgroundColor: `rgba(255,255,255,0.9)`,
				// backgroundColor: 'white',
				boxShadow: `0 0 50px 10px white`,
				bottom: 125,
				position: 'fixed',
				width: 400,
				left: '50%',
				transform: `translateX(-200px)`,
				color: "#777",
				padding: 50,
				borderRadius: 200
			}}>
				<Icon style={{ fontSize: '46pt' }} type={IconTypes.EmojiPeopleRounded} />
				<div>Hi! Click the play button below to watch a video tour of 10 Minute Funnels.</div>
				{/* <div style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={() => Intercom('show')}>Testing</div> */}
			</div>}
			<VideoTutorial
				src="https://kjgraham.wistia.com/medias/irmqoi7hqt"
				data={data}
				videoConfig={{
					wistia: {
						options: { resumable: false }
					}
				}}
				style={{
					container: { width: 1100, transform: `translateX(-550px)`, left: "50%" },
					videoContainer: {
						width: `80vw`,
						height: `calc(80vw / (1690 / 886))`
					}
				}}
				sectionToPlay={localStorage.getItem('zero-to-hero-progress') || 'welcome'}
				onSectionChange={section => {
					localStorage.setItem('zero-to-hero-progress', section.id)
				}}
				onClose={() => {
					if (window.confirm("Don't Show Again? (Still available in 10MF University)")) {
						localStorage.setItem("zero-to-hero", "done");
						setShowTutorial(false)
					}
				}}
				onPlay={() => {

					setWelcomeTextIsShowing(false)
					if (!Boolean(localStorage.getItem('zero-to-hero-progress'))) {
						localStorage.setItem('zero-to-hero-progress', 'welcome')
					}
				}}
				onComplete={() => {
					localStorage.setItem("zero-to-hero", "done");
					setShowTutorial(false);
				}}
				secondary
			/>
		</>
	)
}