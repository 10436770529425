import * as React from "react";
import Switch from "@material-ui/core/Switch";
import { RequiredProps } from "../types";

/**
 * A toggle switch for establishing if the field should be marked as "required" or not • This means it will fail validation unless filled
 */
const Required: React.FC<RequiredProps> = ({
  onChange = () => null,
  onBlur = () => null,
  value = false,
  label,
}) => {
  return (
    <div data-testid="FormBuilder-Attributes-Required">
      <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
      <span style={{ position: "relative", padding: 0, top: 1 }}>
        {label || "This Field is Required"}
      </span>
    </div>
  );
};

export default Required;
