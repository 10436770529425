import * as React from "react";
import { get, head } from "lodash";

import { CURSOR_ID } from "@launchos/modules/editor/Builder/WebComponent/types";
import { TabContent } from "./live";
import { TabsProps } from "./types";

import { EditorMode } from "@launchos/modules/v2/Editor/types";
import { EmptyState } from '@launchos/modules/editor/Builder/EditorObject';

export const TabEditorVersion: React.FC<TabsProps> = (props) => {
    const { settings = {}, pageContent = [], children = null, mode = EditorMode.LIVE } = props;

    let isEmpty = false;
    const childContent = pageContent.filter((itm) => itm.parent === settings.id);
    if (childContent.length) isEmpty = !!(childContent.length === 1 && childContent[0].id === CURSOR_ID);
    else isEmpty = true;

    // only show if parent tab is active
    const parentTab = head(pageContent.filter(({ id }) => id === settings.parent));
    if (get(parentTab, 'items', []).findIndex(({ id, active = false }) => id === get(settings, 'itemId') && active) > -1) {
        return (
            <TabContent {...settings}>
                {
                    (isEmpty && mode === EditorMode.EDITOR)
                        ? <div style={{ padding: 30, border: `1px solid #CCC` }}><EmptyState {...props} /></div>
                        : children
                }
            </TabContent>
        );
    }
    return null;
};

export default TabEditorVersion;
