import _ from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import { Button, TextInput } from "@launchos/components/ui";

class Title extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageName: props.name,
      isEditing: _.get(props, "isEditing", false),
      isHover: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
    this.renamePage = this.renamePage.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ pageName: this.props.name });
  }

  renamePage() {
    const { pageName } = this.state;

    this.props.onChange(pageName);
  }

  handleClick() {
    this.setState({
      isEditing: true,
      isHover: false,
    });
  }

  stopEditing(e) {
    this.setState(
      {
        isEditing: false,
        pageName: e.target.value,
        isHover: false,
      },
      () => this.props.onChange(this.state.pageName)
    );
  }

  render() {
    const PageTitle = styled.div`
      cursor: pointer;
      padding: 5px;
      margin-top: -5px;
      display: inline;
      &:hover {
        background-color: #ddd;
      }
    `;

    const { isEditing, pageName } = this.state;
    if (isEditing) {
      return (
        <span style={this.props.style}>
          <TextInput
            style={{
              fontWeight: "bold",
              fontSize: 18,
              padding: 0,
              margin: 0,
              marginTop: -5,
              border: "none",
              textAlign: "center",
              display: "inline-block",
              backgroundColor: "transparent",
              ":focus": {
                border: "none",
              },
              ...this.props.style,
            }}
            selectOnMount
            ref={(node) => (this.input = node)}
            onBlur={this.stopEditing}
            onKeyUp={(e) => {
              if (e.keyCode === 13) this.stopEditing(e);
              if (e.keyCode === 27)
                this.setState({
                  pageName: this.props.name,
                  isEditing: false,
                  isHover: false,
                });
            }}
            value={pageName}
          />
        </span>
      );
    }

    return (
      <span
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
      >
        <PageTitle style={this.props.style} onClick={this.handleClick}>
          {pageName}
        </PageTitle>
        {this.state.isHover && this.props.showIcon ? (
          <Button
            onClick={this.handleClick}
            style={{
              position: "absolute",
              color: "#999",
              marginTop: -7,
              ...this.props.iconStyle,
            }}
            icon
          >
            edit
          </Button>
        ) : null}
      </span>
    );
  }
}

Title.defaultProps = {
  onChange: (e) => false,
  showIcon: false,
};

export default Title;
