import styled from "styled-components";

export const TabItem = styled.div`
  padding: 15px 0;
  text-align: center;
  cursor: pointer;
  color: #ccc;
  font-size: 10pt;
  ${({ active }) => (active ? "background-color: #111" : "")} &:hover {
    ${({ active }) =>
    active ? "background-color: #111" : "background-color: #19334d"};
  }
`;

export const icon = { color: "#CCC", marginBottom: 8 };