import React from "react";
import { get } from 'lodash';

import { Menu, ListItem } from "@launchos/components/ui";
import { client } from "@launchos/api/gql/client";


import { getStatsGql } from "./gql";
import { calcStartEndDates } from './scripts'

import { CellStyle } from './style'
import { QuickDateRange, handleDateRangeFunction, StatsNodePayload } from '../types'

const getStats = async (node, { startDate, endDate }, callbackFn = (payload: { [pageId: string]: StatsNodePayload }) => null) => {
  const id = get(node, 'page.id')

  if (startDate && endDate && id) {

    callbackFn({ [id]: { peopleCount: 0, visitorCount: 0, loading: true } })

    const { data } = await client.query({
      query: getStatsGql,
      variables: {
        id,
        type: "PAGE",
        startDate,
        endDate,
      },
    });

    const { stats } = data;
    const { people, visitors, statusCode } = stats;

    if (statusCode === 200) {
      callbackFn({ [id]: { peopleCount: people, visitorCount: visitors, loading: false } })
    }
    else {
      callbackFn({ [id]: { peopleCount: '?', visitorCount: '?', loading: false } })
    }
  }
}

const handleDateRangeChange: handleDateRangeFunction = async (str = QuickDateRange.TODAY, { setCampaignState, objects = [] }) => {
  const { startDate, endDate } = calcStartEndDates(str)

  // retrieve from db here
  const nodes = objects.filter(obj => !get(obj, 'deleted'));
  setCampaignState({ startDate, endDate, dRange: str });

  nodes.forEach(node => getStats(node, { startDate, endDate }, setCampaignState));

};

const Item = ({ children, onClick }) => (
  <ListItem onClick={onClick}>
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ padding: "5px 10px" }}>{children}</div>
    </div>
  </ListItem>
);

export default (props) => {

  const { getCampaignState } = props;
  const dRange = getCampaignState("dRange");

  React.useEffect(() => {
    if (!dRange) handleDateRangeChange(QuickDateRange.TODAY, props);
  });

  return dRange ? (
    <CellStyle>
      <Menu label={`Showing Stats for ${dRange}`}>
        <Item key={QuickDateRange.TODAY} onClick={() => handleDateRangeChange(QuickDateRange.TODAY, props)}>
          {QuickDateRange.TODAY}
        </Item>
        <Item
          key={QuickDateRange.YESTERDAY}
          onClick={() => handleDateRangeChange(QuickDateRange.YESTERDAY, props)}
        >
          {QuickDateRange.YESTERDAY}
        </Item>
        <Item key={QuickDateRange.LASTWEEK} onClick={() => handleDateRangeChange(QuickDateRange.LASTWEEK, props)}>
          {QuickDateRange.LASTWEEK}
        </Item>
        <Item
          key={QuickDateRange.LASTMONTH}
          onClick={() => handleDateRangeChange(QuickDateRange.LASTMONTH, props)}
        >
          {QuickDateRange.LASTMONTH}
        </Item>
        <Item key={QuickDateRange.LAST7DAYS} onClick={() => handleDateRangeChange(QuickDateRange.LAST7DAYS, props)}>
          {QuickDateRange.LAST7DAYS}
        </Item>
        <Item key={QuickDateRange.LAST30DAYS} onClick={() => handleDateRangeChange(QuickDateRange.LAST30DAYS, props)}>
          {QuickDateRange.LAST30DAYS}
        </Item>
        <Item key={QuickDateRange.ALLTIME} onClick={() => handleDateRangeChange(QuickDateRange.ALLTIME, props)}>
          {QuickDateRange.ALLTIME}
        </Item>
      </Menu>
    </CellStyle>
  ) : null;
};
