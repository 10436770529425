import _, { get, head } from "lodash";
import React, { Component } from "react";
import {
  Dialog,
  DialogBody,
  Grid,
  Cell,
  Button,
} from "@launchos/components/ui";

import LogicBuilder from "@launchos/plugins/misc/v2/LogicBuilder";
import { fields } from "@launchos/plugins/misc/v2/LogicBuilder/defaults";
import { SelectorTypes, getType } from "@launchos/plugins/misc/v2/LogicBuilder/LoadSelector/types";

const indexFields = head(fields.filter(({ type }) => type === SelectorTypes.INDEX));
const indexFieldsWithType = { ...indexFields, type: getType(indexFields.type) }

export default class SmartRoutePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: _.get(props, "options.settings.url", ""),
      values: [indexFieldsWithType]
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.getConnectedNodes = this.getConnectedNodes.bind(this);
  }

  handleChange(name, value, newValues) {
    console.log(name, value)

    this.setState({ values: newValues });

    // save to node settings
  }

  async saveSettings() {
    const { options, actions } = this.props;
    const { url } = this.state;
    const { updatePageSettings, updateObjectSettings } = actions;

    const pageId = _.get(options, "page.id");

    const pageScript = `
      <script type="text/javascript">
        window.location.href = "${url}";
      </script>
    `;

    await updateObjectSettings({
      id: options.id,
      settings: { url }
    });

    await updatePageSettings({
      pageId,
      pageSettings: {
        tracking: {
          head: pageScript
        }
      }
    });

    window.location.reload();
    // onClose();
  }

  getConnectedNodes() {

    const { options, getCampaignQuery } = this.props;

    // options.connectTo[{ id }]
    const { connectTo = [] } = options;

    // getCampaignQuery.campaign.objects[{ id, page(slug), screenshot}]
    const { objects = [] } = get(getCampaignQuery, 'campaign', []);

    console.log({ objects, getCampaignQuery })
    // 1. create an array (map) over connectTo
    // 2. look up applicable object in [objects]
    // 3. return the result

    return connectTo.map(({ id }) => ({ id, ...head(objects.filter(obj => obj.id === id)) }));
  }


  render() {
    return (
      <Dialog {...this.props} style={{ width: 950 }} centered>
        <DialogBody>
          <img
            style={{ margin: 10, width: 100 }}
            alt=""
            src="https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_pageredirect.png"
          />

          <h2 style={{ paddingTop: 10 }}>
            Smart Routing
          </h2>

          <p style={{ paddingTop: 10, paddingBottom: 20 }}>
            Route your visitors to a different destination based on conditions you specify.
            <br />Create a condition for each of the destination this Smart Router connects to.
          </p>

          {this.getConnectedNodes().map(({ id, page, name, screenshot }, key) => (
            <div style={{ backgroundColor: key % 2 ? 'transparent' : '#F1F1F1', padding: 20 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: 150, alignSelf: 'center' }}>
                  <img src={screenshot} style={{ width: 100 }} />
                  <p>{name}</p>
                </div>
                <div style={{ padding: "0 30px" }}>
                  <p style={{ paddingBottom: 10, fontWeight: "bold", textAlign: 'left' }}>Go to the "{name}" page when...</p>

                  <LogicBuilder data={fields} value={this.state.values} onChange={this.handleChange} styles={{ valueWrapper: { backgroundColor: 'white', borderRadius: 1, marginRight: 5 } }} />
                </div>
              </div>
            </div>
          ))}

          <Grid justify="space-evenly" style={{ width: 250, margin: "0 auto" }}>
            <Cell align="center">
              <Button onClick={this.saveSettings} large tertiary>
                OK
              </Button>
            </Cell>
            <Cell align="center">
              <Button
                onClick={this.props.onClose}
                large
                transparent
                style={{ color: "#333" }}
              >
                Cancel
              </Button>
            </Cell>
          </Grid>
        </DialogBody>
      </Dialog>
    );
  }
}
