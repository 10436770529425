import PropTypes from "prop-types";
import React, { Component } from "react";
import { FontIcon } from "react-md";
import styled from "styled-components";

const dropDownStyle = {};

const dropDownExpandedStyle = {
  backgroundColor: "white",
  color: "#333",
  // padding: 10,
  marginTop: 10,
};

const DDS = styled.div`
  background-color: #DDD;
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #FFF;
  }
`;

/**
 * A dropdown menu of FancySelectors
 */

class DropDownCollapse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: props.isExpanded,
    };

    this.toggleExpansion = this.toggleExpansion.bind(this);
  }

  toggleExpansion() {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  }

  render() {
    const { text, children, style = {} } = this.props;
    const { isExpanded } = this.state;

    return (
      <DDS style={style}>
        <div onClick={this.toggleExpansion}>
          <div style={{ float: "right", marginTop: 0 }}>
            <FontIcon style={{ color: "#333" }}>
              {isExpanded ? "arrow_drop_up" : "arrow_drop_down"}
            </FontIcon>
          </div>
          <div>{text}</div>
        </div>
        {isExpanded ? (
          <div style={dropDownExpandedStyle}>{children}</div>
        ) : null}
      </DDS>
    );
  }
}

DropDownCollapse.propTypes = {
  /** This is the text that will appear inside of the dropdown menu */
  text: PropTypes.string.isRequired,
  /** This event is triggered when the dropdown is changed */
  // onChange: PropTypes.func.isRequired,
  /** These are the <FancySelector /> elements that are collapsed inside of the dropdown element */
  children: PropTypes.element.isRequired,
  /** Bool if the dropdown should be collapsed or expanded */
  isExpanded: PropTypes.bool.isRequired,
};

DropDownCollapse.defaultProps = {
  text: "Name...",
  isExpanded: false,
};

export default DropDownCollapse;
