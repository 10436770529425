export default {
  textInput: { width: "100%" },
  subdomainContainer: {
    padding: 20,
    margin: "30px -10px",
    backgroundColor: "#EFEFEF",
    borderRadius: 3,
    textAlign: "left"
  },
  subdomainInput: { width: 225, margin: 10, backgroundColor: "white" },
  subdomainSpan: { fontSize: 14, color: "#666" },
  tableGroup: {
    border: "1px solid #CCC",
    padding: "15px 20px",
    backgroundColor: "#F0F0F0",
    margin: "15px 0",
    borderRadius: 3,
    boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.13)"
  }
};
