import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 100,
    mobile: {
      desktop: { padding: 100 },
      smartphone: { padding: 10 },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "26pt",
    lineHeight: "46px",
    fontFamily: theme.fontFamily,
    padding: 20,
    // fontWeight: "bold" as "bold",
    // paddingBottom: 20,
    mobile: {
      desktop: { fontSize: "32pt", padding: 20 },
      smartphone: { fontSize: "22pt", padding: 5 },
    },
  },
  SUBHEADLINE: {
    textAlign: "center",
    fontSize: "14pt",
    lineHeight: "24px",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 20,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "25px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      // margin: "40px auto",
      marginTop: 40,
      borderRadius: 3,
      cursor: "pointer",
      textAlign: "center",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "18pt",
    },
  },
};
