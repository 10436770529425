import gql from "graphql-tag";

// allDomainsQuery;
export const allDomainsQuery = gql`
  query allDomainsQuery($userId: ID!) {
    domains(where: { user: { id: $userId } }, orderBy: createdAt_DESC) {
      id
      name
      createdAt
      campaign {
        id
        name
      }
    }
  }
`;

// createDomain;
export const createDomain = gql`
  mutation createDomain(
    $userId: ID!
    $name: String!
    $cloudfrontDistribution: String
  ) {
    createDomain(
      data: {
        user: { connect: { id: $userId } }
        name: $name
        cloudfrontDistribution: $cloudfrontDistribution
      }
    ) {
      id
      name
      cloudfrontDistribution
    }
  }
`;

// deleteDomainMutation;
export const deleteDomainMutation = gql`
  mutation deleteDomain($id: ID!) {
    deleteDomain(where: { id: $id }) {
      id
      name
    }
  }
`;

// createCampainOfDomain;
export const createCampainOfDomain = gql`
  mutation createDomain(
    $userId: ID!
    $name: String!
    $campaignId: ID!
    $cloudfrontDistribution: String
  ) {
    createDomain(
      data: {
        user: { connect: { id: $userId } }
        name: $name
        campaign: { connect: { id: $campaignId } }
        cloudfrontDistribution: $cloudfrontDistribution
      }
    ) {
      id
      name
    }
  }
`;

// updateCampaignOfDomain;
export const updateCampaignOfDomain = gql`
  mutation updateDomain(
    $id: ID!
    $userId: ID!
    $name: String!
    $campaignIds: [CampaignWhereUniqueInput!]
  ) {
    updateDomain(
      where: { id: $id }
      data: {
        user: { connect: { id: $userId } }
        name: $name
        campaign: { connect: $campaignIds }
      }
    ) {
      id
      name
    }
  }
`;

// createDomainOnServer
export const createDomainOnServer = gql`
  mutation createDomainOnServer(
    $domain: String!
    $ssl: Boolean!
    $email: String!
    $proxyPass: String!
  ) {
    createDomainOnServer(domain: $domain, ssl: $ssl, email: $email, proxyPass: $proxyPass) {
      statusCode
    }
  }
`;

// createSSLOnServer
export const createSSLOnServer = gql`
  mutation createSSLOnServer($domain: String!, $email: String!) {
    createSSLOnServer(domain: $domain, email: $email) {
      statusCode
      response
    }
  }
`;

// deleteDomainOnServer
export const deleteDomainOnServer = gql`
  mutation deleteDomainOnServer($domain: String!) {
    deleteDomainonServer(domain: $domain) {
      statusCode
    }
  }
`;
