import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CountDownTypes } from "@launchos/plugins/webcomponents/v2/Countdown/types";
import { UrgencyLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: UrgencyLayouts.TWO,
  layout: UrgencyLayouts.TWO,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/urgency/Urgency02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkztsx5q2llj0780vbppfmr4",
    itemId: "ooal9q3ut6",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.URGENCY,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                src: theme.logoDark,
                style: {
                  width: 250,
                  marginTop: 40,
                  display: "inline-block",
                  textAlign: "center",
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  display: "inline-block",
                  textAlign: "center",
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      textAlign: "center",
                      color: theme.highlightColor,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: "Registration Closes In...",
                  },
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      display: "inline-block",
                      textAlign: "center",
                      marginTop: 10,
                    },
                    children: [
                      {
                        type: ComponentTypes.COUNTDOWN,
                        countType: CountDownTypes.EVENT,
                        eventDate: Date.now() + 2 * 24 * 60 * 60 * 1000,
                        properties: {},
                        dimensionStyle: style.COUNTDOWN.dimensionStyle,
                        labelStyle: style.COUNTDOWN.labelStyle,
                        numberStyle: style.COUNTDOWN.numberStyle,
                        dividerStyle: style.COUNTDOWN.dividerStyle,
                        showDivider: false,
                        actions: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  display: "inline-block",
                  mobile: {
                    desktop: {
                      textAlign: "right",
                    },
                    smartphone: {
                      textAlign: "center",
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      marginTop: 10,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: `<p>Need Help? <br />Call Us at 1-888-555-1212<br />...or <a href="#">email support</a></p>`,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
