import React from "react";
import { Height, Section, Width } from "..";

const WidthHeight = (props) => (
  <Section label="Size" icon="photo_size_select_large" {...props}>
    {props.showWidth ? <Width {...props} /> : null}
    {props.showHeight ? <Height {...props} /> : null}
  </Section>
);

WidthHeight.defaultProps = {
  showWidth: true,
  showHeight: true,
};

export default WidthHeight;
