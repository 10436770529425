import React from "react";
import { Button } from "@launchos/components/ui";

export const HamburgerMenu: React.FC<{ toggleAppDrawer: () => void }> = ({
  toggleAppDrawer,
}) => (
  <Button style={{ color: "inherit" }} icon onClick={toggleAppDrawer}>
    menu
  </Button>
);
