import React from "react";
import { ButtonSliderGroup } from "..";

import {
  convertToRem,
  convertFromRem,
} from "@launchos/modules/editor/Builder/MobileResponsiveToggle/style";
import { MobileState } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";

import { LineHeightProps } from "./types";

const LineHeight: React.FC<LineHeightProps> = ({
  settings = {},
  updateComponentStyle = () => null,
  getMobileState = () => ({
    type: MobileState.FULLSCREEN,
  }),
  useRem = true,
}) => {
  const lineHeight = settings.properties.lineHeight
    ? Number(String(settings.properties.lineHeight).replace("px", ""))
    : 12;

  // send raw pixel value to the button slider even if input value is in rem
  const convertedValue = Number(
    convertFromRem(lineHeight, getMobileState()["type"])
  );

  const handleChange = (value, shouldDbUpdate = true, wait = 500) => {
    let lhOffset;
    if (value === "SHRINK") lhOffset = convertedValue - 2;
    else if (value === "GROW") lhOffset = convertedValue + 2;

    const newLineHeight = Number.isInteger(value)
      ? `${value}px`
      : `${lhOffset}px`;

    // convert the raw pixel value to rem before saving it the db
    const newValue = useRem
      ? convertToRem(newLineHeight, getMobileState()["type"])
      : newLineHeight;

    const newStyle = { lineHeight: newValue };

    updateComponentStyle(settings.id, newStyle, shouldDbUpdate, wait);
  };

  return (
    <div data-testid="Properties-LineHeight">
      <ButtonSliderGroup
        onChange={handleChange}
        text="Line Height"
        button1={{ icon: "remove", value: "SHRINK" }}
        button2={{ icon: "add", value: "GROW" }}
        minValue={8}
        maxValue={100}
        value={convertedValue}
      />
    </div>
  );
};

export default LineHeight;
