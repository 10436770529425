import gql from "graphql-tag";

// allIntegrationQuery;
export const allIntegrationQuery = gql`
  query allIntegrationQuery($userId: ID!) {
    integrations(where: { user: { id: $userId } }) {
      id
      type
      setting
      createdAt
      updatedAt
      user {
        id
      }
    }
  }
`;

// deleteIntegrationMutation;
export const deleteIntegrationMutation = gql`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(where: { id: $id }) {
      id
    }
  }
`;

// createIntegration;
export const createIntegration = gql`
  mutation createIntegration($userId: ID!, $setting: Json!, $type: String!) {
    createIntegration(
      data: {
        user: { connect: { id: $userId } }
        setting: $setting
        type: $type
      }
    ) {
      id
    }
  }
`;

// updateIntegration;
export const updateIntegration = gql`
  mutation updateIntegration($id: ID!, $setting: Json!) {
    updateIntegration(where: { id: $id }, data: { setting: $setting }) {
      id
      setting
    }
  }
`;
