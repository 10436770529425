import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "../../../../../../webcomponents/v2/Icon";
import {
  getClipPath,
  ClipPathTypes,
} from "@launchos/plugins/utilities/clipPaths";
import { SocialProofLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.FIFTEEN,
  layout: SocialProofLayouts.FIFTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof15.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkysae0n2d0l0780y3gavfbw",
    itemId: "wskpwrs3si",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        preventDelete: true,
        properties: {
          height: "100%",
          padding: 25,
          minHeight: 20,
          maxWidth: 700,
          display: "inline-block",
          textAlign: "center",
        },
        children: [
          {
            type: ComponentTypes.ICON,
            canHaveChildren: false,
            properties: style.ICON,
            iconType: IconTypes.FormatQuote,
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
            },
            html: lorem.generateSentences(4),
          },
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              // margin: "0 auto",
              marginTop: 20,
              width: 50,
              // height: 50,
              textAlign: "center",
              display: "inline-block",
            },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                src:
                  "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                style: {
                  display: "block",
                  clipPath: getClipPath({ type: ClipPathTypes.CIRCLE }),
                },
              }),
            ],
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
            },
            html: `- ${startCase(lorem.generateWords(2))}`,
          },
        ],
      },
    ],
  },
};

export default settings;
