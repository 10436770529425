import {
  allCampaigns,
  createCampaignObject,
  getCampaign,
  publishCampaign,
  publishCampaignId,
  updateCampaign,
  updateCampaignObject,
  updateObjectSettings
} from "@launchos/api/gql/campaigns";
import {
  allDomainsQuery,
  createCampainOfDomain,
  updateCampaignOfDomain
} from "@launchos/api/gql/domains";
import {
  createPage,
  makeThisMyErrorPage,
  makeThisMyHomePage,
  updatePageContent,
  updatePageName,
  updatePageType,
  updatePageSettings
} from "@launchos/api/gql/pages";
import { getUser } from "@launchos/api/gql/users";
import { compose, graphql } from "react-apollo";
import Campaign from "./Campaign";

// export const allCampaigns = allCampaigns;

export default compose(
  graphql(allCampaigns, {
    name: "allCampaignsQuery",
    options: props => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),
  graphql(getCampaign, {
    name: "getCampaignQuery",
    options: props => ({
      variables: {
        id: props.match.params.campaignId
      },
      fetchPolicy: "network-only"
    })
  }),
  graphql(updateCampaign, {
    name: "updateCampaignMutation"
  }),
  graphql(updateCampaignObject, {
    name: "updateCampaignObjectMutation"
  }),
  graphql(updateObjectSettings, { name: "updateObjectSettings" }),
  graphql(createCampaignObject, {
    name: "createCampaignObjectMutation"
  }),
  graphql(allDomainsQuery, {
    name: "allDomainsQuery",
    options: props => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),
  graphql(updateCampaignOfDomain, {
    name: "updateCampaignOfDomain"
  }),
  graphql(createCampainOfDomain, {
    name: "createCampainOfDomain"
  }),
  graphql(publishCampaign, {
    name: "publishCampaign"
  }),
  graphql(publishCampaignId, {
    name: "publishCampaignId"
  }),
  graphql(updatePageContent),
  graphql(updatePageSettings, { name: "updatePageSettings" }),
  graphql(updatePageName, {
    name: "updatePageName"
  }),
  graphql(updatePageType, {
    name: "updatePageType"
  }),
  graphql(createPage, {
    name: "createPageMutation"
  }),
  graphql(makeThisMyHomePage, {
    name: "makeThisMyHomePage"
  }),
  graphql(makeThisMyErrorPage, {
    name: "makeThisMyErrorPage"
  }),
  graphql(getUser, {
    name: "getUserQuery",
    options: () => ({
      variables: {
        userId: localStorage.userId
      }
    })
  })
)(Campaign);
