import React from "react";
import { Step, StepLabel, Stepper } from "@launchos/components/ui";
import style from "./style";

const ShowStepper = props => {
  const { activeStep, tabs, history } = props;

  return (
    <div style={style.stepper}>
      <Stepper activeStep={activeStep}>
        {tabs
          .filter(tab => tab.show)
          .map((itm, key) => (
            <Step
              key={key}
              onClick={() => {
                if (key < activeStep)
                  history.push(`./${tabs[key].id.toLowerCase()}`);
              }}
              style={style.step}
            >
              <StepLabel>{itm.label}</StepLabel>
            </Step>
          ))}
      </Stepper>
    </div>
  );
};

export default ShowStepper;
