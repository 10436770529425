// export interface ItemComponentProps {
//   id: string;
// }

import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

export interface AddButtonComponentProps {
  /**
   * Triggers when the [Add] button is clicked
   */
  onAdd?: () => void;
}

export interface EditButtonComponentProps {
  /**
   * Triggers when the edit button next to an item is clicked
   */
  onEdit?: (section: ItemComponentProps) => void;

  /**
   * The array item from the data array associated with this specific edit button
   */
  payload?: any;
}

export interface DeleteButtonComponentProps {
  /**
   * Triggers when the [Delete] button is clicked
   */
  onDelete?: (section: ItemComponentProps) => void;

  /**
   * The array item from the data array associated with this specific delete button
   */
  payload?: any;
}
export interface UpDownButtonComponentProps {
  /**
   * Triggers when the [Up or Down] button is clicked
   */
  onChange?: (direction: "UP" | "DOWN", payload: ItemComponentProps) => void;

  /**
   * The array item from the data array associated with this specific delete button
   */
  payload?: any;
}

export interface SortableListProps {
  id?: string;

  /**
   * The incoming data that this component will manage
   */
  data: ItemComponentProps[] | object[];

  /**
   * The style to apply to the main List Sortable container
   */
  style?: React.CSSProperties;

  /**
   * The name to use throughout the component (e.g. Add New "Bullet" or Add New "Block")
   */
  name?: string;

  /**
   * The style to apply to each list sortable item
   */
  itemStyle?: React.CSSProperties;

  /**
   * The style to apply to each list sortable item (when the mouse is hovering over it)
   */
  itemHoverStyle?: React.CSSProperties;

  /**
   * The component to use that will represent each item in the incoming data array
   */
  ItemComponent: React.FC<any>;

  /**
   * Whether or not to show the item component in between the CRUD buttons or beneath
   */
  showItemInline?: boolean;

  /**
   * A component to use instead of the default add button component
   */
  AddButtonComponent?: React.FC<AddButtonComponentProps>;

  /**
   * A component to use instead of the default edit button component
   */
  EditButtonComponent?: React.FC<EditButtonComponentProps>;

  /**
   * A component to use instead of the default delete button component
   */
  DeleteButtonComponent?: React.FC<DeleteButtonComponentProps>;

  /**
   * A component to use instead of the default up/down button component
   */
  UpDownButtonComponent?: React.FC<UpDownButtonComponentProps>;

  /**
   * Whether or not show the CRUD icons when the mouse is hovered on the item or not (always shows)
   */
  showCRUDOnHover?: boolean;

  /**
   * Whether or not to show a draggable handle indicator next to each item
   */
  showDragHandle?: boolean;

  /**
   * Whether or not the dragging should be locked along the Y axis
   */
  lockYAxis?: boolean;

  /**
   * Whether or not drag and drop is enabled
   */
  canDragAndDrop?: boolean;

  /**
   * Whether or not to display the delete icon next to each item
   */
  canDelete?: boolean;

  /**
   * Whether or not to show the add button
   */
  canAdd?: boolean;

  /**
   * Whether or not to display the edit icon next to each item
   */
  canEdit?: boolean;

  /**
   * Whether or not this element is nested inside of another sortable list
   */
  nested?: boolean;

  /**
   * Triggered when an item changes.  Returns an updated version of the data array.  It's up to the component provider to update the data prop
   */
  onChange?: (updatedData: ItemComponentProps[]) => void;

  /**
   * Triggers when the edit button next to an item is clicked. Returns the data item being edited
   */
  onEdit?: (item: ItemComponentProps) => void;

  /**
   * Triggers when the [Add] button is clicked
   */
  onAdd?: () => void;

  /**
   * Triggers when the [Delete] button is clicked. Returns the data item to be deleted, as well as an updated version of the data array. It's up to the component provider to update the data prop
   */
  onDelete?: (
    item: ItemComponentProps,
    updatedData: ItemComponentProps[]
  ) => void;
}

export enum ItemComponentTypes {
  TEXT,
  INPUT,
  DROPDOWN,
}

export interface ItemComponentProps {
  type?: ItemComponentTypes;
  caption?: string;
  placeholder?: string;
  isHovering?: boolean;
  icon?: IconTypes;
  data?: { label: string; value: string; icon?: IconTypes; image?: string }[];
  isExpanded: boolean;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  children?: React.ReactNode;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseMove?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onAdd?: () => void;
  toggleExpansion?: () => void;
}
