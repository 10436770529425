import * as React from "react";
import { client } from "@launchos/api/gql/client";
import { getStatsGql } from "./gql";

import { getBackground } from './scripts';
import style from "./style";

import theme from '@launchos/components/ui/theme'

import { StatsNodeProps } from '../types'

const StatsNode: React.FC<StatsNodeProps> = ({ getCampaignState, setCampaignState, page, visitorCount = 0, peopleCount = 0, loading = false }) => {
  // const [peopleCount, setPeopleCount] = React.useState(false);
  // const [visitorCount, setVisitorCount] = React.useState(false);
  // const [isLoading, setIsLoading] = React.useState(false);
  // const [startDate, setStartDate] = React.useState(getCampaignState("startDate"));
  // const [endDate, setEndDate] = React.useState(getCampaignState("endDate"));

  // const startDate = getCampaignState("startDate");
  // const endDate = getCampaignState("endDate");

  const backgroundColor = getBackground(page);

  // const initialize = async () => {
  // const { id } = page;
  // setIsLoading(true);
  // try {
  //   if (startDate && endDate) {
  //     const { data } = await client.query({
  //       query: getStatsGql,
  //       variables: {
  //         id,
  //         type: "PAGE",
  //         startDate,
  //         endDate,
  //       },
  //     });

  //     const { stats } = data;
  //     const { people, visitors, statusCode } = stats;

  //     setIsLoading(false);

  //     if (statusCode === 200) {
  //       setPeopleCount(people);
  //       setVisitorCount(visitors);
  //       // setCampaignState({ [id]: { people, visitors } });
  //       // setCampaignState({ people, visitors })
  //     }
  //     else setPeopleCount("?")
  //   }
  // } catch (err) {
  //   setIsLoading(false);
  //   setPeopleCount("?");
  // }
  // setCampaignState({ test: 123 })
  // };

  // React.useEffect(() => {
  // const { id } = page;

  // console.log(id, "Mounted")
  //   initialize();
  // }, []);

  return (
    <div
      style={{
        ...style.parent,
        backgroundColor: Number.isInteger(peopleCount) ? backgroundColor : theme.darkColor,
      }}
    >
      {!loading ? (
        <div style={style.child}>
          {visitorCount ? <span style={style.visitorCount}>{visitorCount}</span> : null}
          <span style={style.peopleCount}>{peopleCount}</span>
        </div>
      ) : (
        <div style={style.loading}>
          <img
            src="https://www.ruralsourcing.com/wp-content/uploads/2016/10/3-Steps-to-Optimize-SAP.gif"
            style={style.loadingImg}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(StatsNode);
