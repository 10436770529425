import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { ComponentTypes, IComponentSettings } from "../../types";
import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/types";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import theme from "@launchos/components/ui/theme";

const settings: IComponentSettings = {
  id: ComponentTypes.GOOGLELOGIN,
  name: "Google Optin",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 10,
    },
    iconSource: IconSource.FONTAWESOME,
    icon: faGoogle,
    iconStyle: { fontSize: "16pt" },
  },
  category: "social",
  default: {
    type: ComponentTypes.GOOGLELOGIN,
    properties: {
      backgroundColor: "#2761FF",
      // width: "100%",
      textAlign: "center",
      display: "inline-block",
      fontSize: "14pt",
      color: "white",
      padding: 5,
      borderRadius: 4,
      border: "none",
    },
    buttonSettings: {
      caption1: "Continue with Google",
      icon: faGoogle,
      iconSource: IconSource.FONTAWESOME,
      iconStyle: {
        padding: "0 5px",
      },
    },
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CAPTION,
          id: "clientId",
          sectionWrap: true,
          settings: {
            label: "Google Client Id",
            icon: "build",
            text: "Client Id",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CAPTION,
          id: "caption",
          sectionWrap: true,
          settings: {
            label: "Button Caption",
            icon: "format_size",
            text: "Caption",
            isExpanded: false,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.COLORPICKER,
          sectionWrap: true,
          settings: {
            label: "Button Color",
            icon: "colorize",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Button Width",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
        // {
        //   tab: "Advanced",
        //   type: SectionTypes.BORDERSSHADOW,
        //   settings: {
        //     isExpanded: true
        //   },
        // },
        {
          tab: "Basic",
          type: SectionTypes.ACTIONS,
          settings: {
            label: "Actions (Optin Succeeded)",
            onlyTheseActions: ["TRIGGER_NEXT_NODE", "GO_TO_URL"],
            children: null,
          },
        },
        // {
        //   tab: "Advanced",
        //   type: SectionTypes.ACTIONS,
        //   settings: {
        //     label: "Actions (Optin Canceled)",
        //     onlyTheseActions: ["TRIGGER_NEXT_NODE", "GO_TO_URL"],
        //   },
        // },
      ],
    },
  },
};

export default settings;
