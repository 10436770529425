import React from "react";
import { get } from 'lodash';

import {
  ButtonSliderGroup,
  ColorPicker,
  FancySelector,
  Icon,
  Section,
} from "../../";

import { BorderStyles, BorderProps } from './types'

import style from "../../style";

const Border: React.FC<BorderProps> = (props) => {

  const {
    settings = { properties: {} },
    hideBorderStyle = false,
    hideBorderColor = false,
    hideBorderThickness = false,
    hideBorderRadius = false,
  } = props;

  const { properties = {} } = settings;
  const { borderColor = "", borderWidth = 0, borderRadius = 0, borderStyle = "none" } = properties;

  const changeBorderStyle = (id) => {
    const { onChange, settings } = props;
    const borderStyle = id;
    onChange({ borderWidth: borderWidth || 1, borderStyle });
  }

  const changeBorderThickness = (value) => {
    const { onChange, settings } = props;

    const bW = get(settings, 'properties.borderWidth', 0);
    const bWOffset = value === "LESS" ? bW - 1 : bW + 1;
    const borderWidth = Number.isInteger(value) ? value : bWOffset;

    onChange({
      borderWidth,
      // set border style to solid if none
      borderStyle: borderStyle || BorderStyles.SOLID
    });
  }

  const changeBorderRadius = (value) => {
    const { onChange, settings } = props;
    const bR = get(settings, 'properties.borderRadius', 0);
    const bROffset = value === "LESS" ? bR - 2 : bR + 2;
    const borderRadius = Number.isInteger(value) ? value : bROffset;

    onChange({ borderRadius });
  }

  const changeBorderColor = (color) => {
    const { onChange, settings } = props;
    onChange({ borderColor: color.hex });
  }

  const borderItems = [
    {
      id: BorderStyles.NONE,
      component: (props) => (
        <Icon
          {...props}
          icon="do_not_disturb"
          highlighted={borderStyle === BorderStyles.NONE}
          caption={BorderStyles.NONE}
        />
      ),
    },
    {
      id: BorderStyles.SOLID,
      component: (props) => (
        <Icon
          {...props}
          icon="border_outer"
          highlighted={borderStyle === BorderStyles.SOLID}
          caption={BorderStyles.SOLID}
        />
      ),
    },
    {
      id: BorderStyles.DASHED,
      component: (props) => (
        <Icon
          {...props}
          icon="border_clear"
          highlighted={borderStyle === BorderStyles.DASHED}
          caption={BorderStyles.DASHED}
        />
      ),
    },
  ];

  return (
    <div>
      {!hideBorderStyle ? (
        <fieldset style={style.fieldset}>
          <legend>Border Style</legend>
          <FancySelector items={borderItems} onChange={changeBorderStyle} />
        </fieldset>
      ) : null}

      {(!hideBorderColor && borderStyle !== "none") ? (
        <ColorPicker
          {...props}
          text="Border Color"
          colorExpanded={false}
          showAlpha={false}
          color={borderColor}
          onChange={changeBorderColor}
        />
      ) : null}

      {(!hideBorderThickness && borderStyle !== "none") ? (
        <ButtonSliderGroup
          onChange={changeBorderThickness}
          text="Border Thickness"
          button1={{ icon: "remove", value: "LESS" }}
          button2={{ icon: "add", value: "MORE" }}
          minValue={0}
          maxValue={100}
          value={borderWidth || 0}
        />
      ) : null}

      {(!hideBorderRadius) ? (
        <ButtonSliderGroup
          onChange={changeBorderRadius}
          text="Roundness"
          button1={{ icon: "remove", value: "LESS" }}
          button2={{ icon: "add", value: "MORE" }}
          minValue={0}
          maxValue={100}
          value={borderRadius || 0}
        />
      ) : null}
    </div>
  );
}

export default Border;
