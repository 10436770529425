import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 40,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    // color: theme.backgroundColor
    mobile: {
      desktop: { fontSize: "28pt", textAlign: "left" },
      smartphone: { fontSize: "18pt", textAlign: "center" },
    },
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 40,
    // color: theme.backgroundColor,
    fontSize: "8pt",
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      marginTop: 50,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
      mobile: {
        desktop: { textAlign: "left" },
        smartphone: { textAlign: "center" },
      },
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "90%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.backgroundColor}`,
      borderRadius: 5,
      color: theme.backgroundColor,
    },
  },
};
