import theme from "@launchos/components/ui/theme";
import { CSSProperties } from "react";

const style: {
  dropDownMenuWrapper: CSSProperties;
  dropDownMenu: CSSProperties;
  dropDownMenuLabel: CSSProperties;
  dropDownMenuIcon: CSSProperties;
  editorObject: CSSProperties;
  divider: CSSProperties;
} = {
  dropDownMenuWrapper: {
    display: "inline",
    position: "absolute",
    cursor: "pointer",
    top: -7,
    right: 0,
    zIndex: 100,
  },
  dropDownMenu: {
    backgroundColor: theme.altColors[1],
    display: "flex",
    flexDirection: "row",
    zIndex: 50,
    // marginTop: 8,
    // marginTop: 0,
    // marginLeft: 5,
    position: "relative",
    left: 11,
    top: -3,
    padding: 2,
    // borderBottomLeftRadius: 3,
    borderRadius: 25,
  },
  dropDownMenuLabel: {
    padding: 6,
    paddingRight: 0,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 10,
    color: "white",
  },
  dropDownMenuIcon: { zoom: "90%", color: "white" },
  editorObject: { height: "100%" },
  divider: {
    borderTop: "1px solid white",
    height: 1,
    borderBottom: "1px solid #CCC",
  },
};

export default style;
