import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 20,
      },
      smartphone: {
        padding: 5,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    color: theme.foregroundColor,
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    // padding: "20px 0",
    // backgroundColor: theme.highlightColor,
  },
};
