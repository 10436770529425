import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import settings01 from "./01/settings";
import settings02 from "./02/settings";
import settings03 from "./03/settings";
import settings04 from "./04/settings";
import settings05 from "./05/settings";
import settings06 from "./06/settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.WEB_LAYOUT && hook.type === "drawer") {
    return [
      settings01,
      settings02,
      settings03,
      settings04,
      // settings05,
      settings06,
    ];
  }
};
