import * as React from "react";
import Button from "@launchos/components/ui/Button";
import { CURSOR_ID } from "@launchos/modules/editor/Builder/WebComponent/types";
import theme from "@launchos/components/ui/theme";

export const EmptyState: React.FC = ({
  toggleAddNewDrawer = () => null,
  setActiveAddNewTab = () => null,
  hideProperties = () => null,
  settings = { type: "" },
  pageContent = [],
  children = null,
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const childContent = pageContent.filter((itm) => itm.parent === settings.id);
  const isEmptyWithCursor =
    childContent.length === 1 && childContent[0].id === CURSOR_ID;

  const vAlign: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  };

  return (
    <div style={{ height: "100%", ...vAlign }}>
      {isEmptyWithCursor ? (
        <div style={{ height: 95, ...vAlign }}>{children}</div>
      ) : (
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => {
            hideProperties();
            // alert("Test")
            setActiveAddNewTab("OBJECTS");
            toggleAddNewDrawer();
          }}
          style={{
            textAlign: "center",
            position: "relative",
            cursor: "pointer",
            maxWidth: 200,
            margin: "0 auto",
            padding: 15,
          }}
        >
          <Button
            style={{
              zoom: 0.85,
              marginBottom: 15,
              backgroundColor: isHovering ? theme.primaryColor : "#BBB",
              color: 'white'
            }}
            icon
          >
            add
          </Button>
          <p
            style={{
              color: "#AAA",
              //   textShadow: `1px 1px rgba(255, 255, 255, 0.5)`,
            }}
          >
            Click to add an object
          </p>
        </div>
      )}
    </div>
  );
};
