import { CSSProperties } from "react";
import { fieldset } from "@launchos/components/ui/theme";

/**
 * A collapsable color picker
 */
export const style: {
  fieldset: CSSProperties;
  input: CSSProperties;
  pickerElement: CSSProperties;
} = {
  fieldset,
  input: {
    backgroundColor: "transparent",
    border: "none",
    padding: 5,
    maxWidth: 135,
    outline: "none",
    margin: 10,
    color: "#FFF",
  },
  pickerElement: {
    width: 28,
    height: 28,
    marginLeft: 3,
    display: "inline-block",
    top: 8,
    cursor: "pointer",
    position: "relative",
    border: "1px solid transparent",
  },
};

export default style;
