import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 80,
    mobile: {
      desktop: { padding: 80 },
      smartphone: { padding: 0 },
    },
  },
  PRE_HEADLINE: {
    textAlign: "center",
    fontSize: "14pt",
    fontFamily: theme.fontFamily,
    color: "red",
    padding: 20,
    // color: theme.foregroundColor,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
    mobile: {
      desktop: { fontSize: "28pt" },
      smartphone: { fontSize: "18pt" },
    },
  },
  TEXT: {
    textAlign: "center",
    padding: 20,
    fontFamily: theme.fontFamily,
    color: theme.foregroundColor,
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
    padding: "100px 20px",
    background: theme.foregroundColor,
    position: "relative",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      background: theme.foregroundColor,
      width: 150,
      margin: "0 auto",
      borderRadius: 3,
      cursor: "pointer",
      marginTop: 50,
      color: "red",
      textAlign: "center",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  ICON: {
    color: theme.foregroundColor,
    fontSize: "10pt",
    border: `1px solid ${theme.foregroundColor}`,
    borderRadius: "100%",
    padding: 10,
  },
};
