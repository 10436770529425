export enum SocialProofLayouts {
  ONE = "SocialProof 01",
  TWO = "SocialProof 02",
  THREE = "SocialProof 03",
  FOUR = "SocialProof 04",
  FIVE = "SocialProof 05",
  SIX = "SocialProof 06",
  SEVEN = "SocialProof 07",
  EIGHT = "SocialProof 08",
  NINE = "SocialProof 09",
  TEN = "SocialProof 10",
  ELEVEN = "SocialProof 11",
  TWELVE = "SocialProof 12",
  THIRTEEN = "SocialProof 13",
  FOURTEEN = "SocialProof 14",
  FIFTEEN = "SocialProof 15",
}
