import { CSSProperties, ReactNode } from "react";
import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/types";
import { IPropertyBuilderSettings } from "@launchos/modules/v2/Properties/components/generator/types";

import {
  ComponentTypes,
  ISettings,
} from "@launchos/modules/editor/Builder/WebComponent/types";

export { ComponentTypes };

export interface IComponentSettings {
  id: string;
  name: string;
  category?: "element" | "layout" | "social";
  description?: string;
  thumbnail:
    | {
        image?: string;
        style?: CSSProperties;
        icon?: string | ReactNode;
        iconSource?: IconSource;
        iconStyle?: CSSProperties;
      }
    | string;
  default: ISettings;
  properties?: IPropertyBuilderSettings;
}
