import { CSSProperties } from "react";
import layoutTheme from "@launchos/plugins/misc/v2/blocks/weblayouts/theme";

export const style: {
  LISTITEM: {
    style: CSSProperties;
    iconStyle: CSSProperties;
    contentStyle: CSSProperties;
  };
} = {
  LISTITEM: {
    style: {
      fontFamily: layoutTheme.fontFamily,
      // padding: "10px 0",
    },
    iconStyle: {
      // borderRadius: "100%",
      padding: 10,
    },
    contentStyle: {
      padding: 10,
    },
  },
};
