import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Cell,
  Dialog,
  DialogBody,
  DialogFooter,
  Grid,
} from "@launchos/components/ui";

/**
 * This is the popup window that displays when a user attempts to publish their page
 * @param {object} props The props
 * @returns {function} The component
 */
class DeleteConfirmation extends Component {
  constructor() {
    super();
    window.scroll(0, 0);
  }

  render() {
    const {
      hidden,
      campaignData,
      deleteCampaign,
      deletedCampaign,
      onClose,
      deleted,
      deletePageMutation,
    } = this.props;

    if (!hidden) {
      const handleDeletePage = async (id) => {
        if (campaignData) {
          id = campaignData.id;
          await deleteCampaign({ variables: { id } });
          window.location.reload();
        } else {
          await deletePageMutation({ variables: { id } });
          deletedCampaign();
        }
      };

      return (
        <Dialog
          {...this.props}
          style={{ width: 700 }}
          centered
          // closeAction={onClose}
          closeHref="/dashboard/campaigns"
        >
          <DialogBody>
            <img
              alt=""
              style={{ margin: 10, width: 100 }}
              src="https://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/sign-delete-icon.png"
            />
            <h2>Delete ({campaignData.name}) Campaign</h2>
            <p style={{ fontWeight: "bold" }}>
              Warning: this cannot be undone.
            </p>
          </DialogBody>
          <DialogFooter>
            {deleted === true ? (
              <div>
                <h2>Your campaign has been deleted</h2>
                <Link to="/dashboard">
                  <Button secondary large style={{ margin: 10 }}>
                    Close
                  </Button>
                </Link>
              </div>
            ) : (
              <Grid justify="space-evenly">
                <Cell align="center">
                  <Button
                    large
                    secondary
                    onClick={() => handleDeletePage(campaignData.id)}
                  >
                    Yes, Delete this Campaign
                  </Button>
                </Cell>
                <Cell align="center">
                  <Button large style={{ color: "black" }} onClick={onClose}
                    transparent
                  >
                    Cancel, Keep this Campaign
                  </Button>
                </Cell>
              </Grid>
            )}
          </DialogFooter>
        </Dialog>
      );
    }
    return null;
  }
}
DeleteConfirmation.propTypes = {
  /** Boolean whether or not to display the section */
  hidden: PropTypes.bool,
  /** This is the url of the page that was just published */
  // url: PropTypes.string.isRequired
};
DeleteConfirmation.defaultProps = {
  hidden: true,
  url: "",
};

export default DeleteConfirmation;
