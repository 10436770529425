import React, { Component } from "react";
import { triggerHook } from "@launchos/plugins/client";
import {
  ComponentRenderHooks,
  ListItemsHooks,
  HookTriggers,
} from "@launchos/plugins/types";
import ItemDrawerTabContent from "@launchos/modules/campaign/AdvancedBuilder/ItemDrawerTabContent";

export default class DestinationNodesDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "New Pages",
      objects: [],
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.setObjects = this.setObjects.bind(this);
  }

  getObjects(activeTab) {
    return triggerHook(HookTriggers.onListItems, {
      id: ListItemsHooks.PAGE_OBJECTS,
      activeTab,
    });
  }

  setObjects(objects) {
    this.setState({ objects: [] }, () => this.setState({ objects }));
  }

  componentDidMount() {
    this.setState({
      objects: this.getObjects("New Pages"),
    });
  }

  setActiveTab(activeTab) {
    this.setState(
      {
        objects: [],
      },
      () => {
        this.setState({
          activeTab,
          objects: this.getObjects(activeTab),
        });
      }
    );
  }

  render() {
    const { activeTab, objects } = this.state;

    return (
      <ItemDrawerTabContent
        {...this.props}
        title={activeTab}
        objects={objects}
        hint="Click on a page and<br/>drag it to the canvas"
        tabs={[
          "New Pages",
          ...triggerHook(HookTriggers.onListItems, {
            id: ListItemsHooks.PAGES_DRAWER_TABS,
          }),
        ]}
        setObjects={this.setObjects}
        setActiveTab={this.setActiveTab}
        activeTab={activeTab}
      />
    );
  }
}
