import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 100,
      },
      smartphone: {
        padding: 20,
      },
    },
  },
  HEADLINE: {
    fontSize: "14pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 30,
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    fontSize: "10pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  NAVIGATION: {
    linkStyle: {
      fontFamily: theme.fontFamily,
      textAlign: "left",
      paddingBottom: 15,
      color: "black",
    },
  },
};
