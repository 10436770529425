import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CountDownTypes } from "@launchos/plugins/webcomponents/v2/Countdown/types";
import { UrgencyLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: UrgencyLayouts.FOUR,
  layout: UrgencyLayouts.FOUR,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/urgency/Urgency04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkztsx5q2llj0780vbppfmr4",
    itemId: "k96511wkrmi",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.URGENCY,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          mobile: {
            desktop: {
              width: 850,
            },
            smartphone: {
              width: "100%",
            },
          },
          textAlign: "center",
          display: "inline-block",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: "82% Discount available for...",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  display: "inline-block",
                  textAlign: "center",
                },
                children: [
                  {
                    type: ComponentTypes.COUNTDOWN,
                    countType: CountDownTypes.EVENT,
                    eventDate: Date.now() + 2 * 24 * 60 * 60 * 1000,
                    properties: {},
                    dimensionStyle: style.COUNTDOWN.dimensionStyle,
                    labelStyle: style.COUNTDOWN.labelStyle,
                    numberStyle: style.COUNTDOWN.numberStyle,
                    dividerStyle: style.COUNTDOWN.dividerStyle,
                    showDivider: false,
                    actions: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
