export default {
  paper: {
    width: 650,
    textAlign: "center",
  },
  button: {
    display: "block",
    margin: '0 auto',
  },
  logo: {
    display: "block",
    margin: "0 auto",
    padding: 30,
    height: 100
  },
  error: {
    padding: 15,
    background: "lightyellow",
    color: "red",
    marginBottom: 30
  }
};
