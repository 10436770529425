import { Button, Tab, Tabs } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";
import _ from "lodash";
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
// import { app } from "../../../config";
import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";


export default class TrackingScript extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: "",
      body: "",
      bodyEnd: "",
      activeTab: "head",
      loading: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitTracking = this.submitTracking.bind(this);
    this.switchTab = this.switchTab.bind(this);
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { getPageQuery, match } = this.props;

    const pageId = match.params.nodeId;

    const { data } = await getPageQuery.refetch({ pageId });

    const { pageSettings } = data.page;

    console.log({ pageSettings });

    this.setState({
      ..._.get(pageSettings, "tracking", {}),
      loading: false
    });
  }

  handleChange(key, value) {
    this.setState({
      [key]: value
    });
  }

  async submitTracking() {
    const { updatePageSettings, getPageQuery, history, match } = this.props;
    const { head, body, bodyEnd } = this.state;

    const { pageSettings, id } = getPageQuery.page;

    const variables = {
      pageId: id,
      pageSettings: {
        ...pageSettings,
        tracking: {
          head,
          body,
          bodyEnd
        }
      }
    };

    console.log({ variables });

    await updatePageSettings({ variables });

    history.push(
      `/campaign/${match.params.campaignId}/${match.params.nodeId}/builder`
    );
  }

  switchTab(activeTab) {
    console.log({ activeTab });
    this.setState({ activeTab });
  }

  render() {
    const { app } = this.context;
    const { activeTab, loading } = this.state;

    if (loading) return <img alt="" src={app.loadingImage} />;

    return (
      <div
        style={{
          overflowY: "scroll",
          textAlign: "left",
          width: 700,
          margin: "0 auto",
          padding: 20
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: 50 }}>
          Enter any custom scripts you want placed on this page
          {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Page Tracking' })}
        </h2>

        <Tabs transparent>
          <Tab
            key="head"
            active={activeTab === "head"}
            onClick={() => this.switchTab("head")}
          >
            {" "}
            &lt;Head&gt;
          </Tab>
          <Tab
            key="body"
            active={activeTab === "body"}
            onClick={() => this.switchTab("body")}
          >
            &lt;Body&gt;
          </Tab>
          <Tab
            key="bodyEnd"
            active={activeTab === "bodyEnd"}
            onClick={() => this.switchTab("bodyEnd")}
          >
            &lt;/Body&gt;
          </Tab>
        </Tabs>

        <div style={{ borderTop: `1px solid #CCC`, padding: 30 }}>
          {activeTab === "head" && (
            <div>
              <div style={{ textAlign: "center" }}>
                Enter any scripts you want attached to this page&#39;s
                &lt;HEAD&gt;
              </div>
              <CodeMirror
                value={_.get(this.state, "head")}
                className="cdmirror"
                options={{ mode: "javascript", lineNumbers: true }}
                onChange={editor => this.handleChange("head", editor)}
                autoFocus
              />
            </div>
          )}

          {activeTab === "body" && (
            <div>
              <div style={{ textAlign: "center" }}>
                Enter any scripts you want attached to this page&#39;s
                &lt;BODY&gt;
              </div>
              <CodeMirror
                value={_.get(this.state, "body")}
                className="cdmirror"
                options={{ mode: "javascript", lineNumbers: true }}
                onChange={editor => this.handleChange("body", editor)}
              />
            </div>
          )}

          {activeTab === "bodyEnd" && (
            <div>
              <div style={{ textAlign: "center" }}>
                Enter any scripts you want attached to this page&#39;s
                &lt;/BODY&gt;
              </div>
              <CodeMirror
                value={_.get(this.state, "bodyEnd")}
                className="cdmirror"
                options={{ mode: "javascript", lineNumbers: true }}
                onChange={editor => this.handleChange("bodyEnd", editor)}
              />
            </div>
          )}

          <div style={{ padding: 20, textAlign: "center" }}>
            <Button primary large onClick={this.submitTracking}>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
