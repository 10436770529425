import React from "react";
import { Button, Slider } from "@launchos/components/ui";
import theme from "@launchos/components/ui/theme";
import { IButton } from ".";
import style from "./style";

export const ButtonSlider: React.FC<{
  button1: IButton;
  button2: IButton;
  doManualUpdate: () => void;
  doChange: (e: any, value: any) => void;
  doFinalChange: (e: any, value: any) => void;
  sliderValue: string;
  minValue: React.ReactText;
  maxValue: React.ReactText;
  sliderContainerStyle: React.CSSProperties;
}> = ({
  button1,
  button2,
  doManualUpdate,
  doChange,
  doFinalChange,
  sliderValue,
  minValue,
  maxValue,
  sliderContainerStyle = {},
  label = null
}) => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {label && <span style={style.label}>{label}</span>}
      <div style={{ display: "flex", ...sliderContainerStyle }}>
        <div style={style.btnContainer}>
          <Button
            onClick={() => {
              doFinalChange(false, button1.value);
            }}
            icon
            style={{ color: theme.primaryColor, ...button2.style }}
            {...button1.events}
            transparent
          >
            {button1.icon}
          </Button>
        </div>
        <div style={style.sliderContainer} onDoubleClick={doManualUpdate}>
          <Slider
            data-testid="ButtonSliderGroup-slider"
            onChange={doChange}
            onChangeCommitted={doFinalChange}
            style={{ width: "100%" }}
            value={parseInt(sliderValue, 10)}
            valueLabelDisplay={Number.isInteger(sliderValue) ? "auto" : "off"}
            min={parseInt(minValue, 10)}
            max={parseInt(maxValue, 10)}
          />
        </div>
        <div style={style.btnContainer}>
          <Button
            onClick={() => {
              doFinalChange(false, button2.value);
            }}
            icon
            style={{ color: theme.primaryColor, ...button2.style }}
            {...button2.events}
            transparent
          >
            {button2.icon}
          </Button>
        </div>
      </div>
    </div>
  );
