import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // padding: 50,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  NAVIGATION: {
    linkStyle: {
      color: "white",
      listStyle: "none",
      display: "inline-block",
      padding: 30,
      fontFamily: "Roboto",
    },
  },
};
