import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/htmlmixed/htmlmixed";

import * as React from "react";

import CodeMirror from "react-codemirror";

import { Button } from "@launchos/components/ui";

import { EditorMode } from "@launchos/modules/v2/Editor/types";
import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

import htmlSettings from "./settings";
import { HTMLProps } from "./types";

const Properties = (props) => {
  const { updateComponentSettings, settings } = props;
  const { html = "" } = settings;
  const { properties } = htmlSettings;
  const { sections } = properties.main;

  const [currentHTML, setCurrentHTML] = React.useState<string>(html);

  const withCode = updateSection(sections, "custom_html", {
    component: (
      <div>
        <h4 style={{ padding: 20, textAlign: "center" }}>
          Please enter the HTML code you want to see in this position
        </h4>
        <CodeMirror
          // value={this.state.head}
          value={currentHTML}
          className="cdmirror"
          options={{ mode: "htmlmixed", lineNumbers: true }}
          onChange={(editor, data, value) => {
            // this.handleChange(editor, "head", value);
            setCurrentHTML(editor);
            // console.log(value, data, editor);
          }}
          autoFocus
        />
        <div style={{ textAlign: "center" }}>
          <Button
            secondary
            large
            style={{ margin: 10 }}
            onClick={() => {
              updateComponentSettings(settings.id, {
                ...settings,
                html: currentHTML,
              });
              props.hideProperties();
            }}
          >
            Save Settings
          </Button>
        </div>
      </div>
    ),
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withCode },
  };

  return (
    <PropertiesBuilder
      data={updatedProperties}
      {...props}
      title="HTML Settings"
      children={<div />}
    />
  );
};

export const HTML: React.FC<HTMLProps> = (props) => {
  const { html, style, mode = EditorMode.EDITOR, id } = props;
  if (mode === EditorMode.EDITOR)
    return (
      <EditorObject id={id} title="HTML" {...props} PropertiesView={Properties}>
        <div data-testid="WC-HTML-EDIT" style={style}>
          <div
            style={{
              padding: 5,
              textAlign: "center",
              color: "#999",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            <pre>&lt;HTML Code/&gt;</pre>
          </div>
        </div>
      </EditorObject>
    );
};

HTML.defaultProps = {
  style: {},
};

export default HTML;
