import { Button } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
// import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
// import "codemirror/mode/html";
import _ from "lodash";
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
// import { app } from "../../../config";
import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

export default class CSS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: "",
      body: "",
      bodyEnd: "",
      activeTab: "head",
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitCSS = this.submitCSS.bind(this);
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { getPageQuery, match } = this.props;

    const pageId = match.params.nodeId;

    const { data } = await getPageQuery.refetch({ pageId });

    const { pageSettings } = data.page;

    this.setState({
      css: _.get(pageSettings, "css", ""),
      loading: false,
    });
  }

  handleChange(key, value) {
    this.setState({
      [key]: value,
    });
  }

  async submitCSS() {
    const { updatePageSettings, getPageQuery, history, match } = this.props;
    const { css } = this.state;

    const { pageSettings, id } = getPageQuery.page;

    const variables = {
      pageId: id,
      pageSettings: {
        ...pageSettings,
        css,
      },
    };

    console.log({ variables });

    await updatePageSettings({ variables });

    history.push(
      `/campaign/${match.params.campaignId}/${match.params.nodeId}/builder`
    );
  }

  render() {
    const { app } = this.context;
    const { activeTab, loading } = this.state;

    if (loading) return <img alt="" src={app.loadingImage} />;

    return (
      <div
        style={{
          overflowY: "scroll",
          textAlign: "left",
          width: 700,
          margin: "0 auto",
          padding: 20,
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: 50 }}>
          CSS Settings
          {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'CSS' })}
        </h1>

        <h2 style={{ textAlign: "center", marginBottom: 50 }}>
          Enter any CSS you want placed on this page
        </h2>

        <div style={{ padding: 0 }}>
          <div>
            <CodeMirror
              value={_.get(this.state, "css")}
              className="cdmirror"
              options={{ mode: "css", lineNumbers: true }}
              onChange={(editor) => this.handleChange("css", editor)}
              autoFocus
            />
          </div>

          <div style={{ padding: 20, textAlign: "center" }}>
            <Button primary large onClick={this.submitCSS}>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
