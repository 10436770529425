import React from "react";
import { get } from "lodash";
import { TextInput } from "@launchos/components/ui";
import { fieldset } from "@launchos/components/ui/theme";

import style from "./style";
import { ISettings } from "@launchos/modules/editor/Builder/WebComponent/types";

export const VideoActions: React.FC<{
  settings: ISettings;
  updateComponentSettings: (
    id: string,
    settings: any,
    shouldDbUpdate?: boolean,
    debounceTimeout?: number
  ) => void;
}> = ({ settings, updateComponentSettings }) => {
  const handleChange = (e) =>
    updateComponentSettings(settings.id, {
      ...settings,
      triggerAfter: Number(e.target.value),
    });

  return (
    <fieldset style={fieldset} title="">
      <legend>Trigger this action after...</legend>
      <div style={style.videoActionContainer}>
        <TextInput
          style={style.videoActionText}
          value={get(settings, "triggerAfter", 0)}
          type="number"
          onBlur={handleChange}
          focusOnMount
          focusOnClick
        />
        <span style={style.videoActionSeconds}>seconds</span>
      </div>
    </fieldset>
  );
};
