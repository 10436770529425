export default [
  {
    type: "PageComponent",
    id: "blank_page",
    name: "Blank Page",
    // description: "Drag this page to the canvas - Blank Pages represent no specific page type. You're free to build anything with this page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_blank.jpg`,
    pageType: "blank"
  },
  {
    type: "PageComponent",
    id: "content_page",
    name: "Content Page",
    description: "Suggested for when you want to provide your customers/prospects with information and to build trust (e.g. blog posts, videos, or an article).",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_article.jpg`,
    pageType: "content"
  },
  {
    type: "PageComponent",
    id: "home_page",
    name: "Home Page",
    description: "Suggested for when you're building a ‘traditional-style’, “corporate” website.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_about.jpg`,
    pageType: "home"
  },
  {
    type: "PageComponent",
    id: "content_page",
    name: "Launch Page",
    description: "Suggested for when you are building anticipation for an upcoming sale.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_product.jpg`,
    pageType: "launch"
  },
  {
    type: "PageComponent",
    id: "email_squeeze_page",
    name: "Lead Capture Page",
    description: "Suggested for when you're capturing an email address or phone number.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_squeeze_email.jpg`,
    pageType: "lead"
  },
  {
    type: "PageComponent",
    id: "legal_page",
    name: "Legal Page",
    description: "Use this for Terms of Service, Privacy Policies, and other Legal Compliance needs.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_terms.jpg`,
    pageType: "legal"
  },
  {
    type: "PageComponent",
    id: "live_event_page",
    name: "Live Event Page",
    description: "Use this when hosting a live event.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_video.jpg`,
    pageType: "event"
  },
  {
    type: "PageComponent",
    id: "precart_checkout",
    name: "Pre-cart Checkout Page",
    description: "Suggested for use right before an Order Form so that you can capture the lead before your prospect makes their purchase.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_precart.jpg`,
    pageType: "checkout"
  },
  {
    type: "PageComponent",
    id: "order_page",
    name: "Order Form",
    description: "Use this when capturing payment information from a prospect.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_longform.jpg`,
    pageType: "order"
  },
  {
    type: "PageComponent",
    id: "sales_page",
    name: "Sales Page",
    description: "Exclusively dedicated to 'making the sale'. It is usually is placed immediately before an Order Form or a Pre-cart Checkout page.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales.jpg`,
    pageType: "sales"
  },
  {
    type: "PageComponent",
    id: "sales_page",
    name: "Special Offer (Upsell)",
    description: "Use this page immediately after a purchase has been made as a way to increase your average order value and maximize your profits.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_bonus.jpg`,
    pageType: "oto"
  },
  {
    type: "PageComponent",
    id: "thankyou_page",
    name: "Thank You Page",
    description: "Use this after your prospect completes a goal (e.g. immediately after a lead capture or sales page) to thank them for their business.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_ty_simple.jpg`,
    pageType: "thank you"
  },
  {
    type: "PageComponent",
    id: "webinar_page",
    name: "Event (Webinar) Registration",
    description: "Similar to a lead capture page, but specifically for capturing registration information for a live event (e.g. a webinar).",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_squeeze_webinar.jpg`,
    pageType: "webinar"
  }
];
