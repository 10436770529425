import * as React from 'react'
import { ConversionRateProps } from '../types';

const ConversionRate: React.FC<ConversionRateProps> = ({ midPoint = [0, 0], fill = "", value = 0 }) => {
  const lOfst = 5 + String(value).length * 4;

  return (
    <g>
      <circle cx={midPoint[0]} cy={midPoint[1]} r="20" fill={fill} />
      <text x={midPoint[0] - lOfst} y={midPoint[1] + 5} fill="white" style={{ fontSize: '11pt' }}>{value}%</text>
    </g>
  )
};

export default ConversionRate;