import { client } from "@launchos/api/gql/client";
import { deleteDomainOnServer } from "@launchos/api/gql/domains";
import _ from "lodash";

export default {
  handleDeleteDomain: async (props, id) => {
    const domainResponse = await props.deleteDomainMutation({
      variables: { id }
    });

    const domain = _.get(domainResponse, "data.deleteDomain.name", false);

    console.log({ domainResponse, domain });

    if (domain) {
      try {
        const resp = await client.mutate({
          mutation: deleteDomainOnServer,
          variables: {
            domain
          }
        });

        console.log('Response from deleteDomainServer', { resp });

      } catch (err) {
        console.log('Error from deleteDomainServer', { error: err });
      }
    }

    props.history.push("/settings/domains");
    window.location.reload();
  },

  domainMutationSubscriptionUpdate: (previousState, { subscriptionData }) => {
    if (subscriptionData.data.domain.mutation === "CREATED") {
      const newDomain = subscriptionData.data.domain.node;
      return {
        allDomains: previousState.domains.concat([newDomain])
      };
    } else if (subscriptionData.data.domain.mutation === "DELETED") {
      const oldId = subscriptionData.data.domain.previousValues.id;
      const oldDomainIndex = previousState.domains.findIndex(
        domain => domain.id === oldId
      );
      return {
        allDomains: [
          ...previousState.domains.slice(0, oldDomainIndex),
          ...previousState.domains.slice(oldDomainIndex + 1)
        ]
      };
    }
    return false;
  }
};
