import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 100,
    mobile: {
      desktop: { padding: 100 },
      smartphone: { padding: 10 },
    },
  },
  HEADLINE: {
    textAlign: "center",
    color: theme.foregroundColor,
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,
    mobile: {
      desktop: { fontSize: "32pt" },
      smartphone: { fontSize: "22pt" },
    },
  },
  TEXT: {
    textAlign: "center",
    color: theme.foregroundColor,
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    // padding: "20px 0",
    padding: 30,
  },
  VIDEO: {
    backgroundColor: theme.foregroundColor,
    color: theme.backgroundColor,
    // height: "100%",
  },
};
