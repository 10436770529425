import _ from "lodash";

import { client } from "@launchos/api/gql";
import { getCampaignObject } from "@launchos/api/gql/campaigns";
import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";

import CampaignSelector from "./CampaignSelector";
// import OpenDestinationPageInEditorPopup from "./OpenDestinationPageInEditorPopup";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.PAGES_DRAWER_TABS) {
    return ["Existing Pages"];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.PAGES_DRAWER && hook.activeTab === "Existing Pages") {
    return [CampaignSelector];
  }
};

export const onNodeCreate = async (
  hook,
  { item },
  { createPage, updateObjectSettings }
) => {
  if (hook.type === "PageShortcutComponent") {
    const { name, screenshot, id, page } = item;

    // id is the object id of the destination object (not existing obj)
    console.log("New Create Node", { item });
    // const url = `<%=page[${id}]%>`;

    const pageScript = `
      <script type="text/javascript">
        window.location.href = "/${page.slug}";
      </script>
    `;

    const response = await createPage({
      name,
      slug: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 6),
      screenshot,
      theme: "none",
      type: "redirect",
      content: [
        {
          id: 2540,
          parent: false,
          properties: { height: "100%" },
          type: "Body",
          canHaveChildren: true,
        },
      ],
      userId: localStorage.userId,
      pageSettings: {
        tracking: {
          head: pageScript,
        },
      },
    });

    updateObjectSettings({
      objectId: id,
    });

    return response;
  }
};

export const onNodeOpen = async (
  hook,
  { settings, campaignId },
  { showPopup, navigate }
) => {
  if (hook.type === "PageShortcutComponent") {
    const { objectId } = settings.settings;
    // get page id
    const { data } = await client.query({
      query: getCampaignObject,
      variables: {
        id: objectId,
      },
    });
    console.log({ settings, data });

    // ${_.get(data, "object.campaign.id")}
    // if (confirm("Open"))
    navigate(
      `/campaign/${campaignId}/${_.get(data, "object.page.id")}/builder`
    );
  }
};
