import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",

    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    fontSize: "14pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 20,
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    // padding: "20px 0"
  },
  NAVIGATION: {
    linkStyle: {
      fontFamily: theme.fontFamily,
      textAlign: "left",
      paddingBottom: 15,
      color: "black",
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 11,
      border: "none",
      backgroundColor: theme.backgroundColor,
      minWidth: 125,
      borderRadius: 5,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "100%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.backgroundColor}`,
      borderRadius: 5,
      color: theme.backgroundColor,
    },
  },
};
