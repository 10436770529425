import React from "react";
import { Link } from "react-router-dom";

import style from "../../../style";

export const TriggerPurchase = ({ campaignId, nodeId }) => (
    <Link to={`/campaign/${campaignId}/${nodeId}/merchant/index`}>
        <span style={style.linkStyle}>Open Payment Settings</span>
    </Link>
)
