import moment from "moment";
import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FooterLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FooterLayouts.THREE,
  layout: FooterLayouts.THREE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/footers/Footer03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzoubo42irl0780q5gtpfk3",
    itemId: "qldgl2k0p9",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 5, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  maxWidth: 350,
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 5,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.PICTURE,
                            canHaveChildren: false,
                            properties: {
                              // padding: 15,
                              width: 65,
                              maxWidth: "100%",
                              textAlign: "center",
                              display: "inline-block",
                            },
                            src: theme.logoIcon,
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((9 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 5,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              ...get(content, "sub_headline.style", {}),
                            },
                            html: `It's true.  Getting results is much easier than you think.  The truth is most people`,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 5, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.COLUMNS,
                canHaveChildren: false,
                properties: {
                  width: "100%",
                },
                children: [
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((6 * 100) / 12),
                    properties: { height: "100%", padding: 5, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          fontWeight: "bold" as "bold",
                        },
                        html: "Office",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html: `123 Elm Street<br />City, RI`,
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((6 * 100) / 12),
                    properties: { height: "100%", padding: 5, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          fontWeight: "bold" as "bold",
                        },
                        html: "Contacts",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html: `company@domain.com`,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
