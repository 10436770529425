import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";

import fbsettings from "@launchos/plugins/webcomponents/v2/FacebookLogin/settings";
import googlesettings from "@launchos/plugins/webcomponents/v2/GoogleLogin/settings";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.SEVEN,
  layout: FormLayouts.SEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form07.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyu67qy2e6c07805fbyk6cj",
    itemId: "vbjkrsizf2",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          display: "inline-block",
          textAlign: "center",
          mobile: {
            desktop: {
              width: 350,
            },
            smartphone: {
              width: "100%",
            },
          },
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
            },
            html: "Register Now!",
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
            },
            html: lorem.generateSentences(1),
          },
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              mobile: {
                desktop: {
                  marginTop: 50,
                },
                smartphone: {
                  marginTop: 5,
                },
              },
              maxWidth: 500,
              textAlign: "center",
              display: "inline-block",
            },
            children: [
              {
                type: ComponentTypes.FACEBOOKLOGIN,
                canHaveChildren: false,
                ...fbsettings.default,
                properties: {
                  ...fbsettings.default.properties,
                  marginTop: 5,
                  width: "100%",
                },
              },
              {
                type: ComponentTypes.GOOGLELOGIN,
                canHaveChildren: false,
                ...googlesettings.default,
                properties: {
                  ...googlesettings.default.properties,
                  marginTop: 5,
                  width: "100%",
                },
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  mobile: {
                    desktop: {
                      marginTop: 80,
                    },
                    smartphone: {
                      marginTop: 10,
                    },
                  },
                },
                html: `Already have an account? <a href='#' style='color: ${theme.foregroundColor}; text-decoration: underline'>Sign in</a>`,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
