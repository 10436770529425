import { Button, FontIcon } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
// import "react-vertical-timeline-component/style.min.css";
import "./timeline.css";

export default class TheTimeMachine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exitPopup: '{id:"false"}',
      loading: true,
    };

    this.submitHistorySettings = this.submitHistorySettings.bind(this);
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { getPageQuery, match } = this.props;

    const pageId = match.params.nodeId;

    const { data } = await getPageQuery.refetch({ pageId });

    const { pageSettings } = data.page;

    console.log({ pageSettings });

    try {
      this.setState({
        exitPopup: JSON.parse(_.get(pageSettings, "exitPopup", '{id:"false"}')),
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  async submitHistorySettings() {
    const { updatePageSettings, getPageQuery, history, match } = this.props;
    const { exitPopup } = this.state;

    const { pageSettings, id } = getPageQuery.page;

    const variables = {
      pageId: id,
      pageSettings: {
        ...pageSettings,
        exitPopup: JSON.stringify(exitPopup),
      },
    };

    console.log({ variables });
    // alert(JSON.stringify(variables));

    await updatePageSettings({ variables });

    history.push(
      `/campaign/${match.params.campaignId}/${match.params.nodeId}/builder`
    );
  }

  render() {
    const { app } = this.context;
    const { loading } = this.state;
    const { listComponents, pageContent } = this.props;

    if (loading) return <img alt="" src={app.loadingImage} />;

    const WorkIcon = () => <FontIcon>work</FontIcon>;
    const SchoolIcon = () => <FontIcon>school</FontIcon>;
    const StarIcon = () => <FontIcon>star</FontIcon>;

    const data = [
      {
        date: "2011 - present",
        iconComponent: <WorkIcon />,
        title: "Creative Director",
        subtitle: "Miami, FL",
        description:
          "Creative Direction, User Experience, Visual Design, Project Management, Team Leading",
      },
      {
        date: "2010 - 2011",
        iconComponent: <WorkIcon />,
        title: "Art Director",
        subtitle: "San Francisco, CA",
        description:
          "Creative Direction, User Experience, Visual Design, SEO, Online Marketing",
      },
    ];

    return (
      <div
        style={{
          overflowY: "scroll",
          textAlign: "left",
          maxWidth: 700,
          height: "100%",
          minHeight: 500,
          margin: "0 auto",
          padding: 20,
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: 50 }}>Time Machine</h1>

        <h2 style={{ textAlign: "center", marginBottom: 50 }}>
          The Time Machine allows you to restore your page to any point in
          history.
        </h2>

        <div style={{ backgroundColor: "inherit" }}>
          <VerticalTimeline animate={false}>
            {data.map(
              ({
                date = "",
                iconComponent = "",
                title = "",
                subtitle = "",
                description = "",
                image = "",
              }) => (
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    // background: "rgb(33, 150, 243)",
                    // color: "#fff",
                    color: "#CCC",
                  }}
                  contentArrowStyle={{
                    // borderRight: "7px solid  rgb(33, 150, 243)",
                    borderRight: "7px solid #CCC",
                  }}
                  date={date}
                  iconStyle={{
                    // background: "rgb(33, 150, 243)",
                    // color: "#fff"
                    background: "#CCC",
                    color: "#EEE",
                  }}
                  icon={iconComponent}
                >
                  <h3 className="vertical-timeline-element-title">{title}</h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    {subtitle}
                  </h4>
                  <p>{description}</p>
                </VerticalTimelineElement>
              )
            )}
          </VerticalTimeline>
        </div>

        <div style={{ padding: 0 }}>
          <div style={{ marginTop: 20, padding: 20, textAlign: "center" }}>
            <Button primary large onClick={this.submitHistorySettings}>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
