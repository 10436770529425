import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { SocialProofLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/SocialProof/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.FIVE,
  layout: SocialProofLayouts.FIVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkypng982by30780ld7yaetu",
    itemId: "zcm6fm77ln",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  borderRadius: 5,
                  padding: "0 30px",
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html: `"Hands down the best process I've seen in the last 11 years.  Thank you!! I really value your approach"`,
                  },
                  {
                    type: ComponentTypes.LISTITEM,
                    canHaveChildren: false,
                    html: `<p style="font-family: ${theme.fontFamily}; font-size: 12pt; padding: 0; text-align: left;"><strong>Jacob McDonald</strong></p><p style="font-family: ${theme.fontFamily}; font-size: 10pt; padding: 0; text-align: left;">VP Marketing</p>`,
                    icon: IconTypes.AccountCircle,
                    iconStyle: {
                      padding: 5,
                      paddingRight: 15,
                      fontSize: "28pt",
                    },
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  borderRadius: 5,
                  padding: "0 30px",
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html: `"Hands down the best process I've seen in the last 11 years.  Thank you!! I really value your approach"`,
                  },
                  {
                    type: ComponentTypes.LISTITEM,
                    canHaveChildren: false,
                    html: `<p style="font-family: ${theme.fontFamily}; font-size: 12pt; padding: 0; text-align: left;"><strong>Jacob McDonald</strong></p><p style="font-family: ${theme.fontFamily}; font-size: 10pt; padding: 0; text-align: left;">VP Marketing</p>`,
                    icon: IconTypes.AccountCircle,
                    iconStyle: {
                      padding: 5,
                      paddingRight: 15,
                      fontSize: "28pt",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
