import moment from "moment";
import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import {
  LinkDisplayTypes,
  LinkLayoutStyles,
} from "@launchos/plugins/webcomponents/v2/Navigation/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/live";
import {
  faFacebookF,
  faGoogle,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FooterLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FooterLayouts.ELEVEN,
  layout: FooterLayouts.ELEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/footers/Footer11.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzr76bj2k8b0780wdwiwpk7",
    itemId: "j00azs11vz",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: {
                  width: 50,
                },
                src: theme.logoIcon,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: { ...style.TEXT, textAlign: "right" },
                html: `It's true.  Getting results is much easier than you think.  The truth is most people simply don't know the`,
              },
            ],
          },
        ],
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 50,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  padding: 0,
                  fontSize: "9pt",
                  ...get(content, "sub_headline.style", {}),
                },
                html: `© ${moment().format("YYYY")} All Rights Reserved`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.NAVIGATION,
                canHaveChildren: false,
                linkStyle: style.NAVIGATION.linkStyle,
                layoutStyle: LinkLayoutStyles.HORIZONTAL,
                properties: {
                  textAlign: "right",
                  display: "inline-block",
                },
                data: [
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Twitter",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Facebook",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Instagram",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
