// Verson Two Web Components
// import * as Accordian from "./Accordian/plugin";
// import * as Animation from "./Animation/plugin";
// import * as Block from "./Block/plugin";
// import * as Body from "./Body/plugin";
import * as Button from "./Button/plugin";
import * as Carousel from "./Carousel/plugin";
// import * as Container from "./Container/plugin";
import * as Countdown from "./Countdown/plugin";
// import * as Cursor from "./Cursor/plugin";
import * as Divider from "./Divider/plugin";
import * as FacebookComments from "./FacebookComments/plugin";
import * as FacebookLogin from "./FacebookLogin/plugin";
import * as FormBuilder from "./FormBuilder/plugin";
import * as FormField from "./FormField/plugin";
import * as GoogleLogin from "./GoogleLogin/plugin";
// import * as Grid from "./Grid/plugin";
// import * as GridItem from "./GridItem/plugin";
// import * as Group from "./Group/plugin";
// import * as Headline from "./Headline/plugin";
import * as HTML from "./HTML/plugin";
import * as Icon from "./Icon/plugin";
// import * as Image from "./Image/plugin";
import * as List from "./List/plugin";
import * as ListItem from "./ListItem/plugin";
import * as Navigation from "./Navigation/plugin";
import * as OrderBump from "./OrderBump/plugin";
import * as OrderSummary from "./OrderSummary/plugin";
// import * as Popup from "./Popup/plugin";
import * as Progress from "./Progress/plugin";
// import * as Repeater from "./Repeater/plugin";
// import * as Shapes from "./Shapes/plugin";
// import * as ShareButton from "./ShareButton/plugin";
// import * as SmartContainer from "./SmartContainer/plugin";
import * as Spacer from "./Spacer/plugin";
// import * as Stacks from "./Stacks/plugin";
// import * as Step from "./Step/plugin";
// import * as Stepper from "./Stepper/plugin";
// import * as Switch from "./Switch/plugin";
import * as Tabs from "./Tabs/plugin";
// import * as Table from "./Table/plugin";
// import * as Text from "./Text/plugin";
// import * as Video from "./Video/plugin";
// import * as Row from "./Row/plugin";

// import * as Testing from "./Testing/plugin";

export default [
  // Accordian,
  // // Block,
  // Body,
  Button,
  // Container,
  Countdown,
  // Cursor,
  Divider,
  FacebookComments,
  FacebookLogin,
  GoogleLogin,
  FormBuilder,
  FormField,
  // Grid,
  // // GridItem,
  // // Group,
  // Headline,
  HTML,
  Icon,
  // Image,
  List,
  ListItem,
  Navigation,
  OrderBump,
  OrderSummary,
  // // Popup,
  Progress,
  // Repeater,
  // Shapes,
  // ShareButton,
  // SmartContainer,
  Spacer,
  // Stacks,
  // // Step,
  // Stepper,
  // Switch,
  Tabs,
  Carousel,
  // Table,
  // Text,
  // // // Video,
  // Testing,
  // Row,
];
