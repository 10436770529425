import * as React from "react";

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  VKShareButton,
  ViberShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import {
  FacebookShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
} from "react-share";
import { ShareButtonProps, ShareButtonTypes } from "./types";

export const ShareButton: React.FC<ShareButtonProps> = ({
  url,
  children,
  onClick,
  onComplete,
  shareButtonType,
  subject,
  content,
  hashtags,
  showShareCount,
}) => {
  return (
    <div data-testid="WC-SHAREBUTTON-LIVE">
      {shareButtonType === ShareButtonTypes.EMAIL && (
        <EmailShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          subject={subject}
          body={content}
        >
          {children(false)}
        </EmailShareButton>
      )}
      {shareButtonType === ShareButtonTypes.FACEBOOK && (
        <FacebookShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          quote={content}
          hashtag={hashtags}
        >
          <FacebookShareCount url={url}>
            {(shareCount) =>
              showShareCount ? children(shareCount) : children(false)
            }
          </FacebookShareCount>
        </FacebookShareButton>
      )}
      {shareButtonType === ShareButtonTypes.INSTAPAPER && (
        <InstapaperShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          description={content}
        >
          {children(false)}
        </InstapaperShareButton>
      )}
      {shareButtonType === ShareButtonTypes.LINE && (
        <LineShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
        >
          {children(false)}
        </LineShareButton>
      )}
      {shareButtonType === ShareButtonTypes.LINKEDIN && (
        <LinkedinShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          summary={content}
          source={url}
        >
          {children(false)}
        </LinkedinShareButton>
      )}
      {shareButtonType === ShareButtonTypes.LIVEJOURNAL && (
        <LivejournalShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          description={content}
        >
          {children(false)}
        </LivejournalShareButton>
      )}
      {shareButtonType === ShareButtonTypes.MAILRU && (
        <MailruShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          description={content}
          imageUrl={url}
        >
          {children(false)}
        </MailruShareButton>
      )}
      {shareButtonType === ShareButtonTypes.OK && (
        <OKShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          description={content}
          image={url}
        >
          <OKShareCount url={url}>
            {(shareCount) =>
              showShareCount ? children(shareCount) : children(false)
            }
          </OKShareCount>
        </OKShareButton>
      )}
      {shareButtonType === ShareButtonTypes.PINTEREST && (
        <PinterestShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          media={url}
          description={content}
        >
          <PinterestShareCount url={url}>
            {(shareCount) =>
              showShareCount ? children(shareCount) : children(false)
            }
          </PinterestShareCount>
        </PinterestShareButton>
      )}
      {shareButtonType === ShareButtonTypes.POCKET && (
        <PocketShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
        >
          {children(false)}
        </PocketShareButton>
      )}
      {shareButtonType === ShareButtonTypes.REDDIT && (
        <RedditShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
        >
          <RedditShareCount url={url}>
            {(shareCount) =>
              showShareCount ? children(shareCount) : children(false)
            }
          </RedditShareCount>
        </RedditShareButton>
      )}
      {shareButtonType === ShareButtonTypes.TELEGRAM && (
        <TelegramShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
        >
          {children(false)}
        </TelegramShareButton>
      )}
      {shareButtonType === ShareButtonTypes.TUMBLR && (
        <TumblrShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          caption={content}
          tags={hashtags}
        >
          <TumblrShareCount url={url}>
            {(shareCount) =>
              showShareCount ? children(shareCount) : children(false)
            }
          </TumblrShareCount>
        </TumblrShareButton>
      )}
      {shareButtonType === ShareButtonTypes.TWITTER && (
        <TwitterShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          via={content}
          hashtags={hashtags}
        >
          {children(false)}
        </TwitterShareButton>
      )}
      {shareButtonType === ShareButtonTypes.VIBER && (
        <ViberShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
        >
          {children(false)}
        </ViberShareButton>
      )}
      {shareButtonType === ShareButtonTypes.VK && (
        <VKShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
          image={url}
        >
          <VKShareCount url={url}>
            {(shareCount) =>
              showShareCount ? children(shareCount) : children(false)
            }
          </VKShareCount>
        </VKShareButton>
      )}
      {shareButtonType === ShareButtonTypes.WHATSAPP && (
        <WhatsappShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          title={subject}
        >
          {children(false)}
        </WhatsappShareButton>
      )}
      {shareButtonType === ShareButtonTypes.WORKPLACE && (
        <WorkplaceShareButton
          beforeOnClick={onClick}
          onShareWindowClose={onComplete}
          url={url}
          quote={content}
          hashtag={hashtags}
        >
          {children(false)}
        </WorkplaceShareButton>
      )}
    </div>
  );
};

ShareButton.defaultProps = {
  children: () => <div>Share Now</div>,
  subject: "",
  content: "",
  url: "",
  showShareCount: false,
  type: ShareButtonTypes.FACEBOOK,
};

export default ShareButton;
