import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { CTALayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.SIXTEEN,
  layout: CTALayouts.SIXTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA16.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "amxogtycdgh",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: `Learn the Simple Strategy I used to Rank #1 for "Online Marketing"`,
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "sub_headline.style", {}),
        },
        html: lorem.generateSentences(3),
      },
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 500,
          width: "100%",
          textAlign: "center",
          display: "inline-block",
        },
        children: [
          {
            type: ComponentTypes.FORMFIELD,
            canHaveChildren: false,
            styles: {
              containerStyle: style.FORM_FIELD.style,
              inputStyle: style.FORM_FIELD.inputStyle,
            },
            attributes: {
              placeholder: "Enter Your Primary Email Here...",
              name: "email",
            },
            fieldType: FormFieldTypes.TEXT,
          },
          {
            type: ComponentTypes.BUTTON,
            canHaveChildren: false,
            properties: style.BUTTON.style,
            caption1: "Yes! I would like to learn more",
            caption1style: style.BUTTON.caption1,
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
            },
            html: `<a href="#">No Thanks! I don't want this amazing opportunity</a>`,
          },
        ],
      },
    ],
  },
};

export default settings;
