import React from "react";
import { Menu } from "@launchos/components/ui";
import style from "../../../style";


export const NiceMenu = (props) => (
    <div style={{ ...style.selectField, cursor: "pointer", ...props.style }}>
        <Menu {...props} style={{ width: "100%", textAlign: "left" }}>
            <div style={{ maxHeight: 200, overflowY: "scroll" }}>
                {props.children}
            </div>
        </Menu>
    </div>
);
