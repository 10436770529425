import { VideoActions } from "@launchos/components/v2/VideoTutorial/types";

export const welcome = [
  {
    id: "welcome",
    title: "From Zero to Hero » Welcome to 10 Minute Funnels",
    seconds: [0, Number(1 * 60 + 30)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "what_is_a_funnel",
    title: "From Zero to Hero » What is a Funnel?",
    seconds: [Number(1 * 60 + 30), Number(3 * 60 + 16)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "micro_funnel",
    title: "From Zero to Hero » What are Micro Funnels?",
    seconds: [Number(3 * 60 + 16), Number(3 * 60 + 55)],
    action: VideoActions.NONE,
  },
  {
    id: "choosing_a_funnel",
    title: "From Zero to Hero » How to Choose a Funnel",
    seconds: [Number(3 * 60 + 55), Number(6 * 60 + 7)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "funnel_builder",
    title: "From Zero to Hero » Introducing the Funnel Builder",
    seconds: [Number(6 * 60 + 7), Number(7 * 60 + 38)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "page_builder",
    title: "From Zero to Hero » Building Pages in 10 Minute Funnels",
    seconds: [Number(7 * 60 + 38), Number(8 * 60 + 36)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "customize_template",
    title: "From Zero to Hero » Customizing the Template",
    seconds: [Number(8 * 60 + 36), Number(11 * 60 + 38)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "financial_advisor",
    title: "From Zero to Hero » The Financial Advisor Page",
    seconds: [Number(11 * 60 + 38), Number(12 * 60 + 59.5)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "emails",
    title: "From Zero to Hero » How to Connect to Your CRM",
    seconds: [Number(12 * 60 + 59.5), Number(14 * 60 + 20)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "tracking",
    title: "From Zero to Hero » Tracking Funnel Performance",
    seconds: [Number(14 * 60 + 20), Number(15 * 60 + 41)],
    action: VideoActions.NONE,
  },
  {
    id: "merchant_1",
    title: "From Zero to Hero » Creating a Sales Funnel",
    seconds: [Number(15 * 60 + 41), Number(16 * 60 + 34)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "merchant_2",
    title: "From Zero to Hero » Connecting to your Merchant Account",
    seconds: [Number(16 * 60 + 34), Number(18 * 60 + 44)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "domains",
    title: "From Zero to Hero » How to Connect to a Custom Domain Name ",
    seconds: [Number(18 * 60 + 44), Number(21 * 60 + 5)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "conclusion",
    title: "From Zero to Hero » Conclusion (and Getting Support)",
    seconds: [Number(21 * 60 + 5), Number(21 * 60 + 50)],
    action: VideoActions.MINIMIZE,
  },
];

export const testing = [
  {
    id: "emails",
    title: "From Zero to Hero » How to Connect to Your CRM",
    seconds: [Number(12 * 60 + 59.5), Number(14 * 60 + 20)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "tracking",
    title: "From Zero to Hero » Tracking Funnel Performance",
    seconds: [Number(14 * 60 + 20), Number(15 * 60 + 41)],
    action: VideoActions.NONE,
  },
  {
    id: "merchant_1",
    title: "From Zero to Hero » Creating a Sales Funnel",
    seconds: [Number(15 * 60 + 41), Number(16 * 60 + 34)],
    action: VideoActions.MINIMIZE,
  },
  {
    id: "merchant_2",
    title: "From Zero to Hero » Connecting to your Merchant Account",
    seconds: [Number(16 * 60 + 34), Number(18 * 60 + 44)],
    action: VideoActions.MINIMIZE,
  },
];

export default { welcome, testing };
