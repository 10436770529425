import { Button } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React from "react";
// import { app } from "../../config";
import AvatarButton from "../settings/AvatarButton";

const leftActionsGroup = [
  props => (
    <Button style={{ color: "inherit" }} icon onClick={props.toggleAppDrawer}>
      menu
    </Button>
  )
];

const rightActionsGroup = [
  props => (
    <AppConfig.Consumer>
      {({ app }) => (app.showAvatar ? <AvatarButton {...props} /> : null)}
    </AppConfig.Consumer>
  )
];

export { leftActionsGroup, rightActionsGroup };
