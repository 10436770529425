export default {
  dragDropCursorHorizontal: {
    width: "100%",
    minWidth: 100,
    marginTop: -2,
    height: 4,
  },
  dragDropCursorVertical: {
    height: "100%",
    // minWidth: 100,
    // marginTop: -2,
    width: 4,
  },
};
