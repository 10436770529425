import * as React from "react";
import { IBodyProps, PluginProps } from "./types";
import { ComponentTypes } from "../../types";
// import style from "./style";

export const Body: React.FC<IBodyProps> = ({
  properties = {},
  style = {},
  testId = "",
  children,
}) => {
  return (
    <div data-testid={testId} style={{ ...style, ...properties }}>
      {/* This is a test!! */}
      {children}
    </div>
  );
};

const BodyPluginComponent: React.FC<PluginProps> = ({ settings }) => {
  return <Body {...settings} />;
};

export const onComponentRender = (hook, payload, actions) => {
  if (hook.if === "webcomponent" && payload.type === ComponentTypes.BODY) {
    return [BodyPluginComponent];
  }
};

export default Body;
