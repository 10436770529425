export default {
  // background: "green",
  padding: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: 1400,
  margin: "0 auto",
};
