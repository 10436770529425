import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import settings from "./settings";

import HTML from "./component";
import { ComponentTypes } from "../../types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
    // return [
    //   {
    //     id: ComponentTypes.HTML,
    //     name: "html",
    //     description: "",
    //     thumbnail: "comp_html.png",
    //     default: {
    //       type: "Html",
    //       properties: {
    //         width: "100%",
    //       },
    //     },
    //     properties: {
    //       tabs: ["Basic"],
    //       sections: [
    //         {
    //           tab: "Basic",
    //           type: SectionTypes.TYPOGRAPHY,
    //           settings: {
    //             isExpanded: true,
    //           },
    //         },
    //       ],
    //     },
    //   },
    // ];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.HTML
  ) {
    return [HTML];
  }
};
