import { Button, Cell, Grid, Toolbar, HelpButton } from "@launchos/components/ui";
import { FixedPositions } from "@launchos/components/ui/types";

import theme from "@launchos/components/ui/theme";
import { AppConfig } from "@launchos/modules/app";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppDrawer from "../appdrawer";
import CampaignItem from "./CampaignItem";
import DeleteConfirmation from "./DeleteConfirmation";
import functions from "./functions";
import style from "./style";
import { leftActionsGroup, rightActionsGroup } from "./ToolbarComponents";
import {
  ShareLinkPopup,
  ShareCampaignConfirmationPopup,
} from "./ShareLinkComponents";
import { client } from "@launchos/api/gql";
import { cloneCampaign } from "@launchos/api/gql/campaigns";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";
import { triggerHook } from "@launchos/plugins/client";


const Campaigns = (props) => (
  <AppConfig.Consumer>
    {({ app }) => (
      <div>
        <Grid style={style.mainContent}>
          <Cell>
            <h2 style={{ marginBottom: 20 }}>My Funnels</h2>
          </Cell>
          <Cell>
            {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Dashboard' })}
          </Cell>
        </Grid>
        <Grid style={style.mainContent} justify="start" wrap>
          {props.campaignData.map((campaign, key) => (
            <Cell key={key} style={style.campaignItemCell}>
              <CampaignItem
                id={campaign.id}
                name={campaign.name}
                campaign={campaign}
                thumbnail={app.logoIcon}
                onDeleteCampaignItem={props.toggleDeleteConfirmationPopup}
                onDuplicateCampaignItem={functions.onDuplicateCampaignItem}
                queryProps={props.queryProps}
                cloneCampaign={props.cloneCampaign}
                getCampaignShareLink={props.getCampaignShareLink}
              />
            </Cell>
          ))}
        </Grid>
      </div>
    )}
  </AppConfig.Consumer>
);

Campaigns.defaultProps = {
  campaignData: false,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appDrawerVisible: false,
      deleteConfirmationVisible: false,
      shareLinkPopupVisible: false,
      activeCampaignData: {},
    };

    this.toggleAppDrawer = this.toggleAppDrawer.bind(this);
    this.toggleDeleteConfirmationPopup = this.toggleDeleteConfirmationPopup.bind(
      this
    );
    this.cloneCampaign = this.cloneCampaign.bind(this);
    this.getCampaignShareLink = this.getCampaignShareLink.bind(this);
  }

  toggleAppDrawer() {
    this.setState((prevState) => ({
      appDrawerVisible: !prevState.appDrawerVisible,
    }));
  }

  toggleDeleteConfirmationPopup(campaign) {
    window.scrollTo(0, 0);

    this.setState((prevState) => ({
      deleteConfirmationVisible: !prevState.deleteConfirmationVisible,
      activeCampaignData: campaign,
    }));
  }

  async cloneCampaign(id, userId) {
    await client.mutate({
      mutation: cloneCampaign,
      variables: {
        id,
        userId,
      },
    });
  }

  getCampaignShareLink(activeCampaignData) {
    this.setState({
      shareLinkPopupVisible: true,
      activeCampaignData,
    });
  }

  static contextType = AppConfig;

  render() {
    const { app } = this.context;

    if (this.props.allCampaignsQuery.loading) {
      return (
        <div style={{ textAlign: "center" }}>
          <img src={app.loadingImage} alt="Loading..." />
        </div>
      );
    }

    if (this.props.allCampaignsQuery.error) {
      return (
        <div style={{ textAlign: "center" }}>
          {console.log(this.props.allCampaignsQuery.error)}
          <img src={app.errorImage} alt="Connection Error" />
        </div>
      );
    }

    const { campaigns } = this.props.allCampaignsQuery;

    return (
      <div>
        <DeleteConfirmation
          hidden={!this.state.deleteConfirmationVisible}
          deleted={this.state.deleted}
          deletedCampaign={this.deletedCampaign}
          onClose={this.toggleDeleteConfirmationPopup}
          onDeletePage={this.handleDeletePage}
          campaignData={this.state.activeCampaignData}
          url=""
          {...this.props}
        />
        {this.props.show === "share" && (
          <ShareCampaignConfirmationPopup
            {...this.props}
            closeHref="/dashboard/campaigns"
            cloneCampaign={this.cloneCampaign}
          />
        )}
        {this.state.shareLinkPopupVisible && (
          <ShareLinkPopup
            onClose={() => this.setState({ shareLinkPopupVisible: false })}
            campaignData={this.state.activeCampaignData}
            cloneCampaign={this.cloneCampaign}
          />
        )}
        <AppDrawer
          hidden={!this.state.appDrawerVisible}
          toggleAppDrawer={this.toggleAppDrawer}
        />
        <Toolbar
          leftActionsGroup={leftActionsGroup}
          rightActionsGroup={rightActionsGroup}
          toggleAppDrawer={this.toggleAppDrawer}
        />

        {campaigns.length ? (
          <Grid justify="center" style={{ marginTop: 50 }}>
            <Campaigns
              campaignData={campaigns}
              toggleDeleteConfirmationPopup={this.toggleDeleteConfirmationPopup}
              queryProps={this.props}
              cloneCampaign={this.cloneCampaign}
              getCampaignShareLink={this.getCampaignShareLink}
            />
            <Link to="/catalog/funnels">
              <Button
                style={{
                  position: "fixed",
                  zIndex: 300,
                  boxShadow:
                    "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)",
                }}
                icon
                secondary
                fixedPosition={FixedPositions.BOTTOM_RIGHT}
              >
                add
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid justify="center" align="center" style={{ marginTop: 60 }}>
            <Cell style={{ textAlign: "center" }} align="center">
              <h1 style={{ padding: 15, color: theme.darkColor }}>
                Create your first campaign
              </h1>
              <p style={{ fontSize: 14, color: "#666" }}>
                Welcome to the Funnel Builder, let's start by creating your
                first campaign.
              </p>
              <img
                style={{ width: "33%", margin: '0 auto', display: "block" }}
                alt="Blank State"
                src="https://s3.amazonaws.com/sandcastleassets/images/emptystate1.svg"
              />
              <Link to="/catalog/funnels">
                <Button secondary large style={{ margin: 10 }}>
                  Create a new Campaign
                </Button>
              </Link>
            </Cell>
          </Grid>
        )}
      </div>
    );
  }
}

export default Dashboard;
