import * as React from "react";
import { ContainerProps } from "./types";

export const Container: React.FC<ContainerProps> = ({
  children,
  style,
  properties,
  mode,
  id,
}) => {
  return (
    <div
      data-testid="WC-CONTAINER-LIVE"
      style={{ maxWidth: "100%", ...style, ...properties }}
    >
      {children}
    </div>
  );
};

export default Container;
