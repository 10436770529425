import _ from "lodash";
import SelectPagePopup from "./SelectPagePopup";
import { client } from "@launchos/api/gql";
import { getCampaignObject } from "@launchos/api/gql/campaigns";
import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.DESTINATION_OBJECTS && hook.activeTab === "Common") {
    return [
      {
        type: "AnotherFunnelComponent",
        name: "Another Funnel",
        description: "Creates a link that automatically routes its visitors to a page in another funnel",
        screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_anotherfunnel.png`,
        width: 150,
        height: 100,
      },
    ];
  }
};

export const onNodeCreate = async (hook, { item }, { createPage }) => {
  if (hook.type === "AnotherFunnelComponent") {
    const { name, screenshot } = item;

    const pageScript = `
      <script type="text/javascript">
        window.location.href = "#";
      </script>
    `;

    const response = await createPage({
      name,
      slug: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 6),
      screenshot,
      theme: "none",
      type: "redirect",
      content: [
        {
          id: 2540,
          parent: false,
          properties: { height: "100%" },
          type: "Body",
          canHaveChildren: true,
        },
      ],
      userId: localStorage.userId,
      pageSettings: {
        tracking: {
          head: pageScript,
        },
      },
    });

    console.log({ response });

    return response;
  }
};

export const onNodeOpen = async (
  hook,
  { settings },
  { showPopup, updateObject, updatePageSettings, updateObjectSettings }
) => {
  if (hook.type === "AnotherFunnelComponent") {
    showPopup(SelectPagePopup, settings, {
      updateObject,
      updatePageSettings,
      updateObjectSettings,
    });
  }
};
