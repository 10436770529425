import * as React from "react";
import { get } from 'lodash';
import { ColorPicker } from "@launchos/modules/editor/Builder/Properties";
import { doColorConversion } from '@launchos/modules/v2/Properties/components/groups/ColorPicker/scripts'

import { Tabs, Tab } from "@material-ui/core";

export interface ColorPickerSectionProps {
  updateComponentSettings: (
    id: string,
    properties: any,
    dbUpdate?: boolean,
    forceUpdate?: number
  ) => any;
  updateComponentStyle: (
    id: string,
    properties: any,
    dbUpdate?: boolean,
    forceUpdate?: number
  ) => any;
  settings: any;
}

export const ColorPickerSection: React.FC<ColorPickerSectionProps> = ({
  updateComponentSettings,
  updateComponentStyle,
  settings,
}) => {

  const [value, setValue] = React.useState<string>(0);
  const switchTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getColor = (color) => {

    let rgb = get(color, "rgb", doColorConversion(color.hex, "rgb"));
    return `rgb(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`; // we use rgb() vs rgba() bc alpha still works and hex converter (for input field) won't convert to hex otherwise

  }

  return (
    <div onMouseDown={e => e.stopPropagation()}>
      <Tabs value={value} onChange={switchTab}>
        <Tab label="Normal" value={0} />
        <Tab label="Hovered" value={1} />
      </Tabs>
      <div style={{ padding: 10 }}>
        {value === 0 && (
          <ColorPicker
            showAlpha
            onChange={(e) =>
              updateComponentStyle(
                settings.id,
                { backgroundColor: getColor(e) },
                true,
                0
              )
            }
          />
        )}

        {value === 1 && (
          <ColorPicker
            showAlpha
            onChange={(e) =>
              updateComponentSettings(
                settings.id,
                {
                  ...settings,
                  hoverStyle: {
                    ...settings.hoverStyle,
                    backgroundColor: getColor(e),
                  },
                },
                true,
                0
              )
            }
          />
        )}
      </div>
    </div>
  );
};
