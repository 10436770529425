import * as React from "react";
import moment from "moment";

import { TextInput } from "@launchos/components/ui";
import { DateValueProps } from "./types";

export const DateValue: React.FC<DateValueProps> = ({
  value = new Date(),
  onChange = () => null,
}) => {
  return (
    <div data-testid="DateValue-LIVE">
      {/* <input type="date" value={moment(value).format("YYYY-MM-DD")} onChange={e => onChange(e.target.value)} /> */}
      <TextInput
        type="date"
        value={moment(value).format("YYYY-MM-DD")}
        onChange={onChange}
      />
    </div>
  );
};

export default DateValue;
