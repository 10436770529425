import { client } from "@launchos/api/gql/client";
import { createSSLOnServer } from "@launchos/api/gql/domains";
import { Button, Cell, Grid, ListItem, MenuButton } from "@launchos/components/ui";
import { FixedPositions } from "@launchos/components/ui/types";
import theme from "@launchos/components/ui/theme";
import { AppConfig } from "@launchos/modules/app";
import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import functions from "../functions";
import style from "../style";
import DomainDeleteConfirmation from "./DomainDeleteConfirmation";
import DomainSetupPopup from "./DomainSetup";
import SSLConfirmation from "./SSLConfirmation";

const DomainSectionEmpty = () => (
  <Grid
    justify="center"
    align="center"
    style={{ height: "60vh", marginTop: 80 }}
  >
    <Cell style={{ textAlign: "center" }} align="center">
      <h1 style={{ padding: 15, color: theme.darkColor }}>
        Add a custom domain name
      </h1>
      <p style={{ fontSize: 14, color: "#666" }}>
        You don&#39;t have any domain names added yet.
        <br />
        Click the button below to add your first custom domain name.
      </p>
      <img
        alt=""
        style={{ display: "block", width: 550, marginBottom: 50 }}
        src="https://s3.amazonaws.com/sandcastleassets/images/emptystate4.svg"
      />
      <Grid justify="space-evenly" style={{ marginTop: 50 }}>
        <Cell align="center">
          <Link to="/settings/domains/add">
            <Button large secondary>
              Add a Domain Name
            </Button>
          </Link>
        </Cell>
      </Grid>
    </Cell>
  </Grid>
);

const DomainItem = (props) => (
  <Grid justify="space-between" style={style.tableGroup}>
    <Cell align="left" style={{ width: "70%", marginTop: 7 }}>
      <span style={{ fontSize: 15, color: theme.darkColor }}>
        {props.data.name}
      </span>
      <br />
      <span style={{ fontSize: 15, color: "#999" }}>
        Added {moment(props.data.createdAt).fromNow()}
      </span>
    </Cell>
    <Cell align="center">
      <MenuButton icon="more_horiz">
        <Link to={`/settings/domains/${props.data.id}/ssl`}>
          <ListItem>Secure This Domain (SSL)</ListItem>
        </Link>
        <Link to={`/settings/domains/${props.data.id}/delete`}>
          <ListItem>Disconnect This Domain</ListItem>
        </Link>
      </MenuButton>
    </Cell>
  </Grid>
);

const DomainSectionFull = (props) => (
  <AppConfig.Consumer>
    {(settings) => (
      <div>
        <DomainDeleteConfirmation
          {...props}
          hidden={props.action !== "delete"}
          closeHref="/settings/domains"
        />
        {props.action === "ssl" && (
          <SSLConfirmation {...props} closeHref="/settings/domains" />
        )}
        <Grid justify="center" align="center" style={{ marginTop: 80 }}>
          <Cell
            style={{ maxWidth: 980, width: 980, margin: "auto 20px" }}
            align="center"
          >
            <Grid>
              <Cell>
                <h2>My Domain Names</h2>
              </Cell>
              <Cell>
                {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Domains' })}
              </Cell>
            </Grid>

            <div style={{ marginTop: 30 }}>
              {props.allDomainsQuery.domains
                .filter(
                  (itm) => itm.name.indexOf(`.${settings.app.domain}`) === -1
                )
                .map((domain) => (
                  <DomainItem key={domain.id} data={domain} {...props} />
                ))}
            </div>

            <Link to="/settings/domains/add">
              <Button
                style={{
                  position: "fixed",
                  zIndex: 300,
                  boxShadow:
                    "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)",
                }}
                icon
                secondary
                fixedPosition={FixedPositions.BOTTOM_RIGHT}
              >
                add
              </Button>
            </Link>
          </Cell>
        </Grid>
      </div>
    )}
  </AppConfig.Consumer>
);

DomainSectionFull.defaultProps = {
  action: false,
};

/**
 * This is the section that displays the users list of domain names
 * @param {object} props The props (the list of domains and associated information)
 * @returns {function} The Domain Section component
 */
class DomainSection extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteDomain = functions.handleDeleteDomain.bind(this);
    this.performSSL = this.performSSL.bind(this);
  }

  static contextType = AppConfig;

  // componentDidMount() {
  //   this.props.allDomainsQuery.subscribeToMore({
  //     document: domainMutationSubscription,
  //     variables: {
  //       userId: localStorage.userId
  //     },
  //     updateQuery: functions.domainMutationSubscriptionUpdate,
  //     onError: err => console.log(err)
  //   });
  // }

  async performSSL(domainId, name) {
    console.log("Securing Domain Now...");

    const domain = name;
    const variables = {
      domain,
      email: localStorage.email,
    };

    const sslResponse = await client.mutate({
      mutation: createSSLOnServer,
      variables,
    });

    return _.get(sslResponse, "data.createSSLOnServer.response", {
      stdout: "errors",
    });
  }

  render() {
    const settings = this.context;

    if (this.props.allDomainsQuery.loading)
      return (
        <div style={{ textAlign: "center" }}>
          <img src={settings.app.loadingImage} alt="Loading..." />
        </div>
      );
    if (this.props.allDomainsQuery.error)
      return (
        <div style={{ textAlign: "center" }}>
          <img src={settings.app.errorImage} alt="Connection Error" />
        </div>
      );

    return (
      <div>
        <DomainSetupPopup
          {...this.props}
          step={
            this.props.match.params.step !== undefined
              ? this.props.match.params.step.toUpperCase()
              : false
          }
          hidden={this.props.match.params.step === undefined}
        />

        {!this.props.allDomainsQuery.domains.filter(
          (itm) => itm.name.indexOf(`.${settings.app.domain}`) === -1
        ).length && !(this.props.action && this.props.action === "add") ? (
          <DomainSectionEmpty {...this.props} />
        ) : (
          <DomainSectionFull
            {...this.props}
            onDeleteDomain={this.handleDeleteDomain}
            performSSL={this.performSSL}
          />
        )}
      </div>
    );
  }
}
DomainSection.propTypes = {
  /** Boolean whether or not to display the section */
  hidden: PropTypes.bool,
};
DomainSection.defaultProps = {
  hidden: false,
};

export default DomainSection;
