export default {
  toggleAppDrawer() {
    this.setState(prevState => ({
      appDrawerVisible: !prevState.appDrawerVisible
    }));
    return null;
  },

  createCampaignMutationUpdate: (previous, { subscriptionData }) => {
    const newAllCampaigns = [
      subscriptionData.data.createCampaign.node,
      ...previous.feed.campaign
    ];
    const result = {
      ...previous,
      feed: {
        Campaign: newAllCampaigns
      }
    };
    return result;
  },

  handleDeletePage: async id => {
    debugger;
    this.props.history.replace({ pathname: "/settings/domains" });
    await this.props.deletePageMutation({ variables: { id } });
  },

  returnPage: async (pageId, queryProps) => {
    const data = await queryProps.getPage.refetch({ pageId });
    return data;
  },

  onDeleteCampaignItem() {
    const id = this.props.id;
    this.props.deleteCampaign({ variables: { id } });
  },

  onRenameCampaignItem() {
    return null;
  },

  onDuplicateCampaignItem() {
    return null;
  },

  doPageEdit() {
    return null;
  },

  doPageDelete() {
    return null;
  },

  doPagePreview() {
    return null;
  },

  doPageRename() {
    return null;
  },

  doPageDuplicate() {}
};
