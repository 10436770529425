import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default (props) => (
  <Snackbar {...props}>
    <Alert severity={props.severity} onClose={props.onClose}>
      {props.children}
    </Alert>
  </Snackbar>
);
