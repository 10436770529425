import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",

    mobile: {
      desktop: {
        padding: 100,
      },
      smartphone: {
        padding: 20,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "center",
    padding: 20,
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
      margin: "20px auto 10px auto",
      textAlign: "center",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  BUTTON_CONTAINER: {
    maxWidth: 350,
    margin: "0 auto",
    mobile: {
      smartphone: {
        marginTop: 25,
      },
    },
  },
};
