import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Cell, DropDownSelector, Grid } from "@launchos/components/ui";
import style from "./style";

export default class MerchantChooseOffer extends Component {
  constructor(props) {
    super(props);

    const choice = props.providerSettings.payload.hasOwnProperty("offerId")
      ? props.providerSettings.payload.offerId
      : false;

    this.state = {
      list: [],
      choice
    };

    this.saveOfferSelection = this.saveOfferSelection.bind(this);
  }

  componentDidMount() {
    console.log("ive loaded", this.props.providerSettings.payload);
    // grab available offers
    const { getProviderOffers } = this.props;

    // get all campaigns
    getProviderOffers({
      variables: {
        provider: "limelight",
        payload: this.props.providerSettings.payload
      }
    }).then(response => {
      const offers = response.data.getOffers.response;
      console.log({ offers });
      if (offers.hasOwnProperty("data")) {
        const list = offers.data;
        this.setState({ list });
      } else {
        console.log("Could not find offers");
      }
    });
  }

  saveOfferSelection() {
    const { updateSettings, providerSettings, history } = this.props;
    const { choice } = this.state;

    // Save to State
    const payload = providerSettings.payload;
    updateSettings({
      ...payload,
      offerId: choice,
      offers: _.filter(
        this.state.list,
        offer => parseInt(offer.id, 10) === parseInt(choice, 10)
      )
    });
    // Show the Billing Screen
    history.push("./billing");
  }

  render() {
    if (!this.props.hidden) {
      return (
        <div style={{ width: 550, margin: "0 auto" }}>
          <h2>
            Select the offer(s) this checkout page should be associated with?
          </h2>
          <div style={{ margin: "30px 0", ...style.selectField }}>
            <DropDownSelector
              text="Click here to choose an offer..."
              showId
              items={this.state.list}
              choice={this.state.choice}
              onChange={choice => this.setState({ choice })}
            />
          </div>
          <Grid justify="space-evenly" style={{ marginTop: 30 }}>
            <Cell align="center">
              <Link to={this.props.prevHref}>
                <Button large transparent style={{ color: "#333" }}>
                  Back
                </Button>
              </Link>
              <Button large tertiary onClick={this.saveOfferSelection}>
                Next
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
