import {
  Button,
  Cell,
  CheckBox,
  Dialog,
  DialogBody,
  DialogFooter,
  Grid,
  ListItem,
  MenuButton
} from "@launchos/components/ui";
import theme from "@launchos/components/ui/theme";
// // import { app } from "../../../config";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import style from "../style";
import IntegrationEditor from "./IntegrationEditor";
import providers from "./integrationProviders";
import IntegrationsSelector from "./IntegrationsSelector";

import WebHookEditor from './IntegrationBuilder/CustomWebHookEditor'

import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";


/**
 * This is the popup window that displays when a user attempts to publish their page
 * @param {object} props The props
 * @returns {function} The component
 */
const DeleteConfirmation = props => {
  const {
    deleteIntegrationMutation,
    hideDisableConfirmation,
    allIntegrationQuery,
    integration
  } = props;

  const handleDelete = () => {
    deleteIntegrationMutation({
      variables: {
        id: integration.id
      }
    }).then(response => {
      console.log({ response });

      allIntegrationQuery
        .refetch({ userId: localStorage.userId })
        .then(response => {
          window.location.reload();
        });
      hideDisableConfirmation();
    });
  };

  console.log({ props });

  return (
    <Dialog
      {...props}
      style={{
        width: 700
      }}
      centered
      onClose={hideDisableConfirmation}
    >
      <DialogBody>
        <img
          style={{
            margin: 10,
            width: 100
          }}
          alt=""
          src="https://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/sign-delete-icon.png"
        />
        <h2>Would you like to disable this integration?</h2>
        <p
          style={{
            fontWeight: "bold"
          }}
        >
          Warning: this cannot be undone.
        </p>
      </DialogBody>
      <DialogFooter>
        <Grid justify="space-evenly">
          <Cell align="center">
            <Button
              onClick={handleDelete}
              secondary
              large
              style={{
                margin: 10
              }}
            >
              Yes, Disable This Integration
            </Button>
            <Button
              transparent
              large
              style={{
                margin: 10,
                color: "black"
              }}
              onClick={hideDisableConfirmation}
            >
              Cancel
            </Button>
          </Cell>
        </Grid>
      </DialogFooter>
    </Dialog>
  );
};

const IntegrationItem = props => {
  const { data } = props;
  let itm = _.head(_.filter(providers, p => p.id === data.type));

  if (data.type == "webhook") itm = {
    type: "email",
    id: "webhook",
    icon: data.setting.icon === "" ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtDJgneZ_dezqakp5msHb0dXVfaZdJ2lztRH1y_e_ZM-WOeFc" : data.setting.icon,
    caption: _.get(data, 'setting.name')
  }

  if (!itm) return null;

  // const img = props.data.type === "webhook" ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtDJgneZ_dezqakp5msHb0dXVfaZdJ2lztRH1y_e_ZM-WOeFc" : _.get(
  //   _.head(_.filter(providers, p => p.id === props.data.type)), "img", ""
  // )

  const img = itm.icon || _.get(_.head(_.filter(providers, p => p.id === props.data.type)), "img", "")

  // display correct icon
  // let img = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtDJgneZ_dezqakp5msHb0dXVfaZdJ2lztRH1y_e_ZM-WOeFc";
  // img = _.get(_.head(_.filter(providers, p => p.id === props.data.type)), "img", "")
  // if (props.data.type === "webhook" && props.data.setting.icon !== "") img == props.data.setting.icon;

  return (
    <Grid justify="space-between" style={style.tableGroup}>
      <Cell
        align="center"
        style={{
          width: "70%"
        }}
      >
        <Grid justify="start">
          <Cell align="center" style={{ width: 50, textAlign: "center" }}>
            <img
              alt=""
              style={{
                height: 50
              }}
              src={img}
            />
          </Cell>
          <Cell
            style={{
              marginLeft: 15,
              padding: 5
            }}
          >
            <span
              style={{
                fontSize: 15,
                color: theme.darkColor
              }}
            >
              {_.get(itm, "caption")}
            </span>
            <br />
            <span
              style={{
                fontSize: 15,
                color: "#999"
              }}
            >
              Updated {moment(props.data.updatedAt).fromNow()}
            </span>
          </Cell>
        </Grid>
      </Cell>
      <Cell align="center">
        <Grid justify="space-between">
          <Cell align="center">
            <CheckBox on />
          </Cell>
          <Cell align="center">Ready</Cell>
        </Grid>
      </Cell>
      <Cell align="center">
        <MenuButton icon="more_horiz">
          <ListItem onClick={() => props.showDisableConfirmation(props.data)}>
            Disable This Integration
          </ListItem>
          <ListItem onClick={() => {
            if (props.data.type === "webhook") props.showWebHookEditor(props.data)
            else props.showIntegrationEditor(props.data)
          }
          }>
            Edit This Integration
          </ListItem>
        </MenuButton>
      </Cell>
    </Grid>
  );
};

class IntegrationsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisableConfirmation: false,
      showIntegrationEditor: false,
      integration: false,
      showWebHookEditor: false,
    };

    this.showDisableConfirmation = this.showDisableConfirmation.bind(this);
    this.hideDisableConfirmation = this.hideDisableConfirmation.bind(this);
    this.showIntegrationEditor = this.showIntegrationEditor.bind(this);
    this.hideIntegrationEditor = this.hideIntegrationEditor.bind(this);
    this.showWebHookEditor = this.showWebHookEditor.bind(this);
    this.hideWebHookEditor = this.hideWebHookEditor.bind(this);
  }

  static contextType = AppConfig;

  showDisableConfirmation(integration) {
    this.setState({ showDisableConfirmation: true, integration }, () => {
      window.scrollTo(0, 0);
    });
  }

  hideDisableConfirmation(integration) {
    this.setState({ showDisableConfirmation: false, integration: false });
  }

  showIntegrationEditor(integration) {
    this.setState({ showIntegrationEditor: true, integration });
  }

  hideIntegrationEditor(integration) {
    this.setState({ showIntegrationEditor: false, integration: false });
  }

  showWebHookEditor(integration) {
    this.setState({ showWebHookEditor: true, integration });
  }

  hideWebHookEditor(integration) {
    this.setState({ showWebHookEditor: false });
  }

  render() {
    const { allIntegrationQuery } = this.props;
    const { app } = this.context;

    if (allIntegrationQuery.loading)
      return (
        <div
          style={{
            textAlign: "center"
          }}
        >
          <img src={app.loadingImage} alt="Loading..." />
        </div>
      );
    if (allIntegrationQuery.error)
      return (
        <div
          style={{
            textAlign: "center"
          }}
        >
          <img src={app.errorImage} alt="Connection Error" />
        </div>
      );

    return (
      <div>
        {this.state.showDisableConfirmation ? (
          <DeleteConfirmation
            {...this.props}
            hideDisableConfirmation={this.hideDisableConfirmation}
            integration={this.state.integration}
          />
        ) : null}
        {this.state.showIntegrationEditor ? (
          <IntegrationEditor
            {...this.props}
            hideIntegrationEditor={this.hideIntegrationEditor}
            integration={this.state.integration}
          />
        ) : null}
        {this.state.showWebHookEditor && (
          <WebHookEditor
            {...this.props}
            hideWebHookEditor={this.hideWebHookEditor}
            integration={this.state.integration}
          />
        )}
        <Grid
          justify="center"
          align="center"
          style={{
            marginTop: 80
          }}
        >
          <Cell
            style={{ maxWidth: 980, width: 980, margin: "auto 20px" }}
            align="center"
          >
            <Grid justify="space-between">
              <Cell>
                <h2>
                  My Integrations
                  {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'Integrations' })}
                </h2>
              </Cell>
              <Cell>
                <Button onClick={this.showWebHookEditor} primary style={{ marginTop: 10 }}>Add Custom Webhook</Button>
              </Cell>
            </Grid>

            <IntegrationsSelector
              label="Add a new integration..."
              showCategories
              {...this.props}
            />

            <div
              style={{
                marginTop: 30
              }}
            >
              {allIntegrationQuery.integrations.map((integration, key) => (
                <IntegrationItem
                  key={key}
                  data={integration}
                  {...this.props}
                  showDisableConfirmation={this.showDisableConfirmation}
                  showIntegrationEditor={this.showIntegrationEditor}
                  showWebHookEditor={this.showWebHookEditor}
                />
              ))}
            </div>
          </Cell>
        </Grid>
      </div>
    );
  }
}

IntegrationsSection.propTypes = {
  /** Boolean whether or not to display the section */
  hidden: PropTypes.bool
  /**
   * The list of integrations (and relative setup details, like API key & secret)
   * that the user has associated with their account
   */
  // integrations: PropTypes.objectOf(
  //   PropTypes.oneOf(PropTypes.object, PropTypes.bool)
  // )
};
IntegrationsSection.defaultProps = {
  hidden: false
};

export default IntegrationsSection;
