import {
  getCampaign,
  getProviderCampaign,
  getProviderOffers,
  getProviderProducts,
  updateCampaignObject,
} from "@launchos/api/gql/campaigns";
import { allCatalogDesignItems } from "@launchos/api/gql/catalog";
import {
  allIntegrationQuery,
  createIntegration,
  updateIntegration,
} from "@launchos/api/gql/integrations";
import { createMediaItem, uploadURLToS3 } from "@launchos/api/gql/media";
import {
  getPage,
  publishPage,
  updatePageContent,
  updatePageName,
  updatePageSettings,
  updatePageType,
} from "@launchos/api/gql/pages";
import { getUser } from "@launchos/api/gql/users";
import { compose, graphql } from "react-apollo";
import Editor from "./Editor";
import Test from '../settings/AvatarButton'

export default compose(
  graphql(getPage, {
    name: "getPageQuery",
    options: (props) => ({
      variables: {
        pageId: props.match.params.nodeId,
      },
    }),
  }),

  graphql(updatePageName, {
    name: "updatePageName",
    // options: { fetchPolicy: "network-only" }
  }),

  graphql(updatePageType, {
    name: "updatePageType",
    // options: { fetchPolicy: "network-only" }
  }),

  graphql(updatePageSettings, {
    name: "updatePageSettings",
    // options: { fetchPolicy: "network-only" }
  }),

  graphql(updatePageContent, {
    name: "updatePageContent",
  }),

  graphql(getProviderOffers, { name: "getProviderOffers" }),

  graphql(getProviderProducts, { name: "getProviderProducts" }),

  // graphql(getProviderCampaigns, { name: "getProviderCampaigns" }),

  graphql(getProviderCampaign, { name: "getProviderCampaign" }),

  graphql(getCampaign, {
    name: "getCampaignQuery",
    options: (props) => ({
      variables: {
        id: props.match.params.campaignId,
      },
    }),
  }),

  graphql(updateCampaignObject, {
    name: "updateCampaignObjectMutation",
  }),

  graphql(allIntegrationQuery, {
    name: "allIntegrations",
    options: (props) => ({
      variables: {
        userId: localStorage.userId,
      },
    }),
  }),

  graphql(updateIntegration, { name: "updateIntegration" }),

  graphql(createIntegration, {
    name: "createIntegration",
  }),

  graphql(publishPage, {
    name: "publishPage",
  }),

  graphql(allCatalogDesignItems, {
    name: "allCatalogDesignItems",
  }),

  graphql(createMediaItem, {
    name: "createMediaItem",
  }),

  graphql(uploadURLToS3, {
    name: "uploadURLToS3",
  }),

  graphql(getUser, {
    name: "getUserQuery",
    options: () => ({
      variables: {
        userId: localStorage.userId,
      },
    }),
  })
// )(Test);
)(Editor);
