// import { app } from "../../../config";
import { decode, encode } from "@launchos/api/utils";
import { DialogBody } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import providers from "../../settings/IntegrationManagement/integrationProviders";
import EmailChooseCampaign from "./ChooseCampaign";
import EmailChooseMapping from "./ChooseMapping";
import EmailChooseProvider from "./ChooseProvider";
import EmailPasteFormCode from "./PasteFormCode";
import ShowStepper from "./ShowStepper";
import EmailSuccess from "./Success";

export class EmailSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerCredentials: {},
      payload: {},
      campaign: {}
    };

    this.updateEmailSettings = this.updateEmailSettings.bind(this);
    this.updatePageSettings = this.updatePageSettings.bind(this);
  }

  static contextType = AppConfig;

  componentDidMount() {
    const { app } = this.context;
    const { getPageQuery, allIntegrations } = this.props;

    allIntegrations.refetch({ userId: localStorage.userId }).then(data => {
      const pageSettings = getPageQuery.page.pageSettings || {};

      const providerType = _.get(
        this.state,
        "payload.type",
        app.defaultProvider
      );
      const payloadObj = data.data.integrations.filter(itm => providerType);
      const payload = payloadObj.length
        ? { type: providerType, ...payloadObj[0].setting }
        : false;

      console.log({ providerType }, { data }, { payload }, { pageSettings });

      this.setState({
        payload: _.has(pageSettings, "email")
          ? JSON.parse(decode(pageSettings.email.payload))
          : payload
      });
    });
  }

  updateEmailSettings(payload) {
    this.setState({ payload });
  }

  updatePageSettings() {
    const { app } = this.context;
    const { getPageQuery, match } = this.props;
    const { pageSettings } = getPageQuery.page;

    const variables = {
      pageId: match.params.nodeId,
      pageSettings: {
        ...pageSettings,
        email: {
          provider: _.get(this.state, "payload.type", app.defaultProvider),
          providerCredentials: encode(
            JSON.stringify(this.state.providerCredentials)
          ),
          payload: encode(JSON.stringify(this.state.payload))
        }
      }
    };

    this.props.updatePageSettings({ variables });
  }

  render() {
    const { app } = this.context;
    const myProps = {
      ...this.props,
      updateSettings: this.updateEmailSettings,
      providerSettings: this.state
    };
    const providerInfo = _.filter(
      providers,
      o =>
        o.id === _.get(this.state, "payload.type", app.defaultProvider) &&
        o.type === "email"
    );

    const step = this.props.step.toUpperCase();
    const providerTab = {
      id: "PROVIDER",
      label: "Email Provider",
      show: this.props.showProvider ? true : false
    };

    let tabs = [providerTab];
    tabs = providerInfo.length ? tabs.concat(providerInfo[0].tabs) : tabs;

    const activeStep = parseInt(
      _.findKey(_.filter(tabs, t => t.show), o => o.id === step),
      10
    );

    console.log({ providerInfo, tabs });

    return (
      <div style={{ padding: 40 }}>
        {tabs.length > 1 ? (
          <div style={{ marginBottom: 40 }}>
            <ShowStepper {...this.props} tabs={tabs} activeStep={activeStep} />
          </div>
        ) : null}
        <DialogBody>
          {step === "PROVIDER" ? (
            <EmailChooseProvider
              nextHref="./campaign"
              filter="email"
              message="What email provider should we use to send new leads that come from this page?"
              label="Search for an email provider..."
              {...myProps}
            />
          ) : null}
          {step === "CAMPAIGN" ? (
            <EmailChooseCampaign
              prevHref="./provider"
              nextHref="./success"
              {...myProps}
              message="When there is a new lead, which campaign should we send their contact information to?"
            />
          ) : null}
          {step === "FORMCODE" ? (
            <EmailPasteFormCode
              prevHref="./provider"
              nextHref="./mapping"
              {...myProps}
              payload={this.state.payload}
            />
          ) : null}
          {step === "MAPPING" ? (
            <EmailChooseMapping
              prevHref="./formcode"
              nextHref="./success"
              {...myProps}
              payload={this.state.payload}
            />
          ) : null}
          {step === "SUCCESS" ? (
            <EmailSuccess
              prevHref="./campaign"
              {...myProps}
              updatePageSettings={this.updatePageSettings}
              message="Your Campaign has been connected"
              footer={`
                After publishing this page, any information submitted from
                this page, will be sent to the corresponding campaign in your
                ${providerInfo.length ? providerInfo[0].caption : ""}
                account.
              `}
            />
          ) : null}
        </DialogBody>
      </div>
    );
  }
}

EmailSettings.defaultProps = {
  step: "CAMPAIGN",
  showProvider: true
};

export default EmailSettings;
