import { Button, Cell, Grid, TextInput } from "@launchos/components/ui";
// import { app } from "../../../config";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import md5 from "md5";
import React, { Component } from "react";
import style from "../style";

/**
 * This is the section where they can update the information (email, password, etc)
 * that we have on file for them.
 * @param {object} props The props
 * @returns {function} The component
 */
class ProfileSection extends Component {
  constructor(props) {
    super(props);

    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
  }

  static contextType = AppConfig;

  async handleUpdateProfile() {
    const { updateUserMutation } = this.props;

    const omitEmptyKeys = obj => {
      _.keys(obj, k => {
        if (!obj[k].length) _.omit(obj, k);
      });

      return obj;
    };

    let fields = omitEmptyKeys(this.state);

    if (_.has(fields, "password")) {
      if (_.get(fields, "password") !== _.get(fields, "confirmPassword")) {
        alert("Sorry, your passwords don't match.  Please try again");
        return false;
      } else {
        fields.password = md5(fields.password);
        fields = _.omit(fields, ["confirmPassword"]);
      }
    }

    await updateUserMutation({
      variables: {
        id: localStorage.userId,
        ...fields
      }
    });

    alert("Your settings have been updated");

    window.location.reload();
  }

  render() {
    const { app } = this.context;

    if (!this.props.hidden) {
      if (this.props.getUserQuery.loading)
        return (
          <div style={{ textAlign: "center" }}>
            <img src={app.loadingImage} alt="loading gif" />
          </div>
        );
      if (this.props.getUserQuery.error)
        return (
          <div>
            <img src={app.errorImage} alt="error gif" />
          </div>
        );

      return (
        <Grid justify="center" style={{ marginTop: 60 }}>
          <Cell align="center" style={{ width: 780 }}>
            <TextInput
              style={style.textInput}
              label="First Name *"
              onChange={e => this.setState({ firstName: e.target.value })}
              value={this.props.getUserQuery.user.firstName}
            />
            <TextInput
              style={style.textInput}
              label="Last Name *"
              onChange={e => this.setState({ lastName: e.target.value })}
              value={this.props.getUserQuery.user.lastName}
            />
            <TextInput
              style={style.textInput}
              label="Email Address *"
              onChange={e => this.setState({ email: e.target.value })}
              value={this.props.getUserQuery.user.email}
            />
            <TextInput
              style={style.textInput}
              type="password"
              label="New Password"
              onChange={e => this.setState({ password: e.target.value })}
            />
            <TextInput
              style={style.textInput}
              type="password"
              label="Confirm Password"
              onChange={e => this.setState({ confirmPassword: e.target.value })}
            />

            {/* {JSON.stringify(this.props.getUserQuery.user.permissions)} */}

            <Grid justify="center" style={{ marginTop: 50 }}>
              <Cell align="center">
                <Button secondary large onClick={this.handleUpdateProfile}>
                  Update Settings
                </Button>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      );
    }
    return null;
  }
}

ProfileSection.defaultProps = {
  hidden: false
};

export default ProfileSection;
