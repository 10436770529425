import { CSSProperties } from "react";

export const style: { renamableTitle: CSSProperties } = {
  renamableTitle: {
    fontSize: 24,
    lineHeight: "32px",
    color: "rgba(0, 0, 0, 0.87)",
    marginLeft: -7,
    fontFamily: `'Open Sans', sans-serif`,
    fontWeight: 300,
    textAlign: "left",
  },
};
