import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
// import settings01 from "./01/settings";
// import settings02 from "./02/settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.WEB_LAYOUT && hook.type === "drawer") {
    return [
      // settings01,
      // settings02,
    ];
  }
};
