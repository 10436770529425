export default {
  default: {
    isPopupBackgroundShowing: true,
    properties: {
      backgroundColor: "white",
      margin: "0 auto",
      marginTop: 100,
      overflow: "visible",
      height: "auto",
      oldDisplay: "block",
      borderStyle: "none",
      borderRadius: 12,
      padding: 35,
      mobile: {
        desktop: {
          display: "block",
          oldDisplay: "block"
        }
      },
      width: 710,
      maxWidth: "100%",
      shadowRadius: 0,
      shadowOffset: 0,
      display: "block"
    },
    type: "Popup",
    popupType: "window",
    canHaveChildren: true,
    children: [
      {
        state: "normal",
        properties: {
          textAlign: "center",
          fontFamily: "Open Sans",
          fontSize: 11,
          padding: 5,
          mobile: {
            desktop: {
              textAlign: "center",
              fontSize: 11,
              fontFamily: "Open Sans"
            }
          },
          display: "inline-block"
        },
        type: "Paragraph",
        html: "<p>We value your privacy! &nbsp;We will never spam you.</p>"
      },
      {
        state: "normal",
        properties: {
          backgroundColor: "#2980b9",
          marginTop: 30,
          paddingRight: 30,
          borderWidth: 0,
          borderRadius: 0,
          color: "white",
          textAlign: "center",
          paddingTop: 10,
          minWidth: 100,
          paddingBottom: 10,
          fontSize: 18,
          mobile: {
            desktop: {
              backgroundColor: "#2980b9",
              width: 380,
              maxWidth: "100%"
            }
          },
          width: 380,
          maxWidth: "100%",
          paddingLeft: 30,
          display: "inline-block"
        },
        actions: [
          {
            type: "SUBMIT_FORM",
            name: "Submit this Form",
            payload: {
              url: "{next_page}",
              urlPageName: "Go to the next page",
              useTag: false
            },
            behavior: "click"
          }
        ],
        type: "Button",
        html: "Sign Up Now",
        href: "{next_page}"
      },
      {
        name: "email",
        state: "normal",
        placeholder: "Email Address ",
        properties: {
          marginTop: 10,
          textAlign: "center",
          padding: 15,
          mobile: {
            desktop: {
              padding: 15
            }
          },
          width: 380,
          maxWidth: "100%",
          display: "inline-block"
        },
        fieldType: "text",
        type: "TextInput"
      },
      {
        name: "firstName",
        state: "normal",
        placeholder: "First Name",
        properties: {
          marginTop: 30,
          textAlign: "center",
          padding: 15,
          mobile: {
            desktop: {
              width: 380,
              maxWidth: "100%",
              padding: 15,
              marginTop: 30
            }
          },
          width: 380,
          maxWidth: "100%",
          display: "inline-block"
        },
        fieldType: "text",
        type: "TextInput"
      },
      {
        state: "normal",
        properties: {
          marginTop: 10,
          color: "#2c3e50",
          textAlign: "center",
          fontFamily: "Actor",
          lineHeight: "30px",
          fontSize: 24,
          padding: 5,
          mobile: {
            desktop: {
              lineHeight: "30px",
              color: "#2c3e50"
            }
          },
          display: "inline-block"
        },
        type: "Paragraph",
        html:
          "<p><strong>Please enter your name and email address&nbsp;</strong></p>\n<p><strong>below to get instant access now!</strong></p>"
      },
      {
        state: "normal",
        properties: {
          marginTop: 10,
          color: "#2c3e50",
          textAlign: "center",
          fontFamily: "Open Sans",
          fontSize: 12,
          padding: 5,
          mobile: {
            desktop: {
              marginTop: 10,
              color: "#2c3e50",
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: 12
            }
          },
          display: "inline-block"
        },
        type: "Paragraph",
        html:
          "<p>Almost there! &nbsp;Please enter your name and email address in the form below to get access right away.</p>"
      }
    ]
  }
};
