import { Button, Cell, Grid, TextInput } from "@launchos/components/ui";
import React, { Component } from "react";
import _ from 'lodash'

export class DomainSubdomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subdomain: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({
      subdomain: event.target.value
    });
  }
  render() {
    if (!this.props.hidden) {
      const { domainInfo, updateDomainInfo } = this.props;
      // const theDomain = getDomainInfo().value;
      const theDomain = _.get(domainInfo, 'value')

      return (
        <div style={{ width: 700 }}>
          <h2>
            No problem. Since www.
            {theDomain} is already in use, let&#39;s make a subdomain (e.g.
            example.
            {theDomain}) instead...
          </h2>
          <div style={{ margin: 50 }}>
            <div style={{ borderBottom: "1px solid #CCC" }}>
              <Grid style={{ width: 400, margin: "0 auto", padding: 20 }}>
                <Cell>
                  <TextInput
                    onChange={this.handleChange}
                    focusOnMount
                    value={this.state.subdomain}
                    placeholder="example"
                    style={{
                      border: "none",
                      marginTop: -20,
                      fontSize: 20,
                      display: "inline-block"
                    }}
                  />
                </Cell>
                <Cell>
                  <span style={{ fontSize: 20, color: "#999" }}>
                    .{theDomain}
                  </span>
                </Cell>
              </Grid>
            </div>
            <p style={{ marginTop: 10, fontSize: 11, color: "#666" }}>
              Enter a subdomain prefix to use with your domain name
            </p>
          </div>
          <Grid justify="space-evenly" style={{ marginTop: 30 }}>
            <Cell align="center">
              <Button
                onClick={() =>
                  updateDomainInfo({
                    step: "tutorial",
                    value: `${this.state.subdomain}.${theDomain}`
                  })
                }
                large
                disabled={this.state.subdomain === ""}
                tertiary={this.state.subdomain !== ""}
              >
                Next
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
