import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // padding: 50
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1style: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  NAVIGATION: {
    linkStyle: {
      // color: "white",
      listStyle: "none",
      display: "inline-block",
      padding: "30px 20px",
      fontFamily: "Roboto",
    },
  },
  LI: {
    style: { marginTop: 40 },
    iconStyle: {
      backgroundColor: theme.foregroundColor,
      color: theme.backgroundColor,
      fontSize: "36pt",
      paddingRight: 15,
    },
  },
};
