import { ComponentMountHooks } from "@launchos/plugins/types";

export const onComponentMount = (hook) => {
  if (hook.id === ComponentMountHooks.APP_DID_LOAD) {
    const h = location.hostname;
    if (h.indexOf("staging") === -1 && h.indexOf("localhost") === -1) {
      console = {
        ...console,
        log: () => {},
        warn: () => {},
        error: () => {},
        exception: () => {},
        info: () => {},
      };
    }
  }
};

export default { onComponentMount };
