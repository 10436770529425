import _ from "lodash";
import React from "react";
import { TextInput } from "@launchos/components/ui";

export const CreateTag = (props) => {
    const { action, updateAction } = props;
    const { payload } = action;

    const tagName = _.get(payload, "tagName", "");
    const tagValue = _.get(payload, "tagValue", "");

    return (
        <div>
            <TextInput
                placeholder="Name of Tag"
                style={{ marginTop: 10 }}
                value={tagName}
                onBlur={(e) => updateAction(action, { tagValue, tagName: e.target.value })}
                focusOnMount
                focusOnClick />
            <TextInput
                placeholder="(Optional) Value of tag"
                style={{ marginTop: 10 }}
                value={tagValue}
                onBlur={(e) => updateAction(action, { tagName, tagValue: e.target.value })}
                focusOnClick />
        </div>
    );
};
