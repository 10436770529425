import _, { get } from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";

import { generateFontList } from "./scripts";
import { Toolbar } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import LivePagePreview from "@launchos/modules/editor/Builder/Page/Preview";

import AppDrawer from "../appdrawer";
import { RenamableTitle, style as campaignStyle } from "../campaign/shared";
import Builder from "./Builder";
import { leftActionsGroup, rightActionsGroup } from "./Header";
import PublishSuccessPopup from "./PublishSuccess";
import SEO from "./SEO";
import { EmailSettings, MerchantSettings } from "./Settings";
import Navigation from "./Navigation";
import Templates from "./Templates";
import Tracking from "./Tracking";
import CSS from "./CSS";
import ExitIntent from "./ExitIntent";
import History from "./History";

import { getResponsiveSettings } from "./Builder/Canvas/scripts";

import style, { getMainContainerStyle, getPageWrapStyle } from "./style";
import { whichKeysAreDifferent } from "./Builder/WebComponent/WebComponent";

const CURSOR_ID = 1111;

const standardCursor = {
  type: "Cursor",
  id: CURSOR_ID,
  className: false,
  html: "",
  parent: false,
  properties: false,
};

var saveTimeout = null;

const isFromIframe = window.location !== window.parent.location;

class Editor extends Component {
  constructor(props) {
    super(props);

    const isFromIframe = window.location !== window.parent.location;
    // const isFromIframe = _.get(this.props, 'location.state.from', false) === 'iframe';

    this.state = {
      appDrawerVisible: false,
      settings: false,
      content: [],
      showPublish: false,
      state: {
        active: false,
        hover: false,
      },
      undoStack: [],
      undoPosition: 0,
      canUndo: true,
      mobileState: _.get(props, "defaultMobileState", {
        type: props.show === "builder" ? "fullscreen" : "desktop",
        dimensions:
          props.show === "builder"
            ? {
                width: "100%",
                minHeight: "100vh",
                margin: 0,
                marginTop: isFromIframe ? 0 : 57,
              }
            : {
                maxWidth: 1000,
                width: "100%",
                minHeight: "70vh",
                margin: "80px auto",
                overflow: "hidden",
              },
      }),
      activePropertyWindows: [],
      savingStatus: "SAVED",
      canDrag: true,
      hostFrameData: {},
    };

    this.ancestors = [];

    this.setActiveObject = this.setActiveObject.bind(this);
    this.toggleAppDrawer = this.toggleAppDrawer.bind(this);

    // page edit methods
    this.addThisAfterThat = this.addThisAfterThat.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.duplicateItem = this.duplicateItem.bind(this);
    this.moveThisByThat = this.moveThisByThat.bind(this);
    this.addHoverCursor = this.addHoverCursor.bind(this);
    this.changeState = this.changeState.bind(this);
    this.renderFromState = this.renderFromState.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.updateComponentSettings = this.updateComponentSettings.bind(this);
    this.updateComponentStyle = this.updateComponentStyle.bind(this);
    this.listAncestors = this.listAncestors.bind(this);
    this.doUndoRedo = this.doUndoRedo.bind(this);
    this.setCanUndo = this.setCanUndo.bind(this);
    this.setMobileState = this.setMobileState.bind(this);
    this.getMobileState = this.getMobileState.bind(this);
    this.setActivePropertyWindows = this.setActivePropertyWindows.bind(this);
    this.getActivePropertyWindows = this.getActivePropertyWindows.bind(this);
    this.setCanDrag = this.setCanDrag.bind(this);
    this.getCanDrag = this.getCanDrag.bind(this);
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { match, getPageQuery } = this.props;

    try {
      const { data } = await getPageQuery.refetch({ id: match.params.nodeId });

      const { page } = data;

      if (!page) window.location.href = "/login";
      else {
        this.setState(
          {
            content: page.content.map((itm) => ({ ...itm, state: "normal" })), // give everything a "normal" state
            undoStack: [page.content],
            name: page.name,
            id: page.id,
          },
          () => this.loadFonts(this.state.content)
        );
      }

      // Listen for incoming messages from the parent iframe
      if (isFromIframe) {
        window.addEventListener("message", (event) => {
          if (
            event.origin !== "http://localhost:5173" &&
            event.origin !== "https://app.launchos.com" &&
            event.origin !== "http://localhost:6006"
          )
            return;
          if (event.data.type === "onLoad")
            this.setState({ hostFrameData: event.data });
          if (event.data.type === "onEditorAction") {
            console.log(
              "In Sandcastle App - Incoming Editor Action Request from launch os iframe",
              event.data
            );

            if (event.data.action === "updateContent") {
              // content, dbUpdate = true, debounceTimeout = 500, callbackFn = () => false
              // this.updateContent(event.data.payload.content, true, false, () => {
              //   window.parent.postMessage({ type: "onEditorActionComplete", id: event.data.id }, "*");
              // })
              this.updateContent(event.data.payload.content, true);
            }

            if (event.data.action === "updateComponentSettings") {
              // id, settings, dbUpdate = true, debounceTimeout = 500, callbackFn = () => false
              // this.updateComponentSettings(event.data.id, event.data.payload.settings, true, false, () => {
              //   window.parent.postMessage({ type: "onEditorActionComplete", id: event.data.id }, "*");
              // })
              this.updateComponentSettings(
                event.data.id,
                event.data.payload.settings,
                true
              );
            }

            if (event.data.action === "updateComponentStyle") {
              this.updateComponentStyle(
                event.data.id,
                event.data.payload.newStyle,
                true
              );
            }

            if (event.data.action === "doUndoRedo") {
              this.doUndoRedo(event.data.payload.type);
            }

            if (event.data.action === "setMobileState") {
              this.setMobileState(
                event.data.payload.type,
                event.data.payload.dimensions
              );
            }

            // if (event.data.action === "getPageContent") {
            //   window.parent.postMessage({ type: "onEditorActionComplete", action: "getPageContent", payload: { content: this.state.content } }, "*");
            // }
          }

          // console.log("In Sandcastle App - Data from launch os iframe", event.data);
        });

        // Ask the parent iframe for the current state (sends the request, which posts a message back -- handled above)
        window.parent.postMessage({ type: "getState" }, "*");
      }
    } catch (err) {
      if (location.hostname !== "localhost")
        window.location.href = window.location.href;
      else {
        alert(
          `Sorry, there was an error.  Let's try again
          
          ${JSON.stringify(err)}`
        );
        console.log({ err });
      }
    }
  }

  loadFonts(content) {
    // Load Web Fonts
    const families = generateFontList(content);
    if (families.length) WebFont.load({ google: { families } });
  }

  toggleAppDrawer() {
    this.setState((prevState) => ({
      appDrawerVisible: !prevState.appDrawerVisible,
    }));
  }

  setActiveObject(settings) {
    this.setState({ settings });
    return settings;
  }

  scheduleUpdate(updateFn, callbackFn = () => null) {
    this.pendingUpdateFn = [updateFn, callbackFn];

    if (!this.requestedFrame) {
      this.requestedFrame = requestAnimationFrame(this.renderFromState);
    }
  }

  changeState(id, state = "normal", callbackFn = () => false) {
    // If a state change event exists in the props, call it
    if (_.has(this.props, "onChangeState"))
      this.props.onChangeState(id, state, this.state.content);

    // If a state change event exists from the parent iframe, call it
    if (isFromIframe)
      window.parent.postMessage(
        { type: "onEditorItemChangeState", id, state },
        "*"
      );

    // console.log("hover", id)

    if (!id) return;

    const prevContent = this.state.content;

    // 0. find the location in the array where this item is
    const key = prevContent.findIndex((itm) => itm.id === id);

    if (key === -1) return;

    // 1. remove the state field for all items in the content arrray with that state
    const content = prevContent.map((itm) => ({
      ...itm,
      state: itm.state === state ? "normal" : itm.state,
    }));

    // 2. reconstruct the content array, but modify the state of the specific object
    const updatedContent = [
      ...content.slice(0, key),
      { ...content[key], state },
      ...content.slice(key + 1),
    ];

    if (state === "hover" && prevContent[key].state === "active") return;
    if (state === "normal" && prevContent[key].state === "active") return;

    // 3. remove any cursor still showing
    const contentWithNoCursors = updatedContent.filter(
      (itm) => itm.id !== CURSOR_ID
    );

    if (_.has(this.props, "onItemSelect") && state === "active")
      this.props.onItemSelect(id, updatedContent[key]);
    if (isFromIframe && state === "active")
      window.parent.postMessage(
        { type: "onEditorItemSelect", id, item: updatedContent[key] },
        "*"
      );

    // 4. update the state with the reconstructed content array
    this.scheduleUpdate({ content: contentWithNoCursors }, callbackFn);
  }

  recursivelyAddThisAfterThat(newItem, afterId, content) {
    // 1. Get the position of the 'afterId' item
    const key = content.findIndex((itm) => itm.id === afterId);

    // 2. Remove any children from the new Item
    const { children, ...newItemWithoutChildren } = newItem;

    // 3. Generate a new Id for the new item
    const id = Math.random().toString(36).slice(2);

    // 4. Add the childless item to the content array that you will return for db updating
    this.addedContent = [
      ...content.slice(0, key + 1),
      {
        ...newItemWithoutChildren,
        oldId: _.get(newItem, "id", id),
        justAdded: true, // so that we have a list of all the recently added items
        id,
      },
      ...content.slice(key + 1),
    ];

    // 5. Add any child items if there is any
    if (newItem.hasOwnProperty("children")) {
      // Loop through each child object
      const children = [..._.get(newItem, "children", [])].reverse();
      children.forEach((itm) => {
        // Set the previously generated id as the child object's parent
        const updatedItm = {
          ...itm,
          oldId: itm.id,
          justAdded: true,
          parent: id,
        };
        // Call the recursive function to update the added content array with our child object
        // ...or handle any children that my new item has
        this.addedContent = this.recursivelyAddThisAfterThat(
          updatedItm,
          id,
          this.addedContent
        );
      });
    }

    // console.log("after children update", id, this.addedContent);
    this.changeState(id, "active");

    // 6. Return the updated content array
    // console.log({ group: this.addedContent })
    return this.addedContent;
  }

  addThisAfterThat(item, id, callbackFn = () => false) {
    if (!id) return;
    // Get content item
    const prevContent = this.state.content;

    // Establish parent for new item
    const object = prevContent.filter((itm) => itm.id === id);
    if (!object.length) return;

    const itemWithParent = {
      ...item,
      parent: object[0].parent,
      state: "active",
    };
    console.log({ item });

    // Call recursive function to build a chain of items to add
    // as decendents of this object
    const theContent = this.recursivelyAddThisAfterThat(
      itemWithParent,
      id,
      prevContent
    );

    // remap id's of references to other page elements within the group of content we're adding
    const groupToRemap = theContent.filter(
      (group) => _.get(group, "justAdded", false) === true
    );
    const remappedGroup = this.remapIdsInGroup(groupToRemap);
    // console.log({ remappedGroup })
    const updatedContent = theContent.map((itm) => {
      const keyIfInRemappedGroup = remappedGroup.findIndex(
        (obj) => obj.id === itm.id
      );
      if (keyIfInRemappedGroup > -1) return remappedGroup[keyIfInRemappedGroup];
      // but remove justAdded key
      else return itm;
    });

    // remove the cursor
    const content = updatedContent.filter((itm) => itm.id !== CURSOR_ID);

    // reload fonts
    this.loadFonts(content);

    // Now update the document with all the new items
    this.setState(
      {
        content,
      },
      () => {
        this.handleUpdatePageContent(
          content,
          true,
          () => callbackFn(content),
          false
        );
      }
    );
  }

  recursivelyRemoveItem(id, content) {
    this.removedContent = content.filter((itm) => itm.id !== id); // everything without item
    this.removedContent
      .filter((itm) => itm.parent === id)
      .forEach((itm) => {
        this.removedContent = this.recursivelyRemoveItem(
          itm.id,
          this.removedContent
        );
      });

    return this.removedContent;
  }

  removeItem(id, dbUpdate = true) {
    const prevContent = this.state.content;
    const key = prevContent.findIndex((itm) => itm.id === id);
    if (prevContent[key].parent) {
      const content = this.recursivelyRemoveItem(id, prevContent);

      this.setState(
        {
          content,
        },
        () => {
          // persist the state change to the database
          if (dbUpdate) this.handleUpdatePageContent(content);
        }
      );
    } else {
      alert("You cannot remove the main body object on this page");
    }
  }

  recursivelyDuplicate(id, content, parent) {
    // 1. Get Object

    // 2. Get its direct children
    const children = content.filter((itm) => itm.parent === id);

    // 3. Loop through each child
    children.forEach((child) => {
      // 4. Add a modified object to the class level array
      const newId = Math.random().toString(36).slice(2);

      this.duplicatedItemCollection = [
        ...this.duplicatedItemCollection,
        { ...child, state: "normal", id: newId, oldId: child.id, parent },
      ];

      // 5. Call recursivelyDuplicate on child object
      this.recursivelyDuplicate(child.id, content, newId);
    });

    // 6. Return the class level array so that it can be added to state
    return this.duplicatedItemCollection;
  }

  remapIdsInGroup(group) {
    let returnGroup = group;

    // the list of keys used for mapping to a page element
    const inKeys = (key) => {
      return (
        key === "showId" ||
        key === "hideId" ||
        key === "sectionId" ||
        key === "tabId" ||
        key === "tabItemId"
      );
    };

    // 1. Look in group for any objects with action payloads & keys that reference another page element
    // a. loop through the list of items in the group
    const remappableProspects = group.filter((itm) => {
      // b. grab the list of actions if there are any
      const actions = _.get(itm, "actions", false);
      if (actions) {
        // c. now check if any of the actions have a payload that references another page element somewhere
        return (
          actions.findIndex(({ payload = false }) => {
            if (payload)
              return (
                _.keys(payload).findIndex((key) => {
                  return inKeys(key);
                }) > -1
              );
          }) > -1
        );
      }
      return false;
    });

    // 2. If found, look inside the [original group] for any object that matches that id
    if (remappableProspects.length) {
      // loop through the list of prospects
      _.forEach(remappableProspects, (prospect) => {
        // isolate the payload & loop through the keys
        const { actions } = prospect;
        _.forEach(actions, ({ payload }, j) => {
          _.forEach(_.keys(payload), (key) => {
            // if any of the keys in the payload represent a key that references a page element...
            if (inKeys(key)) {
              // change the mapping of that key to the new element
              const k = group.findIndex((itm) => itm.id === prospect.id);
              const idToRemap = group[k].actions[j].payload[key];

              // look through the group and see if any oldId's match the id in the payload
              const oldIdMatches = group.filter(
                (itm) => itm.oldId === idToRemap
              );
              const originalId = _.get(_.head(oldIdMatches), "id", idToRemap);

              // 3. If found (both elements are inside the group), update the action payload to match new item in the group
              returnGroup[k].actions[j].payload[key] = originalId;
            }
          });
        });
      });
    }
    // console.log(`Remapping all children`, { group, returnGroup, remappableProspects })
    returnGroup = returnGroup.map((grp) => {
      const { justAdded, ...toReturn } = grp; // remove the justAdded key
      return toReturn;
    });

    return returnGroup;
  }

  duplicateItem(
    id,
    doRecursive = true,
    dbUpdate = true,
    callbackFn = () => false
  ) {
    const prevContent = this.state.content;

    // get the key of the id
    const key = prevContent.findIndex((itm) => itm.id === id);
    const newId = Math.random().toString(36).slice(2);

    // create a duplicate version of the item, but only modify the id
    const originalItem = { ...prevContent[key], state: "normal", id: newId };
    this.duplicatedItemCollection = [originalItem];

    const duplicateItem = doRecursive
      ? this.recursivelyDuplicate(id, prevContent, newId)
      : [originalItem];

    // console.log("Duplicating Item", id, duplicateItem)

    const content = [
      ...prevContent.slice(0, key + 1),
      ...this.remapIdsInGroup(duplicateItem),
      ...prevContent.slice(key + 1),
    ];

    // generate a new array with the item twice
    this.setState(
      {
        content,
      },
      () => {
        this.changeState(newId, "active");
        // persist the state change to the database
        if (dbUpdate) this.handleUpdatePageContent(content);
        callbackFn();
      }
    );
  }

  moveThisByThat(id1, id2, dbUpdate = true) {
    console.log("moveThisByThat", id1, id2);

    if (id1 === id2) return; // cancel if they're the same

    const prevContent = this.state.content;

    const keyOfId1 = prevContent.findIndex((itm) => itm.id === id1);
    const keyOfId2 = prevContent.findIndex((itm) => itm.id === id2);
    const id1Content = prevContent[keyOfId1];

    if (keyOfId2 === -1) return; // cancel if the second id doesn't exist

    // remove id1
    const contentAfterRemoveItem = [
      ...prevContent.slice(0, keyOfId1),
      ...prevContent.slice(keyOfId1 + 1),
    ];

    // add id1 under id2 and return result
    const parent = keyOfId2 > -1 ? prevContent[keyOfId2].parent : false;
    const revisedItem = { ...id1Content, parent, state: "active" };

    const content = [
      ...contentAfterRemoveItem.slice(0, keyOfId2),
      revisedItem,
      ...contentAfterRemoveItem.slice(keyOfId2),
    ];

    // now remove the cursor (if any)
    const contentWithoutCursor = content.filter((itm) => itm.id !== CURSOR_ID);

    this.setState(
      {
        content: contentWithoutCursor,
      },
      () => {
        // persist the state change to the database
        this.changeState(id1, "active", () => {
          if (dbUpdate) this.handleUpdatePageContent(contentWithoutCursor);
        });
      }
    );
  }

  addHoverCursor(id, addType = "after") {
    if (!id) return;

    const prevContent = this.state.content;

    // 1. Remove all existing cursors
    const contentWithNoCursors = prevContent.filter(
      (itm) => itm.id !== CURSOR_ID
    );

    // 2. Find the location of the array where this item is
    const key = contentWithNoCursors.findIndex((itm) => itm.id === id);
    if (key === -1) return;

    const i = addType === "after" ? 1 : 0;

    // 4. Add the cursor in correct position
    const cursorParent =
      addType === "inside" ? id : contentWithNoCursors[key].parent;
    const cursorContent = { ...standardCursor, parent: cursorParent };

    // 5. Change the state of the parent container to be a hover state
    let contentWithParentHover = contentWithNoCursors;
    if (cursorParent) {
      const cursorParentKey = contentWithNoCursors.findIndex(
        (itm) => itm.id === cursorParent
      );
      const parentContent = contentWithNoCursors[cursorParentKey];

      // a. remove the state field for all items in the content array with that state
      const noHoverStateContent = contentWithNoCursors.map((itm) => {
        const { state, ...stateRemoved } = itm;
        return stateRemoved;
      });

      // b. reconstruct the content array, but modify the state of the specific object
      contentWithParentHover = [
        ...noHoverStateContent.slice(0, cursorParentKey),
        { ...parentContent, state: "hover" },
        ...noHoverStateContent.slice(cursorParentKey + 1),
      ];
    }

    // 6. update the state
    const content = contentWithParentHover;

    this.scheduleUpdate({
      content: [
        ...content.slice(0, key + i),
        cursorContent,
        ...content.slice(key + i),
      ],
    });
  }

  updateComponentSettings(
    id,
    settings,
    dbUpdate = true,
    debounceTimeout = 500,
    callbackFn = () => null
    // callbackFn = () => console.log("Updated", id, settings, dbUpdate, debounceTimeout),
  ) {
    // console.log("handleUpdatexPageContent", "updateComponentSettings", settings.type)
    console.log(
      "updateComponentSettings",
      id,
      settings,
      dbUpdate,
      debounceTimeout
    );

    // get the key of the id
    const key = this.state.content.findIndex((itm) => itm.id === id);

    // get current state
    const content = [
      ...this.state.content.slice(0, key),
      {
        ...settings,
        state: _.get(this.state, `content[${key}].state`, "normal"),
      },
      ...this.state.content.slice(key + 1),
    ];

    this.setState({ content }, () => {
      if (dbUpdate) {
        // console.log("Saved, now updating db", settings);
        this.handleUpdatePageContent(
          content,
          true,
          callbackFn,
          debounceTimeout
        );
      }
    });
  }

  updateComponentStyle(id, newStyle, dbUpdate = true, debounceTimeout = 500) {
    // console.log("handleUpdatexPageContent", "updateComponentStyle")
    const prevContent = this.state.content;

    // get the settings object of the id
    const key = prevContent.findIndex((itm) => itm.id === id);
    if (key === -1) return; // ignore if id doesn't exist

    const theSettings = prevContent[key];

    // mobile properties
    const cmp = _.get(theSettings, "properties.mobile", {});
    const desktopProperties = _.get(cmp, "desktop", {});
    const tabletProperties = _.get(cmp, "tablet", {});
    const smartphoneProperties = _.get(cmp, "smartphone", {});
    const fullscreenProperties = _.get(cmp, "fullscreen", {});

    const currentMobileState = this.state.mobileState.type;

    let mobileProperties = {};

    if (currentMobileState === "fullscreen") {
      mobileProperties = {
        fullscreen: { ...fullscreenProperties, ...newStyle },
      };
    }
    if (currentMobileState === "desktop") {
      mobileProperties = { desktop: { ...desktopProperties, ...newStyle } };
    }
    if (currentMobileState === "tablet") {
      mobileProperties = { tablet: { ...tabletProperties, ...newStyle } };
    }
    if (currentMobileState === "smartphone") {
      mobileProperties = {
        smartphone: { ...smartphoneProperties, ...newStyle },
      };
    }

    // does the new property key exist in the fullscreen mobile object?
    const existsInFullScreen =
      _.keys(newStyle).findIndex((itm) => _.has(fullscreenProperties, itm)) >
      -1;

    // if not, place the original value there
    if (
      !existsInFullScreen &&
      (currentMobileState === "tablet" || currentMobileState === "smartphone")
    ) {
      _.keys(newStyle).forEach((k) => {
        if (_.has(theSettings, `properties[${k}]`)) {
          mobileProperties = {
            ...mobileProperties,
            fullscreen: {
              ..._.get(mobileProperties, "fullscreen", {}),
              [k]: theSettings.properties[k],
            },
          };
        }
      });
    }

    const updatedSettingsObj = {
      ...theSettings,
      properties: {
        ...theSettings.properties,
        mobile: {
          ..._.get(theSettings, "properties.mobile", {}),
          ...mobileProperties,
        },
      },
    };

    this.updateComponentSettings(
      id,
      updatedSettingsObj,
      dbUpdate,
      debounceTimeout
    );

    return getResponsiveSettings(updatedSettingsObj, {
      type: currentMobileState,
    });
  }

  updateContent(
    content,
    dbUpdate = true,
    debounceTimeout = 500,
    callbackFn = () => false
  ) {
    this.setState({ content }, () => {
      if (dbUpdate) {
        this.handleUpdatePageContent(
          content,
          true,
          callbackFn,
          debounceTimeout
        );
      }
    });
  }

  generateAncestors(content, id) {
    const i = content.findIndex((c) => c.id === id);
    if (i > -1) {
      const parent = content[i].parent;
      this.ancestors = [...this.ancestors, content[i]];
      if (parent) this.generateAncestors(content, parent);
      return this.ancestors;
    }
    return false;
  }

  listAncestors(content, id) {
    if (!id) return;
    const ancestors = this.generateAncestors(content, id);
    this.ancestors = [];
    return ancestors;
  }

  listComponents(content = [], filterOut = [], filterOnly = []) {
    const iconMap = {
      Container: "select_all",
      Columns: "view_column",
      Collection: "format_list_numbered",
      p: "text_fields",
      Paragraph: "text_fields",
      Video: "play_circle_outline",
      Picture: "image",
      Headline: "title",
      Button: "crop_16_9",
      TextInput: "input",
      Popup: "aspect_ratio",
    };

    const filteredList = _.filter(content, (obj) => {
      return _.findIndex(filterOut, (i) => obj[i[0]] === i[1]) === -1;
    });

    const showOnly = _.filter(filteredList, (obj) => {
      if (filterOnly.length)
        return _.findIndex(filterOnly, (i) => obj[i[0]] === i[1]) > -1;
      return true;
    });

    return _.map(showOnly, (obj) => {
      const name = _.get(obj, "name", obj.type);
      let html = _.has(obj, "html")
        ? obj.html.replace(/<(?:.|\n)*?>/gm, "")
        : name;

      if (_.has(obj, "placeholder"))
        html = obj.placeholder.replace(/<(?:.|\n)*?>/gm, "");

      return {
        id: obj.id,
        icon: _.get(iconMap, obj.type, "widgets"),
        html: _.get(obj, "label", html),
        settings: obj,
      };
    });
  }

  doUndoRedo(type) {
    console.log("Doing an Undo/Redo", type);

    this.setState(
      (prevContent) => {
        const { undoStack, undoPosition } = prevContent;
        const key = type === "undo" ? undoPosition - 1 : undoPosition + 1;

        if (undoStack.hasOwnProperty(key)) {
          return {
            ...prevContent,
            content: undoStack[key],
            undoPosition: key,
          };
        }
        return prevContent;
      },
      () => this.handleUpdatePageContent(this.state.content, false)
    );
  }

  setCanUndo(canUndo) {
    this.setState({ canUndo });
  }

  setMobileState(type, dimensions, backgroundImage = false) {
    this.setState(
      {
        mobileState: {
          type,
          dimensions,
        },
      },
      () => {
        // force a rerender otherwise the content will overlap the mobile viewport
        this.changeState(this.state.content[0].id, this.state.content[0].state);
      }
    );
  }

  getMobileState() {
    return this.state.mobileState;
  }

  setActivePropertyWindows(activePropertyWindows, callbackFn = () => false) {
    this.setState({ activePropertyWindows }, callbackFn);
  }

  getActivePropertyWindows() {
    return this.state.activePropertyWindows;
  }

  debounce = (func, wait) => {
    if (!saveTimeout) {
      clearTimeout(saveTimeout);

      saveTimeout = setTimeout(() => {
        saveTimeout = null;
        func();
      }, wait);
    }
  };

  handleUpdatePageContent = async (
    content,
    recordUndo = true,
    callbackFn = () => false,
    wait = 500
  ) => {
    // console.log("about to update the state!", content, whichKeysAreDifferent(content, this.state.content).map(i => ([content[i], this.state.content[i]])))
    // console.log('updating page', content[0])

    this.debounce(() => {
      this.setState({ savingStatus: ["SAVING"] }, () => {
        try {
          if (isFromIframe)
            window.parent.postMessage({ type: "onEditorChange", content }, "*");
        } catch (error) {
          console.log({ error });
        }

        this.setState(
          (prevContent) => {
            const { undoStack, undoPosition } = prevContent;

            if (!recordUndo) return {};
            return {
              undoStack: [
                ...undoStack.slice(0, undoPosition + 1),
                content.filter((itm) => itm.id !== CURSOR_ID),
              ],
              undoPosition: undoPosition + 1,
            };
          },
          () => {
            // filter out 'active' states
            const normalizedContent = _.map(content, (obj) => {
              if (_.get(obj, "state") === "active")
                return { ...obj, state: "normal" };
              else return obj;
            });

            const variables = {
              pageId: _.get(this.props, "match.params.nodeId", false),
              content: normalizedContent,
            };

            try {
              this.props.updatePageContent({ variables }).then((response) => {
                this.setState({ savingStatus: ["SAVED", new Date()] });
                if (!wait) this.setState({ content }); // if wait flag is false, repeat the content
                callbackFn();
              });
            } catch (error) {
              // .catch((error) => {
              console.log({ error });
              alert("Sorry, there was an error saving your latest change.");
              // });
            }
          }
        );
      });
    }, wait);
  };

  renderFromState() {
    this.setState(this.pendingUpdateFn[0], this.pendingUpdateFn[1]);
    // if (_.has(this.props, 'onChange')) this.props.onChange({ ...this.pendingUpdateFn[0], shouldSave: false })

    this.pendingUpdateFn = null;
    this.requestedFrame = null;
  }

  setCanDrag(canDrag, callbackFn = () => false) {
    this.setState({ canDrag }, callbackFn);
  }

  getCanDrag() {
    return this.state.canDrag;
  }

  render() {
    const { app } = this.context;
    const {
      show,
      getPageQuery = {},
      match,
      getCampaignQuery = {},
      getUserQuery = {},
      allIntegrations = {},
      allCatalogDesignItems = {},
    } = this.props;
    const { mobileState, content = [], showPublish } = this.state;
    const { campaignId, nodeId } = match.params;

    if (
      getPageQuery.loading ||
      getCampaignQuery.loading ||
      getUserQuery.loading ||
      allIntegrations.loading ||
      allCatalogDesignItems.loading
    ) {
      // if (
      //   (getPageQuery.loading || getCampaignQuery.loading || getUserQuery.loading,
      //   allIntegrations.loading,
      //   allCatalogDesignItems.loading)
      // ) {
      return (
        <div style={{ textAlign: "center" }}>
          <img src={app.loadingImage} alt="Loading..." />
        </div>
      );
    }
    if (getPageQuery.error) {
      console.log(getPageQuery.error);
      return (
        <div style={{ textAlign: "center" }}>
          <img src={app.errorImage} alt="Connection Error" />
        </div>
      );
    }

    if (!content.length) return <div>...</div>;

    const isFromIframe = window.location !== window.parent.location;
    // const isFromIframe = _.get(this.props, 'location.state.from', false) === 'iframe';

    let mLeft = 0;
    if (show !== "publish") mLeft = 85;
    if (isFromIframe) mLeft = 0;

    const editorProps = {
      hidden: false,
      content: "edit",
      pageContent: content.length ? content : getPageQuery.page.content,
      // getPageContent: () => content.length ? content : getPageQuery.page.content,
      removeItem: this.removeItem,
      duplicateItem: this.duplicateItem,
      moveThisByThat: this.moveThisByThat,
      addHoverCursor: this.addHoverCursor,
      addThisAfterThat: this.addThisAfterThat,
      changeState: this.changeState,
      state: this.state.state,
      updateContent: this.updateContent,
      updateComponentStyle: this.updateComponentStyle,
      updateComponentSettings: this.updateComponentSettings,
      listAncestors: this.listAncestors,
      listComponents: this.listComponents,
      setActiveObject: this.setActiveObject,
      doUndoRedo: this.doUndoRedo,
      undoStackSize: this.state.undoStack.length - 1,
      undoPosition: this.state.undoPosition,
      setCanUndo: this.setCanUndo,
      setMobileState: this.setMobileState,
      getMobileState: this.getMobileState,
      currentMobileState: this.state.mobileState,
      setActivePropertyWindows: this.setActivePropertyWindows,
      getActivePropertyWindows: this.getActivePropertyWindows,
      setCanDrag: this.setCanDrag,
      getCanDrag: this.getCanDrag,
    };

    if (show === "preview") {
      const { id, pageSettings } = this.props.getPageQuery.page;
      return (
        <LivePagePreview
          content={editorProps.pageContent}
          // nextPage=""
          seoPayload={_.get(pageSettings, "seo", {})}
          merchantPayload={_.get(pageSettings, "merchant", {})}
          trackingPayload={_.get(pageSettings, "tracking", {})}
          emailPayload={_.get(pageSettings, "email", {})}
          cssPayload={_.get(pageSettings, "css", "")}
          exitPayload={_.get(pageSettings, "exitPopup", {})}
          id={id}
        />
      );
    }

    return (
      <div
        style={{
          ...getPageWrapStyle(this.state.hostFrameData),
          marginLeft: mLeft,
          ...get(this.state.hostFrameData, "pageWrapStyle", {}),
        }}
      >
        <div
          style={{
            ...campaignStyle.bigToolbar.main,
            marginLeft: mLeft,
            zIndex: 301,
          }}
        >
          {showPublish ? (
            <PublishSuccessPopup
              {...this.props}
              onClose={() => this.setState({ showPublish: false })}
            />
          ) : null}
          <AppDrawer
            hidden={!this.state.appDrawerVisible}
            toggleAppDrawer={this.toggleAppDrawer}
          />

          {!isFromIframe && (
            <Toolbar
              leftActionsGroup={leftActionsGroup}
              title={() => (
                <RenamableTitle
                  {...this.props}
                  name={this.props.getPageQuery.page.name}
                  onChange={(pageName) => {
                    this.props
                      .updatePageName({
                        variables: {
                          pageId: this.props.getPageQuery.page.id,
                          name: pageName,
                        },
                      })
                      .then((result) => {
                        const object = result.data.updatePage.object;
                        console.log({ object });
                        console.log("PAGE IS RENAMED", result);
                        if (object) {
                          this.props
                            .updateCampaignObjectMutation({
                              variables: {
                                name: pageName,
                                deleted: false,
                                screenshot: object.screenshot,
                                id: object.id,
                                connectToIds: object
                                  ? object.connectTo.map((obj) => ({
                                      id: obj.id,
                                    }))
                                  : [],
                              },
                            })
                            .then((json) => {
                              this.props.getPageQuery.refetch({
                                variables: { pageId: nodeId },
                              });
                              this.props.getCampaignQuery.refetch({
                                variables: { campaignId },
                              });
                            });
                        }
                      });
                  }}
                />
              )}
              pages={_.filter(
                _.get(this.props, "getCampaignQuery.campaign.objects", []),
                (itmm) => itmm.type === "PageComponent"
              )}
              match={this.props.match}
              rightActionsGroup={rightActionsGroup}
              toggleAppDrawer={this.toggleAppDrawer}
              theme="light"
              doUndoRedo={this.doUndoRedo}
              undoPosition={this.state.undoPosition}
              undoStackSize={this.state.undoStack.length - 1}
              canUndo={this.state.canUndo}
              onPublish={() => this.setState({ showPublish: true })}
              savingStatus={this.state.savingStatus}
            />
          )}
        </div>

        {!isFromIframe && (
          <Navigation
            {...this.props}
            style={{ width: mLeft, ...style.sidebarContainer }}
            content={content}
            listComponents={this.listComponents}
            mobileState={mobileState}
          />
        )}

        <div
          style={getMainContainerStyle(
            mobileState,
            show,
            this.state.hostFrameData
          )}
        >
          <div style={{ height: "100%" }}>
            {show === "seo" && (
              <div style={{ padding: 80 }}>
                <SEO {...this.props} />
              </div>
            )}
            {show === "email" && (
              <div style={{ padding: 35 }}>
                <EmailSettings
                  {...this.props}
                  hidden={false}
                  step={match.params.step}
                  showProvider={app.showProviderStep}
                />
              </div>
            )}
            {show === "merchant" && (
              <MerchantSettings
                {...this.props}
                hidden={false}
                step={match.params.step}
                showProvider={app.showProviderStep}
              />
            )}
            {show === "tracking" && (
              <div style={{ padding: 80 }}>
                <Tracking {...this.props} />
              </div>
            )}
            {show === "css" && (
              <div style={{ padding: 35 }}>
                <CSS {...this.props} />
              </div>
            )}
            {show === "exit" && (
              <div style={{ padding: 35, height: "100%" }}>
                <ExitIntent {...this.props} {...editorProps} />
              </div>
            )}
            {show === "history" && (
              <div style={{ padding: 35, height: "100%" }}>
                <History {...this.props} {...editorProps} />
              </div>
            )}
            {show === "templates" && (
              <Templates
                {...this.props}
                {...editorProps}
                tab={match.params.type}
                title={false}
              />
            )}
            {show === "builder" && <Builder {...this.props} {...editorProps} />}
          </div>
        </div>
      </div>
    );
  }
}

export default Editor;
