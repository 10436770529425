import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 100,
      },
      smartphone: {
        padding: 20,
      },
    },
  },
  HEADLINE: {
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    padding: 10,
  },
  TEXT: {
    padding: 10,
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: 250,
      maxWidth: "100%",
      borderRadius: 3,
      cursor: "pointer",
      margin: "30px auto",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
