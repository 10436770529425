import React from "react";
import { Link } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { FontIcon } from "@launchos/components/ui";

import { TabItem, icon } from "./styles";

export const MoreTab = ({ campaignId, nodeId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        style={{ position: "absolute", bottom: 0, width: 85 }}
        aria-controls="more_menu"
        aria-haspopup="true"
        onClick={handleMoreClick}
      >
        <TabItem>
          <FontIcon style={icon}>more_horiz</FontIcon>
          <div>More</div>
        </TabItem>
      </div>
      <Menu
        id="more_menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          style={{ color: "#333" }}
          to={`/campaign/${campaignId}/${nodeId}/exit`}
        >
          <MenuItem>
            <FontIcon style={{ ...icon, color: "#333" }}>exit_to_app</FontIcon>
            <div style={{ padding: 10, marginTop: -2 }}>
              Exit Intent Settings
            </div>
          </MenuItem>
        </Link>
        <Link
          style={{ color: "#333" }}
          to={`/campaign/${campaignId}/${nodeId}/css`}
        >
          <MenuItem>
            <FontIcon style={{ ...icon, color: "#333" }}>format_paint</FontIcon>
            <div style={{ padding: 10, marginTop: -2 }}>CSS for this Page</div>
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
};
