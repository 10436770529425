import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { SocialProofLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/SocialProof/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.THIRTEEN,
  layout: SocialProofLayouts.THIRTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof13.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkysae0n2d0l0780y3gavfbw",
    itemId: "tbudm6tzft",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 5 * 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.ICON,
                canHaveChildren: false,
                properties: {
                  display: "inline-block",
                  fontSize: "46pt",
                  textAlign: "center",
                  color: "#999",
                },
                iconType: IconTypes.FormatQuote,
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: `"${startCase(lorem.generateSentences(2))}"`,
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: `- ${startCase(lorem.generateWords(2))}`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 5 * 6, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                style: {
                  // backgroundColor: theme.backgroundColor,
                  maxWidth: 350,
                  // height: 250,
                  margin: "0 auto",
                },
              }),
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
