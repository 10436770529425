import React, { Component } from "react";
import { ListItem } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";

import { NiceMenu } from "./Helpers/NiceMenu";
import { Payload } from "./Payload";

import style from "../../style";
import { actions } from "./actions";

export class Interaction extends Component {
  constructor(props) {
    super(props);

    this.updateAction = this.updateAction.bind(this);
  }

  static contextType = AppConfig;

  updateAction(action, behavior) {
    const { setAction, myKey } = this.props;
    setAction(myKey || 0, action, behavior);
  }

  render() {
    // const { app } = this.context;
    const {
      label,
      action,
      behavior,
      setAction,
      myKey,
      onlyTheseActions = false,
    } = this.props;

    return (
      <div>
        <fieldset style={style.fieldset} title={label}>
          <legend>{label}</legend>
          <div style={{ padding: "5px 10px" }}>
            <NiceMenu label={action.name}>
              {actions
                .filter((itm) => {
                  if (!onlyTheseActions) return true;
                  return onlyTheseActions.indexOf(itm.type) > -1;
                })
                .map((itm, key) => (
                  <ListItem
                    key={key}
                    onClick={() => this.updateAction(itm, behavior)}
                  >
                    {itm.name}
                  </ListItem>
                ))}
            </NiceMenu>

            {action.hasOwnProperty("payload") ? (
              <Payload
                {...this.props}
                setAction={setAction}
                type={action.type}
                action={action}
                myKey={myKey}
              />
            ) : null}
          </div>
        </fieldset>
      </div>
    );
  }
}
