import * as React from "react";
import { get } from "lodash";
import { Switch, Grid, Cell } from "@launchos/components/ui";

import { CarouselProps } from "../types";

import { fieldset } from "@launchos/components/ui/theme";

export const CarouselOptions: React.FC<CarouselProps> = ({
  onChange,
  options,
}) => {
  const [arrows, setArrows] = React.useState<boolean>(
    get(options, "arrows", false)
  );
  const [clickToChange, setclickToChange] = React.useState<boolean>(
    get(options, "clickToChange", false)
  );
  const [centered, setCentered] = React.useState<boolean>(
    get(options, "centered", false)
  );
  const [swipeable, setSwipeable] = React.useState<boolean>(
    get(options, "swipeable", false)
  );
  const [dots, setDots] = React.useState<boolean>(get(options, "dots", false));
  const [autoPlay, setAutoplay] = React.useState<boolean>(
    get(options, "autoPlay", false)
  );

  const handleChange = (opt, checked) => {
    onChange(opt, checked);
  };

  const toggleCheck = (opt, checked) => {
    switch (opt) {
      case "arrows":
        setArrows(checked);
        break;
      case "clickToChange":
        setclickToChange(checked);
        break;
      case "centered":
        setCentered(checked);
        break;
      case "swipeable":
        setSwipeable(checked);
        break;
      case "dots":
        setDots(checked);
        break;
      case "autoPlay":
        setAutoplay(checked);
        break;
      default:
        break;
    }

    handleChange(opt, checked);
  };

  return (
    <div>
      <fieldset style={fieldset}>
        <legend>Carousel Options</legend>
        <Grid>
          <Cell style={{ width: "50%" }}>
            <Switch
              checked={arrows}
              color="primary"
              onChange={(e, checked) => {
                toggleCheck("arrows", checked);
              }}
            />
            <span style={{ position: "relative", fontSize: "9pt" }}>
              Arrows
            </span>
          </Cell>
          <Cell>
            <Switch
              checked={clickToChange}
              color="primary"
              onChange={(e, checked) => {
                toggleCheck("clickToChange", checked);
              }}
            />
            <span style={{ position: "relative", fontSize: "8pt" }}>
              Click to Change
            </span>
          </Cell>
        </Grid>
        <Grid>
          <Cell style={{ width: "50%" }}>
            <Switch
              checked={centered}
              color="primary"
              onChange={(e, checked) => {
                toggleCheck("centered", checked);
              }}
            />
            <span style={{ position: "relative", fontSize: "9pt" }}>
              Centered
            </span>
          </Cell>
          <Cell>
            <Switch
              checked={swipeable}
              color="primary"
              onChange={(e, checked) => {
                toggleCheck("swipeable", checked);
              }}
            />
            <span style={{ position: "relative", fontSize: "9pt" }}>
              Swipeable
            </span>
          </Cell>
        </Grid>
        <Grid>
          <Cell style={{ width: "50%" }}>
            <Switch
              checked={dots}
              color="primary"
              onChange={(e, checked) => {
                toggleCheck("dots", checked);
              }}
            />
            <span style={{ position: "relative", fontSize: "9pt" }}>
              Show Dots
            </span>
          </Cell>
          <Cell>
            <Switch
              checked={autoPlay}
              color="primary"
              onChange={(e, checked) => {
                toggleCheck("autoPlay", checked);
              }}
            />
            <span style={{ position: "relative", fontSize: "9pt" }}>
              Autoplay
            </span>
          </Cell>
        </Grid>
      </fieldset>
    </div>
  );
};
