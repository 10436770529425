import _ from "lodash";
import React, { Component } from "react";
import {
  Dialog,
  DialogBody,
  Grid,
  Cell,
  Button,
  Menu,
  ListItem,
  Step,
  StepLabel,
  Stepper
} from "@launchos/components/ui";
import { client } from "@launchos/api/gql";
import { getCampaign } from "@launchos/api/gql/campaigns";
import style from "./style";

class ChooseCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignId: false,
      label: _.get(props, "settings.campaignName", "Choose a Campaign...")
    };
    this.updateCampaign = this.updateCampaign.bind(this);
  }

  updateCampaign(d) {
    const { updateSettings, settings } = this.props;
    this.setState({ campaignId: d.id, label: d.name }, () => {
      updateSettings({ ...settings, campaignId: d.id, campaignName: d.name });
    });
  }

  render() {
    const { label } = this.state;
    const { changeStep, allCampaignsQuery, onClose } = this.props;
    const { campaigns } = allCampaignsQuery;

    return (
      <div>
        <img
          style={{ margin: 10, width: 100 }}
          alt=""
          src="https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_pageredirect.png"
        />

        <h2>Select the funnel that you would like to link to...</h2>

        <div style={{ ...style.selectField, width: 350, margin: "30px auto" }}>
          <Menu style={{ width: "100%" }} label={label}>
            <div style={{ maxHeight: 300, overflowY: "auto" }}>
              {campaigns.map(d => (
                <ListItem onClick={() => this.updateCampaign(d)} key={d.id}>
                  {d.name}
                </ListItem>
              ))}
            </div>
          </Menu>
        </div>

        <Grid justify="space-evenly" style={{ width: 250, margin: "0 auto" }}>
          <Cell align="center">
            <Button onClick={() => changeStep("page")} large tertiary>
              Next
            </Button>
          </Cell>

          <Cell align="center">
            <Button
              onClick={onClose}
              large
              transparent
              style={{ color: "#333" }}
            >
              Cancel
            </Button>
          </Cell>
        </Grid>
      </div>
    );
  }
}

class ChoosePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objectId: false,
      label: _.get(props, "settings.objectName", "Choose a Page..."),
      objects: []
    };

    this.updatePage = this.updatePage.bind(this);
  }

  async componentDidMount() {
    const { settings } = this.props;
    const { data } = await client.query({
      query: getCampaign,
      variables: {
        id: settings.campaignId
      }
    });

    const { objects } = data.campaign;

    this.setState({ objects });
  }

  updatePage(d) {
    const { updateSettings, settings } = this.props;
    this.setState({ objectId: d.id, label: d.name }, () => {
      updateSettings({ ...settings, objectId: d.id, objectName: d.name });
    });
  }

  render() {
    const { label, objects } = this.state;
    const { changeStep, onClose } = this.props;

    return (
      <div>
        <img
          style={{ margin: 10, width: 100 }}
          alt=""
          src="https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_pageredirect.png"
        />

        <h2>Now choose the page in that funnel...</h2>

        <div style={{ ...style.selectField, width: 350, margin: "30px auto" }}>
          <Menu style={{ width: "100%" }} label={label}>
            <div style={{ maxHeight: 300, overflowY: "auto" }}>
              {objects
                .filter(obj => _.has(obj, "page.slug"))
                .map(d => (
                  <ListItem onClick={() => this.updatePage(d)} key={d.id}>
                    {d.name}
                  </ListItem>
                ))}
            </div>
          </Menu>
        </div>

        <Grid justify="space-evenly" style={{ width: 250, margin: "0 auto" }}>
          <Cell align="center">
            <Button onClick={() => changeStep("success")} large tertiary>
              Next
            </Button>
          </Cell>
          <Cell align="center">
            <Button
              onClick={onClose}
              large
              transparent
              style={{ color: "#333" }}
            >
              Cancel
            </Button>
          </Cell>
        </Grid>
      </div>
    );
  }
}

const Success = props => {
  const { saveSettings } = props;

  return (
    <div>
      <img
        style={{ margin: 10, height: 100 }}
        alt="Success Indicator"
        src="https://s3.amazonaws.com/sandcastleassets/images/success_check.png"
      />
      <h2>Your Funnel Has Been Updated</h2>
      <Grid justify="space-evenly" style={{ width: 250, margin: "0 auto" }}>
        <Cell align="center">
          <Button onClick={saveSettings} large tertiary>
            Save
          </Button>
        </Cell>
        <Cell align="center">
          <Button
            onClick={props.onClose}
            large
            transparent
            style={{ color: "#333" }}
          >
            Cancel
          </Button>
        </Cell>
      </Grid>
    </div>
  );
};

export default class SelectPagePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: "campaign",
      // url: _.get(props, "options.settings.url", ""),
      settings: _.get(props, "options.settings", {})
    };

    this.changeStep = this.changeStep.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
  }

  updateSettings(settings) {
    this.setState({ settings });
  }

  changeStep(step) {
    this.setState({ step });
  }

  async saveSettings() {
    const { options, actions } = this.props;
    const { settings } = this.state;
    const { updateObjectSettings } = actions;

    await updateObjectSettings({
      id: options.id,
      settings
    });

    window.location.reload();
  }

  render() {
    const { step, settings } = this.state;
    const steps = ["campaign", "page", "success"];
    return (
      <Dialog {...this.props} style={{ width: 700 }} centered>
        <DialogBody>
          <div style={style.stepper}>
            <Stepper activeStep={steps.indexOf(step)}>
              <Step style={style.step}>
                <StepLabel>Choose Campaign</StepLabel>
              </Step>
              <Step style={style.step}>
                <StepLabel>Choose Page</StepLabel>
              </Step>
              <Step style={style.step}>
                <StepLabel>Success</StepLabel>
              </Step>
            </Stepper>
          </div>

          {step === "campaign" && (
            <ChooseCampaign
              {...this.props}
              changeStep={this.changeStep}
              saveSettings={this.saveSettings}
              updateSettings={this.updateSettings}
              settings={settings}
            />
          )}
          {step === "page" && (
            <ChoosePage
              {...this.props}
              changeStep={this.changeStep}
              saveSettings={this.saveSettings}
              updateSettings={this.updateSettings}
              settings={settings}
            />
          )}
          {step === "success" && (
            <Success
              {...this.props}
              changeStep={this.changeStep}
              saveSettings={this.saveSettings}
              updateSettings={this.updateSettings}
              settings={settings}
            />
          )}
        </DialogBody>
      </Dialog>
    );
  }
}
