import { IComponentSettings, ComponentTypes } from "../../types";

const settings: IComponentSettings = {
  id: ComponentTypes.VIDEO,
  name: ComponentTypes.VIDEO,
  thumbnail: "comp_video.png",
  default: {
    type: ComponentTypes.VIDEO,
    src: {
      mp4: "https://www.youtube.com/watch?v=OfIQW6s1-ew",
      ogg: "https://www.youtube.com/watch?v=OfIQW6s1-ew",
    },
    properties: {
      textAlign: "center",
      display: "inline-block",
      width: "100%",
    },
  },
};

export default settings;
