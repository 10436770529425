import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: { padding: 100 },
      smartphone: { padding: 20 },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 40,
  },
  ITEM_HEADLINE: {
    textAlign: "left",
    fontSize: "16pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 20,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
};
