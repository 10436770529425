import * as React from "react";
import _ from "lodash";
import { get } from "lodash";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import { CURSOR_ID } from "@launchos/modules/editor/Builder/WebComponent/types";

import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import { Carousel } from "./live";
import { CarouselProps } from "./types";
import Properties from "./Properties";

import { addNewCarouselItem, switchCarouselItem } from "./scripts";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

export const CarouselEditorVersion: React.FC<CarouselProps> = (props) => {
  const {
    settings,
    id,
    pageContent = {},
    updateComponentSettings,
    updateContent,
    children,
  } = props;

  let isEmpty = false;
  const childContent = pageContent.filter((itm) => itm.parent === settings.id);
  if (childContent.length)
    isEmpty = !!(childContent.length === 1 && childContent[0].id === CURSOR_ID);
  else isEmpty = true;

  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Carousel"
        id={id}
        {...props}
        PropertiesView={Properties}
        dropDownItems={(items) => {
          const key = items.findIndex(({ id }) => id === "divider");
          return [
            ...items.slice(0, key),
            ...[
              { id: "divider" },
              ...settings.items.map(({ id, label, active = false }) => ({
                id,
                text: `Switch to "${label}"`,
                icon: active ? "done" : "check_box_outline_blank",
              })),
              { id: "ADD", text: "Add a New Item", icon: "add_circle" },
              { id: "divider" },
            ],
            ...items.slice(key + 1),
          ];
        }}
        onDropDownChange={(id) => {
          if (id === "ADD") {
            addNewCarouselItem({
              settings,
              pageContent,
              updateContent,
              updateComponentSettings,
            });
          } else {
            // Switch To Carousel Item
            switchCarouselItem({
              updateComponentSettings,
              settings,
              carouselItemId: id,
              mode: EditorMode.EDITOR,
            });
          }
          return true;
        }}
      >
        <Carousel
          {...settings}
          properties={properties}
          testId="WC-CAROUSEL-EDIT"
          mode={EditorMode.EDITOR}
          onSwitch={(carouselItemId) => {
            switchCarouselItem({
              settings,
              updateComponentSettings,
              carouselItemId,
              mode: EditorMode.EDITOR,
            });
          }}
        >
          {/* Only show if carousel item exists in carousel item list */}
          {React.Children.toArray(children).filter((child) => {
            if (
              get(settings, "items", []).findIndex(
                ({ id }) => id === child.props.settings.itemId
              ) > -1
            )
              return child;
          })}
        </Carousel>
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default CarouselEditorVersion;
