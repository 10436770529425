import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import EditorSocial from "./Social";
import settings from "./settings";

// export const onListItems = (hook, payload, actions) => {
//   if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
//     return [settings];
//   }
// };

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Social") {
    return [EditorSocial];
  }
};
