import { uniqueId } from "lodash";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import theme from "@launchos/components/ui/theme";
import { IComponentSettings, ComponentTypes } from "../../types";
import { lorem } from "../../../misc/v2/blocks/weblayouts/utils";
import { IconTypes } from "../Icon";
import layoutTheme from "@launchos/plugins/misc/v2/blocks/weblayouts/theme";
import { OrderSummaryItemTypes, OrderSummaryDisplayOptions } from "./types";

const style = {
  LISTITEM: {
    style: {
      fontFamily: layoutTheme.fontFamily,
      padding: "10px 0",
    },
    iconStyle: {
      borderRadius: "100%",
      padding: 10,
      // color: layoutTheme.backgroundColor,
    },
    contentStyle: {
      padding: 10,
    },
  },
};

const settings: IComponentSettings = {
  id: ComponentTypes.ORDERSUMMARY,
  name: "Order Summary",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 7,
    },
    icon: "list_alt",
  },
  default: {
    type: ComponentTypes.ORDERSUMMARY,
    data: [
      {
        id: uniqueId(),
        title: "Product A",
        itemType: OrderSummaryItemTypes.PRODUCT,
        value: 2.25,
      },
      {
        id: uniqueId(),
        title: "Product B",
        itemType: OrderSummaryItemTypes.PRODUCT,
        value: 2.25,
      },
      {
        id: uniqueId(),
        title: "Special Bonus Gift",
        itemType: OrderSummaryItemTypes.PRODUCT,
        value: "FREE",
      },
      // {
      //   id: uniqueId(),
      //   title: "Promo Code Applied",
      //   itemType: OrderSummaryItemTypes.PROMOCODE,
      //   // value: "FREE",
      //   codes: [
      //     {
      //       id: uniqueId(),
      //       key: uniqueId(),
      //       code: "TESTCODEA",
      //       discount: "5%",
      //     },
      //     {
      //       id: uniqueId(),
      //       key: uniqueId(),
      //       code: "TESTCODEB",
      //       discount: "15%",
      //     },
      //     {
      //       id: uniqueId(),
      //       key: uniqueId(),
      //       code: "TESTCODEC",
      //       discount: "$18",
      //     },
      //   ],
      // },
    ],
    properties: {
      display: "inline-block",
      width: "100%",
      padding: "0.9375rem",
      fontSize: "0.857rem",
    },
    displayOptions: {
      [OrderSummaryDisplayOptions.HEADER]: true,
      [OrderSummaryDisplayOptions.PRODUCTS]: true,
      [OrderSummaryDisplayOptions.TOTAL]: true,
      [OrderSummaryDisplayOptions.SUBTOTAL]: false,
    },
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "SummaryBuilderSettings",
          sectionWrap: true,
          settings: {
            label: "Order Summary Builder",
            icon: "construction",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "OrderSummaryDisplay",
          sectionWrap: true,
          settings: {
            label: "What to Show",
            icon: "settings",
            isExpanded: false,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.TYPOGRAPHY,
          settings: {
            label: "Fonts",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Width",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default settings;
