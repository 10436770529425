import * as React from "react";
import styled from "styled-components";
import { has } from "lodash";

import theme from "@launchos/components/ui/theme";

import { SectionsLegendProps } from "./types";
import SectionItem from "./SectionItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon, IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { EditorObjectState } from "@launchos/modules/v2/Editor/components/EditorObject/types";

const styles = {
  container: {
    border: `1px solid #DDD`,
    borderRadius: 4,
  },
};

const StyledButton = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-bottom-width: 2px;
  border-radius: 4px;
  cursor: pointer;
  color: ${theme.primaryColor};
  &:hover {
    background-color: #f4f4f4;
  }
`;

/**
 * A quick at-a-glance way to see a manage all the sections on that page
 *
 * - • As a user I would like to see all the sections on my page...
 * - • As a user I would like a way to easily open the [Add New Section] drawer
 * - • As a user I would like to reorder them up and down using drag-and-drop and have the order immediately reflected in the editor canvas
 * - • As a user I would like to toggle between seeing and not seeing this view in case I want to focus only on the editor canvas
 * - • As a user I would like the section in the editor canvas to highlight when i mouse over
 * - • As a user I would like the section in the editor canvas to activate when I click on the section
 */
const SectionsLegend: React.FC<SectionsLegendProps> = ({
  title = "Page Sections",
  sections = [],
  onChange = () => null,
  onCreate = () => null,
  onChangeState = () => null,
  style = {},
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(true);

  const pageSections = sections;

  /**
   * Shows or Hides the <SectionLegend /> component
   * @params
   */
  const showHideToggle = (): void => {
    setIsVisible(!isVisible);
  };

  // this will re-order the sections
  // const onDragEnd = (result) => {
  //   if (!result.destination) return;

  //   console.log({ result });

  //   const sIndex = result.source.index;
  //   const dIndex = result.destination.index;

  //   // remove source item
  //   const withoutSource = pageSections.filter((itm, k) => k !== sIndex);

  //   // add source item next to destination item
  //   const content = [
  //     ...withoutSource.slice(0, dIndex),
  //     pageSections[sIndex],
  //     ...withoutSource.slice(dIndex),
  //   ];

  //   onChange(content);
  // };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceItem = pageSections[result.source.index];
    const destinationItem = pageSections[result.destination.index];

    onChange(sourceItem.id, destinationItem.id);
  };

  return (
    <div data-testid="Blocks-SectionsLegend">
      <div style={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isVisible && <h4 style={{ margin: 0 }}>{title.toUpperCase()}</h4>}
          <button
            data-testid="Blocks-SectionsLegend-ShowHideToggle"
            onClick={showHideToggle}
            style={{
              border: "none",
              background: "transparent",
              padding: 0,
              margin: 0,
              cursor: "pointer",
            }}
          >
            {isVisible ? `Hide` : `Show Sections`}
          </button>
        </div>
        {isVisible && (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    style={styles.container}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {pageSections.map((section, key) => (
                      <Draggable
                        key={section.id}
                        draggableId={section.id}
                        index={key}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SectionItem
                              {...section}
                              onMouseEnter={() =>
                                onChangeState(
                                  section.id,
                                  EditorObjectState.HOVER
                                )
                              }
                              onMouseLeave={() =>
                                onChangeState(
                                  section.id,
                                  EditorObjectState.NORMAL
                                )
                              }
                              onClick={() =>
                                onChangeState(
                                  section.id,
                                  EditorObjectState.ACTIVE
                                )
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </div>

      <StyledButton
        data-testid="Blocks-SectionsLegend-AddNewButton"
        onClick={onCreate}
        style={{
          ...style,
          top: "inherit",
          ...(has(style, "bottom") ? { bottom: style.bottom - 80 } : {}),
        }}
      >
        <div style={{ display: "flex" }}>
          <span
            style={{
              backgroundColor: "#EEE",
              borderRadius: 3,
              padding: "2px 4px",
              marginRight: 10,
            }}
          >
            <Icon type={IconTypes.Add} />
          </span>
          <div style={{ padding: 5, fontSize: "11pt" }}>Add New Section</div>
        </div>
      </StyledButton>
    </div>
  );
};

export default SectionsLegend;
