import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 100,
        width: 650,
      },
      smartphone: {
        padding: 20,
        width: "100%",
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    mobile: {
      desktop: {
        fontSize: "36pt",
        lineHeight: "52px",
        fontWeight: "bold" as "bold",
      },
      smartphone: {
        fontSize: "24pt",
        lineHeight: "36px",
        fontWeight: "normal",
      },
    },
    // fontFamily: theme.fontFamily,
    color: "black",
    fontFamily: "Poppins",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
      marginTop: 20,
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%", marginTop: 20 },
    inputStyle: {
      width: "100%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.backgroundColor}`,
      borderRadius: 5,
      // color: theme.foregroundColor
    },
  },
};
