import React from "react";
import { has } from 'lodash';
import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";

import CodeMirror from "react-codemirror";


import {
  Button,
  Cell,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Grid,
  TextInput,
  Tab, Tabs,
  NiceMenu,
  ListItem
} from "@launchos/components/ui";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const WebHookEditor = props => {
  const { hideWebHookEditor, integration, createIntegration, updateIntegration, allIntegrationQuery } = props;
  const [variables, setVariables] = React.useState({ name: "", url: "", icon: "", body: "{}", settings: "{}", method: "POST" })
  const [activeTab, setActiveTab] = React.useState(0)

  const handleSave = async () => {
    // console.log(variables)

    // make sure valid json
    if (!isJsonString(variables.body) || !isJsonString(variables.settings)) {

      alert("Please use valid JSON in Field Mapping and User Field tabs");

    } else {

      if (has(integration, 'id')) {
        await updateIntegration({ variables: { id: integration.id, type: "webhook", setting: variables, userId: localStorage.userId } })
      } else {
        await createIntegration({ variables: { type: "webhook", setting: variables, userId: localStorage.userId } });
      }

      await allIntegrationQuery.refetch({ userId: localStorage.userId });
      hideWebHookEditor();

    }

  }

  React.useEffect(() => {
    window.scroll(0, 0);
    if (has(props.integration, 'id')) setVariables({ ...variables, ...props.integration.setting })
  }, [])

  return (
    <Dialog {...props} style={{ width: 700 }} centered onClose={hideWebHookEditor}>
      <DialogTitle>
        <div>
          <img style={{ width: 60 }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtDJgneZ_dezqakp5msHb0dXVfaZdJ2lztRH1y_e_ZM-WOeFc" />
        </div>
        Add a Custom Webhook Integration
      </DialogTitle>

      <DialogBody>
        <div style={{ paddingBottom: 20 }}>
          <p>
            Webhooks let you notify a custom server whenever you get a new lead or sale
          </p>
          <p>
            Create the web hook profile below, then open the editor and choose it in campaign settings
          </p>
        </div>
        <Tabs transparent>
          <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>Required</Tab>
          <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Field Mapping</Tab>
          <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>User Fields</Tab>
          {/* <Tab>Headers</Tab> */}
          {/* <Tab>Custom Fields</Tab> */}
        </Tabs>

        {activeTab === 0 && (
          <div style={{ padding: 20 }}>
            <TextInput value={variables.name} onChange={(e) => setVariables({ ...variables, name: e.target.value })} placeholder="Name of Integration" style={{ textAlign: 'left' }} />
            <TextInput value={variables.icon} onChange={(e) => setVariables({ ...variables, icon: e.target.value })} placeholder="Icon URL" style={{ textAlign: 'left' }} />
            <TextInput value={variables.url} onChange={(e) => setVariables({ ...variables, url: e.target.value })} placeholder="Web Hook URL" style={{ textAlign: 'left' }} />
            <NiceMenu label={variables.method} style={{ textAlign: 'left', padding: 5 }}>
              <ListItem onClick={() => setVariables({ ...variables, method: "POST" })}>POST</ListItem>
              <ListItem onClick={() => setVariables({ ...variables, method: "GET" })}>GET</ListItem>
            </NiceMenu>
          </div>
        )}

        {activeTab === 1 && (
          <div style={{ padding: 20 }}>
            <CodeMirror
              value={variables.body}
              className="cdmirror"
              options={{ mode: { name: "javascript", json: true }, lineNumbers: true }}
              onChange={(editor) => {
                setVariables({ ...variables, body: editor })
              }}
              autoFocus
            />
          </div>
        )}

        {activeTab === 2 && (
          <div style={{ padding: 20 }}>
            <CodeMirror
              value={variables.settings}
              className="cdmirror"
              options={{ mode: { name: "javascript", json: true }, lineNumbers: true }}
              onChange={(editor) => {
                setVariables({ ...variables, settings: editor })
              }}
              autoFocus
            />
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Grid justify="space-evenly">
          <Cell align="center">
            <Button onClick={handleSave} secondary large>Save</Button>
          </Cell>
          <Cell align="center">
            <Button onClick={hideWebHookEditor} transparent style={{ color: 'black' }} large>Cancel</Button>
          </Cell>
        </Grid>
      </DialogFooter>
    </Dialog>
  )
}

export default WebHookEditor;