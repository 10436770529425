import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.TWO,
  layout: FormLayouts.TWO,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyu67qy2e6c07805fbyk6cj",
    itemId: "7posaeg0hrq",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: "20px 0",
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      padding: 0,
                    },
                    html: "Contact Us",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      mobile: {
                        desktop: {
                          marginTop: 150,
                        },
                        smartphone: {
                          marginTop: 10,
                        },
                      },
                    },
                    html: "Company@domain.com",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: "123 Elm Street, City, RI 12345",
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    type: FormFieldTypes.TEXT,
                    name: "name",
                    placeholder: "Name",
                    preset: FieldNamePresets.NAME,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: "email",
                    placeholder: "Email",
                    preset: FieldNamePresets.EMAIL,
                  },
                  {
                    type: FormFieldTypes.TEXTAREA,
                    name: "comment",
                    placeholder: "Write something...",
                  },
                ],
                properties: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                },
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: style.BUTTON.style,
                caption1: "Send",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
