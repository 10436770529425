import * as React from "react";
import ReactJson from "react-json-view";
import { get } from "lodash";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import {
  PluginProps,
  OrderSummaryTypes,
  OrderSummaryDisplayOptions,
} from "./types";
import { OrderSummary, getCellStyle, getFooterCellStyle } from "./live";

import Properties from "./Properties";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

/**
 * Ability to create bulleted list
 */
export const EditorList: React.FC<PluginProps> = (props) => {
  const { id, settings } = props;
  const properties = convertProperties(settings.properties);
  const { data, displayOptions } = settings;

  return (
    <>
      <V1ObjectWrapper settings={settings} showOverlay={true}>
        <EditorObject
          label="Order Summary"
          id={id}
          {...props}
          PropertiesView={Properties}
        // debug
        >
          <OrderSummary
            {...settings}
            properties={properties}
            cellStyle={getCellStyle(properties)}
            footerCellStyle={getFooterCellStyle(properties)}
            cart={data.map((itm) => ({
              ...itm,
              title: get(itm, "caption", itm.title),
            }))}
            showHeader={get(
              displayOptions,
              OrderSummaryDisplayOptions.HEADER,
              false
            )}
            showSubtotal={get(
              displayOptions,
              OrderSummaryDisplayOptions.SUBTOTAL,
              false
            )}
            showTotal={get(
              displayOptions,
              OrderSummaryDisplayOptions.TOTAL,
              false
            )}
            showCart={get(
              displayOptions,
              OrderSummaryDisplayOptions.PRODUCTS,
              false
            )}
            style={properties}
            type={OrderSummaryTypes.STANDARD}
            mode={EditorMode.EDITOR}
          />
          {/* <ReactJson src={settings} /> */}
        </EditorObject>
      </V1ObjectWrapper>
    </>
  );
};

export default EditorList;
