import _ from "lodash";
import React, { Component } from "react";
import {
  Button,
  Cell,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Grid,
  TextInput
} from "@launchos/components/ui";
// import canvasStyle from "../../page/style";
import providers from "./integrationProviders";

/**
 * This is a dialog box (popup window) that shows the settings we have on file
 * for the user...
 * @param {object} props The props
 * @returns {function} The component
 */

class IntegrationEditor extends Component {
  constructor(props) {
    super(props);

    let fields = {};
    this.providerInfo = _.filter(
      providers,
      o => o.id === props.integration.type
    )[0];

    this.providerInfo.fields.forEach(field => {
      fields[field.name] = props.integration.setting[field.name];
    });

    this.state = {
      fields
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    window.scroll(0, 0);
  }

  handleChange(field, e) {
    let item = {};
    item[field] = e.target.value;

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ...item
      }
    }));
  }

  async handleSave() {
    const variables = _.has(this.props.integration, "id")
      ? {
        id: this.props.integration.id,
        type: this.props.integration.type,
        setting: this.state.fields,
        userId: localStorage.userId
      }
      : {
        type: this.props.integration.type,
        setting: this.state.fields,
        userId: localStorage.userId
      };

    if (_.has(this.props.integration, "id")) {
      await this.props.updateIntegration({ variables });
      await this.props.allIntegrationQuery.refetch({
        userId: localStorage.userId
      });

      this.props.onSave(variables);
      this.props.hideIntegrationEditor();
    } else {
      await this.props.createIntegration({ variables });
      await this.props.allIntegrationQuery.refetch({
        userId: localStorage.userId
      });

      this.props.onSave(variables);
      this.props.hideIntegrationEditor();
    }
  }

  render() {
    return (
      <Dialog
        {...this.props}
        // style={canvasStyle.settingsPopup}
        // overlayStyle={canvasStyle.bgSettingsPopup}
        centered
        onClose={() => this.props.hideIntegrationEditor()}
      >
        <DialogTitle>
          <img alt="" src={this.providerInfo.img} style={{ height: 75 }} />
          <br />
          {this.props.integration.caption} Settings
        </DialogTitle>
        <DialogBody>
          {this.providerInfo.fields.map((field, key) => (
            <TextInput
              key={key}
              size="large"
              // multiline={_.get(field, "fieldType", "text") === "textarea"}
              placeholder={field.caption}
              value={this.state.fields[field.name]}
              onChange={e => this.handleChange(field.name, e)}
            />
          ))}
          {_.has(this.providerInfo, "support") ? (
            <p style={{ padding: 20 }}>
              <a
                href={this.providerInfo.support.url}
                rel="noopener noreferrer"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                {this.providerInfo.support.title}
                {/* Click here for step by step instructions */}
              </a>
            </p>
          ) : null}
        </DialogBody>
        <DialogFooter>
          <Grid justify="space-evenly">
            <Cell align="center">
              <Button
                onClick={this.handleSave}
                secondary
                large
                style={{ margin: 10 }}
              >
                Save
              </Button>
              <Button
                onClick={() => this.props.hideIntegrationEditor()}
                transparent
                large
                style={{ margin: 10, color: "black" }}
              >
                Cancel
              </Button>
            </Cell>
          </Grid>
        </DialogFooter>
      </Dialog>
    );
  }
}

IntegrationEditor.propTypes = {};
IntegrationEditor.defaultProps = {
  onSave: () => false
};

export default IntegrationEditor;
