export default [
  // 'Titillium Web:300,400,700',
  // 'Kirang Haerang:300,400,700',
  // 'ABeeZee:300,400,700',
  // 'Abel:300,400,700',
  "Abhaya Libre:300,400,700",
  "Abril Fatface:300,400,700",
  "Aclonica:300,400,700",
  "Acme:300,400,700",
  "Actor:300,400,700",
  "Adamina:300,400,700",
  "Advent Pro:300,400,700",
  "Aguafina Script:300,400,700",
  "Akronim:300,400,700",
  "Aladin:300,400,700",
  "Aldrich:300,400,700",
  "Alef:300,400,700",
  "Alegreya:300,400,700",
  "Alegreya SC:300,400,700",
  "Alegreya Sans:300,400,700",
  "Alegreya Sans SC:300,400,700",
  "Alex Brush:300,400,700",
  "Alfa Slab One:300,400,700",
  "Alice:300,400,700",
  "Alike:300,400,700",
  "Alike Angular:300,400,700",
  "Allan:300,400,700",
  "Allerta:300,400,700",
  "Allerta Stencil:300,400,700",
  "Allura:300,400,700",
  "Almendra:300,400,700",
  "Almendra Display:300,400,700",
  "Almendra SC:300,400,700",
  "Amarante:300,400,700",
  "Amaranth:300,400,700",
  "Amatic SC:300,400,700",
  "Amatica SC:300,400,700",
  "Amethysta:300,400,700",
  "Amiko:300,400,700",
  "Amiri:300,400,700",
  "Amita:300,400,700",
  "Anaheim:300,400,700",
  "Andada:300,400,700",
  "Andika:300,400,700",
  "Angkor:300,400,700",
  "Annie Use Your Telescope:300,400,700",
  "Anonymous Pro:300,400,700",
  "Antic:300,400,700",
  "Antic Didone:300,400,700",
  "Antic Slab:300,400,700",
  "Anton:300,400,700",
  "Arapey:300,400,700",
  "Arbutus:300,400,700",
  "Arbutus Slab:300,400,700",
  "Architects Daughter:300,400,700",
  "Archivo:300,400,700",
  "Archivo Black:300,400,700",
  "Archivo Narrow:300,400,700",
  "Aref Ruqaa:300,400,700",
  "Arima Madurai:300,400,700",
  "Arimo:300,400,700",
  "Arizonia:300,400,700",
  "Armata:300,400,700",
  "Arsenal:300,400,700",
  "Artifika:300,400,700",
  "Arvo:300,400,700",
  "Arya:300,400,700",
  "Asap:300,400,700",
  "Asap Condensed:300,400,700",
  "Asar:300,400,700",
  "Asset:300,400,700",
  "Assistant:300,400,700",
  "Astloch:300,400,700",
  "Asul:300,400,700",
  "Athiti:300,400,700",
  "Atma:300,400,700",
  "Atomic Age:300,400,700",
  "Aubrey:300,400,700",
  "Audiowide:300,400,700",
  "Autour One:300,400,700",
  "Average:300,400,700",
  "Average Sans:300,400,700",
  "Averia Gruesa Libre:300,400,700",
  "Averia Libre:300,400,700",
  "Averia Sans Libre:300,400,700",
  "Averia Serif Libre:300,400,700",
  "Bad Script:300,400,700",
  "Bahiana:300,400,700",
  "Baloo:300,400,700",
  "Baloo Bhai:300,400,700",
  "Baloo Bhaijaan:300,400,700",
  "Baloo Bhaina:300,400,700",
  "Baloo Chettan:300,400,700",
  "Baloo Da:300,400,700",
  "Baloo Paaji:300,400,700",
  "Baloo Tamma:300,400,700",
  "Baloo Tammudu:300,400,700",
  "Baloo Thambi:300,400,700",
  "Balthazar:300,400,700",
  "Bangers:300,400,700",
  "Barrio:300,400,700",
  "Basic:300,400,700",
  "Battambang:300,400,700",
  "Baumans:300,400,700",
  "Bayon:300,400,700",
  "Belgrano:300,400,700",
  "Bellefair:300,400,700",
  "Belleza:300,400,700",
  "BenchNine:300,400,700",
  "Bentham:300,400,700",
  "Berkshire Swash:300,400,700",
  "Bevan:300,400,700",
  "Bigelow Rules:300,400,700",
  "Bigshot One:300,400,700",
  "Bilbo:300,400,700",
  "Bilbo Swash Caps:300,400,700",
  "BioRhyme:300,400,700",
  "BioRhyme Expanded:300,400,700",
  "Biryani:300,400,700",
  "Bitter:300,400,700",
  "Black Ops One:300,400,700",
  "Bokor:300,400,700",
  "Bonbon:300,400,700",
  "Boogaloo:300,400,700",
  "Bowlby One:300,400,700",
  "Bowlby One SC:300,400,700",
  "Brawler:300,400,700",
  "Bree Serif:300,400,700",
  "Bubblegum Sans:300,400,700",
  "Bubbler One:300,400,700",
  "Buda:300,400,700",
  "Buenard:300,400,700",
  "Bungee:300,400,700",
  "Bungee Hairline:300,400,700",
  "Bungee Inline:300,400,700",
  "Bungee Outline:300,400,700",
  "Bungee Shade:300,400,700",
  "Butcherman:300,400,700",
  "Butterfly Kids:300,400,700",
  "Cabin:300,400,700",
  "Cabin Condensed:300,400,700",
  "Cabin Sketch:300,400,700",
  "Caesar Dressing:300,400,700",
  "Cagliostro:300,400,700",
  "Cairo:300,400,700",
  "Calligraffitti:300,400,700",
  "Cambay:300,400,700",
  "Cambo:300,400,700",
  "Candal:300,400,700",
  "Cantarell:300,400,700",
  "Cantata One:300,400,700",
  "Cantora One:300,400,700",
  "Capriola:300,400,700",
  "Cardo:300,400,700",
  "Carme:300,400,700",
  "Carrois Gothic:300,400,700",
  "Carrois Gothic SC:300,400,700",
  "Carter One:300,400,700",
  "Catamaran:300,400,700",
  "Caudex:300,400,700",
  "Caveat:300,400,700",
  "Caveat Brush:300,400,700",
  "Cedarville Cursive:300,400,700",
  "Ceviche One:300,400,700",
  "Changa:300,400,700",
  "Changa One:300,400,700",
  "Chango:300,400,700",
  "Chathura:300,400,700",
  "Chau Philomene One:300,400,700",
  "Chela One:300,400,700",
  "Chelsea Market:300,400,700",
  "Chenla:300,400,700",
  "Cherry Cream Soda:300,400,700",
  "Cherry Swash:300,400,700",
  "Chewy:300,400,700",
  "Chicle:300,400,700",
  "Chivo:300,400,700",
  "Chonburi:300,400,700",
  "Cinzel:300,400,700",
  "Cinzel Decorative:300,400,700",
  "Clicker Script:300,400,700",
  "Coda:300,400,700",
  "Coda Caption:300,400,700",
  "Codystar:300,400,700",
  "Coiny:300,400,700",
  "Combo:300,400,700",
  "Comfortaa:300,400,700",
  "Coming Soon:300,400,700",
  "Concert One:300,400,700",
  "Condiment:300,400,700",
  "Content:300,400,700",
  "Contrail One:300,400,700",
  "Convergence:300,400,700",
  "Cookie:300,400,700",
  "Copse:300,400,700",
  "Corben:300,400,700",
  "Cormorant:300,400,700",
  "Cormorant Garamond:300,400,700",
  "Cormorant Infant:300,400,700",
  "Cormorant SC:300,400,700",
  "Cormorant Unicase:300,400,700",
  "Cormorant Upright:300,400,700",
  "Courgette:300,400,700",
  "Cousine:300,400,700",
  "Coustard:300,400,700",
  "Covered By Your Grace:300,400,700",
  "Crafty Girls:300,400,700",
  "Creepster:300,400,700",
  "Crete Round:300,400,700",
  "Crimson Text:300,400,700",
  "Croissant One:300,400,700",
  "Crushed:300,400,700",
  "Cuprum:300,400,700",
  "Cutive:300,400,700",
  "Cutive Mono:300,400,700",
  "Damion:300,400,700",
  "Dancing Script:300,400,700",
  "Dangrek:300,400,700",
  "David Libre:300,400,700",
  "Dawning of a New Day:300,400,700",
  "Days One:300,400,700",
  "Dekko:300,400,700",
  "Delius:300,400,700",
  "Delius Swash Caps:300,400,700",
  "Delius Unicase:300,400,700",
  "Della Respira:300,400,700",
  "Denk One:300,400,700",
  "Devonshire:300,400,700",
  "Dhurjati:300,400,700",
  "Didact Gothic:300,400,700",
  "Diplomata:300,400,700",
  "Diplomata SC:300,400,700",
  "Domine:300,400,700",
  "Donegal One:300,400,700",
  "Doppio One:300,400,700",
  "Dorsa:300,400,700",
  "Dosis:300,400,700",
  "Dr Sugiyama:300,400,700",
  "Droid Sans:300,400,700",
  "Droid Sans Mono:300,400,700",
  "Droid Serif:300,400,700",
  "Duru Sans:300,400,700",
  "Dynalight:300,400,700",
  "EB Garamond:300,400,700",
  "Eagle Lake:300,400,700",
  "Eater:300,400,700",
  "Economica:300,400,700",
  "Eczar:300,400,700",
  "El Messiri:300,400,700",
  "Electrolize:300,400,700",
  "Elsie:300,400,700",
  "Elsie Swash Caps:300,400,700",
  "Emblema One:300,400,700",
  "Emilys Candy:300,400,700",
  "Encode Sans:300,400,700",
  "Encode Sans Condensed:300,400,700",
  "Encode Sans Expanded:300,400,700",
  "Encode Sans Semi Condensed:300,400,700",
  "Encode Sans Semi Expanded:300,400,700",
  "Engagement:300,400,700",
  "Englebert:300,400,700",
  "Enriqueta:300,400,700",
  "Erica One:300,400,700",
  "Esteban:300,400,700",
  "Euphoria Script:300,400,700",
  "Ewert:300,400,700",
  "Exo:300,400,700",
  "Exo 2:300,400,700",
  "Expletus Sans:300,400,700",
  "Fanwood Text:300,400,700",
  "Farsan:300,400,700",
  "Fascinate:300,400,700",
  "Fascinate Inline:300,400,700",
  "Faster One:300,400,700",
  "Fasthand:300,400,700",
  "Fauna One:300,400,700",
  "Faustina:300,400,700",
  "Federant:300,400,700",
  "Federo:300,400,700",
  "Felipa:300,400,700",
  "Fenix:300,400,700",
  "Finger Paint:300,400,700",
  "Fira Mono:300,400,700",
  "Fira Sans:300,400,700",
  "Fira Sans Condensed:300,400,700",
  "Fira Sans Extra Condensed:300,400,700",
  "Fjalla One:300,400,700",
  "Fjord One:300,400,700",
  "Flamenco:300,400,700",
  "Flavors:300,400,700",
  "Fondamento:300,400,700",
  "Fontdiner Swanky:300,400,700",
  "Forum:300,400,700",
  "Francois One:300,400,700",
  "Frank Ruhl Libre:300,400,700",
  "Freckle Face:300,400,700",
  "Fredericka the Great:300,400,700",
  "Fredoka One:300,400,700",
  "Freehand:300,400,700",
  "Fresca:300,400,700",
  "Frijole:300,400,700",
  "Fruktur:300,400,700",
  "Fugaz One:300,400,700",
  "GFS Didot:300,400,700",
  "GFS Neohellenic:300,400,700",
  "Gabriela:300,400,700",
  "Gafata:300,400,700",
  "Galada:300,400,700",
  "Galdeano:300,400,700",
  "Galindo:300,400,700",
  "Gentium Basic:300,400,700",
  "Gentium Book Basic:300,400,700",
  "Geo:300,400,700",
  "Geostar:300,400,700",
  "Geostar Fill:300,400,700",
  "Germania One:300,400,700",
  "Gidugu:300,400,700",
  "Gilda Display:300,400,700",
  "Give You Glory:300,400,700",
  "Glass Antiqua:300,400,700",
  "Glegoo:300,400,700",
  "Gloria Hallelujah:300,400,700",
  "Goblin One:300,400,700",
  "Gochi Hand:300,400,700",
  "Gorditas:300,400,700",
  "Goudy Bookletter 1911:300,400,700",
  "Graduate:300,400,700",
  "Grand Hotel:300,400,700",
  "Gravitas One:300,400,700",
  "Great Vibes:300,400,700",
  "Griffy:300,400,700",
  "Gruppo:300,400,700",
  "Gudea:300,400,700",
  "Gurajada:300,400,700",
  "Habibi:300,400,700",
  "Halant:300,400,700",
  "Hammersmith One:300,400,700",
  "Hanalei:300,400,700",
  "Hanalei Fill:300,400,700",
  "Handlee:300,400,700",
  "Hanuman:300,400,700",
  "Happy Monkey:300,400,700",
  "Harmattan:300,400,700",
  "Headland One:300,400,700",
  "Heebo:300,400,700",
  "Henny Penny:300,400,700",
  "Herr Von Muellerhoff:300,400,700",
  "Hind:300,400,700",
  "Hind Guntur:300,400,700",
  "Hind Madurai:300,400,700",
  "Hind Siliguri:300,400,700",
  "Hind Vadodara:300,400,700",
  "Holtwood One SC:300,400,700",
  "Homemade Apple:300,400,700",
  "Homenaje:300,400,700",
  "IM Fell DW Pica:300,400,700",
  "IM Fell DW Pica SC:300,400,700",
  "IM Fell Double Pica:300,400,700",
  "IM Fell Double Pica SC:300,400,700",
  "IM Fell English:300,400,700",
  "IM Fell English SC:300,400,700",
  "IM Fell French Canon:300,400,700",
  "IM Fell French Canon SC:300,400,700",
  "IM Fell Great Primer:300,400,700",
  "IM Fell Great Primer SC:300,400,700",
  "Iceberg:300,400,700",
  "Iceland:300,400,700",
  "Imprima:300,400,700",
  "Inconsolata:300,400,700",
  "Inder:300,400,700",
  "Indie Flower:300,400,700",
  "Inika:300,400,700",
  "Inknut Antiqua:300,400,700",
  "Irish Grover:300,400,700",
  "Istok Web:300,400,700",
  "Italiana:300,400,700",
  "Italianno:300,400,700",
  "Itim:300,400,700",
  "Jacques Francois:300,400,700",
  "Jacques Francois Shadow:300,400,700",
  "Jaldi:300,400,700",
  "Jim Nightshade:300,400,700",
  "Jockey One:300,400,700",
  "Jolly Lodger:300,400,700",
  "Jomhuria:300,400,700",
  "Josefin Sans:300,400,700",
  "Josefin Slab:300,400,700",
  "Joti One:300,400,700",
  "Judson:300,400,700",
  "Julee:300,400,700",
  "Julius Sans One:300,400,700",
  "Junge:300,400,700",
  "Jura:300,400,700",
  "Just Another Hand:300,400,700",
  "Just Me Again Down Here:300,400,700",
  "Kadwa:300,400,700",
  "Kalam:300,400,700",
  "Kameron:300,400,700",
  "Kanit:300,400,700",
  "Kantumruy:300,400,700",
  "Karla:300,400,700",
  "Karma:300,400,700",
  "Katibeh:300,400,700",
  "Kaushan Script:300,400,700",
  "Kavivanar:300,400,700",
  "Kavoon:300,400,700",
  "Kdam Thmor:300,400,700",
  "Keania One:300,400,700",
  "Kelly Slab:300,400,700",
  "Kenia:300,400,700",
  "Khand:300,400,700",
  "Khmer:300,400,700",
  "Khula:300,400,700",
  "Kite One:300,400,700",
  "Knewave:300,400,700",
  "Kotta One:300,400,700",
  "Koulen:300,400,700",
  "Kranky:300,400,700",
  "Kreon:300,400,700",
  "Kristi:300,400,700",
  "Krona One:300,400,700",
  "Kumar One:300,400,700",
  "Kumar One Outline:300,400,700",
  "Kurale:300,400,700",
  "La Belle Aurore:300,400,700",
  "Laila:300,400,700",
  "Lakki Reddy:300,400,700",
  "Lalezar:300,400,700",
  "Lancelot:300,400,700",
  "Lateef:300,400,700",
  "Lato:300,400,700",
  "League Script:300,400,700",
  "Leckerli One:300,400,700",
  "Ledger:300,400,700",
  "Lekton:300,400,700",
  "Lemon:300,400,700",
  "Lemonada:300,400,700",
  "Libre Barcode 128:300,400,700",
  "Libre Barcode 128 Text:300,400,700",
  "Libre Barcode 39:300,400,700",
  "Libre Barcode 39 Extended:300,400,700",
  "Libre Barcode 39 Extended Text:300,400,700",
  "Libre Barcode 39 Text:300,400,700",
  "Libre Baskerville:300,400,700",
  "Libre Franklin:300,400,700",
  "Life Savers:300,400,700",
  "Lilita One:300,400,700",
  "Lily Script One:300,400,700",
  "Limelight:300,400,700",
  "Linden Hill:300,400,700",
  "Lobster:300,400,700",
  "Lobster Two:300,400,700",
  "Londrina Outline:300,400,700",
  "Londrina Shadow:300,400,700",
  "Londrina Sketch:300,400,700",
  "Londrina Solid:300,400,700",
  "Lora:300,400,700",
  "Love Ya Like A Sister:300,400,700",
  "Loved by the King:300,400,700",
  "Lovers Quarrel:300,400,700",
  "Luckiest Guy:300,400,700",
  "Lusitana:300,400,700",
  "Lustria:300,400,700",
  "Macondo:300,400,700",
  "Macondo Swash Caps:300,400,700",
  "Mada:300,400,700",
  "Magra:300,400,700",
  "Maiden Orange:300,400,700",
  "Maitree:300,400,700",
  "Mako:300,400,700",
  "Mallanna:300,400,700",
  "Mandali:300,400,700",
  "Manuale:300,400,700",
  "Marcellus:300,400,700",
  "Marcellus SC:300,400,700",
  "Marck Script:300,400,700",
  "Margarine:300,400,700",
  "Marko One:300,400,700",
  "Marmelad:300,400,700",
  "Martel:300,400,700",
  "Martel Sans:300,400,700",
  "Marvel:300,400,700",
  "Mate:300,400,700",
  "Mate SC:300,400,700",
  "Maven Pro:300,400,700",
  "McLaren:300,400,700",
  "Meddon:300,400,700",
  "MedievalSharp:300,400,700",
  "Medula One:300,400,700",
  "Meera Inimai:300,400,700",
  "Megrim:300,400,700",
  "Meie Script:300,400,700",
  "Merienda:300,400,700",
  "Merienda One:300,400,700",
  "Merriweather:300,400,700",
  "Merriweather Sans:300,400,700",
  "Metal:300,400,700",
  "Metal Mania:300,400,700",
  "Metamorphous:300,400,700",
  "Metrophobic:300,400,700",
  "Michroma:300,400,700",
  "Milonga:300,400,700",
  "Miltonian:300,400,700",
  "Miltonian Tattoo:300,400,700",
  "Miniver:300,400,700",
  "Miriam Libre:300,400,700",
  "Mirza:300,400,700",
  "Miss Fajardose:300,400,700",
  "Mitr:300,400,700",
  "Modak:300,400,700",
  "Modern Antiqua:300,400,700",
  "Mogra:300,400,700",
  "Molengo:300,400,700",
  "Molle:300,400,700",
  "Monda:300,400,700",
  "Monofett:300,400,700",
  "Monoton:300,400,700",
  "Monsieur La Doulaise:300,400,700",
  "Montaga:300,400,700",
  "Montez:300,400,700",
  "Montserrat:300,400,700",
  "Montserrat Alternates:300,400,700",
  "Montserrat Subrayada:300,400,700",
  "Moul:300,400,700",
  "Moulpali:300,400,700",
  "Mountains of Christmas:300,400,700",
  "Mouse Memoirs:300,400,700",
  "Mr Bedfort:300,400,700",
  "Mr Dafoe:300,400,700",
  "Mr De Haviland:300,400,700",
  "Mrs Saint Delafield:300,400,700",
  "Mrs Sheppards:300,400,700",
  "Mukta:300,400,700",
  "Mukta Mahee:300,400,700",
  "Mukta Malar:300,400,700",
  "Mukta Vaani:300,400,700",
  "Muli:300,400,700",
  "Mystery Quest:300,400,700",
  "NTR:300,400,700",
  "Neucha:300,400,700",
  "Neuton:300,400,700",
  "New Rocker:300,400,700",
  "News Cycle:300,400,700",
  "Niconne:300,400,700",
  "Nixie One:300,400,700",
  "Nobile:300,400,700",
  "Nokora:300,400,700",
  "Norican:300,400,700",
  "Nosifer:300,400,700",
  "Nothing You Could Do:300,400,700",
  "Noticia Text:300,400,700",
  "Noto Sans:300,400,700",
  "Noto Serif:300,400,700",
  "Nova Cut:300,400,700",
  "Nova Flat:300,400,700",
  "Nova Mono:300,400,700",
  "Nova Oval:300,400,700",
  "Nova Round:300,400,700",
  "Nova Script:300,400,700",
  "Nova Slim:300,400,700",
  "Nova Square:300,400,700",
  "Numans:300,400,700",
  "Nunito:300,400,700",
  "Nunito Sans:300,400,700",
  "Odor Mean Chey:300,400,700",
  "Offside:300,400,700",
  "Old Standard TT:300,400,700",
  "Oldenburg:300,400,700",
  "Oleo Script:300,400,700",
  "Oleo Script Swash Caps:300,400,700",
  "Open Sans:300,400,700",
  "Open Sans Condensed:300,400,700",
  "Oranienbaum:300,400,700",
  "Orbitron:300,400,700",
  "Oregano:300,400,700",
  "Orienta:300,400,700",
  "Original Surfer:300,400,700",
  "Oswald:300,400,700",
  "Over the Rainbow:300,400,700",
  "Overlock:300,400,700",
  "Overlock SC:300,400,700",
  "Overpass:300,400,700",
  "Overpass Mono:300,400,700",
  "Ovo:300,400,700",
  "Oxygen:300,400,700",
  "Oxygen Mono:300,400,700",
  "PT Mono:300,400,700",
  "PT Sans:300,400,700",
  "PT Sans Caption:300,400,700",
  "PT Sans Narrow:300,400,700",
  "PT Serif:300,400,700",
  "PT Serif Caption:300,400,700",
  "Pacifico:300,400,700",
  "Padauk:300,400,700",
  "Palanquin:300,400,700",
  "Palanquin Dark:300,400,700",
  "Pangolin:300,400,700",
  "Paprika:300,400,700",
  "Parisienne:300,400,700",
  "Passero One:300,400,700",
  "Passion One:300,400,700",
  "Pathway Gothic One:300,400,700",
  "Patrick Hand:300,400,700",
  "Patrick Hand SC:300,400,700",
  "Pattaya:300,400,700",
  "Patua One:300,400,700",
  "Pavanam:300,400,700",
  "Paytone One:300,400,700",
  "Peddana:300,400,700",
  "Peralta:300,400,700",
  "Permanent Marker:300,400,700",
  "Petit Formal Script:300,400,700",
  "Petrona:300,400,700",
  "Philosopher:300,400,700",
  "Piedra:300,400,700",
  "Pinyon Script:300,400,700",
  "Pirata One:300,400,700",
  "Plaster:300,400,700",
  "Play:300,400,700",
  "Playball:300,400,700",
  "Playfair Display:300,400,700",
  "Playfair Display SC:300,400,700",
  "Podkova:300,400,700",
  "Poiret One:300,400,700",
  "Poller One:300,400,700",
  "Poly:300,400,700",
  "Pompiere:300,400,700",
  "Pontano Sans:300,400,700",
  "Poppins:300,400,700",
  "Port Lligat Sans:300,400,700",
  "Port Lligat Slab:300,400,700",
  "Pragati Narrow:300,400,700",
  "Prata:300,400,700",
  "Preahvihear:300,400,700",
  "Press Start 2P:300,400,700",
  "Pridi:300,400,700",
  "Princess Sofia:300,400,700",
  "Prociono:300,400,700",
  "Prompt:300,400,700",
  "Prosto One:300,400,700",
  "Proza Libre:300,400,700",
  "Puritan:300,400,700",
  "Purple Purse:300,400,700",
  "Quando:300,400,700",
  "Quantico:300,400,700",
  "Quattrocento:300,400,700",
  "Quattrocento Sans:300,400,700",
  "Questrial:300,400,700",
  "Quicksand:300,400,700",
  "Quintessential:300,400,700",
  "Qwigley:300,400,700",
  "Racing Sans One:300,400,700",
  "Radley:300,400,700",
  "Rajdhani:300,400,700",
  "Rakkas:300,400,700",
  "Raleway:300,400,700",
  "Raleway Dots:300,400,700",
  "Ramabhadra:300,400,700",
  "Ramaraja:300,400,700",
  "Rambla:300,400,700",
  "Rammetto One:300,400,700",
  "Ranchers:300,400,700",
  "Rancho:300,400,700",
  "Ranga:300,400,700",
  "Rasa:300,400,700",
  "Rationale:300,400,700",
  "Ravi Prakash:300,400,700",
  "Redressed:300,400,700",
  "Reem Kufi:300,400,700",
  "Reenie Beanie:300,400,700",
  "Revalia:300,400,700",
  "Rhodium Libre:300,400,700",
  "Ribeye:300,400,700",
  "Ribeye Marrow:300,400,700",
  "Righteous:300,400,700",
  "Risque:300,400,700",
  "Roboto:300,400,700",
  "Roboto Condensed:300,400,700",
  "Roboto Mono:300,400,700",
  "Roboto Slab:300,400,700",
  "Rochester:300,400,700",
  "Rock Salt:300,400,700",
  "Rokkitt:300,400,700",
  "Romanesco:300,400,700",
  "Ropa Sans:300,400,700",
  "Rosario:300,400,700",
  "Rosarivo:300,400,700",
  "Rouge Script:300,400,700",
  "Rozha One:300,400,700",
  "Rubik:300,400,700",
  "Rubik Mono One:300,400,700",
  "Ruda:300,400,700",
  "Rufina:300,400,700",
  "Ruge Boogie:300,400,700",
  "Ruluko:300,400,700",
  "Rum Raisin:300,400,700",
  "Ruslan Display:300,400,700",
  "Russo One:300,400,700",
  "Ruthie:300,400,700",
  "Rye:300,400,700",
  "Sacramento:300,400,700",
  "Sahitya:300,400,700",
  "Sail:300,400,700",
  "Saira:300,400,700",
  "Saira Condensed:300,400,700",
  "Saira Extra Condensed:300,400,700",
  "Saira Semi Condensed:300,400,700",
  "Salsa:300,400,700",
  "Sanchez:300,400,700",
  "Sancreek:300,400,700",
  "Sansita:300,400,700",
  "Sarala:300,400,700",
  "Sarina:300,400,700",
  "Sarpanch:300,400,700",
  "Satisfy:300,400,700",
  "Scada:300,400,700",
  "Scheherazade:300,400,700",
  "Schoolbell:300,400,700",
  "Scope One:300,400,700",
  "Seaweed Script:300,400,700",
  "Secular One:300,400,700",
  "Sedgwick Ave:300,400,700",
  "Sedgwick Ave Display:300,400,700",
  "Sevillana:300,400,700",
  "Seymour One:300,400,700",
  "Shadows Into Light:300,400,700",
  "Shadows Into Light Two:300,400,700",
  "Shanti:300,400,700",
  "Share:300,400,700",
  "Share Tech:300,400,700",
  "Share Tech Mono:300,400,700",
  "Shojumaru:300,400,700",
  "Short Stack:300,400,700",
  "Shrikhand:300,400,700",
  "Siemreap:300,400,700",
  "Sigmar One:300,400,700",
  "Signika:300,400,700",
  "Signika Negative:300,400,700",
  "Simonetta:300,400,700",
  "Sintony:300,400,700",
  "Sirin Stencil:300,400,700",
  "Six Caps:300,400,700",
  "Skranji:300,400,700",
  "Slabo 13px:300,400,700",
  "Slabo 27px:300,400,700",
  "Slackey:300,400,700",
  "Smokum:300,400,700",
  "Smythe:300,400,700",
  "Sniglet:300,400,700",
  "Snippet:300,400,700",
  "Snowburst One:300,400,700",
  "Sofadi One:300,400,700",
  "Sofia:300,400,700",
  "Sonsie One:300,400,700",
  "Sorts Mill Goudy:300,400,700",
  "Source Code Pro:300,400,700",
  "Source Sans Pro:300,400,700",
  "Source Serif Pro:300,400,700",
  "Space Mono:300,400,700",
  "Special Elite:300,400,700",
  "Spectral:300,400,700",
  "Spicy Rice:300,400,700",
  "Spinnaker:300,400,700",
  "Spirax:300,400,700",
  "Squada One:300,400,700",
  "Sree Krushnadevaraya:300,400,700",
  "Sriracha:300,400,700",
  "Stalemate:300,400,700",
  "Stalinist One:300,400,700",
  "Stardos Stencil:300,400,700",
  "Stint Ultra Condensed:300,400,700",
  "Stint Ultra Expanded:300,400,700",
  "Stoke:300,400,700",
  "Strait:300,400,700",
  "Sue Ellen Francisco:300,400,700",
  "Suez One:300,400,700",
  "Sumana:300,400,700",
  "Sunshiney:300,400,700",
  "Supermercado One:300,400,700",
  "Sura:300,400,700",
  "Suranna:300,400,700",
  "Suravaram:300,400,700",
  "Suwannaphum:300,400,700",
  "Swanky and Moo Moo:300,400,700",
  "Syncopate:300,400,700",
  "Tangerine:300,400,700",
  "Taprom:300,400,700",
  "Tauri:300,400,700",
  "Taviraj:300,400,700",
  "Teko:300,400,700",
  "Telex:300,400,700",
  "Tenali Ramakrishna:300,400,700",
  "Tenor Sans:300,400,700",
  "Text Me One:300,400,700",
  "The Girl Next Door:300,400,700",
  "Tienne:300,400,700",
  "Tillana:300,400,700",
  "Timmana:300,400,700",
  "Tinos:300,400,700",
  "Titan One:300,400,700",
  "Titillium Web:300,400,700",
  "Trade Winds:300,400,700",
  "Trirong:300,400,700",
  "Trocchi:300,400,700",
  "Trochut:300,400,700",
  "Trykker:300,400,700",
  "Tulpen One:300,400,700",
  "Ubuntu:300,400,700",
  "Ubuntu Condensed:300,400,700",
  "Ubuntu Mono:300,400,700",
  "Ultra:300,400,700",
  "Uncial Antiqua:300,400,700",
  "Underdog:300,400,700",
  "Unica One:300,400,700",
  "UnifrakturCook:300,400,700",
  "UnifrakturMaguntia:300,400,700",
  "Unkempt:300,400,700",
  "Unlock:300,400,700",
  "Unna:300,400,700",
  "VT323:300,400,700",
  "Vampiro One:300,400,700",
  "Varela:300,400,700",
  "Varela Round:300,400,700",
  "Vast Shadow:300,400,700",
  "Vesper Libre:300,400,700",
  "Vibur:300,400,700",
  "Vidaloka:300,400,700",
  "Viga:300,400,700",
  "Voces:300,400,700",
  "Volkhov:300,400,700",
  "Vollkorn:300,400,700",
  "Voltaire:300,400,700",
  "Waiting for the Sunrise:300,400,700",
  "Wallpoet:300,400,700",
  "Walter Turncoat:300,400,700",
  "Warnes:300,400,700",
  "Wellfleet:300,400,700",
  "Wendy One:300,400,700",
  "Wire One:300,400,700",
  "Work Sans:300,400,700",
  "Yanone Kaffeesatz:300,400,700",
  "Yantramanav:300,400,700",
  "Yatra One:300,400,700",
  "Yellowtail:300,400,700",
  "Yeseva One:300,400,700",
  "Yesteryear:300,400,700",
  "Yrsa:300,400,700",
  "Zeyada:300,400,700",
  "Zilla Slab:300,400,700",
  "Zilla Slab Highlight:300,400,700"
];
