import * as React from "react";
import { get, head } from 'lodash';

import { fieldset } from "@launchos/components/ui/theme";
import LogicBuilder from "@launchos/plugins/misc/v2/LogicBuilder";
import { fields } from "@launchos/plugins/misc/v2/LogicBuilder/defaults";
import { SelectorTypes, getType } from "@launchos/plugins/misc/v2/LogicBuilder/LoadSelector/types";

import { SmartLogicProps } from "./types";

export enum SmartConditionActions {
  SHOWELEMENT = "SHOW",
}

export const SmartLogic: React.FC<SmartLogicProps> = ({
  updateComponentSettings = () => null,
  title = "",
  settings = {},
}) => {
  const indexFields = head(fields.filter(({ type }) => type === SelectorTypes.INDEX));
  const indexFieldsWithType = { ...indexFields, type: getType(indexFields.type) }

  const [values, setValues] = React.useState<any[]>([indexFieldsWithType])

  const handleChange = (name, value, newValues) => {
    console.log(name, value)
    setValues(newValues)

    // update the component with the values
    updateComponentSettings(settings.id, {
      ...settings,
      conditions: [{
        ...get(settings, 'conditions[0]', {}),
        action: SmartConditionActions.SHOWELEMENT,
        [name]: value,
      }]
    })
  }

  return (
    <div data-testid="SmartLogic-LIVE">
      <fieldset style={fieldset}>
        <legend>Show This {title.replace(" Settings", "") || settings.label || settings.type} When</legend>
        <LogicBuilder
          data={fields}
          value={values}
          onChange={handleChange}
          styles={{
            valueWrapper: {
              width: '100%',
              marginBottom: 10,
              // marginBottom: key === values.length - 1 ? 0 : 10,
              // marginRight: 5,
            }
          }} />
      </fieldset>
    </div>
  );
};

export default SmartLogic;
