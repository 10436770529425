import * as React from "react";
import _ from "lodash";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import { CURSOR_ID } from "@launchos/modules/editor/Builder/WebComponent/types";

import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import { Tabs } from "./live";
import { TabsProps } from "./types";
import Properties from "./Properties";

import { addNewTab, switchTab } from './scripts'
import { EditorMode } from "@launchos/modules/v2/Editor/types";

export const TabsEditorVersion: React.FC<TabsProps> = (
  props
) => {
  const { settings, id, pageContent = {}, updateComponentSettings, updateContent, children } = props;

  let isEmpty = false;
  const childContent = pageContent.filter((itm) => itm.parent === settings.id);
  if (childContent.length) isEmpty = !!(childContent.length === 1 && childContent[0].id === CURSOR_ID);
  else isEmpty = true;

  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Tabs"
        id={id}
        {...props}
        PropertiesView={Properties}
        dropDownItems={(items) => {
          const key = items.findIndex(({ id }) => id === "divider");
          return [
            ...items.slice(0, key),
            ...[
              { id: "divider" },
              ...settings.items.map(({ id, label, active = false }) => ({
                id, text: `Switch to "${label}"`, icon: active ? 'done' : 'tab'
              })),
              { id: "ADD", text: "Add a New Tab", icon: "add_circle" },
              { id: "divider" },
            ],
            ...items.slice(key + 1),
          ]
        }}
        onDropDownChange={id => {
          if (id === "ADD") {
            addNewTab({
              settings,
              pageContent,
              updateContent,
              updateComponentSettings,
            })
          } else {
            // Switch To Tab
            switchTab({ updateComponentSettings, settings, tabId: id, mode: EditorMode.EDITOR })
          }
          return true;
        }}
      >
        <Tabs
          {...settings}
          properties={properties}
          testId="WC-TABS-EDIT"
          mode={EditorMode.EDITOR}
          onSwitch={(tabId) => { switchTab({ settings, updateComponentSettings, tabId, mode: EditorMode.EDITOR }) }}
        >
          {children}
        </Tabs>
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default TabsEditorVersion;

