import React from "react";
import { Grid } from "@launchos/components/ui";
import CatalogItem from "./CatalogItem";

const CatalogItemGroup = (props) => {
  const { label, items, isExpanded } = props;

  if (props.items.length) {
    return (
      <div>
        <h2 style={{ marginTop: 20 }}>{label}</h2>
        <Grid wrap>
          {items.map((itm, key) => (
            <CatalogItem
              key={key}
              {...props}
              {...itm}
              isExpanded={isExpanded}
            />
          ))}
        </Grid>
      </div>
    );
  }
  return null;
};

CatalogItemGroup.defaultProps = {
  isExpanded: false,
};

export default CatalogItemGroup;
