import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import settings from "./settings";
import { ComponentTypes } from "../../types";
import { IconComponent } from "./component";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ICON
  ) {
    return [IconComponent];
  }
};
