import * as React from "react";
import { head, get } from "lodash";
import { ContainerProps } from "../Container/component";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

import {
  SmartConditionTypes,
  SmartConditionOperations,
  SmartConditionActions,
} from "./types";

interface IConditionData {
  type: SmartConditionTypes;
  condition: SmartConditionOperations;
  action: SmartConditionActions;
  value: string;
  id: string;
}

interface IContainerData {
  id: string;
  container: React.ReactNode;
  default: Boolean;
}

interface SmartContainerProps {
  mode?: EditorMode;
  conditions: IConditionData[] | any[];
  containers: IContainerData[] | any[];
}

export const SmartContainer: React.FC<SmartContainerProps> = ({
  conditions = [],
  containers = [],
}) => {
  const [containerIndex, setContainerIndex] = React.useState(false);

  React.useEffect(() => {
    const dflt =
      get(
        head(containers.filter((itm: IContainerData) => itm.default)),
        "id"
      ) || get(containers[0], "id");

    conditions.forEach((itm) => {
      const { type, condition, value, id } = itm;
      if (type === SmartConditionTypes.DATE) {
        if (condition === SmartConditionOperations.ISAFTER) {
          if (Date.now() > value) {
            setContainerIndex(id);
          }
        }
      } else {
        setContainerIndex(dflt);
      }
    });
  }, []);

  return (
    <div data-testid="WC-SMARTCONTAINER-LIVE">
      {containers
        .filter((itm) => itm.id === containerIndex)
        .map((itm: IContainerData) => {
          const TheContainer: any = itm.container;
          return <TheContainer />;
        })}
    </div>
  );
};

export default SmartContainer;
