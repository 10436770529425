import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { HeaderLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.FOURTEEN,
  layout: HeaderLayouts.FOURTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header14.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "x2qphr33mti",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: false,
        properties: {
          maxWidth: 700,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.PRE_HEADLINE,
              ...get(content, "pre_headline.style", {}),
            },
            html: get(content, "pre_headline.html", "..."),
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: 500,
              maxWidth: "100%",
              margin: "0 auto",
              marginTop: 20,
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((8 * 100) / 12),
                properties: { height: "100%", padding: 3, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.FORMFIELD,
                    canHaveChildren: false,
                    fieldType: FormFieldTypes.TEXT,
                    attributes: {
                      name: "email",
                      placeholder: "Enter Your Email",
                    },
                    styles: {
                      containerStyle: style.FORM_FIELD.style,
                      inputStyle: style.FORM_FIELD.inputStyle,
                    },
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 3, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: style.BUTTON.style,
                    caption1: "Get Started",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "privacy_reminder.style", {}),
            },
            html: get(content, "privacy_reminder.html", "..."),
          },
        ],
      },
    ],
  },
};

export default settings;
