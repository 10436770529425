import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // padding: 50,
    // maxWidth: 200
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 40,
    color: theme.foregroundColor,
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      marginTop: 40,
      border: "none",
      background: theme.foregroundColor,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
