import { ComponentRenderHooks } from "@launchos/plugins/types";
import { ComponentTypes } from "../../types";
import TabsEditorVersion from "./TabsComponent";
import TabEditorVersion from "./TabComponent";
import settings from "./settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.TABS
  ) {
    return [TabsEditorVersion];
  }

  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.TAB
  ) {
    return [TabEditorVersion];
  }
};
