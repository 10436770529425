import React from "react";
import MaterialButton from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  Dialog,
  DialogBody,
  DialogTitle,
  Button,
  TextInput,
} from "@launchos/components/ui";
import { FixedPositions } from "@launchos/components/ui/types";

import { DisplayItems } from "@launchos/modules/v2/CRUD/DisplayItems/";
import {
  DisplayItemsFieldDataType,
  DisplayItemsPropsType,
  DisplayItemsFieldData,
} from "@launchos/modules/v2/CRUD/DisplayItems/components/types";

import Page from "@launchos/modules/app/Page";
import theme from "@launchos/components/ui/theme";

import { client } from "@launchos/api/gql/client";
import gql from "graphql-tag";

import AdminSettings from "./Settings";

import { app } from "@launchos/10mf/src/config";

const NewAccount: React.FC = ({ onCreate, onClose }) => {
  const [data, setData] = React.useState({});
  const [shouldSendEmail, setShouldSendEmail] = React.useState(false);

  return (
    <Dialog centered onClose={onClose}>
      <DialogTitle>Create New Account</DialogTitle>
      <DialogBody>
        <div style={{ textAlign: "center", width: 500 }}>
          <TextInput
            placeholder="First Name"
            onChange={(e) => setData({ ...data, firstName: e.target.value })}
          />
          <TextInput
            placeholder="Last Name"
            onChange={(e) => setData({ ...data, lastName: e.target.value })}
          />
          <TextInput
            placeholder="Email Address"
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <TextInput
            placeholder="Password"
            type="password"
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />

          <Grid container justify="space-between" style={{ marginTop: 20 }}>
            {app.id === "10mf" && (
              <Grid>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shouldSendEmail}
                      onChange={(e) => {
                        setShouldSendEmail(e.target.checked);
                      }}
                      name="activeShouldSendEmail"
                    />
                  }
                  label="Send Welcome Email?"
                />
              </Grid>
            )}
            <Grid>
              <MaterialButton
                variant="contained"
                color="primary"
                onClick={() =>
                  onCreate({ ...data, emailUser: shouldSendEmail })
                }
              >
                Create Account
              </MaterialButton>
            </Grid>
          </Grid>
        </div>
      </DialogBody>
    </Dialog>
  );
};

const LoginButton: React.FC = () => {
  return <div>Testing 123</div>
}

const Administration: React.FC = () => {
  const [users, setUsers] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("accounts");
  const [isLoading, setIsLoading] = React.useState(true);
  const [newAccountIsShowing, setNewAccountIsShowing] = React.useState(false);
  const [onlyShowActiveUsers, setOnlyShowActiveUsers] = React.useState(true);

  // grab list of users
  const getUsers = async () => {
    try {
      const { data } = await client.query({
        query: gql`
          query getUsers {
            users(
              orderBy: createdAt_DESC
              where: {
                # deleted: null
                apps_some: { name: "${app.id}" }
              }
            ) {
              id
              firstName
              lastName
              email
              subdomain
              deleted
              permissions
              domains {
                id
                name
              }
              apps {
                id
                name
                host
              }
              createdAt
            }
          }
        `,
      });

      // console.log({ data });

      setUsers(data.users);
      setIsLoading(false);
    } catch (err) {
      console.log({ err });
      // alert(
      //   "Sorry, there was an issue retrieving user data.  Let's try that again"
      // );
      // window.location.href = window.location.href;
    }
  };

  React.useEffect(() => {
    getUsers();
    // console.log({ app });
  }, []);

  const fields: DisplayItemsFieldData[] = [
    {
      name: "firstName",
      label: "First Name",
      type: DisplayItemsFieldDataType.STRING,
    },
    {
      name: "lastName",
      label: "Last Name",
      type: DisplayItemsFieldDataType.STRING,
    },
    {
      name: "email",
      label: "Email",
      type: DisplayItemsFieldDataType.EMAIL,
    },
    {
      name: "createdAt",
      label: "Join Date",
      type: DisplayItemsFieldDataType.DATE,
    },
    {
      name: "deleted",
      label: "Canceled",
      type: DisplayItemsFieldDataType.BOOLEAN,
    },
    {
      name: "login",
      label: "Log In",
      type: DisplayItemsFieldDataType.LINK,
      onClick: (user) => {
        const { email, id } = user
        // alert(JSON.stringify(user))
        if (confirm("Are you sure you want to log into this account? \n\nYou will be logged out of this account first")) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("auth0UserId");
          localStorage.removeItem("email");
          localStorage.removeItem("expiresAt");
          localStorage.removeItem("userId");
          localStorage.removeItem("clipboard");

          localStorage.setItem("userId", id);
          sessionStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("email", email);

          window.location.href = "/dashboard";
        }
      }
    },
  ];

  const createNewUser = async (data) => {
    console.log({ data });

    const host = process.env.REACT_APP_SERVER_ENDPOINT;
    const options = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Origin: window.location.origin,
      },
      body: JSON.stringify({
        ...data,
        appName: app.id,
        permissions: [app.id],
      }),
    };

    const res = await fetch(`https://api.tenminutefunnels.com/v1/users/createUser`, options);
    const response = await res.json();
    console.log({ response });

    window.location.href = "/admin";
    // setNewAccountIsShowing(false);
  };

  const cancelUsers = async (users) => {
    if (
      window.confirm(`Are you sure you want to cancel ${users.length} users?`)
    ) {
      // loop through users
      for (let i = 0; i < users.length; i++) {
        // cancel each one
        console.log(users[i].id);

        await client.mutate({
          mutation: gql`
            mutation cancelUser($id: ID!) {
              updateUser(data: { deleted: true }, where: { id: $id }) {
                id
              }
            }
          `,
          variables: {
            id: users[i].id,
          },
        });
      }

      // refresh the page
      window.location.href = "/admin";
    }
  };

  const activateUsers = async (users) => {
    if (
      window.confirm(`Are you sure you want to activate ${users.length} users?`)
    ) {
      // loop through users
      for (let i = 0; i < users.length; i++) {
        // cancel each one
        console.log(users[i].id);

        await client.mutate({
          mutation: gql`
            mutation activateUser($id: ID!) {
              updateUser(data: { deleted: false }, where: { id: $id }) {
                id
              }
            }
          `,
          variables: {
            id: users[i].id,
          },
        });
      }

      // refresh the page
      window.location.href = "/admin";
    }
  };

  return (
    <Page path="/admin">
      {/* <Tabs
        // transparent
        style={{ borderBottom: "1px solid #EEE", marginBottom: 20 }}
      >
        <Tab
          key="accounts"
          active={activeTab === "accounts"}
          onClick={() => setActiveTab("accounts")}
        >
          Accounts
        </Tab>
        <Tab
          key="settings"
          active={activeTab === "settings"}
          onClick={() => setActiveTab("settings")}
        >
          Settings
        </Tab>
        <Tab
          key="integration"
          active={activeTab === "integration"}
          onClick={() => setActiveTab("integration")}
        >
          Integration
        </Tab>
      </Tabs> */}
      <Button
        style={{
          position: "fixed",
          zIndex: 300,
          boxShadow:
            "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)",
        }}
        icon
        secondary
        fixedPosition={FixedPositions.BOTTOM_RIGHT}
        onClick={() => setNewAccountIsShowing(true)}
      >
        add
      </Button>
      {newAccountIsShowing && (
        <NewAccount
          onCreate={createNewUser}
          onClose={() => setNewAccountIsShowing(false)}
        />
      )}
      <div
        // justify="center"
        style={{
          width: "100%",
          maxWidth: 1400,
          margin: "0 auto",
          marginTop: 50,
        }}
      >
        {activeTab === "accounts" && (
          <div>
            <Grid container justify="space-between">
              <Grid item>
                <h1 style={{ padding: 15, color: theme.darkColor }}>
                  Account Administration
                </h1>
              </Grid>
              <Grid item>
                <div style={{ padding: 25 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={onlyShowActiveUsers}
                        onChange={(e) => {
                          setOnlyShowActiveUsers(e.target.checked);
                        }}
                        name="activeOnlyChecked"
                      />
                    }
                    label="Only Show Active Users"
                  />
                </div>
              </Grid>
            </Grid>
            <DisplayItems
              name="AccountAdmin"
              data={users.filter((user) => {
                // user.deleted == onlyShowActiveUsers
                if (onlyShowActiveUsers) return !user.deleted;
                return true;
              })}
              loading={isLoading}
              fields={fields}
              // title="Administration"
              onFilterChange={(e) => console.log("changed filter", e)}
              dataItemActions={[]}
              paginationSettings={{}}
              type={DisplayItemsPropsType.TABLE}
              showCheckboxes
              showFilterControls={false}
              bulkActions={[
                (props) => (
                  <MaterialButton
                    style={{ marginRight: 10 }}
                    onClick={() => cancelUsers(props.data)}
                  >
                    Cancel Account
                  </MaterialButton>
                ),
                (props) => (
                  <MaterialButton
                    variant="contained"
                    color="primary"
                    onClick={() => activateUsers(props.data)}
                  >
                    Activate
                  </MaterialButton>
                ),
              ]}
            />
          </div>
        )}

        {activeTab === "settings" && (
          <div>
            <h1 style={{ padding: 15, color: theme.darkColor }}>
              Account Settings
            </h1>
            <AdminSettings />
          </div>
        )}

        {activeTab === "integration" && (
          <div>
            <h1 style={{ padding: 15, color: theme.darkColor }}>Integration</h1>
          </div>
        )}
      </div>
    </Page>
  );
};

export default <Administration />;
