import moment from "moment";
import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import {
  LinkDisplayTypes,
  LinkLayoutStyles,
} from "@launchos/plugins/webcomponents/v2/Navigation/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/live";
import {
  faFacebookF,
  faGoogle,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FooterLayouts } from "../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FooterLayouts.FIVE,
  layout: FooterLayouts.FIVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/footers/Footer05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzoubo42irl0780q5gtpfk3",
    itemId: "439ulwzrpxq",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      display: "block",
                    },
                    smartphone: {
                      display: "none",
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.HEADLINE,
                                canHaveChildren: false,
                                properties: {
                                  ...style.HEADLINE,
                                  ...get(content, "main_headline.style", {}),
                                },
                                html: "Information",
                              },
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.HEADLINE,
                                canHaveChildren: false,
                                properties: {
                                  ...style.HEADLINE,
                                  ...get(content, "main_headline.style", {}),
                                },
                                html: "Support",
                              },
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.HEADLINE,
                                canHaveChildren: false,
                                properties: {
                                  ...style.HEADLINE,
                                  ...get(content, "main_headline.style", {}),
                                },
                                html: "Features",
                              },
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  display: "inline-block",
                  textAlign: "right",
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      padding: 20,
                      marginTop: -20,
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Subscribe",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          ...get(content, "sub_headline.style", {}),
                        },
                        html:
                          "Get all the latest information on upcoming events, sales, and offers. Subscribe today.",
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((8 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 20,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.FORMFIELD,
                            canHaveChildren: false,
                            attributes: {
                              placeholder: "Enter Your Email",
                              name: "email",
                            },
                            fieldType: FormFieldTypes.TEXT,
                            styles: {
                              containerStyle: style.FORM_FIELD.style,
                              inputStyle: style.FORM_FIELD.inputStyle,
                            },
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((4 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 20,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: style.BUTTON.style,
                            caption1: "Get Started",
                            caption1style: style.BUTTON.caption1,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 60,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: `© ${moment().format("YYYY")} All Rights Reserved`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.NAVIGATION,
                canHaveChildren: false,
                linkStyle: style.NAVIGATION.linkStyle,
                layoutStyle: LinkLayoutStyles.HORIZONTAL,
                properties: {
                  padding: 20,
                  display: "inline-block",
                  textAlign: "right",
                },
                data: [
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Twitter",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Facebook",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Instagram",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
