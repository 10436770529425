import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",

    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
    padding: 10,
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 10,
    color: theme.foregroundColor,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
  LIST: { marginTop: 60, paddingLeft: 0 },
  LI: {
    style: { marginTop: 40, color: theme.foregroundColor },
    iconStyle: {
      color: theme.foregroundColor,
      backgroundColor: theme.backgroundColor,
      fontSize: "22pt",
      padding: 10,
    },
    contentStyle: {
      padding: 10,
    },
  },
};
