export enum FormLayouts {
  ONE = "Form 01",
  TWO = "Form 02",
  THREE = "Form 03",
  FOUR = "Form 04",
  FIVE = "Form 05",
  SIX = "Form 06",
  SEVEN = "Form 07",
  EIGHT = "Form 08",
  NINE = "Form 09",
  TEN = "Form 10",
  ELEVEN = "Form 11",
  TWELVE = "Form 12",
  THIRTEEN = "Form 13",
  FOURTEEN = "Form 14",
  FIFTEEN = "Form 15",
  SIXTEEN = "Form 16",
  SEVENTEEN = "Form 17",
  EIGHTEEN = "Form 18",
  NINETEEN = "Form 19",
  TWENTY = "Form 20",
  TWENTYONE = "Form 21"
}
