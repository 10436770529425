import _ from "lodash";

import { Button, Cell, Grid } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import { client } from "@launchos/api/gql/client";

import React, { Component } from "react";
import { style } from "../campaign/shared";
import { getCampaign } from "@launchos/api/gql/campaigns";

import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

class CatalogItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDetailsToggled: false,
      loading: false,
      isHover: false,
    };

    this.toggleDetails = this.toggleDetails.bind(this);
    this.createCampaign = this.createCampaign.bind(this);
    this.deleteCatalogItem = this.deleteCatalogItem.bind(this);
    this.deployCatalogItem = this.deployCatalogItem.bind(this);
    this.getDefaultDomainId = this.getDefaultDomainId.bind(this);
  }

  static contextType = AppConfig;

  toggleDetails() {
    this.setState((prevState) => ({
      isDetailsToggled: !prevState.isDetailsToggled,
    }));
  }

  getDefaultDomainId() {
    const settings = this.context;
    const { getUserQuery } = this.props;

    const { subdomain, domains } = getUserQuery.user;

    console.log({ subdomain, domains });

    const domainObj = domains.filter(
      (domain) => domain.name === `${subdomain}.${settings.app.domain}`
    );

    console.log({
      subdomain,
      domainObj,
      domains,
      toMatch: settings.app.domain,
      with: `${subdomain}.${settings.app.domain}`,
    });

    if (domainObj.length) return domainObj[0].id;

    return false;
  }

  async createCampaign() {
    const {
      history,
      campaign,
      allCampaignsQuery,
      getCampaignQuery,
      createCampaign,
      createCampaignObject,
      updateCampaignObject,
      createPage,
    } = this.props;

    console.log({ campaign });
    const oldCampaignId = campaign.id;
    const userId = localStorage.userId;

    this.setState({
      loading: true,
    });

    // 0. grab the users default Id
    const domainId = this.getDefaultDomainId();
    console.log({ domainId, oldCampaignId });

    // 1. grab the campaign
    // const campaignDetails = await getCampaignQuery.refetch({
    //   id: oldCampaignId,
    // });

    const campaignDetails = await client.query({
      query: getCampaign,
      variables: { id: oldCampaignId },
    });

    console.log({ campaignDetails, props: this.props });

    // begin tour if applicable
    const tags = this.props.tags || '';
    if (tags.indexOf('Tour-') > -1) { // we should start a tour
      const allTags = tags.split(",");
      const item = _.head(allTags.filter(itm => itm.indexOf("Tour-") > -1))
      const tour = item.replace("Tour-", "")
      localStorage.removeItem('zero-to-hero-progress')
      localStorage.removeItem("zero-to-hero");
      localStorage.setItem("showThisTour", tour);
    }

    // 2. create a campaign using old campaign data, and add objectIds (merged HasTable of new Object Ids)
    const { name, objects, screenshot, type } = campaignDetails.data.campaign;
    const newCampaignName = window.prompt(
      "Please give your new campaign a name",
      name.replace("[Template] ", "")
    );
    if (!newCampaignName) {
      this.setState({ loading: false });
      return false
    };

    const variables = {
      name: newCampaignName,
      screenshot,
      type,
      userId,
      domainId,
      deleted: false,
    };

    console.log({ variables, objects });

    const createNewCampaign = await createCampaign({ variables });
    const campaignId = createNewCampaign.data.createCampaign.id;

    console.log({ createNewCampaign, campaignId });

    // 3. loop through the campaign objects
    let hash = [];
    for (const obj of objects) {
      // 4. in the loop, create a duplicate object and an associated page; take note of new object Id; and connect it to the old id [oldObjId] = [newObjId]
      const { name, screenshot, settings, type, x, y, deleted } = obj;
      const objUpdated = { name, screenshot, settings, type, x, y, deleted };

      const page = {
        name: obj.page.name,
        slug: `${obj.page.slug}-${Math.random()
          .toString(36)
          .substr(2, 2)}`,
        type: obj.page.type,
        theme: obj.page.theme,
        screenshot: obj.page.screenshot,
        content: obj.page.content,
        pageSettings: obj.page.pageSettings, // when doing campaign sharing, remove email/merchant settings before sharing
        userId,
      };
      console.log({ page });

      const createPageResult = await createPage({ variables: page });
      console.log({ createPageResult });

      const pageId = _.get(createPageResult, "data.createPage.id", false);

      const variables = {
        ...objUpdated,
        campaignId,
        connectToIds: [],
        pageId,
      };
      console.log({ variables });

      const createObject = await createCampaignObject({ variables });
      console.log({ createObject });

      hash[obj.id] = createObject.data.createObject.id;
    }

    // 5. loop through objects again, updateObject connectToIds = merged hash lookup of new obj ids
    for (const obj of objects) {
      const { connectTo, name, deleted, screenshot } = obj;

      const connectToIdsOld = connectTo.map((itm) => itm.id);
      const connectToIdsNew = connectToIdsOld.map((id) => ({ id: hash[id] }));
      const variables = {
        id: hash[obj.id],
        connectToIds: connectToIdsNew,
        screenshot,
        name,
        deleted,
      };

      console.log({ variables });
      await updateCampaignObject({ variables });
    }

    allCampaignsQuery.refetch({ userId }).then((data) => {
      // history.push(`/campaign/${campaignId}/edit`);
      window.location.href = `/campaign/${campaignId}/edit`
    });
  }

  async deleteCatalogItem() {
    const { deleteCatalogItem, allCatalogItemsQuery, id } = this.props;
    if (
      window.confirm(
        "Are you sure you want to delete this campaign from the Catalog?"
      )
    ) {
      await deleteCatalogItem({ variables: { id } });
      console.log(id);
      allCatalogItemsQuery.refetch();
    }
  }

  async deployCatalogItem() {
    const { deployCatalogItem, id } = this.props;
    if (window.confirm("Are you sure you want to deploy this catalog item?")) {
      await deployCatalogItem({
        variables: {
          id,
          destination: localStorage.destination || "production",
          userId:
            localStorage.destination === "production"
              ? "cjk2japmt11oh0105fbyq7o8u"
              : localStorage.userId,
        },
      });

      alert("The Item Has Been Deployed");
      window.location.href = "/catalog/funnels";
    }
  }

  render() {
    const settings = this.context;

    const {
      id,
      type,
      image,
      name,
      video,
      color,
      skill,
      partner,
      isNew,
      description,
      link,
      rating,
      usageCount,
      appId,
      earnings,
      isRecommended,
      tags,
      categories,
      isExpanded,
      showEditPopup,
      campaign,
      page,
    } = this.props;

    const campaignDetails = {
      id,
      type,
      image,
      name,
      video,
      color,
      skill,
      partner,
      isNew,
      description,
      link,
      rating,
      usageCount,
      appId,
      earnings,
      isRecommended,
      tags,
      categories,
      isExpanded,
      showEditPopup,
    };

    const { isDetailsToggled, isHover } = this.state;

    const skillIcon = {
      any: "signal_cellular_alt",
      beginner: "signal_cellular_alt",
      intermediate: "signal_cellular_alt",
      advanced: "signal_cellular_alt",
    };

    return (
      <Cell
        style={{
          display: isExpanded ? "block" : "",
          width: isExpanded ? "100%" : 300,
          borderRadius: 3,
          backgroundColor: "white",
          boxShadow: "5px 5px 25px 0 rgba(46,61,73,.2)",
          margin: 30,
          marginLeft: 0,
        }}
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
      >
        <Grid justify="space-between">
          <Cell
            style={{
              width: 350,
              backgroundColor: "#f2f2f2",
              backgroundImage: `url('${image}')`,
              backgroundSize: type === "designs" ? "cover" : "contain",
              backgroundRepeat: "no-repeat",
            }}
          />
          <Cell
            style={{
              width: 675,
              padding: 35,
              paddingBottom: 0,
              backgroundSize: "50%",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
            }}
          >
            {this.state.loading ? (
              <div
                style={{
                  textAlign: "center",
                  position: "fixed",
                  width: "100%",
                  height: "100vh",
                  zIndex: 10,
                  top: 0,
                  left: 0,
                  background: "white",
                }}
              >
                <img src={settings.app.loadingImage} alt="Loading..." />
              </div>
            ) : null}

            <div style={{ height: isExpanded ? "unset" : "unset" }}>
              {/* {settings.app.adminIds.indexOf(localStorage.userId) > -1 ? ( */}
              {location.hostname === "localhost" &&
                <div
                  style={{
                    float: "right",
                    zoom: 0.75,
                    visibility: isHover ? "visible" : "hidden",
                  }}
                >
                  <div style={{ position: 'absolute', marginTop: 50, marginLeft: -125 }}>
                    <Button
                      onClick={() =>
                        showEditPopup({
                          ...campaignDetails,
                          campaignId: campaign ? campaign.id : false,
                          pageId: page ? page.id : false,
                        })
                      }
                      primary
                      style={{ color: "white", margin: 3 }}
                      icon
                    >
                      edit
                    </Button>
                    <Button
                      onClick={this.deployCatalogItem}
                      primary
                      style={{ color: "white", margin: 3 }}
                      icon
                    >
                      cloud_upload
                    </Button>
                    <Button
                      onClick={this.deleteCatalogItem}
                      primary
                      style={{ color: "white", margin: 3 }}
                      icon
                    >
                      delete
                    </Button>
                  </div>
                </div>
              }

              <Grid justify="space-between">
                <Cell>
                  {categories ? (
                    <p>{categories.split(",")[0].toUpperCase()}</p>
                  ) : null}
                </Cell>
                <Cell>
                  {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.CATALOG_ITEM_TITLE, type: campaignDetails.id }, {
                    ...campaignDetails,
                    isHover,
                  })}
                </Cell>
              </Grid>

              <h2>{name}</h2>
              <h4>
                {partner ? <div>In Collaboration with {partner}</div> : null}
                {/* <Link to="./preview">Preview</Link> */}
              </h4>
              <Button
                onClick={this.createCampaign}
                secondary
                style={{ margin: "25px 0" }}
              >
                Launch &gt;
              </Button>

            </div>
            <div style={{ ...style.bigToolbar.innerInfoBar, width: "100%" }}>
              <Grid justify="space-between">
                <Cell style={{ marginLeft: -20 }}>
                  <Button
                    onClick={this.toggleDetails}
                    transparent
                    iconLeft={`${isDetailsToggled ? "remove" : "add"}`}
                    style={{ color: "#333" }}
                  >
                    Details
                  </Button>
                </Cell>
                <Cell>
                  <Button
                    transparent
                    iconLeft={skillIcon[skill] || skillIcon.any}
                    style={{ color: "#666" }}
                  >
                    {skill}
                  </Button>
                </Cell>
              </Grid>
              {isDetailsToggled ? (
                <div
                  style={{
                    padding: "20px 0",
                    fontSize: "11pt",
                    lineHeight: "13pt",
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : null}
            </div>
          </Cell>
        </Grid>
      </Cell>
    );
  }
}

export default CatalogItem;
