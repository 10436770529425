import _ from "lodash";
import React, { Component } from "react";
import { DropTarget } from "react-dnd";

import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

import handleCanvasDragDrop from "./handleDragDrop";

import { getWebComponentStyle } from "./style";
// import { listAncestors } from "../../../v2/Editor/actions";

export const shallowEqual = (object1, object2, what) => {
  if (object1 && object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      // different number of keys
      return false;
    }

    for (let key of keys1) {
      // now compare the values of each key
      if (object1[key] !== object2[key]) {
        // console.log("Diff Detected", key, what)
        return false;
      }
    }
    return true;
  }
  return false;
};

export const whichKeysAreDifferent = (a, b) =>
  _.reduce(
    a,
    (result, value, key) =>
      _.isEqual(value, b[key]) ? result : result.concat(key),
    []
  );

const checkIfDecendentsChanged = ({
  id,
  type,
  oldPageContent,
  newPageContent,
  listAncestors = () => null,
}) => {
  const diff = whichKeysAreDifferent(oldPageContent, newPageContent);

  if (diff.length && id) {
    let willRender = false;
    diff.forEach((i) => {
      // get the id of the item that just changed
      // & compare it to the id of the item that we're deciding if we should render
      const idThatChanged = _.get(newPageContent[i], "id", false);
      const typeToRender = type;

      // check if the item that changed (idThatChanged) is a decendent of item that we're rendering (idToRender)
      const ancestors = listAncestors(newPageContent, idThatChanged);
      if (ancestors)
        willRender =
          ancestors.findIndex((itm) => _.get(itm, "id", false) === id) > -1;
      // console.log({
      //   typeToRender: type,
      //   idThatChanged,
      //   itemThatChanged: { before: oldPageContent[i], after: newPageContent[i] },
      //   idToRender: id,
      //   ancestors,
      //   willRender
      // })

      if (
        (typeToRender === "Column" || typeToRender === "Columns") &&
        ancestors
      ) {
        // check if typeToChange can be found anywhere in the hierarchy
        // in other words, as long as I mouse over a container wher the column is somewhere in the hierarchy, it will rerender
        // you could optimize this further by making it only look up two levels (vs every one)
        willRender =
          ancestors.findIndex((itm) => itm.id === idThatChanged) > -1;
      }
    });
    return willRender;
  }

  // console.log(id, whatChanged)
  return true; // for the other changes (e.g. addNew, updateDragState)
};

// Must be a class component because react-dnd does not pass along stateless
// functional components in the connectDropTarget function
export class WebComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingProperties: false,
      isEditing: false,
      objCoordinates: false,
    };

    this.setIsEditing = this.setIsEditing.bind(this);
    this.showProperties = this.showProperties.bind(this);
    this.hideProperties = this.hideProperties.bind(this);

    this.counter = 0;
  }

  setIsEditing(isEditing) {
    this.setState({ isEditing });
  }

  showProperties() {
    const {
      setActivePropertyWindows,
      getActivePropertyWindows,
      settings,
    } = this.props;
    
    // ability to know when properties are trying to show (e.g. you want to control properties externally)
    if (typeof this.props.onShowProperties === 'function') this.props.onShowProperties(settings);

    // show the properties window (assuming a disableDefaultProperties prop wasn't passed)
    if (this.props.disableDefaultProperties === undefined || !this.props.disableDefaultProperties) {
      this.setState({ showingProperties: true });
      const windows = getActivePropertyWindows();
      setActivePropertyWindows([...windows, settings.id]);
    }
      
  }

  hideProperties() {
    const {
      setActivePropertyWindows,
      getActivePropertyWindows,
      settings,
    } = this.props;

    // ability to know when properties are trying to hide (e.g. you want to control properties externally)
    if (typeof this.props.onHideProperties === 'function') this.props.onHideProperties(settings);

    this.setState({ showingProperties: false });
    const windows = getActivePropertyWindows();
    const newWindows = windows.filter((id) => id !== settings.id);
    setActivePropertyWindows(newWindows);
  }

  componentDidMount() {
    this.setState({
      objCoordinates: this.objectRef.getBoundingClientRect().toJSON(),
    });
    // console.log("mounted")
  }

  // Significantly improves editor speed
  // Instead of re-rendering every component on every state chaneg (including hover)
  // it re-renders only the specific component being affected
  shouldComponentUpdate(nextProps, nextState) {
    // return true;

    if (this.props.mode === "live") return true;

    if (!_.isEqual(nextState, this.state)) return true;

    if (!_.isEqual(nextProps, this.props)) {
      const {
        settings,
        rSettings,
        ecCoordinates,
        isDragInProgress,
        undoStackSize,
        undoPosition,
        currentMobileState,
      } = this.props;
      const whatChanged = whichKeysAreDifferent(nextProps, this.props);

      const didAnyDecendentsChange = checkIfDecendentsChanged({
        id: settings.id,
        type: settings.type,
        oldPageContent: this.props.pageContent,
        newPageContent: nextProps.pageContent,
        listAncestors: this.props.listAncestors,
        whatChanged,
      });

      if (
        ((settings.canHaveChildren ||
          settings.type === "Column" ||
          settings.type === "Columns" ||
          settings.type === "Tabs" ||
          settings.type === "Tab") &&
          didAnyDecendentsChange) || // but don't render unless decendent has changed
        !_.isEqual(settings, nextProps.settings) || // good for single elements (canHav = false)
        !_.isEqual(rSettings, nextProps.rSettings) || // good for single elements (canHav = false)
        settings.isDragging !== _.get(nextProps, "settings.isDragging") ||
        settings.isEditing !== _.get(nextProps, "settings.isEditing") ||
        !_.isEqual(ecCoordinates, nextProps.ecCoordinates) || // if the coord's change
        !_.isEqual(isDragInProgress, nextProps.isDragInProgress) || // if doing drag & drop
        !_.isEqual(undoStackSize, nextProps.undoStackSize) ||
        !_.isEqual(undoPosition, nextProps.undoPosition) ||
        !_.isEqual(currentMobileState, nextProps.currentMobileState) // if they change the mobile state
      )
        return true;

      return false;
    }

    return false;
  }

  render() {
    const {
      type,
      settings,
      getMobileState,
      rSettings = {},
      mode,
      triggerHook,
      pageContent,
      connectDropTarget = (ret) => ret,
    } = this.props;

    const { isEditing, showingProperties, objCoordinates } = this.state;

    // Pass along the coordinates of this component
    let parentCoordinates = false;
    const getPageContent = () => pageContent;

    if (this.objectRef) {
      const objParent =
        type === "Column" || type === "Tab"
          ? this.objectRef.parentNode.closest(".wc")
          : this.objectRef.closest(".wc");

      if (objParent) {
        parentCoordinates = objParent.parentNode
          .getBoundingClientRect()
          .toJSON();
      }
    }

    // Grab the appropriate plugin from the plugin
    const WebComponentPlugin = _.head(
      triggerHook(
        HookTriggers.onComponentRender,
        { id: "webcomponent", type: "component", mode },
        settings,
        {
          getPageContent,
        }
      )
    );

    // Grab any wrapper components specified in the plugin
    const WrapperComponent = _.head(
      triggerHook(
        HookTriggers.onComponentRender,
        { id: ComponentRenderHooks.WEBCOMPONENT_WRAPPER, mode },
        settings
      )
    );

    // Combine the default settings and the responsive
    // settings into the settings that are appropriate
    // for the current display
    const responsiveSettings = {
      ...rSettings,
      properties: _.omit(rSettings.properties, "mobile"),
    };

    const componentProps = {
      ...this.props,
      objCoordinates,
      setObjCoordinates: (val) => this.setState({ objCoordinates: val }),
      parentCoordinates,
      showingProperties,
      isEditing,
      setIsEditing: this.setIsEditing,
      settings: responsiveSettings,
      showProperties: this.showProperties,
      hideProperties: this.hideProperties,
      key: `wc_${settings.id}`,
    };

    const Comp = (
      <div
        id={settings.id}
        style={getWebComponentStyle(mode === "live" ? settings : rSettings)}
        className={`wc wc-${type}`}
        ref={(node) => (this.objectRef = node)}
      >
        {mode === "live"
          ? this.props.render?.(this.props) || (
              <WebComponentPlugin {...this.props} />
            )
          : this.props.render?.(componentProps) || (
              <WebComponentPlugin {...componentProps} />
            )}
      </div>
    );

    if (WrapperComponent) {
      return (
        <WrapperComponent
          settings={settings}
          pageContent={pageContent}
          currentMobileState={getMobileState()}
        >
          {connectDropTarget(Comp)}
        </WrapperComponent>
      );
    }

    return connectDropTarget(Comp);
  }
}

export default DropTarget(
  "box",
  { hover: handleCanvasDragDrop },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(WebComponent);
