export enum BenefitLayouts {
  ONE = "Benefit 01",
  TWO = "Benefit 02",
  THREE = "Benefit 03",
  FOUR = "Benefit 04",
  FIVE = "Benefit 05",
  SIX = "Benefit 06",
  SEVEN = "Benefit 07",
  EIGHT = "Benefit 08",
  NINE = "Benefit 09",
  TEN = "Benefit 10",
  ELEVEN = "Benefit 11",
  TWELVE = "Benefit 12",
  THIRTEEN = "Benefit 13",
  FOURTEEN = "Benefit 14",
  FIFTEEN = "Benefit 15",
  SIXTEEN = "Benefit 16",
  SEVENTEEN = "Benefit 17",
  EIGHTEEN = "Benefit 18",
  NINETEEN = "Benefit 19",
  TWENTY = "Benefit 20",
  TWENTYONE = "Benefit 21",
  TWENTYTWO = "Benefit 22",
  TWENTYTHREE = "Benefit 23",
  TWENTYFOUR = "Benefit 24"
}
