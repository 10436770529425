import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    fontSize: "22pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 30,
  },
  SUBHEADLINE: {
    fontSize: "13pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 30,
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  NAVIGATION: {
    linkStyle: {
      fontFamily: theme.fontFamily,
      textAlign: "left",
      paddingBottom: 15,
      color: "black",
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: "10px 40px",
      border: "none",
      backgroundColor: theme.backgroundColor,
      marginTop: 30,
      borderRadius: 3,
      cursor: "pointer",
      mobile: {
        desktop: {
          textAlign: "left",
        },
        smartphone: {
          textAlign: "center",
        },
      },
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
