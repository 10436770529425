import * as React from "react";
import { PluginProps } from "./types";
import { FormBuilder } from "@launchos/modules/v2/CRUD/FormBuilder";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import { V1ObjectWrapper } from "../V1ObjectWrapper";
import Properties from "./Properties";
// import { LiveFormFieldProps } from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField/types";

import { generateFormComponentStyle } from '../FormField/script'

/**
 * The version of the <FormBuilder /> component that shows in the editor canvas
 */
const FormBuilderEditorObject: React.FC<PluginProps> = (props) => {
  const { settings } = props;
  const { data, properties } = settings;

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        showOverlay
        {...props}
        PropertiesView={Properties}
      // dropDownItems={items => {
      //   const key = items.findIndex(({ id }) => id === "PROPERTIES");
      //   return [
      //     ...items.slice(0, key),
      //     { id: "ADD", text: "Add Another Field", icon: "add_circle" },
      //     ...items.slice(key),
      //   ]
      // }}
      // onDropDownChange={id => {
      //   if (id === "ADD") {
      //     alert("Adding New Form Item")
      //   }
      // }}
      >
        <FormBuilder data={data} globalFieldStyle={generateFormComponentStyle(properties)} />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default FormBuilderEditorObject;
