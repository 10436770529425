import * as React from "react";

import { ImageProps } from "./types";

export const Image: React.FC<ImageProps> = ({ src, style }) => {
  const img =
    src.indexOf("http://") > -1 || src.indexOf("https://") > -1
      ? src
      : // : require(`@launchos/lsvt/src/images/${src}`);
      null;

  return (
    <img
      data-testid="WC-IMAGE-LIVE"
      style={{
        maxWidth: "100%",
        ...style,
      }}
      src={img}
    />
  );
};

export default Image;
