import * as React from "react";
import { Button, Icon } from "@material-ui/core";

// import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  FilterGroupProps,
  DisplayItemsFieldDataType,
  DisplayItemsFieldData,
} from "../types";

interface ConditionsProps {
  field: DisplayItemsFieldData;
  onChange: (filter: any) => any;
}

interface DateRangeProps {
  onChange: (filter: any) => any;
}

interface ShowDialogProps {
  title: string;
  message?: string;
  fieldLabel: string;
  fieldType?: DisplayItemsFieldDataType;
  fieldValue?: any;
  onClose: () => any;
  onComplete: (response: any) => void;
}

const ShowDialog: React.FC<ShowDialogProps> = ({
  title,
  // message,
  fieldLabel,
  fieldType,
  fieldValue,
  onClose,
  onComplete,
}: ShowDialogProps) => {
  const [response, setResponse] = React.useState<any>(fieldValue || "");

  return (
    <Dialog
      data-testid="FilterGroup-FilterConditionDialog"
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>{message}</DialogContentText> */}
        {(fieldType === DisplayItemsFieldDataType.STRING ||
          fieldType === DisplayItemsFieldDataType.EMAIL ||
          fieldType === DisplayItemsFieldDataType.SENTENCE) && (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={fieldLabel}
            value={response}
            type="email"
            onChange={(e) => setResponse(e.target.value)}
            fullWidth
          />
        )}

        {(fieldType === DisplayItemsFieldDataType.DATE ||
          fieldType === DisplayItemsFieldDataType.TIME) && (
          <div>Time Placeholder</div>
          // <MuiPickersUtilsProvider utils={DateFnsUtils}>
          //   <KeyboardDatePicker
          //     disableToolbar
          //     variant="inline"
          //     format="MM/dd/yyyy"
          //     label="Start Date"
          //     value={response}
          //     onChange={d => setResponse(d)}
          //   />
          // </MuiPickersUtilsProvider>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          data-testid="FilterGroup-FilterConditionDialog-OKBtn"
          onClick={() => onComplete(response)}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ShowConditions: React.FC<ConditionsProps> = ({
  field,
  onChange,
}: ConditionsProps) => {
  const [anyDialogsShowing, showThisDialog] = React.useState<String | Boolean>(
    false
  );

  return (
    <ul data-testid="FilterGroup-FilterConditions">
      {(field.type === DisplayItemsFieldDataType.STRING ||
        field.type === DisplayItemsFieldDataType.EMAIL ||
        field.type === DisplayItemsFieldDataType.SENTENCE) && (
        <li>
          <span onClick={() => showThisDialog("startsWith")}>Starts With</span>
          {anyDialogsShowing === "startsWith" && (
            <ShowDialog
              title={field.label}
              fieldLabel="Starts With"
              fieldType={field.type}
              onClose={() => showThisDialog(false)}
              onComplete={(response) => {
                onChange({ [field.name]: response, condition: "startsWith" });
                showThisDialog(false);
              }}
            />
          )}
        </li>
      )}
      {(field.type === DisplayItemsFieldDataType.NUMBER ||
        field.type === DisplayItemsFieldDataType.CURRENCY) && (
        <li>
          <span onClick={() => showThisDialog("isGreaterThan")}>
            Is Greater Than
          </span>
          {anyDialogsShowing === "isGreaterThan" && (
            <ShowDialog
              title={field.label}
              fieldLabel="Is Greater Than"
              fieldType={field.type}
              onClose={() => showThisDialog(false)}
              onComplete={(response) => {
                onChange({
                  [field.name]: response,
                  condition: "isGreaterThan",
                });
                showThisDialog(false);
              }}
            />
          )}
        </li>
      )}
      {(field.type === DisplayItemsFieldDataType.NUMBER ||
        field.type === DisplayItemsFieldDataType.CURRENCY) && (
        <li>
          <span onClick={() => showThisDialog("isLessThan")}>Is Less Than</span>
          {anyDialogsShowing === "isLessThan" && (
            <ShowDialog
              title={field.label}
              fieldLabel="Is Less Than"
              fieldType={field.type}
              onClose={() => showThisDialog(false)}
              onComplete={(response) => {
                onChange({ [field.name]: response, condition: "isLessThan" });
                showThisDialog(false);
              }}
            />
          )}
        </li>
      )}
      {(field.type === DisplayItemsFieldDataType.NUMBER ||
        field.type === DisplayItemsFieldDataType.CURRENCY) && (
        <li>
          <span onClick={() => showThisDialog("isEqualTo")}>Is Equal To</span>
          {anyDialogsShowing === "isEqualTo" && (
            <ShowDialog
              title={field.label}
              fieldLabel="Is Equal To"
              fieldType={field.type}
              onClose={() => showThisDialog(false)}
              onComplete={(response) => {
                onChange({ [field.name]: response, condition: "isEqualTo" });
                showThisDialog(false);
              }}
            />
          )}
        </li>
      )}
      {(field.type === DisplayItemsFieldDataType.DATE ||
        field.type === DisplayItemsFieldDataType.TIME) && (
        <li>
          <span onClick={() => showThisDialog("isBefore")}>Is Before</span>
          {anyDialogsShowing === "isBefore" && (
            <ShowDialog
              title={field.label}
              fieldLabel="Is Before"
              fieldType={field.type}
              fieldValue={new Date()}
              onClose={() => showThisDialog(false)}
              onComplete={(response) => {
                onChange({ [field.name]: response, condition: "isBefore" });
                showThisDialog(false);
              }}
            />
          )}
        </li>
      )}
      {(field.type === DisplayItemsFieldDataType.DATE ||
        field.type === DisplayItemsFieldDataType.TIME) && (
        <li>
          <span onClick={() => showThisDialog("isOn")}>Is On</span>
          {anyDialogsShowing === "isOn" && (
            <ShowDialog
              title={field.label}
              fieldLabel="Is On"
              fieldType={field.type}
              fieldValue={new Date()}
              onClose={() => showThisDialog(false)}
              onComplete={(response) => {
                onChange({ [field.name]: response, condition: "isOn" });
                showThisDialog(false);
              }}
            />
          )}
        </li>
      )}
      {(field.type === DisplayItemsFieldDataType.DATE ||
        field.type === DisplayItemsFieldDataType.TIME) && (
        <li>
          <span onClick={() => showThisDialog("isAfter")}>Is After</span>
          {anyDialogsShowing === "isAfter" && (
            <ShowDialog
              title={field.label}
              fieldLabel="Is After"
              fieldType={field.type}
              fieldValue={new Date()}
              onClose={() => showThisDialog(false)}
              onComplete={(response) => {
                onChange({ [field.name]: response, condition: "isAfter" });
                showThisDialog(false);
              }}
            />
          )}
        </li>
      )}
    </ul>
  );
};

const ShowDateRange: React.FC<DateRangeProps> = ({
  onChange,
}: DateRangeProps) => {
  const [dateRange, setDateRange] = React.useState<any[]>([
    new Date(),
    new Date(),
  ]);

  const handleDateStartChange = (date: Date | null) => {
    setDateRange([date, dateRange[1]]);
    onChange({ dateRange });
  };

  const handleDateEndChange = (date: Date | null) => {
    setDateRange([dateRange[0], date]);
    onChange({ dateRange });
  };

  return (
    <div data-testid="FilterGroup-DateRange">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          label="Start Date"
          value={dateRange[0]}
          onChange={handleDateStartChange}
        />

        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          label="End Date"
          value={dateRange[1]}
          onChange={handleDateEndChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

/**
 * A component that provides filter controls against a provided data set and hooks for handling the filter selections
 */
const FilterGroup: React.FC<FilterGroupProps> = ({
  showDateRange,
  fields,
  savedFilters,
  savedFiltersIsEnabled,
  onChange,
}: FilterGroupProps) => {
  const [filtersAreShowing, setFiltersAreShowing] = React.useState<Boolean>(
    false
  );

  const [
    filterConditionIsShowing,
    setFilterConditionIsShowing,
  ] = React.useState<Boolean>(false);

  const [filterConditionToShow, setFilterConditionToShow] = React.useState<
    Number
  >(0);

  return (
    <div data-testid="FilterGroup-Default">
      <Button
        data-testid="FilterGroup-Button"
        onClick={(event) => setFiltersAreShowing(!filtersAreShowing)}
      >
        <Icon>filter_list</Icon>
      </Button>

      {filtersAreShowing && fields && (
        <ul>
          {fields.map((field, k) => (
            <li
              key={k}
              data-testid="FilterGroup-Filter"
              onClick={() => {
                // if (k === filterConditionToShow)
                // setFilterConditionIsShowing(!filterConditionIsShowing);
                // else
                setFilterConditionIsShowing(true);
                setFilterConditionToShow(k);
              }}
            >
              <span>{field.label}</span>
              {filterConditionIsShowing && filterConditionToShow === k && (
                <ShowConditions field={field} onChange={onChange} />
              )}
            </li>
          ))}
        </ul>
      )}

      {showDateRange && <ShowDateRange onChange={onChange} />}

      {savedFiltersIsEnabled &&
        savedFilters &&
        savedFilters.map((filter, k) => (
          <li
            key={k}
            data-testid="FilterGroup-SavedFilter"
            onClick={() => onChange({ filter })}
          >
            {filter}
          </li>
        ))}
    </div>
  );
};

export default FilterGroup;
