import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.EIGHTEEN,
  layout: BenefitLayouts.EIGHTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit18.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx8c94s23wd0780cdzpdmmv",
    itemId: "59nmdxw7kqt",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "01",
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.ITEM_HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Featured Benefit",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: get(content, "sub_headline.html", "..."),
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "02",
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.ITEM_HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Featured Benefit",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: get(content, "sub_headline.html", "..."),
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "03",
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.ITEM_HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Featured Benefit",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: get(content, "sub_headline.html", "..."),
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
