import * as React from "react";
import { get } from "lodash";

import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";
import carouselSettings from "../settings";
import { CarouselBuilder } from "./CarouselBuilder";
import { CarouselOptions } from "./CarouselOptions";
import { CarouselArrowOptions } from "./CarouselArrowOptions";
import { CarouselNumberOfSlides } from "./CarouselNumberOfSlides";
import { addNewCarouselItem } from "../scripts";

export const Properties = (props) => {
  const { updateComponentSettings, settings } = props;
  const { properties } = carouselSettings;
  const { sections } = properties.main;

  const withCarouselBuilder = updateSection(
    sections,
    "carouselBuilder_settings",
    {
      component: (
        <CarouselBuilder
          {...settings}
          onChange={(items) => {
            // console.log({ items });
            updateComponentSettings(settings.id, { ...settings, items });
          }}
          onAdd={() => addNewCarouselItem(props)}
        />
      ),
    }
  );

  const withCarouselOptions = updateSection(
    withCarouselBuilder,
    "carouselBuilder_options",
    {
      component: (
        <CarouselOptions
          {...settings}
          onChange={(opt, value) => {
            // console.log({ items });
            updateComponentSettings(settings.id, {
              ...settings,
              options: { ...get(settings, "options", {}), [opt]: value },
            });
          }}
        />
      ),
    }
  );

  const withCarouselArrowOptions = updateSection(
    withCarouselOptions,
    "carouselArrow_options",
    {
      component: (
        <CarouselArrowOptions
          {...settings}
          onChange={(options) => {
            // console.log({ items });
            updateComponentSettings(settings.id, {
              ...settings,
              arrowOptions: {
                ...get(settings, "arrowOptions", {}),
                [options.direction]: options,
              },
            });
          }}
        />
      ),
    }
  );

  const withCarouselNumberOfSlides = updateSection(
    withCarouselArrowOptions,
    "carouselBuilder_numOfSlides",
    {
      component: (
        <CarouselNumberOfSlides
          {...settings}
          onChange={(numberOfSlides) => {
            // console.log({ items });
            updateComponentSettings(settings.id, {
              ...settings,
              numberOfSlides,
            });
          }}
        />
      ),
    }
  );

  // don't show arrows section unless the arrows option is selected
  const updatedSections = get(settings, "options.arrows", false)
    ? withCarouselNumberOfSlides
    : withCarouselNumberOfSlides.filter(
      ({ id = false }) => id !== "carouselArrow_options"
    );

  return (
    <PropertiesBuilder
      {...props}
      data={{
        ...properties,
        main: { ...properties.main, sections: updatedSections },
      }}
      title="Carousel Settings"
    />
  );
};

export default Properties;
