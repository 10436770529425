import { Cell, Drawer, Grid, Tab, Tabs } from "@launchos/components/ui";
import { client } from "@launchos/api/gql/client";
import { getPage } from "@launchos/api/gql/pages";

import { AppConfig } from "@launchos/modules/app";
import { filter, indexOf, has, head, get } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
// import { objects } from "./objects";
import ObjectThumbnail from "./ObjectThumbnail";

import { fieldset } from "@launchos/components/ui/theme";
import { triggerHook } from "@launchos/plugins/client";
import {
  ComponentRenderHooks,
  ListItemsHooks,
  HookTriggers,
} from "@launchos/plugins/types";

import AddSectionsTab from "@launchos/plugins/misc/v2/blocks/AddSectionsTab";
import { propsData } from "@launchos/plugins/misc/v2/blocks/AddSectionsTab/AddSectionsTab.stories";

import { styles } from "./style";

import {
  ComponentTypes,
  IStates,
  BODY_ID,
} from "@launchos/modules/editor/Builder/WebComponent/types";
import { PageSectionCategory } from "@launchos/plugins/misc/v2/blocks/weblayouts/types";

import { listChildren } from "@launchos/modules/v2/Editor/actions";

const getSectionBlock = async (layout, sectionsFromPlugins) => {
  let item = head(sectionsFromPlugins.filter((itm) => itm.layout === layout));

  if (get(item, "loadFromExt", false)) {
    const {
      loadFromExtSettings: { pageId, itemId },
    } = item;

    // 1c. if it is, generate a new item.default (with children array)
    const pageDetails = await client.query({
      query: getPage,
      variables: { pageId },
    });

    const content = get(pageDetails, "data.page.content", false);

    item = {
      ...item,
      default: listChildren(content, itemId),
    };
  }

  return item;
};

class AddNewDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
    };
  }

  static contextType = AppConfig;

  render() {
    const objectsFromPlugins = triggerHook(HookTriggers.onListItems, {
      id: ComponentRenderHooks.WEBCOMPONENT,
      type: "drawer",
    });

    const sectionsFromPlugins = triggerHook(HookTriggers.onListItems, {
      id: ListItemsHooks.WEB_LAYOUT,
      type: "drawer",
    });

    let { categories } = propsData;

    // only show personal if an admin
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (get(user, "permissions", []).indexOf("admin") === -1) {
      categories = categories.filter(
        ({ type }) => type !== PageSectionCategory.PERSONAL
      );
    }

    const layoutObjects = objectsFromPlugins.filter(
      (itm) =>
        itm.category === "layout" &&
        (itm.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1 ||
          itm.name === "")
    );

    const elementObjects = objectsFromPlugins.filter(
      (itm) =>
        itm.category !== "layout" &&
        itm.category !== "social" &&
        (itm.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1 ||
          itm.name === "")
    );

    const socialObjects = objectsFromPlugins.filter(
      (itm) =>
        itm.category === "social" &&
        (itm.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1 ||
          itm.name === "")
    );

    return (
      <Drawer
        {...this.props}
        overlay={false}
        style={{ ...this.props.style, width: 400, zIndex: 301 }}
        onClose={this.props.onClose}
      >
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#F5F5F5",
            borderBottom: "1px solid #EEE",
          }}
        >
          <h2 style={{ padding: 30 }}>
            {this.props.activeTab === "OBJECTS"
              ? "Page Elements"
              : "Page Sections"}
          </h2>

          <Tabs transparent>
            <Tab
              active={this.props.activeTab === "OBJECTS"}
              onClick={() => this.props.switchTab("OBJECTS")}
            >
              Elements
            </Tab>
            <Tab
              active={this.props.activeTab === "SECTIONS"}
              onClick={() => this.props.switchTab("SECTIONS")}
            >
              Sections
            </Tab>
          </Tabs>
          {triggerHook(
            HookTriggers.onComponentRender,
            {
              id: ComponentRenderHooks.DRAWER_TITLE,
              type: this.props.activeTab,
            },
            this.props
          )}
        </div>
        {this.props.activeTab === "OBJECTS" && (
          <div
            style={{
              padding: "20px 60px",
              textAlign: "center",
              maxHeight: `calc(100vh - 130px)`,
              overflowY: "auto",
            }}
          >
            <Grid justify="center" style={{ margin: 10 }}>
              <Cell align="center" style={{ margin: 5 }}>
                <img
                  alt="drag widget hint"
                  src="https://s3.amazonaws.com/sandcastleassets/images/drag_widget_hint.png"
                />
              </Cell>
              <Cell align="center" style={{ margin: 5 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 11,
                    marginTop: 5,
                    textTransform: "uppercase",
                  }}
                >
                  Drag an object
                  <br />
                  over to your web page
                </p>
              </Cell>
            </Grid>
            <input
              type="text"
              value={this.state.filter}
              onChange={(e) => this.setState({ filter: e.target.value })}
              style={styles.input}
              placeholder="Search for an element..."
            />

            <div style={{ marginLeft: -15 }}>
              {Boolean(layoutObjects.length) && (
                <fieldset style={styles.fieldset}>
                  <legend style={styles.label}>Layout</legend>
                  <Grid
                    wrap
                    justify="start"
                    style={{ width: 275, margin: "0 auto" }}
                  >
                    {
                      // Layout
                      layoutObjects.map((obj, key) => (
                        <ObjectThumbnail
                          {...this.props}
                          item={obj}
                          key={key}
                          closeDrawer={this.props.onClose}
                          settings={{
                            type: [
                              obj.id[0].toUpperCase(),
                              ...obj.id.slice(1),
                            ].join(""),
                            ...obj.default,
                          }}
                        />
                      ))
                    }
                  </Grid>
                </fieldset>
              )}

              {Boolean(elementObjects.length) && (
                <fieldset style={styles.fieldset}>
                  <legend style={styles.label}>Elements</legend>
                  <Grid
                    wrap
                    justify="start"
                    style={{ width: 275, margin: "0 auto" }}
                  >
                    {
                      // Elements
                      elementObjects.map((obj, key) => (
                        <ObjectThumbnail
                          {...this.props}
                          item={obj}
                          key={key}
                          closeDrawer={this.props.onClose}
                          settings={{
                            type: [
                              obj.id[0].toUpperCase(),
                              ...obj.id.slice(1),
                            ].join(""),
                            ...obj.default,
                          }}
                        />
                      ))
                    }
                  </Grid>
                </fieldset>
              )}

              {Boolean(socialObjects.length) && (
                <fieldset style={styles.fieldset}>
                  <legend style={styles.label}>Social</legend>
                  <Grid
                    wrap
                    justify="start"
                    style={{ width: 275, margin: "0 auto" }}
                  >
                    {
                      // Social
                      socialObjects.map((obj, key) => (
                        <ObjectThumbnail
                          {...this.props}
                          item={obj}
                          key={key}
                          closeDrawer={this.props.onClose}
                          settings={{
                            type: [
                              obj.id[0].toUpperCase(),
                              ...obj.id.slice(1),
                            ].join(""),
                            ...obj.default,
                          }}
                        />
                      ))
                    }
                  </Grid>
                </fieldset>
              )}
            </div>
          </div>
        )}

        {this.props.activeTab === "SECTIONS" && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <AddSectionsTab
              {...this.props}
              sections={sectionsFromPlugins}
              categories={categories}
              onAddSection={async (layout, addAfterId = false) => {
                const {
                  pageContent,
                  addThisAfterThat,
                  changeState,
                } = this.props;

                // 1. grab content to add from section var
                const item = await getSectionBlock(layout, sectionsFromPlugins);

                // 2. identify the section that's "closest" to the currently active content object
                const filteredSections = pageContent.filter(
                  (itm) =>
                    itm.type === ComponentTypes.SECTION &&
                    itm.state === IStates.ACTIVE
                );

                // (if none is found, make it the last child of the BODY container)
                const bodyChildren = pageContent.filter(
                  (itm) => itm.parent === BODY_ID
                );

                let id = addAfterId;
                if (!id)
                  id = filteredSections.length
                    ? head(filteredSections)["id"]
                    : bodyChildren[bodyChildren.length - 1]["id"];

                // remove state,
                changeState(BODY_ID, "active", () => {
                  // 3. recursively add the section content below that section
                  addThisAfterThat(item.default, id);
                  this.props.onClose();
                  window.location.href = window.location.pathname + `#${id}`; // scroll to the section
                });
              }}
            />
          </div>
        )}
      </Drawer>
    );
  }
}

AddNewDrawer.propTypes = {
  activeTab: PropTypes.string,
};

PropTypes.defaultProps = {
  activeTab: "OBJECTS",
};

export default AddNewDrawer;
