/* ** MAIN EDITOR COMPONENTS ** */
import FormField from '@launchos/plugins/webcomponents/v1/formField/FormField'
import Text from '@launchos/plugins/webcomponents/v1/text/Text'

// alternative names
const Paragraph = {
  ...Text,
  settings: {
    ...Text.settings,
    id: "paragraph"
  }
};

const TextInput = {
  ...FormField,
  settings: {
    ...FormField.settings,
    id: "textinput"
  }
};

export { Paragraph, TextInput };
