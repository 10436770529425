import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import FormField from "./component";
import settings from "./settings";
import { ComponentTypes } from "../../types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FORMFIELD
  ) {
    return [FormField];
  }
};
