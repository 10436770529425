import Radium from "radium";
import React, { Component } from "react";
import { Toolbar } from "@launchos/components/ui";
import AppDrawer from "../appdrawer";
import functions from "../dashboard/functions";
import {
  leftActionsGroup,
  rightActionsGroup
} from "../dashboard/ToolbarComponents";
import DomainSection from "./DomainManagement/DomainSection";
import IntegrationsSection from "./IntegrationManagement/IntegrationsSection";
import ProfileSection from "./UserManagement/ProfileSection";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = { appDrawerVisible: false };
    this.toggleAppDrawer = functions.toggleAppDrawer.bind(this);
  }

  render() {
    return (
      <div>
        <AppDrawer
          hidden={!this.state.appDrawerVisible}
          toggleAppDrawer={this.toggleAppDrawer}
        />
        <Toolbar
          leftActionsGroup={leftActionsGroup}
          rightActionsGroup={rightActionsGroup}
          toggleAppDrawer={this.toggleAppDrawer}
        />
        {this.props.tab === "profile" ? (
          <ProfileSection hidden={false} {...this.props} />
        ) : null}
        {this.props.tab === "domains" ? (
          <DomainSection hidden={false} {...this.props} />
        ) : null}
        {this.props.tab === "integrations" ? (
          <IntegrationsSection
            hidden={false}
            {...this.props}
            integrations={[true, true]}
          />
        ) : null}
      </div>
    );
  }
}

export default Radium(Settings);
