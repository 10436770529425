import { get, head, filter } from "lodash";
import moment from "moment";

import theme from "@launchos/components/ui/theme";
import { objects } from "@launchos/modules/campaign/shared";
import { IPage, QuickDateRange } from "../types";

export const getBackground: (page: IPage) => string = (page) => {
  let { screenshot, type } = page;

  if (!screenshot) {
    const thumbMatch = head(
      filter(objects.pages, (itm) => itm.pageType === type)
    );
    screenshot = get(thumbMatch, "screenshot");
  }

  if (!screenshot) return theme.lightColor;

  if (screenshot.indexOf("PT_misc_blank") > -1) return "#760144";
  if (screenshot.indexOf("PT_content_article") > -1) return "#0099CB";
  if (screenshot.indexOf("PT_misc_about") > -1) return "#760144";
  if (screenshot.indexOf("PT_content_product") > -1) return "#0099CB";
  if (screenshot.indexOf("PT_squeeze_email") > -1) return "#EC8522";
  if (screenshot.indexOf("PT_misc_terms") > -1) return "#760144";
  if (screenshot.indexOf("PT_content_video") > -1) return "#0099CB";
  if (screenshot.indexOf("PT_sales_precart") > -1) return "#C0B937";
  if (screenshot.indexOf("PT_sales_longform") > -1) return "#C0B937";
  if (screenshot.indexOf("PT_sales") > -1 || type === "sales") return "#C0B937";
  if (screenshot.indexOf("PT_sales_bonus") > -1) return "#C0B937";
  if (screenshot.indexOf("PT_ty_simple") > -1) return "#FEBE34";
  if (screenshot.indexOf("PT_squeeze_webinar") > -1) return "#EC8522";
  if (screenshot.indexOf("PT_popup_optin") > -1) return "#64DDB8";
  if (screenshot.indexOf("TS_facebook") > -1) return "#547BBC";
  if (screenshot.indexOf("TS_adwords") > -1) return "#4B7BBE";
  if (screenshot.indexOf("TS_email") > -1) return "#FAC666";
  if (screenshot.indexOf("TS_blog") > -1) return "#8C589F";
  if (screenshot.indexOf("TS_twitter") > -1) return "#78CBEF";
  if (screenshot.indexOf("TS_youtube") > -1) return "#CE4646";
  if (screenshot.indexOf("TS_webinar") > -1) return "#ADCE74";
  if (screenshot.indexOf("TS_affiliate") > -1) return "#8CCEDD";
  if (screenshot.indexOf("TS_other") > -1) return "#D87CA5";
  if (screenshot.indexOf("TS_organic") > -1) return "#EF8952";
  if (screenshot.indexOf("TS_organic") > -1) return "#EF8952";

  if (screenshot.indexOf("AC_splittesting") > -1) return "#B08ABE";
  if (screenshot.indexOf("AC_pageredirect") > -1) return "#B08ABE";
  if (screenshot.indexOf("AC_conditionalredirect") > -1) return "#B08ABE";
  if (screenshot.indexOf("AC_listsegment") > -1) return "#333333";
  if (screenshot.indexOf("AC_anotherfunnel") > -1) return "#8CCEDD";
};

export const calcStartEndDates = (str) => {
  let startDate;
  let endDate;

  switch (str) {
    case QuickDateRange.TODAY: {
      startDate = moment().startOf("day").format();
      endDate = moment().endOf("day").format();
    }

    case QuickDateRange.YESTERDAY: {
      startDate = moment().subtract(1, "days").startOf("day").format();
      endDate = moment().subtract(1, "days").endOf("day").format();
    }

    case QuickDateRange.LASTWEEK: {
      startDate = moment().subtract(7, "days").startOf("week").format();
      endDate = moment().subtract(7, "days").endOf("week").format();
    }

    case QuickDateRange.LASTMONTH: {
      startDate = moment().subtract(30, "days").startOf("month").format();
      endDate = moment().subtract(30, "days").endOf("month").format();
    }

    case QuickDateRange.LAST7DAYS: {
      startDate = moment().subtract(7, "days").format();
      endDate = moment().format();
    }

    case QuickDateRange.LAST30DAYS: {
      startDate = moment().subtract(30, "days").format();
      endDate = moment().format();
    }

    case QuickDateRange.ALLTIME: {
      startDate = moment("2021").format();
      endDate = moment().format();
    }
  }

  return { startDate, endDate };
};

export const calcConversionRate = (
  { originId, destinationId, objects },
  getCampaignState
) => {
  const getPageId = (id) =>
    get(head(objects.filter((node) => node.id === id)), "page.id");

  const originValue = Number(
    get(getCampaignState(getPageId(originId)), "peopleCount", 0)
  );
  const destinationValue = Number(
    get(getCampaignState(getPageId(destinationId)), "peopleCount", 0)
  );

  if (!originValue || !destinationValue) return 0;

  return (
    parseFloat(String((destinationValue / originValue) * 100)).toFixed(0) || 0
  );
};
