import _ from "lodash";
import React from "react";
import { ListItem, TextInput, Menu, Button, FontIcon } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import { NiceMenu } from "../Helpers/NiceMenu";
import { GoToUrl } from "./GoToUrl";
import ReactJson from "react-json-view";

import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

import { ShowUserFields } from "@launchos/modules/settings/IntegrationManagement/IntegrationBuilder/ShowUserFieflds";

import { DropDownCollapse } from "@launchos/modules/editor/Builder/Properties";
import { ActionTypes } from "../types";
import { cancelActions } from "@launchos/modules/editor/scripts";

import { allIntegrationQuery } from "@launchos/api/gql/integrations";
import { client } from "@launchos/api/gql";

import style from "../../../style";

export const TriggerWebHook = (props) => {
  const [webHooks, setWebHooks] = React.useState([]);
  const [activeHook, setActiveHook] = React.useState({});

  const { action, updateAction } = props;
  const { payload } = action;

  // const webhooks = [{ name: 'Testing 123' }]

  const loadIntegrations = async () => {
    const allIntegrations = await client.query({
      query: allIntegrationQuery,
      variables: { userId: localStorage.userId },
    });

    const customHooks = allIntegrations.data.integrations.filter(
      (itm) => itm.type === "webhook"
    );
    const toUse = customHooks.map(({ id, setting }) => ({ id, ...setting }));

    setWebHooks(toUse);

    if (_.get(payload, "type") === "webhook") {
      // const name = _.get(payload, '')
      const { id } = payload;
      const hookDetails = _.head(toUse.filter((hook) => hook.id === id));
      // const name = hookDetails.name
      setActiveHook(hookDetails);
      console.log({ toUse, customHooks, payload, hookDetails });
    }
  };

  React.useEffect(() => {
    loadIntegrations();
  }, []);

  return (
    <AppConfig.Consumer>
      {({ app }) => (
        <div
          style={{ marginTop: 10 }}
          onClick={cancelActions}
          onDoubleClick={cancelActions}
          onMouseDown={cancelActions}
          onContextMenu={cancelActions}
          onDrag={cancelActions}
          onDragStart={cancelActions}
        >
          <div style={{ marginBottom: 10 }}>
            <NiceMenu label={_.get(activeHook, "name", "Choose...")}>
              {webHooks.map((hook) => (
                <ListItem
                  onClick={() => {
                    const newPayload = {
                      type: "webhook",
                      name: hook.name,
                      id: hook.id,
                      request_method: "POST",
                      settings: hook.settings,
                    };
                    updateAction(action, newPayload);

                    setActiveHook(newPayload);
                  }}
                >
                  <div style={{ display: "flex", textAlign: "center" }}>
                    <div style={{ marginRight: 10, textAlign: "center" }}>
                      <img
                        style={{ width: 25, maxWidth: 25, maxHeight: 30 }}
                        alt=""
                        src={hook.icon === "" ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtDJgneZ_dezqakp5msHb0dXVfaZdJ2lztRH1y_e_ZM-WOeFc" : hook.icon}
                      />
                    </div>
                    <div style={{ marginTop: 4 }}>{hook.name}</div>
                  </div>
                </ListItem>
              ))}

              {Boolean(webHooks.length) && <div style={style.divider} />}

              <ListItem
                onClick={() => {
                  const newPayload = {
                    type: "custom",
                    name: "Custom Webhook",
                  };
                  updateAction(action, newPayload);

                  setActiveHook(newPayload);
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <FontIcon>add</FontIcon>
                  </div>
                  <div style={{ padding: "1px 5px" }}>
                    Create a Custom Webhook
                  </div>
                </div>
              </ListItem>
            </NiceMenu>

            {/* Place User Fields */}
            {_.get(activeHook, 'settings', '') !== "" && (
              <div style={{ marginTop: 10 }}>
                <ShowUserFields onChange={(obj) => updateAction(action, { ...payload, ...obj })} settings={activeHook.settings} values={payload} />
              </div>
            )}

            {_.get(payload, "type") !== "custom" &&
              _.has(activeHook, "name") && (
                <GoToUrl
                  action={action}
                  updateAction={updateAction}
                  {...props}
                />
              )}
          </div>

          {_.get(payload, "type") === "custom" && (
            <div>
              <TextInput
                value={_.get(payload, "request_url", "https://")}
                onBlur={(e) => {
                  updateAction(action, {
                    ...payload,
                    request_url: e.target.value,
                  });
                }}
                placeholder="Web Hook URL"
                style={{
                  textAlign: "left",
                  padding: "20px 85px 20px 20px",
                  fontSize: "9pt",
                }}
              />

              <Menu
                style={{ float: "right", marginTop: -35, padding: 5 }}
                label={_.get(payload, "request_method", "POST")}
              >
                <ListItem
                  onClick={() => {
                    updateAction(action, {
                      ...payload,
                      request_method: "POST",
                    });
                  }}
                >
                  POST
                </ListItem>
                <ListItem
                  onClick={() => {
                    updateAction(action, { ...payload, request_method: "GET" });
                  }}
                >
                  GET
                </ListItem>
                <ListItem
                  onClick={() => {
                    updateAction(action, { ...payload, request_method: "PUT" });
                  }}
                >
                  PUT
                </ListItem>
                <ListItem
                  onClick={() => {
                    updateAction(action, {
                      ...payload,
                      request_method: "PATCH",
                    });
                  }}
                >
                  PATCH
                </ListItem>
                <ListItem
                  onClick={() => {
                    updateAction(action, {
                      ...payload,
                      request_method: "DELETE",
                    });
                  }}
                >
                  DELETE
                </ListItem>
              </Menu>

              <GoToUrl action={action} updateAction={updateAction} {...props} />

              <div
                style={{
                  borderTop: "1px solid #CCC",
                  marginTop: 30,
                  marginBottom: -15,
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    position: "relative",
                    top: -17,
                    background: "white",
                    padding: "5px 15px",
                    display: "inline-block",
                  }}
                >
                  ADVANCED
                </span>
              </div>

              <DropDownCollapse
                text="Send Extra Data (JSON)"
                style={{
                  backgroundColor: "white",
                  fontWeight: "normal",
                  padding: 20,
                }}
              >
                <div style={{ padding: 5 }} />
                <ReactJson
                  name="Body Values"
                  style={{ fontSize: "9pt" }}
                  src={_.get(action, "payload.body", {})}
                  enableClipboard={false}
                  displayObjectSize={false}
                  quotesOnKeys={false}
                  displayDataTypes={false}
                  onEdit={({
                    updated_src,
                    name,
                    namespace,
                    new_value,
                    existing_value,
                  }) => {
                    updateAction(action, { ...payload, body: updated_src });
                    // return false;
                  }}
                  onAdd={() => null}
                  onDelete={({ updated_src }) => {
                    updateAction(action, { ...payload, body: updated_src });
                  }}
                  collapseStringsAfterLength={12}
                />
                <div style={{ padding: 10 }} />
                <ReactJson
                  name="Header Values"
                  style={{ fontSize: "9pt" }}
                  src={_.get(action, "payload.headers", {})}
                  enableClipboard={false}
                  displayObjectSize={false}
                  quotesOnKeys={false}
                  displayDataTypes={false}
                  onEdit={({
                    updated_src,
                    name,
                    namespace,
                    new_value,
                    existing_value,
                  }) => {
                    updateAction(action, { ...payload, headers: updated_src });
                    // return false;
                  }}
                  onAdd={() => null}
                  onDelete={({ updated_src }) => {
                    updateAction(action, { ...payload, headers: updated_src });
                  }}
                  collapseStringsAfterLength={12}
                />
              </DropDownCollapse>

              {/* <div style={{ textAlign: 'center' }}>
                <Button tertiary>Test Webhook</Button>
              </div> */}
            </div>
          )}

          <div style={{ display: "flex", marginTop: 30 }}>
            {triggerHook(
              HookTriggers.onComponentRender,
              {
                id: ComponentRenderHooks.SECTIONS_TITLE,
                type: ActionTypes.TRIGGER_WEBHOOK,
              },
              props
            )}
            <label style={{ paddingLeft: 8, marginTop: -2 }}>Learn More</label>
          </div>
        </div>
      )}
    </AppConfig.Consumer>
  );
};
