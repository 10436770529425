import { map } from "lodash";
import { Drawer, ListItem } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React from "react";
import { Link } from "react-router-dom";
import { triggerHook } from "@launchos/plugins/client";
import {
  ComponentRenderHooks,
  ListItemsHooks,
  HookTriggers,
} from "@launchos/plugins/types";

const DrawerItem = ({ route, link, icon, label }) => {
  if (link)
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <ListItem leftIcon={icon}>{label}</ListItem>
      </a>
    );
  else
    return (
      <Link to={route}>
        <ListItem leftIcon={icon}>{label}</ListItem>
      </Link>
    );
};

const AppDrawer = (props) => {
  const menuItems = triggerHook(HookTriggers.onListItems, {
    id: ListItemsHooks.APP_DRAWER,
    position: "top",
  });

  const bottomMenuItems = triggerHook(HookTriggers.onListItems, {
    id: ListItemsHooks.APP_DRAWER,
    position: "bottom",
  });

  return (
    <AppConfig.Consumer>
      {({ app }) => (
        <Drawer
          {...props}
          onClose={props.toggleAppDrawer}
          style={{ position: "fixed", zIndex: 1000 }}
        >
          {app.showBigLogo ? (
            <div
              style={{
                borderBottom: "1px solid #ccc",
                padding: 40,
                textAlign: "center",
              }}
            >
              <Link to="/dashboard">
                <div style={{ padding: 10 }}>
                  <img src={app.logoIcon} role="presentation" alt="Logo Icon" />
                </div>
              </Link>
              <div style={{ padding: 5 }}>{app.name}</div>
            </div>
          ) : null}

          <div style={{ padding: "60px 25px" }}>
            <h5 style={{ padding: "0 20px" }}>Apps</h5>
            <Link to="/dashboard">
              <ListItem leftIcon="dashboard">Dashboard</ListItem>
            </Link>
            {map(menuItems, ({ route, icon, label }, key) => (
              <Link key={key} to={route}>
                <ListItem leftIcon={icon}>{label}</ListItem>
              </Link>
            ))}
          </div>

          <div
            style={{
              borderTop: "1px solid #ccc",
              width: "100%",
              // padding: "20px 0",
              padding: "40px 25px",
              // marginTop: 100,
              bottom: 0,
              position: "absolute",
            }}
          >
            <Link to="/catalog">
              <ListItem leftIcon="highlight">Funnel Catalog</ListItem>
            </Link>
            <Link to="/settings/domains">
              <ListItem leftIcon="dns">Domain Names</ListItem>
            </Link>
            {/* <a href={app.helpUrl} target="_blank" rel="noopener noreferrer">
              <ListItem leftIcon="help">Help &amp; Support</ListItem>
            </a> */}
            {map(bottomMenuItems, (settings, key) => (
              <DrawerItem {...settings} key={key} />
            ))}
          </div>
        </Drawer>
      )}
    </AppConfig.Consumer>
  );
};

export default AppDrawer;
