import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 0,
      },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 20,
    fontSize: "9pt",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 12,
      border: "none",
      backgroundColor: theme.backgroundColor,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "100%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.backgroundColor}`,
      borderRadius: 5,
      color: theme.backgroundColor,
    },
  },
};
