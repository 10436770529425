import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: `rgb(0,0,0,0.75)`,
    backgroundImage: `url(https://images.unsplash.com/photo-1497561813398-8fcc7a37b567?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80)`,
    backgroundSize: "cover",
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 0,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
    padding: 5,
    mobile: {
      desktop: {
        fontSize: "28pt",
      },
      smartphone: {
        fontSize: "22pt",
      },
    },
  },
  SUBHEADLINE: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    padding: 5,
    mobile: {
      desktop: {
        fontSize: "18pt",
        fontWeight: "bold" as "bold",
        lineHeight: "20pt",
      },
      smartphone: {
        fontSize: "14pt",
        lineHeight: "16px",
      },
    },
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  FORM_FIELD: {
    style: {
      padding: "5px 0",
      maxWidth: "100%",
    },
    inputStyle: {
      width: "100%",
      // backgroundColor: "#EEE",
      padding: 15,
      // borderRadius: 20,
      border: "1px solid #DDD",
      borderRadius: 4,
    },
    labelStyle: {
      fontFamily: "Roboto",
      fontSize: "10pt",
      textAlign: "left",
      padding: 5,
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: "15px 15px 12px",
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "12pt",
    },
    caption2: {
      color: theme.foregroundColor,
      fontSize: "9pt",
    },
  },
};
