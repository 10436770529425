import * as React from "react";

import {
  EditorComponentProps,
  EditorMode,
} from "@launchos/modules/v2/Editor/types";

export enum ShareButtonTypes {
  EMAIL = "Email",
  FACEBOOK = "Facebook",
  INSTAPAPER = "Instapaper",
  LINE = "Line",
  LINKEDIN = "Linkedin",
  LIVEJOURNAL = "Livejournal",
  MAILRU = "Mailru",
  OK = "OK",
  PINTEREST = "Pinterest",
  POCKET = "Pocket",
  REDDIT = "Reddit",
  TELEGRAM = "Telegram",
  TUMBLR = "Tumblr",
  TWITTER = "Twitter",
  VIBER = "Viber",
  VK = "VK",
  WHATSAPP = "Whatsapp",
  WORKPLACE = "Workplace",
}

export interface ShareButtonProps {
  mode?: EditorMode;
  children?: React.ReactNode | any;
  onComplete?: () => any;
  onClick?: () => any;
  subject?: string;
  content?: string;
  url?: string;
  hashtags?: string[] | string | any;
  showShareCount?: Boolean;
  shareButtonType: ShareButtonTypes;
}

export interface PluginProps extends EditorComponentProps {
  settings: ShareButtonProps;
}
