// import { getProviderCampaigns } from "@launchos/api/gql/campaigns";
import {
  allDomainsQuery,
  createDomain,
  deleteDomainMutation
} from "@launchos/api/gql/domains";
import {
  allIntegrationQuery,
  createIntegration,
  deleteIntegrationMutation,
  updateIntegration
} from "@launchos/api/gql/integrations";
import { getUser, updateUserMutation } from "@launchos/api/gql/users";
import { compose, graphql } from "react-apollo";
import Settings from "./Settings";

export default compose(
  // graphql(getProviderCampaigns, { name: "getProviderCampaigns" }),
  graphql(allDomainsQuery, {
    name: "allDomainsQuery",
    options: props => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),

  graphql(allIntegrationQuery, {
    name: "allIntegrationQuery",
    options: props => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),

  graphql(deleteDomainMutation, { name: "deleteDomainMutation" }),

  graphql(deleteIntegrationMutation, { name: "deleteIntegrationMutation" }),

  graphql(getUser, {
    name: "getUserQuery",
    options: () => ({
      variables: {
        userId: localStorage.userId
      }
    })
  }),

  graphql(createDomain, {
    name: "createDomain"
  }),

  graphql(createIntegration, {
    name: "createIntegration"
  }),

  graphql(updateIntegration, { name: "updateIntegration" }),

  graphql(updateUserMutation, { name: "updateUserMutation" })
)(Settings);
