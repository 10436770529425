import { Button } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
// import { app } from "../../../config";
import AvatarButton from "../../settings/AvatarButton";

const leftActionsGroup = [
  (props) => (
    <Button style={{ color: "inherit" }} icon onClick={props.toggleAppDrawer}>
      menu
    </Button>
  ),
];

const title = "My Campaign Name";

const rightActionsGroup = [
  (props) =>
    _.has(props, "savingStatus.length") ? (
      <span
        style={{ color: "#AAA", fontSize: 14 }}
        title="Every change you make is automatically saved."
      >
        {props.savingStatus[0] === "SAVING" && "Saving..."}
        {props.savingStatus[0] === "SAVED" &&
          `All changes saved (${moment(props.savingStatus[1]).format("LT")})`}
      </span>
    ) : null,
  (props) =>
    !props.noPreview ? (
      <Link to="./builder/preview" target="_blank" style={{ color: "#333" }}>
        <Button style={{ color: "inherit" }} icon>
          visibility
        </Button>
      </Link>
    ) : null,
  (props) => (
    <Button iconLeft="publish" secondary onClick={props.onPublish}>
      Publish
    </Button>
  ),
  (props) => (
    <AppConfig.Consumer>
      {({ app }) => {
        if (app.showAvatar) return <AvatarButton {...props} />;
        else return null;
      }}
    </AppConfig.Consumer>
  ),
];

export { leftActionsGroup, title, rightActionsGroup };
