export default {
  containerStyle: {
    position: "fixed",
    textAlign: "center",
    left: 0,
    top: 0,
    height: "100vh",
    backgroundColor: "#EEE"
  },
  characterStyle: {
    fontFamily: "Arial",
    padding: 3,
    fontSize: 20,
    margin: 3,
    color: "#666",
    display: "block",
    cursor: "pointer"
  }
};
