import * as React from "react";
import { get } from "lodash";

import {
  SortableList,
  DefaultItemComponent,
} from "@launchos/modules/v2/CRUD/SortableList";

import { AttributeContainer } from "@launchos/modules/v2/CRUD/FormBuilder";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";
import { LiveFormFieldProps } from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField/types";
import { ItemComponentTypes } from "@launchos/modules/v2/CRUD/SortableList/types";
import { TabItemType } from "../types";
import { IconTypes } from "../../Icon";

interface TabAttributes extends LiveFormFieldProps {
  active: boolean;
}

export const TabBuilder: React.FC<{
  items: TabItemType[];
  onChange: () => void;
  onAdd: () => void;
}> = ({
  items = [],
  onChange = () => null,
  onAdd = () => null,
}) => {

    const handleChange = (mutatedPayload: TabItemType[]): void => onChange(mutatedPayload);

    const handleEdit = (data: TabItemType): void => {
      handleChange(items.map((itm) => ({ ...itm, active: itm.id === data.id })));
    };

    const ItemComponent = (itm) => {
      const [attributes, setAttributes] = React.useState<TabAttributes>(itm)
      const { id, label, description, active } = attributes;

      return (
        <DefaultItemComponent
          {...itm}
          placeholder="Add a new tab"
          caption={get(itm, 'label', 'Untitled Tab')}
          type={ItemComponentTypes.TEXT}
          isExpanded={active}
          icon={active ? IconTypes.RadioButtonChecked : IconTypes.RadioButtonUnchecked}
          toggleExpansion={() => handleEdit(itm)}
        >
          <div style={{
            backgroundColor: "#F5F5F5",
            borderRadius: 5,
            padding: 30
          }}>
            <AttributeContainer
              attributes={attributes}
              data={[
                {
                  id: "3",
                  attribute: AttributeNames.LABEL,
                  settings: {

                  },
                },
                {
                  id: "4",
                  attribute: AttributeNames.DESCRIPTION,
                  settings: {},
                },
                { id: "1", attribute: AttributeNames.ICON, settings: { isExpanded: false } },
              ]}
              onChange={setAttributes}
              onBlur={newAttributes => {
                console.log("AttributeContainer Blur", { newAttributes })
                const key = items.findIndex((itm) => itm.id === id);
                const newItems = [
                  ...items.slice(0, key),
                  newAttributes,
                  ...items.slice(key + 1),
                ];
                onChange(newItems);
              }}
              hideFooter
            />
          </div>
        </DefaultItemComponent>
      )
    }

    return (
      <div style={{
        width: 350,
        marginLeft: -20,
      }}>
        <SortableList
          data={items}
          itemStyle={{
            border: "1px solid #DDD",
            borderRadius: 3,
            margin: "10px 0",
            height: 45,
          }}
          itemHoverStyle={{
            border: "1px solid #AAA",
            boxShadow: "0 2px 1px 0 rgb(0,0,0,0.1)",
            backgroundColor: "#F3F3F3"
          }}
          ItemComponent={ItemComponent}
          onAdd={onAdd}
          onEdit={handleEdit}
          onChange={handleChange}
          onDelete={(payload, updatedData) => { if (updatedData.length) handleChange(updatedData) }}
          canDragAndDrop
          showItemInline={false}
          name="Tab"
        />
      </div>)
  };
