import * as React from "react";
import { get } from "lodash";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";
import { fieldset } from "@launchos/components/ui/theme";

import { PropertiesProps, LinkLayoutStyles, LinkDisplayTypes } from "../types";
import linkBuilderSettings from "../settings";

import { LinkBuilder } from "./LinkBuilder";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { IconTypes } from "../../Icon";

import { ILinks } from "@launchos/modules/v2/CRUD/AttributeBuilder/LinkSelector/types";
/**
 * The property window that shows for the FormBuilderEditorObject
 */
const LinkBuilderPropertyWindow: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);
  const { updateComponentSettings, getCampaignQuery } = props;

  const handleSettingsChange = (id, settings, dbUpdate = true) => {
    setSettings(settings);
    if (dbUpdate) updateComponentSettings(id, settings, true, false);
  };

  const { properties } = linkBuilderSettings;
  const { sections } = properties.main;

  const withLinkLayout = updateSection(sections, "LinkLayout", {
    component: (
      <fieldset style={fieldset}>
        <legend>Layout Style</legend>
        <RadioGroup
          // aria-label="linkDisplay"
          // name="linkDisplay"
          value={settings.layoutStyle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSettingsChange(settings.id, {
              ...settings,
              layoutStyle: e.target.value as LinkLayoutStyles,
            });
          }}
        >
          <div style={{ display: "flex" }}>
            <FormControlLabel
              value={LinkLayoutStyles.HORIZONTAL}
              control={<Radio />}
              label="Horizontal"
            />
            <FormControlLabel
              value={LinkLayoutStyles.VERTICAL}
              control={<Radio />}
              label="Vertical"
            />
          </div>
        </RadioGroup>
      </fieldset>
    ),
  });

  const withLinkBuilder = updateSection(withLinkLayout, "LinkBuilderSettings", {
    component: (
      <LinkBuilder
        {...settings}
        links={get(getCampaignQuery, "campaign.objects", []).map((object) => ({
          type: object.page.type,
          slug: object.page.slug,
          name: object.name,
        }))}
        onChange={(data) =>
          handleSettingsChange(settings.id, { ...props.settings, data })
        }
      />
    ),
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withLinkBuilder },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export default React.memo(LinkBuilderPropertyWindow);
// export default LinkBuilderPropertyWindow;
