import * as React from "react";
import styled from "styled-components";

import { BulkActionsMenu } from "../";
import {
  BulkSelectedActionsProps,
  BulkSelectedActionsPropsLocation,
} from "../types";
import styles from "./styles";

interface sProps {
  location: BulkSelectedActionsPropsLocation;
}

const BulkSelectedActionsWrapper = styled.div<sProps>`
  position: fixed;
  ${(p) =>
    p.location === BulkSelectedActionsPropsLocation.TOP
      ? `top: 0;`
      : `bottom: 0;`}
  left: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #eee;
  box-shadow: -5px 0 7px 3px #eee;
  background-color: white;
`;

/**
 * Shows when there are one or more items selected.  Provides a quick way to perform bulk actions on the selected list
 */
const BulkSelectedActions: React.FC<BulkSelectedActionsProps> = (
  props: BulkSelectedActionsProps
) => {
  const {
    showActionButtons,
    actionButtons,
    showBulkActionsMenuButton,
    bulkActionsMenuSettings,
    location,
    children,
    data,
  } = props;

  return (
    <BulkSelectedActionsWrapper
      location={location}
      data-testid={`BulkSelectedActions-location-${location}`}
    >
      <div data-testid="BulkSelectedActions-Default" style={styles}>
        {children && (
          <div data-testid="BulkSelectedActions-Children">
            {typeof children === "function" ? children(props) : children}
          </div>
        )}
        {!children && (
          <div>
            {data.length} item{data.length !== 1 && `s`} selected
          </div>
        )}
        {showActionButtons && (
          <div data-testid="BulkSelectedActions-ActionButtons">
            {actionButtons &&
              actionButtons.map((Btn, key) => (
                <span key={key} data-testid="BulkSelectedActions-ActionButton">
                  <Btn {...props} />
                </span>
              ))}
          </div>
        )}
        {showBulkActionsMenuButton && bulkActionsMenuSettings && (
          <BulkActionsMenu {...bulkActionsMenuSettings} />
        )}
      </div>
    </BulkSelectedActionsWrapper>
  );
};

BulkSelectedActions.defaultProps = {
  data: [],
  location: BulkSelectedActionsPropsLocation.BOTTOM,
  showActionButtons: false,
  showBulkActionsMenuButton: false,
};

export default BulkSelectedActions;
