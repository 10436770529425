import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 80,
      },
      smartphone: {
        padding: 20,
      },
    },
  },
  HEADLINE: {
    fontSize: "14pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    marginTop: 5,
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    marginTop: 10,
    fontSize: "12pt",
    lineHeight: "18pt",
    color: "#666",
  },
  LIST: {
    paddingLeft: 0,
    padding: 50,
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  LI: {
    style: { marginTop: 40 },
    iconStyle: {
      backgroundColor: theme.foregroundColor,
      color: theme.backgroundColor,
      fontSize: "36pt",
      paddingRight: 15,
    },
  },
};
