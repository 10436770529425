import gql from "graphql-tag";

// verifyUser
export const verifyUser = gql`
  query allUsers($email: String!, $password: String!) {
    users(where: { email: $email, password: $password }) {
      id
      firstName
      lastName
      email
      subdomain
      deleted
      permissions
      apps {
        id
        name
      }
      domains {
        id
        name
      }
    }
  }
`;

// getUser
export const getUser = gql`
  query getUser($userId: ID!) {
    user(where: { id: $userId }) {
      id
      firstName
      lastName
      subdomain
      email
      domains {
        id
        name
      }
      permissions
      apps {
        id
        name      
        host
        defaultDomain
      }
    }
  }
`;

// updateUserMutation;
export const updateUserMutation = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $subdomain: String
    $password: String
  ) {
    updateUser(
      where: { id: $id }
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        subdomain: $subdomain
        password: $password
      }
    ) {
      id
    }
  }
`;
