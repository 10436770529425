import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import theme from "@launchos/components/ui/theme";

import { IComponentSettings, ComponentTypes } from "../../types";
import {
  ActionTypes,
  ActionBehaviors,
} from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/types";

const settings: IComponentSettings = {
  id: ComponentTypes.ORDERBUMP,
  name: "Order Bump",
  description:
    "An Order Bump is a powerful way to increase the value of each purchase. It is a way to provide your customers the chance to add an additional item to their shopping cart right before completing their order.",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "queue",
  },
  default: {
    type: ComponentTypes.ORDERBUMP,
    canHaveChildren: false,
    properties: {
      display: "inline-block",
    },
    actions: [
      {
        behavior: ActionBehaviors.CLICK,
        name: "Toggle Add/Remove To Cart",
        payload: false,
        type: ActionTypes.TOGGLE_ADD_REMOVE_TO_CART,
      },
    ],
    html: `<p><span style="font-size: 1.0rem;">Add the [Bonus Product Name] to your order for <strong>just $27,</strong> (normally $199) and get the [Substantial Benefit]!</span></p>`,
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.SMARTLOGIC,
          sectionWrap: true,
          settings: {
            label: "Smart Logic",
            icon: "psychology",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ACTIONS,
          settings: {
            label: "Order Bump Actions",
            children: null,
            isExpanded: true,
          },
        },
      ],
    },
  },
};

export default settings;
