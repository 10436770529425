import * as React from "react";
import { get, head, keys, map } from "lodash";
import ReactJson from "react-json-view";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import linkBuilderSettings from "../settings";

import {
  SortableList,
  DefaultItemComponent,
} from "@launchos/modules/v2/CRUD/SortableList";
import { fieldset } from "@launchos/components/ui/theme";

import Icon, { IconTypes } from "../../Icon";

import { ListItemType, ListBuilderProps, LinkDisplayTypes } from "../types";
// import style from "./style";
import { AttributeContainer } from "@launchos/modules/v2/CRUD/FormBuilder";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";
import { LiveFormFieldProps } from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField/types";
import { ItemComponentTypes } from "@launchos/modules/v2/CRUD/SortableList/types";
import { ButtonPresets } from "../../Button/types";
import theme from "../../../../misc/v2/blocks/weblayouts/theme";

interface ItemComponentProps extends ListItemType {
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isHovering?: boolean;
}

interface LinkBuilderAttributes extends LiveFormFieldProps {
  linkDisplayType: LinkDisplayTypes;
}

export const LinkBuilder: React.FC<ListBuilderProps> = ({
  data,
  onChange,
  icon,
  links,
}) => {
  const [isExpandedList, setIsExpandedList] = React.useState<string[]>([]);

  const handleChange = (mutatedPayload: ListItemType[]): void => {
    console.log({ mutatedPayload });
    onChange(mutatedPayload);
  };

  const handleEdit = (data: ListItemType): void => {
    // toggle expansion
    if (data.id === head(isExpandedList)) setIsExpandedList([])
    else setIsExpandedList([data.id]);

    // if (isExpandedList.indexOf(data.id) > -1) {
    //   // remove if it exists
    //   setIsExpandedList(isExpandedList.filter((id) => id !== data.id));
    // } else {
    //   // add if it doesn't exist
    //   setIsExpandedList([...isExpandedList, data.id]);
    // }
  };

  const handleAdd = () => {
    // const newData = {
    //   caption: "New Link",

    //   id: Math.random()
    //     .toString(36)
    //     .slice(2),
    // };

    const newData = {
      ...linkBuilderSettings.default.data[0],
      id: Math.random()
        .toString(36)
        .slice(2),
      caption: "Link",
      preset: "Transparent"
    };

    handleChange([...data, newData]);
    handleEdit(newData);
  };

  const ItemComponent: React.FC<ItemComponentProps> = (incAttr) => {
    const {
      id,
      caption,
      onMouseEnter,
      onMouseLeave,
      isHovering,
      icon,
    } = incAttr;

    const [attributes, setAttributes] = React.useState<LinkBuilderAttributes>({
      linkDisplayType: LinkDisplayTypes.TEXT,
      label: caption,
      ...incAttr,
    });

    let icn = IconTypes.TextFields;
    switch (attributes.linkDisplayType) {
      case LinkDisplayTypes.TEXT:
        icn = IconTypes.TextFields;
        break;
      case LinkDisplayTypes.ICON:
        icn = icon || IconTypes.EmojiEmotions;
        break;
      case LinkDisplayTypes.BUTTON:
        icn = IconTypes.Crop75;
        break;
    }

    const isExpanded = Boolean(isExpandedList.indexOf(id) > -1);

    const attributeContainerData = [
      ...(attributes.linkDisplayType !== LinkDisplayTypes.ICON
        ? [
          {
            id: "label",
            attribute: AttributeNames.LABEL,
            settings: {
              label: "Link Text",
            },
          },
        ]
        : []),
      ...(attributes.linkDisplayType === LinkDisplayTypes.ICON
        ? [
          {
            id: "icons",
            attribute: AttributeNames.ICON,
            settings: {},
          },
        ]
        : []),
      {
        id: "links",
        attribute: AttributeNames.LINK,
        settings: {
          label: "Link Destination",
          links,
        },
      },
      ...(attributes.linkDisplayType === LinkDisplayTypes.BUTTON
        ? [
          {
            id: "preset",
            attribute: AttributeNames.PRESET,
            settings: {
              label: "Button Style",
              presets: map(keys(ButtonPresets), (preset) => ({
                label: ButtonPresets[preset],
                type: ButtonPresets[preset],
                image: theme.logoIcon,
              })),
              // xpresets: [
              //   { label: "Testing Content!", type: "content", image: theme.logoIcon },
              //   { label: "Testing Launch", type: "launch", image: theme.logoIcon },
              // ]
            },
          },
        ]
        : []),
    ];

    // const handleSingleFieldBlur = (
    //   caption: string,
    //   icon: boolean | IconTypes = false
    // ): void => {
    const handleSingleFieldBlur = (newAttributes) => {
      const key = data.findIndex((itm) => itm.id === id);
      const newData = [
        ...data.slice(0, key),
        { ...data[key], caption: newAttributes.label, ...newAttributes },
        ...data.slice(key + 1),
      ];
      console.log({ newData });
      onChange(newData);
    };

    return (
      <DefaultItemComponent
        caption={caption}
        icon={icn}
        placeholder="Choose a destination..."
        type={ItemComponentTypes.TEXT}
        onAdd={handleAdd}
        // onBlur={handleSingleFieldBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isExpanded={isExpanded}
        isHovering={isHovering}
        toggleExpansion={() => handleEdit({ id })}
      >
        {/* <ReactJson src={attributes} collapsed /> */}
        <div style={{ position: "relative", top: 0, padding: 12, marginBottom: 20, backgroundColor: "#F5F5F5", borderRadius: 3 }}>
          <fieldset style={{ ...fieldset, backgroundColor: 'white' }}>
            <legend>Link Type</legend>
            <RadioGroup
              aria-label="linkDisplay"
              name="linkDisplay"
              value={attributes.linkDisplayType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newAttributes = {
                  ...attributes,
                  linkDisplayType: e.target.value as LinkDisplayTypes,
                };
                setAttributes(newAttributes);
                handleSingleFieldBlur(newAttributes);
              }}
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value={LinkDisplayTypes.TEXT}
                  control={<Radio />}
                  label="Text Link"
                />
                <FormControlLabel
                  value={LinkDisplayTypes.ICON}
                  control={<Radio />}
                  label="Icon"
                />
                <FormControlLabel
                  value={LinkDisplayTypes.BUTTON}
                  control={<Radio />}
                  label="Button"
                />
              </div>
            </RadioGroup>
          </fieldset>
          <fieldset style={{ ...fieldset, backgroundColor: 'white', paddingBottom: 20 }}>
            <legend>
              <div style={{ display: "flex" }}>
                <Icon type={icn} />
                <div style={{ padding: "3px 8px" }}>
                  {attributes.linkDisplayType} Link Settings
                </div>
              </div>
            </legend>
            {/* {JSON.stringify(attributeContainerData)} */}
            <AttributeContainer
              attributes={attributes}
              data={attributeContainerData}
              onChange={(attr) => {
                console.log({ attr });
                setAttributes(attr);
              }}
              onBlur={(data) => {
                console.log({ data });
                handleSingleFieldBlur({ ...data, caption: data.label });
              }}
              hideFooter
            />
          </fieldset>
        </div>
      </DefaultItemComponent>
    );
  };

  return (
    <div>
      <SortableList
        data={data}
        itemStyle={{
          border: "1px solid #DDD",
          borderRadius: 3,
          margin: "10px 0",
          height: 45,
        }}
        ItemComponent={ItemComponent}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onChange={handleChange}
        onDelete={(payload, updatedData) => handleChange(updatedData)}
        canDragAndDrop
        showItemInline={false}
        name="Link"
      />
    </div>
  );
};
