import * as React from "react";
import { CustomProps } from "./types";

/**
 * Creates a custom group for displaying a custom section
 */
const Custom: React.FC<CustomProps> = ({ component }) => {
  // const MyComponent = component;
  // return <MyComponent />;
  return component;
};

export default Custom;
