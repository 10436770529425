import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { PricingLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Pricing/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: PricingLayouts.TWO,
  layout: PricingLayouts.TWO,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/pricing/Pricing02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyd2er52ag90780rf8yda8f",
    itemId: "zgfratvhdi",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.PRICING,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          width: 700,
          maxWidth: "100%",
          display: "inline-block",
          textAlign: "center",
          margin: "0 auto",
          mobile: {
            desktop: {
              width: 700,
            },
            smartphone: {
              width: "100%",
            },
          },
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              fontSize: "30pt",
            },
            html: "Pricing",
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: { ...style.TEXT, padding: 20 },
            html:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim.",
          },
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((6 * 100) / 12),
                properties: { height: "100%", padding: 20, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: theme.backgroundColor,
                      padding: 40,
                      mobile: {
                        desktop: {
                          padding: 40,
                        },
                        smartphone: {
                          padding: 0,
                        },
                      },
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          color: theme.foregroundColor,
                        },
                        html: "Basic",
                      },
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          color: theme.foregroundColor,
                          fontWeight: "normal",
                        },
                        html: "$47.00",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          color: theme.foregroundColor,
                        },
                        html: "per month",
                      },
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          ...style.LIST,
                        },
                        children: [
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                              color: theme.foregroundColor,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `Lorem ipsum dolor`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                              color: theme.foregroundColor,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `Sit apet consectetur`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                              color: theme.foregroundColor,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `40 Adipiscing elit`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                              color: theme.foregroundColor,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `25 Sed do eiusmod`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                              color: theme.foregroundColor,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `Tempor incididunt`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: theme.foregroundColor,
                            },
                            caption1: "Purchase",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((6 * 100) / 12),
                properties: { height: "100%", padding: 20, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: "#f5f5f5",
                      padding: 40,
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                        },
                        html: "Professional",
                      },
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          fontWeight: "normal",
                        },
                        html: "$97.00",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html: "per month",
                      },
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          ...style.LIST,
                        },
                        children: [
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `Lorem ipsum dolor`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `Sit apet consectetur`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `40 Adipiscing elit`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `25 Sed do eiusmod`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.LISTITEM,
                            canHaveChildren: false,
                            properties: {
                              ...style.LI.style,
                            },
                            contentStyle: style.LI.contentStyle,
                            html: `Tempor incididunt`,
                            icon: IconTypes.Check,
                          },
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: "transparent",
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Purchase",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
