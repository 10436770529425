
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import "isomorphic-fetch";

// const uri = "http://localhost:4000"; // should be the url of the graphql server
// const uri = "https://47fmc7mbac.execute-api.us-east-1.amazonaws.com"; // production
const uri = "https://api.funnels.cc:40000"; // uncomment this to switch to production

export const client = new ApolloClient({
  link: createUploadLink({ uri }),
  cache: new InMemoryCache()
});
