import { Cell, Grid, TextInput } from "@launchos/components/ui";
import theme from "@launchos/components/ui/theme";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
// import { app } from "../../../config";
import IntegrationEditor from "./IntegrationEditor";
import providers from "./integrationProviders";

const integrationProviders = [...providers];

const style = {
  main: {
    borderRadius: 3,
    margin: "25px 0",
    paddingTop: 7,
    border: "1px solid #CCC",
    backgroundColor: "white",
    textAlign: "center",
  },
};

class IntegrationItem extends Component {
  static contextType = AppConfig;


  UNSAFE_componentWillMount() {
    if (_.has(this.props, "allIntegrationQuery.integrations")) {
      const integration = this.props.allIntegrationQuery.integrations.filter(
        (integration) => integration.type === this.props.provider.id
      );

      this.data = integration.length
        ? integration[0]
        : {
          type: this.props.provider.id,
          setting: false,
          caption: this.props.provider.caption,
        };

      if (this.props.selected) {
        this.props.onSelect(this.data);
      }
    }
  }


  render() {
    const { app } = this.context;
    const HoverSelector = styled.div`
      border: 2px solid white;
      width: 146px;
      padding: 20px;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      border-color: ${(props) =>
        props.selected ? theme.primaryColor : "white"};
      background-color: ${(props) => (props.selected ? "#f2f2f2" : "white")};
      &:hover {
        border-color: ${theme.primaryColor};
      }
    `;

    if (this.props.allIntegrationQuery.loading)
      return (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src={app.loadingImage} alt="Loading..." />
        </div>
      );
    if (this.props.allIntegrationQuery.error)
      return (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src={app.errorImage} alt="Loading..." />
        </div>
      );

    if (this.props.showOnlyRegistered && !_.get(this, "data.setting", false))
      return null;

    return (
      <Cell
        align="baseline"
        key={this.props.provider.id}
        onClick={() =>
          !this.data.setting
            ? this.props.showIntegrationEditor(this.data)
            : this.props.onSelect(this.data)
        }
      >
        <HoverSelector selected={this.props.selected} data={this.data}>
          <div>
            <img
              alt=""
              style={{
                width: 72,
                padding: 5,
              }}
              src={this.props.provider.img}
            />
            <div
              style={{
                fontSize: 12,
              }}
            >
              {this.props.provider.caption}
            </div>
          </div>
        </HoverSelector>
      </Cell>
    );
  }
}

class IntegrationsSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIntegrations: true,
      showIntegrationEditor: false,
      integration: false,
      filterIntegrations: "",
      showOnlyRegistered: props.showOnlyRegistered,
      customProviders: [...providers]
    };
    this.showIntegrationsList = this.showIntegrationsList.bind(this);
    this.hideIntegrationsList = this.hideIntegrationsList.bind(this);
    this.doIntegrationsSearch = this.doIntegrationsSearch.bind(this);
    this.showIntegrationEditor = this.showIntegrationEditor.bind(this);
    this.hideIntegrationEditor = this.hideIntegrationEditor.bind(this);
  }

  componentDidMount() {
    // this.setState(prevState => ({
    //   customProviders: [
    //     ...prevState.customProviders,
    //     {
    //       type: "email",
    //       id: "custom",
    //       caption: "Testing 123",
    //       img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtDJgneZ_dezqakp5msHb0dXVfaZdJ2lztRH1y_e_ZM-WOeFc",
    //       fields: [
    //         {
    //           "name": "apiLoginKey",
    //           "caption": "API Login ID"
    //         },
    //         {
    //           "name": "transactionKey",
    //           "caption": "API Transaction Key"
    //         }
    //       ],
    //       tabs: [
    //         {
    //           "id": "SUCCESS",
    //           "label": "Success!",
    //           "show": true
    //         }
    //       ]
    //     }]
    // }))
  }

  showIntegrationsList() {
    this.setState({ showIntegrations: true });
  }

  hideIntegrationsList() {
    this.setState({ showIntegrations: false });
  }

  doIntegrationsSearch(e) {
    console.log("doing integration search");
    this.setState({ filterIntegrations: e.target.value });
  }

  showIntegrationEditor(integration) {
    this.setState({ showIntegrationEditor: true, integration });
  }

  hideIntegrationEditor(integration) {
    this.setState({ showIntegrationEditor: false, integration: false });
  }

  render() {
    const integrationProvidersFiltered = this.state.customProviders.filter(
      (itm) => itm.type.indexOf(this.props.filter) > -1
    );
    const integrationProvidersToShow = _.uniqBy(
      integrationProvidersFiltered,
      "id"
    ).filter(
      (provider) =>
        provider.caption
          .toUpperCase()
          .search(this.state.filterIntegrations.toUpperCase()) > -1
    );

    console.log({ integrationProvidersToShow });

    return (
      <div
        style={{
          ...style.main,
          ...this.props.style,
        }}
      >
        {this.state.showIntegrationEditor ? (
          <IntegrationEditor
            {...this.props}
            hideIntegrationEditor={this.hideIntegrationEditor}
            integration={this.state.integration}
            onSave={(data) => this.props.onSelect(data)}
          />
        ) : null}
        <TextInput
          iconLeft="search"
          style={{
            border: "none",
            // marginTop: -10,
            padding: 10
          }}
          transparent
          placeholder={this.props.label}
          value={this.state.filterIntegrations}
          onChange={this.doIntegrationsSearch}
        />
        {this.state.showIntegrations ? (
          <div
            style={{
              borderTop: "1px solid #DDD",
              marginTop: -3,
            }}
          >
            <Grid
              justify="start"
              style={{
                padding: 5,
                maxHeight: 330,
                overflowY: "scroll",
              }}
              wrap
            >
              {integrationProvidersToShow.map((provider) => (
                <IntegrationItem
                  key={provider.id}
                  provider={provider}
                  {...this.props}
                  showIntegrationEditor={this.showIntegrationEditor}
                  hideIntegrationEditor={this.hideIntegrationEditor}
                  showOnlyRegistered={this.state.showOnlyRegistered}
                  selected={
                    provider.id === _.get(this.props, "selected.type", false)
                  }
                />
              ))}
              {this.state.showOnlyRegistered ? (
                <div
                  style={{
                    width: "100%",
                    padding: 15,
                    textAlign: "center",
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState((prevState) => ({
                      showOnlyRegistered: !prevState.showOnlyRegistered,
                    }))
                  }
                >
                  Show All Providers
                </div>
              ) : null}
            </Grid>
          </div>
        ) : null}
      </div>
    );
  }
}

IntegrationsSelector.propTypes = {
  showIntegrations: PropTypes.bool,
  label: PropTypes.string,
};

IntegrationsSelector.defaultProps = {
  showIntegrations: false,
  onSelect: () => false,
  label: "Search for an integration",
  filter: "",
  showOnlyRegistered: false,
  selected: false,
};

export default IntegrationsSelector;
