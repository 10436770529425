import { CSSProperties } from 'react'
import styled from "styled-components";

const style: {
  parent: CSSProperties;
  child: CSSProperties;
  visitorCount: CSSProperties;
  peopleCount: CSSProperties;
  loading: CSSProperties;
  loadingImg: CSSProperties;
} = {
  parent: {
    width: "100%",
    height: "100%",
    fontSize: "42pt",
    fontWeight: "bold",
    display: "table",
    color: 'white',
  },
  child: {
    display: "table-cell",
    verticalAlign: "middle"
  },
  visitorCount: {
    position: 'absolute',
    marginTop: -20,
    fontSize: '11pt',
    left: 20,
    color: `rgba(255,255,255,0.5)`
  },
  peopleCount: {},
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  loadingImg: {
    width: 80,
    height: 80
  }
};

export const CellStyle = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    border-color: 1px solid #666;
    color: black;
  }
`;

export default style