import PropTypes from "prop-types";
import Radium from "radium";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardCaption,
  Cell,
  Grid,
  ListItem,
  MenuButton,
  TextInput,
  Thumbnail
} from "@launchos/components/ui";
import style from "./style";

/**
 * This component displays a thumbnail image of campaign (or page)
 * @param {object} props The props
 * @returns {function} The component
 */

class CampaignItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      name: props.name,
      isHovering: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCampaignUpdateName = this.handleCampaignUpdateName.bind(this);
  }

  // enable editing
  handleClick() {
    this.setState({ edit: true });
  }

  // handleBlur
  handleBlur() {
    this.setState(
      {
        edit: false
      },
      () => {
        this.handleCampaignUpdateName(this.state.name);
      }
    );
  }

  async handleCampaignUpdateName(name) {
    const campaignId = this.props.id;

    const { updateCampaignName } = this.props.queryProps;
    const variables = { campaignId, name };

    this.setState({ edit: false });
    await updateCampaignName({ variables });
  }

  // handle enter key press
  handleKeyPress(e) {
    if (e.key === "Enter") this.handleCampaignUpdateName(e.target.value);
  }

  // on state change.
  handleChange(e) {
    const val = e.target.value;
    this.setState({
      name: val
    });
  }

  render() {
    return (
      // <Card>
      <div
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        <Card
          style={{ ...style.campaignItem, ...this.state.isHovering ? style.campaignItemHovering : {} }}
        >
          <Link to={`/campaign/${this.props.id}/edit`}>
            <Thumbnail
              style={{ padding: "40px 90px" }}
              src={this.props.thumbnail}
            />
          </Link>
        </Card>
        {/* <CardCaption style={style.campaignItemCaption}> */}
        <Grid justify="space-between" style={{ width: "100%" }}>
          {this.state.edit === true ? (
            <Cell>
              <TextInput
                style={{
                  padding: 4,
                  border: "none",
                  margin: 0,
                  marginTop: 10,
                  marginLeft: -2
                }}
                selectOnMount
                type="text"
                value={this.state.name}
                onKeyPress={this.handleKeyPress}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
              />
            </Cell>
          ) : (
            <Cell align="center" onClick={this.handleClick}>
              {this.state.name}
            </Cell>
          )}
          <Cell>
            <MenuButton
              id="CampaignItem"
              icon="more_vert"
              style={style.campaignItemCaptionButton}
            >

              <Link to={`/campaign/${this.props.id}/edit`}>
                <ListItem>Edit This Campaign</ListItem>
              </Link>

              <ListItem onClick={this.handleClick}>
                Rename This Campaign
              </ListItem>

              <div style={style.divider} />

              <ListItem onClick={async () => {
                const { cloneCampaign, id } = this.props;
                await cloneCampaign(id, localStorage.userId);
                window.location.reload();
              }}>
                Make a Copy
              </ListItem>

              <ListItem onClick={() => this.props.getCampaignShareLink(this.props.campaign)}>
                Share This Campaign
              </ListItem>

              <div style={style.divider} />

              <ListItem
                onClick={() => this.props.onDeleteCampaignItem(this.props)}
              >
                Delete This Campaign
              </ListItem>

            </MenuButton>
          </Cell>
        </Grid>
        {/* </CardCaption> */}
        {/* </Card> */}
      </div>
    );
  }
}

CampaignItem.propTypes = {
  /** The id of the campaign (or page) */
  id: PropTypes.string.isRequired,
  /** The name to display for the campaign (or page) */
  name: PropTypes.string.isRequired
};

export default Radium(CampaignItem);
