import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    paddingTop: 50,
    paddingBottom: 0,
    paddingLeft: 50,
    paddingRight: 50,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: 200,
    //    fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "center",
    padding: 20,
    fontFamily: theme.fontFamily,
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
    padding: "100px 20px",
    backgroundColor: theme.backgroundColor,
    position: "relative",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 10,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: 150,
      margin: "0 auto",
      borderRadius: 3,
      cursor: "pointer",
      marginTop: 50,
      display: "inline-block",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
