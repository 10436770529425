import theme from "../../theme";

export const style = {
  SECTION: {
    // backgroundColor: theme.backgroundColor,
    backgroundImage: `url(https://images.unsplash.com/photo-1591708441374-07a209f5cac8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1363&q=80)`,
    backgroundColor: `rgb(0,0,0,0.75)`,
  },
  SECTION_INNER: {
    margin: "0 auto",
  },
  CONTAINER: {
    mobile: {
      desktop: {
        padding: 90,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 40,
    color: theme.foregroundColor,
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 10,
      background: theme.foregroundColor,
      color: theme.backgroundColor,
      // border: `1px solid ${theme.backgroundColor}`,
      border: "none",
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "100%",
      padding: 14,
      backgroundColor: "transparent",
      border: `1px solid ${theme.foregroundColor}`,
      borderRadius: 5,
      color: theme.foregroundColor,
    },
  },
};
