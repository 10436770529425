import * as React from "react";
import { get, head } from "lodash";

import {
  SortableList,
  DefaultItemComponent,
} from "@launchos/modules/v2/CRUD/SortableList";
import theme from "@launchos/components/ui/theme";

import Icon, { IconTypes } from "../../Icon";

import { ListItemType, ListBuilderProps } from "../types";
import style from "./style";
import { AttributeContainer } from "@launchos/modules/v2/CRUD/FormBuilder";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";
import { LiveFormFieldProps } from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField/types";
import { ItemComponentTypes } from "@launchos/modules/v2/CRUD/SortableList/types";

interface ItemComponentProps extends ListItemType {
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isHovering?: boolean;
}

export const ListBuilder: React.FC<ListBuilderProps> = ({
  data = [],
  onChange,
  icon,
}) => {
  const [isExpandedList, setIsExpandedList] = React.useState<string[]>([]);

  const handleChange = (mutatedPayload: ListItemType[]): void => {
    console.log({ mutatedPayload });
    onChange(mutatedPayload);
  };

  const handleEdit = (data: ListItemType): void => {
    // toggle expansion
    if (data.id === head(isExpandedList)) setIsExpandedList([])
    else setIsExpandedList([data.id]);

    // if (isExpandedList.indexOf(data.id) > -1) {
    //   // remove if it exists
    //   setIsExpandedList(isExpandedList.filter((id) => id !== data.id));
    // } else {
    //   // add if it doesn't exist
    //   setIsExpandedList([...isExpandedList, data.id]);
    // }
  };

  const handleAdd = () => {
    handleChange([
      ...data,
      {
        ...data[data.length - 1],
        id: Math.random().toString(36).slice(2),
      },
    ]);
  };

  const ItemComponent: React.FC<ItemComponentProps> = (itm) => {
    const { id, caption } = itm;
    const icn = get(itm, "icon", icon);

    const [attributes, setAttributes] = React.useState<LiveFormFieldProps>({
      icon: icn,
      description: caption,
    });

    const isExpanded = Boolean(isExpandedList.indexOf(id) > -1);

    const handleSingleFieldBlur = (
      caption: string,
      icon: boolean | IconTypes = false
    ): void => {
      const key = data.findIndex((itm) => itm.id === id);
      const newData = [
        ...data.slice(0, key),
        { ...data[key], caption, icon },
        ...data.slice(key + 1),
      ];
      // console.log({ newData });
      onChange(newData);
    };

    return (
      <DefaultItemComponent
        {...itm}
        icon={icn}
        placeholder="Enter new bullet point"
        type={ItemComponentTypes.TEXT}
        onAdd={handleAdd}
        onBlur={handleSingleFieldBlur}
        isExpanded={isExpanded}
      >
        <div style={{ position: "relative", top: 0, padding: 12, marginBottom: 20, backgroundColor: "#F5F5F5", borderRadius: 3 }}>

          <AttributeContainer
            attributes={attributes}
            data={[
              {
                id: "4",
                attribute: AttributeNames.DESCRIPTION,
                settings: { label: "Caption" },
              },
              { id: "1", attribute: AttributeNames.ICON, settings: { isExpanded: false } },
            ]}
            onChange={(attr) => {
              // console.log({ attr });
              setAttributes(attr);
            }}
            onBlur={(data) => {
              handleSingleFieldBlur(data.description, data.icon);
              // console.log({ data });
            }}
            hideFooter
          />
        </div>
      </DefaultItemComponent>
    );
  };

  return (
    <div>
      <SortableList
        data={data}
        itemStyle={{
          border: "1px solid #DDD",
          borderRadius: 3,
          margin: "10px 0",
          height: 45,
        }}
        ItemComponent={ItemComponent}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onChange={handleChange}
        onDelete={(payload, updatedData) => { if (updatedData.length) handleChange(updatedData) }}
        canDragAndDrop
        showItemInline={false}
        name="Bullet Point"
      />
    </div>
  );
};
