import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: ContentLayouts.NINE,
  layout: ContentLayouts.NINE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/content/Content09.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyb60t929qm07804oando29",
    itemId: "x1f4ws3ut9m",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CONTENT,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: false,
        properties: {
          maxWidth: 600,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.ITEM_HEADLINE,
            },
            html: "Topical Headline",
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
            },
            html: `It's true. Getting results is much easier than the relationship betweeen the links it contains so<br /><br />Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.ITEM_HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: "Secondary Point",
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
            },
            html: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.`,
          },
        ],
      },
    ],
  },
};

export default settings;
