import _ from "lodash";
import React, { Component } from "react";
import {
  Dialog,
  DialogBody,
  Grid,
  Cell,
  Button,
  TextInput
  // FontIcon
} from "@launchos/components/ui";
// import theme from "@launchos/components/ui/theme";

// import registrars from "@launchos/modules/settings/DomainManagement/registrars";

// class Tutorial extends Component {
//   state = {
//     step: 1
//   };
//   render() {
//     const myRegistrar = "GoDaddy";
//     const { step } = this.state;

//     return (
//       <Grid style={{ width: "100%" }}>
//         <Cell
//           style={{
//             // padding: "20px 30px 20px 0",
//             display: "inline-block",
//             width: 300,
//             maxHeight: 250,
//             overflowY: "hidden"
//           }}
//         >
//           <a
//             href={registrars[myRegistrar].steps[step].image}
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               style={{ width: "100%" }}
//               alt=""
//               src={registrars[myRegistrar].steps[step].image}
//             />
//           </a>
//         </Cell>
//         <Cell
//           style={{
//             textAlign: "left",
//             padding: "0 30px",
//             width: 300,
//             display: "inline-block"
//           }}
//         >
//           {/* <div>
//             <img
//               style={{ width: 150 }}
//               alt=""
//               src={registrars[myRegistrar].logo}
//             />
//           </div> */}
//           <div>
//             <div
//               style={{ display: "inline-block", marginTop: -5, float: "right" }}
//             >
//               <p style={{ marginTop: 10, textAlign: "center", zoom: 1.2 }}>
//                 <FontIcon
//                   style={{ cursor: "pointer" }}
//                   onClick={() =>
//                     this.setState(prevState => ({
//                       step: prevState.step
//                         ? parseInt(prevState.step, 10) - 1
//                         : 0
//                     }))
//                   }
//                 >
//                   arrow_left
//                 </FontIcon>
//                 <span
//                   style={{
//                     display: "inline-block",
//                     padding: 10,
//                     top: -7,
//                     position: "relative"
//                   }}
//                 >
//                   Step {step + 1} of {registrars[myRegistrar].steps.length}
//                 </span>
//                 <FontIcon
//                   style={{ cursor: "pointer" }}
//                   onClick={() =>
//                     this.setState(prevState => ({
//                       step:
//                         prevState.step <
//                         registrars[myRegistrar].steps.length - 1
//                           ? parseInt(prevState.step, 10) + 1
//                           : prevState.step
//                     }))
//                   }
//                 >
//                   arrow_right
//                 </FontIcon>
//               </p>
//             </div>
//             <div>
//               <span
//                 style={{
//                   padding: 5,
//                   backgroundColor: theme.primaryColor,
//                   color: "white",
//                   fontSize: 10,
//                   fontWeight: 600
//                 }}
//               >
//                 STEP {step + 1}
//               </span>
//               <div
//                 style={{
//                   display: "inline-block",
//                   marginRight: 10,
//                   marginTop: 5,
//                   width: 0,
//                   height: 0,
//                   borderTop: "10px solid transparent",
//                   borderBottom: "10px solid transparent",
//                   borderLeft: `10px solid ${theme.primaryColor}`,
//                   position: "relative",
//                   top: 6
//                 }}
//               />
//             </div>
//             <p
//               style={{
//                 fontWeight: 600,
//                 display: "block",
//                 marginTop: 15,
//                 clear: "both"
//               }}
//             >
//               {registrars[myRegistrar].steps[step].title}
//             </p>
//           </div>
//           <p
//             style={{ marginTop: 10 }}
//             dangerouslySetInnerHTML={{
//               __html: registrars[myRegistrar].steps[step].description
//             }}
//           />
//         </Cell>
//       </Grid>
//     );
//   }
// }

export default class ShoppingCartPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: _.get(props, "options.settings.url", "")
    };

    this.saveSettings = this.saveSettings.bind(this);
  }

  async saveSettings() {
    const { options, actions } = this.props;
    const { url } = this.state;
    const { updatePageSettings, updateObjectSettings } = actions;

    const pageId = _.get(options, "page.id");

    const pageScript = `
      <script type="text/javascript">
        window.location.href = "${url}";
      </script>
    `;

    await updateObjectSettings({
      id: options.id,
      settings: { url }
    });

    await updatePageSettings({
      pageId,
      pageSettings: {
        tracking: {
          head: pageScript
        }
      }
    });

    window.location.reload();
    // onClose();
  }

  render() {
    const { options, onClose } = this.props;
    return (
      <Dialog {...this.props} style={{ width: 700 }} centered>
        <DialogBody>
          <div style={{ padding: 30 }}>
            <img
              src={options.screenshot}
              alt=""
              style={{ width: 150, marginBottom: 20 }}
            />
            <h2>Enter the URL to your Order Form below...</h2>
            <TextInput
              style={{ textAlign: "center", marginTop: 30 }}
              placeholder={`e.g. http://www.YourShoppingCart.com/myOrderForm`}
              onChange={e => this.setState({ url: e.target.value })}
              value={this.state.url}
            />
          </div>

          <Grid justify="space-evenly" style={{ width: 250, margin: "0 auto" }}>
            <Cell align="center">
              <Button onClick={this.saveSettings} large tertiary>
                OK
              </Button>
            </Cell>
            <Cell align="center">
              <Button
                onClick={onClose}
                large
                transparent
                style={{ color: "#333" }}
              >
                Cancel
              </Button>
            </Cell>
          </Grid>

          {/* <div style={{ borderTop: "1px dashed #DDD", margin: "40px 0" }} />

          <div style={{ padding: 30, marginTop: 30, border: "2px solid #EEE" }}>
            <p style={{ paddingBottom: 20 }}>
              To route potential buyers to your shopping cart, please follow
              these instructions...
            </p>
            <Tutorial options={options} />
          </div> */}
        </DialogBody>
      </Dialog>
    );
  }
}
