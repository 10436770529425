import * as React from "react";
import { get } from "lodash";
import { PropertiesProps } from "../types";

import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

import bulletBuilderSettings from "../settings";

import { ListBuilder } from "./ListBuilder";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { IconTypes } from "../../Icon";

/**
 * The property window that shows for the FormBuilderEditorObject
 */
const BulletBuilderPropertyWindow: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);

  const handleSettingsChange = (id, settings, dbUpdate = true) => {
    const { updateComponentSettings } = props;
    setSettings(settings);
    if (dbUpdate) updateComponentSettings(id, settings); //, true, false);
  };

  const { properties } = bulletBuilderSettings;
  const { sections } = properties.main;

  const withListBuilder = updateSection(sections, "BulletBuilderSettings", {
    component: (
      <ListBuilder
        {...settings}
        onChange={(data) =>
          handleSettingsChange(settings.id, { ...props.settings, data })
        }
      />
    ),
  });

  const withIconSelector = updateSection(
    withListBuilder,
    SectionTypes.ICONSELECTOR,
    {
      onChange: (e) =>
        handleSettingsChange(settings.id, { ...props.settings, icon: IconTypes[e] }),
    }
  );

  const withSize = updateSection(withIconSelector, "customIconSize", {
    onChange: (e) => {
      switch (e) {
        case "GROW": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "1pt").replace("pt")
            ) + 5;

          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: { ...get(settings, 'iconStyle', {}), fontSize: `${value}pt` },
            },
            // true,
            // 0
          );
          break;
        }
        case "SHRINK": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "6pt").replace("pt")
            ) - 5;

          // const value = convertFromRem(get(settings, "properties.fontSize"))

          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: { ...get(settings, 'iconStyle', {}), fontSize: `${value}pt` },
            },
            // true,
            // 0
          );
          break;
        }
        default: {
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: { ...get(settings, 'iconStyle', {}), fontSize: `${e}pt` },
            },
            // true,
            // 0
          );
        }
      }
    },
  });

  const withPosition = updateSection(withSize, "customIconPosition", {
    // value: get(settings, "properties.fontSize", "1pt").replace("pt", ""),
    onChange: (e) => {
      console.log("size", e);
      switch (e) {
        case "GROW": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "1pt").replace("pt")
            ) + 5;
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: { ...get(settings, 'iconStyle', {}), marginTop: `${value}pt` },
            },
            // true,
            // false
          );
          break;
        }
        case "SHRINK": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "6pt").replace("pt")
            ) - 5;
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: { ...get(settings, 'iconStyle', {}), marginTop: `${value}pt` },
            },
            // true,
            // false
          );
          break;
        }
        default: {
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: { ...get(settings, 'iconStyle', {}), marginTop: `${e}pt` },
            },
            // false,
            // false
          );
        }
      }
    },
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withPosition },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export default React.memo(BulletBuilderPropertyWindow);
