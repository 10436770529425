import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import * as Tools from "@launchos/modules/editor/Builder/Properties";
import React, { Component } from "react";
import style from "./style";

const ButtonContentEdit = (props) => (
  <button
    href={props.settings.href || "#"}
    style={{
      cursor: /*props.show === "preview" ? "pointer" : */ "grab",
      ...props.settings.properties,
      marginTop: 0,
      textAlign: "center",
    }}
  >
    <div
      style={{ display: "inline", color: props.settings.properties.color, textAlign: "center" }}
      dangerouslySetInnerHTML={{
        __html: props.settings.html || "Subscribe Now",
      }}
    />
    {props.settings.secondline ? props.settings.secondline : null}
  </button>
);

const ButtonForCanvas = (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";
  return (
    <div style={{ marginTop, textAlign }}>
      <EditorObject {...props} PropertiesView={Properties}>
        <ButtonContentEdit {...props} />
      </EditorObject>
    </div>
  );
};

class BasicPropertiesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonContent: this.props.settings.html,
    };
    this.handleChange = this.handleChange.bind(this);
    this.doChange = this.doChange.bind(this);
  }

  handleChange(e) {
    this.setState(
      {
        buttonContent: e.target.value,
      },
      () => {
        // this.props.updateComponentSettings(
        //   this.props.settings.id,
        //   {
        //     ...this.props.settings,
        //     html: this.state.buttonContent,
        //   },
        //   false
        // );
      }
    );
  }

  doChange() {
    this.props.updateComponentSettings(
      this.props.settings.id,
      {
        ...this.props.settings,
        html: this.state.buttonContent,
      },
      true
    );
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Tools.Section label="Button Text" icon="text_fields" isExpanded>
          <fieldset style={style.fieldset} title="Button Content">
            <legend>Button Content</legend>
            <input
              placeholder="Type Button Text"
              value={this.state.buttonContent}
              ref={(el) => (this.input = el)}
              onMouseDown={() => this.props.setCanDrag(false)}
              onMouseUp={() => this.props.setCanDrag(true)}
              onChange={this.handleChange}
              onBlur={() => {
                this.props.setCanDrag(true);
                this.doChange();
              }}
              onKeyUp={e => {
                e.stopPropagation();
                if (e.keyCode === 13) this.doChange();
                if (e.keyCode === 46 || e.keyCode === 8) return false;
              }}
              style={{
                padding: 10,
                width: "100%",
                borderRadius: 5,
                border: "1px solid #CCC",
                margin: "5px 0",
                fontSize: 14,
              }}
            />
          </fieldset>
          <Tools.Typography {...this.props} isExpanded hideLabel />
        </Tools.Section>
        <Tools.Section
          label="Button Color"
          {...this.props}
          icon="format_color_fill"
        >
          <Tools.ColorPicker
            {...this.props}
            color={this.props.settings.properties.backgroundColor}
            onChange={(color, dbUpdate = true, debounceTimeout = 0) => {
              this.props.updateComponentStyle(
                this.props.settings.id,
                {
                  backgroundColor: color.hex,
                },
                dbUpdate,
                debounceTimeout
              );
            }}
          />
        </Tools.Section>
        <Tools.Interactions
          label="Button Actions"
          {...this.props}
          onUpdate={(action) => console.log(action)}
        >
          <div />
        </Tools.Interactions>
        <Tools.WidthHeight label="Button Size" {...this.props} />
        <Tools.PositionPadding {...this.props} />
        <Tools.Alignment verticalAlign={false} textAlign {...this.props} />
      </div>
    );
  }
}

const AdvancedPropertiesTab = (props) => (
  <div style={{ width: "100%" }}>
    <Tools.BordersShadow {...props} isExpanded />
  </div>
);

const Properties = (props) => (
  <Tools.PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[
      { id: "basic", title: "Button", component: BasicPropertiesTab },
      { id: "advanced", title: "Advanced", component: AdvancedPropertiesTab },
    ]}
  />
);

export default ButtonForCanvas;
