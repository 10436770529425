import React from "react";
import { Switch } from "@launchos/components/ui";
import style from "./style";

export const AutoCheck: React.FC<{
  autoCheckValue: number;
  setAutoCheckValue: React.Dispatch<React.SetStateAction<number | boolean>>;
  curAutoCheckValue: number | boolean;
  doFinalChange: (e: any, value: any) => void;
  originalValue: number;
}> = ({
  autoCheckValue,
  setAutoCheckValue,
  curAutoCheckValue,
  doFinalChange,
  originalValue,
}) => (
    <>
      <Switch
        data-testid="ButtonSliderGroup-autoCheck"
        checked={Boolean(autoCheckValue)}
        onClick={() => {
          setAutoCheckValue(!curAutoCheckValue);
          doFinalChange(false, curAutoCheckValue ? originalValue : false);
        }}
      />
      <span style={style.switchLabel}>Determine Automatically</span>
    </>
  );
