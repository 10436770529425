import * as React from "react";
import TextField from "@material-ui/core/TextField";

import { PlaceholderProps } from "../types";
import styles from "@launchos/modules/v2/CRUD/FormBuilder/styles";

/**
 * A component for collecting the placeholder value to use for the form field
 */
const Placeholder: React.FC<PlaceholderProps> = ({
  onChange = () => null,
  onBlur = () => null,
  value,
  label,
}) => {
  let inputRef = React.useRef(null);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 50);
  // }, []);
  return (
    <>
      <TextField
        data-testid="FormBuilder-Attributes-Placeholder"
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onBlur(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onKeyUp={e => e.which === 13 && onBlur(e.target.value)}
        defaultValue={value}
        value={value}
        variant="filled"
        style={styles.input}
        label={label || "Placeholder Text"}
        inputRef={inputRef}
      />
    </>
  );
};
export default Placeholder;
