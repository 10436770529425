import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 20,
      },
      smartphone: {
        padding: 5,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "18pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  COUNTDOWN: {
    style: {
      margin: "0 auto",
      display: "inline-block",
    },
    dimensionStyle: {
      minWidth: 75,
    },
    numberStyle: {
      textAlign: "center",
      fontSize: "26pt",
      fontFamily: theme.fontFamily,
      fontWeight: "bold" as "bold",
      padding: 10,
      backgroundColor: theme.backgroundColor,
      color: theme.foregroundColor,
      margin: 5,
    },
    labelStyle: {
      fontWeight: "bold" as "bold",
      fontFamily: theme.fontFamily,
    },
    dividerStyle: {
      padding: 10,
      fontWeight: "bold" as "bold",
      fontFamily: theme.fontFamily,
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      // minWidth: 125,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
      // margin: "30px auto"
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
