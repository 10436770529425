"use strict";
exports.__esModule = true;
var TextInput_1 = require("./TextInput");
exports.TextInput = TextInput_1["default"];
var Switch_1 = require("./Switch");
exports.Switch = Switch_1["default"];
var Slider_1 = require("./Slider");
exports.Slider = Slider_1["default"];
var CheckBox_1 = require("./CheckBox");
exports.CheckBox = CheckBox_1["default"];
var Radio_1 = require("./Radio");
exports.Radio = Radio_1["default"];
exports["default"] = TextInput_1["default"];
