import React from 'react'
import { Link } from "react-router-dom";
import { AppConfig } from "@launchos/modules/app";

import { GoToUrl } from './GoToUrl'
import style from "../../../style";

export const SubmitForm = (props) => {
    const { action, updateAction, match, emailPayload } = props;
    const { campaignId, nodeId } = match.params;

    return (
        <AppConfig.Consumer>
            {({ app }) => (
                <>
                    <GoToUrl action={action} updateAction={updateAction} {...props} />
                    <Link to={`/campaign/${campaignId}/${nodeId}/email/${app.showProviderStep && !_.has(emailPayload, "campaignId") ? "provider" : "campaign"}`}>
                        <span style={style.linkStyle}>Open Campaign Settings</span>
                    </Link>
                </>
            )}
        </AppConfig.Consumer>
    )
}