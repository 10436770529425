import { IComponentSettings } from "../../types";

const settings: IComponentSettings = {
  id: "headline",
  name: "headline",
  thumbnail: "comp_headline.png",
  default: {
    type: "Headline",
    html: `<p><span style="font-size: 2.5rem;">Replace this text with yours</span></p>`,
    properties: {
      display: "inline-block",
      padding: 5,
      // fontSize: 40,
      textAlign: "left",
    },
  },
};

export default settings;
