import * as React from "react";

import { TextInput } from "@launchos/components/ui";

import { NumberValueProps } from "./types";

export const NumberValue: React.FC<NumberValueProps> = ({
  value = 0,
  onChange = () => null,
}) => {

  return (
    <div data-testid="NumberValue-LIVE">
      {/* <input type="number" value={value} onChange={e => onChange(e.target.value)} /> */}

      <TextInput
        type="number"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};

export default NumberValue;
