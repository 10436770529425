import React from "react";
import { Button } from "@launchos/components/ui";

export const UndoRedo: React.FC<{
  undoPosition: Number;
  undoStackSize: Number;
  doUndoRedo: (type: string) => void;
  canUndo?: boolean;
}> = ({ undoPosition = 0, undoStackSize = 0, doUndoRedo = () => null, canUndo = true }) => {
  return (
    <>
      {canUndo && undoPosition ? (
        <Button
          style={{ color: "inherit" }}
          icon
          onClick={() => doUndoRedo("undo")}
        >
          undo
        </Button>
      ) : null}

      {canUndo && (undoPosition !== undoStackSize) ? (
        <Button
          style={{ color: "inherit" }}
          icon
          onClick={() => doUndoRedo("redo")}
        >
          redo
        </Button>
      ) : null}
    </>
  );
};
