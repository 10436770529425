import { client } from "@launchos/api/gql";
import { getProviderCampaigns } from "@launchos/api/gql/campaigns";
import { Button, Cell, DropDownSelector, Grid } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { app } from "../../../config";
import providers from "../../settings/IntegrationManagement/integrationProviders";
import style from "./style";

export default class ChooseCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      choice: false,
      updateProviders: false,
    };

    this.saveListSelection = this.saveListSelection.bind(this);
    this.loadCampaignChoices = this.loadCampaignChoices.bind(this);
  }

  static contextType = AppConfig;

  async loadCampaignChoices(nextProps) {
    const { app } = this.context;
    const { history } = nextProps;

    const providerType = _.get(
      nextProps,
      "providerSettings.payload.type",
      app.defaultProvider
    );

    console.log({ providerType, nextProps });
    this.providerInfo = _.filter(providers, (o) => o.id === providerType)[0];

    const variables = {
      provider: providerType,
      // providerCredentials: {},
      payload: nextProps.providerSettings.payload,
    };

    console.log({ variables });
    // console.log("getProviderCampaigns", getProviderCampaigns);

    if (Object.keys(_.omit(variables.payload, ["type"])).length) {
      // get all campaigns
      const { data } = await client.query({
        query: getProviderCampaigns,
        variables,
      });

      console.log({ data });

      const list = _.get(data, "getProviderCampaigns.response.campaigns", []);

      if (!list.length) {
        // alert(message);
        history.push("./provider");
      } else {
        this.setState({
          list: _.map(list, (itm) => ({
            id: itm.campaign_id,
            name: itm.campaign_name,
          })),
        });
      }
    }
  }

  componentDidMount() {
    const choice = _.get(
      this.props,
      "providerSettings.payload.campaignId",
      false
    );

    this.setState({ choice }, () => this.loadCampaignChoices(this.props));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { app } = this.context;
    const choice = _.get(
      nextProps,
      "providerSettings.payload.campaignId",
      false
    );
    const payload = _.get(nextProps, "providerSettings.payload", false);
    const providerSettings = Object.keys(_.omit(payload, ["type"]));

    console.log({ providerSettings });

    if (providerSettings.length && !this.state.list.length) {
      const providerType = _.get(
        nextProps,
        "providerSettings.payload.type",
        app.defaultProvider
      );
      this.providerInfo = _.filter(providers, (o) => o.id === providerType)[0];

      this.setState({ choice }, () => {
        this.loadCampaignChoices(nextProps);
      });
    }
  }

  async saveListSelection() {
    const { app } = this.context;
    const {
      updateSettings,
      getProviderCampaign,
      providerSettings,
      history,
      nextHref,
    } = this.props;
    const { choice } = this.state;

    // Save to State grab the entire campaign payload
    const providerType = _.get(
      providerSettings,
      "payload.type",
      app.defaultProvider
    );
    const payload = providerSettings.payload;

    const variables = {
      provider: providerType,
      providerCredentials: {},
      payload: {
        ...payload,
        campaignId: choice,
      },
    };

    console.log({ variables });

    const campaign =
      app.defaultProvider === "limelight"
        ? await getProviderCampaign({ variables })
        : false;
    console.log({ campaign }, { variables });

    await updateSettings({
      ...payload,
      campaignId: choice,
      campaign: _.get(campaign, "data.getCampaign.response", false),
    });

    // Show the Next Screen
    history.push(nextHref);
  }

  render() {
    const { app } = this.context;
    if (!this.props.hidden) {
      return (
        <div
          style={{
            width: 550,
            margin: "0 auto",
          }}
        >
          <div style={{ marginTop: -30 }}>
            <img
              alt=""
              src={_.get(
                this,
                "providerInfo.img",
                app.loadingImage
              )}
              style={{ height: 75, margin: 20 }}
            />
            <br />
          </div>
          <h2>{this.props.message}</h2>
          <div
            style={{
              margin: "30px 0",
              ...style.selectField,
            }}
          >
            <DropDownSelector
              text={
                this.state.list.length
                  ? "Click here to choose a campaign..."
                  : "Loading Campaigns..."
              }
              showId={_.get(this.providerInfo, "showId", false)}
              items={_.orderBy(
                this.state.list,
                (itm) => parseInt(itm.id, 10),
                "desc"
              )}
              choice={this.state.choice}
              onChange={(choice) => this.setState({ choice })}
            />
          </div>
          <Grid
            justify="space-evenly"
            style={{
              marginTop: 30,
            }}
          >
            <Cell align="center">
              {this.props.prevHref && !app.defaultProvider ? (
                <Link to={this.props.prevHref}>
                  <Button large transparent style={{ color: "#333" }}>
                    Back
                  </Button>
                </Link>
              ) : null}
              <Button large tertiary onClick={this.saveListSelection}>
                Next
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
