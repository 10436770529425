import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";
import { CTALayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.TWENTY,
  layout: CTALayouts.TWENTY,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA20.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "5ybj023tx1i",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: "Create Your Free Account Now",
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "sub_headline.style", {}),
        },
        html: lorem.generateSentences(1),
      },
      {
        type: ComponentTypes.FORM,
        data: [
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.FIRSTNAME,
            placeholder: "First Name",
            preset: FieldNamePresets.FIRSTNAME,
          },
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.LASTNAME,
            placeholder: "Last Name",
            preset: FieldNamePresets.LASTNAME,
          },
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.EMAIL,
            placeholder: "Email",
            preset: FieldNamePresets.EMAIL,
          },
        ],
        properties: {
          containerStyle: style.FORM_FIELD.style,
          inputStyle: style.FORM_FIELD.inputStyle,
        },
      },
      {
        type: ComponentTypes.BUTTON,
        canHaveChildren: false,
        properties: style.BUTTON.style,
        caption1: "Get Instant Access",
        caption1style: style.BUTTON.caption1,
      },
    ],
  },
};

export default settings;
