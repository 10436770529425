import { has } from 'lodash'
import config from '@launchos/10mf/src/config'

import { ListItemsHooks } from "@launchos/plugins/types";

export const onListItems = (hook, payload, actions) => {
  if (
    hook.id === ListItemsHooks.APP_DRAWER &&
    hook.position === "bottom" &&
    has(config, 'app.helpUrl')
  ) {
    return [
      {
        // link: "http://support.tenminutefunnels.com",
        // link: "https://ambid.zendesk.com/hc/en-us/requests/new",
        link: config.app.helpUrl,
        icon: "help",
        label: "Help & Support"
      }
    ];
  }
};
