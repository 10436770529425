import moment from "moment";
import React from "react";

export const SavingStatus: React.FC<{ savingStatus: string[] }> = ({
  savingStatus = [],
}) => {
  if (!savingStatus.length) return null;

  return (
    <span
      style={{ color: "#AAA", fontSize: 14 }}
      title="Every change you make is automatically saved."
    >
      {savingStatus[0] === "SAVING" && "Saving..."}
      {savingStatus[0] === "SAVED" &&
        `All changes saved (${moment(savingStatus[1]).format("LT")})`}
    </span>
  );
};
