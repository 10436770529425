import { ILayoutSettings } from "@launchos/plugins/misc/v2/blocks/weblayouts/types";

export const tmf_pricing01: ILayoutSettings = {
  label: "Personal Pricing 01",
  layout: "Personal Pricing 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/pricing01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp8j3rr1d3f50780x8r40ddu",
    itemId: "u5j1r64m5rf",
  },
};

export const tmf_nav01: ILayoutSettings = {
  label: "Personal Navigation 01",
  layout: "Personal Navigation 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/nav01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp3yt9kzbtmt0780pt1lgy9l",
    itemId: "y4itvxpzsnr",
  },
};

export const tmf_header03: ILayoutSettings = {
  label: "Personal Header 03",
  layout: "Personal Header 03",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/header03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp7jyid9cwu50780ic9mila9",
    itemId: "u5j1r64m5rf",
  },
};

export const tmf_header02: ILayoutSettings = {
  label: "Personal Header 02",
  layout: "Personal Header 02",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/header02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp74ovamcqph0780r4kok8fc",
    itemId: "cmzz5df0mo",
  },
};

export const tmf_header01: ILayoutSettings = {
  label: "Personal Header 01",
  layout: "Personal Header 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/header01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp3yt9kzbtmt0780pt1lgy9l",
    itemId: "78acgdwa9n3",
  },
};

export const tmf_guarantee01: ILayoutSettings = {
  label: "Personal Guarantee 01",
  layout: "Personal Guarantee 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/guarantee01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp74ovamcqph0780r4kok8fc",
    itemId: "xrozzgkkhzc",
  },
};

export const tmf_footer01: ILayoutSettings = {
  label: "Personal Footer 01",
  layout: "Personal Footer 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/footer01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp3yt9kzbtmt0780pt1lgy9l",
    itemId: "8tb4g3w6qly",
  },
};

export const tmf_cta02: ILayoutSettings = {
  label: "Personal CTA 02",
  layout: "Personal CTA 02",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/cta02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp74ovamcqph0780r4kok8fc",
    itemId: "6thycvovl8t",
  },
};

export const tmf_cta01: ILayoutSettings = {
  label: "Personal CTA 01",
  layout: "Personal CTA 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/cta01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp3yt9kzbtmt0780pt1lgy9l",
    itemId: "pemyoqcu0as",
  },
};

export const tmf_content03: ILayoutSettings = {
  label: "Personal Content 03",
  layout: "Personal Content 03",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/content03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckq2s0l5e2pv00880k4e0nxma",
    itemId: "2b85rlg1tdy",
  },
};

export const tmf_content02: ILayoutSettings = {
  label: "Personal Content 02",
  layout: "Personal Content 02",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/content02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp87gbrocy1x0780dslk5jp3",
    itemId: "rhq3178tnw",
  },
};

export const tmf_content01: ILayoutSettings = {
  label: "Personal Content 01",
  layout: "Personal Content 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/content01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp7jyid9cwu50780ic9mila9",
    itemId: "vuona9hzw9k",
  },
};

export const tmf_benefit04: ILayoutSettings = {
  label: "Personal Benefit 04",
  layout: "Personal Benefit 04",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/benefit04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp74ovamcqph0780r4kok8fc",
    itemId: "mhvqdtpy7zo",
  },
};

export const tmf_benefit03: ILayoutSettings = {
  label: "Personal Benefit 03",
  layout: "Personal Benefit 03",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/benefit03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp74ovamcqph0780r4kok8fc",
    itemId: "mrbgefiyz7c",
  },
};

export const tmf_benefit02: ILayoutSettings = {
  label: "Personal Benefit 02",
  layout: "Personal Benefit 02",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/benefit02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp74ovamcqph0780r4kok8fc",
    itemId: "ysqkblbuge8",
  },
};

export const tmf_benefit01: ILayoutSettings = {
  label: "Personal Benefit 01",
  layout: "Personal Benefit 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/benefit01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckp3yt9kzbtmt0780pt1lgy9l",
    itemId: "q4z5q8lqtvj",
  },
};

export const tmf_benefit05: ILayoutSettings = {
  label: "Personal Benefit 05",
  layout: "Personal Benefit 05",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/benefit05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplqqtwy00jg088057zg476y",
    itemId: "u5j1r64m5rf",
  },
};

export const tmf_benefit06: ILayoutSettings = {
  label: "Personal Benefit 06",
  layout: "Personal Benefit 06",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/benefit06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckq2s0l5e2pv00880k4e0nxma",
    itemId: "u5j1r64m5rf",
  },
};

export const tmf_socialproof01: ILayoutSettings = {
  label: "Personal SocialProof 01",
  layout: "Personal SocialProof 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/socailproof01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplqqtwy00jg088057zg476y",
    itemId: "z58lh0ryfdl",
  },
};

export const tmf_socialproof02: ILayoutSettings = {
  label: "Personal SocialProof 02",
  layout: "Personal SocialProof 02",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/socialproof02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplvivdz03aj0880gga4rnl2",
    itemId: "a9rxkhv9pxb",
  },
};

export const tmf_socialproof03: ILayoutSettings = {
  label: "Personal SocialProof 03",
  layout: "Personal SocialProof 03",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/socialproof03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplvivdz03aj0880gga4rnl2",
    itemId: "slohwk9j94",
  },
};

export const tmf_socialproof04: ILayoutSettings = {
  label: "Personal SocialProof 04",
  layout: "Personal SocialProof 04",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/socialproof04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplvivdz03aj0880gga4rnl2",
    itemId: "z2wp63x707",
  },
};

export const tmf_header04: ILayoutSettings = {
  label: "Personal Header 04",
  layout: "Personal Header 04",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/header04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplvivdz03aj0880gga4rnl2",
    itemId: "26n18ztvx2i",
  },
};

export const tmf_header05: ILayoutSettings = {
  label: "Personal Header 05",
  layout: "Personal Header 05",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/header05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplvivdz03aj0880gga4rnl2",
    itemId: "26n18ztvx2i",
  },
};

export const tmf_header06: ILayoutSettings = {
  label: "Personal Header 06",
  layout: "Personal Header 06",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/header06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckplvivdz03aj0880gga4rnl2",
    itemId: "gpakv3n1szq",
  },
};

export const tmf_form01: ILayoutSettings = {
  label: "Personal Form 01",
  layout: "Personal Form 01",
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/10mfSections/form01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckq2s0l5e2pv00880k4e0nxma",
    itemId: "3zvaqhtyeql",
  },
};
