import {
  Button,
  Cell,
  Dialog,
  DialogBody,
  DialogFooter,
  Grid,
} from "@launchos/components/ui";
// // import settings from "../../../config";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";

import { triggerHook } from "@launchos/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

/**
 * This is the popup window that displays when a user attempts to add an SSL to their domain name
 * @param {object} props The props
 * @returns {function} The component
 */
class SSLConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      success: false,
    };

    this.doSSL = this.doSSL.bind(this);
  }

  static contextType = AppConfig;

  doSSL(id, name) {
    const { performSSL } = this.props;

    this.setState({ loading: true }, async () => {
      const sslResponse = await performSSL(id, name);

      console.log({ sslResponse });
      const { stdout } = sslResponse;

      if (stdout.indexOf("errors") > -1) {
        console.log("Did Not Work");
        this.setState({ error: true, loading: false, success: false });
      } else {
        console.log("SUCCESS! ~ update lock logo for domain");
        this.setState({ success: true, error: false, loading: false });
      }
    });
  }

  render() {
    window.scroll(0, 0);

    const settings = this.context;

    const { allDomainsQuery, history, match, closeHref } = this.props;

    const domainInfoIndex = allDomainsQuery.domains.findIndex(
      (domain) => domain.id === match.params.domainId
    );

    const domainInfo = allDomainsQuery.domains[domainInfoIndex];

    if (!_.has(domainInfo, "name")) history.push("/settings/domains");

    return (
      <Dialog
        {...this.props}
        style={{ width: 700 }}
        centered
        closeHref={closeHref}
      >
        <DialogBody>
          <img
            style={{ margin: 10, width: 100 }}
            alt=""
            src="https://hostpapa.blog/blog/wp-content/uploads/2017/03/ssl-certificate.jpg"
          />

          {this.state.success ? (
            <div>
              <h2>Congrats! Your secure certificate has been created</h2>
              <p style={{ fontWeight: "bold" }}>
                Your visitors will now be able to access your website using the
                https protocol...
              </p>
              <p
                style={{
                  marginTop: 30,
                  border: "1px solid #CCC",
                  padding: 25,
                  borderRadius: 5,
                  backgroundColor: "#f3f3f3",
                }}
              >
                <span style={{ fontSize: "22pt" }}>
                  https://
                  {domainInfo.name}
                </span>
              </p>
              <br />
              <Link to={closeHref}>
                <Button large primary>
                  Ok
                </Button>
              </Link>
            </div>
          ) : (
            <div>
              <h2>
                Create a secure certificate for
                <br />({domainInfo.name}) now?
                {triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE, type: 'SSL' })}
              </h2>
              <p style={{ fontWeight: "bold", paddingTop: 20 }}>
                When you create an SSL Certificate, the padlock and https
                <br />
                protocol will be activated for your website.
              </p>
            </div>
          )}

          {this.state.loading && (
            <div style={{ textAlign: "center" }}>
              <img src={settings.app.loadingImage} alt="Loading..." />
            </div>
          )}

          {this.state.error && (
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#FFC",
                padding: 40,
                border: "1px solid #f50057",
                margin: 20,
              }}
            >
              <p style={{ fontWeight: "bold" }}>
                Unfortunately, we could not create an SSL certificate for this
                domain.
              </p>
              <p>
                If you recently added this domain and already have it pointing
                to us,
                <br />
                please try again in a few hours.
              </p>
              <br />
              <Link to={closeHref}>
                <Button large primary>
                  Ok
                </Button>
              </Link>
            </div>
          )}
        </DialogBody>

        {!this.state.loading && !this.state.error && !this.state.success && (
          <DialogFooter>
            <Grid justify="space-evenly">
              <Cell align="center">
                <Button
                  large
                  secondary
                  onClick={() =>
                    this.doSSL(match.params.domainId, domainInfo.name)
                  }
                >
                  Yes, secure this Domain
                </Button>
              </Cell>
              <Cell align="center">
                <Link to={closeHref}>
                  <Button
                    large
                    style={{ color: "black" }}
                    transparent
                  >
                    Cancel
                  </Button>
                </Link>
              </Cell>
            </Grid>
            <p style={{ color: "#999", marginTop: 30, textAlign: "center" }}>
              Once activated, all of the data flowing between your website and <br />
              your browser will be encrypted, making it safer for your visitors <br />
              to send over personal information (such as credit cards).
            </p>
          </DialogFooter>
        )}
      </Dialog>
    );
  }
}

SSLConfirmation.defaultProps = {
  hidden: true,
};

export default SSLConfirmation;
