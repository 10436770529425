import _ from "lodash";
import React, { Component } from "react";

import Section from "../../Section";

import { Interaction } from "./Interaction";

export default class Interactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // actions: props.settings.hasOwnProperty("actions")
      actions: _.get(props, "settings.actions", []).length
        ? props.settings.actions
        : [
          {
            type: "DO_NOTHING",
            behavior: "click",
            name: "Do this...",
          },
        ],
    };

    this.setAction = this.setAction.bind(this);
  }

  setAction(key, action, behavior, newSettings = {}) {
    // console.log({ key, action, props: this.props });

    this.setState(
      (prevState) => ({
        actions: [
          ...prevState.actions.slice(0, key),
          {
            ...action,
            behavior,
            payload: _.get(action, "payload", false),
          },
          ...prevState.actions.slice(key + 1),
        ],
      }),
      () => {
        // console.log("updating actions", {
        //   ...this.props.settings,
        //   actions: this.state.actions,
        // });

        this.props.updateComponentSettings(
          this.props.settings.id,
          {
            ...this.props.settings,
            actions: this.state.actions,
            ...newSettings,
          },
          true,
          false
        );

        this.props.onUpdate(this.state.actions);
      }
    );
  }

  render() {
    const { actions } = this.state;

    return (
      <Section label="Interactions" icon="link" {...this.props}>
        {this.props.children}
        {actions.map((action, key) => (
          <Interaction
            {...this.props}
            behavior={this.props.behavior || "click"}
            label={_.get(this.props, "label", "When Object is Clicked...")}
            action={action}
            myKey={key}
            key={key}
            setAction={this.setAction}
          />
        ))}
      </Section>
    );
  }
}

Interactions.defaultProps = {
  onUpdate: () => false,
};
