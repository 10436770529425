import * as React from "react";
import { CursorProps } from "./types";
import style from "./style";

export const Cursor: React.FC<CursorProps> = ({ color = "#333" }) => {
  return (
    <div
      data-testid="WC-CURSOR-EDIT"
      style={{
        ...style.dragDropCursorHorizontal,
        backgroundColor: color,
        boxShadow: `0 0 0 1px #666`,
      }}
    />
  );
};

export default Cursor;
