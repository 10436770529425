import * as React from "react";
import { get } from "lodash";
import styled from "styled-components";

import { LiveFormFieldProps } from "../../LiveFormField/types";
import { LinearScaleFieldProps, LinearScaleDisplayType } from "../types";
import { EditorMode } from "../../../../Editor/types";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";

import { AttributeContainer } from "@launchos/modules/v2/CRUD/AttributeBuilder";
import { LiveLinearScaleField } from "./LiveLinearScaleField";

const LinearScaleField: React.FC<LinearScaleFieldProps> = (props) => {
  const {
    mode = EditorMode.LIVE,
    // data = [],
    displayType = LinearScaleDisplayType.RADIO,
    min = 1,
    max = 5,
    onChange = () => null,
    styles = {
      labelStyle: {},
      descriptionStyle: {},
      inputStyle: {},
      containerStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    name,
    label,
    description,
    defaultValue,
    disabled,
    icon,
    columns = 12,
    attributeContainerDataSettings = {},
  } = props;

  /**
   * Deals with any changes that comes from the various attributes that are loaded
   *
   * • Converts to LiveFormFieldProps shape
   * • Triggers (and passes along the mutated LiveFormFieldProps payload to) the onChange event
   * @params
   */
  const handleChange = (data: LiveFormFieldProps): void => {
    onChange(data);
  };

  const {
    iconStyle = {},
    descriptionStyle = {},
    containerStyle = {},
    inputStyle = {},
    validationStyle = {},
    labelStyle = {},
  } = styles;
  return (
    <div
      data-testid="FormBuilder-FormFields-LinearScaleField"
      style={containerStyle}
    >
      {mode === EditorMode.LIVE && (
        <LiveLinearScaleField
          label={label}
          styles={styles}
          max={max}
          min={min}
          description={description}
          handleChange={handleChange}
        />
      )}
      {mode === EditorMode.EDITOR && (
        <div data-testid="FormBuilder-FormFields-LinearScaleField-EDITOR">
          <AttributeContainer
            onChange={handleChange}
            attributes={props}
            data={[
              {
                id: "label",
                attribute: AttributeNames.LABEL,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "description",
                attribute: AttributeNames.DESCRIPTION,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "name",
                attribute: AttributeNames.FORMITEMNAME,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "columns",
                attribute: AttributeNames.COLUMNS,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "required",
                attribute: AttributeNames.REQUIRED,
                settings: {},
              },
            ]}
          >
            <div data-testid="FormBuilder-FormFields-LinearScaleField-MinMax">
              <select>
                <option onChange={handleChange}>1</option>
                <option onChange={handleChange}>2</option>
                <option onChange={handleChange}>3</option>
                <option onChange={handleChange}>4</option>
                <option onChange={handleChange}>5</option>
              </select>
              <select>
                <option onChange={handleChange}>1</option>
                <option onChange={handleChange}>2</option>
                <option onChange={handleChange}>3</option>
                <option onChange={handleChange}>4</option>
                <option onChange={handleChange}>5</option>
              </select>

              <input
                type="text"
                data-testid="FormBuilder-FormFields-LinearScaleField-MinLabel"
                onChange={handleChange}
              />
              <input
                type="text"
                data-testid="FormBuilder-FormFields-LinearScaleField-MaxLabel"
                onChange={handleChange}
              />
            </div>

            <div data-testid="FormBuilder-FormFields-LinearScaleField-RadioOrSlider">
              <input type="radio" onClick={handleChange} />
              <span>Show as radio buttons</span>
              <input type="radio" onClick={handleChange} />
              <span>Show as a slider</span>
            </div>
          </AttributeContainer>
        </div>
      )}
    </div>
  );
};

export default LinearScaleField;
