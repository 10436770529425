import { Cell, Grid, ListItem } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import objects from "@launchos/modules/campaign/shared/objects";
import _ from "lodash";
import React from "react";
import { ComponentTypes } from "../../../../WebComponent/types";
import style from "../../../style";
import { NiceMenu } from "../Helpers/NiceMenu";
import { IconTypes, Icon } from "@launchos/plugins/webcomponents/v2/Icon";
import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/types";
import { getSectionIcon } from "@launchos/plugins/misc/v2/blocks/SectionsLegend/SectionItem/SectionItem";


export const ScrollToSection = (props) => {
  const { action, updateAction, pageContent } = props;
  const { payload } = action;

  // console.log({ payload });

  return (
    <AppConfig.Consumer>
      {({ app }) => (
        // show dropdown menu of pages, with option to enter url
        <NiceMenu
          style={{ marginTop: 10 }}
          label={_.get(payload, "sectionType", "Choose the section...")}
        >
          {pageContent.filter(itm => itm.type === ComponentTypes.SECTION).map((itm, key) => {
            return (
              <ListItem key={key} onClick={() => updateAction(action, {
                ...payload,
                sectionId: itm.id,
                sectionType: itm.label,
              })}>
                <Grid>
                  <Cell
                    style={{ width: 30, paddingRight: 10, textAlign: "center" }}
                  >
                    <Icon type={getSectionIcon(itm.label)} source={IconSource.FONTAWESOME} />
                  </Cell>
                  <Cell>
                    {itm.label || "Section"}
                  </Cell>
                </Grid>
              </ListItem>
            )
          })}
          <div style={{ borderTop: '1px solid #CCC' }} />
          {pageContent.filter(itm => !itm.canHaveChildren).map((itm, key) => {
            return (
              <ListItem key={key} onClick={() => updateAction(action, {
                ...payload,
                sectionId: itm.id,
                sectionType: itm.label,
              })}>
                {itm.label || itm.type || "(N/A)"}
              </ListItem>
            )
          })}
        </NiceMenu>
      )}
    </AppConfig.Consumer>
  );
};
