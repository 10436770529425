import _ from "lodash";
import React from 'react';
import { Link } from "react-router-dom";

import { Button, TextInput } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import AvatarButton from "@launchos/modules/settings/AvatarButton";

export const leftActionsGroup = [
  props => (
    <Button style={{ color: "inherit" }} icon onClick={props.toggleAppDrawer}>
      menu
    </Button>
  )
];

export const rightActionsGroup = [
  props => (
    <AppConfig.Consumer>
      {({ app }) => {
        if (app.showAvatar) return <AvatarButton {...props} />;
        else return null;
      }}
    </AppConfig.Consumer>
  )
];


export class ToolbarContent extends React.Component {
  state = {
    isFocused: false,
  };
  render() {
    const { isFocused } = this.state;
    return (<AppConfig>
      {({ app }) => (<div style={{ padding: "0 80px 40px", width: 950, textAlign: 'center', margin: '0 auto' }}>

        <Link to="/dashboard">
          <img src={app.logoWhite} role="presentation" alt="Logo Icon" style={{ height: 45, margin: 15 }} />
        </Link>

        <h2 style={{ color: 'white', margin: 15 }}>Welcome to 10 Minute Funnels University</h2>

        <TextInput style={{
          backgroundColor: 'white',
          opacity: isFocused ? 1 : 0.3,
          boxShadow: isFocused ? '0 10px 20px rgba(0,0,0,0.03)' : 'none',
          marginTop: 30,
          padding: 20,
          borderRadius: 5,
          fontSize: '14pt',
          border: 'none',
          iconLeft: {
            position: "absolute",
            margin: 15,
            zoom: 1.2,
            color: isFocused ? 'inherit' : 'rgb(255,255,255,0.2)'
          },
        }}
          // focusOnMount
          onFocus={() => this.setState({ isFocused: true })} onBlur={() => this.setState({ isFocused: false })}
          // onChange={doSearch}
          placeholder="Search for videos, articles, demos, etc..." iconLeft="search" />
      </div>)}
    </AppConfig>);
  }
}
