import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import theme from "@launchos/components/ui/theme";
import { IComponentSettings, ComponentTypes } from "../../types";

const settings: IComponentSettings = {
  id: ComponentTypes.SECTION,
  name: "Section",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "view_agenda",
  },
  category: "layout",
  default: {
    type: ComponentTypes.SECTION,
    properties: {
      // background: "red",
      display: "block",
      width: "100%",
      padding: 40,
    },
    canHaveChildren: true,
  },
  properties: {
    main: {
      tabs: ["Container"],
      sections: [
        {
          tab: "Container",
          type: SectionTypes.BACKGROUND,
          settings: {
            isExpanded: true,
            showAlpha: true,
          },
        },
        {
          tab: "Container",
          type: SectionTypes.WIDTHHEIGHT,
          settings: {
            label: "Section Size",
            widthLabel: "Inner Width",
          },
        },
        {
          tab: "Container",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
      ],
    },
  },
};

export default settings;
