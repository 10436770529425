import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { Button, Grid, Cell, MultiSelector } from "@launchos/components/ui";

import style from "./style";

export default class MerchantChooseBilling extends Component {
  constructor(props) {
    super(props);

    const choice = props.providerSettings.payload.hasOwnProperty("billingModel")
      ? props.providerSettings.payload.billingModel
      : [];
    this.state = {
      list: [],
      choice,
    };
    this.saveOfferSelection = this.saveOfferSelection.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { providerSettings } = this.props;
    const offers = _.reduce(
      _.map(providerSettings.payload.offers, (o) => o.billing_models),
      _.extend
    );
    const items = _.map(offers, (name, key) => ({ id: key, name }));

    this.setState((prevState) => {
      // filter out invalid choices
      const choice = items.filter(
        (itm) => prevState.choice.findIndex((c) => c.id === itm.id) > -1
      );
      return {
        choice,
        list: items,
      };
    });
  }

  saveOfferSelection() {
    const { updateSettings, providerSettings, history } = this.props;
    const { choice } = this.state;

    // Save to State
    const payload = providerSettings.payload;
    updateSettings({
      ...payload,
      billingModel: choice,
    });
    // Show the Billing Screen
    history.push("./products");
  }

  render() {
    const { hidden } = this.props;
    const items = this.state.list;

    if (!hidden) {
      return (
        <div style={{ width: "100%", margin: "0 auto" }}>
          <h2>
            {/* Based on the offers you selected, here are the billing methods available.<br /> */}
            What Billing Options would you like to be made available on this
            page?
          </h2>
          <div style={{ margin: "30px 0", ...style.selectField }}>
            <MultiSelector
              items={items}
              columns="2"
              choice={this.state.choice}
              onChange={(choice) => this.setState({ choice })}
            />
          </div>
          <Grid justify="space-evenly" style={{ marginTop: 30 }}>
            <Cell align="center">
              <Link to={this.props.prevHref}>
                <Button large transparent style={{ color: "#333" }}>
                  Back
                </Button>
              </Link>
              <Button large tertiary onClick={this.saveOfferSelection}>
                Next
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
