import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    // marginTop: 15,
    mobile: {
      desktop: {
        fontSize: "28pt",
      },
      smartphone: {
        fontSize: "22pt",
      },
    },
  },
  SUBHEADLINE: {
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    // marginTop: 15,
    mobile: {
      desktop: {
        fontSize: "18pt",
      },
      smartphone: {
        fontSize: "13pt",
      },
    },
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
  FORM_FIELD: {
    style: {
      padding: "5px 0",
      maxWidth: "100%",
    },
    inputStyle: {
      width: "100%",
      // backgroundColor: "#EEE",
      padding: 15,
      // borderRadius: 20,
      border: "1px solid #DDD",
      borderRadius: 4,
    },
    labelStyle: {
      fontFamily: "Roboto",
      fontSize: "10pt",
      textAlign: "left",
      padding: 5,
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
      marginTop: 40,
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  LISTITEM: {
    style: {
      color: theme.foregroundColor,
      fontFamily: theme.fontFamily,
      // padding: "10px 0"
    },
    iconStyle: {
      borderRadius: "100%",
      padding: 10,
      color: theme.foregroundColor,
    },
    contentStyle: {
      padding: 10,
    },
  },
};
