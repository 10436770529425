import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.FOUR,
  layout: BenefitLayouts.FOUR,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkwzmhha20mu0780yzhnmh2r",
    itemId: "7ltfsb59jks",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.MOBILE04,
                style: {
                  position: "relative",
                  // top: "50%",
                  // transform: "translateY(-50%)",
                  maxWidth: 275,
                  margin: "0 auto",
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: {
              height: "100%",
              mobile: {
                desktop: {
                  padding: 80,
                },
                smartphone: {
                  padding: 15,
                },
              },
              minHeight: 20,
            },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: get(content, "main_headline.html", "..."),
              },
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  ...style.LIST,
                },
                children: [
                  {
                    type: ComponentTypes.LISTITEM,
                    canHaveChildren: false,
                    properties: {
                      ...style.LI.style,
                    },
                    icon: IconTypes.AccountCircle,
                    iconStyle: style.LI.iconStyle,
                    html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p>
                    
                    <p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                  },
                  {
                    type: ComponentTypes.LISTITEM,
                    canHaveChildren: false,
                    properties: {
                      ...style.LI.style,
                    },
                    icon: IconTypes.AccountCircle,
                    iconStyle: style.LI.iconStyle,
                    html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
