import * as React from "react";
import styled from "styled-components";
import { get, head } from "lodash";
import { Grid } from "@material-ui/core";

import { MultipleChoiceProps, IRadioItem } from "../types";
import { EditorMode } from "../../../../Editor/types";
import { AttributeContainer } from "@launchos/modules/v2/CRUD/AttributeBuilder";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";
import SortableList from "@launchos/modules/v2/CRUD/SortableList";
import { LiveFormFieldProps } from "../../LiveFormField/types";
import { LiveMultipleChoice } from "./LiveMultipleChoice";

const MultipleChoice: React.FC<MultipleChoiceProps> = (props) => {
  const {
    mode = EditorMode.LIVE,
    data = [],
    items = [],
    onChange = () => null,
    onBlur = () => null,
    styles = {
      labelStyle: {},
      descriptionStyle: {},
      inputStyle: {},
      containerStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    name,
    label,
    description,
    defaultValue,
    disabled,
    icon,
    columns = 12,
    attributeContainerDataSettings = {},
  } = props;
  /**
   * Deals with any changes that comes from the various attributes that are loaded
   *
   * • Converts to LiveFormFieldProps shape
   * • Triggers (and passes along the mutated LiveFormFieldProps payload to) the onChange event
   * @params
   */
  const handleChange = (data: LiveFormFieldProps): void => {
    onChange(data);
  };

  const handleBlur = (blurData: LiveFormFieldProps): void => {
    onBlur(blurData);
  };

  const {
    iconStyle = {},
    descriptionStyle = {},
    containerStyle = {},
    inputStyle = {},
    validationStyle = {},
    labelStyle = {},
  } = styles;

  const RadioItem: React.FC<IRadioItem> = (itm) => {
    const [value, setValue] = React.useState<string>("");
    const { id, label, key } = itm;

    React.useEffect(() => {
      setValue(itm.value);
    }, []);

    return (
      <div
        style={{
          width: "100%",
          margin: 5,
        }}
      >
        <input
          tabIndex={key}
          style={{
            border: "none",
            width: "100%",
            padding: 10,
            background: "transparent",
            fontSize: "11pt",
          }}
          type="text"
          value={value}
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => {
            const k = items.findIndex((dta) => dta.name === itm.name);
            const item = head(items.filter((dta) => dta.name === itm.name));

            const updatedData = {
              ...props,
              items: [
                ...items.slice(0, k),
                { ...item, value: e.target.value },
                ...items.slice(k + 1),
              ],
            };

            handleChange(updatedData);
            handleBlur(updatedData);
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
    );
  };

  // const CheckboxItem: React.FC<IListItem> = (itm) => {
  //   const [value, setValue] = React.useState<string>("");
  //   // const { id, label, key } = itm;

  //   React.useEffect(() => {
  //     setValue(itm.value);
  //   }, []);

  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         margin: 5,
  //       }}
  //     >
  //       <input
  //         type="text"
  //         value={value}
  //         style={{
  //           border: "none",
  //           width: "100%",
  //           padding: 10,
  //           background: "transparent",
  //           fontSize: "11pt",
  //         }}
  //         onClick={(e) => e.stopPropagation()}
  //         onBlur={(e) => {
  //           const k = items.findIndex((dta) => dta.name === itm.name);
  //           const item = head(items.filter((dta) => dta.name === itm.name));

  //           const updatedData = {
  //             ...props,
  //             items: [
  //               ...items.slice(0, k),
  //               { ...item, value: e.target.value },
  //               ...items.slice(k + 1),
  //             ],
  //           };

  //           handleChange(updatedData);
  //           handleBlur(updatedData);
  //         }}
  //         onChange={(e) => {
  //           setValue(e.target.value);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  return (
    <div
      data-testid="FormBuilder-FormFields-MultipleChoice"
      style={containerStyle}
    >
      {mode === EditorMode.LIVE && (
        <LiveMultipleChoice data={data} styles={styles} label={label} description={description} handleChange={handleChange} />
      )}

      {mode === EditorMode.EDITOR && (
        <div data-testid="FormBuilder-FormFields-MultipleChoice-EDITOR">
          <AttributeContainer
            onChange={handleChange}
            attributes={props}
            data={[
              {
                id: "label",
                attribute: AttributeNames.LABEL,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "description",
                attribute: AttributeNames.DESCRIPTION,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "name",
                attribute: AttributeNames.FORMITEMNAME,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "columns",
                attribute: AttributeNames.COLUMNS,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "required",
                attribute: AttributeNames.REQUIRED,
                settings: {},
              },
            ]}
          >
            <SortableList
              itemStyle={{
                border: "1px solid #DDD",
                borderRadius: 3,
                margin: "10px 0",
                textAlign: "left"
              }}
              canEdit={false}
              data={items}
              ItemComponent={(itm) => <RadioItem {...itm} />}
              onChange={(updatedData) => {
                handleChange({ ...props, items: updatedData });
                handleBlur({ ...props, items: updatedData });
              }}
              onDelete={(payload, updatedData) => {
                handleChange({ ...props, items: updatedData });
                handleBlur({ ...props, items: updatedData });
              }}
              onAdd={() => {
                const updatedData = {
                  ...props,
                  items: items.length
                    ? [
                      ...items,
                      {
                        ...items[items.length - 1],
                        name: Math.random()
                          .toString(36)
                          .slice(2),
                        id: Math.random()
                          .toString(36)
                          .slice(2),
                      },
                    ]
                    : [
                      {
                        id: Math.random()
                          .toString(36)
                          .slice(2),
                        name: Math.random()
                          .toString(36)
                          .slice(2),
                        value: "",
                      },
                    ],
                };
                handleChange(updatedData);
                handleBlur(updatedData);
              }}
              canDragAndDrop
              nested
            />
          </AttributeContainer>
        </div>
      )}
    </div>
  );
};

export default MultipleChoice;
