import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
    // backgroundColor: "#222",
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 50,
    mobile: {
      desktop: { padding: 50 },
      smartphone: { padding: 0 },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
    mobile: {
      desktop: { fontSize: "28pt" },
      smartphone: { fontSize: "18pt" },
    },
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 40,
    color: theme.foregroundColor,
    // fontSize: "8pt"
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      background: theme.foregroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  BUTTON_CONTAINER: {
    maxWidth: 350,
    marginTop: 40,
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "90%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.foregroundColor}`,
      borderRadius: 5,
      color: theme.foregroundColor,
    },
  },
};
