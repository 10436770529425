import * as React from "react";
import { get } from "lodash";
import styled from "styled-components";

import { AddSectionsTabProps } from "./types";
import SectionPreview from "./SectionPreview";
import Chip from "@material-ui/core/Chip";
import CheckIcon from "@material-ui/icons/Check";
import { CategoryData } from "./types";

const Categories = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  text-align: center;
  justify-content: center;
  background-color: #fff;
`;
// box-shadow: rgba(0, 0, 0, 0.3) -1px 1px 10px 0px;
// background-color: #f5f5f5;

/**
 * A tab in the "Add New" Item Drawer with all of the available sections that can be added to the page
 *
 * - As a user, I would like to see a separate "Sections" tab in the Add New Item drawer
 * - As a user, I would like to see a categorized list of all the available sections that I can add to my page
 * - As a user, I would like to be able to search and filter that list
 * - As a user, I would like to be able to add any of the sections to my page (via drag/drop, double-clicking on it, or a separate "add to page" button)
 * - As a user, I would like to be able see a larger preview of any of the sections in the list
 * - As a user, I would like to be able to add the section from the preview popup
 */
export const AddSectionsTab: React.FC<AddSectionsTabProps> = ({
  sections = [],
  categories = [],
  onAddSection,
  addThisAfterThat,
  onClose,
}) => {
  const [filter, setFilter] = React.useState<string>("");
  const [categoryData, setCategoryData] = React.useState<CategoryData[]>(
    categories
  );

  let categoryLimitedSections = sections.filter((section) => {
    // is this filter a part of the category selection?
    return (
      categoryData.findIndex(
        (category) =>
          category.selected &&
          section.label.toUpperCase().indexOf(category.type.toUpperCase()) > -1
      ) > -1
    );
  });

  // if there are no categories selected, show everything
  if (categoryData.findIndex((itm) => itm.selected) === -1)
    categoryLimitedSections = sections;

  // now filter based on search parameter
  const filteredSections = categoryLimitedSections.filter(
    (section) => section.label.toUpperCase().indexOf(filter.toUpperCase()) > -1
  );

  return (
    <div data-testid="Blocks-AddSectionsTab">
      <Categories>
        {/* <input
          placeholder="Search for a section..."
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        /> */}
        {/* <h4 style={{ fontSize: "16pt", marginBottom: 30 }}>
          Choose a category...
        </h4> */}
        <p style={{}}>Choose a category...</p>
        {categoryData.map((data, key) => (
          <li
            data-testid="Blocks-AddSectionsTab-Category"
            key={key}
            style={{ margin: 3 }}
          >
            <Chip
              color={data.selected ? "primary" : "default"}
              label={data.label}
              clickable
              icon={data.selected ? <CheckIcon /> : null}
              onClick={() =>
                setCategoryData([
                  ...categoryData.slice(0, key),
                  { ...data, selected: !data.selected },
                  ...categoryData.slice(key + 1),
                ])
              }
            />
          </li>
        ))}
      </Categories>
      <div
        style={{
          width: 400,
          top: 320,
          padding: 10,
          textAlign: "center",
          bottom: 0,
          overflowY: "scroll",
          position: "fixed",
        }}
      >
        {filteredSections.map((section, key) => (
          <div key={key} style={{ margin: "10px 0" }}>
            <SectionPreview
              {...section}
              onClose={onClose}
              settings={get(section, "default")}
              onAddSection={onAddSection}
              addThisAfterThat={addThisAfterThat}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddSectionsTab;
