import { Button, Cell, Grid } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React, { Component } from "react";
import _ from 'lodash';

export class DomainSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  static contextType = AppConfig;

  async componentDidMount() {
    const { hidden, domainInfo, addDomain } = this.props;
    if (!hidden && _.get(domainInfo, 'value') !== "") {
      await addDomain();
      this.setState({ loading: false });
    }
  }

  render() {
    const { app } = this.context;
    if (!this.props.hidden) {
      const closeHref =
        localStorage.builderType === "editor" ? "../builder" : "../edit";
      if (this.state.loading)
        return (
          <div style={{ textAlign: "center" }}>
            <img src={app.loadingImage} alt="Loading..." />
          </div>
        );
      return (
        <div style={{ width: 550 }}>
          <img
            style={{ margin: 10 }}
            alt="Success Indicator"
            src="https://s3.amazonaws.com/sandcastleassets/images/success_check.png"
          />
          <h2>Your Domain Name has been added</h2>
          <p style={{ marginTop: 30, fontSize: 13, color: "#666" }}>
            Note: In most cases, it only takes about 20 minutes before your
            domain is ready.
            <br />
            In rare cases it can take up to 48 hours.
          </p>
          <Grid justify="space-evenly" style={{ marginTop: 30 }}>
            <Cell align="center">
              <Button
                style={{ cursor: "pointer" }}
                onClick={() =>
                (window.location.href = this.props.campaignId
                  ? closeHref
                  : "/settings/domains")
                }
                large
                tertiary
              >
                OK
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
