import * as React from "react";

import "@launchos/10mf/src/index.css";
import "@launchos/10mf/src/style.css";

import EditorCanvas from "@launchos/modules/editor/Builder/PureCanvas";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BODY } from "@launchos/modules/v2/Editor/types";
// import WebFontLoader from "webfontloader";

export const sampleContentData = [
  {
    parent: "u5j1r64m5rf",
    id: "bj51e45wmym",
    properties: {
      // marginTop: 50,
      marginTop: 0,
      color: "#95a5a6",
      textAlign: "center",
      fontFamily: "Advent Pro",
      fontSize: 36,
      padding: 5,
      display: "inline-block",
    },
    type: "Paragraph",
    html: "<p>Replace this content with your own!</p>",
  },
  {
    parent: "u5j1r64m5rf",
    id: "hm3iurc13pd",
    properties: {
      marginTop: 2,
      color: "#95a5a6",
      textAlign: "center",
      fontFamily: "Advent Pro",
      fontSize: 20,
      padding: 5,
      mobile: {
        desktop: {
          marginTop: 2,
          color: "#95a5a6",
          textAlign: "center",
          fontFamily: "Advent Pro",
          fontSize: 20,
        },
      },
      display: "inline-block",
    },
    type: "Paragraph",
    html: "<p>(Just select me and hit your keyboard's [DELETE] key)</p>",
  },
  {
    parent: 2540,
    id: "u5j1r64m5rf",
    properties: {
      width: "100%",
      mobile: {
        desktop: {
          padding: 100,
        },
      },
      padding: 100,
    },
    type: "Container",
    canHaveChildren: true,
  },
  {
    parent: false,
    state: "normal",
    id: BODY,
    properties: {
      backgroundColor: "#ffffff",
      backgroundImage: "none",
      height: "100%",
      backgroundAttachment: "scroll",
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "repeat-y",
      mobile: {
        desktop: {
          backgroundColor: "#ffffff",
          backgroundImage: "none",
          backgroundAttachment: "scroll",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          backgroundRepeat: "repeat-y",
        },
      },
    },
    type: ComponentTypes.BODY,
    canHaveChildren: true,
  },
];

export const sendMessage = (type, request, payload, id) => {
  const isFromIframe = window.location !== window.parent.location;
  if (!isFromIframe) return;
  window.parent.postMessage({ type, request, payload, id }, "*")
}

export const LaunchOSEditor = ({ id }) => {
  const [pageContent, setPageContent] = React.useState(sampleContentData);
  const pageId = id;

  return (
    <EditorCanvas
      content={pageContent}
      onChange={({ content = [] }) => {
        if (content.length) {
          setPageContent(content);
          sendMessage("EditorCanvasEvent", "onChange", content, pageId);
        }
      }}
      onSave={({ content = [] }) => {
        if (content.length) {
          console.log("We should save the following content to the database", content);
          sendMessage("EditorCanvasEvent", "onSave", content);          
        }
      }}
      onItemSelect={(id, settings) => {
        console.log(`You selected item id ${id}`, settings);
        sendMessage('EditorCanvasEvent', 'onItemSelect', { id, settings }, pageId);
      }}
      disableDefaultProperties
      onShowProperties={({ id, type }) => sendMessage('EditorCanvasEvent', 'onShowProperties', { id, type }, pageId)}
      onHideProperties={({ id, type }) => sendMessage('EditorCanvasEvent', 'onHideProperties', { id, type }, pageId)}
      // render={(obj) => {
      //   if (obj.type === "Paragraph") {
      //     return (
      //       <div
      //         style={{
      //           padding: 10,
      //           backgroundColor: "green",
      //           color: "white",
      //           fontSize: 18,
      //           ...obj?.settings?.properties
      //         }}
      //         dangerouslySetInnerHTML={{__html: obj?.settings?.html}}
      //       />
      //     );
      //   }
      //   return false;
      // }}
    />
  );
};

// export const NewEditor = () => {
//   const [content, setContent] = React.useState(sampleContentData);

//   return (
//     <div style={{ width: "75%", height: "80vh", margin: "100px auto", border: "1px solid #CCC", borderRadius: 5 }}>
//       <button onClick={() => setContent(content.slice(1))}>Remove First Item</button>
//       <EditorCanvas
//         content={content}
//         onItemSelect={console.log}
//       />
//     </div>
//   )
// }

export default LaunchOSEditor;
