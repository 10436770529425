// import { client } from "@launchos/api/gql";
// import { getPage } from "@launchos/api/gql/pages";
import {
  Button,
  Cell,
  Dialog,
  DialogBody,
  DialogFooter,
  Grid,
} from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import style from "./style";
// import { triggerAsyncHook } from "@launchos/plugins/client";

/**
 * This is the popup window that displays when a user attempts to publish their page
 * @param {object} props The props
 * @returns {function} The component
 */
class PublishCampaignSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      published: false,
    };

    this.getNextPage = this.getNextPage.bind(this);
    this.doPublish = this.doPublish.bind(this);

    window.scroll(0, 0);
  }

  static contextType = AppConfig;

  // calculate nextPage
  getNextPage(pageId) {
    // 1. get list of page objects
    const objects = this.props.getCampaignQuery.campaign.objects;
    const pages = objects.filter(
      (obj) => !obj.deleted && _.has(obj, "page.slug")
    );

    // 2. find the object associated with the provided pageId
    if (pages) {
      const theObject = pages.filter((obj) => obj.page.id === pageId);

      if (theObject.length) {
        const object = theObject[0]; // this is the object associated with the page I'm using to find the nextPage's slug

        // 3. in that object, find the first matching connectTo objId that happens to be
        // a page (find out if any of the connectTo ids match any of the ids in the
        // pages object array)
        if (object.connectTo) {
          const nextPageObj = object.connectTo.filter((itm) => {
            const objId = itm.id; // this is the object id in the connectTo array
            return pages.filter((pg) => pg.id === objId) && !itm.deleted;
          });

          // 4. return the slug of that matching object
          console.log(pageId, { pages }, { nextPageObj });
          if (nextPageObj.length) {
            const thePages = _.head(
              _.filter(pages, (pg) => pg.id === nextPageObj[0].id)
            );
            return _.get(thePages, "page.slug", false);
          }
          return false;
        }
        return false;
      }
    }

    return false;
  }

  async doPublish() {
    // const { app } = this.context;
    const appId = localStorage.getItem("appId");

    const { getCampaignQuery, publishCampaignId } = this.props;

    const { id } = getCampaignQuery.campaign;

    try {
      const { data } = await publishCampaignId({
        variables: {
          id,
          appId,
        },
      });
      const { webSiteUrl } = data.publishCampaignId;

      this.setState({
        published: true,
        url: webSiteUrl,
      });

      console.log({ data });
    } catch (err) {
      console.log(err);
      alert("There was an error publishing your campaign.  Please try again!");
      // window.location.href = `/campaign/${id}/edit`;
    }
  }

  componentDidMount() {
    const { getCampaignQuery } = this.props;

    if (!getCampaignQuery.loading && !getCampaignQuery.error) {
      this.doPublish();
    }
  }

  render() {
    const { app } = this.context;
    return (
      <Dialog
        {...this.props}
        style={style.popup}
        overlayStyle={style.popupBg}
        centered
        hidden={false}
      >
        <DialogBody>
          {this.state.published ? (
            <div>
              <img
                alt=""
                style={{
                  margin: 10,
                }}
                src="https://s3.amazonaws.com/sandcastleassets/images/success_check.png"
              />
              <h2>Your Campaign Has Been Published!</h2>
              <Grid
                justify="center"
                style={{
                  marginTop: 30,
                  border: "1px solid #CCC",
                  padding: 5,
                  borderRadius: 3,
                  width: 600,
                }}
              >
                <Cell align="center">
                  <a
                    target="_blank"
                    href={this.state.url}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: "#555",
                        textDecoration: "underline",
                      }}
                    >
                      {this.state.url}
                    </span>
                  </a>
                </Cell>
                <Cell align="center">
                  <Button
                    style={{
                      color: "#999",
                      visibility: "hidden",
                    }}
                    icon
                  >
                    edit
                  </Button>
                </Cell>
              </Grid>
            </div>
          ) : (
            <div>
              <h2>Please wait while we publish your campaign...</h2>
              <div
                style={{
                  color: "red",
                  textAlign: "center",
                }}
              >
                Note: This may take up to 60 seconds
              </div>
              <img
                style={{
                  marginTop: 10,
                }}
                alt=""
                src={app.loadingImage}
              />
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          {this.state.published ? (
            <Grid justify="space-evenly">
              <Cell align="center">
                <a
                  target="_blank"
                  href={this.state.url}
                  rel="noopener noreferrer"
                >
                  <Button large tertiary>
                    VISIT PAGE NOW
                  </Button>
                </a>
              </Cell>
            </Grid>
          ) : null}
        </DialogFooter>
      </Dialog>
    );
  }
}

PublishCampaignSuccess.defaultProps = {
  hide: true,
};

export default PublishCampaignSuccess;
