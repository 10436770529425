import { Button, Cell, Grid } from "@launchos/components/ui";
import React from "react";
import _ from 'lodash'


export const DomainHasContent = props => {
  if (!props.hidden) {
    const { changeStep, domainInfo, updateDomainInfo } = props;
    // const theDomain = getDomainInfo().value;
    const theDomain = _.get(domainInfo, 'value');

    return (
      <div style={{ width: 700 }}>
        <img
          alt="Domain Name Icon"
          src="https://s3.amazonaws.com/sandcastleassets/images/domain_icon.png"
        />
        <h2 style={{ padding: 30 }}>
          Is there any content on{" "}
          <span style={{ color: "red" }}>{theDomain}</span>
          <br />
          that you would like to preserve?
        </h2>
        <Grid justify="space-evenly" style={{ marginTop: 30 }}>
          <Cell align="center">
            <Button
              onClick={() =>
                updateDomainInfo({
                  step: "tutorial",
                  value: `www.${theDomain}`
                })
              }
              large
              tertiary
            >
              Domain Has No Content
            </Button>
          </Cell>
          <Cell align="center">
            <Button onClick={() => changeStep("subdomain")} large tertiary>
              Domain Has Content
            </Button>
          </Cell>
        </Grid>
      </div>
    );
  }
  return null;
};
