import * as React from "react";
import { get } from "lodash";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";
import { fieldset } from "@launchos/components/ui/theme";

import {
  PropertiesProps,
  OrderSummaryDisplayOptions,
  OrderSummaryItemTypes,
} from "../types";
import orderBuilderSettings from "../settings";

import { SummaryBuilder } from "./SummaryBuilder";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { IconTypes } from "../../Icon";

import { Grid } from "@material-ui/core";
/**
 * The property window that shows for the FormBuilderEditorObject
 */
const SummaryBuilderPropertyWindow: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);
  const { updateComponentSettings, getCampaignQuery } = props;

  const handleSettingsChange = (id, settings, dbUpdate = true) => {
    setSettings(settings);
    if (dbUpdate) updateComponentSettings(id, settings, true, false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ settings })

    handleSettingsChange(settings.id, {
      ...props.settings,
      displayOptions: {
        ...settings.displayOptions,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const { properties } = orderBuilderSettings;
  const { sections } = properties.main;

  const withOrderSummaryDisplay = updateSection(
    sections,
    "OrderSummaryDisplay",
    {
      component: (
        <Grid container>
          <Grid item xs={6}>
            <FormControlLabel
              name={OrderSummaryDisplayOptions.HEADER}
              control={
                <Checkbox
                  checked={get(
                    settings,
                    `displayOptions.${OrderSummaryDisplayOptions.HEADER}`,
                    false
                  )}
                  onChange={handleCheckboxChange}
                />
              }
              label="Table Header"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name={OrderSummaryDisplayOptions.SUBTOTAL}
              control={
                <Checkbox
                  checked={get(
                    settings,
                    `displayOptions.${OrderSummaryDisplayOptions.SUBTOTAL}`,
                    false
                  )}
                  onChange={handleCheckboxChange}
                />
              }
              label="Subtotal Amount"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name={OrderSummaryDisplayOptions.PRODUCTS}
              control={
                <Checkbox
                  checked={get(
                    settings,
                    `displayOptions.${OrderSummaryDisplayOptions.PRODUCTS}`,
                    false
                  )}
                  onChange={handleCheckboxChange}
                />
              }
              label="List of Products"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name={OrderSummaryDisplayOptions.TOTAL}
              control={
                <Checkbox
                  checked={get(
                    settings,
                    `displayOptions.${OrderSummaryDisplayOptions.TOTAL}`,
                    false
                  )}
                  onChange={handleCheckboxChange}
                />
              }
              label="Total Amount"
            />
          </Grid>
        </Grid>
      ),
    }
  );

  const withSummaryBuilder = updateSection(
    withOrderSummaryDisplay,
    "SummaryBuilderSettings",
    {
      component: (
        <SummaryBuilder
          {...settings}
          onChange={(data) =>
            handleSettingsChange(settings.id, { ...props.settings, data })
          }
        />
      ),
    }
  );

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withSummaryBuilder },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export default React.memo(SummaryBuilderPropertyWindow);
// export default SummaryBuilderPropertyWindow;
