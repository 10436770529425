// const gql = require("graphql-tag");
import gql from 'graphql-tag'

// allCampaigns;
export const allCampaigns = gql`
  query allCampaigns($userId: ID!) {
    campaigns(where: { user: { id: $userId }, deleted: false }) {
      id
      type
      name
      deleted
      screenshot
      createdAt
      updatedAt
      user {
        id
      }
      objects {
        id
        name
        x
        y
        type
        screenshot
        connectTo {
          id
          deleted
        }
        page {
          id
          name
        }
        settings
      }
    }
  }
`;

// getCampaign;
export const getCampaign = gql`
  query getCampaign($id: ID!) {
    campaign(where: { id: $id }) {
      id
      type
      deleted
      name
      screenshot
      createdAt
      scriptTags
      user {
        id
        firstName
        lastName
        subdomain
        email
      }
      domain {
        id
        name
        cloudfrontDistribution
      }
      objects {
        id
        name
        deleted
        x
        y
        width
        height
        type
        screenshot
        connectTo {
          id
          deleted
        }
        page {
          id
          name
          slug
          type
          theme
          screenshot
          content
          pageSettings
          homePageForDomain {
            id
          }
          errorPageForDomain {
            id
          }
        }
        settings
      }
    }
  }
`;

// createCampaign;
export const createCampaign = gql`
  mutation createCampaign(
    $type: String
    $deleted: Boolean
    $name: String!
    $screenshot: String
    # $objects: [CampaignobjectsObject!]
    $userId: ID!
    $domainId: ID
  ) {
    createCampaign(
      data: {
        type: $type
        deleted: $deleted
        name: $name
        screenshot: $screenshot
        # objects: { create: [$objects] }
        user: { connect: { id: $userId } }
        domain: { connect: { id: $domainId } }
      }
    ) {
      id
      type
      deleted
      name
      screenshot
      createdAt
      domain {
        id
      }
      objects {
        id
        name
        x
        y
        connectTo {
          id
          deleted
        }
        type
        screenshot
      }
    }
  }
`;

// updateCampaign;
export const updateCampaign = gql`
  mutation updateCampaign(
    $id: ID!
    $type: String
    $deleted: Boolean
    $name: String!
    $screenshot: String
    $scriptTags: String
    $objectsIds: [ObjectWhereUniqueInput!]
  ) {
    updateCampaign(
      where: { id: $id }
      data: {
        type: $type
        deleted: $deleted
        name: $name
        screenshot: $screenshot
        objects: { connect: $objectsIds }
        scriptTags: $scriptTags
      }
    ) {
      id
      type
      deleted
      name
      screenshot
      createdAt
      objects {
        id
        name
        x
        y
        connectTo {
          id
          deleted
        }
        type
        screenshot
      }
    }
  }
`;

// deleteCampaign;
export const deleteCampaign = gql`
  mutation deleteCampaign($id: ID!) {
    deleteCampaign(where: { id: $id }) {
      id
      deleted
      name
    }
  }
`;

// publishCampaign;
export const publishCampaign = gql`
  mutation publishCampaign(
    $campaignId: ID!
    $objects: Json!
    $trackingPayload: String!
    $launchDate: DateTime
    $type: String
    $parentDomain: String!
    $cloudfrontDistribution: String
  ) {
    publishCampaign(
      campaignId: $campaignId
      objects: $objects
      trackingPayload: $trackingPayload
      launchDate: $launchDate
      type: $type
      parentDomain: $parentDomain
      cloudfrontDistribution: $cloudfrontDistribution
    ) {
      webSiteUrl
      statusCode
    }
  }
`;

// publishCampaignId;
export const publishCampaignId = gql`
  mutation publishCampaignId(
    $id: ID!
    $appId: ID!
  ) {
    publishCampaignId(
      id: $id
      appId: $appId
    ) {
      webSiteUrl
      statusCode
    }
  }
`;

// updateCampaignName;
export const updateCampaignName = gql`
  mutation updateCampaign($campaignId: ID!, $name: String) {
    updateCampaign(where: { id: $campaignId }, data: { name: $name }) {
      id
    }
  }
`;

// getCampaignObject;
export const getCampaignObject = gql`
  query getCampaignObject($id: ID!) {
    object(where: { id: $id }) {
      id
      name
      x
      y
      width
      height
      connectTo {
        id
        deleted
      }
      campaign {
        id
      }
      page {
        id
        slug
      }
      settings
      type
      screenshot
    }
  }
`;

// updateObjectSettings
export const updateObjectSettings = gql`
  mutation updateObject($id: ID!, $settings: Json!) {
    updateObject(where: { id: $id }, data: { settings: $settings }) {
      id
    }
  }
`;

// updateCampaignObject;
export const updateCampaignObject = gql`
  mutation updateObject(
    $id: ID!
    $connectToIds: [ObjectWhereUniqueInput!]
    $disconnectIds: [ObjectWhereUniqueInput!]
    $deleted: Boolean
    $name: String!
    $screenshot: String
    $settings: Json
    $type: String
    $x: Int
    $y: Int
    $width: Int
    $height: Int
    $oldCampaignId: String
  ) {
    updateObject(
      where: { id: $id }
      data: {
        connectTo: { connect: $connectToIds, disconnect: $disconnectIds }
        deleted: $deleted
        name: $name
        screenshot: $screenshot
        settings: $settings
        type: $type
        x: $x
        y: $y
        width: $width
        height: $height
        oldCampaignId: $oldCampaignId
      }
    ) {
      id
      name
      x
      y
      width
      height
      connectTo {
        id
        deleted
      }
      type
      screenshot
    }
  }
`;

// createCampaignObject;
export const createCampaignObject = gql`
  mutation createObject(
    $connectToIds: [ObjectWhereUniqueInput!]
    $name: String!
    $screenshot: String
    $settings: Json
    $type: String!
    $x: Int!
    $y: Int!
    $width: Int
    $height: Int
    $campaignId: ID!
    $pageId: ID
  ) {
    createObject(
      data: {
        connectTo: { connect: $connectToIds }
        name: $name
        screenshot: $screenshot
        settings: $settings
        type: $type
        x: $x
        y: $y
        width: $width
        height: $height
        campaign: { connect: { id: $campaignId } }
        page: { connect: { id: $pageId } }
      }
    ) {
      id
      name
      x
      y
      width
      height
      connectTo {
        id
        deleted
      }
      page {
        id
        slug
      }
      type
      screenshot
      campaign {
        id
      }
    }
  }
`;

// getProviderOffers;
export const getProviderOffers = gql`
  mutation getOffers($provider: String!, $payload: Json) {
    getOffers(provider: $provider, payload: $payload) {
      message
      response
      statusCode
    }
  }
`;

// getProviderProducts;
export const getProviderProducts = gql`
  mutation getProducts(
    $provider: String!
    $providerCredentials: Json
    $payload: Json
  ) {
    getProducts(
      provider: $provider
      providerCredentials: $providerCredentials
      payload: $payload
    ) {
      message
      response
      statusCode
    }
  }
`;

// getProviderCampaigns;
export const getProviderCampaigns = gql`
  query getProviderCampaigns(
    $provider: String!
    # $providerCredentials: Json
    $payload: Json
  ) {
    getProviderCampaigns(
      provider: $provider
      # providerCredentials: $providerCredentials
      payload: $payload
    ) {
      # message
      response
      statusCode
    }
  }
`;

// getProviderCampaign;
export const getProviderCampaign = gql`
  mutation getCampaign(
    $provider: String!
    $providerCredentials: Json
    $payload: Json
  ) {
    getCampaign(
      provider: $provider
      providerCredentials: $providerCredentials
      payload: $payload
    ) {
      message
      response
      statusCode
    }
  }
`;

export const cloneObject = gql`
  mutation cloneObject($id: ID!, $campaignId: ID!) {
    cloneObject(id: $id, campaignId: $campaignId) {
      message
      response
      statusCode
    }
  }
`;

export const cloneCampaign = gql`
  mutation cloneCampaign($id: ID!, $userId: ID!) {
    cloneCampaign(id: $id, userId: $userId) {
      message
      response
      statusCode
    }
  }
`;

// module.exports = {
//   allCampaigns,
//   getCampaign,
//   createCampaign,
//   updateCampaign,
//   deleteCampaign,
//   publishCampaign,
//   publishCampaignId,
//   updateCampaignName,
//   getCampaignObject,
//   updateObjectSettings,
//   updateCampaignObject,
//   createCampaignObject,
//   getProviderOffers,
//   getProviderProducts,
//   getProviderCampaigns,
//   getProviderCampaign,
//   cloneObject,
//   cloneCampaign,
// }