export enum ValueTypesEnum {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATERANGE = "DATERANGE",
  NUMBER = "NUMBER",
  STRING = "STRING",
  CUSTOM = "CUSTOM",
  SELECT = "SELECT",
  MULTISELECT = "MULTISELECT",
}

export interface ValueSelectorProps {
  // key to pass back alongside chosen value
  name?: any;

  // (one of the components in this schema)
  type?: ValueTypesEnum;

  // The current value of the entry
  value?: any;

  // List of items
  items?: { caption: string; id: string | number }[];

  // should trigger when component changes
  onChange?: (data: any, name: string) => any;

  // should trigger on final change
  onBlur?: () => any;
}
