import _ from 'lodash';
import React from 'react'
import Page from "@launchos/modules/app/Page"
import theme from "@launchos/components/ui/theme";
import { AppConfig } from "@launchos/modules/app";
import { leftActionsGroup, rightActionsGroup, ToolbarContent } from './ToolbarComponents'
import {
  Card,
  CardCaption,
  Thumbnail,
  Grid,
  Cell,
  Button,
} from "@launchos/components/ui";
import style from "@launchos/modules/dashboard/style";

import content from './content'

class Group extends React.Component {
  setScrollPosition() {
    // console.log(this.groupList.scrollLeft)
  }

  render() {
    const { category, logoIcon } = this.props;
    return (
      <div style={{ maxWidth: '90%', width: 1350, margin: '45px auto' }}>
        <div style={{ zoom: .7, float: 'right' }}>
          <Button style={{ marginRight: 5 }} negative primary icon onClick={this.setScrollPosition}>keyboard_arrow_left</Button>
          <Button negative primary icon>keyboard_arrow_right</Button>
        </div>
        <h2 style={{ fontWeight: 'bold' }}>{category.name}</h2>

        <div style={{ width: '100%', overflow: 'hidden' }} ref={(el) => { this.groupList = el }}>
          <Grid style={{ width: _.filter(content, itm => itm.category === category.id).length * 280 }} justify="start" wrap>
            {
              _.map(
                _.filter(content, itm => itm.category === category.id), (item, key) => (
                  <Cell key={key}>
                    <Card style={{
                      width: 250, height: 180, marginRight: 20, marginBottom: 80
                    }}>
                      <div style={style.campaignItem}>
                        <a target="_blank" href={_.get(item, 'link')}>
                          <Thumbnail
                            style={{ padding: "40px 70px" }}
                            src={_.get(item, 'thumbnail', logoIcon)}
                          />
                        </a>
                      </div>
                      <CardCaption style={{ textAlign: 'center', marginTop: 10 }}>{item.name}</CardCaption>
                    </Card>
                  </Cell>
                )
              )
            }
          </Grid>
        </div>
      </div>
    )
  }
}


export default (
  <Page path="/university"
    ToolbarContent={ToolbarContent}
    toolbarOptions={
      {
        leftActionsGroup,
        rightActionsGroup,
        theme: 'dark',
        style: {
          backgroundColor: theme.primaryColor,
          // backgroundColor: theme.darkColor,
        },
      }
    }>
    <AppConfig>
      {({ app }) => {
        return (
          <>
            {
              _.map(
                _.filter(content, itm => itm.type === 'Category'), category => (
                  <Group category={category} logoIcon={app.logoIcon} />
                )
              )
            }
          </>
        )
      }
      }
    </AppConfig>

  </Page>
)