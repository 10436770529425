import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import theme from "@launchos/components/ui/theme";
import wltheme from "@launchos/plugins/misc/v2/blocks/weblayouts/theme";
import { lorem } from "@launchos/plugins/misc/v2/blocks/weblayouts/utils";

import { IComponentSettings, ComponentTypes } from "../../types";
import { IconTypes } from "../Icon";
import { IconSource } from "../Icon/live";

const settings: IComponentSettings = {
  id: ComponentTypes.LISTITEM,
  name: "List Item",
  // thumbnail: "comp_text.png",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "dns",
  },
  default: {
    type: ComponentTypes.LISTITEM,
    canHaveChildren: false,
    properties: {
      display: "inline-block",
    },
    icon: IconTypes.PictureAsPdf,
    iconSource: IconSource.MATERIAL,
    iconStyle: {
      fontSize: "28pt",
      padding: "0 15px",
      color: theme.darkColor,
    },
    html: `<p><span style="font-size: 1.0rem;"><strong>Value creation worksheet</strong></span></p><p>&nbsp;</p><p><span style="font-size: 1.0rem;">${lorem.generateParagraphs(
      1
    )}</span></p>`,
  },
  properties: {
    main: {
      // tabs: ["Basic", "Typography"],
      sections: [
        {
          // tab: "Basic",
          type: SectionTypes.ICONSELECTOR,
          sectionWrap: true,
          settings: {
            label: "Choose an Icon",
            icon: "insert_emoticon",
            containerStyle: { maxHeight: 400, overflowY: "scroll" },
            isExpanded: true,
          },
        },
        {
          // tab: "Basic",
          type: SectionTypes.BUTTONSLIDER,
          id: "customIconSize",
          sectionWrap: true,
          settings: {
            label: "Icon Size",
            icon: "aspect_ratio",
            text: "Icon Size",
            button1: { icon: "remove", value: "SHRINK" },
            button2: { icon: "add", value: "GROW" },
            minValue: 10,
            maxValue: 250,
            value: 42,
            showAutoCheck: false,
          },
        },
        {
          // tab: "Basic",
          type: SectionTypes.BUTTONSLIDER,
          id: "customIconPosition",
          sectionWrap: false,
          settings: {
            label: "Icon Position",
            icon: "aspect_ratio",
            text: "Icon Position",
            button1: { icon: "remove", value: "SHRINK" },
            button2: { icon: "add", value: "GROW" },
            minValue: -100,
            maxValue: 100,
            value: 0,
            showAutoCheck: false,
          },
        },
        {
          // tab: "Basic",
          type: SectionTypes.COLORPICKER,
          sectionWrap: false,
          settings: {
            icon: "colorize",
            label: "Icon Color",
            text: "Icon Color",
          },
        },
        // {
        //   // tab: "Typography",
        //   type: SectionTypes.TYPOGRAPHY,
        //   settings: {
        //     isExpanded: true,
        //     colorExpanded: true,
        //     showLineHeight: true,
        //   },
        // },
        {
          // tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          // tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Width",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          // tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default settings;
