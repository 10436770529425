import * as React from "react";
import { get, range } from "lodash";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import { updateSection } from "@launchos/modules/v2/Properties/components/generator";
import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
// import { FacebookComments } from "./live";

import commentSettings from "./settings";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

const Properties = (props) => {
  const { updateComponentSettings, settings } = props;
  const { properties } = commentSettings;
  const { sections } = properties.main;

  const updatedSections = updateSection(sections, SectionTypes.CAPTION, {
    value: get(settings, "appId", ""),
    onChange: (resp) => {
      // console.log({ resp });
      updateComponentSettings(
        settings.id,
        { ...settings, appId: resp },
        true,
        0
      );
    },
  });

  return (
    <PropertiesBuilder
      title="Facebook Comments"
      data={{
        ...properties,
        main: { ...properties.main, sections: updatedSections },
      }}
      {...props}
    />
  );
};

export const EditorFacebookComments: React.FC<PluginProps> = (props) => {
  const { settings, mode, id } = props;
  // const properties = convertProperties(settings.properties);
  const backgroundColor = "#EEE";
  const random = (seed) => {
    const x = Math.sin(seed++) * 10000;
    const number = (x - Math.floor(x)) * 100;
    // console.log(number);
    return number;

    // Math.floor(Math.random() * (100 - 20 + 1) + 20)
  };
  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Facebook Comments"
        id={id}
        {...props}
        PropertiesView={Properties}
      >
        <Grid container>
          <Grid item xs={3}>
            <Skeleton
              variant="rect"
              height={15}
              animation={false}
              style={{ backgroundColor }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rect"
              height={15}
              animation={false}
              style={{ backgroundColor }}
            />
          </Grid>
        </Grid>
        <Skeleton
          variant="rect"
          height={1}
          animation={false}
          style={{ margin: "20px 0" }}
        />
        <Grid container style={{ marginTop: 10 }} spacing={5}>
          <Grid item xs={1}>
            <Skeleton
              variant="circle"
              width={50}
              height={50}
              animation={false}
              style={{ backgroundColor }}
            />
          </Grid>
          <Grid item xs={11}>
            <div
              style={{
                border: `1px solid ${backgroundColor}`,
                height: 90,
                color: "#CCC",
                fontSize: "14pt",
                padding: 10,
              }}
            >
              Add a comment...
            </div>
          </Grid>
        </Grid>
        {range(1, 4).map((itm, key) => (
          <Grid key={key} container style={{ marginTop: 10 }} spacing={5}>
            <Grid item xs={1}>
              <Skeleton
                variant="circle"
                width={50}
                height={50}
                animation={false}
                style={{ backgroundColor }}
              />
            </Grid>
            <Grid item xs={11}>
              {range(1, 5).map((j, k) => (
                <Skeleton
                  key={k}
                  variant="rect"
                  height={7}
                  animation={false}
                  style={{
                    backgroundColor,
                    width: `${j === 1 ? 100 : random(j)}%`,
                    marginBottom: 10,
                  }}
                />
              ))}
            </Grid>
          </Grid>
        ))}
        <Skeleton
          variant="rect"
          height={1}
          animation={false}
          style={{ margin: "10px 0" }}
        />
        <Skeleton
          variant="rect"
          height={15}
          width={250}
          animation={false}
          style={{ backgroundColor }}
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorFacebookComments;
