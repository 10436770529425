import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 80,
    mobile: {
      desktop: { padding: 80 },
      smartphone: { padding: 10 },
    },
  },
  HEADLINE: {
    fontSize: "26pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    marginTop: 10,
  },
  LIST: { marginTop: 40, paddingLeft: 0 },
  LI: {
    style: { marginTop: 40, display: "inline-block" },
    iconStyle: {
      // backgroundColor: theme.foregroundColor,
      // color: theme.backgroundColor,
      fontSize: "18pt",
      // paddingRight: 15
      // borderRadius: "100%",
      backgroundColor: theme.foregroundColor,
      padding: 10,
      color: theme.backgroundColor,
      // border: `1px solid ${theme.backgroundColor}`,
      // marginBottom: 20,
      textAlign: "center",
      // margin: 20,
    },
  },
};
