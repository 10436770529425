import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { Navigation } from "./";
import { NavigationLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Navigation/types";

export interface ITheme {
  default: any;
  darkMode: any;
  lightMode: any;
}

export const themeSettings: ITheme = {
  default: {
    primaryColor: "#060606",
    secondaryColor: "#1baa10",
    highlightColor: "#f05a1f",
    lsHighlightColor: "#2096D3",
    darkTextColor: "#060606",
    lightTextColor: "#FFFFFF",
    heroImage: "background.png",
    avatar:
      "https://scontent-mia3-2.xx.fbcdn.net/v/t1.0-9/83336313_2530980476999415_5189690747049213952_o.jpg?_nc_cat=1&_nc_sid=85a577&_nc_ohc=vbqCtn5e_HoAX90N-Oz&_nc_ht=scontent-mia3-2.xx&oh=4ad24c763d18af44eacfb4eb9539004e&oe=5E991086",
    logo: "logos/bradlea.png",
    company: "Brad Lea LLC",
  },
  darkMode: {
    // primaryColor: "#060606",
  },
  lightMode: {},
};

export const styleData = {
  PAGE_MARGIN: {
    maxWidth: 1440,
    margin: "0 auto",
  },
  SECTION: { padding: 150, backgroundColor: "white" },
  TEXT: {
    color: themeSettings.default.lightTextColor,
    textAlign: "center",
    fontSize: "20pt",
    fontFamily: "Poppins",
  },
  HEADLINE: {
    color: themeSettings.default.darkTextColor,
    fontFamily: "PT Serif",
    fontSize: "28pt",
    paddingTop: 40,
    paddingBottom: 100,
    paddingLeft: 150,
    paddingRight: 150,
  },
  STANDARD_TEXT: {
    fontFamily: "Roboto",
    fontSize: "14pt",
  },
};

export const BradNav = () => {
  // console.log({ folder });
  return (
    <Navigation
      layout={NavigationLayouts.STANDARD}
      logo={themeSettings.default.logo}
      logoHref={`/${folder}`}
      logoStyle={{ maxWidth: 250 }}
      items={[]}
      // items={[
      //   { caption: "Consulting", href: "./consulting" },
      //   { caption: "Speaking", href: "./speaking" },
      //   { caption: "Training", href: "./training" },
      //   { caption: "Podcast", href: "./podcast" },
      // ]}
      linkStyle={{
        color: "white",
        listStyle: "none",
        display: "inline-block",
        padding: "20px 30px",
        fontFamily: "Roboto",
      }}
      style={{ padding: 20 }}
    />
  );
};

export const getMediaQueries = () => {
  // return { xs: false, sm: false, md: false, lg: false, xl: false };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  return {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    xs: useMediaQuery(theme.breakpoints.up("xs")),

    // Small devices (landscape phones, 576px and up)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    sm: useMediaQuery(theme.breakpoints.up("sm")),

    // Medium devices (tablets, 768px and up)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    md: useMediaQuery(theme.breakpoints.up("md")),

    // Large devices (desktops, 992px and up)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    lg: useMediaQuery(theme.breakpoints.up("lg")),

    // Extra large devices (large desktops, 1200px and up)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    xl: useMediaQuery(theme.breakpoints.up("xl")),
  };
};

export const folder =
  typeof window === "object" ? window.location.pathname.split("/")[1] : "";

// export default () => <div />;
