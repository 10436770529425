import * as React from "react";
import { get } from "lodash";
import styled from "styled-components";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowMaximize,
  faWindowMinimize,
  faQuoteLeft,
  faDollarSign,
  faCommentDollar,
  faMoneyCheck,
  faAward,
  faLocationArrow,
  faCompass,
  faSitemap,
  faStar,
  faStream,
  faPollH,
  faHourglass,
  faNewspaper,
  faCartPlus,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import { EditorObjectState } from "@launchos/modules/v2/Editor/components/EditorObject/types";
import theme from "@launchos/components/ui/theme";

import { SectionItemProps } from "./types";
import { IconTypes, Icon } from "@launchos/plugins/webcomponents/v2/Icon";
import { PageSectionCategory } from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/types";

const backgroundColor: string = "rgb(244, 244, 244)";

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #f0f0f0;
  background: white;
  z-index: -1;
  border-radius: 4px;
  &:hover {
    background-color: ${backgroundColor};
  }
  &:active {
    background-color: #eee;
    border-top: 1px solid #f0f0f0;
    margin-top: -1px;
  }
`;

export const getSectionIcon = (type) => {
  let icon: React.ReactNode = faQuestion;

  switch (type) {
    case PageSectionCategory.HEADER:
      icon = faWindowMaximize;
      break;
    case PageSectionCategory.BENEFIT:
      icon = faStar;
      break;
    case PageSectionCategory.CTA:
      icon = faCartPlus;
      break;
    case PageSectionCategory.CONTENT:
      icon = faNewspaper;
      break;
    case PageSectionCategory.PRICING:
      icon = faDollarSign;
      break;
    case PageSectionCategory.SOCIALPROOF:
      icon = faCommentDollar;
      break;
    case PageSectionCategory.FORM:
      icon = faMoneyCheck;
      break;
    case PageSectionCategory.NAVIGATION:
      icon = faSitemap;
      break;
    case PageSectionCategory.FOOTER:
      icon = faWindowMinimize;
      break;
    case PageSectionCategory.GUARANTEE:
      icon = faAward;
      break;
    case PageSectionCategory.QUIZ:
      icon = faPollH;
      break;
    case PageSectionCategory.URGENCY:
      icon = faHourglass;
      break;
  }

  return icon;
}

// border-radius: 4px;
/**
 * The specific item in the legend representing a section on the page
 */
const SectionItem: React.FC<SectionItemProps> = ({
  label = "",
  type,
  settings,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onDoubleClick,
}) => {
  let style = {};
  switch (get(settings, "state")) {
    case EditorObjectState.HOVER || "hover":
      style = {
        borderBottom: `1px solid #DDD`,
        backgroundColor,
      };
      break;
    case EditorObjectState.NORMAL || "normal":
      style = { borderBottom: `1px solid #DDD` };
      break;
    case EditorObjectState.ACTIVE || "active":
      style = {
        // borderBottom: `2px solid #DDD`
        backgroundColor: "rgb(238, 238, 238)",
      };
      break;
  }

  // let icon: IconTypes = IconTypes.Ballot;
  // let icon: IconName = "window-maximize";
  let icon: React.ReactNode = getSectionIcon(type)

  return (
    <Item
      data-testid="Blocks-SectionsLegend-SectionItem"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDoubleClick={onDoubleClick}
      style={style}
    >
      <span style={{ width: 35, display: "inline-block", textAlign: "center" }}>
        <Icon
          type={icon}
          source={IconSource.FONTAWESOME}
          style={{
            fontSize: "16pt",
            color:
              get(settings, "state", false) === EditorObjectState.ACTIVE
                ? theme.primaryColor
                : "#666",
            fontWeight:
              get(settings, "state", false) === EditorObjectState.ACTIVE
                ? "bold"
                : "inherit",
          }}
        />
      </span>
      <span
        style={{
          padding: 8,
          display: "inline-block",
          color:
            get(settings, "state", false) === EditorObjectState.ACTIVE
              ? theme.primaryColor
              : "#666",
          fontWeight:
            get(settings, "state", false) === EditorObjectState.ACTIVE
              ? "bold"
              : "inherit",
        }}
      >
        {label.toUpperCase()}
      </span>
    </Item>
  );
};

export default SectionItem;
