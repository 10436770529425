import { get } from "lodash";
import React from "react";
import ButtonSliderGroup from "../BuildingBlocks/ButtonSliderGroup";

import {
  convertToRem,
  convertFromRem,
} from "@launchos/modules/editor/Builder/MobileResponsiveToggle/style";
import { MobileState } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";

import { SpacingProps } from "./types";

/**
 * Creates a Button Slider Group to Change the Components Spacing
 */
const Spacing: React.FC<SpacingProps> = ({
  onChange = () => null,
  settings = {},
  updateComponentStyle = () => null,
  minValue = 0,
  maxValue = 100,
  getMobileState = () => ({
    type: MobileState.FULLSCREEN,
  }),
  useRem = true,
}) => {
  const margin = get(settings, "properties.margin", 0);
  const spacing = get(settings, "properties.Spacing", 0); // TODO: CONFLICT!!

  // send raw pixel value to the button slider even if input value is in rem
  const convertedValue = Number(
    convertFromRem(margin, getMobileState()["type"])
  );

  const handleChange = (value, shouldDbUpdate = true, wait = 100) => {
    const marginOffset =
      value === "SHRINK" ? convertedValue - 5 : convertedValue + 5;

    const newMargin = Number.isInteger(value) ? value : marginOffset;

    // convert the raw pixel value to rem before saving it the db
    const newValue = useRem
      ? convertToRem(newMargin, getMobileState()["type"])
      : newMargin;

    const newStyle = { margin: newValue };
    updateComponentStyle(settings.id, newStyle, shouldDbUpdate, wait);
    onChange();
  };

  return (
    <div data-testid="Properties-Spacing">
      <ButtonSliderGroup
        onChange={handleChange}
        text="Spacing"
        button1={{ icon: "remove", value: "SHRINK" }}
        button2={{ icon: "add", value: "GROW" }}
        minValue={minValue}
        maxValue={maxValue}
        value={convertedValue}
      />
    </div>
  );
};
export default Spacing;
