import * as React from 'react';
// import { Tabs, Tab } from "@material-ui/core";
import { Tab, Tabs } from "@launchos/components/ui";

import Section from "../../Section";

import Border from './Border'
import Shadow from './Shadow'

import { BorderShadowTypes, BordersShadowProps } from './types'

/**
 * A section for configuring the borders and shadows of an object
 */
export const BordersShadow: React.FC<BordersShadowProps> = (props) => {
    const [value, switchTab] = React.useState<BorderShadowTypes>(BorderShadowTypes.BORDER);

    const { hideShadow = false, settings = {}, updateComponentStyle = () => null } = props;
    const { id } = settings;

    return (
        <Section label="Border & Shadow" icon="rounded_corners" {...props}>
            {
                !hideShadow ? (
                    <Tabs transparent>
                        <Tab active={value === BorderShadowTypes.BORDER} onClick={() => switchTab(BorderShadowTypes.BORDER)}>Border</Tab>
                        <Tab active={value === BorderShadowTypes.SHADOW} onClick={() => switchTab(BorderShadowTypes.SHADOW)}>Shadow</Tab>
                    </Tabs>
                ) : null
            }
            <div style={{ padding: 10, paddingBottom: 20 }}>
                {value === BorderShadowTypes.BORDER && <Border onChange={payload => updateComponentStyle(id, payload, true)} {...props} />}
                {value === BorderShadowTypes.SHADOW && <Shadow onChange={payload => updateComponentStyle(id, payload, true)} {...props} />}
            </div>
        </Section>
    )
}

export default BordersShadow;