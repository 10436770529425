import { get, merge } from "lodash";
import { LoremIpsum } from "lorem-ipsum";

export const doIShow: any = (data, key) => get(data, `${key}.render`, true);

export const combineData: any = (...args) =>
  args.reduce((data, itm) => merge(data, itm));

export const lorem: any = new LoremIpsum({
  sentencesPerParagraph: {
    max: 4,
    min: 3,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});
