import { CSSProperties } from "react";

export enum VideoPositions {
  TOP,
  BOTTOM,
}

export enum VideoActions {
  MINIMIZE,
  NONE,
}

export type VideoTutorialStyles = {
  container?: CSSProperties;
  section?: CSSProperties;
  sectionWrapper?: CSSProperties;
  videoContainer?: CSSProperties;
  playButton?: CSSProperties;
  playButtonIcon?: CSSProperties;
  title?: CSSProperties;
  closeButton?: CSSProperties;
  replayButton?: CSSProperties;
  background?: CSSProperties;
};

type VideoPlaylistSection = {
  id: string;
  title: string;
  seconds: number[];
  action: VideoActions;
};

export type VideoTutorialProps = {
  src: string;
  // sections: VideoPlaylistSection[];
  position?: VideoPositions;
  style?: VideoTutorialStyles;
  data: VideoPlaylistSection[];
  showCloseButton?: boolean;
  showSections?: boolean;
  showTitle?: boolean;
  autoPlay?: boolean;
  transparent?: boolean;
  secondary?: boolean;
  primary?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  negative?: boolean;
  videoConfig?: unknown;
  sectionToPlay: string | boolean;
  onPlay?: (section: VideoPlaylistSection) => void;
  onPause?: (section: VideoPlaylistSection) => void;
  onProgress?: (seconds: string) => void;
  onComplete?: (section: VideoPlaylistSection) => void;
  onSectionChange?: (section: VideoPlaylistSection) => void;
  onClose?: (section: VideoPlaylistSection) => void;
};
