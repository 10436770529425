import * as React from "react";
import { BooleanValueProps } from "./types";

import Checkbox from "@material-ui/core/Checkbox";

export const BooleanValue: React.FC<BooleanValueProps> = ({
  value = false,
  onChange = () => null,
}) => {
  return (
    <div
      data-testid="BooleanValue-LIVE"
    >
      <Checkbox defaultChecked={value} onChange={e => onChange(e.target.checked)} />
    </div>
  );
};

export default BooleanValue;
