import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import {
  settingsBlank,
  settings00,
  settings01,
  settings02,
  settings03,
  settings04,
  settings05,
  settings06,
  settings07,
  settings08,
  settings09,
  settings10,
  settings11,
  settings12,
  settings13,
  settings14,
  settings15,
  settings16,
  settings17,
  settings18,
  settings19,
  settings20,
  settings21,
  settings22,
  settings23,
} from "./";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.WEB_LAYOUT && hook.type === "drawer") {
    return [
      settingsBlank,
      settings00,
      settings05,
      settings03,
      settings01,
      settings04,
      settings02,
      settings06,
      settings07,
      settings08,
      settings09,
      settings10,
      settings11,
      settings12,
      settings13,
      settings14,
      settings15,
      settings16,
      settings17,
      settings18,
      settings19,
      settings20,
      // settings21,
      // settings22,
      settings23,
    ];
  }
};
