import _ from "lodash";
import React from "react";
import { ComponentSelector } from "../Helpers/ComponentSelector";

export const ShowHide = (props) => {
  const { action, updateAction } = props;
  const { payload } = action;

  return (
    <React.Fragment>
      <ComponentSelector
        {...props}
        onChange={(itm) =>
          updateAction(action, {
            ..._.get(action, "payload", false),
            showId: itm.settings.id,
            showLabel: itm.html,
          })
        }
        filterOut={[["type", "Body"]]}
        filterOnly={[
          ["type", "Section"],
          ["type", "Container"],
          ["type", "Columns"],
        ]}
        label={
          _.has(payload, "showLabel")
            ? `Show: ${payload.showLabel}`
            : "Select an Item to Show..."
        }
        children={<div />}
      />
      <ComponentSelector
        {...props}
        onChange={(itm) =>
          updateAction(action, {
            ..._.get(action, "payload", false),
            hideId: itm.settings.id,
            hideLabel: itm.html,
          })
        }
        filterOut={[["type", "Body"]]}
        filterOnly={[
          ["type", "Section"],
          ["type", "Container"],
          ["type", "Columns"],
        ]}
        label={
          _.has(payload, "hideLabel")
            ? `Hide: ${payload.hideLabel}`
            : "Select an Item to Hide..."
        }
        children={<div />}
      />
    </React.Fragment>
  );
};
