export default {
  id: "picture",
  name: "picture",
  thumbnail: "comp_picture.png",
  default: {
    type: "Picture",
    name: "Image",
    src: "https://assets-pages.s3.amazonaws.com/drop_img-02.jpg",
    properties: {
      textAlign: "center",
      display: "inline-block",
      width: "100%"
    }
  }
};
