import _ from "lodash";
import React from "react";
import { ListItem } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import { NiceMenu } from "../Helpers/NiceMenu";


export const DownloadFile = (props) => {
    const { action, updateAction } = props;
    const { payload } = action;

    return (
        <AppConfig.Consumer>
            {({ app }) => (
                // show dropdown menu of files
                <NiceMenu
                    style={{ marginTop: 10 }}
                    label={_.get(payload, "urlFileName", "Choose a file...")}
                >
                    <ListItem
                        icon="link"
                        onClick={() => {
                            const urlPrompt = window.prompt(
                                "Enter the url to the file you want downloaded",
                                _.get(payload, "url", "http://")
                            );

                            if (urlPrompt) {
                                const url = urlPrompt.indexOf("http") > -1
                                    ? urlPrompt
                                    : `http://${urlPrompt}`;

                                updateAction(
                                    action,
                                    {
                                        url,
                                        urlFileName: url,
                                        useTag: false,
                                    },
                                    { href: url }
                                );
                            }
                        }}
                    >
                        Enter a Custom URL
                    </ListItem>
                </NiceMenu>
            )}
        </AppConfig.Consumer>
    );
};
