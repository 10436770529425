import { Tab, Tabs, NiceMenu, ListItem, Grid, Cell } from "@launchos/components/ui";
import _ from "lodash";
import React, { Component } from "react";

import { doColorConversion } from '@launchos/modules/v2/Properties/components/groups/ColorPicker/scripts'

import {
  ColorPicker,
  DropDownCollapse,
  FancySelector,
  Icon,
  Section,
  UploadGroup,
} from "..";

import {
  ButtonSlider
} from '@launchos/modules/editor/Builder/Properties/BuildingBlocks/ButtonSliderGroup/ButtonSlider'

class Background extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.theActiveTab || "color",
      position: "Automatic",
      repeat: "Don't Repeat",
      color: _.get(props, 'color')
    };

    this.switchTab = this.switchTab.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.handleRepeatChange = this.handleRepeatChange.bind(this);
  }

  switchTab(activeTab) {
    this.setState({ activeTab });
  }

  handleUpload(files) {
    const { settings, updateComponentStyle } = this.props;

    console.log("Uploaded Files:", files);

    if (files.length) {
      const file = _.head(files);

      if (!_.has(file, "preview")) {
        Object.assign(file, { preview: URL.createObjectURL(file) });
      }

      updateComponentStyle(
        settings.id,
        {
          backgroundImage: `url(${file.preview})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
        true,
        false
      );
    }
  }

  handleColorChange(color, dbUpdate = true, scheduleUpdate = false) {
    const { updateComponentStyle, settings } = this.props;

    this.setState({ color }, () => {
      let rgb = _.get(color, "rgb", doColorConversion(color.hex, "rgb"));
      const backgroundColor = `rgb(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`; // we use rgb() vs rgba() bc alpha still works and hex converter (for input field) won't convert to hex otherwise
      // console.log({ backgroundColor });

      updateComponentStyle(
        settings.id,
        {
          backgroundColor,
        },
        dbUpdate,
        scheduleUpdate
      );
    })

  }

  handlePositionChange(position) {
    const { settings, updateComponentStyle } = this.props;

    switch (position) {
      case "auto":
        updateComponentStyle(
          settings.id,
          {
            backgroundPosition: "left",
            backgroundSize: "auto",
            backgroundAttachment: "scroll",
          },
          true,
          false
        );
        this.setState({ position: "Automatic" });
        break;
      case "cover":
        updateComponentStyle(
          settings.id,
          {
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
          true,
          false
        );
        this.setState({ position: "Cover" });
        break;
      case "contain":
        updateComponentStyle(
          settings.id,
          {
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          },
          true,
          false
        );
        this.setState({ position: "Contain" });
        break;
      default:
        break;
    }
  }

  handleRepeatChange(backgroundRepeat) {
    const { settings, updateComponentStyle } = this.props;
    updateComponentStyle(settings.id, { backgroundRepeat }, true, false);

    let repeat = "Don't Repeat";
    if (backgroundRepeat === "repeat-x") repeat = "Left to Right";
    if (backgroundRepeat === "repeat-y") repeat = "Top to Bottom";
    if (backgroundRepeat === "repeat") repeat = "Both (Tile)";
    this.setState({ repeat });
  }

  render() {
    const {
      propertiesToHide,
      updateComponentStyle,
      settings,
      isExpanded,
      label,
      showExtras = true,
      hideTabs = false,
    } = this.props;
    const { activeTab, color } = this.state;

    let url = "";
    if (settings.hasOwnProperty("properties")) {
      if (settings.properties.hasOwnProperty("backgroundImage")) {
        url = settings.properties.backgroundImage;
        if (url.indexOf("(") > -1)
          url = url.match(/\((.*?)\)/)[1].replace(/('|")/g, "");
      }
    }

    const bgColor = _.get(settings, "properties.backgroundColor", false);
    const bgColorConverted = doColorConversion(bgColor, "rgb");
    // console.log({ bgColor, converted: bgColorConverted });
    const { backgroundSize = "auto", backgroundPosition = "center center" } = settings.properties;
    const bgSizeText = `${backgroundSize.substring(0, 1).toUpperCase()}${backgroundSize.substring(1)}`;
    let backgroundPositionX = backgroundPosition.split(" ")[0]; backgroundPositionX = `${backgroundPositionX.substring(0, 1).toUpperCase()}${backgroundPositionX.substring(1)}`
    let backgroundPositionY = backgroundPosition.split(" ")[1] || "center"; backgroundPositionY = `${backgroundPositionY.substring(0, 1).toUpperCase()}${backgroundPositionY.substring(1)}`
    return (
      <Section
        label={label || "Background"}
        icon="colorize"
        isExpanded={isExpanded}
      >
        {!hideTabs && (
          <Tabs transparent>
            {propertiesToHide.indexOf("color") === -1 ? (
              <Tab
                active={activeTab === "color"}
                onClick={() => this.switchTab("color")}
              >
                Color
              </Tab>
            ) : null}
            {propertiesToHide.indexOf("image") === -1 ? (
              <Tab
                active={activeTab === "image"}
                onClick={() => this.switchTab("image")}
              >
                Image
              </Tab>
            ) : null}
          </Tabs>
        )}

        {activeTab === "color" ? (
          <div style={{ padding: 10 }}>
            <ColorPicker
              {...this.props}
              color={_.get(this.props, 'settings.properties.backgroundColor')}
              onChange={this.handleColorChange}
            />
          </div>
        ) : null}

        {activeTab === "image" ? (
          <div label="Image">
            <UploadGroup
              url={url}
              onChange={this.handleUpload}
              highlighted
              hideLabel
            />

            {showExtras &&
              settings.properties.backgroundImage &&
              settings.properties.backgroundImage !== "none" ? (
              <div>
                <DropDownCollapse text={`Image Size: ${bgSizeText}`}>
                  <FancySelector
                    onChange={(id) => this.handlePositionChange(id)}
                    items={[
                      {
                        id: "auto",
                        component: (props) => (
                          <Icon {...props} icon="autorenew" caption="auto" />
                        ),
                      },
                      {
                        id: "cover",
                        component: (props) => (
                          <Icon {...props} icon="burst_mode" caption="cover" />
                        ),
                      },
                      {
                        id: "contain",
                        component: (props) => (
                          <Icon
                            {...props}
                            icon="aspect_ratio"
                            caption="contain"
                          />
                        ),
                      }, //settings_overscan
                    ]}
                  />
                  <NiceMenu label={bgSizeText}>
                    {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200].map(val => (
                      <ListItem onClick={() => {
                        updateComponentStyle(
                          settings.id,
                          {
                            backgroundSize: `${val}%`,
                          },
                          true,
                          false
                        );
                      }}>{val}%</ListItem>
                    ))}
                  </NiceMenu>
                </DropDownCollapse>
                <DropDownCollapse text={`Position: ${backgroundPositionX} ${backgroundPositionY}`}>
                  <NiceMenu label={`Horizontal Position: ${backgroundPositionX}`}>
                    {["Left", "Center", "Right", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%"].map(val => (
                      <ListItem onClick={() => {
                        updateComponentStyle(
                          settings.id,
                          {
                            backgroundPosition: `${val.toLowerCase()} ${backgroundPositionY}`,
                          },
                          true,
                          false
                        );
                      }}>Horizontal Position: {val}</ListItem>
                    ))}
                  </NiceMenu>
                  <NiceMenu label={`Vertical Position: ${backgroundPositionY}`} style={{ marginTop: 10 }}>
                    {["Top", "Center", "Bottom", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%"].map(val => (
                      <ListItem onClick={() => {
                        updateComponentStyle(
                          settings.id,
                          {
                            backgroundPosition: `${backgroundPositionX} ${val.toLowerCase()}`,
                          },
                          true,
                          false
                        );
                      }}>{val}</ListItem>
                    ))}
                  </NiceMenu>
                </DropDownCollapse>
                <DropDownCollapse text={`Repeat: ${this.state.repeat}`}>
                  <FancySelector
                    style={{ zoom: 0.80, width: "100%" }}
                    onChange={(id) => this.handleRepeatChange(id)}
                    items={[
                      {
                        id: "no-repeat",
                        component: (props) => (
                          <Icon
                            {...props}
                            icon="block"
                            caption="Don't repeat"
                          />
                        ),
                      },
                      {
                        id: "repeat-x",
                        component: (props) => (
                          <Icon
                            {...props}
                            icon="view_column"
                            caption="Left to Right"
                          />
                        ),
                      },
                      {
                        id: "repeat-y",
                        component: (props) => (
                          <Icon
                            {...props}
                            icon="view_stream"
                            caption="Top to Bottom"
                          />
                        ),
                      },
                      {
                        id: "repeat",
                        component: (props) => (
                          <Icon
                            {...props}
                            icon="view_module"
                            caption="Both (Tile)"
                          />
                        ),
                      },
                    ]}
                  />
                </DropDownCollapse>
              </div>
            ) : null}
          </div>
        ) : null}

        {activeTab === "video" ? (
          <div>
            <UploadGroup text="Video" />
            <DropDownCollapse text="Size: Automatic">
              <FancySelector>
                <Icon icon="settings" caption="auto" />
                <Icon icon="settings" caption="cover" />
                <Icon icon="settings" caption="contain" />
              </FancySelector>
            </DropDownCollapse>
          </div>
        ) : null}
      </Section>
    );
  }
}

Background.propTypes = {};

Background.defaultProps = {
  propertiesToHide: [],
};

export default Background;
