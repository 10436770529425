import * as React from "react";
import { useState } from "react";
import { get } from 'lodash';

import Typography from "@launchos/modules/editor/Builder/Properties/Sections/Typography";
import { Icon } from "@launchos/plugins/webcomponents/v2";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

import { CaptionProps } from "./types";
import style from "./style";

/**
 * Creates a caption group for gathering input data
 */
const Caption: React.FC<CaptionProps> = (props) => {
  const {
    value,
    placeholder,
    text,
    onChange,
    onKeyUp,
    help,
    showTypography = false,
    setCanDrag = (value) => false,
  } = props;

  const [caption, setCaption] = useState<string>(value);
  return (
    <div onClick={e => e.stopPropagation()}>
      <fieldset
        data-testid="Properties-Caption"
        style={style.fieldset}
        title={text}
      >
        <legend>{text || "Caption"}</legend>
        <div style={{ display: "flex" }}>
          <input
            placeholder={placeholder}
            value={caption}
            // ref={(el) => (this.input = el)}
            onMouseDown={(e) => { setCanDrag(false); e.stopPropagation(); }}
            onMouseUp={(e) => { setCanDrag(true); e.stopPropagation(); }}
            onChange={(e) => {
              setCaption(e.target.value);
            }}
            // onKeyUp={e => { e.preventDefault(); e.stopPropagation(); return false; }}
            onKeyUp={e => { onKeyUp(caption) }}
            onBlur={(e) => {
              setCanDrag(true);
              onChange(e.target.value);
            }}
            style={{
              padding: 10,
              width: "100%",
              border: "none",
              fontSize: 14,
            }}
          />
          <div
            onClick={() => {
              setCaption("");
              onChange("");
            }}
            style={{
              zoom: 0.65,
              cursor: "pointer",
              padding: 16,
              color: "#CCC",
            }}
          >
            <Icon type={IconTypes.Cancel} />
          </div>
        </div>
      </fieldset>

      {help
        ? <a
          target="_blank"
          style={{ textDecoration: 'underline', textAlign: 'center', color: '#666', fontSize: '10pt' }}
          href={get(help, 'url')}
        >{get(help, 'text')}</a>
        : null}

      {showTypography && (
        <Typography showColors={false} hideLabel {...props} type="Caption" />
      )}
    </div>
  );
};

export default Caption;
