import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // padding: 50,
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  SUBHEADLINE: {
    textAlign: "center",
    fontSize: "26pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 30,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  LISTITEM: {
    style: {
      // color: theme.foregroundColor,
      fontFamily: theme.fontFamily,
      padding: "10px 0",
    },
    iconStyle: {
      borderRadius: "100%",
      // backgroundColor: theme.foregroundColor,
      padding: 10,
      color: theme.backgroundColor,
    },
    contentStyle: {
      padding: 10,
    },
  },
  FORM_FIELD: {
    style: { width: "100%", padding: "20px 0" },
    inputStyle: {
      width: "100%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.backgroundColor}`,
      borderRadius: 5,
      fontSize: "14pt",
      color: theme.backgroundColor,
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: "15px 50px 7px",
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "18pt",
      paddingTop: 1,
    },
  },
};
