import * as React from "react";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface ComponentProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  caption?: string;
  style?: any;
}

export const Component: React.FC<ComponentProps> = ({ children, style }) => {
  return <>{children}</>;
};

export default Component;
