import React from "react";
import { ListItem, NiceMenu } from "@launchos/components/ui";
import * as Tools from "@launchos/modules/editor/Builder/Properties";
import { MobileState } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";

const BasicPropertiesTab = (props) => (
  <div style={{ width: "100%" }}>
    <Tools.Background {...props} label="Background" isExpanded />
    <Tools.WidthHeight {...props} label="Size" />
    <Tools.Section label="Position" icon="import_export">
      <Tools.Margin {...props} />
    </Tools.Section>
    <Tools.Alignment
      {...props}
      label="Alignment"
      verticalAlign={false}
      marginAlign
    />
  </div>
);

const AdvancedPropertiesTab = (props) => {
  const { getMobileState } = props;
  const { type } = getMobileState();

  const setItemsPerRow = ({ updateComponentStyle, settings }, num) => {
    updateComponentStyle(settings.id, {
      maxColumnsPerRow: num,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Tools.Section label="Columns Per Row" icon="view_column" isExpanded>
        <div style={{ padding: 10 }}>
          <NiceMenu
            label={`Max items per row: ${_.get(
              props.settings.properties,
              `mobile[${props.getMobileState().type}].maxColumnsPerRow`,
              ""
            )}`}
          >
            <ListItem onClick={() => setItemsPerRow(props, 1)}>1</ListItem>
            <ListItem onClick={() => setItemsPerRow(props, 2)}>2</ListItem>
            <ListItem onClick={() => setItemsPerRow(props, 3)}>3</ListItem>
            <ListItem onClick={() => setItemsPerRow(props, 4)}>4</ListItem>
            <ListItem onClick={() => setItemsPerRow(props, 5)}>5</ListItem>
          </NiceMenu>
        </div>
      </Tools.Section>
      <Tools.BordersShadow {...props} label="Borders & Shadows" isExpanded />
    </div>
  )
};

const Properties = (props) => (
  <Tools.PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[
      { id: "basic", title: "Row", component: BasicPropertiesTab },
      { id: "advanced", title: "Advanced", component: AdvancedPropertiesTab },
    ]}
  />
);

export default Properties;
