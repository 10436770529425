import * as React from "react";
import { debounce } from "lodash";

import Icon, { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

import { IconSelectorProps } from "./types";
import style, { LI } from "./style";

export const treat = (str) =>
  str.replace(/^[a-z]|[A-Z]/g, (v, i) =>
    i === 0 ? v.toUpperCase() : " " + v.toLowerCase()
  );

/**
 * Shows a list of icons that the user can select from.
 *
 * Includes tools to help them search and filter
 */
const IconSelector: React.FC<IconSelectorProps> = ({
  onChange,
  containerStyle,
  isExpanded = true,
  icon = "",
  showNoneSelector = false,
  initialSearchString = "",
}) => {
  const [searchString, setSearchString] = React.useState<string>(
    initialSearchString
  );
  const [activeIcon, setActiveIcon] = React.useState<string>(icon);
  const [icons, setIcons] = React.useState<string[]>([]);
  const [allIcons, setAllIcons] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState<boolean>(false);
  const [isIconListExpanded, setIsExpanded] = React.useState<boolean>(
    isExpanded
  );
  const [doDebounce] = React.useState(() =>
    debounce(() => setReload(true), 500)
  );

  React.useEffect(() => {
    if (reload) {
      const results = allIcons.filter(
        (itm) =>
          itm.toUpperCase().indexOf(searchString.toUpperCase()) > -1 ||
          itm.toUpperCase().indexOf(treat(searchString).toUpperCase()) > -1
      );
      setIcons(results);
      setReload(false);
      setLoading(false);
    }
  }, [reload]);

  React.useEffect(() => {
    const all = Object.keys(IconTypes).filter(
      (itm) =>
        itm.indexOf("Outlined") === -1 &&
        itm.indexOf("Rounded") === -1 &&
        itm.indexOf("Sharp") === -1 &&
        itm.indexOf("TwoTone") === -1
    );
    setAllIcons(all);
    setReload(true);
  }, []);

  return (
    <>
      <input
        type="text"
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value);
          setLoading(true);
          setIcons([]);
          doDebounce();
        }}
        onClick={() => setIsExpanded(true)}
        placeholder="Search for an icon..."
        style={style.input}
        data-testid="Properties-IconSelector-input"
      />
      {loading && (
        <div style={{ padding: 20, textAlign: "center" }}>Loading Icons...</div>
      )}
      {!loading && (isIconListExpanded || searchString.length) ? (
        <ul
          style={{ ...style.UL, ...containerStyle }}
          data-testid="Properties-IconSelector"
        >
          {showNoneSelector && (
            <LI
              key={0}
              style={{
                ...style.LI,
                ...(icon === IconTypes.CancelPresentationTwoTone &&
                  style.activeLI),
              }}
              onClick={() => {
                setActiveIcon(IconTypes.CancelPresentationTwoTone);
                onChange(IconTypes.CancelPresentationTwoTone);
              }}
            >
              <Icon
                type={IconTypes.CancelPresentationTwoTone}
                style={style.iconStyle}
              />
              <p>None</p>
            </LI>
          )}
          {icons.map((icon, key) => (
            <LI
              key={key}
              style={{
                ...style.LI,
                ...(icon === IconTypes[activeIcon] && style.activeLI),
              }}
              onClick={() => {
                setActiveIcon(IconTypes[icon]);
                onChange(IconTypes[icon]);
              }}
              data-testid="Properties-IconSelector-icon"
            >
              <Icon type={IconTypes[icon]} style={style.iconStyle} />
              <p>{treat(icon)}</p>
            </LI>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default IconSelector;
