import * as React from "react";
import { get, head } from "lodash";

import { useDimensions } from "@launchos/plugins/utilities";

import { DropdownFieldProps } from "../types";
import { EditorMode } from "@launchos/modules/v2/Editor/types";
import { AttributeContainer } from "@launchos/modules/v2/CRUD/AttributeBuilder";
import { AttributeNames } from "@launchos/modules/v2/CRUD/AttributeBuilder/types";

import SortableList from "@launchos/modules/v2/CRUD/SortableList";
import { LiveFormFieldProps } from "../../LiveFormField/types";
import { LiveDropdownField } from "./LiveDropdownField";

const DropdownField: React.FC<DropdownFieldProps> = (props) => {
  const [ref, iconDimensions] = useDimensions();

  const {
    mode = EditorMode.LIVE,
    placeholder,
    data = [],
    items = [],
    styles = {
      labelStyle: {},
      descriptionStyle: {},
      inputStyle: {},
      containerStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    name,
    label,
    description,
    defaultValue,
    disabled,
    icon,
    columns = 12,
    onBlur = () => null,
    onChange = () => null,
    attributeContainerDataSettings = {},
  } = props;

  /**
   * Deals with any changes that comes from the various attributes that are loaded
   *
   * • Converts to LiveFormFieldProps shape
   * • Triggers (and passes along the mutated LiveFormFieldProps payload to) the onChange event
   * @params
   */
  const handleChange = (changeData: LiveFormFieldProps): void => {
    // console.log({ changeData });
    onChange(changeData);
  };

  const handleBlur = (blurData: LiveFormFieldProps): void => {
    onBlur(blurData);
  };

  const {
    iconStyle = {},
    descriptionStyle = {},
    containerStyle = {},
    inputStyle = {},
    validationStyle = {},
    labelStyle = {},
  } = styles;

  const inputPadding = get(inputStyle, 'padding', 'inherit')

  const ItemComponent = (itm) => {
    const [value, setValue] = React.useState<string>("");

    React.useEffect(() => {
      setValue(itm.value);
    }, []);

    return (
      <div
        style={{
          width: "100%",
          margin: 5,
        }}
      >
        <input
          type="text"
          value={value}
          // variant="outlined"
          style={{
            border: "none",
            width: "100%",
            padding: 10,
            background: "transparent",
            fontSize: "11pt",
          }}
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => {
            const k = items.findIndex((dta) => dta.name === itm.name);
            const item = head(items.filter((dta) => dta.name === itm.name));
            // console.log({ item });
            // setValue(e.target.value);
            const updatedData = {
              // ...data,
              ...props,
              items: [
                ...items.slice(0, k),
                { ...item, value: e.target.value },
                ...items.slice(k + 1),
              ],
            };
            // console.log({ item, items, data, updatedData })
            handleChange(updatedData);
            handleBlur(updatedData);
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
    );
  };

  return (
    <div
      data-testid="FormBuilder-FormFields-DropdownField"
      style={containerStyle}
    >
      {mode === EditorMode.LIVE && (
        <LiveDropdownField
          ref={ref}
          data={data}
          style={styles}
          iconDimensions={iconDimensions}
          placeholder={placeholder}
          label={label}
          description={description}
          icon={icon}
          handleChange={handleChange}
        />
      )}
      {mode === EditorMode.EDITOR && (
        <div data-testid="FormBuilder-FormFields-DropdownField-EDITOR">
          <AttributeContainer
            onChange={handleChange}
            onBlur={onBlur}
            attributes={props}
            data={[
              {
                id: "label",
                attribute: AttributeNames.LABEL,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "description",
                attribute: AttributeNames.DESCRIPTION,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "name",
                attribute: AttributeNames.FORMITEMNAME,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "columns",
                attribute: AttributeNames.COLUMNS,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "required",
                attribute: AttributeNames.REQUIRED,
                settings: {},
              },
              {
                id: "iconSelector",
                attribute: AttributeNames.ICON,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
            ]}
          >
            <div style={{ textAlign: "center", padding: 10, marginTop: 10 }}>
              - Dropdown Items -
            </div>

            <div style={{ maxHeight: 350, overflow: "auto" }}>
              <SortableList
                id="DropdownField"
                itemStyle={{
                  border: "1px solid #DDD",
                  borderRadius: 3,
                  margin: "10px 0",
                  textAlign: "left"
                }}
                data={items.map((itm) => ({ ...itm, id: itm.name }))}
                ItemComponent={ItemComponent}
                canEdit={false}
                onAdd={() => {
                  const updatedData = {
                    ...props,
                    items: items.length
                      ? [
                        ...items,
                        {
                          ...items[items.length - 1],
                          name: Math.random()
                            .toString(36)
                            .slice(2),
                          id: Math.random()
                            .toString(36)
                            .slice(2),
                        },
                      ]
                      : [
                        {
                          id: Math.random()
                            .toString(36)
                            .slice(2),
                          name: Math.random()
                            .toString(36)
                            .slice(2),
                          value: "",
                        },
                      ],
                  };
                  handleChange(updatedData);
                  handleBlur(updatedData);
                }}
                onChange={(updatedData) => {
                  handleChange({ ...props, items: updatedData });
                  handleBlur({ ...props, items: updatedData });
                }}
                onDelete={(payload, updatedData) => {
                  // console.log({ payload, data, updatedData })
                  handleChange({ ...props, items: updatedData });
                  handleBlur({ ...props, items: updatedData });
                }}
                canDragAndDrop
                nested
              />
            </div>
          </AttributeContainer>
        </div>
      )}
    </div>
  );
};

export default DropdownField;
