import styled from "styled-components";
import { get } from "lodash";

import theme from "../../ui/theme";
import { VideoTutorialStyles } from "./types";

export const getStyles: (VideoTutorialProps) => VideoTutorialStyles = ({
  transparent = false,
  secondary = false,
  primary = true,
  tertiary = false,
  disabled = false,
  negative = false,
  container = {},
  section = {},
  background = {},
  sectionWrapper = {},
  videoContainer = {},
  playButton = {},
  playButtonIcon = {},
  title = {},
  closeButton = {},
  replayButton = {},
}) => {
  let color = theme.primaryColor;
  if (primary) color = theme.primaryColor;
  if (transparent) color = "transparent";
  if (secondary) color = theme.secondaryColor;
  if (tertiary) color = theme.tertiaryColor;
  if (disabled) color = theme.disabledColor;
  if (negative) color = theme.disabledColor;

  return {
    container: {
      position: "fixed",
      bottom: 0,
      left: 0,
      backgroundColor: color,
      padding: 15,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      zIndex: 1001,
      ...container,
    },
    section: {
      ...section,
    },
    sectionWrapper: {
      display: "flex",
      width: 450,
      justifyContent: "flex-end",
      ...sectionWrapper,
    },
    videoContainer: {
      width: "70vw",
      height: `calc(70vw / (16/9))`,
      // maxWidth: "80vw",
      // maxHeight: `calc(70vw / (16/9))`,
      position: "fixed",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1001,
      ...videoContainer,
    },
    playButton: {
      borderColor: color,
      marginTop: -40,
      textAlign: "center",
      ...playButton,
    },
    playButtonIcon: {
      fontSize: "38pt",
      color,
      padding: 5,
      ...(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        ? {
            position: "relative",
            top: -2,
          }
        : {}),
      ...playButtonIcon,
    },
    title: {
      color: "white",
      fontSize: "11pt",
      fontWeight: "bold",
      padding: 8,
      width: 450,
      ...title,
    },
    closeButton: {
      color: "white",
      padding: 5,
      position: "relative",
      top: 4,
      cursor: "pointer",
      ...closeButton,
    },
    replayButton: {
      color,
      backgroundColor: "white",
      textTransform: "uppercase",
      borderRadius: 25,
      border: "none",
      fontSize: "9pt",
      fontWeight: "bold",
      padding: "0 15px",
      margin: "0 10px",
      cursor: "pointer",
      ...replayButton,
    },
    background: {
      backgroundColor: `rgba(0,0,0,0.75)`,
      zIndex: 1000,
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      ...background,
    },
  };
};

export const SectionCircle = styled.div`
  padding: 2px;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  margin: 2px;
  margin-top: 14px;
  background-color: white;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
  cursor: pointer;
  transition: 100ms;
  &:hover {
    transform: scale(1.3);
  }
`;

export const BigButton = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: white;
  cursor: pointer;
  border-width: 5px;
  border-style: solid;
  transition: 100ms;
  &:hover {
    transform: scale(1.3);
  }
`;
