import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import { decode } from "@launchos/api/utils";
import { FontIcon } from "@launchos/components/ui";
import theme from "@launchos/components/ui/theme";
import { AppConfig } from "@launchos/modules/app";

import { MoreTab } from "./MoreTab";

import { TabItem, icon } from "./styles";

class Navigation extends Component {
  static contextType = AppConfig;

  render() {
    const { app } = this.context;
    const { show, getPageQuery, match, history, content, style } = this.props;
    const { campaignId, nodeId } = match.params;

    const iconActive = { color: theme.altColors[4] };

    const showMerchantSettings =
      content.findIndex((itm) => itm.name === "cardNumber") > -1 ||
      content.findIndex((itm) => _.get(itm, 'attributes.name') === 'cardNumber') > -1 || // form field
      content.findIndex((itm) => {
        return _.get(itm, 'data', []).findIndex(({ name }) => name === "cardNumber") > -1 // in form builder
      }) > -1 ||
      content
        .filter((itm) => itm.hasOwnProperty("actions"))
        .findIndex(
          (i) =>
            i.actions.findIndex(
              (o) => o.type === "TRIGGER_UPSELL" || o.type === "TRIGGER_BOLTPAY"
            ) > -1
        ) > -1;

    const pageSettings = getPageQuery.page.pageSettings;

    const storedEmailPayload = _.get(pageSettings, "email.payload", false);
    const emailPayload = storedEmailPayload
      ? JSON.parse(decode(storedEmailPayload))
      : false;

    return (
      <div style={style}>
        <TabItem
          style={{ paddingTop: 18 }}
          onClick={() => {
            localStorage.builderType === "editor"
              ? history.push(`/campaign/${campaignId}/builder`)
              : (window.location.href = `/campaign/${campaignId}/edit`);
          }}
        >
          <FontIcon style={icon}>keyboard_arrow_left</FontIcon>
          <div>My Funnel</div>
        </TabItem>

        <Link
          to={`/campaign/${campaignId}/${nodeId}/templates/${getPageQuery.page.type}`}
        >
          <TabItem active={show === "templates"}>
            <FontIcon
              style={show === "templates" ? { ...icon, ...iconActive } : icon}
            >
              apps
            </FontIcon>
            <div style={show === "templates" ? iconActive : {}}>Templates</div>
          </TabItem>
        </Link>

        <Link to={`/campaign/${campaignId}/${nodeId}/builder`}>
          <TabItem active={show === "builder"}>
            <FontIcon
              style={show === "builder" ? { ...icon, ...iconActive } : icon}
            >
              create
            </FontIcon>
            <div style={show === "builder" ? iconActive : {}}>Editor</div>
          </TabItem>
        </Link>

        <Link to={`/campaign/${campaignId}/${nodeId}/seo`}>
          <TabItem active={show === "seo"}>
            <FontIcon
              style={show === "seo" ? { ...icon, ...iconActive } : icon}
            >
              thumb_up
            </FontIcon>
            <div style={show === "seo" ? iconActive : {}}>SEO</div>
          </TabItem>
        </Link>

        {!showMerchantSettings ? (
          <Link
            to={`/campaign/${campaignId}/${nodeId}/email/${app.showProviderStep && !_.has(emailPayload, "campaignId")
              ? "provider"
              : "campaign"
              }`}
          >
            <TabItem active={show === "email"}>
              <FontIcon
                style={show === "email" ? { ...icon, ...iconActive } : icon}
              >
                email
              </FontIcon>
              <div style={show === "email" ? iconActive : {}}>
                Campaign Settings
              </div>
            </TabItem>
          </Link>
        ) : null}

        {showMerchantSettings ? (
          <Link to={`/campaign/${campaignId}/${nodeId}/merchant/index`}>
            <TabItem active={show === "merchant"}>
              <FontIcon
                style={show === "merchant" ? { ...icon, ...iconActive } : icon}
              >
                attach_money
              </FontIcon>
              <div style={show === "merchant" ? iconActive : {}}>
                Payment Settings
              </div>
            </TabItem>
          </Link>
        ) : null}

        <Link to={`/campaign/${campaignId}/${nodeId}/tracking`}>
          <TabItem active={show === "tracking"}>
            <FontIcon
              style={show === "tracking" ? { ...icon, ...iconActive } : icon}
            >
              settings_ethernet
            </FontIcon>
            <div style={show === "tracking" ? iconActive : {}}>Tracking</div>
          </TabItem>
        </Link>

        <MoreTab campaignId={campaignId} nodeId={nodeId} />
      </div>
    );
  }
}

export default Navigation;
