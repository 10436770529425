import * as React from "react";

import { HeadlineProps } from "./types";

export const Headline: React.FC<HeadlineProps> = ({
  children,
  style,
  mode,
  id,
}) => {
  return (
    <h1 data-testid="WC-HEADLINE-LIVE" style={style}>
      {children}
    </h1>
  );
};

Headline.defaultProps = {
  style: {},
};

export default Headline;
