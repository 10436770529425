import theme from "../../theme";

export const style = {
  SECTION: {
    backgroundColor: theme.backgroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    // paddingTop: 50,
    // paddingBottom: 0,
    // paddingLeft: 50,
    // paddingRight: 50,
  },
  HEADLINE: {
    textAlign: "left",
    mobile: {
      desktop: {
        fontSize: "28pt",
      },
      smartphone: {
        fontSize: "16pt",
      },
    },
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    color: theme.foregroundColor,
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 40,
    color: theme.foregroundColor,
  },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      marginTop: 40,
      border: "none",
      background: theme.foregroundColor,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.backgroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  LI: {
    color: theme.foregroundColor,
    fontFamily: theme.fontFamily,
    padding: "10px 0",
  },
  ICON: {
    display: "inline-block",
    // borderRadius: "100%",
    // backgroundColor: theme.foregroundColor,
    padding: 5,
    color: theme.foregroundColor,
  },
};
