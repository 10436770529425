export default {
  input: {
    // padding: 10,
    // fontSize: "11pt",
    // width: "100%",
    // margin: "5px 0",
    // border: "1px solid #DDD",
    // borderRadius: 3,
    width: "100%",
    marginTop: 10,
    textAlign: "left",
  },
  select: {
    container: {
      padding: "9px 9px 9px 0",
      margin: "5px 0",
      border: "1px solid #DDD",
      borderRadius: 3,
      backgroundColor: "white",
    },
    element: {
      width: "100%",
      fontSize: "11pt",
      cursor: "pointer",
      color: `rgb(102,102,102)`,
      border: "none",
      background: "transparent",
    },
  },
};
