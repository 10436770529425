import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Cell, Grid, MultiSelector } from "@launchos/components/ui";
import style from "./style";

export default class MerchantChooseProduct extends Component {
  constructor(props) {
    super(props);

    const choice = props.providerSettings.payload.hasOwnProperty("products")
      ? props.providerSettings.payload.products
      : [];
    this.state = {
      choice,
      list: [],
    };

    this.saveProductSelection = this.saveProductSelection.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { providerSettings } = this.props;

    const items = _.map(providerSettings.payload.offers, (offer) => {
      return _.map(offer.products, (product, key) => ({
        offer_id: offer.id,
        id: key,
        product_id: key,
        name: product,
        billing_model_options: offer.billing_models,
        quantity: 1,
        can_be_trial: offer.trial_flag === "1",
      }));
    });

    this.setState((prevState) => {
      // filter out invalid choices
      const list = items.length ? items[0] : [];
      const choice = prevState.choice.filter(
        (itm) => list.findIndex((c) => c.id === itm.id) > -1
      );

      return {
        choice,
        list,
      };
    });
  }

  saveProductSelection() {
    const {
      updateSettings,
      getProviderProducts,
      providerSettings,
      history,
    } = this.props;
    const { choice } = this.state;

    const productId = choice.map((itm) => itm.id);

    // Save to State
    const payload = providerSettings.payload;

    getProviderProducts({
      variables: {
        provider: "limelight",
        providerCredentials: {},
        payload: {
          ...payload,
          productId,
        },
      },
    }).then((response) => {
      const productDetails = _.map(
        response.data.getProducts.response.products,
        (itm) =>
          _.omit(itm, [
            "cost_of_goods_sold",
            "product_category_name",
            "product_is_shippable",
            "vertical_name",
            "response_code",
          ])
      );
      console.log({ productDetails });

      updateSettings({
        ...payload,
        products: choice,
        productDetails,
      });

      // Show the Success Screen
      history.push("./success");
    });
  }

  render() {
    if (!this.props.hidden) {
      return (
        <div style={{ width: "100%", margin: "0 auto" }}>
          <h2>What products would you like buyers to purchase on this page?</h2>
          <div style={{ margin: "30px 0", ...style.selectField }}>
            <MultiSelector
              items={this.state.list}
              choice={this.state.choice}
              onChange={(choice) => this.setState({ choice })}
              showOptions
            />
          </div>
          <Grid justify="space-evenly" style={{ marginTop: 30 }}>
            <Cell align="center">
              <Link to={this.props.prevHref}>
                <Button large transparent style={{ color: "#333" }}>
                  Back
                </Button>
              </Link>
              <Button large tertiary onClick={this.saveProductSelection}>
                Next
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
