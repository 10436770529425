import { CSSProperties } from "react";
const style: {
  span: CSSProperties;
  coverObject: CSSProperties;
  videoActionContainer: CSSProperties;
  videoActionText: CSSProperties;
  videoActionSeconds: CSSProperties;
  previewContainer: CSSProperties;
  previewContainerIcon: CSSProperties;
} = {
  span: {
    cursor: "text",
    display: "inline-block",
  },
  coverObject: {
    width: "100%",
    zIndex: 50,
    position: "absolute",
    height: "100%",
    textAlign: "center",
    // paddingTop: 100,
    opacity: 0.4,
  },
  videoActionContainer: {
    padding: "5px 10px",
    display: "flex",
    justifyContent: "space-between",
  },
  videoActionText: { marginTop: 10 },
  videoActionSeconds: { marginTop: 25 },
  previewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgba(255,255,255,0.5)",
    borderRadius: 3,
    backgroundColor: "rgba(255,255,255,0.1)",
    boxShadow: `0 0 2px rgba(0,0,0)`,
    // margin: -1,
  },
  previewContainerIcon: {
    color: `rgba(255, 255, 255, 0.4)`,
    textShadow: `1px 1px rgba(0,0,0,0.4)`,
    fontSize: "7.2rem",
  },
};

export default style;
