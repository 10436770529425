import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";
import {
  OrderSummaryTypes,
  OrderSummaryItemTypes,
  OrderSummaryDisplayOptions,
} from "@launchos/plugins/webcomponents/v2/OrderSummary/types";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
} from "@fortawesome/free-brands-svg-icons";
import { IconSource } from "../../../../../../webcomponents/v2/Icon/live";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.FIFTEEN,
  layout: FormLayouts.FIFTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form15.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzjrul12h5h0780m1q9wbx3",
    itemId: "wfgzp8n9tug",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.SUBHEADLINE,
          ...get(content, "main_headline.style", {}),
          marginTop: 30,
        },
        html: "Contact Information",
      },
      {
        type: ComponentTypes.FORM,
        data: [
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.FIRSTNAME,
            placeholder: "First Name",
            preset: FieldNamePresets.FIRSTNAME,
            columns: 6,
          },
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.LASTNAME,
            placeholder: "Last Name",
            preset: FieldNamePresets.LASTNAME,
            columns: 6,
          },
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.EMAIL,
            placeholder: "Email",
            preset: FieldNamePresets.EMAIL,
            columns: 6,
          },
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.PHONE,
            placeholder: "Phone",
            preset: FieldNamePresets.PHONE,
            columns: 6,
          },
        ],
        properties: {
          containerStyle: style.FORM_FIELD.style,
          inputStyle: style.FORM_FIELD.inputStyle,
          labelStyle: style.FORM_FIELD.labelStyle,
        },
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  marginTop: 30,
                },
                html: "Payment Information",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: {
              height: "100%",
              padding: 0,
              minHeight: 20,
              mobile: {
                desktop: {
                  display: "block",
                },
                smartphone: {
                  display: "none",
                },
              },
            },
            children: [
              {
                type: ComponentTypes.LIST,
                canHaveChildren: false,
                properties: {
                  marginTop: 30,
                  display: "inline-block",
                  textAlign: "right",
                },
                data: [
                  {
                    id: Math.random().toString(36).slice(2),
                    icon: faCcVisa,
                    iconSource: IconSource.FONTAWESOME,
                    iconStyle: {
                      fontSize: "20pt",
                      color: theme.backgroundColor,
                    },
                    style: {
                      display: "inline-block",
                      padding: "0 3px",
                    },
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    icon: faCcMastercard,
                    iconSource: IconSource.FONTAWESOME,
                    iconStyle: {
                      fontSize: "20pt",
                      color: theme.backgroundColor,
                    },
                    style: {
                      display: "inline-block",
                      padding: "0 3px",
                    },
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    icon: faCcDiscover,
                    iconSource: IconSource.FONTAWESOME,
                    iconStyle: {
                      fontSize: "20pt",
                      color: theme.backgroundColor,
                    },
                    style: {
                      display: "inline-block",
                      padding: "0 3px",
                    },
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    icon: faCcAmex,
                    iconSource: IconSource.FONTAWESOME,
                    iconStyle: {
                      fontSize: "20pt",
                      color: theme.backgroundColor,
                    },
                    style: {
                      display: "inline-block",
                      padding: "0 3px",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        type: ComponentTypes.FORM,
        data: [
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.CARDNUMBER,
            placeholder: "•••• •••• •••• ••••",
            preset: FieldNamePresets.CARDNUMBER,
          },
          {
            type: FormFieldTypes.DROPDOWN,
            name: FieldNamePresets.EXPIRATIONMONTH,
            placeholder: "Month",
            preset: FieldNamePresets.EXPIRATIONMONTH,
          },
          {
            type: FormFieldTypes.DROPDOWN,
            name: FieldNamePresets.EXPIRATIONYEAR,
            placeholder: "Year",
            preset: FieldNamePresets.EXPIRATIONYEAR,
          },
          {
            type: FormFieldTypes.TEXT,
            name: FieldNamePresets.CVV,
            placeholder: "CVV",
            preset: FieldNamePresets.CVV,
          },
        ],
        properties: {
          marginTop: 10,
          containerStyle: style.FORM_FIELD.style,
          inputStyle: style.FORM_FIELD.inputStyle,
          labelStyle: style.FORM_FIELD.labelStyle,
        },
      },

      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.SUBHEADLINE,
          marginTop: 40,
        },
        html: "Order Summary",
      },
      {
        type: ComponentTypes.ORDERSUMMARY,
        data: [
          {
            id: uniqueId(),
            title: "Premium Account",
            itemType: OrderSummaryItemTypes.PRODUCT,
            value: 997,
          },
          {
            id: uniqueId(),
            title: "Elite Mastery",
            itemType: OrderSummaryItemTypes.PRODUCT,
            value: 1997,
          },
        ],
        showHeader: true,
        showTotal: true,
        properties: { marginTop: 10 },
        designType: OrderSummaryTypes.STANDARD,
        displayOptions: {
          [OrderSummaryDisplayOptions.HEADER]: false,
          [OrderSummaryDisplayOptions.PRODUCTS]: false,
          [OrderSummaryDisplayOptions.TOTAL]: true,
          [OrderSummaryDisplayOptions.SUBTOTAL]: false,
        },
      },
      // {
      //   type: ComponentTypes.ORDERSUMMARY,
      //   data: [
      //   { title: "10 Minute Funnels Premium Subscription", value: 997 },
      //   { title: "10 Minute Funnels Premium Subscription", value: 1997 },
      // ],
      //   showHeader: false,
      //   showTotal: true,
      //   showCart: false,
      //   properties: { marginTop: 10 },
      //   displayType: OrderSummaryTypes.STANDARD,
      // },
      {
        type: ComponentTypes.BUTTON,
        canHaveChildren: false,
        properties: {
          ...style.BUTTON.style,
          marginTop: 30,
        },
        caption1: "Place Order Now",
        caption1style: style.BUTTON.caption1,
      },
    ],
  },
};

export default settings;
