import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { ContentLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Content/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CTALayouts } from "../types";
import { CountDownTypes } from "@launchos/plugins/webcomponents/v2/Countdown/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.NINETEEN,
  layout: CTALayouts.NINETEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA19.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "5k4nc153jkp",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: "Your Chance to Grab This Product at HALF-PRICE Disappears In...",
      },
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          textAlign: "center",
          mobile: {
            desktop: {
              padding: 40,
            },
            smartphone: {
              padding: 5,
            },
          },
        },
        children: [
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              display: "inline-block",
              textAlign: "center",
            },
            children: [
              {
                type: ComponentTypes.COUNTDOWN,
                countType: CountDownTypes.EVENT,
                eventDate: Date.now() + 2 * 24 * 60 * 60 * 1000,
                properties: {},
                dimensionStyle: style.COUNTDOWN.dimensionStyle,
                labelStyle: style.COUNTDOWN.labelStyle,
                numberStyle: style.COUNTDOWN.numberStyle,
                dividerStyle: style.COUNTDOWN.dividerStyle,
                showDivider: false,
                actions: [],
              },
            ],
          },
          {
            type: ComponentTypes.BUTTON,
            canHaveChildren: false,
            properties: style.BUTTON.style,
            caption1: "Click Here to Get Your Discount",
            caption1style: style.BUTTON.caption1,
            caption2: "...and complete Your order!",
            caption2style: style.BUTTON.caption2,
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              fontWeight: "bold" as "bold",
              color: theme.highlightColor,
              textAlign: "center",
              ...get(content, "sub_headline.style", {}),
            },
            html:
              "** This Discount Offer & All Bonuses <u>EXPIRES TONIGHT</u> At Midnight **",
          },
        ],
      },
    ],
  },
};

export default settings;
