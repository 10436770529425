// Nodes

import * as page_nodes from "./page_nodes/tab/client";
import * as tracking_nodes from "./tracking_nodes/tab/client";
// import * as action_nodes from "./funnel_actions/tab/client";
import * as destination_tab from "./destination_nodes/tab/client";

import * as smart_route from "./destination_nodes/smart_route/client";
import * as another_funnel from "./destination_nodes/another_funnel/client";
import * as redirect from "./destination_nodes/redirect/client";
import * as shopping_carts from "./destination_nodes/shopping_carts/client";
import * as page_shortcut from "./destination_nodes/page_shortcut/client";
import * as absplit from "./destination_nodes/absplit/client";

// import * as segment from "./funnel_actions/segment/client";
// import * as webhook from "./funnel_actions/webhook/client";
// import * as zapier from "./funnel_actions/zapier/client";
// import * as email from "./funnel_actions/email/client";
// import * as sms from "./funnel_actions/sms/client";
// import * as desktop from "./funnel_actions/desktop/client";
// import * as messenger from "./funnel_actions/messenger/client";
// import * as timer from './funnel_actions/time/clientr'
// import * as date from './funnel_actions/dat/cliente'

const destination_nodes = [
  destination_tab,
  shopping_carts,
  smart_route,
  redirect,
  another_funnel,
  page_shortcut,
  absplit,
];

const funnel_actions = [
  // action_nodes,
  // segment,
  // webhook,
  // zapier,
  // email,
  // sms,
  // desktop,
  // messenger,
  // timer,
  // date,
];

export default [
  page_nodes,
  tracking_nodes,
  ...destination_nodes,
  ...funnel_actions,
];
