import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { PricingLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Pricing/types";
import { combineData, lorem } from "../../utils";
import moment from "moment";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: PricingLayouts.FOUR,
  layout: PricingLayouts.FOUR,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/pricing/Pricing04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyd2er52ag90780rf8yda8f",
    itemId: "kflqfz7psn",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.PRICING,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 1200,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
              marginTop: 40,
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: "#f5f5f5",
                      padding: 30,
                    },
                    children: [
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html: "Basic",
                      },
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                        },
                        html: "$17",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html:
                          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris",
                      },
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          textAlign: "center",
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: "transparent",
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Purchase",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: "#f5f5f5",
                      padding: 30,
                    },
                    children: [
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html: "Small",
                      },
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                        },
                        html: "$47",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html:
                          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris",
                      },
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          textAlign: "center",
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: "transparent",
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Purchase",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: theme.backgroundColor,
                      padding: 30,
                    },
                    children: [
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          color: theme.foregroundColor,
                        },
                        html: "Professional",
                      },
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          color: theme.foregroundColor,
                        },
                        html: "$97",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          color: theme.foregroundColor,
                        },
                        html:
                          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris",
                      },
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          textAlign: "center",
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: theme.foregroundColor,
                            },
                            caption1: "Purchase",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: "#f5f5f5",
                      padding: 30,
                    },
                    children: [
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html: "Elite",
                      },
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                        },
                        html: "$197",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                        },
                        html:
                          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris",
                      },
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          textAlign: "center",
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: "transparent",
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Purchase",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              marginTop: 30,
            },
            html: `Limited offer, save 20% for annual plan only until the 30th of ${moment().format(
              "MMMM"
            )}`,
          },
        ],
      },
    ],
  },
};

export default settings;
