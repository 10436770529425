import { ComponentRenderHooks } from "@launchos/plugins/types";
import Body from "./Body";
import Live from "./component";

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Body") {
    if (hook.mode === "live") return [Live];
    else return [Body];
  }
};
