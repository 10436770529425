import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",

    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 20,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    lineHeight: "36px",
    // fontWeight: "bold" as "bold",
    padding: 10,
    mobile: {
      desktop: {
        fontSize: "28pt",
        lineHeight: "36px",
      },
      smartphone: {
        fontSize: "22pt",
        lineHeight: "36px",
      },
    },
  },
  SUBHEADLINE: {
    textAlign: "center",
    fontSize: "22pt",
    lineHeight: "30px",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    padding: 10,
    mobile: {
      desktop: {
        fontSize: "22pt",
        lineHeight: "30px",
      },
      smartphone: {
        fontSize: "14pt",
        lineHeight: "18px",
      },
    },
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      margin: 10,
      padding: 20,
      backgroundColor: theme.highlightColor,
      border: "none",
      borderRadius: 5,
      boxShadow: "1px 1px rgb(0,0,0,0.25)",
      width: "100%",
      maxWidth: "100%",
      cursor: "pointer",
      textAlign: "center",
      mobile: {
        desktop: {
          padding: 20,
        },
        smartphone: {
          padding: 10,
        },
      },
    },
    caption1: {
      fontFamily: theme.fontFamily,
      // textShadow: "2px 2px rgb(0,0,0,0.25)",
      fontSize: "22pt",
      fontWeight: "bold" as "bold",
      color: theme.foregroundColor,
      lineHeight: "35px",
      display: "block",
    },
    caption2: {
      color: "white",
      fontSize: "11pt",
    },
  },
};
