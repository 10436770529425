import { Button } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import React from "react";
import { Link } from "react-router-dom";
import AvatarButton from "../settings/AvatarButton";

const leftActionsGroup = [
  props => (
    <Button icon style={{ color: 'white' }} onClick={props.toggleAppDrawer}>
      menu
    </Button>
  ),
  () => (
    <AppConfig.Consumer>
      {({ app }) =>
        app.showToolbarLogo ? (
          <Link to="/dashboard">
            <img
              alt={`${app.name} Logo`}
              src={app.logoWhite}
              style={{ height: 35 }}
            />
          </Link>
        ) : (
          <span />
        )
      }
    </AppConfig.Consumer>
  )
];

const rightActionsGroup = [
  props => (
    <AppConfig.Consumer>
      {({ app }) => (app.showAvatar ? <AvatarButton {...props} /> : null)}
    </AppConfig.Consumer>
  )
];
export { leftActionsGroup, rightActionsGroup };
