import { Button, Cell, Grid } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
// import "codemirror/mode/javascript/javascript";
import "codemirror/mode/htmlmixed/htmlmixed";
import _ from "lodash";
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import { Link } from "react-router-dom";
// import { app } from "../../../config";
import providers from "../../settings/IntegrationManagement/integrationProviders";

export default class PasteFormCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: _.get(props, "payload.formCode", "")
    };

    this.saveCode = this.saveCode.bind(this);
  }

  static contextType = AppConfig;

  async saveCode() {
    console.log({ code: this.state.code });
    const { updateSettings, providerSettings, history, nextHref } = this.props;

    const payload = providerSettings.payload;

    await updateSettings({
      ...payload,
      formCode: this.state.code
    });

    // Show the Next Screen
    history.push(nextHref);
  }

  render() {
    const { app } = this.context;
    const providerType = _.get(
      this.props,
      "providerSettings.payload.type",
      app.defaultProvider
    );

    const providerInfo = _.head(
      _.filter(providers, o => o.id === providerType)
    );

    return (
      <div
        style={{
          margin: "0 auto"
        }}
      >
        <h2>
          Please Paste In Your {_.upperFirst(_.get(providerInfo, "id", ""))} Web
          Form Code
        </h2>

        <CodeMirror
          value={this.state.code}
          className="cdmirrorHtml"
          options={{ mode: "text/html", tabMode: "indent", lineNumbers: true }}
          onChange={(editor, data, value) => this.setState({ code: editor })}
          autoFocus
        />

        <Grid
          justify="space-evenly"
          style={{
            marginTop: 30
          }}
        >
          <Cell align="center">
            {this.props.prevHref && !app.defaultProvider ? (
              <Link to={this.props.prevHref}>
                <Button large transparent style={{ color: "#333" }}>
                  Back
                </Button>
              </Link>
            ) : null}
            <Button large tertiary onClick={this.saveCode}>
              Next
            </Button>
          </Cell>
        </Grid>
      </div>
    );
  }
}
