import { ClipPathTypes } from "./types";

export { ClipPathTypes };

interface IClipPaths {
  type: ClipPathTypes;
}

export const getClipPath = ({ type }: IClipPaths) => {
  switch (type) {
    case ClipPathTypes.TRIANGLE:
      return `polygon(50% 0%, 0% 100%, 100% 100%);`;
    case ClipPathTypes.TRAPEZOID:
      return `polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);`;
    case ClipPathTypes.PARALLELOGRAM:
      return `polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)`;
    case ClipPathTypes.RHOMBUS:
      return `polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);`;
    case ClipPathTypes.PENTAGON:
      return `polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);`;
    case ClipPathTypes.HEXAGON:
      return `polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);`;
    case ClipPathTypes.HELPTAGON:
      return `polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);`;
    case ClipPathTypes.OCTAGON:
      return `polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);`;
    case ClipPathTypes.NONAGON:
      return `polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);`;
    case ClipPathTypes.DECAGON:
      return `polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);`;
    case ClipPathTypes.BEVEL:
      return `polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);`;
    case ClipPathTypes.RABBET:
      return `polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);`;
    case ClipPathTypes.LEFTARROW:
      return `polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)`;
    case ClipPathTypes.RIGHTARROW:
      return `polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)`;
    case ClipPathTypes.LEFTPOINT:
      return `polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%)`;
    case ClipPathTypes.RIGHTPOINT:
      return `polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)`;
    case ClipPathTypes.LEFTCHEVRON:
      return `polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)`;
    case ClipPathTypes.RIGHTCHEVRON:
      return `polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)`;
    case ClipPathTypes.STAR:
      return `polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)`;
    case ClipPathTypes.CROSS:
      return `polygon(10% 25%, 35% 25%, 35% 0%, 65% 0%, 65% 25%, 90% 25%, 90% 50%, 65% 50%, 65% 100%, 35% 100%, 35% 50%, 10% 50%)`;
    case ClipPathTypes.MESSAGE:
      return `polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)`;
    case ClipPathTypes.CLOSE:
      return `polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)`;
    case ClipPathTypes.FRAME:
      return `polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%)`;
    case ClipPathTypes.INSET:
      return `inset(5% 20% 15% 10%)`;
    case ClipPathTypes.CIRCLE:
      return `circle(50% at 50% 50%)`;
    case ClipPathTypes.ELLIPSE:
      return `ellipse(25% 40% at 50% 50%)`;
    default:
      return null;
  }
};
