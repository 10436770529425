import _ from "lodash";
import React, { Component } from "react";
import { FontIcon } from "@launchos/components/ui";
import { BigLegend } from "./BigLegend";
import { LegendObject } from "./LegendObject";

class AncestorLegend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBigLegend: false,
      showAllText: false,
    };

    this.toggleBigLegend = this.toggleBigLegend.bind(this);
  }

  toggleBigLegend() {
    this.setState((prevState) => ({
      showBigLegend: !prevState.showBigLegend,
    }));
  }

  render() {
    const {
      listAncestors,
      pageContent,
      changeState,
      state,
      updateComponentSettings,
    } = this.props;

    // find active object
    const activeObject = pageContent.filter((itm) => itm.state === "active");
    if (!activeObject) return null;

    if (activeObject.length) {
      const ancestors = listAncestors(
        pageContent,
        activeObject[0].id
      ).reverse();

      const isFromIframe = window.location !== window.parent.location;

      return (
        <React.Fragment>
          {this.state.showBigLegend ? (
            <BigLegend {...this.props} toggleBigLegend={this.toggleBigLegend} />
          ) : null}
          <div
            style={{
              padding: "10px 20px",
              position: "fixed",
              bottom: 0,
              left: 85,
              background: "#f1f1f1",
              ...isFromIframe ? { bottom: 40, left: 0 } : {}
            }}
          >
            <span
              style={{
                top: 8,
                position: "relative",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={this.toggleBigLegend}
              onMouseEnter={() => this.setState({ showAllText: true })}
              onMouseLeave={() => this.setState({ showAllText: false })}
            >
              <FontIcon>list_alt</FontIcon>
              {this.state.showAllText ? (
                <span
                  style={{
                    fontSize: 11,
                    padding: 10,
                    top: -8,
                    position: "relative",
                    textDecoration: "underline",
                  }}
                >
                  Show All Objects
                </span>
              ) : null}
            </span>
            {ancestors.map((itm, key) => {
              const name = _.get(itm, "label", itm.type);
              const html = name;

              return (
                <span key={key} title="Double-Click to Rename">
                  <LegendObject
                    settings={itm}
                    changeState={changeState}
                    state={state}
                    key={key}
                    updateComponentSettings={updateComponentSettings}
                  >
                    {html.length > 15
                      ? `${html.substring(0, 15).trim()}...`
                      : html.substring(0, 15)}
                    {key < ancestors.length - 1 ? (
                      <FontIcon
                        style={{
                          zoom: "50%",
                          paddingLeft: 5,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        keyboard_arrow_right
                      </FontIcon>
                    ) : null}
                  </LegendObject>
                </span>
              );
            })}
          </div>
        </React.Fragment>
      );
    }

    return null;
  }
}

export default AncestorLegend;
