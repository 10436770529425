import * as React from "react";
import { get, head } from "lodash";

import { CURSOR_ID } from "@launchos/modules/editor/Builder/WebComponent/types";
import { CarouselItemContent } from "./live";
import { CarouselProps } from "./types";

import { EditorMode } from "@launchos/modules/v2/Editor/types";
import { EmptyState } from "@launchos/modules/editor/Builder/EditorObject";

import { getResponsiveSettings } from "@launchos/modules/editor/Builder/Canvas/scripts";

export const CarouselItemEditorVersion: React.FC<CarouselProps> = (props) => {
  const {
    settings = {},
    pageContent = [],
    children = null,
    mode = EditorMode.LIVE,
    getMobileState = () => null,
  } = props;

  let isEmpty = false;
  const childContent = pageContent.filter((itm) => itm.parent === settings.id);
  if (childContent.length)
    isEmpty = !!(childContent.length === 1 && childContent[0].id === CURSOR_ID);
  else isEmpty = true;

  // const { getMobileState } = props;
  const currentMobileState = getMobileState();

  // only show if parent carousel is active
  const parentCarousel = head(
    pageContent.filter(({ id }) => id === settings.parent)
  );

  const parentCarouselSettings = getResponsiveSettings(
    parentCarousel,
    currentMobileState
  );

  if (
    get(parentCarousel, "items", []).findIndex(
      ({ id, active = false }) => id === get(settings, "itemId")
    ) > -1
  ) {
    return (
      <div
        style={{ padding: get(parentCarouselSettings, "properties.margin", 0) }}
      >
        <CarouselItemContent {...settings}>
          {isEmpty && mode === EditorMode.EDITOR ? (
            <div style={{ padding: 30 }}>
              <EmptyState {...props} />
            </div>
          ) : (
            children
          )}
        </CarouselItemContent>
      </div>
    );
  }
  return null;
};

export default CarouselItemEditorVersion;
