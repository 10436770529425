import React from "react";
import { DropTarget } from "react-dnd";

import { Button } from "@launchos/components/ui";
import { FixedPositions } from "@launchos/components/ui/types";
import { AppConfig } from "@launchos/modules/app";
import { useDimensions } from "@launchos/plugins/utilities";

import AddNewDrawer from "./AddNewDrawer";
import AncestorLegend from "./AncestorLegend";
import MobileResponsiveToggle from "./MobileResponsiveToggle";
import SectionsLegend from "./SectionsLegend";

import Canvas from "./Canvas";
import style, { getCanvasWrapperStyle } from "./style";



/**
 * This is the entire viewing area for the editor.  All page building/editing
 * happens here...
 * @param {object} props The props
 * @returns {function} The component
 */
const Builder = (props) => {
  const [addNewDrawerVisible, setAddNewDrawerVisible] = React.useState(false)
  const [activeAddNewTab, setActiveAddNewTab] = React.useState("OBJECTS")
  const [ref, hoverBoundingRect] = useDimensions();

  const toggleAddNewDrawer = () => setAddNewDrawerVisible(!addNewDrawerVisible)

  return (
    <AppConfig.Consumer>
      {({ app }) => {
        const { getPageQuery, connectDropTarget, getMobileState } = props;

        if (getPageQuery.loading) return <img alt="" src={app.loadingImage} />;
        if (getPageQuery.error) return <img alt="" src={app.errorImage} />;

        const isFromIframe = window.location !== window.parent.location;

        return (
          <div style={style.pageWrap}>
            <AddNewDrawer
              {...props}
              activeTab={activeAddNewTab}
              onClose={toggleAddNewDrawer}
              hidden={!addNewDrawerVisible}
              switchTab={setActiveAddNewTab}
            />
            <Button
              style={{ ...style.addNewButton, ...isFromIframe && { top: 10, left: 10 } }}
              icon
              secondary
              fixedPosition={FixedPositions.TOP_LEFT}
              onClick={toggleAddNewDrawer}
            >
              add
            </Button>
            <SectionsLegend
              {...props}
              openAddNewTab={() => {
                setActiveAddNewTab("SECTIONS");
                toggleAddNewDrawer()
              }}
            />
            {
              connectDropTarget(
                <div ref={ref} style={getCanvasWrapperStyle(getMobileState())}>
                  <Canvas
                    {...props}
                    toggleAddNewDrawer={toggleAddNewDrawer}
                    setActiveAddNewTab={setActiveAddNewTab}
                    ecCoordinates={hoverBoundingRect}
                  />
                </div>
              )
            }
            <AncestorLegend {...props} />
            <MobileResponsiveToggle {...props} />
          </div>
        )
      }}
    </AppConfig.Consumer>

  )
}

export default DropTarget(
  "CANVAS",
  {
    hover: (props, monitor) => {
      const { onResize } = monitor.getItem();
      onResize(props, monitor);
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(Builder);
