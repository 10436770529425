export default [
  {
    type: "TrackingSource",
    id: "traffic_source_facebook",
    name: "Facebook Traffic",
    description: "Creates a tracking link (for your Facebook Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_facebook.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_adwords",
    name: "Google Adwords",
    description: "Creates a tracking link (for your Google Adwords Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_adwords.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_email",
    name: "Email Traffic",
    description: "Creates a tracking link (for your Email Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_email.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_blog",
    name: "Blog Traffic",
    description: "Creates a tracking link (for your Blog Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_blog.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_twitter",
    name: "Twitter Traffic",
    description: "Creates a tracking link (for your Twitter Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_twitter.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_youtube",
    name: "YouTube Traffic",
    description: "Creates a tracking link (for your YouTube Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_youtube.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_webinar",
    name: "Webinar Traffic",
    description: "Creates a tracking link (for your Webinar Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_webinar.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_affiliate",
    name: "Affiliate Traffic",
    description: "Creates a tracking link (for your Affiliate Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_affiliate.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_other",
    name: '"Other" Traffic',
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_other.png`,
    width: 150,
    height: 100
  },
  {
    type: "TrackingSource",
    id: "traffic_source_organic",
    name: "Organic Traffic",
    description: "Creates a tracking link (for your Organic Traffic). People who visit the link will automatically be redirected to the next page in the funnel sequence.",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_organic.png`,
    width: 150,
    height: 100
  }
];
