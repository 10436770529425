import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import EditorPicture from "./Picture";
import settings from "./settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Picture") {
    return [EditorPicture];
  }
};
