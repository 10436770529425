export enum CTALayouts {
  ONE = "CTA 01",
  TWO = "CTA 02",
  THREE = "CTA 03",
  FOUR = "CTA 04",
  FIVE = "CTA 05",
  SIX = "CTA 06",
  SEVEN = "CTA 07",
  EIGHT = "CTA 08",
  NINE = "CTA 09",
  TEN = "CTA 10",
  ELEVEN = "CTA 11",
  TWELVE = "CTA 12",
  THIRTEEN = "CTA 13",
  FOURTEEN = "CTA 14",
  FIFTEEN = "CTA 15",
  SIXTEEN = "CTA 16",
  SEVENTEEN = "CTA 17",
  EIGHTEEN = "CTA 18",
  NINETEEN = "CTA 19",
  TWENTY = "CTA 20",
  TWENTYONE = "CTA 21",
  TWENTYTWO = "CTA 22"
}
