import theme from "@launchos/components/ui/theme";

export default {
  id: "column",
  name: "Columns",
  // thumbnail: "comp_column.png",
  thumbnail: {
    style: {
      background: theme.altColors[1],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 11,
    },
    icon: "view_column",
  },
  category: 'layout',
  default: {
    type: "Columns",
    label: "Row",
    canHaveChildren: false,
    properties: {
      width: "100%"
    },
    children: [
      {
        type: "Column",
        canHaveChildren: true,
        preventDelete: true,
        md: 50,
        properties: { height: "100%", padding: "0.35rem", minHeight: 20 }
      },
      {
        type: "Column",
        canHaveChildren: true,
        preventDelete: true,
        md: 50,
        properties: { height: "100%", padding: "0.35rem", minHeight: 20 }
      }
    ]
  }
};
