import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import settings01 from "./01/settings";
import settings02 from "./02/settings";
import settings03 from "./03/settings";
import settings04 from "./04/settings";
import settings05 from "./05/settings";
import settings06 from "./06/settings";
import settings07 from "./07/settings";
import settings08 from "./08/settings";
import settings09 from "./09/settings";
import settings10 from "./10/settings";
import settings11 from "./11/settings";
import settings12 from "./12/settings";
import settings13 from "./13/settings";
import settings14 from "./14/settings";
import settings15 from "./15/settings";
import settings16 from "./16/settings";
import settings17 from "./17/settings";
import settings18 from "./18/settings";
import settings19 from "./19/settings";
import settings20 from "./20/settings";
import settings21 from "./21/settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.WEB_LAYOUT && hook.type === "drawer") {
    return [
      settings01,
      settings02,
      settings03,
      settings04,
      settings05,
      settings06,
      settings07,
      settings08,
      // settings09,
      settings10,
      settings11,
      settings12,
      settings13,
      settings14,
      settings15,
      // settings16,
      // settings17,
      // settings18,
      settings19,
      settings20,
      settings21,
    ];
  }
};
