import * as React from "react";
import * as WebComponents from "..";
import { ComponentTypes } from "../../types";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface StacksProps {
  mode?: EditorMode;
  data: any;
  globalStyle?: any;
}

interface IStackData {
  type: ComponentTypes;
  style?: any;
  html?: string | React.ReactNode;
}

export const Stacks: React.FC<StacksProps> = ({ data = [], globalStyle }) => {
  return (
    <div data-testid="WC-STACKS-LIVE">
      {data.map((itm: IStackData, key: number) => {
        const Component: any = WebComponents[itm.type];
        return (
          <div key={key} data-testid="WC-STACK-LIVE">
            <Component style={{ ...globalStyle, ...itm.style }}>
              {itm.html}
            </Component>
          </div>
        );
      })}
    </div>
  );
};

export default Stacks;
