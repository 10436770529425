import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { GuaranteeLayouts } from "../types";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: GuaranteeLayouts.THREE,
  layout: GuaranteeLayouts.THREE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/guarantees/Guarantee03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzt2al72l5707802d671a78",
    itemId: "k9zigl7o3i",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.GUARANTEE,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.LISTITEM,
                canHaveChildren: false,
                html: `<p style="font-size: 18pt; font-family: ${
                  theme.fontFamily
                }"><strong>30 Day Guarantee</strong></p><p>_</p><p style="font-size: 13pt; line-height: 18pt; padding: 15px 0; font-family: ${
                  theme.fontFamily
                }">${lorem.generateSentences(1)}</p>`,
                icon: IconTypes.Security,
                iconStyle: {
                  paddingRight: 20,
                  fontSize: "56pt",
                  color: theme.backgroundColor,
                },
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.LISTITEM,
                canHaveChildren: false,
                html: `<p style="font-size: 18pt; font-family: ${
                  theme.fontFamily
                }"><strong>30 Day Guarantee</strong></p><p>_</p><p style="font-size: 13pt; line-height: 18pt; padding: 15px 0; font-family: ${
                  theme.fontFamily
                }">${lorem.generateSentences(1)}</p>`,
                icon: IconTypes.Lock,
                iconStyle: {
                  paddingRight: 20,
                  fontSize: "56pt",
                  color: theme.backgroundColor,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
