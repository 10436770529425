import { get, has, cloneDeep } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
// import { getWebComponentStyle } from ''

import { FontIcon } from "@launchos/components/ui";

import style from "./style";
import { DropDownMenu } from "./DropDownMenu";

class DragHandle extends Component {
  constructor(props) {
    super(props);
    this.handleDropDownClick = this.handleDropDownClick.bind(this);
    this.handleDropDownClose = this.handleDropDownClose.bind(this);
  }

  handleDropDownClick(event) {
    event.preventDefault();
    // this.setState({ anchorEl: event.currentTarget });
    this.props.setIsContextMenuShowing({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }

  handleDropDownClose() {
    this.props.setIsContextMenuShowing(null);
  }

  render() {
    const {
      settings,
      rSettings,
      showProperties,
      color,
      objCoordinates,
      parentCoordinates,
      ecCoordinates,
      customComponent,
      getMobileState,
      noOffset = false,
      forceOffset = false,
    } = this.props;

    const type = [
      settings.type[0].toUpperCase(),
      ...settings.type.slice(1),
    ].join("");

    const distFromTop = Math.abs(ecCoordinates.top - objCoordinates.top);
    const distFromParent = Math.abs(parentCoordinates.top - objCoordinates.top);
    let offset =
      (distFromTop < 22 || distFromParent < 22) && !noOffset
        ? // ((distFromTop < 150 || distFromParent < 150) && !noOffset)
        { marginTop: 0 }
        : { marginTop: -22 };

    if (forceOffset) offset = { marginTop: 0 };

    const hideLabel =
      settings.properties.width < 110 || objCoordinates.width < 110;

    let label = settings.type;
    if (has(this.props, "label")) label = this.props.label;
    if (has(settings, "label")) label = settings.label;

    const objectHandle = {
      active: {
        ...style.objectHandleNormal,
        ...offset,
        backgroundColor:
          typeof v === "function" ? color(settings.state) : color,
        borderColor: typeof v === "function" ? color(settings.state) : color,
        color: "white",
        fontWeight: "bold",
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        cursor: "grab",
        ...get(this.props, "objectHandleStyle.active", {}),
      },
      hover: {
        ...style.objectHandleNormal,
        ...offset,
        color: typeof v === "function" ? color(settings.state) : color,
        cursor: "grab",
        ...get(this.props, "objectHandleStyle.hover", {}),
      },
    };

    return (
      <>
        {customComponent}
        <div style={objectHandle[settings.state]}>
          {hideLabel ? null : (
            <span style={{ ...style.dragHandleText }}>
              {label.toUpperCase()}
            </span>
          )}
          {settings.state === "active" ? (
            <div style={{ display: "inline-block" }}>
              <FontIcon
                onClick={showProperties}
                style={style.gearIcon}
                title={`Show ${type} Properties`}
              >
                settings
              </FontIcon>
              {settings.parent && (
                <div
                  style={{
                    display: "inline",
                    position: "absolute",
                    marginLeft: 7,
                    marginTop: -5,
                    cursor: "pointer",
                  }}
                >
                  <span
                    aria-controls="editor-object-menu"
                    aria-haspopup="true"
                    onClick={this.handleDropDownClick}
                  >
                    <FontIcon
                      className="arrdropdown"
                      style={{ zoom: "90%", color: "#666" }}
                    >
                      arrow_drop_down
                    </FontIcon>
                  </span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

DragHandle.propTypes = {
  showProperties: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool,
  isResizable: PropTypes.bool,
  color: PropTypes.string,
  // dropDownItems: PropTypes.array
};

DragHandle.defaultProps = {
  dropDownItems: false,
};

export default DragHandle;
