import React from "react";
import { Switch } from "@launchos/components/ui";
import style from "./style";

export const MaxCheck: React.FC<{
  curMaxCheckValue: number | boolean;
  setMaxCheckValue: React.Dispatch<React.SetStateAction<number | boolean>>;
  maxValue: React.ReactText;
  sliderValue: string;
  setSliderValue: React.Dispatch<React.SetStateAction<string>>;
  doFinalChange: (e: any, value: any) => void;
}> = ({
  curMaxCheckValue,
  setMaxCheckValue,
  maxValue,
  sliderValue,
  setSliderValue,
  doFinalChange,
}) => (
    <>
      <Switch
        data-testid="ButtonSliderGroup-maxCheck"
        checked={curMaxCheckValue}
        onClick={() => {
          setMaxCheckValue(!curMaxCheckValue);
          const newSliderValue = curMaxCheckValue ? maxValue : sliderValue;
          setSliderValue(newSliderValue);
          doFinalChange(false, sliderValue === "100%" ? "auto" : "100%");
        }}
      />
      <span style={style.switchLabel}>Maximum Amount</span>
    </>
  );
