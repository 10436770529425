import {
  Button,
  Cell,
  Dialog,
  DialogBody,
  DialogFooter,
  Grid,
} from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
// import { app } from "../../config";
import style from "./style";

/**
 * This is the popup window that displays when a user attempts to publish their page
 * @param {object} props The props
 * @returns {function} The component
 */
class PublishSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      published: false,
    };

    this.getNextPage = this.getNextPage.bind(this);
  }

  static contextType = AppConfig;

  // calculate nextPage
  getNextPage(pageId) {
    const { getPageQuery } = this.props;
    const object = getPageQuery.page.object;
    console.log("NEXT PAGE", { object });

    if (object) {
      const nextPageObj = object.connectTo
        ? object.connectTo.filter((obj) => {
          if (obj.hasOwnProperty("page")) return true;
          return false;
        })
        : false;

      console.log("NEXTPAGEOBJ", { nextPageObj });

      if (nextPageObj.length) return nextPageObj[0].page.slug;
      return false;
    }
    return false;
  }

  componentDidMount() {
    const { app } = this.context;
    const { getPageQuery, getCampaignQuery, getUserQuery, match } = this.props;

    if (!getCampaignQuery.loading && !getCampaignQuery.error) {
      console.log(
        {
          getCampaignQuery,
        },
        { getPageQuery }
      );

      const pageId = match.params.hasOwnProperty("nodeId")
        ? match.params.nodeId
        : false;

      if (pageId) {
        const pageSettings = getPageQuery.page.pageSettings || {};
        const userSettings = getUserQuery.user;

        const seoPayload = pageSettings.hasOwnProperty("seo")
          ? JSON.stringify(pageSettings.seo)
          : "{}";
        const emailPayload = pageSettings.hasOwnProperty("email")
          ? JSON.stringify(pageSettings.email)
          : "{}";
        const merchantPayload = pageSettings.hasOwnProperty("merchant")
          ? JSON.stringify(pageSettings.merchant)
          : "{}";
        const trackingPayload = pageSettings.hasOwnProperty("tracking")
          ? JSON.stringify(pageSettings.tracking)
          : "{}";
        const cssPayload = pageSettings.hasOwnProperty("css")
          ? pageSettings.css
            .replace(/\n/g, "")
            .replace(/\t/g, "")
            .replace(/ /g, "")
          : "";
        const exitPayload = pageSettings.hasOwnProperty("exitPopup")
          ? pageSettings.exitPopup
          : "{}";

        const nextPage = this.getNextPage(pageId) || "";

        // const parentDomain = _.get(
        //   getCampaignQuery,
        //   "campaign.domain.name",
        //   `${userSettings.subdomain}.${app.domain}`
        // );
        // ^^ we now map domains to their subdomain on s3, so we should upload to that folder (and not a folder with their domain name)
        const parentDomain = `${userSettings.subdomain}.${app.domain}`;
        console.log({ parentDomain, nextPage });

        const isHomePage = getPageQuery.page.homePageForDomain !== null;
        const is404 = getPageQuery.page.errorPageForDomain !== null;

        const variables = {
          pageId: match.params.nodeId,
          slug: getPageQuery.page.slug,
          subdomain: userSettings.subdomain,
          launchDate: new Date().toISOString(),
          content: JSON.stringify(getPageQuery.page.content),
          parentDomain,
          seoPayload,
          emailPayload,
          merchantPayload,
          trackingPayload,
          cssPayload,
          exitPayload,
          nextPage,
          isHomePage,
          is404,
          type: getPageQuery.page.type,
          cloudfrontDistribution:
            _.get(
              getCampaignQuery,
              "campaign.domain.cloudfrontDistribution",
              false
            ) || app.cloudfrontDistribution,
        };
        console.log("publishing!", { variables }, pageSettings);

        const webSiteUrl = `http://${_.get(
          getCampaignQuery,
          "campaign.domain.name",
          variables.parentDomain
        )}/${variables.slug}`;

        this.props.publishPage({ variables }).then((response) => {
          console.log({ response });
          this.setState({
            published: true,
            url: webSiteUrl,
          });
        });
      }
    }
  }

  render() {
    const { app } = this.context;

    return (
      <Dialog
        {...this.props}
        style={style.popup}
        overlayStyle={style.popupBg}
        centered
        hidden={false} // closeHref="builder"
      >
        <DialogBody>
          {this.state.published ? (
            <div>
              <img
                alt=""
                style={{
                  margin: 10,
                }}
                src="https://s3.amazonaws.com/sandcastleassets/images/success_check.png"
              />
              <h2>Your Page Has Been Published!</h2>
              {/* <div
                style={{
                  color: "red",
                  textAlign: "center"
                }}
              >
                Please Note: Your changes may not take effect for up to another
                60 seconds!
              </div> */}
              <Grid
                justify="center"
                style={{
                  marginTop: 30,
                  border: "1px solid #CCC",
                  padding: 5,
                  borderRadius: 3,
                  width: 600,
                }}
              >
                <Cell align="center">
                  <a
                    target="_blank"
                    href={this.state.url}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: "#555",
                        textDecoration: "underline",
                      }}
                    >
                      {this.state.url}
                    </span>
                  </a>
                </Cell>
                <Cell align="center">
                  <Button
                    style={{
                      color: "#999",
                      visibility: "hidden",
                    }}
                    icon
                  >
                    edit
                  </Button>
                </Cell>
              </Grid>
            </div>
          ) : (
            <div>
              <h2>Please wait while we publish your page...</h2>
              {/* <div
                style={{
                  color: "red",
                  textAlign: "center",
                }}
              >
                Note: This can take up to 60 seconds
              </div> */}
              <img
                style={{
                  marginTop: 10,
                }}
                alt=""
                src={app.loadingImage}
              />
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          {this.state.published ? (
            <Grid justify="space-evenly">
              <Cell align="center">
                <a
                  target="_blank"
                  href={this.state.url}
                  rel="noopener noreferrer"
                >
                  <Button large tertiary>
                    VISIT PAGE NOW
                  </Button>
                </a>
              </Cell>
            </Grid>
          ) : null}
        </DialogFooter>
      </Dialog>
    );
  }
}

PublishSuccess.propTypes = {
  /** Boolean whether or not to display the section */
  hidden: PropTypes.bool,
  /** This is the url of the page that was just published */
  url: PropTypes.string.isRequired,
};
PublishSuccess.defaultProps = {
  hide: true,
  url: "http://mysubdomain.10minutefunnels.com/the-page-name-slug",
};

export default PublishSuccess;
