// const gql = require("graphql-tag");
import gql from 'graphql-tag'


// deletePageMutation;
export const deletePageMutation = gql`
  mutation deletePage($id: ID!) {
    deletePage(where: { id: $id }) {
      id
      name
      screenshot
      slug
      type
      theme
      content
      user {
        id
      }
    }
  }
`;

// getPage;
export const getPage = gql`
  query getPage($pageId: ID!) {
    page(where: { id: $pageId }) {
      id
      name
      content
      publishedAt
      pageSettings
      slug
      type
      homePageForDomain {
        id
      }
      errorPageForDomain {
        id
      }
      object {
        id
        connectTo {
          id
          deleted
          page {
            id
            slug
          }
        }
      }
    }
  }
`;

// createPage;
export const createPage = gql`
  mutation createPage(
    $name: String!
    $screenshot: String
    $slug: String!
    $theme: String!
    $type: String!
    $content: Json
    $userId: ID!
    $pageSettings: Json
  ) {
    createPage(
      data: {
        name: $name
        screenshot: $screenshot
        slug: $slug
        theme: $theme
        type: $type
        content: $content
        user: { connect: { id: $userId } }
        pageSettings: $pageSettings
      }
    ) {
      id
      name
      screenshot
      slug
      theme
      type
      content
      pageSettings
      user {
        id
      }
    }
  }
`;

// updatePageContent;
export const updatePageContent = gql`
  mutation updatePage($pageId: ID!, $content: Json, $type: String) {
    updatePage(
      where: { id: $pageId }
      data: { content: $content, type: $type }
    ) {
      id
      content
      pageSettings
      user {
        id
      }
    }
  }
`;

// updatePageName;
export const updatePageName = gql`
  mutation updatePage($pageId: ID!, $name: String, $slug: String) {
    updatePage(where: { id: $pageId }, data: { name: $name, slug: $slug }) {
      id
      content
      pageSettings
      user {
        id
      }
      slug
      object {
        id
        screenshot
        connectTo {
          id
          deleted
        }
      }
    }
  }
`;

// updatePageSettings;
export const updatePageSettings = gql`
  mutation updatePage($pageId: ID!, $pageSettings: Json) {
    updatePage(where: { id: $pageId }, data: { pageSettings: $pageSettings }) {
      id
      content
      pageSettings
      user {
        id
      }
    }
  }
`;

// updatePageType;
export const updatePageType = gql`
  mutation updatePage($pageId: ID!, $userId: ID, $type: String) {
    updatePage(where: { id: $pageId }, data: { userId: $userId, type: $type }) {
      id
      type
    }
  }
`;

// publishPage;
export const publishPage = gql`
  mutation publishPage(
    $pageId: ID!
    $slug: String!
    $subdomain: String!
    $launchDate: DateTime
    $content: String!
    $seoPayload: String!
    $emailPayload: String!
    $merchantPayload: String!
    $trackingPayload: String!
    $cssPayload: String!
    $exitPayload: String!
    $type: String!
    $nextPage: ID!
    $parentDomain: String!
    $isHomePage: Boolean!
    $is404: Boolean!
    $cloudfrontDistribution: String
  ) {
    publishPage(
      pageId: $pageId
      slug: $slug
      subdomain: $subdomain
      launchDate: $launchDate
      content: $content
      seoPayload: $seoPayload
      emailPayload: $emailPayload
      merchantPayload: $merchantPayload
      trackingPayload: $trackingPayload
      cssPayload: $cssPayload
      exitPayload: $exitPayload
      type: $type
      nextPage: $nextPage
      parentDomain: $parentDomain
      isHomePage: $isHomePage
      is404: $is404
      cloudfrontDistribution: $cloudfrontDistribution
    ) {
      webSiteUrl
      message
      statusCode
    }
  }
`;

// makeThisMyHomePage;
export const makeThisMyHomePage = gql`
  mutation makePageAHomePage($domainId: ID!, $pageId: ID!) {
    updateDomain(
      where: { id: $domainId }
      data: { homePage: { connect: { id: $pageId } } }
    ) {
      id
      homePage {
        id
        name
      }
    }
  }
`;

// makeThisMyErrorPage;
export const makeThisMyErrorPage = gql`
  mutation makeThisMyErrorPage($domainId: ID!, $pageId: ID!) {
    updateDomain(
      where: { id: $domainId }
      data: { errorPage: { connect: { id: $pageId } } }
    ) {
      id
      errorPage {
        id
        name
      }
    }
  }
`;


export const checkIfSlugExists = gql`
  query checkIfSlugExists($slug: String!, $userId: ID!, $pageId: ID!) {
      pages(
        where: {
          slug: $slug
          object: { campaign: { user: { id: $userId } }, deleted: false }
          id_not: $pageId
        }
      ) {
        id
      }
    }
`