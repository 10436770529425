import { PageTypes } from "@launchos/modules/v2/CRUD/AttributeBuilder/LinkSelector/types";

export type StatsProps = {
  pageId: string;
  campaignId: string;
  userId: string;
  siteId: string;
  objectId: string;
};

export type IPage = {
  id: string;
  type?: PageTypes;
  screenshot?: string;
  name?: string;
  content?: unknown[];
  pageSettings?: unknown;
  slug?: string;
};

export type ConversionRateProps = {
  midPoint: number[];
  fill: string;
  value: number;
};

export enum QuickDateRange {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  LASTWEEK = "Last Week",
  LASTMONTH = "Last Month",
  LAST7DAYS = "Last 7 Days",
  LAST30DAYS = "Last 30 Days",
  ALLTIME = "All Time",
}

export type handleDateRangeFunction = (
  str: QuickDateRange,
  campaignProps: unknown
) => void;

export type StatsNodePayload = {
  peopleCount: number | string;
  visitorCount: number | string;
  loading: boolean;
};

export interface StatsNodeProps extends StatsNodePayload {
  page: IPage;
  getCampaignState?: (value: string) => void;
  setCampaignState?: (payload: unknown) => void;
}
