import { ValueTypesEnum } from "./ValueSelector/types";
import { SelectorTypes } from "./LoadSelector/types";
import {
  CommonProfiles,
  DateItems,
  BooleanItems,
  StringItems,
  QuickDates,
  NumberItems,
} from "./types";

export const smartWidgetIndexItems = [
  {
    id: "startDate",
    caption: "The Start Date",
    next: CommonProfiles.DATE_OPERATORS,
  },
  {
    id: "endDate",
    caption: "The End Date",
    next: CommonProfiles.DATE_OPERATORS,
  },
  {
    id: "daysAfterFirstVisit",
    caption: "The # Days Since First Visit",
    next: CommonProfiles.NUMBER_OPERATORS,
  },
  {
    id: "daysAfterLastVisit",
    caption: "The # Days Since Last Visit",
    next: CommonProfiles.NUMBER_OPERATORS,
  },
  {
    id: "numPageVisits",
    caption: "The # Page Visits",
    next: CommonProfiles.NUMBER_OPERATORS,
  },
  { id: "url", caption: "The URL", next: CommonProfiles.STRING_OPERATORS },
  // { id: "test", caption: "Bool Test", next: CommonProfiles.BOOLEAN_OPERATORS },
  // { id: "form", caption: "The Incoming Form Submission", next: CommonProfiles.FORM_FIELDS },
  // { id: "tags", caption: "The Visitor Tags", next: CommonProfiles.TAG_LIST },
  // { id: "collection", caption: "The Collection", next: CommonProfiles.COLLECTION_FIELDS },
];

const dateOperatorItems = [
  { id: DateItems.IS, caption: "Is", next: CommonProfiles.QUICK_DATES },
  {
    id: DateItems.IS_BEFORE,
    caption: "Is before",
    next: CommonProfiles.QUICK_DATES,
  },
  {
    id: DateItems.IS_AFTER,
    caption: "Is after",
    next: CommonProfiles.QUICK_DATES,
  },
  {
    id: DateItems.IS_BETWEEN,
    caption: "Is within",
    next: CommonProfiles.QUICK_DATES_RANGE,
  },
];

const numberOperatorItems = [
  { id: NumberItems.EQUALS, caption: "=", next: ValueTypesEnum.NUMBER },
  { id: NumberItems.DOES_NOT_EQUAL, caption: "≠", next: ValueTypesEnum.NUMBER },
  { id: NumberItems.GREATER_THAN, caption: ">", next: ValueTypesEnum.NUMBER },
  { id: NumberItems.LESS_THAN, caption: "<", next: ValueTypesEnum.NUMBER },
  {
    id: NumberItems.GREATER_THAN_OR_EQUALS,
    caption: "≥",
    next: ValueTypesEnum.NUMBER,
  },
  {
    id: NumberItems.LESS_THAN_OR_EQUALS,
    caption: "≤",
    next: ValueTypesEnum.NUMBER,
  },
];

const stringOperatorItems = [
  { id: StringItems.IS, caption: "Is", next: ValueTypesEnum.STRING },
  { id: StringItems.IS_NOT, caption: "Is not", next: ValueTypesEnum.STRING },
  {
    id: StringItems.CONTAINS,
    caption: "Contains",
    next: ValueTypesEnum.STRING,
  },
  {
    id: StringItems.DOES_NOT_CONTAIN,
    caption: "Does not contain",
    next: ValueTypesEnum.STRING,
  },
  {
    id: StringItems.STARTS_WITH,
    caption: "Starts with",
    next: ValueTypesEnum.STRING,
  },
  {
    id: StringItems.ENDS_WITH,
    caption: "Ends with",
    next: ValueTypesEnum.STRING,
  },
  { id: StringItems.IS_EMPTY, caption: "Is empty" },
  { id: StringItems.IS_NOT_EMPTY, caption: "Is not empty" },
];

const booleanOperatorItems = [
  { id: BooleanItems.IS, caption: "Is", next: ValueTypesEnum.BOOLEAN },
  { id: BooleanItems.IS_NOT, caption: "Is not", next: ValueTypesEnum.BOOLEAN },
];

const basicDateItems = [
  { id: QuickDates.TODAY, caption: "Today" },
  { id: QuickDates.YESTERDAY, caption: "Yesterday" },
  { id: QuickDates.PAST_WEEK, caption: "One week ago" },
  { id: QuickDates.PAST_MONTH, caption: "One month ago" },
];

const quickDateItems = [
  ...basicDateItems,
  {
    id: QuickDates.CUSTOM_DATE,
    caption: "Custom Date",
    next: ValueTypesEnum.DATE,
  },
];

const quickDateWithRangeItems = [
  ...basicDateItems,
  {
    id: QuickDates.CUSTOM_DATE_RANGE,
    caption: "Custom Date Range",
    next: ValueTypesEnum.DATERANGE,
  },
];

export const fields = [
  {
    id: "INDEX",
    type: SelectorTypes.INDEX,
    name: "index",
    items: smartWidgetIndexItems,
    value: "endDate",
  },
  {
    id: CommonProfiles.DATE_OPERATORS,
    type: SelectorTypes.DIMENSION,
    name: "operator",
    items: dateOperatorItems,
  },
  {
    id: CommonProfiles.QUICK_DATES,
    type: SelectorTypes.DIMENSION,
    name: "date",
    items: quickDateItems,
  },
  {
    id: CommonProfiles.QUICK_DATES_RANGE,
    type: SelectorTypes.DIMENSION,
    name: "date",
    items: quickDateWithRangeItems,
  },
  {
    id: CommonProfiles.NUMBER_OPERATORS,
    type: SelectorTypes.DIMENSION,
    name: "operator",
    items: numberOperatorItems,
  },
  {
    id: CommonProfiles.STRING_OPERATORS,
    type: SelectorTypes.DIMENSION,
    name: "operator",
    items: stringOperatorItems,
  },
  {
    id: CommonProfiles.BOOLEAN_OPERATORS,
    type: SelectorTypes.DIMENSION,
    name: "operator",
    items: booleanOperatorItems,
  },
];
