import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 30,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  SUBHEADLINE: {
    fontSize: "18pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  FORM_FIELD: {
    style: {
      padding: 5,
      maxWidth: "100%",
    },
    inputStyle: {
      width: "100%",
      // backgroundColor: "#EEE",
      padding: 15,
      // borderRadius: 20,
      border: "1px solid #DDD",
      borderRadius: 4,
    },
    labelStyle: {
      fontFamily: "Roboto",
      fontSize: "10pt",
      textAlign: "left",
      padding: 5,
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      width: "100%",
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
