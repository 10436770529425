import _ from "lodash";
import React from "react";
import { ListItem } from "@launchos/components/ui";
import { NiceMenu } from "../Helpers/NiceMenu";
import style from '../../../style'

export const ComponentSelector = (props) => {
    const {
        listComponents,
        changeState,
        pageContent,
        filterOut,
        filterOnly,
        onChange,
    } = props;

    const components = listComponents(pageContent, filterOut, filterOnly)

    return (
        <NiceMenu style={{ marginTop: 10 }} label={props.label}>
            {_.map(components, (itm) => (
                <ListItem
                    key={itm.settings.id}
                    onClick={() => onChange(itm)}
                    leftIcon={_.get(itm, "icon", false)}
                    onMouseEnter={() => changeState(itm.settings.id, "hover")}
                    onMouseLeave={() => changeState(itm.settings.id, "normal")}
                >
                    {_.get(itm, "html", "")}
                </ListItem>
            ))}
            {components.length ? <div style={style.divider} /> : null}
            {props.children}
        </NiceMenu>
    );
};
