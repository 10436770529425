export enum FooterLayouts {
  ONE = "Footer 01",
  TWO = "Footer 02",
  THREE = "Footer 03",
  FOUR = "Footer 04",
  FIVE = "Footer 05",
  SIX = "Footer 06",
  SEVEN = "Footer 07",
  EIGHT = "Footer 08",
  NINE = "Footer 09",
  TEN = "Footer 10",
  ELEVEN = "Footer 11",
  TWELVE = "Footer 12",
  THIRTEEN = "Footer 13",
  FOURTEEN = "Footer 14"
}
