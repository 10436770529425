import { TextInput } from "@launchos/components/ui";
import React from "react";
import { style } from "../campaign/shared";

const CatalogHeaderContent = props => {
  const { hidden, history, match } = props;

  const tabId = match.params.tabId;
  const searchStr = match.params.searchStr || "";

  const doSearch = e => {
    const query = e.target.value;
    history.push(`/catalog/${tabId}/${query}`);
  };

  if (!hidden) {
    return (
      <div>
        <div style={style.bigToolbar.inner}>
          <h1 style={{ textAlign: "center", padding: 35 }}>
            {tabId === "home" ? "The Marketplace" : null}
            {tabId === "funnels" ? "Marketing Funnels" : null}
            {tabId === "designs" ? "Page Templates" : null}
            {tabId === "apps" ? "Marketing Apps" : null}
            {tabId === "tools" ? "Funnel Tools" : null}
            {tabId === "people" ? "Expert Network" : null}
          </h1>
          <div style={{ padding: "0 80px 40px" }}>
            <TextInput
              focusOnMount
              onChange={doSearch}
              placeholder="Search..."
              iconLeft="search"
              value={searchStr}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default CatalogHeaderContent;
