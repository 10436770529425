import * as React from "react";
import { useState } from "react";

import { DataCardProps } from "../types";
import { Icon } from "@material-ui/core";
import { BulkActionsMenu } from "..";

/**
 * The default card to use to display a record in the DisplayItems component
 */
const DataCard: React.FC<DataCardProps> = ({
  icon,
  image,
  component,
  tags,
  label,
  canEditLabel,
  onDataChange,
  showActionControls,
  actions,
  onClick,
  onActionSelect,
  onDoubleClick
}: DataCardProps) => {
  const [isEditing, setIsEditing] = useState(false);

  /**
   * Method to update the data for that record
   * @params
   */
  const updateData = () => {
    setIsEditing(false);
    onDataChange();
  };

  /**
   * Method to handle keyboard input keys (eg [esc] and [enter])
   * @params
   */
  const handleKeyboardInput = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { keyCode } = event;
    if (keyCode === 27) setIsEditing(false);
    if (keyCode === 13) updateData();
  };

  return (
    <div
      data-testid="DataCard-Default"
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {icon && <Icon data-testid="DataCard-Icon">{icon}</Icon>}
      {image && <img src={image} data-testid="DataCard-Image" />}
      {component && <div data-testid="DataCard-Component">{component}</div>}
      {tags && <div data-testid="DataCard-Tags">{tags}</div>}
      {label && (
        <div data-testid="DataCard-Caption">
          {!isEditing ? (
            <span
              data-testid="DataCard-CaptionLabel"
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                canEditLabel && setIsEditing(true)
              }
            >
              {label}
            </span>
          ) : (
            <input
              type="text"
              data-testid="DataCard-CaptionEditing"
              onKeyUp={handleKeyboardInput}
              onBlur={updateData}
            />
          )}
        </div>
      )}
      {showActionControls && actions && (
        <BulkActionsMenu
          icon="more_horiz"
          data={actions}
          onSelect={action => onActionSelect(action)}
        />
      )}
    </div>
  );
};

DataCard.defaultProps = {
  canEditLabel: true,
  showActionControls: true
};

export default DataCard;
