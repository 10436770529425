import { fieldset } from "@launchos/components/ui/theme";

const style = {
  tabHeader: {
    // borderBottom: "1px solid #ccc",

    textAlign: "center",
    // backgroundColor: "#F0F0F0",
    width: "100%",

    backgroundColor: "#F5F5F5",
    borderBottom: "1px solid #EEE",
  },
  tabContent: {
    padding: 20,
    textAlign: "center",
    bottom: 0,
    overflowY: "scroll",
    position: "fixed"
  },
  sectionPanel: {
    width: "100%",
    borderRadius: 3,
    backgroundColor: "#EEE",
    marginBottom: 5
  },
  fieldset: {
    ...fieldset,
    padding: "10px 5px",
  },
  btn: {
    margin: 10
  },
  slider: {
    width: 180,
    display: "inline-block",
    padding: "20px 0"
  },
  selectField: {
    padding: 10,
    color: "#666",
    border: "1px solid #DDD",
    borderRadius: 3
  },
  divider: {
    borderTop: "1px solid white",
    height: 1,
    borderBottom: "1px solid #CCC",
    margin: "10px 0"
  },
  linkStyle: {
    textDecoration: "underline",
    color: "blue",
    cursor: "pointer",
    paddingTop: 15,
    textAlign: "center",
    display: "block"
  }
};

export default style;
