export enum HeaderLayouts {
  BLANK = "Empty Section",
  ZERO = "Header 00",
  ONE = "Header 01",
  TWO = "Header 02",
  THREE = "Header 03",
  FOUR = "Header 04",
  FIVE = "Header 05",
  SIX = "Header 06",
  SEVEN = "Header 07",
  EIGHT = "Header 08",
  NINE = "Header 09",
  TEN = "Header 10",
  ELEVEN = "Header 11",
  TWELVE = "Header 12",
  THIRTEEN = "Header 13",
  FOURTEEN = "Header 14",
  FIFTEEN = "Header 15",
  SIXTEEN = "Header 16",
  SEVENTEEN = "Header 17",
  EIGHTEEN = "Header 18",
  NINETEEN = "Header 19",
  TWENTY = "Header 20",
  TWENTYONE = "Header 21",
  TWENTYTWO = "Header 22",
  TWENTYTHREE = "Header 23",
}
