import { ListItemsHooks } from "@launchos/plugins/types";
import DestinationNodesDrawer from "./DestinationNodesDrawer";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.CANVAS_ITEM_DRAWER_TABS) {
    return [
      {
        id: "Destinations",
        icon: "exit_to_app",
        caption: "Destination",
        component: DestinationNodesDrawer
      }
    ];
  }
};

export default { onListItems };
