import { Button, Cell, Grid, TextInput } from "@launchos/components/ui";
import React, { Component } from "react";
export class DomainAddOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: "",
      isDomainValid: false
    };
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }
  extractHostname(url) {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }
    // find & remove port number
    hostname = hostname.split(":")[0];
    // find & remove "?"
    hostname = hostname.split("?")[0];
    return hostname;
  }
  getRootDomain(url) {
    let domain = this.extractHostname(url);
    const splitArr = domain.split(".");
    const arrLen = splitArr.length;
    // extracting the root domain here
    // if there is a subdomain
    if (arrLen > 2) {
      domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
      // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
      if (
        splitArr[arrLen - 2].length === 2 &&
        splitArr[arrLen - 1].length === 2
      ) {
        // this is using a ccTLD
        domain = splitArr[arrLen - 3] + "." + domain;
      }
    }
    return domain;
  }
  validateDomain(val) {
    return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
      val
    );
  }
  handleBlur = event => {
    const { value } = event.target;
    this.setState({ domain: this.getRootDomain(value) });
    this.props.updateDomainInfo({ value: this.getRootDomain(value) });
  };
  handleKeyUp = event => {
    event.persist();
    const { value } = event.target;
    this.setState(
      { isDomainValid: this.validateDomain(this.getRootDomain(value)) },
      () => {
        if (event.keyCode === 13 && this.state.isDomainValid) {
          this.props.updateDomainInfo({ value: this.getRootDomain(value) });
          this.props.changeStep("content");
        }
      }
    );
  };
  render() {
    if (!this.props.hidden) {
      const { changeStep, campaignId } = this.props;
      return (
        <div style={{ width: 550 }}>
          {campaignId ? (
            <h2>
              Type in the Domain Name you want
              <br />
              to use for this campaign...
            </h2>
          ) : (
            <h2>
              Type in the Domain Name you want
              <br />
              to add to your account.
            </h2>
          )}

          <div style={{ margin: 30 }}>
            <TextInput
              id="registrarList"
              className="md-cell md-cell--6"
              style={{ marginTop: -23 }}
              onBlur={this.handleBlur}
              onKeyUp={this.handleKeyUp}
              focusOnMount
              size="large"
              label="Enter your domain name"
              value={this.state.domain}
            />
            <span
              style={{
                margin: 15,
                fontStyle: "italic",
                color: "#999",
                fontSize: 11
              }}
            >
              (e.g. YourName.com)
            </span>
          </div>
          <Grid justify="space-evenly" style={{ marginTop: 30 }}>
            <Cell align="center">
              <Button
                onClick={() => changeStep("content")}
                large
                disabled={!this.state.isDomainValid}
                tertiary={this.state.isDomainValid}
              >
                Next
              </Button>
            </Cell>
          </Grid>
        </div>
      );
    }
    return null;
  }
}
