import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    padding: 80,
    mobile: {
      desktop: { padding: 80 },
      smartphone: { padding: 0 },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 10,
    marginBottom: 60,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
};
