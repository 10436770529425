import { ComponentRenderHooks } from "@launchos/plugins/types";
import EditorVideo from './Video'
import settings from './settings'

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings]
  }
}

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === 'webcomponent' && payload.type === 'Video') {
    return [EditorVideo]
  }
}