export default {
  selectField: {
    margin: 50,
    padding: 10,
    color: "#666",
    border: "1px solid #DDD",
    borderRadius: 3,
    cursor: "pointer"
  },
  stepper: {
    width: "80%",
    margin: "0 auto"
  },
  step: {
    cursor: "pointer"
  }
};
