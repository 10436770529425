import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import EditorButton from "./Button";
// import settings from "./settings";

/**
 * Retire the old (v1) button from the Add New Drawer, but still render it in the editor (and properties) so that it remains backwards compatible
 */
// export const onListItems = (hook, payload, actions) => {
//   if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
//     return [settings];
//   }
// };

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Button")
    return [EditorButton];
};
