const myExport = {
  toggleAppDrawer() {
    this.setState(prevState => ({
      appDrawerVisible: !prevState.appDrawerVisible
    }));
    return null;
  },

  toggleItemDrawer() {
    this.setState((prevState, props) => ({
      itemDrawerVisible: !prevState.itemDrawerVisible
    }));
    return null;
  },

  closeItemDrawer() {
    this.setState((prevState) => {
      if (prevState.itemDrawerVisible) return { itemDrawerVisible: false }
    })
    return null;
  },

  
};

export const { toggleAppDrawer, toggleItemDrawer, closeItemDrawer } = myExport;

export default myExport;
