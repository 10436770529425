import * as React from "react";

import { FontIcon } from "@launchos/components/ui";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";

import fonts from "@launchos/modules/editor/Builder/Properties/Sections/fonts";
import { AppConfig } from "@launchos/modules/app";

import { getFontStyle, style, Cell } from "./style";
import { TextField } from "@material-ui/core";

export const GoogleFontSelector: React.FC<{
  open: boolean;
  onClose: () => void;
  onSelect: (font: string, callbackFn: () => void) => void;
}> = ({ open = false, onClose = () => null, onSelect = () => null }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>("");

  const handleFontSelect = (font) => {
    setLoading(true);
    onSelect(font, () => {
      setLoading(false);
      // onClose();
    });
  };

  React.useEffect(() => setLoading(false), []);

  return (
    <AppConfig.Consumer>
      {({ app }) => (
        <Dialog open={open} maxWidth="md">
          <DialogContent>
            <FontIcon onClick={onClose} style={style.closeBtn}>
              close
            </FontIcon>
            <h2 style={style.h2}>Choose a Google Font</h2>
            <TextField
              type="text"
              placeholder="Search for a font..."
              value={filter}
              onChange={(e) => {
                setLoading(false);
                setFilter(e.target.value);
              }}
              style={{ width: "100%", textAlign: "center" }}
              variant="outlined"
            />
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <img src={app.loadingImage} alt="Loading..." />
              </div>
            ) : (
              <Grid container style={style.main}>
                {fonts.map((font, key) => {
                  if (font.toLowerCase().indexOf(filter.toLowerCase()) === -1)
                    return null;

                  return (
                    <Grid key={key} item>
                      <Cell
                        onClick={() => handleFontSelect(font.split(":")[0])}
                      >
                        <div style={getFontStyle(key)} />
                      </Cell>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      )}
    </AppConfig.Consumer>
  );
};

export default GoogleFontSelector;
