import * as React from "react";
// import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface PopupProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  caption?: string;
  style?: any;
  open: boolean;
  setOpen: any;
}

export const Popup: React.FC<PopupProps> = ({
  children,
  style,
  open = true,
  setOpen,
}) => {
  return (
    <div data-testid="WC-POPUP-LIVE">
      <Backdrop open={open}>
        <Dialog open={open} maxWidth={false}>
          <CloseIcon
            style={{
              textAlign: "right",
              position: "absolute",
              right: 0,
              margin: 5,
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
          <DialogContent style={{ ...style }}>{children}</DialogContent>
        </Dialog>
      </Backdrop>
    </div>
  );
};

export default Popup;
