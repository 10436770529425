import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import slugify from "slugify";

import PagesDrawer from "./PagesDrawer";
import objects from "./objects";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ListItemsHooks.CANVAS_ITEM_DRAWER_TABS) {
    console.log("plugin", "page_nodes", { hook, payload, actions });
    return [
      {
        id: "PAGES",
        icon: "web_asset",
        caption: "Pages",
        component: PagesDrawer
      }
    ];
  }

  if (hook.id === ListItemsHooks.PAGE_OBJECTS && hook.activeTab === "New Pages") {
    return objects;
  }
};

export const onNodeCreate = async (hook, { item }, { createPage }) => {
  if (hook.type === "PageComponent") {
    const { name, screenshot, pageType } = item;

    const slug = slugify(name.toString().toLowerCase(), { remove: /[*+~.()'"!:@]/g });

    const response = await createPage({
      name,
      slug: `${slug}-${Math.random()
        .toString(36)
        .substr(2, 2)}`,
      screenshot,
      theme: "all",
      type: pageType,
      content: [
        {
          id: 2540,
          parent: false,
          properties: { height: "100%" },
          type: "Body",
          canHaveChildren: true
        }
      ],
      userId: localStorage.userId
    });

    return response;
  }
};

export const onNodeOpen = async (
  hook,
  { campaignId, settings },
  { navigate }
) => {
  if (hook.type === "PageComponent") {
    const { content, type, id } = settings.page;
    if (content.length <= 1) {
      navigate(`/campaign/${campaignId}/${id}/templates/${type}`);
    } else {
      navigate(`/campaign/${campaignId}/${id}/builder`);
    }
  }
};

export const onNodeSelect = async (
  hook,
  { campaignId, settings },
  { navigate }
) => {
  if (hook.type === "PageComponent") {
    console.log("testing!!");
  }
};
