import React from "react";
import { get } from "lodash";
import { DragSource } from "react-dnd";

import styled from "styled-components";

import theme from "@launchos/components/ui/theme";

const RNub = styled.span`
  z-index: 100;
  position: absolute;
`;

const boxSource = {
  beginDrag: (props, monitor, component) => {
    console.log("began the drag");
    props.updateDragState(true)
    return props;
  },
  endDrag: (props, monitor, component) => {
    console.log("ended the drag", props, monitor, component);
    props.updateDragState(false)
    get(props, "onResizeEnd", () => null)(props, monitor, component);
  },
};

const ResizeHandles = (props) => {
  const { connectDragSource, connectDragPreview, isDragging } = props;

  const color = props.resizeHandleColor || props.color;

  const eStyle = {
    position: "relative",
    top: "50%",
    width: 6,
    marginLeft: 7,
    height: 20,
    marginTop: -10,
    backgroundColor: isDragging ? theme.altColors[3] : color,
  };
  const wStyle = {
    position: "relative",
    width: 6,
    marginLeft: -3,
    height: 20,
    marginTop: -10,
    backgroundColor: isDragging ? theme.altColors[3] : color,
  };
  const nStyle = {
    position: "relative",
    width: 20,
    marginTop: 2,
    height: 5,
    marginLeft: -10,
    backgroundColor: color,
  };
  const sStyle = {
    position: "relative",
    width: 20,
    marginBottom: 2,
    height: 5,
    marginLeft: -10,
    backgroundColor: color,
  };

  const neStyle = [
    {
      position: "absolute",
      width: 5,
      height: 10,
      marginLeft: -2,
      marginTop: 3,
      backgroundColor: color,
    },
    {
      position: "absolute",
      width: 10,
      height: 5,
      marginTop: 3,
      marginLeft: -7,
      backgroundColor: color,
    },
  ];
  const seStyle = [
    {
      position: "absolute",
      width: 5,
      height: 10,
      marginLeft: -2,
      marginTop: -12,
      backgroundColor: color,
    },
    {
      position: "absolute",
      width: 10,
      height: 5,
      marginTop: -7,
      marginLeft: -7,
      backgroundColor: color,
    },
  ];
  const swStyle = [
    {
      position: "absolute",
      width: 5,
      height: 10,
      marginLeft: -2,
      marginTop: -12,
      backgroundColor: color,
    },
    {
      position: "absolute",
      width: 10,
      height: 5,
      marginTop: -7,
      marginLeft: -2,
      backgroundColor: color,
    },
  ];
  const nwStyle = [
    {
      position: "absolute",
      width: 5,
      height: 10,
      marginLeft: -2,
      marginTop: 3,
      backgroundColor: color,
    },
    {
      position: "absolute",
      width: 10,
      height: 5,
      marginTop: 3,
      marginLeft: -2,
      backgroundColor: color,
    },
  ];

  const handleArray =
    typeof props.resizeHandles === "string"
      ? [props.resizeHandles]
      : props.resizeHandles || [];

  const handleMouseDown = (e) => {
    e.stopPropagation();
    // props.updateDragState(true)
  }
  const handleMouseUp = (e) => {
    e.stopPropagation();
    // props.updateDragState(false)
  }

  return (
    <div>
      {connectDragPreview(
        <div style={{ width: 1, height: 1, position: "absolute" }} />
      )}
      {handleArray.indexOf("N") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{ top: -4, left: "50%", cursor: "ns-resize" }}
            >
              <div style={nStyle} />
            </RNub>
          </span>
        )
        : null}
      {handleArray.indexOf("NE") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{ top: -5, right: 0, cursor: "nesw-resize" }}
            >
              <div style={neStyle[0]} />
              <div style={neStyle[1]} />
            </RNub>
          </span>
        )
        : null}
      {handleArray.indexOf("E") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{
                width: 20,
                top: 0,
                bottom: 0,
                right: -10,
                cursor: "ew-resize",
              }}
            >
              <div style={eStyle} />
            </RNub>
          </span>
        )
        : null}
      {handleArray.indexOf("SE") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{ bottom: -5, right: 0, cursor: "nwse-resize" }}
            >
              <div style={seStyle[0]} />
              <div style={seStyle[1]} />
            </RNub>
          </span>
        )
        : null}
      {handleArray.indexOf("S") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{ bottom: -4, left: "50%", cursor: "ns-resize" }}
            >
              <div style={sStyle} />
            </RNub>
          </span>
        )
        : null}
      {handleArray.indexOf("SW") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{ bottom: -5, left: 0, cursor: "nesw-resize" }}
            >
              <div style={swStyle[0]} />
              <div style={swStyle[1]} />
            </RNub>
          </span>
        )
        : null}
      {handleArray.indexOf("W") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{ top: "50%", left: 0, cursor: "ew-resize" }}
            >
              <div style={wStyle} />
            </RNub>
          </span>
        )
        : null}
      {handleArray.indexOf("NW") > -1
        ? connectDragSource(
          <span>
            <RNub
              onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
              style={{ top: -5, left: 0, cursor: "nwse-resize" }}
            >
              <div style={nwStyle[0]} />
              <div style={nwStyle[1]} />
            </RNub>
          </span>
        )
        : null}
    </div>
  );
};

export default DragSource("CANVAS", boxSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))(ResizeHandles);
