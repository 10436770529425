import * as React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { EditorMode } from "@launchos/modules/v2/Editor/types";
import { ComponentTypes } from "../../types";
import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

interface AccordianProps {
  mode?: EditorMode;
  // children: React.ReactNode;
  settings: any;
  data: {
    titleStyle: React.CSSProperties;
    title: string;
    children: React.ReactNode;
    open?: boolean;
  }[];
}

export const Accordian: React.FC<AccordianProps> = (props) => {
  const { settings, data = false, mode } = props;
  const content = data || settings.data;
  return (
    <div data-testid="WC-ACCORDIAN-LIVE">
      {content.map((itm) => {
        return <AccordianItem {...itm} mode={mode} />;
      })}
    </div>
  );
};

interface AccordianItemProps {
  mode?: EditorMode;
  open?: Boolean;
  title: string;
  children: React.ReactNode;
  titleStyle?: any;
}

export const AccordianItem: React.FC<AccordianItemProps> = ({
  open,
  title,
  titleStyle,
  children,
}) => {
  const [expanded, setExpanded] = React.useState<Boolean | any>(open);
  const handleChange = (panel: string | Boolean) => (
    event: React.ChangeEvent<{}>,
    isExpanded: Boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div data-testid="WC-ACCORDIANITEM-LIVE">
      <ExpansionPanel expanded={expanded} onChange={handleChange(!expanded)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p style={titleStyle}>{title}</p>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default Accordian;
