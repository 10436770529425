import React from "react";
import style from "./style";

import { BODY_ID } from '@launchos/modules/editor/Builder/WebComponent/types'

const Cursor = ({ settings, color = `rgba(63, 81, 181, 1)` }) => {
  const [cursorColor, setColor] = React.useState<string>(color)

  const colorAlt = `rgba(63, 81, 181, 0)`

  React.useEffect(() => {
    setTimeout(() => {
      if (cursorColor === color) setColor(colorAlt)
      else setColor(color)
    }, 350)

    // return () => {
    //   setColor(cursorColor)
    // }
  }, [cursorColor])

  return (
    <div
      style={{
        ...style.dragDropCursorHorizontal,
        backgroundColor: cursorColor,
        transition: `0.25s ease`,
        ...(settings.parent === BODY_ID ? {
          padding: 25,
          backgroundImage: `url(https://www.toptal.com/designers/subtlepatterns/patterns/stripes-light.png`,
          boxShadow: `inset 0 0 15px rgba(0,0,0,0.25)`,
        } : {}),
      }}
    />
  )
}

export default Cursor;
