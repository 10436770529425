export enum ContentLayouts {
  ONE = "Content 01",
  TWO = "Content 02",
  THREE = "Content 03",
  FOUR = "Content 04",
  FIVE = "Content 05",
  SIX = "Content 06",
  SEVEN = "Content 07",
  EIGHT = "Content 08",
  NINE = "Content 09",
  TEN = "Content 10",
  ELEVEN = "Content 11",
  TWELVE = "Content 12",
  THIRTEEN = "Content 13",
  FOURTEEN = "Content 14",
  FIFTEEN = "Content 15",
  SIXTEEN = "Content 16",
  SEVENTEEN = "Content 17",
  EIGHTEEN = "Content 18",
  NINETEEN = "Content 19"
}
