// import Radium from 'radium';
import theme from "@launchos/components/ui/theme";

const style = {
  bigToolbar: {
    main: {
      background: "white",
      boxShadow: "0 0 40px rgba(0,0,0,.2)",
      left: 0,
      position: "fixed",
      right: 0,
      top: 0,
      zIndex: 2
    },
    inner: {
      margin: "0 auto",
      maxWidth: 1000
    },
    cell: {
      padding: 50,
      paddingTop: 0,
      minWidth: 350
    },
    innerInfoBar: {
      borderTop: "1px solid",
      borderTopColor: theme.lightColor
    },
    button: {
      marginTop: 25,
      boxShadow: "7px 7px 61px 0px rgba(191,191,191,0.75)"
    }
  },
  adBlock: {
    backgroundColor: theme.altColors[8],
    zIndex: 0,
    inner: {
      margin: "0 auto",
      maxWidth: 1000,
      padding: 50
    }
  },
  progress: {
    width: 100,
    display: "inline-block",
    height: 5,
    backgroundColor: "#CCC",
    borderRadius: 3,
    margin: "20px 12px 0 0"
  },
  campaignType: {
    padding: 25,
    cursor: "pointer",
    ":hover": {
      border: `3px solid ${theme.primaryColor}`,
      borderRadius: 5
    }
  },
  taglabel: {
    backgroundColor: theme.primaryColor,
    borderRadius: 3,
    padding: 3,
    color: theme.lightColor,
    fontSize: 9,
    margin: "0 5px"
  },
  addButton: {
    position: "fixed",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)",
    top: 90,
    left: 20,
    zIndex: 2,
    color: 'white'
  },
  zoomButtons: {
    position: "fixed",
    bottom: 140,
    right: 38,
    width: 50,
    zIndex: 2,
    zoom: 0.8
  },
  settingsTabDropdown: {
    boxShadow: "0px 28px 45px 0px rgba(0,0,0,0.15)",
    width: 280,
    position: "absolute",
    marginTop: -15
  },
  sectionPanel: {
    width: "100%",
    borderRadius: 3,
    // backgroundColor: "#EEE",
    marginBottom: 5
  },
  defaultComponent: () => ({
    backgroundColor: "white",
    position: "absolute",
    color: "white",
    textAlign: "center",
    cursor: "move"
  }),
  polyline: {
    fill: "none",
    vectorEffect: "non-scaling-stroke",
    strokeWidth: 2
  },
  objectArrow: {
    position: "absolute",
    cursor: "pointer",
    right: -20,
    color: "#999",
    padding: 10,
    paddingRight: 20,
    borderRadius: 30
  },
  divider: {
    borderTop: "1px solid white",
    height: 1,
    borderBottom: "1px solid #CCC"
  },
  connectionLineColor: "#0FACF8",
  selectField: {
    margin: 50,
    padding: 10,
    color: "#666",
    border: "1px solid #DDD",
    borderRadius: 3,
    cursor: "pointer"
  },
  component: {
    border: "1px solid #CCC",
    width: "100%",
    height: "100%",
    marginBottom: 10,
    color: "#999"
  },
  objStyle: {
    width: "100%",
    padding: 5,
    overflowY: "hidden",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
  },
  basicStyle: {
    cursor: "move",
    width: 140,
    overflow: "hidden",
    textAlign: "center",
    margin: 5,
    marginBottom: 20
  },
  objThumbnail: {
    textAlign: "center",
    padding: 20,
    maxHeight: 105,
    overflow: "hidden",
    marginBottom: 3
  },


  menuItem: {
    // fontWeight: 'bold', 
    fontSize: 13,
    display: 'flex',
    flexDirection: 'row'
  },
  menuItemLabel: { padding: 3, paddingLeft: 10, display: 'block' }
};

export default style;
