import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@launchos/components/ui";

export const PreviewButton: React.FC<{ noPreview: boolean }> = ({
  noPreview = false,
}) => {
  if (noPreview) return null;
  return (
    <Link to="./builder/preview" target="_blank" style={{ color: "#333" }}>
      <Button style={{ color: "inherit" }} icon>
        visibility
      </Button>
    </Link>
  );
};
