import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 30,
      },
    },
  },
  HEADLINE: {
    fontSize: "26pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    padding: 10,
  },
  TEXT: {
    fontFamily: theme.fontFamily,
    fontSize: "11pt",
    padding: 10,
  },
  FORM_FIELD: {
    style: {
      marginTop: 10,
      maxWidth: "100%",
      // paddingRight: 40,
    },
    inputStyle: {
      width: "100%",
      padding: 15,
      border: "1px solid #DDD",
      borderRadius: 4,
    },
    labelStyle: {
      fontFamily: "Roboto",
      fontSize: "10pt",
      textAlign: "left",
      padding: 5,
    },
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 10,
      marginTop: 40,
      border: "none",
      backgroundColor: theme.backgroundColor,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
};
