import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { HeaderLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/types";
import { doIShow, combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.SEVENTEEN,
  layout: HeaderLayouts.SEVENTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header17.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "wxmm0nuxu6k",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
      backgroundColor: theme.highlightColor,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          color: theme.foregroundColor,
        },
        html: `Congratulations On ${startCase(
          lorem.generateWords(10)
        )}! It Should Be In Your Inbox In The Next 10 Minutes.`,
      },
    ],
  },
};

export default settings;
