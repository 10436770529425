import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";
import { IconTypes } from "../../../../../../webcomponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.FOURTEEN,
  layout: FormLayouts.FOURTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form14.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzjrul12h5h0780m1q9wbx3",
    itemId: "rgasy05ubnj",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      padding: 100,
                    },
                    smartphone: {
                      padding: 30,
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: "Activate your free account",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: lorem.generateSentences(2),
                  },
                  {
                    type: ComponentTypes.FORM,
                    data: [
                      {
                        type: FormFieldTypes.TEXT,
                        name: FieldNamePresets.FIRSTNAME,
                        placeholder: "First Name",
                        preset: FieldNamePresets.FIRSTNAME,
                      },
                      {
                        type: FormFieldTypes.TEXT,
                        name: FieldNamePresets.LASTNAME,
                        placeholder: "Last Name",
                        preset: FieldNamePresets.LASTNAME,
                      },
                      {
                        type: FormFieldTypes.TEXT,
                        name: FieldNamePresets.EMAIL,
                        placeholder: "Email",
                        preset: FieldNamePresets.EMAIL,
                      },
                    ],
                    properties: {
                      containerStyle: style.FORM_FIELD.style,
                      inputStyle: style.FORM_FIELD.inputStyle,
                      labelStyle: style.FORM_FIELD.labelStyle,
                    },
                  },
                  {
                    type: ComponentTypes.FORMFIELD,
                    canHaveChildren: false,
                    attributes: {
                      placeholder: "Terms",
                      name: "Terms",
                      items: [
                        {
                          id: 0,
                          label: "Accept our Terms and Conditions",
                          value: false,
                        },
                      ],
                    },
                    fieldType: FormFieldTypes.CHECKBOXES,
                    styles: {
                      containerStyle: {
                        ...style.FORM_FIELD.style,
                        marginTop: 20,
                      },
                      labelStyle: style.FORM_FIELD.labelStyle,
                    },
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: { ...style.BUTTON.style, marginTop: 30 },
                    caption1: "Next",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: {
              height: "100%",
              padding: 0,
              minHeight: 20,
              backgroundColor: theme.backgroundColor,
            },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      padding: 100,
                    },
                    smartphone: {
                      padding: 30,
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.SUBHEADLINE,
                      color: theme.foregroundColor,
                    },
                    html: "What You WIll Receive",
                  },
                  {
                    type: ComponentTypes.LIST,
                    data: [
                      {
                        caption:
                          "<strong>Bonus #1:</strong> " +
                          startCase(lorem.generateWords(6)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                      {
                        caption:
                          "<strong>Bonus #2:</strong> " +
                          startCase(lorem.generateWords(6)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                      {
                        caption:
                          "<strong>Bonus #3:</strong> " +
                          startCase(lorem.generateWords(6)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                      {
                        caption:
                          "<strong>Bonus #4:</strong> " +
                          startCase(lorem.generateWords(6)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                      {
                        caption:
                          "<strong>Bonus #5:</strong> " +
                          startCase(lorem.generateWords(6)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                      {
                        caption:
                          "<strong>Bonus #6:</strong> " +
                          startCase(lorem.generateWords(6)),
                        style: style.LISTITEM.style,
                        iconStyle: style.LISTITEM.iconStyle,
                        contentStyle: style.LISTITEM.contentStyle,
                        icon: IconTypes.CheckCircleOutline,
                      },
                    ],
                  },
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    src: theme.logoLight,
                    style: {
                      marginTop: 40,
                      width: 200,
                      textAlign: "center",
                      display: "inline-block",
                    },
                  }),
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
