import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/types";
import { selectOptions } from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/fieldNames";
import { IconSource } from "../../../../../../webcomponents/v2/Icon/live";
import {
  OrderSummaryTypes,
  OrderSummaryItemTypes,
  OrderSummaryDisplayOptions,
} from "@launchos/plugins/webcomponents/v2/OrderSummary/types";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
} from "@fortawesome/free-brands-svg-icons";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.TWENTYONE,
  layout: FormLayouts.TWENTYONE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form21.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzjrul12h5h0780m1q9wbx3",
    itemId: "nysa51bm72n",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: {
              height: "100%",
              mobile: {
                desktop: {
                  padding: 50,
                },
                smartphone: {
                  padding: 20,
                },
              },
              minHeight: 20,
            },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  marginTop: 30,
                },
                html: "Billing Information",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  marginTop: 10,
                },
                html:
                  "You're information is secure and will not be shared with a 3rd party",
              },
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.FIRSTNAME,
                    placeholder: "First Name",
                    preset: FieldNamePresets.FIRSTNAME,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.EMAIL,
                    placeholder: "Email",
                    preset: FieldNamePresets.EMAIL,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.PHONE,
                    placeholder: "Phone",
                    preset: FieldNamePresets.PHONE,
                  },
                ],
                properties: {
                  marginTop: 10,
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                  labelStyle: style.FORM_FIELD.labelStyle,
                },
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  marginTop: 30,
                },
                html: "Billing Address",
              },
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.ADDRESS,
                    placeholder: "Street Address",
                    preset: FieldNamePresets.ADDRESS,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.CITY,
                    placeholder: "City",
                    preset: FieldNamePresets.CITY,
                  },
                  {
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.STATE,
                    placeholder: "State",
                    preset: FieldNamePresets.STATE,
                    data: selectOptions.state,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.ZIP,
                    placeholder: "Zip/Postal Code",
                    preset: FieldNamePresets.ZIP,
                  },
                  {
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.COUNTRY,
                    placeholder: "Country",
                    preset: FieldNamePresets.COUNTRY,
                    data: selectOptions.country,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.PHONE,
                    placeholder: "Phone",
                    preset: FieldNamePresets.PHONE,
                  },
                ],
                properties: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                  labelStyle: style.FORM_FIELD.labelStyle,
                },
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: { ...style.BUTTON.style, marginTop: 30 },
                caption1: "Get Instant Access",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: {
              height: "100%",
              mobile: {
                desktop: {
                  padding: 50,
                },
                smartphone: {
                  padding: 20,
                },
              },
              minHeight: 20,
              backgroundColor: theme.backgroundColor,
            },
            children: [
              {
                type: ComponentTypes.COLUMNS,
                canHaveChildren: false,
                properties: {
                  width: "100%",
                },
                children: [
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((7 * 100) / 12),
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.SUBHEADLINE,
                          color: theme.foregroundColor,
                          marginTop: 30,
                        },
                        html: "Payment Information",
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((5 * 100) / 12),
                    properties: {
                      height: "100%",
                      padding: 0,
                      minHeight: 20,
                      mobile: {
                        desktop: {
                          display: "block",
                        },
                        smartphone: {
                          display: "none",
                        },
                      },
                    },
                    children: [
                      {
                        type: ComponentTypes.LIST,
                        canHaveChildren: false,
                        data: [
                          {
                            icon: faCcVisa,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                          {
                            icon: faCcMastercard,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                          {
                            icon: faCcDiscover,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                          {
                            icon: faCcAmex,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.CARDNUMBER,
                    placeholder: "•••• •••• •••• ••••",
                    preset: FieldNamePresets.CARDNUMBER,
                  },
                  {
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.EXPIRATIONMONTH,
                    placeholder: "Month",
                    preset: FieldNamePresets.EXPIRATIONMONTH,
                    columns: 4,
                  },
                  {
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.EXPIRATIONYEAR,
                    placeholder: "Year",
                    preset: FieldNamePresets.EXPIRATIONYEAR,
                    columns: 4,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.CVV,
                    placeholder: "CVV",
                    preset: FieldNamePresets.CVV,
                    columns: 4,
                  },
                ],
                properties: {
                  marginTop: 10,
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: {
                    ...style.FORM_FIELD.inputStyle,
                    // paddingLeft: 7,
                    // paddingRight: 7,
                    color: theme.foregroundColor,
                  },
                  labelStyle: {
                    ...style.FORM_FIELD.labelStyle,
                    color: theme.foregroundColor,
                  },
                },
              },
              {
                type: ComponentTypes.FORMFIELD,
                canHaveChildren: false,
                attributes: {
                  placeholder: "Terms",
                  name: "Terms",
                  items: [
                    {
                      id: 0,
                      label: "I agree to the terms and conditions",
                      value: false,
                    },
                  ],
                },
                fieldType: FormFieldTypes.CHECKBOXES,
                styles: {
                  containerStyle: {
                    ...style.FORM_FIELD.style,
                    marginTop: 20,
                    color: "white",
                  },
                  labelStyle: {
                    ...style.FORM_FIELD.labelStyle,
                    color: theme.foregroundColor,
                  },
                },
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  color: theme.foregroundColor,
                  marginTop: 40,
                },
                html: "Order Summary",
              },
              {
                type: ComponentTypes.ORDERSUMMARY,
                data: [
                  {
                    id: uniqueId(),
                    title: "Premium Account",
                    itemType: OrderSummaryItemTypes.PRODUCT,
                    value: "FREE",
                  },
                  {
                    id: uniqueId(),
                    title: "Shipping",
                    itemType: OrderSummaryItemTypes.PRODUCT,
                    value: 7.95,
                  },
                ],
                showHeader: true,
                showTotal: true,
                properties: { marginTop: 10, color: "white" },
                designType: OrderSummaryTypes.STANDARD,
                cellStyle: { color: "white", fontSize: "9pt" },
                footerCellStyle: {
                  color: theme.foregroundColor,
                  fontSize: "9pt",
                },
                displayOptions: {
                  [OrderSummaryDisplayOptions.HEADER]: true,
                  [OrderSummaryDisplayOptions.PRODUCTS]: true,
                  [OrderSummaryDisplayOptions.TOTAL]: true,
                  [OrderSummaryDisplayOptions.SUBTOTAL]: false,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
